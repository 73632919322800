/**
 * 新建批量执行任务
 */
import React, { useEffect, useState } from 'react'
import { Button, Divider, Drawer, Form, Radio, Input, message, Spin, Modal, Tooltip } from 'antd'
import { UploadOutlined, PlusCircleOutlined, PaperClipOutlined, DeleteOutlined } from '@ant-design/icons'
import UploadFolderModal from './UploadFolderModal'
import { CustomDragableUploadList } from 'src/components'
import { parseBatchSqlFile, createBatchSql, deleteSqlFile, getUserSysFile } from 'src/api'
import { ErrorHandleWayEnums, ExecuteWayEnums } from './constant'
import styles from './index.module.scss'
import { debounce } from 'lodash'
import { ElementTreeSelect } from './ElementTreeSelect'
import { useHistory } from 'react-router-dom'

interface IProps {
  visible: boolean
  onClose: () => void
  [p: string]: any
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const CreateBatchExecuteDrawer = ({
  visible,
  onClose,
  refreshData
}: IProps) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [uploadFolderModalVisible, setUploadFolderModalVisible] = useState(false)
  const [folderName, setFolderName] = useState<string>('')
  const [step, setStep] = useState<number>(1)
  const [parseResult, setParseResult] = useState<any>({})
  const [mergedScriptInfos, setMergedScriptInfos] = useState<any[]>([])  // 渲染使用
  const [loading, setLoading] = useState(false)
  const [uploadSize, setUploadSize] = useState<number>(0)

  useEffect(()=>{
    getUploadFileSize()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(()=>{
    const { scriptInfos = [{fileOrder: 1}]} = parseResult || {}
    if(!scriptInfos?.length){
      return
    }
    // 构造渲染数据
    let resultArr: any[] = []
    const obj = handleDataFormat(scriptInfos)
    Object.keys(obj)?.forEach((key: string) => {
      const { connectionId, nodePathWithType, realConnectionName, dataSourceType } = obj[key]?.[0] || {};
      resultArr.push({
        connectionId,
        nodePathWithType,
        connectionName: realConnectionName,
        connectionType: dataSourceType,
        id: key
      })
    })
    setMergedScriptInfos(resultArr)
  },[parseResult])

  // 获取上传文件大小限制
  const getUploadFileSize = () => {
    getUserSysFile().then((res: any)=>{
      if(res){
        const { uploadSize = 0 } = res
        setUploadSize(Number(uploadSize))
      }
    }).catch((err: any)=>{
      console.error('获取上传文件大小限制失败', err)
    })
  }

  // add item
  const handleAddTask = () => {
    form.validateFields().then(()=>{
      setMergedScriptInfos([...mergedScriptInfos, {fileOrder: mergedScriptInfos?.length+1}])
    }).catch((error: any)=>{
      console.error('form error:', error)
    }) 
  }

  // delete item
  const handleDeleteTask = (index: number) => {
    setMergedScriptInfos((m: any[])=>{
      m?.map((r: any, i: number)=>{
        if(index===i){
          r.delete=true
        }
        return {...r}
      })
      return [...m]
    })
  }

  const handleNextStep = () => {
    form.validateFields().then((values) => {
      setStep(2)
    })
  }

  const getScriptInfos = () => {
    let scriptInfosValue: any[] = [], initLength = 0
    const values: any = form.getFieldsValue()
    const keys = Object.keys(values)?.filter(i=>i?.startsWith('uploadFile'))?.sort((a,b)=>{
      const numberA = a?.split('uploadFile')?.[1] ? Number(a?.split('uploadFile')?.[1]) : 0
      const numberB = b?.split('uploadFile')?.[1] ? Number(b?.split('uploadFile')?.[1]) : 0
      return numberA - numberB
    })
    for(let i=0; i<keys?.length; i++){
      // eslint-disable-next-line no-loop-func
      scriptInfosValue[i] = values[keys[i]]?.map((r: any, i: number)=>({...r, fileOrder: (i+1+initLength)}));
      initLength += values[keys[i]]?.length || 0
    }
    return scriptInfosValue?.flat(1)?.filter(i=>i)
  }

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const params = {
        description: values?.description,
        executeMode: values?.executeMode,
        errorHandleMode: values?.errorHandleMode,
        indexFile: parseResult?.indexFileName,
        indexFilePath: parseResult?.indexFilePath,
        scriptInfos: getScriptInfos()
      }
      createBatchSql(params).then((res: any)=>{
        onClose()
        message.success('创建批量执行任务成功')
        history.push({
          pathname:'/download',
          state: {key: "batchExecute"}
        })
        refreshData && refreshData()
      }).catch((err: any)=>{
        console.error('创建批量执行任务失败', err)
      })
    })
  }

  const handleConnectionChange = (value: any, index: number) => {
    const { connectionId, nodePathWithType, connectionType, connectionName } = value || {};
    setMergedScriptInfos((m: any[]) => {
      m?.map((r: any, i: number) => {
        if (index === i) {
          r.connectionId = Number(connectionId);
          r.nodePathWithType = nodePathWithType;
          r.connectionType = connectionType;
          r.connectionName = connectionName;
        }
        return {...r}
      })
      return [...m]
    })
    // 改变连接后重置附件信息
    // form.setFieldsValue({[`uploadFile${index}`]: []})
  }

  // 数据处理 
  const handleDataFormat = (scriptInfos: any[]) => {
    let res: any = {}
    scriptInfos?.sort((a: any, b: any) => a.fileOrder -  b.fileOrder)?.forEach((item: any)=>{
      let resKeysLength = Object.keys(res)?.length || 0
      const preItem: any = resKeysLength ? res[resKeysLength-1]?.[res[resKeysLength-1]?.length - 1] : null
      if(preItem && (preItem?.nodePathWithType === item?.nodePathWithType) && (preItem?.fileOrder+1 === item?.fileOrder)){
        res[resKeysLength-1] = [...res[resKeysLength-1], item]
      }else {
        res[resKeysLength] = [{...item, key: resKeysLength}]
      }
    })
    return res
  }

  // 上传文件夹
  const handleFieldUploadCallback = (value: any[], folderName: string) => {
    setLoading(true)
    parseBatchSqlFile({files: value?.map(i=>i?.originFileObj) }).then((res: any)=>{
      if(res){
        const { scriptInfos=[], warningMessage } = res
        if(warningMessage){
          message.warning(warningMessage.split("\n").map((str: string, index: number) => (
            <div key={str} style={{display: !!index ? 'block' : 'inline-block'}}>{str}</div>
          )))
        }
        // 回显form表单 999 
        const resultObj = handleDataFormat(scriptInfos)
        Object.keys(resultObj)?.forEach((key: string, index: number)=>{
          const { connectionId, nodePathWithType, dataSourceType: connectionType, realConnectionName } = resultObj[key]?.[0] || {};

          form.setFieldsValue({
            [`connection${index}`]: {
              connectionId: Number(connectionId),
              nodePathWithType,
              connectionType,
              connectionName: realConnectionName
            },
            [`uploadFile${index}`]: resultObj[key]?.map((i: any)=>({...i, name: i?.fileName})) 
          })
        })
        // 回显form表单end
        setParseResult(res)  
        setFolderName(folderName || value?.[0]?.name)
        form.setFieldsValue({uploadFolder: value})
      }
    }).catch((err: any)=>{
      console.error('解析文件失败', err)
    }).finally(()=>{
      setLoading(false)
    })
  }

  // 手动上传附件
  const handleManualUpload = (value: any[], preValue: any[], index: number) => {
    !loading && setLoading(true)
    debouncedFunction(value, preValue, index);    
  }

  const debouncedFunction = debounce((value, preValue, index) => {
    manualUploadFn(value, preValue, index);
  }, 300);
  
  const manualUploadFn = (value: any[], preValue: any[], index: number) => {
    const connectionInfo = form.getFieldValue([`connection${index}`])
    const { connectionId, nodePathWithType } = connectionInfo || {};
    parseBatchSqlFile({ files: value?.map(i => i?.originFileObj), connectionId, nodePathWithType, sqlFile: true }).then((res: any) => {
      if(res){
        const { scriptInfos=[], warningMessage } = res
        if(warningMessage){
          message.warning(warningMessage.split("\n").map((str: string, index: number) => (
            <div key={str} style={{display: !!index ? 'block' : 'inline-block'}}>{str}</div>
          )))
        }
        form.setFieldsValue({ [`uploadFile${index}`]: [...preValue, ...scriptInfos?.map((i: any) => ({ ...i, nodePathWithType, name: i?.fileName }))] })
      }else {
        form.setFieldsValue({[`uploadFile${index}`]: [...preValue]}) // 手动重置form表单上传列表
      }
    }).catch((err: any)=>{
      console.error('单个上传解析文件失败', err)
      form.setFieldsValue({[`uploadFile${index}`]: [...preValue]}) // 手动重置form表单上传列表
    }).finally(()=>{
      setLoading(false)
    })
  }

  // 删除单个sql文件
  const handleDeleteRow = (fileIndex: number, index: number) => {
    const uploadFileValue = form.getFieldValue(`uploadFile${index}`)
    const needDeleleItem = uploadFileValue?.find((_: any, i: number)=>fileIndex===i)
    const params = { scriptInfos: [needDeleleItem] }
    handleDeleteUploadSql(params)
    form.setFieldsValue({
      [`uploadFile${index}`]: uploadFileValue?.filter((_: any, i:number)=>fileIndex!==i)
    })
  }

  // 拖拽单个sql文件
  const handleMoveRow = (dragIndex: number, hoverIndex: number, uploadFileKey: string) => {
    const uploadFileValue = form.getFieldValue(uploadFileKey)
    const dragRow = uploadFileValue[dragIndex];
    const updatedFileList = [...uploadFileValue];
    // 过滤不合理的拖拽项
    if(!dragRow) return;
    updatedFileList.splice(dragIndex, 1);
    updatedFileList.splice(hoverIndex, 0, dragRow);
    form.setFieldsValue({[uploadFileKey]: updatedFileList})
  }

  // 模板文件删除
  const handleTemplateRemove = () => {
    Modal.confirm({
      title: '确认删除模板文件吗?删除后所有附件也将清空',
      onOk:()=>{
        form.resetFields()
        setFolderName('')
        setParseResult({})
      }
    })
  }

  // 删除上传sql
  const handleDeleteUploadSql = (params: any) => {
    deleteSqlFile(params).then().catch((err: any)=>{
      console.error('删除上传sql文件失败', err)
    })
  }
  const handleClose = () => {
    const scriptInfos = getScriptInfos()
    if(scriptInfos?.length){
      const params = {
        scriptInfos
      }
      handleDeleteUploadSql(params)
    }
    onClose()
  }

  const validateDescription = (_: any, value: any) => {
    if(value && value?.trim()?.length > 20){
      return Promise.reject('最长不超过20个字符')
    }
    return Promise.resolve()
  }
  return (
    <>
      <Drawer
        title="新建批量执行任务"
        width={1000}
        visible={visible}
        onClose={handleClose}
        maskClosable={false}
        className={styles.createBatchExecuteDrawer}
        footer={
          <>
            {step === 1 ? <Button onClick={handleClose} className='mr10'>取消</Button> : <Button onClick={()=>setStep(1)} className='mr10'>上一步</Button>} 
            {
              step === 1 ? <Button type='primary' onClick={handleNextStep} disabled={loading}>下一步</Button> : <Button type='primary' onClick={handleSubmit}>确定</Button>
            }
          </>
        }
        footerStyle={{display: 'flex', justifyContent: 'flex-end'}}
      >
        <Spin spinning={loading}>
          <Form 
            form={form} 
            {...layout}
          >
            {
              <span style={{display: step===1 ? 'block' : 'none'}}>
                <Form.Item 
                  label="上传文件夹" 
                  name="uploadFolder"
                  extra="支持上传文件夹和压缩文件，文件中支持包含要执行的sql文件和执行顺序文件（索引文件）"
                >
                  <Tooltip title="批量执行仅支持纯文本类型的文件">
                    <Button 
                      className={styles.uploadButtonStyle} 
                      onClick={() => setUploadFolderModalVisible(true)}
                    >
                      点击上传文件夹<UploadOutlined className='ml4' />
                    </Button>
                  </Tooltip>
                </Form.Item>
                {
                  folderName &&
                  <Form.Item wrapperCol={{span: 18, offset: 6}}>
                    <div className='flexAlignCenterBetween' style={{width: 470}}>
                      <span>
                        <PaperClipOutlined className='mr4'/>
                        {folderName}
                      </span>
                      <DeleteOutlined className='options' onClick={()=>handleTemplateRemove()}/>
                    </div>
                  </Form.Item>
                }
                <Divider style={{margin: '24px 0'}} />
                <div className={styles.dataSourceWrap}>
                  <span className={styles.addBtn} style={{top: folderName ? 228: 182}} onClick={handleAddTask}><PlusCircleOutlined /></span>
                  {
                    mergedScriptInfos?.map((item: any, index: number)=>{
                      if(item?.delete){
                        return null
                      }
                      return (
                        <span key={index} style={{position: 'relative'}}>
                          <Form.Item 
                            label="数据库" 
                            name={`connection${index}`}
                            rules={[{ required: true, message: '请选择' }]}
                          >
                            <ElementTreeSelect
                              // value={item}
                              isBatchExecute={true}
                              onChange={(value: any) => { handleConnectionChange(value, index) }}
                            />
                          </Form.Item>
                          <Form.Item 
                            label="附件上传" 
                            name={`uploadFile${index}`}
                            rules={[{ required: true, message: '请选择附件上传' }]}
                          >
                            <CustomDragableUploadList 
                              multiple={true}
                              directory={false}
                              notAllowCompresPack={true}
                              uploadSize={uploadSize}
                              buttonProps={{
                                disabled: !item?.nodePathWithType,
                                style: {width: "470px", border: "1px dashed #DFE1E6", background: "#FBFBFB", marginBottom: 10},
                                buttonText: "上传附件", 
                                tooltipTxt: "批量执行仅支持纯文本类型的文件"
                              }}
                              fileListWidth={470}
                              handleMoveRow={(dragIndex: number, hoverIndex: number)=>handleMoveRow(dragIndex, hoverIndex, `uploadFile${index}`)}
                              handleDeleteRow={(fileIndex: number)=>handleDeleteRow(fileIndex, index)}
                              onChange={(value: any, preValue: any[])=>{handleManualUpload(value, preValue, index)}}
                            />
                          </Form.Item>
                          {
                            mergedScriptInfos?.filter(i=>!i?.delete)?.length > 1 &&
                            <span className='options mb10' style={{position:'absolute', right: '240px', top: '2px'}} onClick={()=>{handleDeleteTask(index)}}>删除</span>
                          }
                        </span>
                      )
                    })
                  }
                </div>
              </span>
            }
            {
              step === 2 &&
              <span style={{display: step===2 ? 'block' : 'none'}}>
                <Form.Item
                  label="执行方式"
                  name="executeMode"
                  rules={[{ required: true, message: '请选择执行方式' }]}
                >
                  <Radio.Group>
                    {
                      ExecuteWayEnums?.map((i: any)=>{
                        return (
                          <Radio value={i?.value} key={i?.value}>{i?.label}</Radio>
                        )
                      })
                    }
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="执行错误处理"
                  name="errorHandleMode"
                  rules={[{ required: true, message: '请选择执行错误处理' }]}
                >
                  <Radio.Group>
                    {
                      ErrorHandleWayEnums?.map((i: any)=>{
                        return (
                          <Radio value={i?.value} key={i?.value}>{i?.label}</Radio>
                        )
                      })
                    }
                  </Radio.Group>
                </Form.Item>
                <Form.Item 
                  label='描述'
                  name='description'
                  rules={[{ validator: validateDescription }]}
                >
                  <Input.TextArea 
                    rows={4} 
                    placeholder='输入描述内容'
                    style={{width: 470}}
                    maxLength={20}
                  />
                </Form.Item>
              </span>
            }
          </Form>
        </Spin>
      </Drawer>
      {/* 上传文件夹 */}
      {
        uploadFolderModalVisible &&
        <UploadFolderModal 
          visible={true}
          uploadSize={uploadSize}
          onClose={() => {
            setUploadFolderModalVisible(false)
          }}
          callback={handleFieldUploadCallback}
        />
      }
    </>
  )
}

export default CreateBatchExecuteDrawer