/**
 * 查看视图结构
 */
import React, { useEffect, useRef, useState } from 'react'
import { useRequest, useSelector } from 'src/hook'
import { AgGridReact } from '@ag-grid-community/react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import {
  GridReadyEvent,
  GridApi,
  ColumnApi,
  ColDef,
  ColGroupDef,
} from '@ag-grid-community/core'
import { Descriptions, Spin, Tabs, Input } from 'antd'
import { viewStructFields, viewStructDDL } from 'src/api'
import { DatabaseInfo } from 'src/pageTabs/queryPage/queryTabs/queryTabsSlice'
import { GridConfigBase } from '../../resultTabs/resultContentGrid'
import styles from './index.module.scss'

const { TabPane } = Tabs


const defaultColDef: ColDef = {
  resizable: true,
  editable: false,
  tooltipValueGetter: ({ value }) => value,
}

interface CreateTablePaneProps {
  databaseInfo: Partial<DatabaseInfo>
  queryTabKey: string
}

export const ViewViewStructurePane = ({
  databaseInfo,
}: CreateTablePaneProps) => {
  const { theme } = useSelector((state) => state.appearance)
  const {
    connectionId,
    connectionType = 'MySQL',
    nodePath,
    nodePathWithType,
    nodeName,
    nodeType,
    databaseName,
  } = databaseInfo
  const { toolbarConnections } = useSelector(state => state.editor)
  const connection = toolbarConnections?.find((t) => t.connectionId === connectionId)
  const connectionName = connection?.alias ? connection.alias : connection?.nodeName


  const [activeTabKey, setActiveTabKey] = useState('field')
  const [rowData, setRowData] = useState<any[]>([])

  const getFieldDefs = () => {
    const baseColumnDefs: ColDef[] = [
      { field: 'columnName', headerName: '字段名', minWidth: 160 },
      { field: 'dataType', headerName: '类型', minWidth: 120 },
      { field: 'length', headerName: '长度', minWidth: 120 },
      { field: 'comment', headerName: '注释', minWidth: 200 },
    ]
    return baseColumnDefs
  }

  const {
    run: queryTableColumns,
    loading: loadingGetTableColumns,
  } = useRequest(
    () => {
      if (!(connectionId && connectionType && databaseName && nodeName)) return
      viewStructFields({
        connectionId,
        connectionType,
        schemaName: databaseName,
        databaseName,
        viewName: nodeName
      }).then((res: any[])=>{
        setRowData(res)
      }).catch((err) => {
        console.error('查询字段信息失败:',err)
      })
    },
    { manual: true },
  )

  const { data: ddlData, run: getDDL, loading: loadingGetDDL } = useRequest(
    () => {
      if (!(connectionId && connectionType && databaseName && nodeName)) return
      return viewStructDDL({
        connectionId,
        connectionType,
        schemaName: databaseName,
        databaseName,
        viewName: nodeName
      })
    },
    {
      manual: true,
    },
  )

  useEffect(() => {
    switch (activeTabKey) {
      case 'ddl':
        getDDL()
        break;
      default:
        queryTableColumns()
        break
    }
  }, [activeTabKey])

  const handleTabChange = (key: string) => {
    setActiveTabKey(key)
  }

  const gridApiRef = useRef<GridApi>()
  const gridColumnApiRef = useRef<ColumnApi>()

  const onGridReady = (params: GridReadyEvent) => {
    gridApiRef.current = params.api
    gridColumnApiRef.current = params.columnApi
    setRowData([])
    gridApiRef.current.sizeColumnsToFit()
  }

  return (
    <Spin spinning={loadingGetTableColumns || loadingGetDDL}>
      <div className={styles.viewStructurePageBody}>
        <Descriptions column={2}>
          <Descriptions.Item label="连接名">{connectionName}</Descriptions.Item>
          <Descriptions.Item label="数据库">{databaseName}</Descriptions.Item>
          <Descriptions.Item label="视图名称">{nodeName}</Descriptions.Item>
        </Descriptions>
        <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
          <TabPane tab="字段" key="field">
            <div  
              style={{height: 'calc(100vh - 316px)', width: '100%'}}
              className={
                theme === 'dark' ? 'ag-theme-balham-dark' : 'ag-theme-balham'
              }
            >
              <AgGridReact
                {...GridConfigBase}
                modules={[ClientSideRowModelModule]}
                columnDefs={getFieldDefs()}
                defaultColDef={defaultColDef}
                rowDragManaged={true}
                animateRows={true}
                onGridReady={onGridReady}
                rowData={rowData}
                rowSelection="single"
              />
            </div>
          </TabPane>
          <TabPane tab="DDL" key="ddl">
            <Input.TextArea
              autoSize={{ minRows: 10, maxRows: 25 }}
              value={ddlData?.ddl}
              readOnly
            />
          </TabPane>
        </Tabs>
      </div>
    </Spin>
  )
}
