import React, { FC, useMemo, useState, useEffect } from 'react'
import {  Dropdown, Input, Tooltip } from 'antd'
import * as _ from 'lodash'
import { useRequest, useSelector } from 'src/hook'
import { useHistory, useLocation } from 'react-router'
import { getUrlSearchParams} from 'src/util'
import { ORDER_MAPPING } from 'src/constants'
import { getAllApproveList, IApplyProps } from 'src/api'
import { SimpleTable, SimpleBreadcrumbs } from 'src/components'

import { SearchHeader, renderMenuList } from '../common'
import { columns } from './columns'
import { getFilteredDatabaseList } from '../utils'
import ModalDataChangeSelectNewApprover from 'src/pageTabs/flowPages/flowDetails/ModalDataChangeSelectNewApprover'
import { SearchOutlined } from '@ant-design/icons'
import styles from './index.module.scss'

interface ListProps {}

export const List: FC<ListProps> = ({ ...rest }) => {
  const defaultSearchParams = {
    currentPage: 1,
    pageSize: 10,
    status: '待审核',
  }

  const history = useHistory()
 const {search} = useLocation<{status: string}>();

  const [searchParams, setSearchParams] =
    useState<IApplyProps>(defaultSearchParams)
    const {
      userInfo={},
    } = useSelector((state) => state.login)
    const { dataSourceList } = useSelector((state) => state.dataSource);

  const [visible_setNewApprover, setVisible_setNewApprover] = useState(false) // 新审批人 modal visible
  const [currentTransferApprover, setCurrentTransferApprover] = useState<
    Array<string>
  >([]) // 新审批人 modal visible
  const [searchValue, setSearchValue] = useState('');

  const { data: managementList, refresh: refreshAllApproveList } = useRequest(
    () => getAllApproveList(searchParams),
    {
      refreshDeps: [searchParams],
      debounceInterval: 300
    },
  )

  // 判断 该登录权限下,该模块 只读/可编辑
  const { permissionList } = useSelector((state) => state?.login)
  const [permissionlist, setPermissionlist] = useState<{isOnlyRead: boolean, roleNameList: any}>()
  const [pathName, setPathName] = useState<string>("DATA_CHANGE_WORK_ORDER_MANAGEMENT")

  useEffect(() => {
    const pathName = (window.location.pathname.split("/")[1].toUpperCase() as keyof typeof permissionList)
    setPathName(pathName)
  
    if (search) {
      const {status} =  getUrlSearchParams(search);

      setSearchParams({
        ...searchParams,
        status: decodeURIComponent(status)
      })
    }
  },[])

  const BREADCRUMB_ITEMS = [
    {
      title: '数据变更',
    },
    {
      title: '工单管理',
    },
  ]

  const getActions = (status: string) => {
    switch (status) {
      case '待审核':
        const DBARoleInfo = userInfo?.roles && userInfo?.roles?.filter((role : any)=> role?.roleId === 3)
        setPermissionlist(permissionList?.DATA_CHANGE?.[pathName as keyof typeof permissionList.DATA_CHANGE])
  
        if (_.isEmpty(DBARoleInfo)) {
          return [{ label: '查看详情', key: 'view' }]
        }
        return [
          { label: '任务转交', key: 'transfer' },
        ]
      default:
        setPermissionlist({
          isOnlyRead: false,
          roleNameList: []
        })
        return [{ label: '查看详情', key: 'view' }]
    }
  }

  const handleInputChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setSearchValue(e?.target?.value);
    setSearchParams({
      ...searchParams,
      keyword: e?.target?.value as string,
    })
  }

  const newColumns = useMemo(() => {
    const actions = getActions(searchParams.status)

    const newColumns = columns.map((item) => {
      if (item.dataIndex === 'nodePath') {
        return {
          ...item,
          filterMultiple: false,
          filters: getFilteredDatabaseList(dataSourceList).map((item: any) => ({
            //@ts-ignore
            text: item?.dataSourceName,
            value: item?.dataSourceName,
          })),
        }
      }
      if (item.dataIndex === 'actions') {
        return {
          ...item,
          render: (_: any, record: any) => (
            <Tooltip title={
              permissionlist?.isOnlyRead?
              `您当前的角色是[${permissionlist?.roleNameList.join(", ")}], 对[工单管理]没有操作权限`
              : null
              } 
            >
              <Dropdown
                overlay={renderMenuList({
                  menus: actions,
                  onChange: (key) => {
                    switch (key) {
                      case '查看详情':
                        history.push(
                          {
                            pathname: `/data_change_work_order_management/${record.id}/detail`, 
                            state: { 
                              flowTaskId: record?.flowTaskId, 
                              orderType: 'FLOW_WORK_ORDER_MANAGEMENT' 
                            },
                          })
                        break
                      case '任务转交':
                        setVisible_setNewApprover(true)
                        setCurrentTransferApprover([record.flowTaskId]) //应该使用当前userId
                        break
                      default:
                        break
                    }
                  },
                })}
                disabled={permissionlist?.isOnlyRead}
              >
                <div style={{ cursor: 'pointer' }}>... </div>
              </Dropdown>
            </Tooltip>
          ),
        }
      }
      return item
    })

    //样式
    return newColumns
  }, [searchParams?.status, permissionlist?.isOnlyRead])

  return (
    <>
      <div className={styles["extra-action"]}>
        <SimpleBreadcrumbs items={BREADCRUMB_ITEMS} />
        <Input
          style={{ width: "200px" }}
          prefix={<SearchOutlined className="site-form-item-icon" />}
          placeholder="搜索标题"
          value={searchValue}
          onChange={handleInputChange}
          allowClear
        />
      </div>
      <div>
        <SearchHeader
          searchParams={searchParams}
          refresh={() => refreshAllApproveList()}
          setSearchParams={(params: any) =>{
              history.push(`/data_change_work_order_management?status=${params?.status}`)
              setSearchParams({
                ...searchParams,
                ...params,
                currentPage: 1
              })
            }
           
          }
        />
        <SimpleTable
          size="small"
          rowKey="flowTaskId"
          //@ts-ignore
          columns={newColumns}
          total={managementList?.total || 0}
          dataSource={managementList?.content || []}
          searchParams={searchParams}
          setSearchParams={(params: any) =>{
 
            let translateParams = params;
            if (params?.type) {
              translateParams.type = Number(params?.type?.[0]);
            }
            if (params?.applyTime) {
              translateParams.sortItem = 'applyTime';
              //@ts-ignore
              translateParams.sortType = ORDER_MAPPING[params.applyTime];
              delete translateParams?.applyTime;
            }
            if (params?.executeStatus) {
              translateParams.executeStatus = params?.executeStatus?.[0];
            }
            if (params?.executeTime) {
              translateParams.sortItem = 'executeTime';
              //@ts-ignore
              translateParams.sortType = ORDER_MAPPING[params.executeTime];
            }
              translateParams.dataSourceType = params?.nodePath?.[0];
              delete translateParams?.nodePath;
              delete translateParams?.executeTime;
            setSearchParams({ ...translateParams })
          }}
        />
      </div>
      <ModalDataChangeSelectNewApprover
        cleanParentComponentData={() => {
          setCurrentTransferApprover([])
          setSearchParams(defaultSearchParams)
        }}
        userTasks={currentTransferApprover}
        visible_setNewApprover={visible_setNewApprover}
        setVisible_setNewApprover={setVisible_setNewApprover}
      />
    </>
  )
}
