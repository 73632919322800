import { NodeTypeKey } from "./DataProtectionMain/type"

const TABS_PANE = {
  DESENS: '脱敏字段',
  DATA_INFO: '脱敏数据',
  FILTER_ROW: '行过滤设置',
  // HIGHRISKFILTER: '红线数据过滤',
  SCAN: '脱敏扫描',
  SECURITY: '安全设置',
}

const DEFAULT_KEY = Object.keys(TABS_PANE)[0]

// 脱敏算法
const RULES_MAP: Record<string, string> = {
  REPLACE: '替换',
  RANDOM_FLOATING: '随机浮动',
  RANDOM_IN_RANGE: '范围内随机',
  SUB: '截取',
  ENCRYPTION: '加密',
  HIDE: '隐藏',
  DATA_SIMULATION: '数据仿真',
  FILTER: '过滤',
}
//脱敏字段批量操作
const DESENS_BATCH_ACTIONS:Record<string, string> = {
  EXPORT: '批量导出',
  DELETE: '批量删除',
  POLICY: '批量设置策略',
  TRUE: '批量启用',
  FALSE: '批量禁用'
}
const DESENS_STATUS:Record<string, string> = {
  ALL: '全部',
  TRUE: '启用',
  FALSE: '关闭'
}

const DESENS_SCAN_TASK_STATUS:Record<number, string> = {
 0: '停止',
 1: '运行',
 2: '正在执行',
 3: '异常',
 4: '完成'
}

// 只有安全设置需要，包含所有，除了 datasource, connection, group, database,schema,oracleUser,tableGroup,table
const SECURITY_CONFIG: NodeTypeKey[] = [
  'root',
  'connectionGroup',
  'catalog',
  'view',
  'column',
  'viewGroup',
  'functionGroup',
  'udfGroup',
  'udf',
  'storedProcedureGroup',
  'favoriteGroup',
  'columnGroup',
  'redisDataBase',
  'redisKey',
  'file',
  'collection',
  'function',
  'procedure',
  'synonym',
  'synonyms',
  'sequence',
  'package',
  'type',
  'packageBody',
  'materializedView',
  'trigger',
  'triggerGroup',
  'foreignTable',
  'syncMaterializedView',
  'asyncMaterializedView',
  'other',
  'materializedViewGroup',
  'syncMaterializedViewGroup',
  'asyncMaterializedViewGroup',
  'routineLoad',
  'routineLoadGroup',
  'procedureGroup',
  'job',
  'jobs',
  'jobGroup',
  'jobsGroup',
  'index',
  'indexGroup',
  'constraint',
  'constraintGroup',
  'packageGroup',
  'packageBodyGroup',
  'synonymGroup',
  'dbLink',
  'dbLinkGroup',
  'foreignTableGroup',
  'dictionary',
  'dictionaryGroup',
  'collectionGroup',
  'redisKeyGroup',
  'fileGroup',
  'foreignKey',
  'foreignKeyGroup',
  'flexTable',
  'flexTableGroup',
  'part',
  'partGroup',
  'GridFs',
  'GridFsGroup',
  'sequencesGroup',
  'sequenceGroup',
  'synonymsGroup',
  'typeGroup',
]

export {
  TABS_PANE,
  DEFAULT_KEY,
  RULES_MAP,
  DESENS_BATCH_ACTIONS,
  DESENS_STATUS,
  DESENS_SCAN_TASK_STATUS,
  SECURITY_CONFIG
}


