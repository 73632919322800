import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useRequest, useSelector } from 'src/hook'
import { TreeSelect } from 'antd'
import {
  getFlowConnectionList,
  getFlowConnectionNodes,
  getBatchExecuteConnectionNodes,
  FlowElementTreeNodeEntity,
  getBatchExecuteConnectionList,
} from 'src/api'
import { Iconfont, RenderDatabaseFieldIcon } from 'src/components'
import type { LegacyDataNode } from 'rc-tree-select/lib/interface'

/** 元素节点元信息 */
type ElementNodeProps = Pick<
  FlowElementTreeNodeEntity,
  | 'connectionId'
  | 'connectionType'
  | 'nodeName'
  | 'nodePath'
  | 'nodePathWithType'
  | 'nodeType'
  | 'hasChild'
>

interface IValueProps {
  connectionId: string | number,
  nodePathWithType: string,
  connectionType: string,
  connectionName: string
  nodeType?: string,
}

interface ElementTreeSelectProps {
  value?: IValueProps
  isBatchExecute?: boolean;
  onChange: (value: IValueProps) => void
}

export const ElementTreeSelect: React.FC<ElementTreeSelectProps> =
  React.memo(({ value, isBatchExecute = false, onChange }) => {
    const { connectionType, connectionName } = value || {}

    // onchange出去数据结构{connectionId,nodePathWithType},进来也一样
    const [treeExpandedKeys, setTreeExpandedKeys] = useState<React.Key[]>([])
    const [treeLoadedKeys, setTreeLoadedKeys] = useState<React.Key[]>([])

    // tree map, 记录元素树节点的引用
    const treeMapRef = useRef(new Map<string, LegacyDataNode>())

    // ! 后续后端应该从 cookie 获取用户信息，不从表单传参
    const { userId } = useSelector((state) => state.login.userInfo)
    // 原先是根据 nodePathWithType 进行图标匹配，但是部分数据源database层级nodePathWithType对应返回的是schame
    // 因此获取选中的节点信息，使用nodeType进行图标匹配
    const [selectNodeInfo, setSelectNodeInfo] = useState<{value: string, node: any}>({value: '', node: {}})

    const mapDataToTreeNode = useCallback((data: FlowElementTreeNodeEntity) => {
      // 从 data 中解构出需要的参数
      const {
        connectionId,
        connectionType,
        nodeName,
        nodePath,
        nodePathWithType,
        nodeType,
        hasChild,
      } = data
      const nodeProps: ElementNodeProps = {
        connectionId,
        connectionType,
        nodeName,
        nodePath,
        nodePathWithType,
        nodeType,
        hasChild,
      }

      const treeNode = {
        key: nodePathWithType,
        value: nodePathWithType,
        title: nodeName,
        icon: <Iconfont type={`icon-${nodeType}`} />,
        props: nodeProps,
        isLeaf: !hasChild,
        nodeType: nodeType,
      }
      treeMapRef.current.set(nodePathWithType, treeNode)
      return treeNode
    }, [])

    // 获取元素树连接层级
    const {
      data: root,
      run: fetchConnections,
      mutate: setRoot,
    } = useRequest((params) => isBatchExecute ?getBatchExecuteConnectionList(params) : getFlowConnectionList(params), {
      manual: true,
      formatResult: (data) => {
        // 构造与子节点结构统一的连接节点
        const root = data.map((node) => {
          const {
            connectionId,
            nodeName,
            nodeType,
            nodePath,
            nodePathWithType,
            connectionType,
            hasChild,
          } = node
          const icon = `icon-connection-${connectionType}`
          const nodeProps: ElementNodeProps = {
            connectionId,
            connectionType,
            nodePath,
            nodePathWithType,
            nodeType,
            nodeName,
            hasChild,
          }
          const treeNode = {
            key: nodePathWithType,
            value: nodePathWithType,
            title: nodeName,
            icon: <Iconfont type={icon} />,
            props: nodeProps,
            selectable: true,
            isLeaf: !hasChild,
            nodeType: nodeType,
          }
          treeMapRef.current.set(nodePathWithType, treeNode)
          return treeNode
        })
        return root
      },
    })
    useEffect(() => {
      if (userId) {
        fetchConnections({ flowType: 'dataCorrection', userId })
      }
    }, [fetchConnections, userId])

    // 获取数据库元素节点子列表
    const { run: loadChildren } = useRequest((params) => {
      return isBatchExecute ? getBatchExecuteConnectionNodes(params): getFlowConnectionNodes(params)
    }, {
      manual: true,
      fetchKey: (params) => params.nodePathWithType,
      formatResult: (data) => data.map(mapDataToTreeNode),
    })

    const labeledValue = useMemo(() => {
      const { nodePathWithType } = value || {}
      if (nodePathWithType) {
        return {
          value: nodePathWithType,
          label: <div style={{ display: 'flex', alignItems: 'center' }}>
            {RenderDatabaseFieldIcon(nodePathWithType, connectionType, connectionName, selectNodeInfo?.node?.nodeType)}
          </div>,
        }
      }
    }, [value, selectNodeInfo])

    const handleSelect = (value: any, node: any) => {
      setSelectNodeInfo({ value: value, node: node })
    }

    const handleChange = (v: any) => {
      if (!v) {
        // 清空值的时候
        onChange(v);
        return;
      }
      const { value: cvalue } = v || {};
      const connectionInfo = cvalue.split(':');
      let connectionId: any;
      for (let i = 0; i < connectionInfo.length; i++) {
        if (connectionInfo[i] === '/CONNECTION') {
          connectionId = connectionInfo[i + 1]?.split('/')[0];
        }
      }
      let connectionExtraInfo = { connectionType: '', connectionName: '' };
      if (root?.length) {
        for (let i = 0; i < root?.length; i++) {
          const { props } = root[i] || {};
          const { connectionType, nodeName, connectionId: id } = props || {};
          if (connectionId == id) {
            connectionExtraInfo = { connectionType, connectionName: nodeName }
          }
        }
      }
      onChange?.({ connectionId, nodePathWithType: cvalue, ...connectionExtraInfo })
    }

    const handleLoadData = async (node: any): Promise<any> => {
      const key = node.key as string
      const props = node.props as ElementNodeProps
      try {
        const children = await loadChildren({
          ...props,
          flowType: 'dataCorrection',
          userId,
        })
        const targetNode = treeMapRef.current.get(key)
        if (!targetNode) return
        targetNode.children = children
        setRoot((root) => [...root])
        // 懒加载成功，加入 loadedKeys
        setTreeLoadedKeys((keys) => [...keys, key])
      } catch {
        // 懒加载失败，自动收起节点
        setTreeExpandedKeys((keys) => keys.filter((el) => el !== key))
      }
    }

    return (
      <TreeSelect
        placeholder="请选择数据库元素"
        treeData={root}
        loadData={handleLoadData}
        value={labeledValue}
        onChange={handleChange}
        onSelect={handleSelect}
        treeExpandedKeys={treeExpandedKeys}
        onTreeExpand={setTreeExpandedKeys}
        treeLoadedKeys={treeLoadedKeys}
        treeNodeFilterProp="title"
        showSearch
        treeIcon
        allowClear
        labelInValue
        style={{ width: 470 }}
        dropdownStyle={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        }}
      />
    )
  })
