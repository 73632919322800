import React, { useEffect, useMemo, useState } from 'react'
import { SearchOutlined, PlusOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { Card, Button, Input, } from 'antd'
import { Link } from 'react-router-dom'

import * as _ from 'lodash';
import classnames from 'classnames'
import { ResizableBox, ResizableProps } from 'react-resizable'

import { useDispatch, useRequest, useSelector } from 'src/hook'
import { Iconfont } from "src/components"
import {
  refreshOnRoot,
  setNewTreeData,
  fetchConnections,
} from './appliedResourceListSlice'
import TreeComponent from './TreeComponent'
import { AboveSchemaLevelContent } from './AboveSchemaLevelContent';
import { SubSchemaLevelContent } from './SubSchemaLevelContent';
import styles from './index.module.scss'

const ResizableBoxProps: ResizableProps = {
  axis: 'x',
  width: 320,
  height: 0,
  minConstraints: [260, 0],
  maxConstraints: [620, 0],
}

const AppliedResourceList = ({
  flowMainUUID //申请单号
}: {
  flowMainUUID: string | undefined;
}) => {

  const history = useHistory()
  const dispatch = useDispatch()

  const { permissionList } = useSelector((state) => state?.login)
  const { newTreeData, selectedTreeNode, } = useSelector(state => state.appliedResourceList);

  const [searchValue, setSearchValue] = useState<string>("");
  const [rightWrapWidth, setRightWrapWidth] = useState<string>("");
  const [permissionlist, setPermissionlist] = useState<{ isOnlyRead: boolean, roleNameList: any }>()

  useEffect(() => {

    if (!flowMainUUID) return
    dispatch(fetchConnections({flowMainUUID}))

    return () => {
      dispatch(setNewTreeData([]))
    }
  }, [dispatch, flowMainUUID])

  useEffect(() => {
    handleLeftWrapResize();

    const pathName = (window.location.pathname.split("/")[1].toUpperCase() as keyof typeof permissionList)
    setPermissionlist(permissionList?.DATABASE_MANAGEMENT?.[pathName])
  }, []);

  const handleSearch = (e: any) => {
    const value = e.target.value;
    setSearchValue(value?.trim());
  };

  const handleLeftWrapResize = () => {
    // @ts-ignore
    const lWidth = document.getElementsByClassName("react-resizable")[0]?.style.width ||
      "320px";
    const width = `calc(100vw - 30px - ${lWidth} )`;
    setRightWrapWidth(width);
  };

  const renderResizeHandle = (
    <div className={styles.resizeHandle}>
      <Iconfont type="icon-handle-8"></Iconfont>
    </div>
  );


  return (
    <Card
      title="申请资源"
      className={styles.borderShow}
      extra={
        <Button type="primary">
          <Link to="/mine_apply/search">
            <PlusOutlined /> &nbsp;&nbsp;添加资源
          </Link>
        </Button>
      }
    >
      <div className={styles.groupAuthorizationWrap}>
        <div className={styles.content}>
          <ResizableBox
            className={styles.resizableBox}
            handle={renderResizeHandle}
            onResize={handleLeftWrapResize}
            {...ResizableBoxProps}
          >
            <div className={styles.leftWrap}>
              {/* 搜索框 */}
              <Input
                className={classnames(styles.searchBtn, styles.mb10)}
                prefix={<SearchOutlined />}
                placeholder="请输入连接名/IP地址"
                allowClear
                onChange={handleSearch}
                value={searchValue}
              />

              {/* 树形目录 */}
              <TreeComponent
                searchValue={searchValue}
                flowMainUUID={flowMainUUID}
                permissionlist={permissionlist}
              />
            </div>
          </ResizableBox>
          <div className={styles.rightWrap} style={{ width: rightWrapWidth }}>
            <div className={styles.operationContent}>
              <div className={styles.contentDetail}>
                {
                  ['datasource', 'connection', 'database', 'schema', 'oracleUser'].includes(selectedTreeNode?.nodeType) &&
                  <AboveSchemaLevelContent
                    selectTreeItem={selectedTreeNode}
                    flowMainUUID={flowMainUUID}
                  />
                }
                {/* 对象级权限设置 */}
                {
                  selectedTreeNode?.nodeType?.toUpperCase()?.endsWith("GROUP") &&
                  <SubSchemaLevelContent
                    selectTreeItem={selectedTreeNode}
                    flowMainUUID={flowMainUUID}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}


export default AppliedResourceList
