// 申请信息
import { Card, Descriptions, Table } from 'antd';
import React, { FC, useEffect, useState, useMemo, useContext } from 'react';
import { FormContext } from '../ApplyFormContext';
import styles from "./index.module.scss"
import { RcSegmented, SegmentedValue } from 'src/components';
import { DownloadOutlined, PaperClipOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { downloadSqlFile } from 'src/api';


export const SqlCard: FC = () => {

  const { currentContextValue } = useContext(FormContext);
  const [currentSqlTabType, setCurrentSqlTbaType] = useState<SegmentedValue>('sqlStatement');

  const [sqlType, setSqlType] = useState(1);

  useEffect(() => {
    setSqlType(currentContextValue?.sqlType);
    generateData();
  }, [currentContextValue, currentContextValue?.sqlType]);


  const SQL_TYPE_TABS = [
    {
      label: '执行SQL',
      value: 'sqlStatement'
    },
    {
      label: '回退SQL',
      value: 'rollbackSqlStatement'
    }
  ]

  const columns = [
    {
      title: '行',
      dataIndex: 'index',
      key: 'index',
      width: "9%",
    },
    {
      title: 'SQL',
      dataIndex: currentSqlTabType,
      key: currentSqlTabType,
      width: "91%",
      ellipsis: true,
    },
  ];

  // 下载文件
  const handleDownloadFile = (filePath: string) => {
    downloadSqlFile({attachPath:filePath}).then().catch((err: any)=>{
      console.error('下载文件失败', err)
    })
  }

  const generateData = () => {
    const arr = currentContextValue?.[currentSqlTabType]?.split(';')?.map((query: string) => query.trim())?.filter((query: string) => query !== '');
    return arr?.map((items: any, index: number) => {
      return {
        key: index,
        index: (index + 1),
        [currentSqlTabType]: items,
      }
    })
  }

  const renderFile = (fileName: string, index: number) => {
    return (
      <div className={styles.fileList} key={index}>
        <span>
          <PaperClipOutlined className='mr4'/>
          {fileName}
        </span>
        <DownloadOutlined className="options" onClick={()=>{handleDownloadFile(`/opt/cloudquery/data/userData/SqlFile/${fileName}`)}} />
      </div>
    )
  }

  return (
    <Card
      title="SQL文本"
      style={{ width: "100%" }}
      extra={
        sqlType === 1 && <RcSegmented options={SQL_TYPE_TABS} onChange={(type: SegmentedValue) => setCurrentSqlTbaType(type)} />
      }
      className={classnames(styles.sqlCard, styles.cardBorder)}
    >
      { // SQL文本
        sqlType === 1 && 
        <Table
          columns={columns}
          dataSource={generateData()}
          size='small'
          bordered={true}
          pagination={false}
          scroll={{x: "100%", y: "300px"}}
        />
      }
      { // SQL附件
        sqlType === 2 && 
        <Descriptions title="" column={1}>
          <Descriptions.Item label="执行SQL">{renderFile(currentContextValue?.sqlStatement, 0)}</Descriptions.Item>
          {/* 回退sql 不是必填项 */}
          <Descriptions.Item label="回退SQL">
            {
              currentContextValue?.rollbackSqlStatement?
              renderFile(currentContextValue?.rollbackSqlStatement, 1)
              : '-'
            }
          </Descriptions.Item>
        </Descriptions>
      }
      
    </Card>
  )
}

export default SqlCard;