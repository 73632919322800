/* eslint-disable no-template-curly-in-string */
// 策略Modal
import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  AutoComplete,
  Input,
  Button,
  message,
  Space,
  Tag,
} from "antd";
import { useModal, useDispatch, useSelector } from "src/hook";
import { getPermEnum, testUserPerm } from "src/api";
import UserTreeSelect from "./UserTreeSelect";
import { resetDesensPolicySettingObj } from "../../DataProtectionPageSlice";

const { TextArea } = Input;

interface IProps {
  policy?: string; // 策略内容
  [p: string]: any;
}
export const PolicySettingModal = (props: IProps) => {
  const { desensPolicySettingObj = {} } = useSelector(
    (state) => state.dataProtection
  );
  const dispatch = useDispatch();
  const { isBatch = false, policy = "", callback } = desensPolicySettingObj;
  const { visible, closeModal } = useModal("DesensPolicySettingModal");
  const [form] = Form.useForm();
  const [userParams, setUserParams] = useState<any[]>([]);
  const [policyValue, setPolicyValue] = useState<string>("");
  const [result, setResult] = useState({
    msg: "",
    status: undefined,
  });

  useEffect(() => {
    setPolicyValue(policy);
  }, [policy]);

  useEffect(() => {
    form.setFieldsValue({ policy: policyValue });
  }, [form, policyValue]);

  const queryPermEnum = () => {
    getPermEnum()
      .then((res: any[]) => {
        setUserParams(
          res?.map(({ key, desc }) => ({
            label: (
              <Space key={key}>
                <span>{key}</span>
                {desc && <Tag color="blue">{desc}</Tag>}
              </Space>
            ),
            value: key,
          }))
        );
      })
      .catch((err: any) => {
        console.error("添加查询失败：", err);
      });
  };

  // 保存
  const handleOk = () => {
    form.validateFields().then((values: any) => {
      callback(policyValue);
      handleClose();
      setResult({
        msg: "",
        status: undefined,
      })
    });
  };

  const handleClose = () => {
    closeModal?.();
    form.resetFields();
    dispatch(resetDesensPolicySettingObj());
    setResult({
      msg: "",
      status: undefined,
    })
  };

  const handleUserChange = (value: any) => {
    form.setFieldsValue({ userId: value });
  };

  // 测试参数
  const handleTest = () => {
    form.validateFields().then((values: any) => {
      const params = {
        userId: values?.userId,
        policy: values?.policy,
      };
      testUserPerm(params)
        .then((res) => {
          const { result, userInfo } = res;
          result && message.success("测试成功");
          setResult({
            status: result,
            msg: result ? "" : userInfo,
          });
        })
        .catch((err: any) => {
          console.log("测试出错：", err);
        });
    });
  };

  const handleSelect = (value: string) => {
    setPolicyValue((prev) => {
      return `${prev}${value.slice(1)}`;
    });
  };

  const handleTextAreaChange = (e: any) => {
    const value = e.target.value?.trim();
    setPolicyValue(value);
    if (value.endsWith("$")) {
      queryPermEnum();
    } else {
      setUserParams([]);
    }
  };

  const descInfo = [
    "1、运算符应使用 <、>、<=、>=、==、!=",
    "2、${TIME.DayOfWeek}应填数字，周四使用${TIME.DayOfWeek} == 4",
    "3、${TIME.TimeOfDay}应填数字，下午两点半使用${TIME.TimeOfDay} == 1430",
    "4、${TIME.DayOfMonth}应填数字，二十七日使用${TIME.DayOfMonth} == 27",
    "5、${TIME.FullTime}应填数字，二〇二三年七月二十七日下午两点半使用${TIME.FullTime} == 202307271430",
    "6、示例：(${USER.UserId} == \"admin\" and ${USER.Phone}== 18000000054) || ( ${USER.Gender}== '男' or TIME < 123456789 or ${USER.Dept} == 'cqUser')",
  ];

  return (
    <Modal
      width={680}
      title={isBatch ? "批量设置策略" : "编辑策略"}
      visible={visible}
      onOk={handleOk}
      onCancel={handleClose}
    >
      <Form
        form={form}
        autoComplete="off"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="条件"
          name="policy"
          rules={[{ required: true, message: "请输入条件" }]}
          extra={
            <>
              {descInfo.map((i, index) => (
                <div key={index}>{i}</div>
              ))}
            </>
          }
        >
          <AutoComplete
            value={policyValue}
            style={{ width: 320 }}
            options={userParams}
            onSelect={handleSelect}
          >
            <TextArea
              rows={3}
              value={policyValue}
              onChange={handleTextAreaChange}
              placeholder="请输入条件"
            />
          </AutoComplete>
        </Form.Item>
        <Form.Item label="测试" name="userId">
          <UserTreeSelect style={{ width: 320 }} onChange={handleUserChange} />
          <Button
            type="primary"
            style={{ marginLeft: 20 }}
            onClick={handleTest}
          >
            测试一下
          </Button>
        </Form.Item>
        <Form.Item label="测试结果">
          {result?.status !== undefined && (result?.status ? "成功" : "失败")}
        </Form.Item>
        {result?.msg && (
          <div style={{ margin: "8px 20px 8px 94px", color: "red" }}>
            {result.msg}
          </div>
        )}
      </Form>
    </Modal>
  );
};
