import React, { useEffect, useMemo, useState } from 'react'
import { Layout, Input, Table, message, Popconfirm, Modal, Tooltip, Button, Dropdown, Space, Menu } from 'antd'
import { useRequest, useSelector, useTableRecordContext } from 'src/hook'
import { IRecordContext } from 'src/hook/useTableRecordContext'
import { CreateButton, SimpleBreadcrumbs } from 'src/components'
import { DownOutlined, PlusSquareOutlined, SearchOutlined } from '@ant-design/icons'
import ParameterModal from './parameterModal'
import { deleteUserParameters, editSysRoles, getSysRoles, getUserParametersSearch, relationUserParameters } from 'src/api'
import styles from './userParameter.module.scss'
import 'src/styles/layout.scss'
import PermissionTooltip from 'src/components/PermissionTooltip/PermissionTooltip'

const { Header, Content } = Layout

const baseColumns = [
  {
    title: '参数名称',
    dataIndex: 'parameterName',
    width: '15%',
    ellipsis: true
  },
  {
    title: '参数标签',
    dataIndex: 'tagName',
    ellipsis: true,
    width: '15%',
    render: (text: string) => <span> {'${' + text + '}'}</span>
  },
  {
    title: '备注',
    dataIndex: 'description',
    render: (description: string) => {
      return <p>{description ? description : '-'}</p>
    },
    width: '20%'
  },
  {
    title: '默认值',
    key: 'defaultValue',
    dataIndex: 'defaultValue',
    render: (description: string) => {
      return <p>{description ? description : '-'}</p>
    },
    width: '20%'
  },
  {
    title: '必填项',
    dataIndex: 'needed',
    render: (needed: boolean) => {
      return <p>{needed === true ? '是' : '否'}</p>
    },
    width: '10%'
  }
]

const lookColumns = [
  {
    title: '数据源',
    dataIndex: 'dataSourceType'
  },
  {
    title: '对象路径',
    dataIndex: 'objectPath'
  },
  {
    title: '对象名称',
    dataIndex: 'objectName'
  },
  {
    title: '过滤规则',
    dataIndex: 'filterRule'
  },
  {
    title: '状态',
    dataIndex: 'status',
    render: (status: boolean) => {
      return <p>{status ? '启用' : '禁用'}</p>
    }
  }
]


export const RecordContext = React.createContext<IRecordContext>({
  mode: 'create',
})

export const UserParameterP = () => {
  /* 查看过滤关联 */
  const [filterRelevance, setFilterRelevance] = useState<string>('')
  const [filterModalVisible, setFilterModalVisible] = useState<boolean>(false)

  /* 编辑/新增 modal */
  const [creatMoalVisible, setCreateModalVisible] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [current, setCurrent] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [pageNum, setPageNum] = useState<number>(0)

  /* 搜索项 */
  const [search, setSearch] = useState<string>('')
  const searchRender = useMemo(
    () => (
      <Input
        prefix={<SearchOutlined />}
        placeholder="输入关键词"
        value={search}
        onChange={(e) => {
          setCurrent(1)
          setPageNum(0)
          setSearch(e.target.value)
        }}
        className={styles.search}
      ></Input>
    ),
    [search],
  )

  // 判断：该登录权限是否 只读/可编辑
  const { permissionList } = useSelector((state) => state?.login)
  const [permissionlist, setPermissionlist] = useState<{isOnlyRead: boolean, roleNameList: any}>()
  useEffect(() => {
    // login返回的key 与路由不一致，
    // const pathName = (window.location.pathname.split("/")[1].toUpperCase() as keyof typeof permissionList)
    // setPermissionlist(permissionList?.SYSTEM_MANAGEMENT?.[pathName])
    setPermissionlist(permissionList?.SYSTEM_MANAGEMENT?.USER_MANAGEMENT)
  }, []);

  /* 用户参数 */
  const { data: filterData, loading, refresh, } = useRequest(() => getUserParametersSearch({ parameterName: search, pageNum, pageSize }), {
    refreshDeps: [search, pageNum, pageSize],
    formatResult: (data) => {
      const filterData = data?.content
      if (filterData && !filterData?.length && data?.pageNum != 0) {
        setPageNum(Number(data?.pageNum - 1))
        setCurrent(Number(data?.pageNum))
      } else {
        setPageNum(Number(data?.pageNum))
        setCurrent(Number(data?.pageNum) + 1)
      }
      setPageSize(Number(data?.pageSize))
      setTotal(data?.totalElements)
      return filterData
    },
  })

  // 角色列表
  const {
    data: roles,
  } = useRequest((name: string = '') => getSysRoles({ type: 'SYSTEM_ROLE', name }))

  // 修改角色
  const {
    run: editSysRolesRun,
  } = useRequest((defaultValue: string | number = '') => editSysRoles({
    id: 1,
    parameterName: '系统角色',
    tagName: 'SystemRole',
    defaultValue, // 选择的角色的id roleId
    needed: false
  }), {
    manual: true,
    onSuccess: () => {
      message.success(`编辑成功`);
      refresh()
    },
    onError: (err: any) => {
      message.error(`编辑失败: ${err}`)
    }
  })

  /* 新建/编辑 */
  const { recordContext, setEditContext, setCreateContext } =
    useTableRecordContext(refresh, 'parameterModal')


  /* 删除 */
  const { run: runDelete } = useRequest(deleteUserParameters, {
    manual: true,
    onSuccess: () => {
      // setPageNum(Number(0))
      // setCurrent(Number(1))
      message.success('删除成功')
      refresh()
    },
  })

  /* 查看 */
  const { data: ralationData, run: runRelation } = useRequest(relationUserParameters, {
    manual: true,
    formatResult: (data) => {
      return data
    }
  })

  const completeColumns = useMemo(() => {
    const extraColumns = [
      {
        title: '操作',
        render: (record: any) => (
          <>
          {
            permissionlist && 
              <PermissionTooltip
                permissionlist={permissionlist}
                title={"用户管理"}
              >
                <Button
                  type='link'
                  disabled={record?.accessMode === 'AUTO' ? true : (record?.accessMode === 'EDIT_ONLY' ? false : (record?.accessMode === 'DELETE_ONLY' ? true : (record?.accessMode === 'EDIT_DELETE' ? false : true))) || permissionlist?.isOnlyRead}
                  onClick={() => {
                    setCreateModalVisible(true)
                    setEditContext(record)
                  }}
                  style={{ paddingLeft: 0}}
                >编辑</Button>
                <Popconfirm
                  title={`确定删除：${record?.parameterName}？`}
                  onConfirm={() => record?.tagName && runDelete(record.tagName)}
                  disabled={record?.accessMode === 'AUTO' ? true : (record?.accessMode === 'EDIT_ONLY' ? true : (record?.accessMode === 'DELETE_ONLY' ? false : (record?.accessMode === 'EDIT_DELETE' ? false : true))) || permissionlist?.isOnlyRead}
                >
                  <Button type='link' danger disabled={record?.accessMode === 'AUTO' ? true : (record?.accessMode === 'EDIT_ONLY' ? true : (record?.accessMode === 'DELETE_ONLY' ? false : (record?.accessMode === 'EDIT_DELETE' ? false : true))) || permissionlist?.isOnlyRead}
                  >删除</Button>
                </Popconfirm>
              </PermissionTooltip>
          }
            
          </>
        ),
        width: '20%',
      },
    ]


    // 渲染菜单项
    const rolesMenu = () => {
      const handleEditSysRoles = (roleId: string | number) => {
        editSysRolesRun(roleId);
      };

      return (
        <Menu onClick={({ key }) => handleEditSysRoles(key)}>
          {roles?.map((item: any) => (
            <Menu.Item key={item?.roleId}>
              {item?.roleName}
            </Menu.Item>
          ))}
        </Menu>
      );
    };
    
    const newBaseColumns = baseColumns?.map((item: any) => {
      if(item?.dataIndex === 'defaultValue') {
        return {
          ...item,
          render: (val: any, record: any) => {
            if (record?.tagName === "SystemRole") {
              return (
                <Dropdown overlay={() => rolesMenu()}>
                  <Space>
                    {record?.defaultValue || '普通用户'}
                    <DownOutlined style={{color: '#3262ff'}}/>
                  </Space>
                </Dropdown>
              )
            }
            return <p>{val ? val : '-'}</p>
          }
        }
      }
      return item
    })
    return [...newBaseColumns, ...extraColumns]
  }, [permissionlist, JSON.stringify(roles)])

  const handleParameterModal = () => {
    setPageNum(Number(0))
    setCurrent(Number(1))
    refresh()
  }

  const tableRender = () => {
    return (
      <Table
        columns={completeColumns}
        dataSource={filterData}
        loading={loading}
        rowKey="id"
        pagination={{ total, current, pageSize, size: 'small', showSizeChanger: true }}
        onChange={(value) => {
          const { current, pageSize } = value
          setCurrent(current as number)
          setPageNum(Number(current) - 1)
          setPageSize(Number(pageSize))
          // refresh()
        }}
        size="small"
      ></Table>
    )
  }

  const BREADCRUMB_ITEMS = [
    {
      title: '系统管理',
    },
    {
      title: '用户管理',
      href: '/person_management',
    },
    {
      separator: '/',
      title: '用户参数管理',
    },
  ]

  return (
    <Layout className="cq-container">
      <Header className="cq-header">
        <div style={{padding: '0 30px'}}>
          <SimpleBreadcrumbs items={BREADCRUMB_ITEMS} />
        </div>
        <div className="cq-header__main">
          <h1>用户参数管理</h1>
          <div className="cq-header__action">
          {
            permissionlist?.isOnlyRead?
            <Tooltip title={`您当前的角色是[${permissionlist?.roleNameList.join(", ")}], 对[用户管理]没有操作权限`} 
              overlayStyle={{width: 180}}
            >
              <Button
                type="primary"
                icon={<PlusSquareOutlined />}
                disabled={permissionlist?.isOnlyRead}
                className={styles.displayFlex}
              >
                添加参数
              </Button>
            </Tooltip>
            : <CreateButton
                onClick={() => {
                  setCreateModalVisible(true)
                  setCreateContext()
                }}
              >
                添加参数
              </CreateButton>
          }
          </div>
        </div>
      </Header>
      <Layout className="cq-main">
        <Content className="cq-content">
          <section className="cq-card cq-card--padding">
            <div className="cq-card__headerbar">
              <div></div>
              <div>{searchRender}</div>
            </div>
            <section className="cq-table">{tableRender()}</section>
            <Modal
              title={`查看 ${filterRelevance} 过滤关联`}
              visible={filterModalVisible}
              footer={null}
              onCancel={() => setFilterModalVisible(false)}
              width='800px'
            >
              <Table
                columns={lookColumns}
                dataSource={ralationData}
                pagination={false}
                size="small"
              >
              </Table>
            </Modal>
            <RecordContext.Provider value={recordContext}>
              <ParameterModal
                visible={creatMoalVisible}
                handleClose={() => setCreateModalVisible(false)}
                callback={handleParameterModal}
              />
            </RecordContext.Provider>
          </section>
        </Content>
      </Layout>
    </Layout>
  )
}
