import React, { useRef, useImperativeHandle, useEffect, useState } from 'react';
import * as _ from 'lodash';
import { Form, message, Tabs, Spin, Alert } from 'antd';
import { useSelector, useRequest } from 'src/hook';
import {
  getBasicSetting,
  getHighRiskPermission,
  IHighRiskPermissionRes,
  saveHighRiskPermission,
} from 'src/api';
import BasicTimeSetting from './SecuritySettingComponents/BasicTimeSetting';
import BasicSetting from './SecuritySettingComponents/BasicSetting';
import HighRiskOperationSetting from './SecuritySettingComponents/HighRiskOperationSetting';
import styles from './index.module.scss';

interface ISecuritySettingWrapperProps {
  showObject?: boolean;
  nodePathWithType?: string;
  nodePathWithTypeList?: string[];
}

const SecuritySetting = (
  { showObject, nodePathWithTypeList = [], nodePathWithType }: ISecuritySettingWrapperProps,
  ref: any
) => {
  const [form] = Form.useForm();
  const timeRef = useRef(null);
  const highRiskOperationSettingRef = useRef<any>()
  const [editPermissionList, setEditPermissionList] = useState<boolean>(false)

  const [activeKey, setActiveKey] = useState<string>("basicSetting");
  // 保存用于保存设置 的当前选中的节点的信息参数
  const [saveParams, setSaveParams] = useState<{
    dataSourceType: string | null,
    groupId: string | null,
    nodePathWithTypeList: string | null,
  }>({
    dataSourceType: null,
    groupId: null,
    nodePathWithTypeList: null,
  })

  const { selectedNode: selectedTreeNode } = useSelector(state => state.dataProtection);


  // 判断 该登录权限下,该模块 只读/可编辑
  const { permissionList } = useSelector((state) => state?.login)
  const [permissionlist, setPermissionlist] = useState<{ isOnlyRead: boolean, roleNameList: any }>()

  //查询 高危操作设置
  const {
    data: permissionTemplate,
    loading,
    run: runGetHighRiskPermission,
  } = useRequest<IHighRiskPermissionRes>(getHighRiskPermission, {
    manual: true,
    onSuccess: (data: any) => {
    },
  });
  //查询 基础设置
  const {
    data: basicTemplate,
    loading: basicLoading,
    run: runGetBasicSetting,
  } = useRequest(getBasicSetting, {
    manual: true,
    onSuccess: (data: any) => {
      form.setFieldsValue({
        count: data.count || 0,
        settingType: data.settingType,
      });
    },
  });

  const { run: runSaveHighRiskPermission } = useRequest(
    saveHighRiskPermission,
    {
      manual: true,
      onSuccess: () => {
        message.success('提交成功');
      },
    }
  );

  useEffect(() => {
    const pathName = (window.location.pathname.split("/")[1].toUpperCase() as keyof typeof permissionList)
    setPermissionlist(permissionList?.DATABASE_MANAGEMENT?.[pathName])
  }, [])

  useEffect(() => {
    let params: any = {
      dataSourceType: selectedTreeNode?.connection?.connectionType,
      groupId: null,
      nodePathWithTypeList: showObject
        ? nodePathWithTypeList
        : [selectedTreeNode?.nodePathWithType],
    };
    if (selectedTreeNode?.nodeType === 'datasource') {
      params.dataSourceType = selectedTreeNode?.nodeName;
      params.nodePathWithTypeList = null;
    } else if (selectedTreeNode?.nodeType === 'group') {
      params = {
        ...params,
        groupId: selectedTreeNode?.id,
        dataSourceType: selectedTreeNode?.connection?.connectionType,
        nodePathWithTypeList: null,
      };
    }
    setSaveParams(params)
  }, [
    selectedTreeNode?.connection?.connectionType,
    selectedTreeNode?.id,
    selectedTreeNode?.nodeName,
    selectedTreeNode?.nodePathWithType,
    selectedTreeNode?.nodeType
  ])

  useEffect(() => {
    if (_.isEmpty(selectedTreeNode)) return;
    if (selectedTreeNode?.nodeType === 'datasource') {
      runGetBasicSetting({
        dataSourceType: selectedTreeNode?.nodeName,
        groupId: null,
        nodePathWithTypeList: null,
      });
      runGetHighRiskPermission({
        dataSourceType: selectedTreeNode?.nodeName,
        groupId: null,
        nodePathWithTypeList: null,
      });
    } else if (selectedTreeNode?.nodeType === 'group') {
      runGetBasicSetting({
        dataSourceType: selectedTreeNode?.connection?.connectionType,
        groupId: selectedTreeNode?.id as string,
        nodePathWithTypeList: null,
      });
      runGetHighRiskPermission({
        dataSourceType: selectedTreeNode?.connection?.connectionType,
        groupId: selectedTreeNode?.id,
        nodePathWithTypeList: null,
      });
    } else {
      runGetBasicSetting({
        dataSourceType: selectedTreeNode?.connection?.connectionType!,
        groupId: null,
        nodePathWithTypeList: showObject
          ? nodePathWithTypeList
          : [selectedTreeNode?.nodePathWithType!],
      });
      runGetHighRiskPermission({
        dataSourceType: selectedTreeNode?.connection?.connectionType,
        groupId: null,
        nodePathWithTypeList: showObject
          ? nodePathWithTypeList
          : [selectedTreeNode?.nodePathWithType],
      });
    }
    setEditPermissionList(false)
  }, [selectedTreeNode?.key, showObject]);

  useEffect(() => {
    if (_.isEmpty(selectedTreeNode)) return;
    if (editPermissionList) {
      if (selectedTreeNode?.nodeType === 'datasource') {
        runGetHighRiskPermission({
          dataSourceType: selectedTreeNode?.nodeName,
          groupId: null,
          nodePathWithTypeList: null,
        });
      } else if (selectedTreeNode?.nodeType === 'group') {
        runGetHighRiskPermission({
          dataSourceType: selectedTreeNode?.connection?.connectionType,
          groupId: selectedTreeNode?.id,
          nodePathWithTypeList: null,
        });
      } else {
        runGetHighRiskPermission({
          dataSourceType: selectedTreeNode?.connection?.connectionType,
          groupId: null,
          nodePathWithTypeList: showObject
            ? nodePathWithTypeList
            : [selectedTreeNode?.nodePathWithType],
        });
      }
    }
    setEditPermissionList(false)
  }, [editPermissionList]);

  const onSubmit = async (callback?: () => void) => {
    form.validateFields().then((values) => {
      let params: any = {
        dataSourceType: selectedTreeNode?.connection?.connectionType,
        groupId: null,
        nodePathWithTypeList: showObject
          ? nodePathWithTypeList
          : [selectedTreeNode?.nodePathWithType],
      };
      if (selectedTreeNode?.nodeType === 'datasource') {
        params.dataSourceType = selectedTreeNode?.nodeName;
        params.nodePathWithTypeList = null;
      } else if (selectedTreeNode?.nodeType === 'group') {
        params = {
          ...params,
          groupId: selectedTreeNode?.id,
          dataSourceType: selectedTreeNode?.connection?.connectionType,
          nodePathWithTypeList: null,
        };
      }

      const timeCallback = (status?: string) => {
        if (status === 'success') {
          if (_.isEmpty(permissionTemplate?.operationsVoList)) {
            Promise.resolve(callback?.())
            return
          }
          runSaveHighRiskPermission({
            ...params,
            ...values,
            level: Number(values?.level),
          }).then(() => callback?.());
        }
      };
      if (!['datasource', 'group'].includes(selectedTreeNode?.nodeType!)) {
        //@ts-ignore
        timeRef?.current?.handleOk(timeCallback);
      } else {
        if (_.isEmpty(permissionTemplate?.operationsVoList)) return
        //只有高危操作
        runSaveHighRiskPermission({
          ...params,
          ...values,
          level: Number(values?.level),
        }).then(() => callback?.());
      }
    });
  };

  useImperativeHandle(ref, () => ({
    onSubmit,
  }));

  // 基础设置
  const renderBasicSetting = (
    <Spin spinning={basicLoading}>
      <div style={{ height: 'calc(100vh - 200px)', overflowY: 'auto' }}>
        {/* 通用 */}
        {!['datasource', 'group'].includes(selectedTreeNode?.nodeType!) &&
          (
            <BasicTimeSetting
              connectionId={
                // zhuyi
                selectedTreeNode?.nodeType === 'connection'
                  ? selectedTreeNode?.id
                  : selectedTreeNode?.props?.connectionId
              }
              nodeType={selectedTreeNode?.nodeType}
              nodePathWithType={showObject ? nodePathWithType : selectedTreeNode?.nodePathWithType}
              nodePathWithTypeList={
                showObject
                  ? nodePathWithTypeList
                  : [selectedTreeNode?.nodePathWithType]
              }
              saveParams={saveParams}
              permissionlist={permissionlist}
            />
          )}
        <BasicSetting
          form={form}
          options={basicTemplate?.selectValues}
          saveParams={saveParams}
          permissionlist={permissionlist}
        />
      </div>
    </Spin>
  );

  // 高危操作设置
  const renderHighRiskOperationSetting = (
    <HighRiskOperationSetting
      ref={highRiskOperationSettingRef}
      loading={loading}
      permissionTemplate={permissionTemplate?.operationsVoList || []}
      permissionlist={permissionlist}
      setEditPermissionList={setEditPermissionList}
    />
  );

  const renderTabs = (
    <Tabs
      size="small"
      className={styles.card}
      type="card"
      tabPosition="top"
      animated
      activeKey={activeKey ? activeKey : "basicSetting"}
      onChange={(key) => setActiveKey(key)}
    >
      <Tabs.TabPane
        tab="基础设置"
        key="basicSetting"
      >
        {renderBasicSetting}
      </Tabs.TabPane>
      <Tabs.TabPane
        tab="高危操作设置"
        key="highRiskOperationSetting"
      >
        {renderHighRiskOperationSetting}
      </Tabs.TabPane>
    </Tabs>
  )

  // 后续支持group也改安全设置
  if (selectedTreeNode?.nodeType?.endsWith('Group')) {
    return <Alert message="继续展开左侧层级可进行安全设置" type="info" showIcon />
  }

  return (
    <>{renderTabs}</>
  );
};

export default React.forwardRef(SecuritySetting);
