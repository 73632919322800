import React, { useEffect, useState } from "react";
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Modal,
  Form,
  Select,
  Input,
  Button,
  message,
  Row,
  Col,
  Tooltip,
  Switch
} from "antd";
import { useRequest } from "src/hook";
import { FormLayout } from "src/constants";
import {
  addDesensScanTask,
  checkTaskCron,
  getDesensScanRules,
  IAddRuleParams,
  IScanRuleItem,
  getDesensScanTaskDetail,
} from "src/api";

const DesensScanTaskModal = ({
  visible,
  selectedNode,
  editRecordId,
  onCancel,
  refresh,
}: {
  visible: boolean;
  selectedNode: any;
  editRecordId?: number;
  onCancel: () => void;
  refresh: () => void;
}) => {
  const [form] = Form.useForm();
  //周期校验状态
  const [cronExpressionStatus, setCronExpressionStatus] = useState("");
  //扫描结果
  const [scanResults, setScanResults] = useState<boolean>(false);
  //添加\编辑
  const { run: runAddDesensScanTask } = useRequest(addDesensScanTask, {
    manual: true,
    onSuccess: () => {
      message.success("添加成功");
      onCancel();
      form.resetFields();
      refresh();
      setCronExpressionStatus('')
    },
  });
  //编辑
  const { data: itemDetail } = useRequest(
    () => editRecordId && getDesensScanTaskDetail(editRecordId),
    {
      refreshDeps: [editRecordId],
    }
  );
  //校验
  const { run: runCheckCron } = useRequest(checkTaskCron, {
    manual: true,
    onSuccess: (res) => {
      if (res) {
        setCronExpressionStatus("success");
      } else {
        setCronExpressionStatus("error");
      }
    },
  });

  //规则
  const { data: rules } = useRequest(getDesensScanRules, {
    formatResult: (res) => {
      return res?.map((item: IScanRuleItem) => ({
        label: item?.ruleName,
        value: item?.id,
      }));
    },
  });

  useEffect(() => {
    if (itemDetail) {
      form.setFieldsValue({
        jobName: itemDetail?.jobName,
        description: itemDetail?.description,
        autoScanning: itemDetail?.jobResource?.autoScanning,
        cronExpression: itemDetail?.cronExpression,
        scanAlgoType: itemDetail?.jobResource?.scanAlgoType || 'DEFAULT'
      });
      setScanResults(itemDetail?.jobResource?.enable ? itemDetail?.jobResource?.enable : false)
    }
  }, [itemDetail]);

  const onSubmitFormFields = (values: any) => {
    const {
      connectionId,
      nodeType,
      nodePath,
      connectionType,
      nodePathWithType,
    } = selectedNode?.props;

    const selectedNodeId = Number(selectedNode?.id);
 
    let params: IAddRuleParams = {
      connectionId: nodeType === "connection"? selectedNodeId : connectionId,
      jobName: values?.jobName,
      cronExpression: values?.cronExpression,
      description: values?.description,
      jobTypes: "DESENS_AUTO_DISCOVERY",
      nodePathWithType,
      jobResource: {
        autoScanning: values?.autoScanning,
        dataSourceType: connectionType,
        connectionId: nodeType === "connection"? selectedNodeId : connectionId,
        objNames: [nodePath],
        nodeType,
        scanAlgoType: values?.scanAlgoType,
        enable: (values.enable === undefined ? false : values.enable) // '扫描结果' 字段
      },
    }; 

    if (editRecordId) {
      params.id = editRecordId;
    }
    runAddDesensScanTask(params);
  };

  return (
    <Modal
      visible={visible}
      width={800}
      maskClosable={false}
      title={editRecordId ? "编辑扫描任务" : "添加扫描任务"}
      onCancel={() => {
        onCancel();
        form.resetFields();
        setCronExpressionStatus('');
      }}
      onOk={() => {
        form.submit();
      }}
    >
      <Form form={form}  onFinish={onSubmitFormFields}  {...FormLayout}>
        <Form.Item
          name="jobName"
          label="任务名称"
          rules={[{ required: true, message: "请输入任务名称" }]}
        >
          <Input placeholder="请输入任务名称" />
        </Form.Item>
        <Form.Item name="description" label="任务描述">
          <Input.TextArea
            autoSize={{ minRows: 2, maxRows: 2 }}
            maxLength={200}
            placeholder="请输入任务描述"
          />
        </Form.Item>

        <Form.Item
          name="autoScanning"
          label="扫描规则"
          rules={[{ required: true, message: "请选择扫描规则" }]}
        >
          <Select
            mode="multiple"
            maxTagCount={3}
            options={rules as any}
            optionFilterProp="label"
            showSearch
            placeholder="请选择扫描规则"
            onChange={(rules: number[]) => {
              form.setFieldsValue({ autoScanning: rules });
            }}
          ></Select>
        </Form.Item>
        <Form.Item
          label={<Tooltip
            title={
              <>
                cron表达式：[秒][分][时][日][月][周][年]
                <div>（1）0 0/2 * * * ?    表示每2分钟触发</div>
                <div>（2）0 0 10,14,16 * * ?   表示每天10点14点16点触发</div>
                <div>（3）0 15 10 ? * *    表示每天上午10:15触发</div>
                <div>（4）0 15 10 ? * MON-FRI    表示周一至周五的上午10:15触发</div>
              </>
            }
          >任务周期 <QuestionCircleOutlined />
          </Tooltip>} required>
          <Row gutter={8}>
            <Col>
              <Form.Item
                name="cronExpression"
                required
                hasFeedback
                //@ts-ignore
                validateStatus={cronExpressionStatus}
                rules={[
                  {
                    required: true,
                    message: "请输入任务周期",
                  },
                  {
                    validator(_:any,value: string){
                     if (!value) {
                      setCronExpressionStatus('error')
                      return Promise.reject()
                     }
                     return Promise.resolve()
                    }
                  }
                ]}
              >
                <Input placeholder="请输入任务周期" />
              </Form.Item>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() =>
                  runCheckCron({
                    cronExpression: form.getFieldValue("cronExpression"),
                  })
                }
              >
                校验
              </Button>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          name="enable"
          label="扫描结果"
        >
          <Switch
            checked={scanResults}
            checkedChildren="启用" 
            unCheckedChildren="禁用"
            onChange={(checked: boolean) => setScanResults(checked)}
          />
        </Form.Item>
        <Form.Item
          name="scanAlgoType"
          label="默认算法"
          initialValue='DEFAULT'
        >
          <Select
            options={[
              { label: '默认',value: 'DEFAULT'},
              { label: '数据仿真',value: 'EMULATION'}
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default DesensScanTaskModal;
