import React, { FC, useState, useEffect, useContext } from 'react'
import { FormInstance } from 'antd/lib/form'
import moment from 'moment'
import { Form, Radio, DatePicker, Input, Button, Card } from 'antd'
import { ApplyItem } from 'src/api'
import { FormContext } from '../ApplyFormContext'
import styles from './index.module.scss'
import classnames from 'classnames'
import { CURRENT_ROUTE_TYPE } from 'src/constants'

interface SubmitApplicationFormProps {
  form: FormInstance
  isViewDetail: boolean
  isEdit?:boolean
  currentRouteType?: CURRENT_ROUTE_TYPE
  onSubmitApplication?: (type: 'draft' | 'finalize', params: ApplyItem | any) => void
  [p: string]: any
}

export const SubmitApplicationForm: FC<SubmitApplicationFormProps> = ({
  form,
  isViewDetail,
  isEdit,
  currentRouteType,
  onSubmitApplication,
}) => {

  const { currentContextValue, setCurrentStep } =
    useContext(FormContext)

  const [executionWay, setExecutionWay] = useState(1)
  const [visibleSqlTypes, setVisibleSqlTypes] = useState(true)

  const validateCurrentFormFields = (type: 'finalize' | 'draft') => {
    form.validateFields().then((values) => {     
   
      onSubmitApplication &&
        onSubmitApplication(type, {
          ...currentContextValue,
          ...values,
          executeTime: values?.executeTime ? moment(values?.executeTime).format('YYYY-MM-DD HH:mm:ss') : null    
        })
    })
  }

  useEffect(() => {
   
    if (isViewDetail || isEdit) {
      setVisibleSqlTypes(false)
      form.setFieldsValue({executeTimeType: currentContextValue?.executeTimeType})
      if (currentContextValue?.executeTimeType === 1) {
        setExecutionWay(1)
      }else {
        setExecutionWay(2)
       
      }
    }
  }, [isViewDetail, isEdit, currentContextValue?.executeTimeType, form])
  
  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

   const disabledDate = (current: any) => {
    return current && current < moment().startOf('day');
  };

  const disabledTime = (now: any) => {

    let time: any = moment();
    let hour = moment(time).hour();
    let minute = moment(time).minute();
    let second = moment(time).second();

      return ({
        disabledHours: () =>range(0, hour),
        disabledMinutes: () => range(0,minute),
        disabledSeconds: () =>  range(0,second)
      })
   
}

  return (
    <Card
      title={''}
      className={classnames(styles['sql-card'], styles['step-card2'], styles['borderNone'])}
      bordered={false}
      id='submitApplicationCard'
      actions={
        [
          <div className={styles['footer-btn']}>
            <Button
              onClick={() => validateCurrentFormFields('draft')}
              className={styles['secondary-btn']}
              style={{ marginRight: 5 }}
            >
              保存草稿
            </Button>
            <Button
              onClick={() => {
                setCurrentStep && 
                setCurrentStep((currentStep: number) => {
                  return (currentStep - 1)
                });
              }}
              className={styles['secondary-btn']}
              style={{ marginRight: 5 }}
            >
              上一步
            </Button>
            <Button
              type="primary"
              onClick={() => validateCurrentFormFields('finalize')}
              disabled={!!currentContextValue?.sqlCheckNotAllowSubmit}
            >
              提&nbsp;&nbsp;交
            </Button>
          </div>
        ]
      }
    >
      <Form
        name="basic"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 10 }}
        layout="horizontal"
        initialValues={{ executeTimeType: 1 }}
        autoComplete="off"
        form={form}
      >
        {!visibleSqlTypes ? (
          <Form.Item
            label="执行方式"
            rules={[{ required: true }]}
          >
           {currentContextValue?.executeTimeType === 1 ? '手动执行' : '自动执行'}
            <Button type="link" onClick={() => setVisibleSqlTypes(true)}>
              修改
            </Button>
          </Form.Item>
        ) : (
          <Form.Item
            label="执行方式"
            name="executeTimeType"
            rules={[{ required: true }]}
            initialValue={currentContextValue?.executeTimeType || 1}
          >
            <Radio.Group
              onChange={(event) => setExecutionWay(event.target.value)}
            >
              <Radio value={1}>手动执行</Radio>
              <Radio value={2}>自动执行</Radio>
            </Radio.Group>
          </Form.Item>
        )}

        {executionWay === 1 ? null : (
          <Form.Item
            label="执行时间"
            name="executeTime"
            rules={[{ required: true, message: '请选择执行时间' },{
              validator(_rule, value,cb) {
                const time = moment(value).valueOf();
               
                if (time < moment().valueOf()) {
                  cb('执行时间必须大于当前时间')
                }
               cb();
              },
            }]}
          >
            <DatePicker 
            showTime={{
                hideDisabledOptions: true,
            }}    
            disabledDate={disabledDate}  
            disabledTime={disabledTime} 
            format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
        )}

        <Form.Item label="备注" name= {currentRouteType === 'MINE_APPROVE' ? 'approveUserComment' :"applyUserComment"}>
          <Input.TextArea rows={3}></Input.TextArea>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default SubmitApplicationForm
