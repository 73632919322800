import React, { useState } from 'react'
import { DatabaseInfo } from 'src/pageTabs/queryPage/queryTabs/queryTabsSlice'
import StepsInfo from './StepsInfo'

interface CreateViewPaneProps {
  databaseInfo: Partial<DatabaseInfo>
  callback?: (p: any) => void
  [p: string]: any
}

export const ObCreateViewPane = ({
  databaseInfo,
  callback
}: CreateViewPaneProps) => {

  const [showStep, setShowStep] = useState(true)

  const handleCreateSql = (sql: string) => {
    setShowStep(false)
    callback && callback(sql)
  }

  return (
    showStep 
    ? <StepsInfo databaseInfo={databaseInfo} handleCreateSql={handleCreateSql} />
    : null
  )
}