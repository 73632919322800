import React, { useEffect } from "react";
import {
  getCartPermissionsPanelObject,

} from 'src/api';
import { useRequest, useSelector, useDispatch } from 'src/hook';
import { updatePermissionCollections } from './applicationListSlice'
import SubchemaLevelComponent from '../components/SubSchemaLevelComponent';

export const SubSchemaLevelContent = ({
  selectTreeItem,
  filter,
  permissionCollectionVOS,
  handleUpdateBelowSchemaParams,
}: {
  isEdit?: boolean;
  selectTreeItem?: any;
  needRefresh?: boolean;
  isInShoppingCart?: boolean;
  viewAppliedDetail?: boolean;
  permissionCollectionVOS?: any;
  filter?: any;
  handleUpdateBelowSchemaParams?: (params: any) => void

}) => {

  const dispatch = useDispatch();
  const { permissionCollections } = useSelector(state => state.applicationList)

  const { data: objectResult, loading, run: runGetCartPermissionsPanelObject } = useRequest(getCartPermissionsPanelObject, {
    manual: true
  })

  useEffect(() => {

    if (!selectTreeItem) {
      return;
    }
    const {
      nodeName,
      nodePathWithType,
      nodeType,
      dataSourceType,
      sdt,
    } = selectTreeItem;
    const connectionType = dataSourceType || sdt?.connectionType
    const params = {
      connectionId: null,
      connectionType,
      nodeType,
      nodeName,
      nodePathWithType,
      filter
    };
    runGetCartPermissionsPanelObject(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectTreeItem?.key]);


  return (
    <SubchemaLevelComponent
      loading={loading}
      objectResult={objectResult}
      selectTreeItem={selectTreeItem}
      isInShoppingCart={true}
      canEdit
      permissionCollections={permissionCollections}
      onUpdateConnectionObjectPermissions={(params: any) => dispatch(updatePermissionCollections(params))}
      onUpdataParmas={handleUpdateBelowSchemaParams}
    />
  )
}