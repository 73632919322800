import React from 'react'
import { Table } from 'antd'
import type { TableProps } from 'antd/lib/table'
import styles from './index.module.scss'

interface ISimpleTableProps extends TableProps<any> {
  total: number;
  searchParams?: {currentPage?: number; pageSize?: number}
  setSearchParams?: (params: any) => void
  showPagination?: boolean;
}

export const SimpleTable = (props: ISimpleTableProps) => {
  const { searchParams ={}, setSearchParams,total,showPagination = true, ...rest} = props

  return (
    <Table<any>
      scroll={{x: 1700, y: 600 }}
      {...rest}
      className={styles['new-version-simple-table']}
      pagination={showPagination ? {
        current: searchParams?.currentPage,
        pageSize: searchParams?.pageSize || 10,
        total,
        showSizeChanger: true,
      }: false}
      onChange={(pagination, filters, sorter, { action}) => {
     
        const { field, order } = sorter as {
          field: string
          order: 'ascend' | 'descend' | null
        }
       
        let currentSearchParams = {
          ...searchParams,
          currentPage: pagination?.current,
          pageSize: pagination?.pageSize
        }

      if (action !== 'paginate') {

        currentSearchParams = {
          ...currentSearchParams,
          ...(field && order
            ? { [field]:order }
            : {}),
            ...filters,
        }
      }
      setSearchParams?.(currentSearchParams)
       
      }}
      
    />
  )
}
