import React, { FC, useContext, useEffect} from 'react'
import { Badge, Card, Steps } from 'antd'
import * as _ from 'lodash'
import classNames from 'classnames'
import { useRequest } from 'src/hook'
import { getNewFlowProcess } from 'src/api'
import { FormContext } from '../ApplyFormContext'
import styles from './index.module.scss'

interface ReviewProcess {
  [p: string]: any
}

export const ReviewProcess: FC<ReviewProcess> = () => {

  const { currentContextValue } = useContext(FormContext)

  //详情
  const { data: list=[], run: getFlowSteps } = useRequest(getNewFlowProcess, {
    manual: true,
  })

  useEffect(() => {
    if (currentContextValue?.flowUUID) {
      getFlowSteps(currentContextValue?.flowUUID)
    }
  }, [currentContextValue?.flowUUID])

  return (
    <Card
      title="工单流转"
      className={styles['review-process-card']}
    >
      <Steps direction="horizontal" labelPlacement='vertical' current={0} size="small">
        {list &&
          list.map((flow: any, index: number) => {
            const { flowProcessInfos = [],flowNodeResult } = flow
            return (
              <Steps.Step
                key={index}
                title={<div className={styles['step-title']}>{ index === 0
                  ? '申请数据变更'
                  : flowProcessInfos?.[0]?.approvedUser}</div>
                 
                }
                description={
                  <>
                  <div className={styles.desc}>
                  {flowNodeResult === 'pass' ?  '审批通过' : flowNodeResult === 'pending' ? '审批中' : currentContextValue?.executeStatus}
                  </div>
                  <div>
                     {
                      currentContextValue?.executeStatus !== '已撤回' &&
                      <span className={styles.time}>{
                        index===0 ? currentContextValue?.applyTime :flowProcessInfos?.[0]?.approvedTime}</span>
                      }
                  </div>
                  </>
                }
                icon={
                  <div
                    className={classNames(styles['step-icon'], {
                      [styles.start]: index === 0,
                    })}
                  >
                    <span
                      className={classNames(styles.icon, {
                        [styles.pending]: flowNodeResult === 'pending',
                      })}
                    >
                      {index === 0 ? '建' : '审'}
                    </span>
                    {index !== 0 && (
                      <Badge
                        dot={true}
                        offset={[8, 10]}
                        className={styles['icon-badge']}
                        color={flowNodeResult === 'pending' ? '#3262FF': '#36B839'}
                      ></Badge>
                    )}
                  </div>
                }
              ></Steps.Step>
            )
          })}
      </Steps>
    </Card>
  )
}
