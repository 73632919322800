/* -------------------------------------------------------------------------- */
/*                                  流程相关 api                                  */
/* -------------------------------------------------------------------------- */

import { fetchDelete, fetchGet, fetchPost, fetchPut } from './customFetch'
import type { FlowType, FormFieldElementEntity } from 'src/types'
import type { IPermKey } from 'src/service/permService/PermStore'
import { SdtNodeType } from '../types'

/* ----------------------------- api definition ----------------------------- */

// ! ngxin 对 flow 服务的转发加了 baseUrl ’/api‘ 前缀
// ! 当前项目中 fetch 封装有缺陷，不太方便给所有服务加上前缀，先单独对 flow 转发一下
const baseUrl = '/api'
const searchPendingFlowApi = `${baseUrl}/flow/allApproveList/pending`
const transferNewApproverApi = `${baseUrl}/flow/referToOthers`

/* -------------------------------- interface ------------------------------- */

export interface IParams_searchPendingFlow {
  userId?: string
  applyType?: string
  applyStatus?: string
  startApplyTime?: string
  endApplyTime?: string
  applyUserName?: string
  currentPage: number
  pageSize: number
  approveUserId?: string
  title?:string
  priGranType?: string
  timer?:string
}

export interface IResponse_searchPendingFlow {
  haveNextPage: boolean
  havePerPage: boolean
  totalCount: number
  pageSize: number
  pageNumber: number
  list: Array<IList>
  lastPage: boolean
  firstPage: boolean
}

export interface IList {
  indexKey: number /** 唯一id（前端添加的） */
  flowTaskId: string //新流程存在flowTaskId, 此处后端要求不考虑老流程情况
  flowInstanceId?: string
  applyId: string
  uuid: string
  applyUserName: string
  applyType: string
  deptName: string
  currentAssignee: string
  applyTime: string
  expiredTimeLimit: number
  lastOperatingTime: string
  connectionName: string
  dataBaseName: string
  dataBaseType: string
  applyStatus: string
  isMultiInstance?: boolean
}

export interface IParams_transferNewApprover {
  referUserId: string
  userTasks: Array<any>
}

export interface IResponse_transferNewApprover {}

export interface IParams_getFlowDetails {
  taskId?: string
  flowId: string
}

/**
 * 流程 数据库元素树 非连接节点
 */
export interface FlowElementTreeNodeEntity {
  alias?: string
  connectionId: number
  connectionType: string
  groupId?: number
  hasChild: boolean
  nodeDescription?: string
  nodeName: string
  nodePath: string
  nodePathWithType: string
  nodeType: SdtNodeType
  newNodeType?: string
  parentGroupId?: number
  experiment?: boolean
  connectionName?: string
  [p: string]: any
}

export interface FlowElementTreeRequest {
  connectionId: number
  connectionType: string
  nodeType: string
  // ? required
  flowType: FlowType
  hasChild?: boolean
  nodeName?: string
  // ? required
  nodePath: string
  nodePathWithType: string
  userId?: string
}

export const getDataChangedDatabaseList = (): Promise<FlowElementTreeNodeEntity[]> => {
  return fetchGet(`/api/flow/connectionList `)
}

export const getFlowConnectionList = ({
  flowType,
  userId,
}: {
  flowType: FlowType
  userId: string | number
}): Promise<FlowElementTreeNodeEntity[]> => {
  return fetchGet(`${baseUrl}/flow/connectionList/${flowType}/users/${userId}`)
}
//批量执行获取连接
export const getBatchExecuteConnectionList = ({
  flowType,
  userId,
}: {
  flowType: FlowType
  userId: string | number
}): Promise<FlowElementTreeNodeEntity[]> => {
  return fetchGet(`${baseUrl}/flow/batchExecute/connectionList/${flowType}/users/${userId}`)
}

//敏感资源获取
export const getMaskedTreeList = (connectionId:number | string): Promise<FlowElementTreeNodeEntity[]> => {
  return fetchGet(`${baseUrl}/flow/maskedTree/${connectionId}`)
}
// 模拟数据库数据获取
export const getSimuleFlowConnectionList = ({
  flowType,
  userId,
}: {
  flowType: FlowType;
  userId: string | number;
}): Promise<FlowElementTreeNodeEntity[]> => {
  return fetchGet(
    `${baseUrl}/flow/simulateConnectionList/${flowType}/users/${userId}`
  );
};


export const getFlowConnectionNodes = (
  params: FlowElementTreeRequest,
): Promise<FlowElementTreeNodeEntity[]> => {
  return fetchPost(`${baseUrl}/flow/connectionNode`, params)
}
//批量执行获取node节点
export const getBatchExecuteConnectionNodes = (
  params: FlowElementTreeRequest,
): Promise<FlowElementTreeNodeEntity[]> => {
  return fetchPost(`${baseUrl}/flow/batchExecute/connectionNode`, params)
}


type User = { userId: string; userName: string }
export const getFlowApproveUser = (params: {
  connectionId?: string | number
  userId: string | number
}): Promise<{
  connectUser: User[]
  deptUser: User | null
  systemUsers: User[]
}> => {
  return fetchPost(`${baseUrl}/flow/approveUser`, params)
}

interface IPermissionContext {
  permissionType: IPermKey
  permissionNodes: FormFieldElementEntity[]
}

interface IPermissionHookRequest {
  /* 连接 id */
  connectionId: string | number
  /* 连接名称 */
  nodeName: string | undefined
  /* 连接路径 */
  nodePath: string
  /* 权限类型 */
  permissionType: IPermKey
  /* 权限路径 */
  permissionPath: string
  /* 埋点类型 */
  permissionInfoEnum: 'SDT' | 'OTHER'
}

/* 转化 sdt nodepath 到 permissionNodePath */
export const getPermissionContext = (data: IPermissionHookRequest) => {
  return fetchPost('/dms/common/permission/info', data).then(
    (data: IPermissionContext) => ({
      ...data,
      permissionNodes: data.permissionNodes.map((node) => ({
        ...node,
        nodePath: node.nodePath || '',
      })),
    }),
  )
}

/** 流程申请参数 */
 interface FlowApplyRequest {
  applyUserId: string | number
  email?: string
  connectionId?: string | number
  dataSourceType?: string
  /** 一级审批人 */
  firstApproveUserId: string | number
  flowType: string
  nodePathList?: string[]
  nodePathWithTypeList?: string[]
  nodeType?: string
  applyReason?: string
  /** 提权期限（单位：小时） */
  expiredTimeLimit?: number
  /** [数据订正] 执行文本 */
  executeText?: string
  /** [数据订正] 回退文本 */
  rollbackText?: string
  /** [数据订正] 执行时机 */
  executeType?: 'auto' | 'manual'
  /** [执行次数提权] 次数限制 */
  executeTimeLimit?: number
  /** [数据导出提权] 导出行数 */
  exportNumLimit?: number
  /** [数据操作提权] 权限操作列表 */
  operationList?: string[]
  /** [执行行数提权] 行数限制 */
  rowNumLimit?: number
  /** 二级审批人 */
  secondApproveUserId?: string | number
  /** [菜单权限] 菜单项 */
  objName?: string[]
   /** [高危申请] 生效时间 */
  beginTime?: string;
  endTime?: string;
  title?: string;
  useCartDataThinTiling?: boolean;
  priGranType?: string;
  remark?: string
}

export const launchFlowApply = (params: FlowApplyRequest) => {
  return fetchPost(`${baseUrl}/flow/apply`, params)
}

/** 查询[我的申请] 列表 */
export const getMyApplications = (params: {
  currentPage: number
  pageSize: number
  applyStatus?: string
  userId: string
  keyword?: string
}) => {
  return fetchPost(`${baseUrl}/flow/applyList/myApply`, params)
}

/** 查询[待处理] 列表 */
export const getPendingApproval = (params: {
  currentPage: number
  pageSize: number
  startApplyTime?: string
  endApplyTime?: string
  applyType?: string
  applyUserName?: string
  userId?: string
  title?: string
  priGranType?: string
  timer?: string
}) => {
  return fetchPost(`${baseUrl}/flow/approveList/pending`, params)
}

/** 查询[已处理] 列表 */
export const getResolvedApproval = (params: {
  currentPage: number
  pageSize: number
  applyStatus?: string
  startApplyTime?: string
  endApplyTime?: string
  applyType?: string
  applyUserName?: string
  title?: string
  userId?: string
  priGranType?: string
  timer?: string
}) => {
  return fetchPost(`${baseUrl}/flow/approveList/solved`, params)
}

export type approveApplicationParams = {
  flowId: string
  taskId?: string
  approvedFlag: boolean
  approvedComment?: string
  approvedTime?: string
  refuseReasonVo?: {
    refuseReason?: string
    is3rdSystem?: boolean
  }
}

/** 通过审批 */
export const approveApplication = (params: approveApplicationParams) => {
  // return fetchPut(`${baseUrl}/flow/apply/${applyId}/pass`)
  // return fetchPut(`${baseUrl}/flow/apply/${applyId}/pass`)
  return fetchPost(`${baseUrl}/flow/executeFlowTask`, params)
}

interface IParam {
  refuseReason: string
}
/** 驳回审批 */
export const rejectApplication = (applyId: string, params: IParam) => {
  return fetchPut(`${baseUrl}/flow/apply/${applyId}/refuse`, params)
}

interface FlowProcessResponse {
  applyTime: string
  applyUserName: string
  finishStatus: string
  finishTime?: string
  firstApproveStatus: string
  firstApproveTime?: string
  firstApproveUserName: string
  firstApproveUserId: string
  secondApproveStatus?: string
  secondApproveTime?: string
  secondApproveUserId: string
  secondApproveUserName?: string
}

/** 查询审批流程进度 */
export const getFlowProcess = (
  flowId: string,
): Promise<FlowProcessResponse> => {
  return fetchGet(`${baseUrl}/flow/${flowId}/process`)
}

/** 查询新类型审批流程进度 */
export const getNewFlowProcess = (flowInstanceId: string): Promise<any> => {
  return fetchPost(`${baseUrl}/flow/viewFlowProcess`, { flowInstanceId })
}

/** 查询新类型审批流程进度 */
export const getFlowStatus = (flowInstanceId: string): Promise<any> => {
  return fetchPost(`${baseUrl}/flow/getFlowStatus`, { flowInstanceId })
}

/** 执行数据订正 */
export const executeDataCorrection = (flowId: string) => {
  return fetchPut(`${baseUrl}/flow/${flowId}/execute`)
}

/** 回退数据订正 */
export const rollbackDataCorrection = (flowId: string) => {
  return fetchPut(`${baseUrl}/flow/${flowId}/rollback`)
}

/** 撤销申请 */
export const withdrawApplication = (flowId: string) => {
  return fetchPut(`${baseUrl}/flow/${flowId}/withdraw`)
}

/* 判断当前用户是否有流程的审批权限  */
export const checkUserFlowRole = (flowId: string) => {
  return fetchGet(`${baseUrl}/flow/${flowId}/role`)
}

/* ----------------------------- 流程-全部流程-公共接口请求 ----------------------------- */

/** 查询审批流程详情 */
export const getFlowDetails = (params: IParams_getFlowDetails) => {
  return fetchPost(`${baseUrl}/flow/common/detail`, params)
}

/* ------------------------------ 流程-全部流程-设计流程 ------------------------------ */
export type IKeyProps = 'apply' | 'dataChange'

/**
 * @description: 流程/数据变更-设计流程-获取全部流程列表
 * @return {*}
 */
export const getFlowModel = (typeName: IKeyProps) => {
  return fetchGet(`${baseUrl}/flow/model/flowTypes/${typeName}`)
}

/**
 * @description: 流程-设计流程-获取流程图BPMN文件
 * @param {string} processDefinitionKey model key值
 * @return {*}
 */
export const getFlowModelDetail = (processDefinitionKey: string) => {
  return fetchGet(
    `${baseUrl}/flow/model/getTaskFlowBPMN/${processDefinitionKey}`,
  )
}

type TXMLFileInfo = {
  bpmnBase64Code: string //base64编码格式
  flowType: string
}

/**
 * @description: 流程-设计流程-保存 指定 流程  bpmnBase64Code （XML内容需要BASE64）
 * @param {*}
 * @return {*}
 */
export const saveFlowModel = (XMLFileInfo: TXMLFileInfo) => {
  return fetchPost(`${baseUrl}/flow/model/deploy`, XMLFileInfo)
}

/**
 * @description: 拒绝所有流程任务(即终止当前流程实例所有正在执行的流程)
 * @param {string} flowType
 * @return {Promise<any>}
 */
export const refuseAllFlowTasks = (flowType: string): Promise<any> => {
  return fetchGet(`${baseUrl}/flow/refuseAllFlowTasks/${flowType}`)
}

/* ------------------------------ 流程-全部流程-管理流程 ------------------------------ */
/**
 * @description: 管理员转审，查询待处理
 * @param {IParams_searchPendingFlow} params 请求参数
 * @return {Promise<IResponse_searchPendingFlow>}
 * @see http://192.168.1.170:9876/doc.html#/Flow:base-2.3.6/%E6%B5%81%E7%A8%8B%E5%AE%9E%E4%BE%8B%E7%9B%B8%E5%85%B3%E6%8E%A5%E5%8F%A3/getAllPendingFlowPageInfo 文档访问询问后端开发
 */
export const searchPendingFlow = (
  params: IParams_searchPendingFlow,
): Promise<IResponse_searchPendingFlow> => {
  return fetchPost(searchPendingFlowApi, params)
}

/**
 * @description: 管理员转审功能
 * @param {IParams_searchPendingFlow} params 请求参数
 * @return {Promise<IResponse_searchPendingFlow>}
 * @see http://192.168.1.170:9876/doc.html#/Flow:base-2.3.6/%E6%B5%81%E7%A8%8B%E5%AE%9E%E4%BE%8B%E7%9B%B8%E5%85%B3%E6%8E%A5%E5%8F%A3/getAllPendingFlowPageInfo 文档访问询问后端开发
 */
export const transferNewApprover = (
  params: IParams_transferNewApprover,
): Promise<IResponse_transferNewApprover> => {
  return fetchPost(transferNewApproverApi, params)
}


/**
 * 访问申请 我的申请 子工单
 */
export const myApplyChildList = (flowUUID: any, flowTaskId: any) => {
  return fetchPost(`${baseUrl}/flow/applyList/myApplyChildList/${flowUUID}/${flowTaskId}`)
}

/**
 * 访问申请 我的申请 已审批 子工单
 */
export const myApplyChildListAppraved = (flowUUID: any) => {
  return fetchPost(`${baseUrl}/flow/applyList/myApplyChildListAppraved/${flowUUID}`)
}

/**
 * 访问申请 我的申请 细粒度|粗粒度 清单详情
 */
export const getInventoryData = (params: {
  flowMainUUID: string,
  connectionIds: any[],
  canOperation: any
}) => {
  return fetchPost(`${baseUrl}/flow/applyList/getInventoryData?bindingResult`, params)
}

export const getInventoryDataForMessage = (params: {
  flowMainUUID: string,
  canOperation: any
}) => {
  return fetchPost(`${baseUrl}/flow/applyList/getInventoryDataForMessage`, params)
}
/** 访问申请 我的审批 已完成 列表 */
export const getApproveListFinish = (params: {
  currentPage: number
  pageSize: number
  applyStatus?: string
  startApplyTime?: string
  endApplyTime?: string
  applyType?: string
  applyUserName?: string
  userId?: string
  title?: string
  priGranType?: string
  timer?: string
}) => {
  return fetchPost(`${baseUrl}/flow/approveList/finish`, params)
}

/** 访问申请 工单管理 已完成 列表 */
export const getAllApproveFinishList = (params: {
  currentPage: number
  pageSize: number
  applyStatus?: string
  startApplyTime?: string
  endApplyTime?: string
  applyType?: string
  applyUserName?: string
  userId?: string
  title?: string
  priGranType?: string
  timer?: string
}) => {
  return fetchPost(`${baseUrl}/flow/allApproveList/finish`, params)
}

/** 访问申请 我的审批 落权 */

export const dealWithPermission = (params: {
  flowUUID: string
  powerStatus: string
  remarks: any
}) => {
  return fetchPost(`${baseUrl}/flow/dealWithPermission`, params)
}
//撤回
export const withdrawOrder = (params: {
  flowId: number;
  withdrawRemark?: string;
  isMyApply?: boolean
}) => {
  return fetchPost(`${baseUrl}/flow/withdraw`, params)
}
//删除
export const deleteOrder = (flowId: number) => {
  return fetchDelete(`${baseUrl}/flow/delete/${flowId}`)
}
//再次申请
export const reApplyFlowOrder = (flowId: string) => {
  return fetchPost(`${baseUrl}/flow/reApplyFlow/${flowId}`)
}

//重新申请
export const reopenFlowOrder = (flowId: string) => {
  return fetchPost(`${baseUrl}/flow/reopenFlow/${flowId}`)
}

/** 访问申请 资源搜素 */
export const getConnectionListForFlow = (params: {
  pageNo: number
  pageSize: number
  dataSourceType?:string | null
  ip?: string
}) => {
  return fetchPost(`/user/connections/connectionForFlow`, params)
}


interface BaseAndSchema {
  connectionId: string | number
  connectionType: string
  nodePathWithType: string
}

export const getAllDataBaseAndSchema = (params: BaseAndSchema) => {
  return fetchPost(`/api/flow/getAllDataBaseAndSchema`, params)
}

/** 访问申请粗粒度清单 */
export const getCartDataFAT = ( ) => {
  return fetchPost(`${baseUrl}/flow/getCartDataFAT`)
}

/** 访问申请 申请清单删除 */
export const deleteCartDataFat = (flowUUID: any) => {
  return fetchPost(`${baseUrl}/flow/deleteCartDataFat/${flowUUID}`)
}

/** 访问申请 申请清单 保存草稿 */
export const saveDraftFat = ( parmas: any) => {
  return fetchPost(`${baseUrl}/flow/saveDraft`, parmas)
}

/** 访问申请 细粒度购物车 清空 删除 */
export const deleteCartDataThin = ( parmas: {
  nodePathWithType: string
  flowType: string
}) => {
  return fetchPost(`${baseUrl}/flow/deleteCartDataThin`, parmas)
}

/** 申请清单 更新权限状态 */
export const updateCartDataThin = ( parmas: {
  flowUUID: any
  operationMap: any
}) => {
  return fetchPost(`${baseUrl}/flow/updateCartDataThin`, parmas)
}

/** 访问申请 我的申请 催一下 */ 
export const getUrgeReminders = ( parmas: {
  senderIds: string | number
  flowId: string | number
}) => {
  return fetchPost(`${baseUrl}/flow/reminders`, parmas)
}

/** 审批信息 备注 */
export const getFlowRemarkInfo = (flowInstanceId: string): Promise<any> => {
  return fetchPost(`${baseUrl}/flow/getFlowRemarkInfo`, { flowInstanceId })
}

/** 审批信息 落权 */
export const getPowerRemarkInfo = (flowInstanceId: string): Promise<any> => {
  return fetchPost(`${baseUrl}/flow/getPowerRemarkInfo`, { flowInstanceId })
}

/**  撤回备注 */
export const getWithdrawRemarkInfo = (flowInstanceId: string): Promise<any> => {
  return fetchPost(`${baseUrl}/flow/getWithdrawRemarkInfo`, { flowInstanceId })
}

/**  导出申请 */
interface SaveFlowApplyIParams {
  applyUserId: string,
  email: string, //用户
  remark: string, //申请原因
  applyReason: string, //申请原因，不知道用哪个字段两个填一样的
  flowType: string, //导出申请的类型，写死这个值 "exportTask"
  connectionId: string | number,  //连接id
  dataSourceType: string,  //数据源类型
  nodePathWithTypeList: string[], //对象路径,对应上面执行返回的 allObjectPathSting 字段
  databaseName?: string,
  schemaName?: string,   //对应执行字段operatingObject
  executeText?: string,   //导出SQL
  exportFormat?: string,  // 导出格式
  exportNumLimit: string | number,  // 允许导出的最大数量，如果点的是全部导出这里输入-1
  exportClearText: boolean,  //标记是否明文导出，上面有描述
  dumpType?: string,  //导出类型
  title: string, //标题
  priGranType: string,  //导出申请的类型，写死这个值  "exportTask"
  useCartDataThinTiling: boolean, //写死这个值 false
}
export const saveFlowApply: any = (params: SaveFlowApplyIParams) => {
  return fetchPost(`/api/flow/apply`, params)
}

/** 附件信息 查看下载文件是否准备完成 */
export const checkFlowInfo = (flowId: string): Promise<any> => {
  return fetchGet(`/export/export/info/flow/${flowId}`)
}

/**
 *  访问申请页面-获取分层sdt连接列表
 */
export const getAccessPermissionConnections = (params: {ip?: string}) => {
  return fetchPost(`/user/connections/accessPermission/connection/nodes`,params);
}

interface IPermissionNodeParams {
  connectionId: number
  connectionType: string
  nodeType: string
  nodeName: string
  nodePath: string
  nodePathWithType: string
}
//node字节点
export const getAccessPermissionNode = (params: IPermissionNodeParams) => {
	return fetchPost(`/dms/meta/accessPermission/node`, params)
} 

//加入申请清单
export const addFlowRequisitionToCart = (params: any) => {
	return fetchPost(`/api/flow/accessPermission/addRequisitionToCart`, params)
} 

//申请单schema以上选中权限
export const getPermissionsPanelUpObject= (params: any): Promise<any> => { 
	return fetchPost(`/user/permissionCollection/accessPermission/getPermissionsPanelUpObject`,params);
}

//申请单schema以下选中权限
export const getFlowPermissionsPanelObject= (params: any): Promise<any> => { 
	return fetchPost(`/user/permissionCollection/accessPermission/getPermissionsPanelObject`,params);
}

/**
 * 购物车-获取分层sdt连接列表
 */
export const getCartConnection = () => {
  return fetchGet(`/user/connections/accessPermission/getCartConnection`);
}

interface IPermissionNodeParams {
  connectionId: number
  connectionType: string
  nodeType: string
  nodeName: string
  nodePath: string
  nodePathWithType: string
}
//购物车-node字节点
export const getCartNode = (params: IPermissionNodeParams) => {
	return fetchPost(`/dms/meta/accessPermission/getCartNode`, params)
} 

//购物车schema以上选中权限
export const getCartPermissionsPanelUpObject= (params: any): Promise<any> => { 
	return fetchPost(`/user/permissionCollection/accessPermission/getCartPermissionsPanelUpObject`,params);
}
//购物车schema以下选中权限
export const getCartPermissionsPanelObject= (params: any): Promise<any> => { 
	return fetchPost(`/user/permissionCollection/accessPermission/getCartPermissionsPanelObject`,params);
}

//购物车 删除node
export const deleteCartNode= (params: {
  nodePath: string; 
  nodePathWithType: string;
  nodeType: string;
}): Promise<any> => { 
	return fetchPost(`/api/flow/accessPermission/deleteCartNode`,params);
}

//购物车 删除node
export const saveCartDrafte= (params: any): Promise<any> => { 
	return fetchPost(`/api/flow/accessPermission/saveDraft`,params);
}

/**
 * 购物车提交
 */

export const startFlowAccessPermission: any = (params: {
  priGranType: string
  endTime?: string
  beginTime?: string
  title: string
  remark?: string;
  flowType: string
}) => {
  return fetchPost(`/api/flow/accessPermission/apply`, params)
}

export const dealWithAccessPermission = (params: {
  flowUUID: string
  powerStatus: string
  remarks: any
}) => {
  return fetchPost(`${baseUrl}/flow/accessPermission/dealWithPermission`, params)
}


/**
 * 申请单-获取分层sdt连接列表
 */
export const getApplyCartConnection = (params: {flowMainUUID: string}) => {
  return fetchPost(`/user/connections/accessPermission/getApplyCartConnection`,params);
}

interface IPermissionNodeParams {
  connectionId: number
  connectionType: string
  nodeType: string
  nodeName: string
  nodePath: string
  nodePathWithType: string;
  flowMainUUID: string;
}
//申请单-node字节点
export const getApplyCartNode = (params: IPermissionNodeParams) => {
	return fetchPost(`/dms/meta/accessPermission/getApplyCartNode`, params)
}

//申请单schema以上选中权限
export const getApplyCartPermissionsPanelUpObject= (params: any): Promise<any> => { 
	return fetchPost(`/user/permissionCollection/accessPermission/getApplyCartPermissionsPanelUpObject`,params);
}
//申请单schema以下选中权限
export const getApplyCartPermissionsPanelObject= (params: any): Promise<any> => { 
	return fetchPost(`/user/permissionCollection/accessPermission/getApplyCartPermissionsPanelObject`,params);
}