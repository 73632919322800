import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'src/hook'
import { Form, message, Input, Select } from 'antd'
import { UIModal } from 'src/components'
import { getWizardFormItem } from '../wizardFormItem'
import {
  getWizardForm,
  WizardItem,
  generateCreateSql,
  GenerateSqlRequest,
  getCharacterSetOrder,
  executeSqlStatement,
} from 'src/api'
import { FormLayout } from 'src/constants'
import {
  finishCreateDatabase,
  setVisibleCreateDatabase,
} from './createDatabaseSlice'
import { refreshOnRoot } from 'src/pageTabs/queryPage/sdt'

export const CreateDatabaseModal = () => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  // generated sql
  const [generatedSql, setGeneratedSql] = useState('')

  const { visibleCreateDatabase } = useSelector(
    (state) => state.wizards.createDatabase,
  )
  const { connectionType = 'MySQL', connectionId = 0 } = useSelector(
    (state) => state.sdt.selectedNode,
  )
  const [formSpec, setFormSpec] = useState<WizardItem[]>([])
  const [databaseForm] = Form.useForm()
  const dispatch = useDispatch()

  const generateSql = () => {
    const tryGenerate = (userInputs: any) => {
      const params: GenerateSqlRequest = {
        nodeType: 'database',
        dataSourceType: connectionType!,
        userInputs,
      }
      generateCreateSql(params).then(({ generatedSql }) => {
        setGeneratedSql(generatedSql)
      })
    }
    databaseForm
      .validateFields()
      .then((userInputs) => {
        tryGenerate(userInputs)
      })
      .catch((e) => {
        const { values } = e || {}
        tryGenerate(values)
      })
  }

  const handleCreateDatabase = useCallback(
    (generatedSql) => {
      setConfirmLoading(true)
      executeSqlStatement({
        connectionId,
        dataSourceType: connectionType,
        statements: [generatedSql],
        tabKey: 'ALL',
        actionType: 'EXECUTE',
      })
        .then((data) => {
          const { executionInfos } = data
          const [{ response }] = executionInfos
          if (response?.executeError) {
            message.error(response.executeError.message)
          } else {
            message.success('数据库添加成功')
            dispatch(refreshOnRoot())
            dispatch(setVisibleCreateDatabase(false))
          }
        })
        .catch(() => {})
        .finally(() => setConfirmLoading(false))
    },
    [connectionId, connectionType, dispatch],
  )

  useEffect(() => {
    if (!visibleCreateDatabase) return
    getWizardForm({
      dataSourceName: connectionType,
      nodeType: 'database',
    }).then(setFormSpec)
    return () => {
      setGeneratedSql('')
    }
  }, [visibleCreateDatabase, connectionType])

  const [collationOptions, setCollationOptions] = useState([])

  const characterOptions = (
    (formSpec.find((spec) => spec.field === 'character') || {}).options || []
  ).map(({ key: value, title: label }: any) => ({ label, value }))

  return (
    <UIModal
      title="添加数据库"
      visible={visibleCreateDatabase}
      onCancel={() => dispatch(finishCreateDatabase())}
      onOk={() => handleCreateDatabase(generatedSql)}
      afterClose={() => databaseForm.resetFields()}
      confirmLoading={confirmLoading}
    >
      <Form
        name="create-database"
        form={databaseForm}
        onValuesChange={() => {
          setTimeout(() => {
            // ? ref: https://github.com/ant-design/ant-design/issues/26747
            generateSql()
          })
        }}
        {...FormLayout}
        size="small"
      >
        {formSpec
          ?.filter((spec) => !['字符集', '排序规则'].includes(spec.label))
          .map((spec) => getWizardFormItem({spec}))}

        <Form.Item label="字符集" name="character" hidden={!characterOptions?.length}>
          <Select
            placeholder="请选择字符集"
            options={characterOptions}
            onChange={(character) => {
              databaseForm.setFields([{ name: 'collation', value: undefined }])
              setCollationOptions([])
              const params = {
                connectionType,
                condition: String(character),
                connectionId,
              }
              getCharacterSetOrder(params).then((collations) => {
                const collationOptions = (collations || []).map(
                  ({ key: value, title: label }: any) => ({
                    value,
                    label,
                  }),
                )
                setCollationOptions(collationOptions)
              })
            }}
            showSearch
            allowClear
          />
        </Form.Item>
        <Form.Item noStyle dependencies={['character']} hidden={!collationOptions?.length}>
          {({ getFieldValue }) => (
            <Form.Item label="排序规则" name="collation">
              <Select
                disabled={!Boolean(getFieldValue('character'))}
                placeholder="请选择排序规则"
                options={collationOptions}
                showSearch
                allowClear
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item label="生成 SQL">
          <Input.TextArea
            readOnly
            style={{ backgroundColor: '#fefefe', height: 240 }}
            value={generatedSql}
          />
        </Form.Item>
      </Form>
    </UIModal>
  )
}
