import { createSlice, PayloadAction , createAsyncThunk} from '@reduxjs/toolkit'
import { AppThunk } from 'src/store'
import { batch } from 'react-redux'
import * as _ from 'lodash'
import { getPermissionList, queryGroupNodes_automic } from 'src/api'
import { getTargetNode, specialFormatTree } from './utils';
import {
  getAccessPermissionNode, 
  getSelectedPermissionCollectionNodes,
  getAccessPermissionConnections
} from 'src/api'

interface AuthorizationAuthorizationManageState {
  permissionList: any[];
  authorizeUserList: any[];
  expendKeys: any[];
  //权限集tree
  loadedTreeNodeMap:  Record<string, any[]>,
  loadedKeys: string[];
  selectedTreeNode: any;
  newTreeData: any[];
  treeNodeChildrenFetching: any;
  permissionCollections: any;
  connectionObjectPermissions: any;
  resourceTotal: number;
}

const initialState: AuthorizationAuthorizationManageState = {
  permissionList: [],
  authorizeUserList: [],
  expendKeys: [],
  loadedTreeNodeMap:{},
  loadedKeys: [],
  newTreeData: [],
  selectedTreeNode: {},
  treeNodeChildrenFetching: {},
  permissionCollections: {},
  connectionObjectPermissions: {},
  resourceTotal: 0,
}


export const getTreeNodeChildren = createAsyncThunk<
  any[],
  any,
  { state: any }
>('visitRequestOperate/getTreeNodeChildren', async (params, { dispatch, getState }) => {
  
  const {
    sdt: { treeNodeChildrenMap, treeNodeChildrenFetching },
    sdtPermission: { status = false }
  } = getState()

  const list = await dispatch(fetchTreeNodeChildren({
    ...params,
  })).unwrap()
 
  return list
})

// 更新树节点 children
export const fetchTreeNodeChildren = createAsyncThunk<
  any,
  any,
  { state: any }
>(
  'visitRequestOperate/fetchTreeNodeChildren',
  async (params) => {
    let children = [];
    if (params?.nodeType) {
      children = await getAccessPermissionNode(params);
    }
    return children
  },
  {
    condition({ nodePath }, { getState }) {
      const {
        sdt: { treeNodeChildrenFetching },
      } = getState()
      const fetching = treeNodeChildrenFetching[nodePath]
      // 应用当前已经在获取数据，则不再重复请求
      return !fetching
    },
  },
)

export const fetchConnections = (params?: {ip?: string}): AppThunk => async (dispatch, getState) => {
  const {
    sdt: { expandedKeys },
    sdtPermission: { status }
  } = getState()

  getAccessPermissionConnections(params || {})
    .then((list) => {
  
    const filteredConnections = list?.nodeList?.filter(((node: any)=> node?.nodeType === 'connection'));
    const result = filteredConnections?.reduce((acc: any, curr: any) => {
      const formatTreeNode = {
        ...curr,
        key: curr?.nodePathWithType,
        title: curr?.nodeName,
        connectionId: curr?.id,
        dataSourceType: curr?.connection?.connectionType,
        isLeaf: false,
      }
      const existingItem = acc.find((item: any) => item?.dataSourceType === curr?.connection?.connectionType);
      if (existingItem) {
        existingItem.children.push(formatTreeNode);
      } else {
       const dataSourceType = curr?.connection?.connectionType;
        acc.push({
          key: `/${dataSourceType}`,
          newNodeType: 'datasource',
          nodeType:'datasource',
          title: dataSourceType,
          dataSourceType: dataSourceType,
          id: dataSourceType,
          nodeName:dataSourceType,
          isLeaf: false,
          nodePath: `/${dataSourceType}`,
          children: [formatTreeNode],
          checkable: false,
        });
      }
      return acc;
    }, []);
   
      dispatch(setNewTreeData(result))
      dispatch(setSelectedNode(result?.[0]))
      dispatch(setResourceTotal(list?.total || 0))
      batch(() => {
        dispatch(setLoadedKeys([]))
        // dispatch(setExpandedKeys([...expandedKeys]))
      })
    })
    .finally(() => {

    })
}
export const refreshOnRoot = (params: {ip?: string}): AppThunk => (dispatch) => {
 
  dispatch(fetchConnections(params))
}

export const visitRequestOperationSlice = createSlice({
  name: "visitRequestOperate",
  initialState,
  reducers: {
    setPermissionList: (state, action: PayloadAction<any[]>) => {
      state.permissionList = action.payload;
    },
    setAuthorizeUserList: (state, action: PayloadAction<any[]>) => {
      state.authorizeUserList = action.payload;
    },
    setSelectedNode: (state, action: PayloadAction<any>) => {
      state.selectedTreeNode = action.payload
    },
    setResourceTotal: (state, action: PayloadAction<any>) => {
      state.resourceTotal = action.payload
    },
    setNodeType: (state, action: PayloadAction<any[]>) => {
      state.expendKeys = action.payload;
    },
    setExpandedKeys: (state, action: PayloadAction<any[]>) => {
      state.expendKeys = action.payload;
    },
    setLoadedTreeNodeMap: (state, action: PayloadAction<any>) => {
      state.loadedTreeNodeMap = action.payload;
    },
    setLoadedKeys: (state, action: PayloadAction<any[]>) => {
      state.loadedKeys = action.payload;
    },
    setNewTreeData: (state, action: PayloadAction<any[]>) => {
      state.newTreeData = action.payload;
    },
    setTreeDataNodeChildren: (
      state,
      action: PayloadAction<{
        nodePath: string
        children: any[]
      }>,
    ) => {
      const { nodePath, children } = action.payload
      const parent = getTargetNode(nodePath, state.newTreeData)
      if (parent) {
        parent.children = children
      }
    },
    //存储当前选中权限内容
    updatePermissionCollections: (state, action: PayloadAction<any>) => {
      const newValue = action.payload
      state.permissionCollections = {...state.permissionCollections, ...newValue};
    },
    resetPermissionCollections: (state) => {
      state.permissionCollections = {}
    },
    updateConnectionObjectPermissions: (state, action: PayloadAction<any>) => {
      const settings = action.payload
      state.connectionObjectPermissions = settings;
    },
    resetConnectionObjectPermissions: (state) => {
      state.connectionObjectPermissions = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTreeNodeChildren.pending, (state, action) => {
      const { key } = action.meta.arg
      const { treeNodeChildrenFetching } = state
      treeNodeChildrenFetching[key] = true
    })
    builder.addCase(fetchTreeNodeChildren.fulfilled, (state, action) => {
      const list = action.payload
      const { key } = action.meta.arg
    
      const target: any = getTargetNode(key, state.newTreeData)
      if (target) {
        
        if (list?.length) target.children = specialFormatTree(list,false,target?.roleId)
        else target.children = []
      }
      state.treeNodeChildrenFetching[key] = false
    })
    builder.addCase(fetchTreeNodeChildren.rejected, (state, action) => {
      const { nodePath } = action.meta.arg
    
      state.loadedKeys = state.loadedKeys.filter((key) => key !== nodePath)
      state.treeNodeChildrenFetching[nodePath] = false
    })
  }
});

export const visitRequestOperationReducer = visitRequestOperationSlice.reducer

export const { setPermissionList, setAuthorizeUserList,
  setLoadedTreeNodeMap,
  setLoadedKeys,
  setNewTreeData,
  setSelectedNode,
  setExpandedKeys,
  setResourceTotal,
  updatePermissionCollections, 
  resetPermissionCollections,
  updateConnectionObjectPermissions,
  resetConnectionObjectPermissions
 } = visitRequestOperationSlice.actions;

export const queryPermissionList =
  (dataSourceType: string): AppThunk =>
    (dispatch) => {
    getPermissionList(dataSourceType).then((res) => {
      dispatch(setPermissionList(res))
    })
  }


