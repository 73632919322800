import React, { FC, useContext } from "react";
import { Card, Tooltip } from "antd";
import moment from "moment";
import { RedoOutlined } from '@ant-design/icons'
import { FormContext } from "../ApplyFormContext";
import styles from "./index.module.scss";
import classnames from "classnames";

interface IExecuteChange {
  onRefresh?: () => void;
  [p: string]: any
}

export const ExecuteChange: FC<IExecuteChange> = ({
  onRefresh
}) => {
  const { currentContextValue = {} } = useContext(FormContext);

  const { id = "", executeLogPath, rollbackLogPath } = currentContextValue;

  //工单管理 只展示日志 自动执行展示时间
  return (
    <Card
      title={
        <>
          执行变更
          <span style={{ fontSize: 14 }}>
            <span style={{ color: "#3262FF" }}>
              &nbsp; &nbsp;{currentContextValue?.executeStatus} &nbsp; &nbsp;
            </span>
            {currentContextValue?.executeTime &&
              !(
                currentContextValue?.executeTimeType === 1 &&
                currentContextValue?.executeStatus === "待执行"
              ) && (
                <span>
                  执行时间:
                  <span style={{ color: "#868FA3" }}>
                    &nbsp; &nbsp;
                    {moment(currentContextValue?.executeTime).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </span>
                </span>
              )}
              {currentContextValue?.executeStatus === '执行中' && 
              <Tooltip title='刷新'>
                 <RedoOutlined style={{marginLeft: 8, color: '#3262FF'}} onClick={() =>onRefresh && onRefresh()}/>
              </Tooltip>}
          </span>
        </>
      }
      className={classnames(styles["step-card"], styles["executeChange-card"])}
    >
        <div>
          <div style={{ color: "#667084" }}>
            执行日志：
            {executeLogPath ? (
              <span
                style={{
                  color: "#3262FF",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() =>
                  executeLogPath &&
                  window.open(`/api/flow/dataChange/${id}/dataChangeLog`)
                }
              >
                查看日志
              </span>
            ) : (
              "-"
            )}
          </div>
            <div style={{ color: "#667084" }}>
              回退日志：
              {rollbackLogPath ? (
                <span
                  style={{
                    color: "#3262FF",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() =>
                    rollbackLogPath &&
                    window.open(
                      `/api/flow/dataChange/${id}/dataChangeRollbackLog`
                    )
                  }
                >
                  查看日志
                </span>
              ) : (
                "-"
              )}
            </div>
        </div>
    </Card>
  );
};
