import React, { useEffect, useMemo, useState } from "react";
import { Button, Space, Spin,  Card, Input, message, Tag, Tooltip, Table, Modal } from "antd";
import styles from "../index.module.scss";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useRequest, useSelector } from "src/hook";
import { batchDelMessages, getAllMes, getAllReadMes, getAllUnReadMes, putReadMes, putReadMess } from "src/api";
import { getMessages } from "src/store/extraSlice/useMesSlice";
import classnames from "classnames";
import type { TablePaginationConfig } from 'antd/lib/table'
import { useHistory } from "react-router-dom";
import type { ColumnsType } from 'antd/es/table';

interface IProps {
	[p: string]: any
}

interface DataType {
  key: React.Key;
  title: string;
  sender: string;
  createdAt: string;
  msgSourceType: string;
  [p: string]: any
}

interface MesType {
  [key: string]: {
    name: string
    opName: string
    opClick: (id: number, record: any, msgId?: number) => void
  }
}

const UserMesMain = (props: IProps): any => {

  const dispatch = useDispatch()
  const history = useHistory()
  const userId: string = useSelector((state) => state.login.userInfo.userId) || '';
  const curKey: string[] = useSelector((state) => state.message.curKey) || '';
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);


  const {
    data: dataSource,
    loading,
    run: getMes,
    refresh: refreshMes,
  } = useRequest(
    curKey[0] === 'allMessage'? getAllMes 
    : curKey[0] === 'readMessage'? getAllReadMes 
    : curKey[0] === 'notReadMessage'? getAllUnReadMes 
    : ()=>{}, 
  {
    defaultParams: [ searchValue, 1, 10],
    onSuccess: () => {
      // 每次刷新列表时重置选中项
      setSelectedRowKeys([])
    },
  })


  useEffect(() => {
    Promise.all([
      setPage(1),
    ]).then(() => {
      if (!!curKey) {
        getMes(searchValue, 1, pageSize)
      }
    })
  }, [JSON.stringify(curKey)])

  const mesTypeMap: MesType = {
    EXPORT: {
      name: '导出任务',
      opName: '查看',
      opClick: async (id: number) => {
        await putReadMes(id)
        dispatch(getMessages(userId))
        history.push('/download')
      },
    },
    PROCESS: {
      name: '流程消息',
      opName: '查看',
      opClick: async (id: number, msgId: any, record: any) => {
        await putReadMes(id)
      },
    },
    AUDIT_WARNING: {
      name: '审计告警',
      opName: '查看',
      opClick: async (id: number, msgId) => {
        await putReadMes(id)
        dispatch(getMessages(userId))
        // TODO: 审计详情表格
        refreshMes()
      },
    },
    ALARM: {
      name: '告警通知',
      opName: '',
      opClick: async () => {},
    },
  }

  const columns: ColumnsType<DataType> = [
    {
      title: '标题内容',
      dataIndex: 'title',
      key: 'title',
      width: 300,
      render: (txt: any, r: any) => {
        const content = `${r?.message + (r?.flowUUID || '') + '  '+ (r?.applyStatus || '')}` 
        return (
          <div className={styles.titleWrap}>
            <Tooltip title={content}>
              {
                r?.type !== 'other' ?
                <div
                  className={classnames(styles.title, r?.status === '已读'? styles.readTitle : styles.notReadTitle, styles.titleHover)}
                  onClick={() => {
                    mesTypeMap['PROCESS']?.opClick(r?.id, r?.msgId, r)
                      history.push({
                        pathname: r.type === "dataCorrection" || r.type === "publishChanges" ?
                            // 发布变更
                            //数据变更
                            (r.applyType === "APPROVAL" ?
                                    `/data_change_mine_approve/${r?.dataChangeId}/detail`
                                    :
                                    `/data_change_mine_apply/${r?.dataChangeId}/detail`
                            )
                            :
                            (r.applyType === "APPROVAL" ?
                                    `/mine_approve/${r?.flowUUID}/detail`
                                :
                                    `/mine_apply/${r?.flowUUID}/detail`
                            ),
                        state: {
                          ...r,
                          mainUUID: r?.flowMainUUID,
                          originType: 'message'
                        }
                      })
                  }}
                >
                  {content}
                </div>
                :
                <div
                  className={classnames(styles.title, r?.status === '已读'? styles.readTitle : styles.notReadTitle)}
                >
                  {content}
                </div>
              }
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: '发送者',
      dataIndex: 'sender',
      key: 'sender',
      width: 150,
    },
    {
      title: '时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
    },
    {
      title: '消息类型',
      dataIndex: 'msgSourceType',
      key: 'msgSourceType',
      width: 100,
      render: (t: string) => mesTypeMap[t]?.name,
    },
  ];

  const onSelectChange = (newSelectedRowKeys: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: any = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const pagination: TablePaginationConfig = {
    current: page,
    total: dataSource?.total,
    onChange: (page: any, pageSize: any) => {
      getMes( searchValue, page, pageSize || 10)
      setPage(page)
      setPageSize(pageSize || 10)
    },
    // size: 'default'
    showTotal: (total: any) => `共 ${total} 条`,
  }

  const getTitle = () => {
    return (
      <span style={{ color: '#333333', marginRight: 9, }}>{
        curKey[0] === 'allMessage'? '全部消息'
        : curKey[0] === 'readMessage'? '已读消息'
        : '未读消息'
      }</span>
    )
  }

  const renderTitle = (
    <div className={styles.treeHeader}>
				<Space>
					{getTitle()}
				</Space>
				<Input
          allowClear
          placeholder="搜索标题内容"
          prefix={<SearchOutlined />} 
          style={{ width: 225}}
          onChange={(e: any) => {
            const val = e?.target.value || ''
            setSearchValue(val)
            getMes( val, page, pageSize)
          }}
        />
			</div>
  )

  const renderList = useMemo(() => {
    return (
      <div className={styles.mesListWrap}>
        <Table
          rowKey='id'
          dataSource={dataSource?.data || []}
          columns={columns}
          rowSelection={rowSelection}
          pagination={pagination}
          scroll={{ y: 500 }}
        />
      </div>
    )
  }, [dataSource, columns, page, pageSize]);

	return (
		<div className={styles.userMesWrap}>
			<Spin spinning={loading}>
        <Card
          key={'userMesCard'}        
          title={renderTitle}
          bordered={false}
          extra={[
            <Button
              style={{
                marginRight: 6
              }}
              onClick={async () => {
                if (selectedRowKeys.length) {
                  Modal.confirm({
                    content: '确定删除吗？',
                    okText: '确认',
                    cancelText: '取消',
                    async onOk() {
                      await batchDelMessages({ids: selectedRowKeys})
                      message.success("删除成功")
                      setSelectedRowKeys([])
                      refreshMes()
                    },
                    onCancel() {},
                  })
                } else {
                  message.info('未勾选信息')
                }
              }}
            >
              删除
            </Button>,
            <Button
              type='primary'
              onClick={async () => {
                if (selectedRowKeys.length) {
                  await putReadMess(selectedRowKeys)
                  message.success("修改成功")
                  dispatch(getMessages(userId))
                  refreshMes()
                } else {
                  message.info('未勾选未读信息')
                }
              }}
              disabled={curKey[0] === 'readMessage'}
            >
              标记已读
            </Button>
          ]}
        >
          {renderList}
        </Card>
      </Spin>
		</div>
	);
};

export { UserMesMain };