/**
 * 连接tabs
 */
import React, { useEffect, useState } from "react"
import { Tabs, Divider, Popconfirm } from 'antd'
import { useRequest, useDispatch, useSelector } from 'src/hook'
import { getdmsConnectionConfig } from 'src/api'
import ConnectionOverview from './ConnectionOverview'
import ConnnectionManage from './ConnectionManage'
import ConnectionSetting from './ConnectionSetting'
import { ResourceConfig } from './main/resourceConfig/index'
import { setRefreshTabConnection } from 'src/pageTabs/connectionManagementPage/connectionManagementPageSlice'
import { pagePermissionCanEdit } from 'src/api'
import { Iconfont } from 'src/components'
import classnames from 'classnames'
import styles from './index.module.scss'

 
interface IProps {
  handleShowContentChange: (to: string, from?: string) => void
  [p: string]: any
}
const ConnectionTabs = (props: IProps) => {
  const dispatch = useDispatch()
  const { refreshTabConnection } = useSelector((state) => state.connectionManagement)

  const {
    connectionId,
    connectionName,
    dataSourceType,
    handleShowContentChange,
    handleEditContent,
    permissionlist,
    isFirstBuildConnection,
    setIsFirstBuildConnection,
    setIsBuildConnection,
  } = props

  const [curTabs, setCurTabs] = useState('1')
  const [canEdit, setCanEdit] = useState<boolean>(true)
  const [visibleGuidePopconfirm, setVisibleGuidePopconfirm] = useState<boolean>(isFirstBuildConnection);

  useEffect(() => {
    setVisibleGuidePopconfirm(isFirstBuildConnection)
  }, [isFirstBuildConnection])

  useEffect(()=>{
    if(connectionId){
      const nodePathWithType = `/CONNECTION:${connectionId}`
      queryPermission(nodePathWithType)
    }
  },[connectionId])

  // 获取当前模块编辑权限
  const queryPermission = async(nodePathWithType?: string) => {
    const params = {
      systemPermissionType: 'CONNECTION_MANAGEMENT',
      nodePathWithType
    }
    pagePermissionCanEdit(params).then((res: boolean) => {
      setCanEdit(res)
    }).catch((err: any)=>{
      console.error('获取页面权限失败', err)
    });
  }

  useEffect(()=>{
    if(refreshTabConnection){
      refreshConnectionPool()
      dispatch(setRefreshTabConnection(false))
    }
  },[refreshTabConnection])

  // 数据源信息查询
  const {
    data: connectionPool,
    loading: connectionPoolLoading,
    run: queryConnectionConfig,
    refresh: refreshConnectionPool
  } = useRequest(getdmsConnectionConfig, {
    manual: true,
    formatResult: (data: any) => {
      return data
        ?.map((item: any) => {
          if (item?.field === 'password') {
            item.value = item?.value?atob(item?.value):'';
          }
          return item
        })
        ?.map((item: any) => {
          //展示label映射
          let realValue = item?.value;
          
          if (item?.field !== 'connectionPort') {
            realValue  = item?.options
            ? item?.options?.find((e: any) => e?.key === item?.value)?.title
            : item?.value;
          }
          return ({
            ...item,
            label: item?.label,
            value: realValue,
          })
        })
        ?.filter((i: any) => i?.label)
    },
  })

  useEffect(() => {
    if (connectionId) {
      queryConnectionConfig(connectionId)
    }
  }, [connectionId, queryConnectionConfig])

  const handleTabsChange = (key: string) => {
    setCurTabs(key)
  }

  const version = connectionPool?.filter(
    (i: any) => i?.field === 'dataSourceVersion',
  )?.[0]?.value
  const remark = connectionPool?.filter((i: any) => i?.field === 'remark')?.[0]
    ?.value

  return (
    <>
    {
      visibleGuidePopconfirm && <div className={styles.mask}></div>
    }
    <div className={styles.connectionTabsWrap}>
      <div className={styles.topLine}>
        <Iconfont
          type={`icon-${dataSourceType}`}
          className={classnames(styles.mr10, styles.titleIcon)}
        />
        <div className={styles.info}>
          <div className={styles.parentName}>
            {dataSourceType}
            {version && <span className={styles.version}>{version}</span>}
          </div>
          <div className={styles.selfName}>
            {connectionName}
            <Divider type="vertical" />
            <span className={styles.remark}>{remark}</span>
          </div>
        </div>
      </div>
      <Tabs onChange={handleTabsChange} activeKey={curTabs} className={styles.connectionTabs}>
        <Tabs.TabPane tab="连接概览" key="1" />
        <Tabs.TabPane tab={
          visibleGuidePopconfirm? 
          <Popconfirm
            title="请确保相应资源已经开启，否则无法进行授权管理等操作"
            onConfirm={() => {
              setVisibleGuidePopconfirm(false);
              setIsFirstBuildConnection(false);
              setIsBuildConnection(false);
            }}
            onCancel={(event: any) => {
              setVisibleGuidePopconfirm(false); 
              setIsFirstBuildConnection(false);
              setIsBuildConnection(false);
              event.stopPropagation()
            }}
            okText={"立即前往设置"}
            cancelText={"忽略"}
            visible={visibleGuidePopconfirm}
            icon={null}
            overlayClassName={styles.guidePopconfirm}
            cancelButtonProps={{
              style: {
                backgroundColor: '#AAAAAA',
                color: 'white'
              }
            }}
          >
            <span
              className={styles.zIndex999}
            >资源管理</span>
          </Popconfirm>
          : "资源管理"
        } key="2"/>
        <Tabs.TabPane tab="连接池管理" key="3" />
        <Tabs.TabPane tab="连接配置" key="4" />
      </Tabs>

      {/* 连接概览详情 */}
      {curTabs === "1" && (
        <ConnectionOverview
          connectionPool={connectionPool}
          connectionPoolLoading={connectionPoolLoading}
          connectionId={connectionId}
          connectionName={connectionName}
          dataSourceType={dataSourceType}
          setCurTabs={setCurTabs}
          handleEditContent={handleEditContent}
          handleShowContentChange={handleShowContentChange}
          permissionlist={permissionlist}
          canEdit={canEdit}
        />
      )}

      {/* 资源管理 */}
      {curTabs === "2" && (
        <ResourceConfig
          connectionId={connectionId}
          connectionType={dataSourceType}
          permissionlist={permissionlist}
          canEdit={canEdit}
        />
      )}

      {/* 连接池管理 */}
      {curTabs === "3" && (
        <ConnnectionManage
          connectionId={connectionId}
          dataSourceType={dataSourceType}
          permissionlist={permissionlist}
          canEdit={canEdit}
        />
      )}

      {/* 连接设置 */}
      {curTabs === "4" && 
        <ConnectionSetting
          dataSourceType={dataSourceType}
          connectionId={connectionId}
          permissionlist={permissionlist}
          canEdit={canEdit}
        />
      }
    </div>
    </>
  );  
}
export default ConnectionTabs