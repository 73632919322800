import React from 'react'
import { useSelector } from 'src/hook'
import * as _ from 'lodash';
import { Col, Row, Tooltip } from 'antd'
import { activeResultSelector, paneInfoMapSelector } from '../resultTabs/resultTabsSlice'
import { activePaneInfoSelector } from '../queryTabs/queryTabsSlice'
import { EncodingDropdownMenu } from './EncodingDropdownMenu'
import { EndOfLineSelector } from './EndOfLineSelector'
import { TextButton } from './TextButton'
import styles from './bottomToolbar.module.scss'
import { durationToMilliseconds } from 'src/util';

export const BottomToolbar = React.memo(() => {
  const activePaneInfo = useSelector(activePaneInfoSelector)
  
  const activeResult = useSelector(activeResultSelector)
  const paneInfoMap = useSelector(paneInfoMapSelector)
  const encodingSet = useSelector(state => state.login.userInfo.personalSettings?.encoding)

  const allTabLogs = useSelector((state) => state.logs)
  // 光标位置
  const cursorPosition = activePaneInfo?.cursorPosition
  // 数据源语言
  const lang = activePaneInfo?.connectionType
  // encoding
  const encoding = activePaneInfo?.encoding || encodingSet || 'UTF-8'
  // end of line
  const endOfLine = activePaneInfo?.endOfLine
  // 执行语句和执行时间
  const statement = activeResult?.info.statement
  const executeTime = durationToMilliseconds(activeResult?.info.executeTime)
  const pageTotal = activeResult?.info.pageTotal
 
  //result tab key
  const tabLog: any= activePaneInfo?.key && allTabLogs[activePaneInfo?.key];
  const lastLog: any = _.last(tabLog?.logs || [])

  return (
    <div className={styles.toolbarWrapper}>
      <Row justify="space-between" gutter={16}>
        {
          paneInfoMap?.pending && <Col><TextButton>执行中...</TextButton></Col>
        }
        <Col>
          <Row gutter={8}>
            {statement && (
              <Col>
              <Tooltip title={lastLog?.statement || ''}>
                <TextButton>{lastLog?.statement?.replace(/^(.{20}).+$/, (match: any, p1: string) => p1 + '...')}</TextButton>
              </Tooltip>
              </Col>
            )}
            {/* 只要是数字都应该显示，包括 0 */}
            {typeof (executeTime) === 'number' && (
              <Col>
                <TextButton>{`执行时间：${executeTime}ms`}</TextButton>
              </Col>
            )}
            {typeof (pageTotal) === 'number' && (
              <Col>
                <TextButton>{`显示行数：${pageTotal}`}</TextButton>
              </Col>
            )}
          </Row>
        </Col>
        <Col>
          <Row gutter={8}>
            {cursorPosition && (
              <Col>
                <TextButton>{`行 ${cursorPosition.lineNumber}, 列 ${cursorPosition.column}`}</TextButton>
              </Col>
            )}
            {encoding && (
              <Col title="选择编码">
                <EncodingDropdownMenu>
                  <TextButton>{encoding}</TextButton>
                </EncodingDropdownMenu>
              </Col>
            )}
            <EndOfLineSelector
              key={activePaneInfo?.key}
              endOfLine={endOfLine}
            />
            {lang && (
              <Col>
                <TextButton>{lang}</TextButton>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  )
})
