export const countCharactersLength = (str: string) => {
  let count = 0;
  for(let i=0; i<str?.length; i++){
    if(str.charCodeAt(i) > 255) {
      count += 2;
    }else {
      count += 1;
    }
  }
  return count
}

export const getCharactersPosition = (str: string, count: number) => {
  let length = count
  let position = 0
  for(let i=0; i<str?.length && length > 0; i++){
    if(str.charCodeAt(i) > 255) {
      length -= 2
    }else{
      length -= 1
    }
    if(length >= 0){
      position += 1
    }
  }
  // position 向下取整返回
  return Math.floor(position)
}