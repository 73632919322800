// 执行历史
import React, { useState, useRef } from 'react'
import { UICard } from 'src/components'
import { CustomColumnTable } from '../audit/executionPage/CustomColumnTable'
import { SearchHeader } from './SearchHeader'


export const UserSqlLogs = () => {

  const customColumnTableRef = useRef(null);
  const [tableParams, setTableParams] = useState<any>({});

  const refresh = () => {
    // @ts-ignore
    customColumnTableRef.current?.refresh();
  }

  return (
    <UICard extra={
      <SearchHeader
        setSearchParams={(values: any) => {
            setTableParams({ ...values })
          }
        }
        refresh={() => refresh()}
      />
    } bodyStyle={{ minHeight: 509 }}>
      <CustomColumnTable
        isSqlLog={true}
        param={tableParams}
        ref={customColumnTableRef}
        scroll={{ y: "50vh" }}
        size={"small"}
        pagination={{
          showQuickJumper: true,
          style: {
            float: 'right', // 设置分页器浮动到右侧
            marginTop: '10px', // 可根据需要调整距离顶部的距离
          },
        }}
      />
    </UICard>
  )
}
