import React, {SetStateAction, useCallback, useMemo, useRef, useState} from 'react'
import {Dropdown, Menu, message, Spin} from 'antd'
import copy from 'copy-to-clipboard'
import {AgGridReact} from '@ag-grid-community/react'
import {GridReadyEvent, IDatasource, IGetRowsParams,} from '@ag-grid-community/core'
import {cloneDeep} from 'lodash'

import {ActioneType, executeSqlStatement, getTaskResult, makeResultAllExport, MessageData, QueryResult} from 'src/api'
import {useDispatch, useRequest, useSelector} from 'src/hook'
import {getColLimitLength, getLimitedString} from 'src/util'
import Service from 'src/service'
import {openFlowForm} from 'src/pageTabs/flowPages'

import {setTabExecutionStatus} from '../../queryTabs/queryTabsSlice'
import {AddSelectedResultExportModal, GridConfigBase, ResultSider} from '.'
import {useCopyableChange} from '../useCopyableChange'
import {MongoJsonViewerModal} from './MongoJsonViewerModal'
import {ResultToolbar} from './ResultToolbar'
import {AddResultExportModal} from './AddResultExportModal'
import styles from './grid.module.scss'
import {BLOCK_SIZE, defaultColDef, infiniteModeOptions} from './gridConfig/GridConfig'
import {updateResultTabs} from 'src/pageTabs/queryPage/resultTabs/resultTabsSlice'
import {ResultAllExport} from "./ResultAllExport";
import { useHandleExternalInfo } from 'src/hook/useHandleExternalInfo'
import { useDownloadNotificationRenderer } from 'src/features/websocket/useDownloadNotification';

export type IRightMenuKey = 'LookCell' | 'LookRow' | 'CopyCell' | 'CopyRow'

const MONGO_ENABLE_ACTION = ['refresh', 'view']

interface IProps {
  result: QueryResult
  [p:string]: any
}
export const ResultGridMongo = ({ result, queryKey, tabResultKey }: IProps) => {
  const { 
    resultTabMap,
  } = useSelector((state) => state.resultTabs)
  const { theme } = useSelector((state) => state.appearance)
  const dispatch = useDispatch()
  const { handleExternalInfo } = useHandleExternalInfo()
  const [renderNotification] = useDownloadNotificationRenderer()

  const {
    connectionId,
    dataSourceType,
    databaseName,
    operatingObject,
    statement,
    dataExport,
    tabKey,
    permissionResult,
    scale,
  } = result;

  const [loading, setLoading] = useState<boolean>(false);
  const [actionType, setActionType] = useState<ActioneType>("ROLLING_RESULT");

  const { paneInfoMap } = useSelector((state) => state.queryTabs);
  const txMode = paneInfoMap[tabKey!].txMode;

  const colFields = useRef([]) as { current: any[] };

  const formatterResultData = (resultData: string[]) =>
    resultData?.map((result, i) => {
      let row = JSON.parse(result);
      const rowKeys: string[] = Object.keys(row);
      colFields.current = Array.from(
        new Set(colFields.current.concat(rowKeys as any))
      );
      rowKeys.forEach((key) => {
        row[key] = JSON.stringify(row[key]);
      });
      return row;
    });

  /** 表格视图 columnDefs */
  const columnDefAry = colFields.current || [];
  const columnDefs = columnDefAry.map((field: string) => ({
    headerName: field,
    field: field,
    valueFormatter: ({ value }: { value: string }) =>
      getLimitedString(value, getColLimitLength(colFields.current.length)),
  }));

  // 结果集 allow copy
  const [copyableRef, allowCopy] = useCopyableChange();
  const gridApiRef = useRef<GridReadyEvent | null>(null);

  /* 获取选中单元格 */
  const getSelectCellData = useCallback(() => {
    const { column } = gridApiRef.current?.api.getFocusedCell()!;
    const { field } = column.getColDef();
    if (!field) return;
    const nodeList = gridApiRef.current?.api.getSelectedNodes();
    if (!nodeList || !nodeList[0]) return [];
    //获取选中的第0行
    const cellData = nodeList[0].data;
    //返回列名是这个的单元格
    console.log(cellData, '---0000');

    return cellData[field];
  }, []);

  /* 获取选中行 */
  const getSelectRowData = useCallback(() => {
    const rowList = gridApiRef.current?.api.getSelectedRows();
    if (!rowList || !rowList[0]) return [];
    return rowList[0];
  }, []);

  /* 单元格复制 */
  const handleCopyCell = useCallback(() => {
    const selectData = getSelectCellData();
    copy(selectData);
  }, [getSelectCellData]);

  /* 单行/多行复制 */
  const handleCopyRow = useCallback(() => {
    const rowList = gridApiRef.current?.api.getSelectedRows();
    if (!rowList || !rowList[0]) return;
    const waitCopyString = rowList.reduce((prev: string, curr: string[]) => {
      const waitCopyRow = Object.values(curr).join("\t") as any;
      return `${prev}\n${waitCopyRow}`;
    }, "");
    copy(waitCopyString);
  }, []);

  /* 单元格二次展开 */
  const handleLookCell = useCallback(() => {
      const { column } = gridApiRef.current?.api.getFocusedCell()!;
      const columnName = column.getColDef();

      const rowData = getSelectRowData();
      const newRowData = { ...rowData }; // 创建 rowData 的副本，不影响原有结果集的数据
      Object.keys(rowData).forEach((field: any) => {

          const resultValue = rowData[field]
          try {
              if (columnName != field){
                  //如果列名不一致，跳过这次循环
                  return
              }

              if (typeof resultValue === 'string') {
                  const cellDataJson = JSON.parse(resultValue)
                  if (typeof cellDataJson === 'object') {
                      newRowData[field] = cellDataJson;
                  } else {
                      //解析后还是非对象类型
                      newRowData[field] = {[field]: resultValue};
                  }
              }else if (typeof resultValue === 'object'){
                  //对象无需处理
                  newRowData[field] = resultValue
              } else {
                  newRowData[field] = {[field]: resultValue}
              }
          }catch (e){
              console.log("json转换失败：" + e)
              newRowData[field] = {[field]: resultValue}
          }
      });
      setSelectData(newRowData);
      setSelectDataViewModalVisible(true);
  }, [getSelectCellData]);

  /* 单行二次展开 */
  const handleLookRow = useCallback(() => {
    const rowData = getSelectRowData();
    const newRowData = { ...rowData }; // 创建 rowData 的副本，不影响原有结果集的数据
    Object.keys(rowData).forEach((field: any) => {
        const resultValue = rowData[field]
        try {
            if (typeof resultValue === 'string') {
                const cellDataJson = JSON.parse(resultValue)
                if (typeof cellDataJson === 'object') {
                    newRowData[field] = cellDataJson;
                } else {
                    //解析后还是非对象类型
                    newRowData[field] = {[field]: resultValue};
                }
            }else if (typeof resultValue === 'object'){
                //对象无需处理
                newRowData[field] = resultValue
            } else {
                newRowData[field] = {[field]: resultValue}
            }
        }catch (e){
            console.log("json转换失败：" + e)
            newRowData[field] = {[field]: resultValue}
        }
    });
    setSelectData(newRowData);
    setSelectDataViewModalVisible(true);
  }, [getSelectRowData]);

  const RightMenuHandleMap = useMemo(
    () =>
      ({
        CopyCell: handleCopyCell,
        CopyRow: handleCopyRow,
        LookCell: handleLookCell,
        LookRow: handleLookRow,
      } as { [key in IRightMenuKey]: any }),
    [handleCopyCell, handleCopyRow, handleLookCell, handleLookRow]
  );

  const handleRightMenu = useCallback(
    ({ key }: { key: IRightMenuKey }) => {
      if (key in RightMenuHandleMap) {
        RightMenuHandleMap[key]();
      } else {
        message.error(`右键菜单${key}`);
      }
    },
    [RightMenuHandleMap]
  );
  const rightMenu = useMemo(() => {
    return (
      <Menu onClick={handleRightMenu as any}>
        <Menu.Item key="LookCell">查看单元格</Menu.Item>
        <Menu.Item key="LookRow">查看单行</Menu.Item>
        <Menu.Divider />
        <Menu.Item key="CopyCell" disabled={!allowCopy || !permissionResult?.resultSetCopy}>
          复制单元格
        </Menu.Item>
        <Menu.Item key="CopyRow" disabled={!allowCopy || !permissionResult?.resultSetCopy}>
          复制单行/多行
        </Menu.Item>
      </Menu>
    );
  }, [allowCopy, handleRightMenu, permissionResult?.resultSetCopy]);

  const handleGridReady = (options: GridReadyEvent): void => {
    gridApiRef.current = options;
  };

  const handleViewCell = () => {
    // todo 查看行/查看单元格不同的提示
    const { column } = gridApiRef.current?.api.getFocusedCell() || {};
    const { field } = column?.getColDef() || {};
    if (field) {
      handleLookCell();
    } else {
      handleCopyRow();
    }
  };

  /* 选中数据 */
  const [selectData, setSelectData] = useState<any[]>([]);
  /* 查看详情 modal visible */
  const [selectDataViewModalVisible, setSelectDataViewModalVisible] =
    useState<boolean>(false);

  const isDataExportPermissionEnabled = Service.moduleService.isModuleExist(
    "/flow"
  )
    ? dataExport.status
    : false;

  /* 打开申请流程权限表单 */
  const applyDataExportPermission = async () => {
    dispatch(
      openFlowForm({
        type: "dataExport",
      })
    );
  };

  const [visibleCommonExport, setVisibleCommonExport] = useState(false);
  const [visibleSelectedExport, setVisibleSelectedExport] = useState(false);
  const [visibleExportAll, setVisibleExportAll] = useState(false);

  /** 获取可用总行数方法 */
  // const getTotal = useCallback(
  //   (callback: (v: number) => void) => {
  //     permissionExportNumber({
  //       connectionId,
  //       connectionType: result.dataSourceType,
  //       databaseName: result.databaseName,
  //       statement: result.statement,
  //       containTempTable: result.containTempTable,
  //       tabKey: result.tabKey,
  //     }).then((v) => {
  //       callback(v)
  //     })
  //   },
  //   [result, dataExport, visibleCommonExport, connectionId],
  // )

  const onOpenExportModal = (key: string) => {
    const visibleMap = {
      ResultExport: () => setVisibleCommonExport(true),
      SelectedResultExport: () => setVisibleSelectedExport(true),
      ResultAllExport: () => setVisibleExportAll(true),
    };
    if (key in visibleMap) {
      visibleMap[key as keyof typeof visibleMap]();
    }
  }

  const { fetchTaskResult } = useHandleExternalInfo(getTaskResult, (data: any) => {
    renderNotification(data)
  })

  const handleExportAllResult = useCallback(
    (data: any) => {
      const params = {
        connectionId: result.connectionId,
        connectionType: result.dataSourceType,
        databaseName: result.databaseName,
        operatingObject: result.operatingObject,
        statement: result.statement,
        containTempTable: result.containTempTable,
        tabKey: result.tabKey,
      }
      return makeResultAllExport(Object.assign({}, params, data)).then((data) => {
        fetchTaskResult(data)
      })
    },
    [result, fetchTaskResult],
  )

  /* 执行模块获取的初始块数据 */
  const isInitializeDataUsed = useRef<boolean>(false);
  /* 获取远程块数据 */
  const { run: fetchBlockData } = useRequest(
    async (offset: number = 0, rowCount: undefined | number = BLOCK_SIZE) => {
      /* 0 首块数据，第一次获取时使用初始化数据。第二次获取使用远程数据 */
      if (offset === 0 && !isInitializeDataUsed.current) {
        isInitializeDataUsed.current = true;
        return Promise.resolve([cloneDeep(result)]);
      }
      const payload = {
        connectionId,
        dataSourceType,
        databaseName,
        operatingObject,
        statements: [statement],
        offset,
        rowCount,
        tabKey,
        autoCommit: txMode === "auto",
        actionType: actionType,
      };
      
      const res = await executeSqlStatement(payload)
      const { executionInfos } = res
      let queryResult: QueryResult[] = []
      executionInfos.forEach((item) => {
        queryResult.push(item.response)
        handleExternalInfo({ type: 'LOG', msg: item.executeLogInfo.message })
      })
      return Promise.resolve(queryResult)
    },
    {
      manual: true,
      formatResult: (res: any) => formatterResultData(res[0]?.resultData),
      onSuccess: () => setActionType("ROLLING_RESULT"),
    }
  );

  const getLastRow = (blockData: any[]) => {
    if (!blockData) return -1;
    if (blockData?.length < BLOCK_SIZE) {
      const lastRowCount = gridApiRef.current?.api.getInfiniteRowCount() || 0;
      return lastRowCount + blockData.length - 1;
    }
    return -1;
  };

  const getRows: (params: IGetRowsParams) => void = useCallback(
    async (params) => {
      const { startRow, successCallback, failCallback } = params;
      try {
        dispatch(setTabExecutionStatus({ key: tabKey as any, pending: true }));
        // 0 获取本次块数据
        const blockData = await fetchBlockData(startRow);
        // console.log('get blockData', blockData)
        // 1 根据本次块数据数量决定是否继续无限滚动
        const lastRow = getLastRow(blockData);
        // console.log('lastRow', lastRow)
        successCallback(blockData, lastRow);
      } catch {
        failCallback();
      } finally {
        setLoading(false);
        dispatch(setTabExecutionStatus({ key: tabKey as any, pending: false }));
      }
    },
    [dispatch, fetchBlockData, tabKey]
  );

  const datasource: IDatasource = {
    getRows,
  };

  const handleRefresh = useCallback(() => {
    if (!gridApiRef.current) return;
    const { api } = gridApiRef.current;
    setLoading(true);
    api.ensureIndexVisible(0);
    api.setRowCount(0);
    api.purgeInfiniteCache();
    setActionType("REFRESH");
  }, []);

  const executePayload = useMemo<any>(
    () => ({
      connectionId,
      dataSourceType,
      operatingObject,
      databaseName,
      statements: [statement] || [],
      tabKey: queryKey,
    }),
    [
      connectionId,
      dataSourceType,
      databaseName,
      operatingObject,
      queryKey,
      statement,
    ]
  );

  // 放大缩小页面
  const handleScaleView = (num: number) => {
    //设置单独结果集scale值 queryKey {}
    const resultTabs = Object.keys(resultTabMap).map(key => {
      if (key === tabResultKey) {
        return {
          key,
          info: {
            ...resultTabMap[key].info,
            scale: num
          }
        }
      }
      return resultTabMap[key]
    })

    dispatch(updateResultTabs(resultTabs))
  }
  return (
    <div className={styles.resultGrid}>
      <div className={styles.resultContent}>
        <ResultToolbar
          showActions={true}
          enabledActions={MONGO_ENABLE_ACTION as any}
          onRefresh={handleRefresh}
          isDataExportPermissionEnabled={isDataExportPermissionEnabled}
          showExported={dataExport.showExported}
          applyDataExportPermission={applyDataExportPermission}
          onOpenExportModal={onOpenExportModal}
          onViewCell={handleViewCell}
          executePayload={executePayload}
          permissionResult={permissionResult}
          dataExport={dataExport}
          onScalePage={handleScaleView}
          scale={scale === undefined ? 100 : scale}
          connectionType={"MongoDB"}
        />
        <Dropdown overlay={rightMenu} trigger={["contextMenu"]}>
          <Spin spinning={loading} wrapperClassName={styles.gridSpinContainer}>
            <div
              id={`${tabResultKey}gridWrapper`}
              style={{ flex: 1 }}
              className={
                theme === "dark" ? "ag-theme-balham-dark" : "ag-theme-balham"
              }
              ref={copyableRef}
            >
              <AgGridReact
                {...GridConfigBase}
                {...infiniteModeOptions}
                datasource={datasource}
                columnDefs={columnDefs}
                defaultColDef={{ ...defaultColDef, editable: false }}
                onGridReady={handleGridReady}
                onCellFocused={(event) => {
                  if (event.rowIndex != null) {

                    const rowNode = event.api.getDisplayedRowAtIndex(
                      event.rowIndex,
                    )
                    event.api.deselectAll() //取消所有选中
                    rowNode?.setSelected(true)
                  }
                }}
              />
            </div>
          </Spin>
        </Dropdown>
      </div>
      <ResultSider>{`已加载文档总数: ${
        gridApiRef.current?.api.getInfiniteRowCount() || 0
      }`}</ResultSider>

      <MongoJsonViewerModal
        dataSource={selectData}
        visible={selectDataViewModalVisible}
        setVisible={setSelectDataViewModalVisible}
      ></MongoJsonViewerModal>
      <AddSelectedResultExportModal
        visible={visibleSelectedExport}
        setVisible={setVisibleSelectedExport}
        gridApi={gridApiRef.current?.api || null}
      />
      <AddResultExportModal
        result={result}
        // getTotal={getTotal}
        visible={visibleCommonExport}
        setVisible={setVisibleCommonExport}
        permissionResult={permissionResult}
      />
      <ResultAllExport
        result={result}
        visible={visibleExportAll}
        setVisible={setVisibleExportAll}
        hanldeExportAll={handleExportAllResult}
        permissionResult={permissionResult}
      />
    </div>
  );
};
