/**
 * 不同对象级别授权
 */
import React, { useEffect, useState } from 'react'
import { ShoppingCartOutlined, PlusOutlined } from "@ant-design/icons"
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
import type { TableRowSelection } from "antd/es/table/interface"
import { Badge, Button, Table, Spin, Checkbox, Tooltip, Space } from "antd"
import { getFlowPermissionsPanelObject } from "src/api"
import { useRequest, useDispatch, useSelector } from 'src/hook'
import { getScrollX, renderTableFields } from 'src/util'
import { Iconfont } from 'src/components'
import { FineGrainedPermissionModal } from '../components/FineGrainedPermissionModal';
import { updatePermissionCollections } from '../visitRequestOperateSlice'
import styles from "./index.module.scss"


interface IProps {
  selectTreeItem?: any;
  cartNumber?: boolean;
  needRefresh?: boolean;
  onCancelBatchOperation?: () => void;
  viewAppliedDetail?: boolean; //仅查看
  [p: string]: any
}

const renderGroupName = (nodeName: string) => nodeName === '表' ? '表名' : nodeName

const ObjectLevelAuthorization = ({
  cartNumber,
  selectTreeItem,
  roleId,
  updateParams,
  canEdit = true,
  viewAppliedDetail = false,
  filter, 
  needRefresh, 
  onCancelBatchOperation
}: IProps) => {

  const history = useHistory();

  const dispatch = useDispatch()
  const { permissionCollections } = useSelector((state) => state.visitRequestOperate)

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
  });
  const [columns, setColumns] = useState<any[]>([]);
  const [authorizeList, setAuthorizeList] = useState<any[]>([])
  const [canOperate, setCanOperate] = useState<boolean>(true)
  const [dataSource, setDataSource] = useState<any[]>([])
  const [permissionCollectionVOS, setPermissionCollectionVOS] = useState<any[]>([])
  const [defaultPermissionSetValues, setDefaultPermissionSetValues] = useState<any[]>([])
  //批量操作schema一下层级
  const [batchOperationState, setBatchOperationState] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isShowAuthModal, setIsShowAuthModal] = useState(false)
  const [selectedRows, setSelectedRows] = useState([]);
  // 查询对象集权限回显
  const { data: objectResult, run: queryPermissionsObject, loading } = useRequest(getFlowPermissionsPanelObject,
    {
      manual: true,
    }
  );

  const formatData = (res: any) => {

    const { authObjectVoList = [] } = res || {}
    // 初始化选中值
    const initPermissionCollectionVOS = authObjectVoList?.map((item: any) => {
      const { jsonObject, connectionType, nodePathWithType, nodeName, nodePath } = item
      const operations = Object.keys(jsonObject)?.map(i => {
        if (jsonObject[i]) {
          return i
        }
        return false
      })?.filter(i => i)

      if (operations?.length) {
        return {
          permissionCollectionObjectVO: {
            dataSourceType: connectionType,
            nodePathWithType: nodePathWithType,
            nodeName,
            nodePath,
            operations
          }
        }
      }
      return false
    })?.filter((i: any) => i);

    return initPermissionCollectionVOS;
  }

  useEffect(() => {

    if (_.isEmpty(objectResult)) return
    const list: any = formatData(objectResult);

    setAuthorizeList(objectResult?.headList || [])
    setPagination({
      current: 1,
      pageSize: 10,
      total: objectResult?.authObjectVoList?.length
    });
    const dataSource = objectResult?.authObjectVoList?.slice(0, 10) || []
    setDataSource(dataSource);

    const curItem = permissionCollections?.[selectTreeItem?.key]

    if (_.isEmpty(curItem)) {
      setDefaultPermissionSetValues(list)
      setPermissionCollectionVOS(list)
    } else {
      setPermissionCollectionVOS(curItem)
    }


    setCanOperate(canEdit && objectResult?.canOperation)
  }, [objectResult])

  useEffect(() => {

    if (!selectTreeItem) {
      return;
    }
    const {
      nodeName,
      nodePathWithType,
      nodeType,
      dataSourceType,
      sdt,
    } = selectTreeItem;
    const connectionType = dataSourceType || sdt?.connectionType
    const params = {
      connectionId: null,
      connectionType,
      nodeType,
      nodeName,
      nodePathWithType,
      roleId,
      filter
    };
    queryPermissionsObject(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectTreeItem?.key,needRefresh ]);

  useEffect(() => {
    setCanOperate(canEdit)
  }, [canEdit])

  useEffect(() => {
    setColumns((c: any[]) => {
      const arr: any[] = [{
        title: renderGroupName(selectTreeItem?.nodeName),
        dataIndex: "objectName",
        key: "objectName",
        width: 200,
        ellipsis: true,
        render: (txt: string) => <span><Iconfont className={styles.mr4} type={`icon-${selectTreeItem?.nodeType}`} /><Tooltip title={renderTableFields(txt)}>{renderTableFields(txt)}</Tooltip></span>
      },];
      authorizeList?.forEach((item: any) => {
        const itemChecked = !!dataSource?.length && !dataSource?.map((i: any) => i?.jsonObject)?.some((i: any) => !i[item?.operation])
        const indeterminate = !itemChecked && dataSource.map((i: any) => i?.jsonObject)?.some((i: any) => i[item?.operation])
        arr.push({
          title: (
            <Checkbox
              disabled={!canOperate || !dataSource.length}
              onChange={(e) => handleItemCheck(e.target.checked, item?.operation, dataSource)}
              checked={itemChecked}
              indeterminate={indeterminate}
            >
              {item?.operationName}
            </Checkbox>
          ),
          dataIndex: item?.operation,
          key: item?.operation,
          width: 120,
          render: (_: string, record: any) => (
            <Checkbox
              disabled={!canOperate}
              checked={record?.jsonObject?.[item?.operation]}
              onClick={(e) => handleAuhtorizeChange(e, item?.operation, record)}
            />
          ),
        });
      });
      return [...arr]
    });
  }, [authorizeList, canOperate, dataSource, selectTreeItem?.nodeName, selectTreeItem?.nodeType])

  useEffect(() => {
  
    // 更新参数
   
      updateParams && updateParams(permissionCollectionVOS);
   
    //更新store存储数据
    const permissionCollectionVOSClone = _.cloneDeep(permissionCollectionVOS)
    permissionCollectionVOSClone?.map((i: any)=>{
      const currentValue = permissionCollectionVOSClone?.filter((i: any)=>i?.permissionCollectionObjectVO?.key === selectTreeItem?.key)
      const permissionObj = {[selectTreeItem?.key]: currentValue}
      canEdit && dispatch(updatePermissionCollections(permissionObj))
    })
  
    //更新table选中状态
    setDataSource((d: any[]) => {
      return d?.map((i: any) => {
        i.jsonObject = {}
        if (permissionCollectionVOS?.map(i => i?.permissionCollectionObjectVO?.nodePathWithType)?.includes(i?.nodePathWithType)) {
          const operations = permissionCollectionVOS?.filter((r: any) => {
            return r?.permissionCollectionObjectVO?.nodePathWithType === i?.nodePathWithType
          })?.map(i => i?.permissionCollectionObjectVO?.operations)?.flat()
          operations?.forEach((element) => {
            i.jsonObject[element] = true;
          });
        }
        return { ...i }
      })
    })
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(permissionCollectionVOS), pagination])

  // 选择整列
  const handleItemCheck = (checked: boolean, checkboxOperation: any, dataSource: any[]) => {

    let clonePermissionCollectionVOS = _.cloneDeep(permissionCollectionVOS);
 
    const operations = checked ? [checkboxOperation] : [];
    if (!checked) {
      clonePermissionCollectionVOS = clonePermissionCollectionVOS?.map(item => {
        const itemOperation = item?.permissionCollectionObjectVO?.operations;
        const dsItem = dataSource.find(ds => ds?.nodePathWithType === item?.permissionCollectionObjectVO?.nodePathWithType);

        if (!_.isEmpty(dsItem)) {
          return {
            permissionCollectionObjectVO: {
              ...item?.permissionCollectionObjectVO,
              operations: itemOperation?.filter((o: string) => o !== checkboxOperation)
            }
          }
        }
        return item;
       
      })?.filter((item) => item?.permissionCollectionObjectVO?.operations?.length);
      setPermissionCollectionVOS(clonePermissionCollectionVOS);
      return 
    }

    let curPermissionCollectionVOS = dataSource?.map((i: any) => {
      return {
        permissionCollectionObjectVO: {
          dataSourceType: i?.connectionType,
          nodePathWithType: i?.nodePathWithType,
          nodeName: i?.objectName,
          nodePath: i?.nodePath,
          operations
        }
      }
    })
   
    //合并
    const newOperatins = mergeOperations([...curPermissionCollectionVOS, ...clonePermissionCollectionVOS])
    setPermissionCollectionVOS(newOperatins)
  }

  // 权限修改
  const handleAuhtorizeChange = (e: any, operation: string, record: any) => {

    const checked = e.target.checked;
    const checkedItem = {
      permissionCollectionObjectVO: {
        dataSourceType: record?.connectionType,
        nodePathWithType: record?.nodePathWithType,
        nodeName: record?.nodeName,
        nodePath: record?.nodePath,
        operations: [operation]
      }
    }

    let clonePermissionCollectionVOS = _.cloneDeep(permissionCollectionVOS);

    const changedItem = clonePermissionCollectionVOS?.find(item => item?.permissionCollectionObjectVO?.nodePathWithType === record?.nodePathWithType);
    if (_.isEmpty(changedItem)) {
      clonePermissionCollectionVOS = clonePermissionCollectionVOS.concat([checkedItem])
    } else {
      clonePermissionCollectionVOS = clonePermissionCollectionVOS.map(item => {
        const { permissionCollectionObjectVO = {} } = item;
        if (permissionCollectionObjectVO?.nodePathWithType === record?.nodePathWithType) {

          let operations = permissionCollectionObjectVO?.operations || [];

          if (checked) {
            operations = operations?.concat([operation])
          } else {
            operations = operations.filter((o: any) => o !== operation)
          }
          return {
            permissionCollectionObjectVO: {
              ...permissionCollectionObjectVO,
              operations
            }
          }
        }
        return item
      })?.filter((item) => item?.permissionCollectionObjectVO?.operations?.length);
    }

    setPermissionCollectionVOS(clonePermissionCollectionVOS)

  }

  // 页码修改
  const handlePageChange = (page: number, pageSize: number = 10) => {
    const dataSource = objectResult?.authObjectVoList?.slice((page - 1) * pageSize, page * pageSize)
    setPagination((p) => {
      return { ...p, current: page, pageSize };
    });
    setDataSource(dataSource)
  };

  //批量操作
  const mergeOperations =  (data: any)  => {
    return data?.reduce((acc: any, curr: any) => {
      let existingItem = acc.find((item: any) => item?.permissionCollectionObjectVO?.nodePathWithType === curr?.permissionCollectionObjectVO?.nodePathWithType);
      if (existingItem) {
        existingItem.permissionCollectionObjectVO.operations = existingItem?.permissionCollectionObjectVO?.operations?.concat(curr?.permissionCollectionObjectVO?.operations);
      } else {
        acc.push({ 
          permissionCollectionObjectVO: {
            nodePathWithType: curr?.permissionCollectionObjectVO?.nodePathWithType,
            dataSourceType:curr?.permissionCollectionObjectVO?.dataSourceType,
            nodeName: curr?.permissionCollectionObjectVO?.nodeName,
            nodePath: curr?.permissionCollectionObjectVO?.nodePath,
            operations: curr?.permissionCollectionObjectVO?.operations
            }
          });
      }
      return acc;
    }, []);
  }
  
  const onBatchChangePermission = (operations: string[]) => {

    let batchPermissions = selectedRows?.map((item: any) => ({
      permissionCollectionObjectVO: {
        nodePathWithType: item?.nodePathWithType,
        dataSourceType: selectTreeItem?.dataSourceType,
        operations: operations,
        nodeName: item?.nodeName,
        nodePath: item?.nodePath,
      }
    }))
    const clone = _.cloneDeep(permissionCollectionVOS)
    const mergedOperations = mergeOperations([...batchPermissions, ...clone]);

    setPermissionCollectionVOS(mergedOperations);
    //回到批量前状态
    setIsShowAuthModal(false);
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setCanOperate(true);
    setBatchOperationState(false);
  }

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys: selectedRowKeys,
    onSelectAll(selected, newSelectedRows) {

      const curRowKeys: any = newSelectedRows.map(row => row?.nodePathWithType);
      let cloneSelectedRows: any = _.cloneDeep(selectedRows);
      let cloneSelectedRowKeys = _.cloneDeep(selectedRowKeys);
      if (selected) {
        cloneSelectedRows = cloneSelectedRows.concat(newSelectedRows)

        cloneSelectedRowKeys = cloneSelectedRowKeys.concat(curRowKeys)
      } else {
        
        const curKeys = dataSource?.map((row: any) => row?.permissionId) || [];
        cloneSelectedRows = cloneSelectedRows.filter((cr: any) => cr && !curKeys.includes(cr?.nodePathWithType));
        cloneSelectedRowKeys = cloneSelectedRowKeys.filter(k => !curKeys.includes(k))

      }
      setSelectedRows(cloneSelectedRows.filter((i: any) => !_.isEmpty(i)));
      setSelectedRowKeys([...new Set(cloneSelectedRowKeys)]);
    },
    onSelect(item: any, selected) {

      let cloneSelectedRows: any = _.cloneDeep(selectedRows);
      let cloneSelectedRowKeys: any = _.cloneDeep(selectedRowKeys);
      if (selected) {

        cloneSelectedRows = cloneSelectedRows.concat([item])
        cloneSelectedRowKeys = cloneSelectedRowKeys.concat([item?.nodePathWithType])
      } else {
        cloneSelectedRows = cloneSelectedRows.filter((cr: any) => cr?.nodePathWithType !== item.nodePathWithType);
        cloneSelectedRowKeys = cloneSelectedRowKeys.filter((k: any) => k !== item.nodePathWithType)
      }
      setSelectedRows(cloneSelectedRows);
      setSelectedRowKeys(cloneSelectedRowKeys);
    },
  };
 

console.log(permissionCollectionVOS, '00000000')
  return (
    <Spin spinning={loading}>
      <div className={styles.objectLevelWrap}>
        <div className={styles.title}>
          <span> {(viewAppliedDetail) ? '已选择的权限' : '选择权限'} </span>
          {
            !viewAppliedDetail &&
            <Space>
              <Button ghost type='primary' onClick={() => {
                //批量操作前清空schema以上批量操作
                if (batchOperationState) {
                  onCancelBatchOperation?.();
                  setCanOperate(true)
                }else {
                  setCanOperate(false)
                }
                setBatchOperationState(!batchOperationState);
               

              }}>
                {batchOperationState ? '取消批量' : '批量操作'}
              </Button>
              <Tooltip title='资源数量以schema数量为准'>
              <Badge count={cartNumber}  showZero size="small" className="mr20">
                < Button type="primary" icon={<ShoppingCartOutlined />}
                  onClick={() => history.push('/mine_apply/application')}
                >申请清单</Button>
              </Badge>
              </Tooltip>
            </Space>
          }
        </div>
        <Table
          rowKey={(item) => item.nodePathWithType}
          columns={columns}
          dataSource={dataSource}
          rowSelection={batchOperationState ? rowSelection : undefined}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => handlePageChange(page, pageSize),
          }}
          scroll={{
            x: getScrollX(columns),
            y: `calc(100vh - 460px)`,
          }}
        />

        <div className={styles.batchFooter}>
          {
            batchOperationState && selectedRowKeys?.length ?
              <Button
                icon={<PlusOutlined />} ghost type='primary'
                onClick={() => setIsShowAuthModal(true)}
              >
                选择权限
              </Button>
              : ''
          }
        </div>
      </div>
      {
        isShowAuthModal &&
        <FineGrainedPermissionModal
          permissions={authorizeList}
          onCancel={() => {
            setIsShowAuthModal(false)
          }}
          onOk={(values: any) => {

            onBatchChangePermission(values)
          }}
        />
      }

    </Spin>
  );
};
export default ObjectLevelAuthorization;
