import React, { PropsWithChildren } from 'react'
import { useSelector } from 'src/hook'
import { Divider } from 'antd'
import { LazyWrapper } from 'src/components'
import { activeMonacoPaneInfoSelector } from '../../queryTabsSlice'
import { MonacoToolbarSessionSelectors } from './MonacoToolbarSessionSelector'
import { MonacoToolbarExecutions } from './MonacoToolbarExecutions'
import { MonacoToolbarEdit } from './MonacoToolbarEdit'
import { MonacoToolbarPlSqlEdit } from './MonacoToolbarPlSqlEdit'
import { MonacoToolbarFavorite } from './MonacoToolbarFavorite'
import { MonacoToolbarHelp } from './MonacoToolbarHelp'
import { RecentlyOpenedFiles } from './RecentlyOpenedFiles'
import { SaveDocument } from './SaveDocument'
import { MonacoExportStatement } from './MonacoExportStatement';
import styles from './index.module.scss'
import classNames from 'classnames'

interface ToolbarProps {
  onPressExecute: (flashbackSql?: string) => void
  clearModelDec: () => void
  onPreCommit?: () => void
  supportTransaction?: boolean
  oneLibraryAndOneConnection?: boolean
  isCreateView?: boolean // 是否创建视图\函数\存储过程
}

export const MonacoToolbar = React.memo(
  ({
    onPressExecute,
    clearModelDec,
    onPreCommit,
    supportTransaction,
    oneLibraryAndOneConnection,
    isCreateView
  }: PropsWithChildren<ToolbarProps>) => {
    const { tabKeyList, paneInfoMap } = useSelector((state) => state.queryTabs)
    const panes = tabKeyList.map((key) => paneInfoMap[key])
    const activeMonacoPaneInfo = useSelector(activeMonacoPaneInfoSelector)
    const encoding = activeMonacoPaneInfo?.encoding

    const curPane = panes.find((pane) => pane.key === activeMonacoPaneInfo?.key)

    if (!activeMonacoPaneInfo && !isCreateView) {
      return null
    }

    return (
      <div className={classNames(styles.monacoToolbarWrapper, 'monaco-toolbar')}>
        {
          !isCreateView && 
          panes.map((pane) => (
            <LazyWrapper key={pane.key} active={activeMonacoPaneInfo?.key === pane.key}>
              <MonacoToolbarSessionSelectors
                paneInfo={pane}
                clearModelDec={clearModelDec}
                oneLibraryAndOneConnection={oneLibraryAndOneConnection}
              />
            </LazyWrapper>
          ))
        }
        <div className='flexAlignCenter'>
          { 
            !isCreateView && 
            <>
              {
                panes.map((pane) => (
                  <LazyWrapper key={pane.key} active={activeMonacoPaneInfo?.key === pane.key}>
                    <MonacoToolbarExecutions
                      paneInfo={pane}
                      onPressExecute={(flashbackSql) => onPressExecute(flashbackSql)}
                      onPreCommit={onPreCommit}
                      supportTransaction={supportTransaction}
                    />
                  </LazyWrapper>
                ))
              }
              <Divider type="vertical" className={styles.divider}></Divider>
            </>
          }
          {activeMonacoPaneInfo?.plSql ? (
            <MonacoToolbarPlSqlEdit />
          ) : (
            <MonacoToolbarEdit />
          )}
          <Divider type="vertical" className={styles.divider}></Divider>
          <MonacoToolbarFavorite />
          <Divider type="vertical" className={styles.divider}></Divider>
          <RecentlyOpenedFiles encoding={encoding} />
          <Divider type="vertical" className={styles.divider}></Divider>
          <SaveDocument encoding={encoding} />
          <Divider type="vertical" className={styles.divider}></Divider>
          <MonacoToolbarHelp />
          <Divider type="vertical" className={styles.divider}></Divider>
          <MonacoExportStatement />
          {
            isCreateView &&
            <span style={{flex:'1', textAlign:'right'}}>
              <span 
                style={{ padding:'6px 13px', borderRadius:'4px', background:'#3262FF', color:'#fff', cursor:'pointer'}}
                onClick={() => !curPane?.pending && onPressExecute()}
                className={curPane?.pending ? 'btnDisabled': ''}
              >
                创建
              </span>
            </span>
          }
        </div>
      </div>
    )
  },
)
