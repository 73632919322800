import { message } from 'antd'
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit'
import { persistReducer, PURGE } from 'redux-persist'
import localforage from 'localforage'
import { editor, IPosition, IRange } from 'monaco-editor'
import {
  // closeConnectionsOnTab,
  // closeConnectionsOnTabs,
  // connectionChangeDatabase,
  DataSourceType,
  FavoriteEntity,
  getTxModeOfConnection,
  SdtNodeType,
  TxModeContext,
  UserFile,
  StatementExecuteParams
} from 'src/api'
import { AppThunk, RootState } from 'src/store'
import {
  BLOCK_SIZE,
  Encoding,
  EOL_SEQUENCE_TYPE,
  TSQL_BLOCK_SIZE,
} from 'src/constants'
import {SqlSplitter, getOperatingObject, TransactSqlSplitter} from 'src/util'
import {
  queryExecuteResults,
  fetchExplain,
} from '../resultTabs/resultTabsSlice'
import { v4 as uuidv4 } from 'uuid'
import { indexedDBStorage } from 'src/store/indexedDB'

interface QueryTabsState {
  activeTabKey: string
  tabKeyList: string[]
  tabInfoMap: { [key: string]: TabInfo }
  paneInfoMap: { [key: string]: PaneInfo }
  lastSavedFavorite: FavoriteEntity | null
  executeActiveTabParams: {[key: string]: string | boolean},
  executeActiveTabInfo: {[key: string]: ExecuteActiveTabInfo},
  tabExecutionStatusPercentageMap : { [key: string]: TabExecutionStatusPercentage },
  examineReviewResult?: any // sql审核结果
  sdtBasicSettingData: any,  // sdt右键导出 安全设置-基础设置-导出设置
}

interface TabExecutionStatusPercentage {
  key: string
  executePercentage: number
  executeStatusMessage: string
}

interface TabInfo {
  key: string
  /** tabPane content type */
  paneType: PaneType
  tabName?: string
  [p: string]: any
}
export type PaneType =
  | 'terminal'
  | 'monaco'
  | 'plSql'
  | 'tSql'
  | 'addTable'
  | 'designTable' /** 设计表 */
  | 'viewTableStructure' /** 查看表结构 */
  | 'grid'
  | 'addView'
  | 'compileInvalidObjects' /** 编译无效对象 */
  | 'viewViewStructureMenu' /** 查看视图结构 */
  | 'obCreateView' /** ob创建视图 */
  | 'createFunction' /** 创建函数 */
  | 'createProcedure' /** 创建存储过程 */

export interface PaneInfo
  extends Partial<MonacoEntity>,
  Partial<TerminalEntity>,
  Partial<AddTableEntity> {
  key: string
  [p: string]: any
}

interface MonacoEntity extends DatabaseInfo {
  key: string
  value: string
  language: string
  cursorPosition: IPosition
  encoding?: Encoding
  endOfLine?: EOL_SEQUENCE_TYPE
  serverFile: UserFile | null
  plSql?: boolean
  tSql?: boolean
  /* 执行在编辑器内选中Range */
  execValueRange?: IRange
  /* 本次执行过程中已经将错误 commit 到editor */
  hasCommitExecError?: boolean
  /* 错误标记 */
  editorExecErrorMark?: IRange[]
  editorExecErrorMarkRange?: any
  /** 是否需要在执行环境建立后立即执行 */
  autoRun?: boolean
  /** 执行环境准备中（当前指切库） */
  prepared?: boolean
  /** 查询窗口当前物理连接事物模式 */
  txMode?: 'auto' | 'manual'
  /** 当前窗口editor 滚动条位置 */
  scrollTop?: number
  /** 历史遗留问题，上述的cursorPosition 在原先的基础上做了节流，新的这个为实时position*/
  viewState?: editor.ICodeEditorViewState
  /** 当前pane是什么类型，通过右键点击进来的类型 */
  menuType?: string
}
interface TerminalEntity {
  key: string
  url: string
}
export interface AddTableEntity extends DatabaseInfo {
  key: string
}

// todo: 待明确意义, 应该指的是 tab 上当前物理连接的执行上下文(包括执行的参数和执行的状态)
export interface DatabaseInfo {
  connectionType: DataSourceType
  connectionId: number | string
  databaseName?: string
  /* pg、polarDB、oceanbase数据库存在 schema 层 */
  schemaName?: string
  /**
   * 执行状态, 之后可能是一个 tuple ['idle','executing'...], 当前只考虑是否在 pending
   */
  pending?: boolean
  nodePath?: string
  nodePathWithType?: string
  nodeName?: string
  nodeType?: SdtNodeType
  [p: string]: any
}

export interface ExecuteActiveTabInfo {
  executeStatus: string,
  messageData: any,
  channelId: string,
  userId: string,
  messageId: string,
  groupName: string
}

interface TabInfoUpdate {
  /** 选项卡文字 */
  tabName?: string
}

const initialState: QueryTabsState = {
  activeTabKey: '',
  tabKeyList: [],
  tabInfoMap: {},
  paneInfoMap: {},
  lastSavedFavorite: null,
  executeActiveTabParams: {},
  executeActiveTabInfo: {},
  tabExecutionStatusPercentageMap: {},
  examineReviewResult: {},
  sdtBasicSettingData: {}
}
/**
 * @description clear all tabs and close session
 */
export const resetQueryTabs = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('queryTabs/resetQueryTabs', async (_, { getState }) => {
  // const tabKeyList = getState().queryTabs.tabKeyList
  // closeConnectionsOnTabs(tabKeyList)
})

/**
 * @description close query tab
 */
export const removeQueryPane = createAsyncThunk<
  string,
  string,
  { state: RootState }
>(
  'queryTabs/removeQueryPane',
  async (targetKey) => {
    // cocurrent fetch
    // closeConnectionsOnTab(targetKey)
    return targetKey
  },
  {
    condition: (targetKey, { getState }) => {
      const tabKeyList = getState().queryTabs.tabKeyList
      if (!tabKeyList.includes(targetKey)) return false
    },
  },
)

/**
 * 切库
 */
// export const changeDatabase = createAsyncThunk(
//   'queryTabs/changeDatabase',
//   async (params: QueryBase) => {
//     return connectionChangeDatabase(params)
//   },
// )

/**
 * 获取事务模式
 */
export const getTxMode = createAsyncThunk(
  'queryTabs/getTxMode',
  async (params: TxModeContext) => {
    return getTxModeOfConnection(params)
  },
)

export const queryTabsSlice = createSlice({
  name: 'queryTabs',
  initialState,
  reducers: {
    setActiveTabKey(state, action: PayloadAction<string>) {
      state.activeTabKey = action.payload
    },
    addPaneWithUniqueKey(
      state,
      action: PayloadAction<
        Partial<TabInfo> & (Partial<MonacoEntity> | Partial<TerminalEntity>)
      >,
    ) {
      const { tabKeyList, tabInfoMap, paneInfoMap } = state
      const {
        key,
        tabName = 'Untitled',
        paneType = 'monaco',
        ...rest
      } = action.payload
      const uuid = key!
      tabInfoMap[uuid] = { key: uuid, tabName, paneType }
      paneInfoMap[uuid] = { key: uuid, ...rest }

      tabKeyList.push(uuid)
      state.activeTabKey = uuid
    },
    updateTabsInfo(
      state,
      action: PayloadAction<{ key: string } & TabInfoUpdate>,
    ) {
      const { tabKeyList, tabInfoMap } = state
      const { key, ...updateTabsInfo } = action.payload
      // 更新目标不在 tabKeyList 中, 不做更新
      if (!tabKeyList.includes(key)) return
      // 更新 tabsInfo
      const updated = { ...tabInfoMap[key], ...updateTabsInfo }
      tabInfoMap[key] = updated
    },

    removeQueryPane: (state, action: PayloadAction<string>) => {
      const { activeTabKey, tabKeyList, tabInfoMap, paneInfoMap, executeActiveTabInfo, tabExecutionStatusPercentageMap } = state
      const targetKey = action.payload
      // 目标 key 不在 list 中, 直接 return
      if (!tabKeyList.includes(targetKey)) return
      if (targetKey === activeTabKey) {
        const index = tabKeyList.findIndex((key) => key === targetKey)
        state.activeTabKey =
          tabKeyList[index + 1] || tabKeyList[index - 1] || '-1'
      }
      // 分别删除 tabKeyList/tabInfoMap/paneInfoMap 中与 targetKey 有关的项
      state.tabKeyList = tabKeyList.filter((key) => key !== targetKey)
      // closeConnectionsOnTab(targetKey)
      delete tabInfoMap[targetKey]
      delete paneInfoMap[targetKey]
      delete executeActiveTabInfo[targetKey]
      tabExecutionStatusPercentageMap && delete tabExecutionStatusPercentageMap[targetKey]
    },

    updatePaneInfo(
      state,
      action: PayloadAction<{ key: string; paneInfo: Partial<PaneInfo> }>,
    ) {
      const { paneInfoMap } = state
      const { key } = action.payload
      paneInfoMap[key] = { ...paneInfoMap[key], ...action.payload.paneInfo }
    },

    setPrepared(
      state,
      action: PayloadAction<{ key: string; prepared: Partial<boolean> }>,
    ) {
      const { paneInfoMap } = state
      const { key } = action.payload
      paneInfoMap[key].prepared = action.payload.prepared
    },

    updateMonaco(
      state,
      action: PayloadAction<{ key: string } & Partial<MonacoEntity>>,
    ) {
      const { paneInfoMap } = state
      const { key, ...rest } = action.payload
      paneInfoMap[key] = {
        ...(paneInfoMap[key] as { key: string } & Partial<MonacoEntity>),
        ...(rest as Partial<MonacoEntity>),
      }
    },
    pushMonacoValue(
      state,
      action: PayloadAction<{ key: string; tailText: any }>,
    ) {
      const { paneInfoMap } = state
      const { key, tailText } = action.payload
      const paneInfo = paneInfoMap[key] as {
        key: string
      } & Partial<MonacoEntity>
      const totalText = `${paneInfo.value || ''}${tailText}`
      paneInfoMap[key] = {
        ...paneInfo,
        value: totalText,
      }
    },
    setLastSavedFavorite: (
      state,
      action: PayloadAction<FavoriteEntity | null>,
    ) => {
      state.lastSavedFavorite = action.payload
    },

    // modify prop 'encoding' of query tab (type monaco)
    updateMonacoTabEncoding: (
      state,
      action: PayloadAction<{ encoding?: Encoding }>,
    ) => {
      const { paneInfoMap, activeTabKey } = state
      const { encoding } = action.payload
      const paneInfo = paneInfoMap[activeTabKey]
      paneInfo.encoding = encoding
    },
    updateMonacoTabEol: (
      state,
      action: PayloadAction<{ endOfLine?: EOL_SEQUENCE_TYPE }>,
    ) => {
      const { paneInfoMap, activeTabKey } = state
      const { endOfLine } = action.payload
      const paneInfo = paneInfoMap[activeTabKey]
      paneInfo.endOfLine = endOfLine
    },
    updateMonacoTabName: (state, action: PayloadAction<string | undefined>) => {
      const tabName = action.payload
      const { tabInfoMap, activeTabKey } = state
      const tabInfo = tabInfoMap[activeTabKey]
      tabInfo.tabName = tabName || 'Untitled'
    },
    setMonacoServerFile: (state, action: PayloadAction<UserFile | null>) => {
      const serverFile = action.payload
      const { paneInfoMap, activeTabKey } = state
      const paneInfo = paneInfoMap[activeTabKey]
      paneInfo.serverFile = serverFile
    },
    // 设置 tab 执行/解释 pending 状态
    // todo: 应该把 queryTabsSlice 和 resultTabsSlice 合二为一
    setTabExecutionStatus: (
      state,
      action: PayloadAction<{ key: string; pending: boolean }>,
    ) => {
      const { paneInfoMap } = state
      const { key, pending } = action.payload
      const paneInfo = paneInfoMap[key]
      if (!paneInfo) return
      paneInfo.pending = pending
    },

    setTabExecutionStatusPercentage: (
        state,
        action: PayloadAction<{ key: string; executePercentage: number, executeStatusMessage: string }>,
    ) => {
      const { tabExecutionStatusPercentageMap } = state
      const { key, executePercentage, executeStatusMessage } = action.payload
      const target = tabExecutionStatusPercentageMap[key]
      const tabExecutionStatusPercentage  = {key: key, executePercentage: executePercentage, executeStatusMessage: executeStatusMessage}
      tabExecutionStatusPercentageMap[key] = {
        ...target,
        ...tabExecutionStatusPercentage,
      }
      console.log(tabExecutionStatusPercentage.executeStatusMessage + '，总进度：' + tabExecutionStatusPercentage.executePercentage)
      //console.log(JSON.stringify(tabExecutionStatusPercentageMap, null, 2));
    },
    /**
     * update connection and database of active paneInfo
     */
    setConnectionSession: (
      state,
      action: PayloadAction<{ key: string; paneInfo: DatabaseInfo }>,
    ) => {
      const { paneInfoMap } = state
      const { key, paneInfo } = action.payload
      const targetPane = paneInfoMap[key]
      if (targetPane) {
        paneInfoMap[key] = {
          ...targetPane,
          ...paneInfo,
        }
      }
    },
    updateConnectionSession: (
      state,
      action: PayloadAction<{ key: string; paneInfo: Partial<DatabaseInfo> }>,
    ) => {
      const { paneInfoMap } = state
      const { key, paneInfo } = action.payload
      const targetPane = paneInfoMap[key]
      if (targetPane) {
        paneInfoMap[key] = {
          ...targetPane,
          ...paneInfo,
        }
      }
    },
    /* reset paneInfo 中涉及到errormark 的部分 */
    updatePaneInfoAboutErrormark: (
      state,
      action: PayloadAction<Partial<PaneInfo>>,
    ) => {
      const { activeTabKey, paneInfoMap } = state
      const paneInfo = paneInfoMap[activeTabKey]
      if (!paneInfo) return
      const {
        execValueRange = undefined,
        // editorExecErrorMark = [],
        editorExecErrorMarkRange = [],
        hasCommitExecError = false,
      } = action.payload || {}
      // paneInfo.editorExecErrorMark = editorExecErrorMark
      paneInfo.editorExecErrorMarkRange = editorExecErrorMarkRange
      paneInfo.execValueRange = execValueRange
      paneInfo.hasCommitExecError = hasCommitExecError
    },
    //当前执行语句参数
    saveExecuteActiveTabParams: (
      state,
      action: PayloadAction<Partial<StatementExecuteParams>>
    ) => { 
      const { tabKey} = action.payload
      //@ts-ignore
      state.executeActiveTabParams[tabKey] = action.payload
    },
    //当前执行语句请求后返回的关键信息
    saveExecuteActiveTabInfo: (
      state,
      action: PayloadAction<{key: string, data: ExecuteActiveTabInfo}>
    ) => { 
      const { key, data } = action.payload
      state.executeActiveTabInfo[key] = data
    },

    setExamineReviewResult: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.examineReviewResult = action.payload
    },
    setSdtBasicSettingData: (state, action: PayloadAction<any>) => {
      state.sdtBasicSettingData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetQueryTabs.fulfilled, (state) => {
      state.tabKeyList = []
      state.tabInfoMap = {}
      state.paneInfoMap = {}
    })
    builder.addCase(removeQueryPane.fulfilled, (state, action) => {
      const { activeTabKey, tabKeyList, tabInfoMap, paneInfoMap, executeActiveTabInfo, tabExecutionStatusPercentageMap } = state
      const targetKey = action.payload
      // 如果当前激活 tab 被删除, 跳转到下一个或上一个 tab 页
      if (targetKey === activeTabKey) {
        const index = tabKeyList.findIndex((key) => key === targetKey)
        state.activeTabKey =
          tabKeyList[index + 1] || tabKeyList[index - 1] || '-1'
      }
      // 分别删除 tabKeyList/tabInfoMap/paneInfoMap 中与 targetKey 有关的项
      state.tabKeyList = tabKeyList.filter((key) => key !== targetKey)
      delete tabInfoMap[targetKey]
      delete paneInfoMap[targetKey]
      executeActiveTabInfo && delete executeActiveTabInfo[targetKey]
      tabExecutionStatusPercentageMap && delete tabExecutionStatusPercentageMap[targetKey]
    })
    // 切库相关
    // builder.addCase(changeDatabase.pending, (state, action) => {
    //   const { tabKey } = action.meta.arg
    //   if (tabKey) {
    //     const paneInfo = state.paneInfoMap[tabKey]
    //     if (paneInfo) {
    //       paneInfo.prepared = false
    //     }
    //   }
    // })
    // builder.addCase(changeDatabase.fulfilled, (state, action) => {
    //   const { tabKey } = action.meta.arg
    //   if (tabKey) {
    //     const paneInfo = state.paneInfoMap[tabKey]
    //     if (paneInfo) {
    //       paneInfo.prepared = true
    //     }
    //   }
    // })
    // builder.addCase(changeDatabase.rejected, (state, action) => {
    //   const { tabKey } = action.meta.arg
    //   if (tabKey) {
    //     const paneInfo = state.paneInfoMap[tabKey]
    //     if (paneInfo) {
    //       paneInfo.prepared = false
    //     }
    //   }
    // })
    // 获取事务模式
    builder.addCase(getTxMode.fulfilled, (state, action) => {
      if(!action.payload) return
      const { txMode } = action.payload
      const { tabKey } = action.meta.arg
      const paneInfo = state.paneInfoMap[tabKey]
      if (paneInfo) {
        paneInfo.txMode = txMode
      }
    })
  },
})

export const {
  setActiveTabKey,
  addPaneWithUniqueKey,
  updateTabsInfo,
  updateMonaco,
  updatePaneInfo,
  setPrepared,
  setLastSavedFavorite,
  updateMonacoTabEncoding,
  updateMonacoTabEol,
  updateMonacoTabName,
  setMonacoServerFile,
  setTabExecutionStatus,
  setTabExecutionStatusPercentage,
  setConnectionSession,
  updateConnectionSession,
  updatePaneInfoAboutErrormark,
  pushMonacoValue,
  saveExecuteActiveTabParams,
  saveExecuteActiveTabInfo,
  setExamineReviewResult,
  setSdtBasicSettingData
} = queryTabsSlice.actions

export const executeEditorSql =
  (
    /** If not given, use sql in active editor */ selectedText?: string,
    /** If not given, use database info in active editor */ databaseParams?: {
    connectionId: number | string
    connectionType: DataSourceType
    databaseName: string
  },
  ): AppThunk =>
    (dispatch, getState) => {
      const { activeTabKey, paneInfoMap } = getState().queryTabs
      const activePaneInfo = paneInfoMap[activeTabKey]
      if (!activePaneInfo) return
      const {
        key,
        connectionId,
        connectionType,
        databaseName,
        value,
        plSql,
        tSql,
        txMode,
      } = activePaneInfo
      const rawSql = selectedText !== undefined ? selectedText : value || ''
      const splitter = tSql
        ? new TransactSqlSplitter(rawSql)
        : new SqlSplitter(rawSql)
      const statements = plSql && !tSql ? [rawSql] : splitter.split()
      if (!connectionId || !connectionType || !statements.length) return
      const params = {
        connectionId: connectionId,
        dataSourceType: connectionType,
        operatingObject: getOperatingObject(activePaneInfo, connectionType),
        statements,
        offset: 0,
        rowCount: tSql ? TSQL_BLOCK_SIZE : BLOCK_SIZE,
        ...databaseParams,
        tabKey: key,
        plSql,
        databaseName,
        autoCommit: txMode === 'auto',
        actionType: 'EXECUTE',
      }
      // 执行前将执行状态置为 pending
      dispatch(setTabExecutionStatus({ key, pending: true }))
      dispatch(setTabExecutionStatusPercentage({key: key, executePercentage: 0, executeStatusMessage: '正在执行...0%'}));
      // 执行语句
      return dispatch(queryExecuteResults(key, params)).finally(() => {
        // 执行状态置为 pending false
        dispatch(setTabExecutionStatus({ key, pending: false }))
        dispatch(setTabExecutionStatusPercentage({key: key, executePercentage: 100, executeStatusMessage: '已执行结束'}));
      })
    }

export const explainEditorSql =
  (
    /** If not given, use sql in active editor */ selectedText?: string,
    /** If not given, use database info in active editor */ databaseParams?: {
    connectionId: number | string
    connectionType: DataSourceType
    databaseName: string
  },
  ): AppThunk =>
    (dispatch, getState) => {
      const { activeTabKey, paneInfoMap } = getState().queryTabs
      const activePaneInfo = paneInfoMap[activeTabKey]
      if (!activePaneInfo) return
      const {
        key,
        connectionId,
        connectionType,
        databaseName: operatingDatabase,
        schemaName,
        value,
      } = activePaneInfo
      const splitter = new SqlSplitter(
        selectedText !== undefined ? selectedText : value || '',
      )
      const statements = splitter.split()
      if (!connectionId || !connectionType || !statements.length) return
      const params = {
        connectionId: connectionId,
        dataSourceType: connectionType,
        databaseName: operatingDatabase,
        operatingObject: schemaName,
        statements,
        offset: 0,
        rowCount: BLOCK_SIZE,
        ...databaseParams,
        tabKey: key,
      }
      // 解释语句前 设置 pending
      dispatch(setTabExecutionStatus({ key, pending: true }))
      dispatch(setTabExecutionStatusPercentage({key: key, executePercentage: 0, executeStatusMessage: '正在执行...0%'}));
      // 执行解释
      return dispatch(fetchExplain(params)).finally(() => {
        dispatch(setTabExecutionStatus({ key, pending: false }))
        dispatch(setTabExecutionStatusPercentage({key: key, executePercentage: 100, executeStatusMessage: '已执行结束'}));
      })
    }

type AddPaneParams = Partial<TabInfo> &
  (Partial<MonacoEntity> & Partial<TerminalEntity>)

export const addPane =
  (params: AddPaneParams): AppThunk =>
    (dispatch, getState) => {
      const uuid = uuidv4()
      const { tabMax } = getState().login.userInfo
      const tabKeys = getState().queryTabs.tabKeyList
      if (tabKeys.length >= Number(tabMax)) {
        return message.warning('查询窗口已达上限')
      }
      dispatch(addPaneWithUniqueKey({ ...params, key: uuid }))
    }

// selectors
export const activePaneInfoSelector = createSelector<
  RootState,
  string,
  { [key: string]: PaneInfo },
  PaneInfo | undefined
>(
  (state) => state.queryTabs.activeTabKey,
  (state) => state.queryTabs.paneInfoMap,
  (activeTabKey, paneInfoMap) => paneInfoMap[activeTabKey],
)

/**
 * @description select active monaco paneInfo from store,
 * if current paneType is not monaco, return undefined
 */
export const activeMonacoPaneInfoSelector = createSelector<
  RootState,
  string,
  { [key: string]: TabInfo },
  { [key: string]: PaneInfo },
  PaneInfo | undefined
>(
  (state) => state.queryTabs.activeTabKey,
  (state) => state.queryTabs.tabInfoMap,
  (state) => state.queryTabs.paneInfoMap,
  (activeTabKey, tabInfoMap, paneInfoMap) => {
    const isMonaco = tabInfoMap[activeTabKey]?.paneType === 'monaco'
    const isPlSql = tabInfoMap[activeTabKey]?.paneType === 'plSql'
    const isTsql = tabInfoMap[activeTabKey]?.paneType === 'tSql'
    const isObCreateView = tabInfoMap[activeTabKey]?.paneType === 'obCreateView'
    if (isMonaco || isPlSql || isTsql || isObCreateView) {
      return paneInfoMap[activeTabKey]
    }
  },
)

const reducer = queryTabsSlice.reducer

const reducerWithPurge: typeof reducer = (state, action) => {
  if (action.type === PURGE) {
    return reducer(undefined, action)
  }
  return reducer(state, action)
}
export const queryTabsReducer = persistReducer(
  {
    key: 'queryTabs',
    version: 3,
    storage: indexedDBStorage,
    throttle: 300,
  },
  reducerWithPurge,
)
