import React, { useEffect, useState } from 'react'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { Link, useLocation } from 'react-router-dom'
import { getCartConnection } from 'src/api'
import { Badge, Button, Input, Tooltip } from 'antd'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'
import { MENU_FLOW } from 'src/constants';
import { Iconfont, SimpleBreadcrumbs } from 'src/components'
import { SearchOutlined } from '@ant-design/icons'
import { MyApplyTabsPage } from './my-apply-tabs'
import classnames from 'classnames'
import { setApplySearchValue } from '../accessRequestSlice'
import styles from './index.module.scss'
import _ from 'lodash'

const breadcrumbData = [
  { title: MENU_FLOW },
  {
    title: "我的申请",
  },
];

export const MyApplyRequest = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation();
  const [searchValue, setSearchValue] = useState('')

  //购物车数量
  const { data: totalSchema, refresh } = useRequest(getCartConnection, {
    formatResult(res) {
      return res?.totalSchema || 0
    }
  })

  useEffect(() => {
    dispatch(setApplySearchValue(''))
  }, [pathname])

  const onChangeValue = (str: string) => {
    setSearchValue(str)
  }

  const onSearch = _.debounce((e) => {
    dispatch(setApplySearchValue(e?.target?.value))
  }, 500)

  const handleInputChange = (e: { persist: () => void; target: { value: React.SetStateAction<string> } }) => {
    e.persist()
    setSearchValue(e?.target?.value)
    onSearch(e)
  }

  return (
    <div className={styles.myApplyRequestWrap}>
      <div className={styles.headers}>
        <SimpleBreadcrumbs items={breadcrumbData} />
        <div className={styles.flexRow}>
          <Input
            prefix={<SearchOutlined className="site-form-item-icon" />}
            placeholder="搜索标题"
            value={searchValue}
            onChange={handleInputChange}
          />
          <Button
            className={classnames(styles.textAlign, styles.ml10, styles.mr8)}
            style={{ textAlign: "right" }}
            type="primary"
          // onClick={handleCreateTask}
          >
            <Link to="/mine_apply/search">访问申请</Link>
          </Button>

          <Badge count={totalSchema} showZero>
            <Tooltip title='资源数量以schema数量为准' placement='bottomRight'>
              <Button
                type="primary"
                className={styles.shoppBtn}
              >
                <Link to="/mine_apply/application">
                  <Iconfont type='icon-gouwuche' style={{ color: '#fff', fontSize: '16px' }} />
                </Link>
              </Button>
            </Tooltip>
          </Badge>
        </div>
      </div>
      <div className={styles.content}>
        <ErrorBoundary>
          <MyApplyTabsPage onChangeValue={onChangeValue} refreshShoppingCart={refresh} />
        </ErrorBoundary>
      </div>
    </div>
  )
}
