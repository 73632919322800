import { SdtNodeType } from 'src/api'

/**
 * 流程类型
 */
export const flowTypes = [
  'dataCorrection',
  'dataManipulation',
  'connectionAccess',
  'dataExport',
  'dataMasking',
  'highRisk',
  'executionTimes',
  'executionRows',
  'menuAccess',
  'desensitizedResource'
] as const

//隐藏后的类型 只展示数据操作提权
export const flowFilterTypes = [
  // 'dataCorrection',
  'dataManipulation',
  // 'dataExport',
  // 'highRisk',
  // 'menuAccess',
] as const

/**
 * cq3 流程类型
 */
export const flowTypesCq3 = [
  'dataManipulation',
  'connectionAccess',
  'dataExport',
  'menuAccess',
] as const

/**
 * 流程类型
 * @type dataCorrection 数据订正
 * @type dataManipulation 数据操作提权
 * @type connectionAccess 连接访问提权
 * @type dataExport 数据导出提权
 * @type dataMasking 数据脱敏提权
 * @type highRisk 高危资源提权
 * @type executionTimes 执行次数提权
 * @type executionRows 执行行数提权
 * @type menuAccess 功能提权
 */
export type FlowType = typeof flowTypes[number]

/** 后端定义的 流程状态字段 */
export type ApplyStatus = 'pending' | 'pass' | 'refuse' | 'withdraw'

/**
 * 流程列表项
 */
export interface FlowTableRecord {
  /** 唯一id（前端添加的） */
  indexKey: number
  /** 审批编号（用于给用户展示） */
  uuid: string
  /** id（在后端与审批编号非同一个，用于接口调用） */
  applyId: string | number
  /** 申请的用户名称 */
  applyUserName?: string
  /** 发起的部门 */
  deptName?: string
  /** 类型 */
  applyType: string
  /** 当前审批节点 */
  currentAssignee?: string
  /** 发起时间 */
  applyTime?: string
  /** 最后操作时间 */
  lastOperatingTime?: string
  /** 审批状态 */
  applyStatus?: ApplyStatus
  /** 连接名称 */
  connectionName?: string
  /** 数据库名 */
  dataBaseName?: string
  /** 使用期限 */
  expiredTimeLimit?: number
  /** 流程任务Id */
  flowTaskId?: string
}

/** 流程表单通用参数 */
interface FormFieldsBasic {
  /** 审核人 id */
  approveUserId: string | number
  /** 数据库元素 */
  elements: FormFieldElementEntity[]
  /** 申请原因 */
  applyReason?: string
  /** 期限 */
  timeLimit:
    | { preset: number; custom?: never }
    | {
        preset: false
        custom: number
  }
  //时间类型
  timeType?:string
  // time 生效时间
  time?: any[]
  title?: string
}
/** 表单字段 数据库元素树 节点 */
export interface FormFieldElementEntity {
  connectionId: string | number | undefined
  connectionType: string
  nodeName?: string
  nodePath: string
  nodePathWithType: string
  nodeType: SdtNodeType
}

export interface FormFieldsDataCorrection
  extends Omit<FormFieldsBasic, 'timeLimit'> {
  /** 订正文本 */
  executeText: string
  /** 订正执行时机 */
  executeType: 'auto' | 'manual'
  /** 回退文本 */
  rollbackText: string
  dataSourceType: string
  nodeType: SdtNodeType
  connectionId: number
  nodePathList: string[]
}

export interface FormFieldsDataManipulation extends FormFieldsBasic {
  /** 操作列表 */
  operationList: string[]
}

export interface FormFieldsConnectionAccess extends FormFieldsBasic {}

export interface FormFieldsDesensitizedResource extends FormFieldsBasic {
  connectionId: string | number;
  connectionType: string;
  nodeType: string;
}

export interface FormFieldsDataExport extends FormFieldsBasic {
  /** 导出行数 */
  numberLimit?: number
}

export interface FormFieldsDataMasking extends FormFieldsBasic {}

export interface FormFieldsHighRisk extends FormFieldsBasic {
   /** 操作列表 */
   operationList: string[]
}

export interface FormFieldsExecutionTimes extends FormFieldsBasic {
  /** 执行次数 */
  numberLimit: number
}

export interface FormFieldsExecutionRows extends FormFieldsBasic {
  /** 执行行数 */
  numberLimit: number
}

export interface FormFieldsMenuAccess
  extends Omit<FormFieldsBasic, 'approveUserId' | 'elements'> {
  /** 提权的功能 */
  menus: string[]
  /** 一级审核人 */
  firstApproveUserId: string | number
  /** 二级审核人 */
  secondApproveUserId: string | number
}

/** 流程详情 */

export interface FlowDetailFields {
  /** 审批编号（展示给用户的） */
  uuid: string
  /** id（在后端与审批编号不是同一个，调接口使用的） */
  applyId: string
  /** 申请缘由 */
  applyReason?: string
  /** 驳回缘由 */
  applyResult?:string
  /** 发起时间 */
  applyTime: string
  /** 申请人 id */
  applyUserId: string
  /** 申请人姓名 */
  applyUserName: string
  /** 数据源 id */
  connectionId?: string | number
  /** 数据源名称 */
  connectionName?: string
  /** 数据源类型 */
  dataSourceType?: string
  /** 所在部门 */
  deptName?: string
  /** [数据订正] 执行文本 */
  executeText?: string
  /** [数据订正] 回滚文本内容 */
  rollbackText?: string
  /** [数据订正] 执行时机 */
  executeType?: string
  /** [数据订正] 订正影响行数 */
  dataCorrectionCount?: number
   /** [数据订正] 回退影响行数 */
  rollbackCount?:number
  /** [执行次数] 次数限制 */
  executeTimeLimit?: number
  /** [数据导出] 导出行数限制 */
  exportNumLimit?: number
  /** 申请的时效（小时） */
  expiredTimeLimit?: number
  /** 当前节点 */
  flowPhase?: string
  /** 流程类型 */
  flowType: FlowType
  /** [数据订正] 是否已经执行 */
  isExecute?: boolean
  /** [数据订正] 是否已经回滚 */
  isRollBack?: boolean
  /** 数据库元素（展示） */
  nodeList?: string[]
  /** 数据库元素 nodePath 列表 */
  nodePathList?: string[]
  /** 数据库元素 nodePath 列表 */
  nodePathWithTypeList?: string[]
  /** 数据库元素 共同节点类型 */
  nodeType?: string
  /** 菜单权限对应 key */
  objName?: string
  /** [数据操作权限] 操作权限列表 */
  operationList?: string[]
  /** [执行行数] 行数限制 */
  rowNumLimit?: number
  /** 最后更新时间 */
  updateTime?: string
  /** 错误信息 */
  errorMessage?: string
  /** 回退结果 */
  rollbackMessage?: string
  /** 驳回理由 */
  refuseReason: string
}

/**
 * 埋点触发流程表单的上下文
 * @property type 触发的表单类型
 * @property fields 回填的字段
 */
export type FlowFormTrackingContext =
  | PayloadConnectionAccess
  | PayloadDataCorrection
  | PayloadDataExport
  | PayloadDataManipulation
  | PayloadDataMasking
  | PayloadExecutionRows
  | PayloadExecutionTimes
  | PayloadHighRisk
  | PayloadMenuAccess
  | PayloadSensitiveResource

interface PayloadConnectionAccess {
  type: Extract<'connectionAccess', FlowType>
  fields?: Partial<FormFieldsConnectionAccess>
}
interface PayloadDataCorrection {
  type: Extract<'dataCorrection', FlowType>
  fields?: Partial<FormFieldsDataCorrection>
}

interface PayloadDataExport {
  type: Extract<'dataExport', FlowType>
  fields?: Partial<FormFieldsDataExport>
}

interface PayloadDataManipulation {
  type: Extract<'dataManipulation', FlowType>
  fields?: Partial<FormFieldsDataManipulation>
}

interface PayloadDataMasking {
  type: Extract<'dataMasking', FlowType>
  fields?: Partial<FormFieldsDataMasking>
}

interface PayloadExecutionRows {
  type: Extract<'executionRows', FlowType>
  fields?: Partial<FormFieldsExecutionRows>
}

interface PayloadExecutionTimes {
  type: Extract<'executionTimes', FlowType>
  fields?: Partial<FormFieldsExecutionTimes>
}

interface PayloadHighRisk {
  type: Extract<'highRisk', FlowType>
  fields?: Partial<FormFieldsHighRisk>
}
interface PayloadSensitiveResource {
  type: Extract<'desensitizedResource', FlowType>
  fields?: Partial<FormFieldsDesensitizedResource>
}

interface PayloadMenuAccess {
  type: Extract<'menuAccess', FlowType>
  fields?: Partial<FormFieldsMenuAccess>
}
