import React, { useEffect, useContext, useMemo, useState } from 'react'
import {
  editor,
  KeyMod,
  KeyCode,
  IPosition,
  Range,
  IScrollEvent,
} from 'monaco-editor'
import * as monaco from "monaco-editor"
import { message } from 'antd'
import { EditorContext } from 'src/components'
import { useDispatch, useSelector } from 'src/hook'
import {
  activePaneInfoSelector,
  updateMonaco,
} from 'src/pageTabs/queryPage/queryTabs/queryTabsSlice'
import { keyStringSplit } from 'src/util/hotKeys'
import { format } from 'sql-formatter';
import { formatOptionalLangs } from 'src/constants'
import { handleSqlSplit } from 'src/components/SqlSplit/handleSqlSplit'

export type ChangeModelContentEvent = (
  editor: editor.IStandaloneCodeEditor,
  event: editor.IModelContentChangedEvent,
) => void

interface BaseEditorProps {
  /** 如果不传入 model, 则使用 editor 实例化时自动创建的 model */
  /** 传入 null, 则会设置 editor 实例的 model 为空(即没有内容区域) */
  model?: editor.ITextModel | null
  /** editor.create 方法的第二个参数, editor 实例的 options */
  options?: editor.IEditorOptions
  /** 执行选中语句事件 */
  onExecuteSelectedText?: () => void
  /** model 内容变化事件 */
  onChangeModelContent?: ChangeModelContentEvent
  /** model 光标位置变化事件 */
  onChangeCursorPosition?: (position: IPosition) => void
  /* 滚动条位置变化事件*/
  handleChangeScrollTop?: (e: IScrollEvent) => void
  /** 执行计划的callback */
  onCommandExplain?: () => void
  getViewTableStructure?: () => void;
  className?: string
}

const defaultOptions = {
  automaticLayout: true,
  autoClosingQuotes: 'never' as editor.EditorAutoClosingStrategy,
}

export const BaseEditor: React.FC<BaseEditorProps> = (props) => {
  const {
    model,
    // @ts-ignore
    options,
    className,
    onExecuteSelectedText,
    onChangeModelContent,
    onChangeCursorPosition,
    handleChangeScrollTop,
    onCommandExplain,
    getViewTableStructure
  } = props

  const [transfer, setTransfer] = useState<boolean>(true)

  const { editorInstance, containerRef } = useContext(EditorContext)
  const { execPlan, execute, prompt, splitSqlStatement } = useSelector(
    (state) => state.setting.hotKeys,
  )
  const execPlanMonaco = useMemo(() => {
    return keyStringSplit(execPlan as string) || undefined
  }, [execPlan])
  const executeMonaco = useMemo(() => {
    return keyStringSplit(execute as string) || undefined
  }, [execute])
  const promptMonaco = keyStringSplit(prompt as string)
  const splitMonaco = keyStringSplit(splitSqlStatement as string)

  const dataSourceList = useSelector((state) => state.dataSource.dataSourceList)
  const dataSourceImplementedExplain = useMemo(
    () =>
      dataSourceList
        .filter(({ editorToolbarButtons }) =>
          editorToolbarButtons.includes('EXPLANATION'),
        )
        .map(({ dataSourceName }) => dataSourceName),
    [dataSourceList],
  )

  const { plSql, connectionType } = useSelector(activePaneInfoSelector) || {}
  const supportExplanation =
    connectionType && dataSourceImplementedExplain.includes(connectionType)

  // 实时监听滚动条位置
  useEffect(() => {
    if (!editorInstance) return
    const eventDisposer = editorInstance?.onDidScrollChange((e) => handleChangeScrollTop?.(e))
    return () => {
      eventDisposer.dispose()
    }
  }, [editorInstance])

  useEffect(() => {
    // editor 当前 model 内容变化事件
    // const editorInstance = editorInstanceRef.current
    if (!editorInstance) return
    const eventDisposer = editorInstance.onDidChangeModelContent((event) => {
      onChangeModelContent?.(editorInstance, event)
    })
    return () => {
      eventDisposer.dispose()
    }
  }, [editorInstance, onChangeModelContent])

  useEffect(() => {
    if (!editorInstance) return
    const eventDisposer = editorInstance.onDidChangeCursorPosition(async (event) => {
      onChangeCursorPosition && onChangeCursorPosition(event.position)  
    })
    
    return () => {
      eventDisposer.dispose()
    }
  }, [editorInstance, onChangeCursorPosition])

  useEffect(() => {
    // action 转大写
    if (!editorInstance) return
    const actionDisposer = editorInstance.addAction({
      id: 'custom.edit.uppercase',
      label: '大小写转换',
      contextMenuGroupId: 'edit',
      contextMenuOrder: 1,
      run: (editor) => {
        if (transfer) {
          const upperStr = editor.getModel()?.getValue()?.toUpperCase() ?? ''
          editor.getModel()?.setValue(upperStr)
        } else {
          const lowerStr = editor.getModel()?.getValue().toLowerCase() ?? ''
          editor.getModel()?.setValue(lowerStr)
        }
        setTransfer(!transfer)
      },
    })
    return () => actionDisposer.dispose()
  }, [editorInstance, transfer])

  useEffect(() => {
    if (!editorInstance) return
    const actionDisposer = editorInstance.addAction({
      id: 'editor.action.formatDocument',
      label: '格式化',
      contextMenuGroupId: 'formatDocument',
      contextMenuOrder: 1,
      run: (editor) => {
        const value = editor.getModel()?.getValue() || ''
        // 选择数据源类型格式化
        let language: typeof formatOptionalLangs[number]
        switch (connectionType) {
          case 'Oracle':
            language = 'plsql'
            break
          case 'SQLServer':
            language = 'tsql'
            break
          // more case ... 

          default:
            language =
              formatOptionalLangs.find(
                (lang: string) => lang === connectionType?.toLowerCase(),
              ) || 'sql'
            break
        }

        const formated = format(value, { language })
        editor.getModel()?.setValue(formated)
      },
    })
    return () => actionDisposer.dispose()
  }, [connectionType, editorInstance])

  useEffect(() => {
    // action 复制
    if (!editorInstance) return
    // const copyMessage = function (e: ClipboardEvent) {
    //   message.success('复制成功')
    // }
    // 此处只对复制的事件监听后提示
    // document.addEventListener('copy', copyMessage);
    // 使用keybindings 后 会导致默认的快捷键 复制的内容不对 所以将提示拆分 监听copy 方法 来提示 
    // 下面的逻辑保持不变 取消掉对复制快捷键的覆盖
    const actionDisposer = editorInstance.addAction({
      id: 'custom.edit.copy',
      label: '复制',
      //keybindings: [KeyMod.CtrlCmd | KeyCode.KEY_C],
      contextMenuGroupId: 'edit',
      contextMenuOrder: 1,
      run: (editor) => {
        editor.getAction('editor.action.clipboardCopyWithSyntaxHighlightingAction').run()
        message.success("复制成功");
      },
    })
    return () => {
      actionDisposer.dispose();
      // document.removeEventListener('copy', copyMessage)
    }
  }, [editorInstance])


  useEffect(() => {
    // action 执行(选中)语句
    if (!editorInstance) return
    const actionDisposer = editorInstance.addAction({
      id: 'custom.execute.executeSelectedText',
      label: '执行（选中语句）',
      keybindings: executeMonaco,
      contextMenuGroupId: 'execute',
      contextMenuOrder: 2,
      run: () => {
        // 执行语句
        onExecuteSelectedText?.()
      },
    })
    return () => actionDisposer.dispose()
  }, [editorInstance, onExecuteSelectedText, executeMonaco])

  useEffect(() => {
    const actionDisposer = editorInstance?.addAction({
      id: 'custom.lsp.autoCompletion',
      label: '执行（选中语句）',
      keybindings: promptMonaco,
      keybindingContext:
        'editorTextFocus && !editorHasSelection && ' +
        '!editorHasMultipleSelections && !editorTabMovesFocus && ' +
        '!hasQuickSuggest',
      run: (editor) => {
        // 执行语句
        editor.trigger('', 'editor.action.triggerSuggest', '')
      },
    })
    return () => actionDisposer?.dispose()
  }, [editorInstance, promptMonaco])

  // const { plSql, connectionType } = useSelector(activePaneInfoSelector) || {}
  // const supportExplanation =
  //   connectionType && dataSourceImplementedExplain.includes(connectionType)

  useEffect(() => {
    // action 执行计划
    if (!supportExplanation || plSql) return
    const disposer = editorInstance?.addAction({
      id: 'custom.execute.explainSelectedText',
      label: '执行计划（选中语句）',
      keybindings: execPlanMonaco,
      contextMenuGroupId: 'execute',
      contextMenuOrder: 3,
      run: () => {
        // 解释语句
        onCommandExplain?.()
      },
    })
    return () => disposer?.dispose()
  }, [
    editorInstance,
    onCommandExplain,
    plSql,
    supportExplanation,
    execPlanMonaco,
  ])

  useEffect(() => {
    if (options && editorInstance) {
      // 更新 instance options
      editorInstance.updateOptions({ ...defaultOptions, ...options })
    }
  }, [editorInstance, options])

  useEffect(() => {
    // 组件 props 没有 model 时, 使用实例化 editor 时的 model
    if (model == null || !editorInstance) return
    // 组件 props 传入了 model 时, 设置 model(ITextModel or null)
    editorInstance.setModel(model)
  }, [editorInstance, model])

  // 分句选择
  useEffect(() => {
    if (!editorInstance || !connectionType) return
    // 目前只支持mysql和oracle
    if (!['oracle', 'mysql'].includes(connectionType?.toLowerCase())) {
      return
    }
    // 添加按住Ctrl加鼠标单击触发分句事件
    editorInstance.onMouseDown((e) => {
      if (!e.event.ctrlKey) {
        return;
      }
      if ([monaco.editor.MouseTargetType.CONTENT_EMPTY, monaco.editor.MouseTargetType.CONTENT_TEXT].includes(e.target.type)) {
        const cursorPosition: IPosition = e.target.position as IPosition;
        const sqls = handleSqlSplit(connectionType, editorInstance.getValue())
        setSqlSelection(sqls, editorInstance, cursorPosition)
      }
    });

    const disposer = editorInstance.addAction({
      id: 'custom.execute.splitSelectedText',
      label: '分句（选中语句）',
      keybindings: splitMonaco,
      contextMenuGroupId: 'navigation',
      contextMenuOrder: 4,
      run: (ed: any) => {
        const sqls = handleSqlSplit(connectionType, ed.getValue())
        const selection = ed.getSelection();
        const cursorPosition = selection.getStartPosition();
        setSqlSelection(sqls, ed, cursorPosition)
      },
    })
    return () => disposer?.dispose()
  }, [connectionType, editorInstance, splitMonaco])

  // //查看表结构
  useEffect(() => {

    if (!editorInstance) return

    const actionDisposer = editorInstance.addAction({
      id: 'custom.execute.viewTableStructure',
      label: `查看定义`,
      contextMenuGroupId: 'viewTableStructure',
      contextMenuOrder: 5,
      run: () => {
        // 查看表结构
       getViewTableStructure?.();
      },
    })

    return () => {
      actionDisposer?.dispose();
    }
  }, [editorInstance])


  // 选中当前所在的sql分句
  const setSqlSelection = (sqls: any[], ed: any, cursorPosition: any) => {
    let selectionRange: monaco.Range | null = null;
    for (let i = 0; i < sqls.length; i++) {
      // antlr4和monaco的坐标有差异，antlr4.column+1=monaco.column
      const monacoRange = new monaco.Range(
        sqls[i].startLine,
        sqls[i].startColumn + 1,
        sqls[i].stopLine,
        sqls[i].stopColumn + 1
      );
      if (monacoRange.containsPosition(cursorPosition)) {
        selectionRange = monacoRange;
      }
    }
    if (selectionRange) {
      ed.setSelection(selectionRange);
    }
  }

  useEffect(() => {
    if (options && editorInstance) {
      // 更新 instance options
      editorInstance.updateOptions({ ...defaultOptions, ...options })
    }
  }, [editorInstance, options])

  useEffect(() => {
    // 组件 props 没有 model 时, 使用实例化 editor 时的 model
    if (model == null || !editorInstance) return
    // 组件 props 传入了 model 时, 设置 model(ITextModel or null)
    editorInstance.setModel(model)
  }, [editorInstance, model])

  return <div className={className} ref={containerRef}></div>
}
