import React, { useEffect } from "react";
import {
  getApplyCartPermissionsPanelObject,
} from 'src/api';
import { useRequest } from 'src/hook';
import SubchemaLevelComponent from '../components/SubSchemaLevelComponent';

export const SubSchemaLevelContent = ({
  selectTreeItem,
  flowMainUUID,
}: {
  selectTreeItem?: any;
  flowMainUUID: string | undefined;
}) => {

  const { data: objectResult, loading, run: runGetApplyCartPermissionsPanelObject } = useRequest(getApplyCartPermissionsPanelObject, {
    manual: true
  })

  useEffect(() => {

    if (!selectTreeItem || !flowMainUUID) return;
    
    const {
      nodeName,
      nodePathWithType,
      nodeType,
      dataSourceType,
      sdt,
    } = selectTreeItem;
    const connectionType = dataSourceType || sdt?.connectionType
    const params = {
      connectionId: null,
      connectionType,
      nodeType,
      nodeName,
      nodePathWithType,
      flowMainUUID,
    };
    runGetApplyCartPermissionsPanelObject(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectTreeItem?.key, flowMainUUID]);


  return (
    <SubchemaLevelComponent
      loading={loading}
      objectResult={objectResult}
      selectTreeItem={selectTreeItem}
      viewAppliedDetail={true}
      canEdit={false}
    />
  )
}