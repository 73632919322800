import { SdtNodeType } from 'src/api'
import type { SqlLanguage } from 'sql-formatter'

export const ORACLE_COLUMN_TYPES = [
  'VARCHAR2',
  'NVARCHAR2',
  'NUMBER',
  'FLOAT',
  'LONG',
  'DATE',
  'BINARY_FLOAT',
  'BINARY_DOUBLE',
  'TIMESTAMP',
  'TIMESTAMP WITH TIME ZONE',
  'TIMESTAMP WITH LOCAL TIME ZONE',
  'INTERVAL YEAR TO MONTH',
  'INTERVAL DAY TO SECOND',
  'RAW',
  'LONG RAW',
  'ROWID',
  'UROWID',
  'CHAR',
  'NCHAR',
  'CLOB',
  'NCLOB',
  'BLOB',
  'BFILE'
]

export const MYSQL_COLUMN_TYPES = [
  'bigint',
  'blob',
  'char',
  'date',
  'datetime',
  'decimal',
  'double',
  'float',
  'int',
  'longtext',
  'smallint',
  'text',
  'time',
  'timestamp',
  'tinyblob',
  'tinyint',
  'tinytext',
  'varchar',
  'year',
]

export const SQLSERVER_COLUMN_TYPES = [
  'bigint',
  'bit',
  'decimal',
  'int',
  'money',
  'numeric',
  'smallint',
  'smallmoney',
  'tinyint',
  'float',
  'real',
  'date',
  'datetime2',
  'datetime',
  'datetimeoffset',
  'smalldatetime',
  'time',
  'char',
  'varchar',
  'text',
  'nchar',
  'nvarchar',
  'ntext',
  'binary',
  'varbinary',
  'image',
  'cursor',
  'rowversion',
  'hierarchyid',
  'uniqueidentifier',
  'sql_variant',
  'xml',
  'Spatial Geometry Types',
  'Spatial Geography Types',
  'table',
]

export const IMPALA_COLUMN_TYPES = [
  'boolean',
  'tinyint',
  'smallint',
  'int',
  'bigint',
  'decimal',
  'float',
  'double',
  'timestamp',
  'string',
  'varchar',
  'char',
]

export const VERTICA_COLUMN_TYPES = [
  'binary ',
  'varbinary',
  'long varbinary',
  'bytea',
  'raw',
  'boolean',
  'char',
  'varchar',
  'long varchar',
  'date',
  'time',
  'datetime',
  'timestamp',
  'float',
  'float8',
  'int',
  'bigint',
  'integer',
  'smallint',
  'tinyint',
  'decimal',
  'numeric',
  'number',
  'uuid',
]

export const HIVE_COLUMN_TYPES = [
  'tinyint',
  'smallint',
  'int',
  'bigint',
  'boolean',
  'binary',
  'float',
  'double',
  'decimal',
  'string',
  'varchar',
  'char',
  'timestamp',
  'date',
  'array<int>',
  'array<string>',
  'map<int,int>',
  'map<string,string>',
  'map<int,string>',
  'map<string,int>',
]

export const POSTGRESQL_COLUMN_TYPES = [
  'bigint',
  'bigserial',
  'bit',
  'bit varying',
  'boolean',
  'box',
  'bytea',
  'character',
  'character varying',
  'cidr',
  'circle',
  'date',
  'double precision',
  'inet',
  'integer',
  'interval',
  'json',
  'jsonb',
  'line',
  'lseg',
  'macaddr',
  'macaddr8',
  'money',
  'numeric',
  'path',
  'pg_lsn',
  'point',
  'polygon',
  'real',
  'smallint',
  'smallserial',
  'serial',
  'text',
  'time',
  'timestamp',
  'tsquery',
  'tsvector',
  'txid_snapshot',
  'uuid',
  'xml',
  'timestamp with time zone',
  'time with time zone',
]

// 数据库同层级类型
export const databaseLike: SdtNodeType[] = [
  'database',
  'redisDataBase',
  'oracleUser',
  'schema',
]

export const formatOptionalLangs: readonly SqlLanguage[] = [
  'db2',
  'mariadb',
  'mysql',
  'n1ql',
  'plsql',
  'postgresql',
  'redshift',
  'spark',
  'sql',
  'tsql',
] as const

//含有schema层级的数据源
export const INCLUDE_SCHEMA_CONNECTIONS = [
  'SQLServer',
  'DamengDB',
  'DB2',
  'MogDB',
  'PostgreSQL',
  'KingBasePG',
  'KingBaseOracle',
  'PolarDB',
  'OracleCDB',
  'StarRocks',
  'Vertica',
  'Trino',
  'GaussDB_DWS',
  'HighGo',
  'TDSQLPG',
  'Greenplum',
  'GaussDB',
  'OpenGauss',
  'GaussDB_DWS',
  'OceanBaseMySQL',
  'Vertica',
  'GBase8c',
  'GoldenDB',
];
