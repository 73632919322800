import React, { useEffect } from "react";
import * as _ from 'lodash';
import {
  getCartPermissionsPanelUpObject,
} from 'src/api';
import { useRequest, useSelector, useDispatch } from 'src/hook';
import { updateConnectionObjectPermissions } from './applicationListSlice'
import AboveSchemaLevelComponent from '../components/AboveSchemaLevelComponent';

export const AboveSchemaLevelContent = ({
  selectTreeItem,
  permissionCollectionVOS,
  updatePermissionCollectionVOS,
}: {
  isEdit?: boolean;
  selectTreeItem?: any;

  isInShoppingCart?: boolean;
  viewAppliedDetail?: boolean;
  permissionCollectionVOS?: any;
  updatePermissionCollectionVOS?: (params: any) => void

}) => {

  const dispatch = useDispatch();
  const { connectionObjectPermissions } = useSelector(state => state.applicationList)

  const { data: list, loading, run: runGetAutomaticObjectPermission } = useRequest(getCartPermissionsPanelUpObject, {
    manual: true
  })

  useEffect(() => {
   if(_.isEmpty(selectTreeItem)) return
    const { connection, dataSourceType, nodePathWithType } = selectTreeItem || {};
    const { connectionType } = connection || {};

    if (nodePathWithType && (connectionType || dataSourceType)) {
      runGetAutomaticObjectPermission({
        nodePath: nodePathWithType,
        dataSourceType: dataSourceType || connectionType
      })
    }
  }, [selectTreeItem?.key])

  return (
    <AboveSchemaLevelComponent
      selectTreeItem={selectTreeItem}
      list={selectTreeItem ?  list: []}
      isEdit={true}
      loading={loading}
      permissionCollectionVOS={permissionCollectionVOS}
      updatePermissionCollectionVOS={updatePermissionCollectionVOS}
      isInShoppingCart={true}
      connectionObjectPermissions={connectionObjectPermissions}
      onUpdateConnectionObjectPermissions={(params: any) =>dispatch(updateConnectionObjectPermissions(params))}
    />
  )
}