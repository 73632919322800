import React, { useEffect, useState } from 'react'
import { useSelector } from 'src/hook'
import { Button, Input, Select, Form, Tree, Tooltip, message } from 'antd'
import { RightOutlined, DownOutlined, PlusOutlined } from '@ant-design/icons'
import { DatabaseInfo } from 'src/pageTabs/queryPage/queryTabs/queryTabsSlice'
import { getObTableViewGroup, getNodeChildList, generateViewSelectSql, generateViewCreateSql } from 'src/api'
import styles from './index.module.scss'
import { Iconfont } from 'src/components'
import _ from 'lodash';
import { countCharactersLength, getCharactersPosition } from './utils'

interface StepInfoProps {
  databaseInfo: Partial<DatabaseInfo>
  handleCreateSql:(p: string)=>void
  [p: string]: any
}

const StepsInfo = ({
  databaseInfo,
  handleCreateSql
}: StepInfoProps) => {
  const [form] = Form.useForm();
  const [nextStepBtnDisable, setNextStepBtnDisable] = useState(true)
  const [fieldsTreeDataOrigin, setFieldsTreeDataOrigin] = useState<any[]>([])  // 搜索数据备份
  const [fieldsTreeData, setFieldsTreeData] = useState<any[]>([])
  const [tableViewTreeDataOrigin, setTableViewTreeDataOrigin] = useState<any[]>([])  // 搜索数据备份
  const [tableViewTreeData, setTableViewTreeData] = useState<any[]>([])
  const [tableViewTreeCheckData, setTableViewTreeCheckData] = useState<any[]>([])
  const [fieldTreeCheckData, setFieldTreeCheckData] = useState<any[]>([])
  const [tableViewSelectData, setTableViewSelectData] = useState<any>([])
  const [customFields, setCustomFields] = useState<any[]>([])
  const [baseInfo, setBaseInfo]= useState<any>({})
  const [tableViewExpandedKeys, setTableViewExpandedKeys] = useState<any>(['root'])
  const [fieldsDesc, setFieldsDesc] = useState<string>('')
  const [tableViewDesc, setTableViewDesc] = useState<string>('')
  const [stepVisible, setStepVisible] = useState({
    '1': true,
    '2': false,
    '3': false,
  })
  
  const {
    connectionId,
    connectionType,
    databaseName,
    nodeType,
    nodePath,
    nodePathWithType,
    groupId,
  } = databaseInfo
  const {status = false } = useSelector((state) => state.sdtPermission)
  const { toolbarConnections } = useSelector(state => state.editor)
  const connection = toolbarConnections?.find((t) => t.connectionId === connectionId)
  const connectionName = connection?.alias ? connection.alias : connection?.nodeName

  useEffect(()=>{
    getTableViewGroup()
  },[])

  // 获取表组和视图组
  const getTableViewGroup = () => {
    const params = {
      connectionId,
      connectionType,
      nodeType: connectionType?.toLowerCase() === 'oceanbase' ? 'schema' : 'database',
      nodePath,
      nodePathWithType,
      groupId,
      globalHavePermissionFlag: status
    }
    getObTableViewGroup(params).then((res: any)=>{
      const { data = [] } = res
      const treeData = [{
        key:'root',
        title: '表和视图',
        selectable: false,
        children: data?.map((i: any)=>{
          return {
            ...i,
            title: (
              <span>
                <Iconfont type={`icon-${i?.nodeName==='视图组' ? 'viewGroup' : 'tableGroup'}`} className='mr4' />
                { 
                  i.nodeName?.length > 15
                  ? <Tooltip getPopupContainer={(node: any)=>node.parentNode.parentNode.parentNode} title={i?.nodeName}>{`${i.nodeName?.slice(0, 15)}...`}</Tooltip> 
                  : i.nodeName
                }
              </span>
            ),
            key: i.nodePath,
            isLeaf: false,
            selectable: false
          }
        })
      }]
      setTableViewTreeData(treeData)
      setTableViewTreeDataOrigin(treeData)
    }).catch((err: any)=>{
      console.error('获取表组和视图组数据失败',err)
    })
  }

  // 表和视图子级数据加载
  const handleLoadTableViewData = (info: any) => {
    const { 
      connectionId,
      connectionType,
      groupId,
      nodeName,
      nodePath,
      nodePathWithType,
      nodeType
    } = info
    if(!connectionId || !connectionType){
      return Promise.resolve()
    }
    const params = {
      connectionId,
      connectionType,
      groupId,
      nodeName,
      nodePath,
      nodePathWithType,
      nodeType,
      globalHavePermissionFlag: status,
    }
    const regTableView = /(?:SCHEMA):(.*)/;  
    const tableViewDesc = nodePathWithType?.match(regTableView)?.[1]
    setTableViewDesc(tableViewDesc)
    return new Promise<void>(resolve => {
      getNodeChildList(params)
      .then((res: any)=>{
        const childData = res?.data?.map((i: any)=>{
          return {
            ...i,
            isLeaf: true,
            key: i.nodePath,
            title: (
              <span>
                <Iconfont type={`${i?.nodeType === 'view' ? 'icon-view' : 'icon-table'}`} className='mr4' />
                { 
                  i.nodeName?.length > 15 
                  ? <Tooltip getPopupContainer={(node: any)=>node.parentNode.parentNode.parentNode} title={i?.nodeName}>{`${i.nodeName?.slice(0, 15)}...`}</Tooltip> 
                  : i.nodeName
                }
              </span>
            ),
          }
        })
        const recursionData = (data: any[], children: any[]) => {
          return data?.map((i: any)=>{
            if(i?.children){
              i.children = recursionData(i.children, children)
            }else if(i?.nodePath===info?.nodePath){
              i.children = children
            }
            return {...i}
          })
        }
        setTableViewTreeData((d: any[])=>{
          return recursionData(d, childData)
        })
        setTableViewTreeDataOrigin((d: any[])=>{
          return recursionData(d, childData)
        })
      })
      .catch(err=>{console.log('load children error',err)})
      .finally(()=>{
        resolve()
      })
    });
  }

  const handleTableViewTreeCheck = (_: any, info: any) =>{
    if(['table', 'view'].includes(info?.selectedNodes?.[0]?.nodeType)){
      const data: any[] = [...info?.selectedNodes]
      setTableViewTreeCheckData(data)
    }
  }

  const handleFieldsTreeCheck: any = (_: any[], info: any) => {
    setFieldTreeCheckData(info?.checkedNodes)
  }

  const handleTableViewChoose = () => {
    if(!tableViewTreeCheckData?.length){
      return message.warning('请选择表或视图')
    }
    const data = _.cloneDeep(tableViewTreeCheckData)
    setTableViewSelectData(data)
  }

  const handleTableViewDelete = () => {
    setTableViewSelectData([])
  }

  const handleFieldsChoose = () => {
    setCustomFields((field: any[])=>{
      const usedFields = field?.filter((i: any)=>(!i?.delete))
      const needAddData = fieldTreeCheckData
                          ?.filter((i: any)=>i?.nodeType !== 'columnGroup')
                          ?.filter((i: any)=>!usedFields?.filter((j)=>j?.nodePath===i?.nodePath)?.length)
      const addFields = needAddData?.map((i: any, index: number)=>{
        return {
          ...i,
          id: field?.length + index + 1
        }
      })
      return [
        ...field,
        ...addFields
      ]
    })
  }

  // 查询字段信息
  const queryFieldsInfo = (data: any[]) => {
    const { 
      connectionId,
      connectionType,
      groupId,
      nodeName,
      nodePath,
      nodePathWithType,
      nodeType
    } = data?.[0]
    const regFields = /(?:TABLE|VIEW):(.*)/;
    const fieldsDesc = nodePathWithType?.match(regFields)?.[1]
    setFieldsDesc(fieldsDesc)
   
    const params: any = {
      connectionId,
      connectionType,
      groupId,
      nodeName: '列组',
      nodePath: nodePath+'/'+'列组',
      nodePathWithType,
      nodeType: 'columnGroup',
      globalHavePermissionFlag: status,
    }
    getNodeChildList(params).then((res: any)=>{
      const childData = res?.data?.map((i: any)=>{
        return {
          ...i,
          isLeaf: true,
          key: i.nodePath,
          title: (
            <span>
              <Iconfont type='icon-ziduan' className='mr4' />
              { 
                i.nodeName?.length > 15 
                ? <Tooltip getPopupContainer={(node: any)=>node.parentNode.parentNode.parentNode} title={i?.nodeName}>{`${i.nodeName?.slice(0, 15)}...`}</Tooltip> 
                : i.nodeName
              }
            </span>
          ),
        }
      })
      const treeData = [
        {
          ...data?.[0],
          isLeaf: childData?.length ? false : true,
          children: childData,
          nodeType: 'columnGroup'
        }
      ]
      setFieldsTreeData(treeData)
      setFieldsTreeDataOrigin(treeData)
    }).catch((err: any)=>{
      console.error('获取字段数据失败',err)
    })
  }

  const handleAddCustom = () => {
    if(!customFields?.length){
      return
    }
    const hasEmpty = customFields
                      ?.filter((i: any)=>!i?.delete)
                      ?.filter((i: any)=>!i?.nodeName?.trim())?.length
    if(!!hasEmpty){
      message.error('自定义字段有空值,请先完善后再添加')
      return
    }
    setCustomFields((fields: any[])=>{
      return [...fields, {id: fields?.length + 1}]
    })
  }

  const handleDeleteCustom = async (id: number) => {
    let deleteNodePath = ''
    await setCustomFields((fields: any[])=>{
      return fields?.map((field: any)=>{
        if(field?.id === id){
          field.delete = true
          deleteNodePath = field?.nodePath
        }
        return {...field}
      })
    })

    if(deleteNodePath){
      // 过滤删除项和全选
      setFieldTreeCheckData((d: any[])=>{
        return d?.filter((i: any)=>(i?.nodePath !== deleteNodePath && i?.nodeType !== 'columnGroup'))
      })
    }
  }

  const handleCustomFieldsChange = (name: string, value: string, id: any) => {
    setCustomFields((fields: any[])=>{
      return fields?.map((field: any)=>{
        if(field?.id === id){
          field[name] = value
          field.edit = false
        }
        return {...field}
      })
    })
  }

  const handleEditCustomFields = (id: any) => {
    setCustomFields((fields: any[])=>{
      return fields?.map((field: any)=>{
        if(field?.id === id){
          field.edit = true
        }
        return {...field}
      })
    })
  }

  const handleTableViewChange = (value: string) => {
    setTableViewSelectData((tableView: any[])=>{
      return tableView?.map((d: any)=>{
        d.aliasName = value
        return {...d}
      })
    })
  }

  const handleTableViewTreeExpand = (expandedKeys: any[]) => {
    setTableViewExpandedKeys(expandedKeys)
  }

  const treeSearch = (searchData: any[], value: string) => {
    return searchData?.filter((i: any)=>{
      if(i?.children?.length){
       i.children = treeSearch(i.children, value)
       return true
      }
      return i?.nodeName?.toLowerCase()?.includes(value?.toLowerCase())
    })
  } 

  const handleTableViewSearch = (value: string) => {
    const tableViewTreeDataCopy = _.cloneDeep(tableViewTreeDataOrigin)
    if(!value?.trim()){
      return setTableViewTreeData(tableViewTreeDataCopy)
    }
    const result = treeSearch(tableViewTreeDataCopy, value)
    setTableViewTreeData(result)
  }

  const handleFieldsSearch = (value: string) => {
    const fieldsTreeDataCopy = _.cloneDeep(fieldsTreeDataOrigin)
    if(!value?.trim()){
      return setFieldsTreeData(fieldsTreeDataCopy)
    }
    const result = treeSearch(fieldsTreeDataCopy, value)
    setFieldsTreeData(result)
  }

  const checkViewName = (_: any, value: any) => {
    if(['', undefined, null].includes(value?.trim())){
      return Promise.reject('请输入视图名称')
    }
    return Promise.resolve()
  }

  const handleToggleStep = (step: string) => {
    setStepVisible((s: any)=>{
      return {
        ...s,
        [step]: !s[step]
      }
    })
  }

  const handleStepOneValueChange = (_: any, allValues: any) => {
    setBaseInfo(allValues)
  }

  const handleStepOneOk = () => {
    form.validateFields().then((res: any)=>{
      setStepVisible((s: any)=>{
        return {
          ...s,
          ['1']: false,
          ['2']: true
        }
      })
    }).catch((err: any)=>{
      console.error('校验失败',err)
    })
  }

  const handleStepTwoOk = () => {
    if(!tableViewSelectData?.length){
      message.error('请选择基表')
      return
    }
    queryFieldsInfo(tableViewSelectData)
    setStepVisible((s: any)=>{
      return {
        ...s,
        ['2']: false,
        ['3']: true
      }
    })
  }

  const handleStepThreeOk = () => {
    const hasEmptyField = customFields
                          ?.filter((i: any)=>!i?.delete)
                          ?.filter((i: any)=>!i?.nodeName?.trim())?.length
    if(!customFields?.length){
      return message.error('请选择或自定义字段')
    }
    if(hasEmptyField){
      return message.error('自定义字段不能为空')
    }
    setNextStepBtnDisable(false)
  }

  // 确认sql
  const handleConfirmSql = () => {
    if(!baseInfo?.viewName){
      form.validateFields()
      return message.error('请输入视图名称')
    }
    const params = {
      connectionType,
      schemaName: databaseName,
      databaseName,
      tableName: tableViewSelectData?.[0]?.nodeName,
      tableAliasName: tableViewSelectData?.[0]?.aliasName,
      columns: customFields?.filter((i: any)=>!i?.delete)
                            ?.map((i: any)=>{
                              return {
                                columnName: i?.nodeName,
                                columnAliasName: i?.aliasName
                              }
                            })
    }
    generateViewSelectSql(params).then((res: any)=>{
      if(res){
        const { sql } = res
        const paraments = {
          dataSourceType: connectionType,
          nodeType,
          userInputs: {
            databaseName,
            viewSql: sql,
            viewName: baseInfo?.viewName,
            checkPoint: baseInfo?.checkItem,
          }
        }
        generateViewCreateSql(paraments).then((res: any)=>{
          if(res){
            const { generatedSql } = res
            handleCreateSql && handleCreateSql(generatedSql)
          }
        }).catch((err)=>{
          console.error('生成view create sql语句失败',err)
        })
      }

    }).catch((err)=>{
      console.error('获取sql语句失败',err)
    })
  }

  const stepsInfo = [{
    title: (
      <div className={styles.title} onClick={()=>{handleToggleStep('1')}}>
        {
          stepVisible['1'] 
          ? <DownOutlined style={{marginRight: '16px', color: '#A4ADB0', fontSize: '12px'}} />
          : <RightOutlined style={{marginRight: '16px', color: '#A4ADB0', fontSize: '12px'}} />
        }
        1.基本信息
      </div>
    ),
    content: (
      <div className={stepVisible['1'] ? styles?.['animateShow'] : styles?.['animateHide']}>
        <Form form={form} layout='inline' initialValues={{checkItem: ' '}} onValuesChange={handleStepOneValueChange}>
          <Form.Item label="视图名称" name="viewName" rules={[{ required: true, validator: checkViewName }]}>
            <Input style={{width: 280}} placeholder='请输入视图名称' />
          </Form.Item>
          <Form.Item label="检查项" name="checkItem" className='ml10'>
            <Select style={{width: 280}} placeholder='请选择检查项'>
              <Select.Option value=" ">无</Select.Option>
              <Select.Option value="WITH READ ONLY">只读</Select.Option>
            </Select>
          </Form.Item>
        </Form>
        <Button 
          style={{marginLeft: 84, marginBottom: 20}} 
          type="primary"
          onClick={handleStepOneOk}
        >
          确定
        </Button>
      </div>
    )
  }, {
    title: (
      <div className={styles.title} onClick={()=>{handleToggleStep('2')}}>
        {
          stepVisible['2'] 
          ? <DownOutlined style={{marginRight: '16px', color: '#A4ADB0', fontSize: '12px'}} />
          : <RightOutlined style={{marginRight: '16px', color: '#A4ADB0', fontSize: '12px'}} />
        }
        2.基表选择
      </div>
    ),
    content: (
      <div  className={stepVisible['2'] ? styles?.['animateShow'] : styles?.['animateHide']}>
        <div className='flexAlignCenter'>
          <div className={styles.leftPane}>
            <Input.Search 
              placeholder='请输入表/视图名称' 
              className='mb10' 
              onChange={(e: any)=>handleTableViewSearch(e.target.value)}
              allowClear 
            />
            {
              !!tableViewTreeData?.length && 
              <Tree
                expandedKeys={tableViewExpandedKeys}
                onExpand={handleTableViewTreeExpand}
                treeData={tableViewTreeData}
                onSelect={handleTableViewTreeCheck}
                loadData={handleLoadTableViewData}
                height={340}
              />
            }
          </div>
          <Button type='primary' className='ml10 mr10' onClick={handleTableViewChoose}><RightOutlined /></Button>
          <div className={styles.rightPane}>
            {
              tableViewSelectData?.map((i: any, index: number)=>{
                return (
                  <div className={styles.items} key={index}>
                    <div className='mr10 flexAlignCenter flex1'>
                      <Iconfont className='mr4' type={`${i?.nodeType === 'view' ? 'icon-view' : 'icon-table'}`} />
                      <span style={{color: '#0F244C', marginRight: 4}}>
                        {
                          countCharactersLength(i?.nodeName) > 18
                          ? <Tooltip title={i?.nodeName}>{`${i?.nodeName?.slice(0, getCharactersPosition(i?.nodeName, 18))}...`}</Tooltip> 
                          : i?.nodeName
                        }
                      </span>
                      <span style={{color: '#667084'}}>
                        {
                          !!tableViewDesc && 
                          countCharactersLength(tableViewDesc) > 6
                            ? <Tooltip title={tableViewDesc}>{`(${tableViewDesc?.slice(0, getCharactersPosition(tableViewDesc, 6))}...)`}</Tooltip> 
                            : `(${tableViewDesc})`
                        }
                      </span>
                    </div>
                    <Input 
                      size='small' 
                      placeholder='别名' 
                      className='mr10' 
                      style={{width: 120}}
                      onChange={(e)=>handleTableViewChange(e.target.value)}
                      value={i?.aliasName}
                    />
                    <Iconfont 
                      className={styles.delete} 
                      type="icon-delete_disable"
                      onClick={handleTableViewDelete} 
                    />
                  </div>
                )
              })
            }
          </div>
        </div>
        <Button className='mt10 mb20' type='primary' onClick={handleStepTwoOk}>确定</Button>
      </div>
    )
  },{
    title: (
      <div className={styles.title} onClick={()=>{handleToggleStep('3')}}>
        {
          stepVisible['3'] 
          ? <DownOutlined style={{marginRight: '16px', color: '#A4ADB0', fontSize: '12px'}} />
          : <RightOutlined style={{marginRight: '16px', color: '#A4ADB0', fontSize: '12px'}} />
        }
        3.字段选择
      </div>
    ),
    content: (
      <div className={stepVisible['3'] ? styles?.['animateShow'] : styles?.['animateHide']}>
        <div className='flexAlignCenter'>
          <div className={styles.leftPane}>
            <Input.Search 
              placeholder='请输入字段名称' 
              className='mb10' 
              onChange={(e: any)=>handleFieldsSearch(e.target.value)}
              allowClear  
            />
            {
              !!fieldsTreeData?.length &&
              <Tree
                checkable
                onCheck={handleFieldsTreeCheck}
                checkedKeys={fieldTreeCheckData?.map((i: any)=>i?.nodePath)}
                treeData={fieldsTreeData}
                height={340}
              />
            }
          </div>
          <Button type='primary' className='ml10 mr10' onClick={handleFieldsChoose}><RightOutlined /></Button>
          <div className={styles.rightPane}>
            {/* <div className={`${customFields?.length ? 'options' : 'disabled'} mb10`} onClick={handleAddCustom} ><PlusOutlined className='mr4' />添加自定义字段</div> */}
            <div style={{maxHeight: 350, paddingRight: 10, overflowY: 'auto'}}>
              {
                customFields?.map((item, index) => {
                  if(item?.delete) return null
                  return (
                    <div className={styles.items} key={index}>
                      <div className='mr10 flexAlignCenter flex1'>
                        <Iconfont className='fs14 mr4' type='icon-ziduan' />
                        {
                          !item?.edit && item?.nodeName 
                          ? <span 
                              style={{color: '#0F244C', marginRight: 4}} 
                              onDoubleClick={()=>handleEditCustomFields(item?.id)}
                            >
                              {
                                countCharactersLength(item?.nodeName) > 18 
                                ? <Tooltip title={item?.nodeName}>{`${item?.nodeName?.slice(0, getCharactersPosition(item?.nodeName, 18))}...`}</Tooltip> 
                                : item?.nodeName
                              }
                            </span>
                          : <Input 
                              defaultValue={item?.nodeName}
                              size='small' 
                              placeholder='输入自定义字段名'
                              onBlur={(e: any)=>handleCustomFieldsChange('nodeName', e.target.value, item?.id)} 
                            />
                        }
                        <span style={{color: '#667084'}}>
                          {
                            !!fieldsDesc && 
                            countCharactersLength(fieldsDesc) > 6
                              ? <Tooltip title={fieldsDesc}>{`(${fieldsDesc?.slice(0, getCharactersPosition(fieldsDesc, 6))}...)`}</Tooltip> 
                              : `(${fieldsDesc})`
                          }
                        </span>
                      </div>
                      <Input 
                        size='small' 
                        placeholder='别名' 
                        className='mr10' 
                        style={{width: 120}} 
                        value={item?.aliasName} 
                        onChange={(e: any)=>handleCustomFieldsChange('aliasName', e.target.value, item?.id)} 
                      />
                      <Iconfont className={styles.delete} type="icon-delete_disable" onClick={()=>{handleDeleteCustom(item?.id)}} />
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
        <Button className='mt10 mb20' type='primary' onClick={handleStepThreeOk}>确定</Button>
      </div>
    )
  }]

  return (
    <div className={styles.createViewPane}>
      <div className={styles.descriptionItems}>
        <span className={styles.item}>连接名:{connectionName}</span>
        <span className={styles.item}>数据库:{databaseName}</span>
      </div>
      {
        stepsInfo?.map((i: any, index: number)=>{
          return (
            <div key={index} className={styles.stepsWrap}>
              {i.title}
              {i.content}
            </div>
          )
        })
      }
      <Button 
        type='primary'
        className={styles.nextStep} 
        disabled={nextStepBtnDisable} 
        onClick={handleConfirmSql}
      >
        下一步,确认SQL
      </Button>
    </div>
  )
}

export default StepsInfo