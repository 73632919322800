import React, { useCallback } from 'react'
import classnames from 'classnames'
import styles from './singleFile.module.scss'
import { deleteFiles, UserFile } from 'src/api'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { clickFile, setClickTreeOrlist, setIsNotClickFile, setPwd, setTreeTag, setConnectionName } from './myFolderSlice'
import { Dropdown, Menu, message } from 'antd'
import { handleDownload } from 'src/util'
import { showModal } from 'src/store/extraSlice/modalVisibleSlice'
import copy from 'copy-to-clipboard'
import { Iconfont } from 'src/components'

interface SingleFileProps {
  file: UserFile
  selected: boolean
  refresh: () => Promise<any>
  lastPathLen?: number | undefined
}

const getFileIcon = (file: UserFile) => {
  const { isDir } = file
  // todo: 后端更新后，使用后端传来的 type
  const type = isDir ? 'directory' : 'file'
  switch (type) {
    case 'directory':
      return <Iconfont type='icon-wenjianjia' />
    case 'file':
      return <Iconfont type='icon-wenjian'/>
  }
}

export const SingleFile: React.FC<SingleFileProps> = (props) => {
  const { file, selected, refresh, lastPathLen } = props
  const { connectionName, type, path, isDir, isSystemSpaceDirChild } = file

  const { pwd } = useSelector((state) => state.myFolder)
  const dispatch = useDispatch()

  const handleDoubleClick = (file: UserFile) => () => {
    const { type, path, connectionName } = file
    if (type === 'file') {
      tryDownloadFile(path)
      return
    }
    if (type === 'directory') {
      dispatch(setPwd(path))
      if(lastPathLen && (lastPathLen < 3)) {
        dispatch(setConnectionName(connectionName))
      }
      refresh()
      return
    }
  }


  const { run: tryDeleteFiles } = useRequest(deleteFiles, {
    manual: true,
    onSuccess: () => {
      message.success('删除成功')
      refresh()
      dispatch(setPwd(pwd.path))
    },
    // onError: () => {
    //   message.error('删除失败')
    // },
  })

  const tryDownloadFile = useCallback((path: string) => {
    handleDownload({ href: `/export/download?path=${path}` })
  }, [])

  const renderMenuItems = () => {
    let MenuItem = []
    if (file?.isSystemSpaceDirChild) {
      MenuItem = [
        // <Menu.Item key="dele" onClick={() => {console.log('系统空间-删除文件夹 file, path :>> ', file, path)}}>删除</Menu.Item>,
        <Menu.Item key="dele" className='chooseFile' onClick={() => {
          tryDeleteFiles([path])
        }}>删除</Menu.Item>,
        <Menu.Item key="copyPath" onClick={() => {
          copy(path)
          dispatch(setIsNotClickFile(true)) // 选择复制选项，对应的文件夹取消选中状态
          message.success('复制成功')
        }}>复制文件地址</Menu.Item>]
    } else {
      MenuItem = [
        <Menu.Item key="dele" className="chooseFile" onClick={() => {
          tryDeleteFiles([path])
        }}>删除</Menu.Item>,
        <Menu.Item key="rename" className="chooseFile" onClick={() => {
          dispatch(showModal('ReNameModal'))
          dispatch(setClickTreeOrlist('list'))
          dispatch(setIsNotClickFile(false))
        }}>重命名</Menu.Item>,
        <Menu.Item key="move" className="chooseFile" onClick={() => {
          dispatch(showModal('MoveModal'))
          dispatch(setIsNotClickFile(false))
        }}>移动</Menu.Item>,
        <Menu.Item key="copyPath" onClick={() => {
          copy(path)
          dispatch(setIsNotClickFile(true)) // 选择复制选项，对应的文件夹取消选中状态
          message.success('复制成功')
        }}>复制文件地址</Menu.Item>
      ]
      if (type !== 'directory' && !isDir) {
        MenuItem.unshift(<Menu.Item key="download" className="chooseFile" onClick={() => {
          tryDownloadFile(path)
          dispatch(setIsNotClickFile(false))
        }}>下载</Menu.Item>)
      } else {
        MenuItem.push(<Menu.Item key="newFolder" className="chooseFile" onClick={() => {
          dispatch(showModal('ModalNewFolder'))
          dispatch(setIsNotClickFile(false))
        }}>新建文件夹</Menu.Item>)
      }
    }
    return MenuItem

  }

  return (
    <Dropdown
      overlay={
        <Menu >
          {renderMenuItems()}
        </Menu>
      }
      trigger={['contextMenu']}
      onVisibleChange={(visible) => {
        if (!visible) {
          // setContextMenuType(null)
        }
      }}
    >
      <div
        className={classnames(
          styles.singleFile,
          styles['type-' + type],
          selected && styles.selected,
        )}
        onDoubleClick={handleDoubleClick(file)}
        onClick={(e) => {
          dispatch(clickFile(path))
          dispatch(setTreeTag(path))
          dispatch(setIsNotClickFile(false))
          e.stopPropagation()
        }}
        onContextMenu={(e) => {
          dispatch(clickFile(path))
          dispatch(setTreeTag(path))
          dispatch(setIsNotClickFile(false))
          e.isDefaultPrevented()
        }}
      >
        <div className={styles.fileIcon}>{getFileIcon(file)}</div>
        <div className={styles.fileName}>{connectionName}</div>
      </div>
    </Dropdown>
  )
}
