import React, { useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router'
import { Spin, List , Radio,  DatePicker, Select } from 'antd'
import { useRequest, useSelector } from 'src/hook'
import { getSql } from 'src/api/getNewData'
import chartStyles from './chart.module.scss'
import classnames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import { SqlTypes } from "src/types/types";

export const SlowSqlChart = () => {
  const history = useHistory();
  const [data, setData] = useState<any>();
  const [dataSource, setDataSource] = useState<any>();
  const [timeRange, setTimeRange] = useState<1 | 7 | 30 | any>(7)
  const [rangePickerTimes, setRangePickerTimes] = useState<string[] | null>(
    [dayjs().subtract(6 , 'day').format('YYYY-MM-DD'),
    dayjs().endOf('d').format('YYYY-MM-DD')]
  )
  const [operateType,setOperateType] = useState<string[]>()
  const SqlTypeOptions = SqlTypes.map((type) => ({ label: type, value: type }));


  // const {
  //   userInfo: { userName },
  // } = useSelector((state) => state.login);
  // const { userAvatar } = useSelector((state) => state.sysUserAvatar)
  const { loading, run } = useRequest(getSql, { manual: true });


  const setListDataFormat = (data: any) => {
    let arr: any = [];
    data.map((item: any) => {
      arr.push({
        sqlId: item.id,
        executor: item.executor,  // 用户名
        executorName: item.executorName,  // 账号
        sqlType: item.sqlType,
        executeSql: item.executeSql,
        executeCostSecond: item.executeCostSecond,
      })
    })
    setDataSource(arr);
  }


  const rangeValue = useMemo(() => {
    if (rangePickerTimes === null) {
      return null
    } else {
      const range = rangePickerTimes.map((timestamp) => dayjs(timestamp)) as [
        Dayjs,
        Dayjs,
      ]
      return range
    }
  }, [rangePickerTimes])

  

  useEffect(() => {
    let params 
    params = {
      sqlTypes:operateType
    }
    if (rangePickerTimes) {
      const startTimes = Number(dayjs(rangePickerTimes[0]).startOf('d').format('x'));
      const endTimes = Number(dayjs(rangePickerTimes[1]).endOf('d').format('x'));
     params = {
        ...params,
        executeBeginMs: startTimes,
        executeEndMs: endTimes,
      }
    }

    run(params).then(res => {
      setData(res);
    })
  }, [operateType,rangePickerTimes]);

  useEffect(() => {
    if (data) {
      setListDataFormat(data);
    }
  }, [data]);

  const toLink = (link: string, sqlId: any) => {
    let state:any;
    history.push({
      pathname: link,
      state: {
        id: sqlId
      },
    })
  }

  return (
    <Spin spinning={loading}>
      <div>
       <div className={chartStyles.toolbar}>
          <Radio.Group
            buttonStyle="solid"
            size="small"
            value={timeRange}
            onChange={(e) => {
              setTimeRange(e.target.value);
              if (e.target.value === 1) {
                setRangePickerTimes([dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')])
              } else if (e.target.value === 7) {
                setRangePickerTimes([dayjs().subtract(6, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')])
              } else {
                setRangePickerTimes([dayjs().subtract(29, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')])
              }
            }}
          >
            <Radio.Button value={1}>今日</Radio.Button>
            <Radio.Button value={7}>7 天</Radio.Button>
            <Radio.Button value={30}>30 天</Radio.Button>
          </Radio.Group>
         <div>
            <DatePicker.RangePicker
              style={{ marginLeft: 10 }}
              //@ts-ignore
              value={rangeValue}
              onChange={(dates, dateStrings) => {
                if (!dateStrings[0]) {
                  setRangePickerTimes(null)
                  setTimeRange(7)
                } else {
                  const diffDays = dayjs(dateStrings[1]).diff(
                    dateStrings[0],
                    'day',
                  )
                  //当日
                  if (diffDays === 0 && dayjs().isSame(dayjs(dateStrings[1]), 'day')) {
                    setTimeRange(1)
                  } else {
                    setTimeRange(diffDays)
                  }
                  setRangePickerTimes(dateStrings)
                }
              }}
              getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
            
            />
            <Select
              showSearch
              mode="multiple"
              showArrow
              maxTagCount={1}
              placeholder='操作类型'
              options={SqlTypeOptions}
              style={{ marginLeft: 10, width: 200 }}
              onChange={(values: string[]) => setOperateType(values)}
              getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
            />
          </div> 
        </div>
        <List
          itemLayout="horizontal"
          dataSource={dataSource}
          renderItem={(item: any, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <div className={chartStyles.listPre}>
                    <span
                      className={classnames(chartStyles.fs28, chartStyles.listImgWrap, chartStyles.listPreMarginRight)}
                      style={
                        {
                          backgroundColor:
                            index === 0 ?
                              "#D96B67"
                              : index === 1 ?
                                "#E2C655"
                                : index === 2 ?
                                  "#84DCE6"
                                  : "#E2E2E2",
                          color:
                            index < 3 ?
                              "#FFFFFF"
                              : "#3D3D3D"
                        }
                      }
                    >{++index}</span>
                    {
                      // userAvatar ? (
                      //   <img
                      //     alt=''
                      //     src={userAvatar}
                      //     className={chartStyles.fs28}
                      //   />
                      // ) : (<span className={classnames(chartStyles.fs28, chartStyles.userImgWrap)}>{userName?.substring(0, 1)}</span>)
                    }
                    <span className={chartStyles.listPreName}>{item.executorName+"("+item.executor+")"}</span>
                  </div>
                }
                title={
                  <div style={{ cursor: "pointer"}} onClick={()=>toLink("/statement_detail", item.sqlId)}>
                    
                    <p className={chartStyles.content1}>
                      <span className={chartStyles.sqlType}>&nbsp;&nbsp;{item.sqlType}&nbsp;&nbsp;</span>
                      {item.executeSql}
                    </p>
                    <span className={chartStyles.sqlCost}>{(item.executeCostSecond) + "s"}</span>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </div>
    </Spin>
  )
}
