/**
 * 权限详情 modal
 */
import { Col, Empty, Modal, Row, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'

interface IProp {
  visible: boolean,
	setVisible:(v: boolean)=>void
  permissionDetaiModalData: any
}

const PermissionDetailModal = (props: IProp) => {
  const { visible, setVisible, permissionDetaiModalData } = props

  const handleCancel = () => {
    setVisible(false)
  }

  function renderTabPane(tabData: any) {
    const { tabName, tabKey, tabContent } = tabData
    return (
      // <Tabs.TabPane tab={tabName} key={tabKey}>
          <div className={styles.rowsDiv} key={tabKey}>
          {
            tabContent.map((item: any, index: number) => (
              index % 3 === 0 && (
                <Row className={styles.colStyle} key={index}>
                  {tabContent.slice(index, index + 3).map((colItem: any, colIndex: number) => (
                    <Col span={8} className='col' key={colIndex}>
                      <span>{colItem}</span>
                    </Col>
                  ))}
                </Row>
              )
            ))
          }
          </div>
      // </Tabs.TabPane>
    );
  }

  return (
    <Modal
      title="权限详情"
      key="permissionDetaiModal"
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      className={styles.modalDiv}
    >
      {
        !!permissionDetaiModalData && permissionDetaiModalData.length === 0 ?
        <Empty className={styles.empty} description="暂无数据"></Empty>
        : 
        // <Tabs defaultActiveKey="1">
        // {
          permissionDetaiModalData && 
          permissionDetaiModalData.map((item: any) => {
            return renderTabPane(item)
          })
        // }
      // </Tabs>
      }
    </Modal>
  )
}

export default PermissionDetailModal