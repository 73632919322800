import React, { useEffect, useMemo, useState } from 'react'
import { ResizableBox, ResizableProps } from 'react-resizable'
import {
  Spin,
  Button,
  Input,
  Space,
  Tooltip,
  Empty
} from 'antd'
import { FileAddOutlined, SearchOutlined } from '@ant-design/icons'
import classnames from 'classnames'
import { useModal, useRequest, useDispatch, useSelector } from 'src/hook'
import { queryGroupNodes, queryTreeNode } from 'src/api'
import { SimpleBreadcrumbs, Iconfont } from "src/components"
import CustomPermissionList from './CustomPermissionList'
import TreeComponent from './TreeComponent'
import ConnectionSetting from './ConnectionSetting'
import OperationPermissionsModal from './OperationPermissionsModal'
import { isSupportCreate } from 'src/api'
import { setSelectedTreeNode } from './authorizationManagePageSlice';
import ObjectLevelAuthorization from './objectLevelAuthorization'
import SecuritySettingWrapper  from './SecuritySettingDrawer'
import styles from './index.module.scss'
import PermissionTooltip from 'src/components/PermissionTooltip/PermissionTooltip'
import _ from 'lodash'

const ResizableBoxProps: ResizableProps = {
  axis: 'x',
  width: 320,
  height: 0,
  minConstraints: [260, 0],
  maxConstraints: [620, 0],
}

const AuthorizationManagePage = () => {

  const dispatch = useDispatch()
  const { openModal} = useModal('SecuritySetting')
  const [selectId, setSelectId] = useState<string>("");

  const [rightWrapWidth, setRightWrapWidth] = useState<string>("");
  const [dataSourceType, setDataSourceType] = useState<string>("");
  const [selectNodeType, setSelectNodeType] = useState<string>("");
  const [nodePathWithType, setNodePathWithType] = useState<string>("");
  const [supportCreate, setSupportCreate] = useState(true);
  const [permissionModalVisible, setPermissionModalVisible] = useState(false);
  
  const [selectTreeItem, setSelectTreeItem] = useState({})
  const { permissionList } = useSelector((state) => state?.login)
  const [permissionlist, setPermissionlist] = useState<{isOnlyRead: boolean, roleNameList: string[]}>()

  // 是否支持创建
  const { run: getSupportCreate } = useRequest(isSupportCreate, {
    manual: true,
    onSuccess: (res: any) => {
      setSupportCreate(res);
    },
  });

  useEffect(() => {
    if (dataSourceType) {
      getSupportCreate(dataSourceType);
    }
  }, [dataSourceType, getSupportCreate]);

  useEffect(() => {
    handleLeftWrapResize();

    const pathName = (window.location.pathname.split("/")[1].toUpperCase() as keyof typeof permissionList)
    setPermissionlist(permissionList?.DATABASE_MANAGEMENT?.[pathName])
  }, []);

  const handleAddAuthorize = () => {
    setPermissionModalVisible(true);
  };

  /**
   * 树选择
   */
  const handleTreeSelect = (_: any[], info: any) => {
    if (!info.selected) {
      return;
    }
    const selectId = info.node.id;
    const nodeType = info?.selectedNodes?.[0]?.nodeType;
    const nodePathWithType = info?.selectedNodes?.[0]?.nodePathWithType;
    // 数据源类型设置
    if (nodeType === "datasource") {
      setDataSourceType(selectId);
    } else {
      const dataSourceType =
        info?.selectedNodes?.[0]?.connection?.connectionType;
      setDataSourceType(dataSourceType);
    }
    if (["datasource", "group", "connection"].includes(nodeType)) {
      setSelectId(selectId);
    } else {
      // schema级及以下用connectionId
      const connectionId = info?.selectedNodes?.[0]?.sdt?.connectionId;
      setSelectId(connectionId);
    }
    setSelectNodeType(nodeType);
    setNodePathWithType(nodePathWithType);
    setSelectTreeItem(info?.selectedNodes?.[0]);
    dispatch(setSelectedTreeNode(info?.selectedNodes?.[0]))
  };

  /**
   * 初始化默认树选项
   */
  const handleTreeDefaultSelect = (item: any) => {
    const { id, nodeType, nodePathWithType, nodeName } = item || {};

    dispatch(setSelectedTreeNode(item || {}))
    setSelectId(id);
    setNodePathWithType(nodePathWithType);
    setDataSourceType(nodeName);
    setSelectNodeType(nodeType);
    setSelectTreeItem(item);
  };

  const handleLeftWrapResize = () => {
    // @ts-ignore
    const lWidth = document.getElementsByClassName("react-resizable")[0]?.style.width ||
      "320px";
    const width = `calc(100vw - 30px - ${lWidth} )`;
    setRightWrapWidth(width);
  };

  const renderResizeHandle = (
    <div className={styles.resizeHandle}>
      <Iconfont type="icon-handle-8"></Iconfont>
    </div>
  );

  const renderRightContent = useMemo(() => {
    const upperSelectNodeType = selectNodeType?.toUpperCase();
    if (upperSelectNodeType === "DATASOURCE") {
      // 数据源级别
      return (
        <>
          <div className={styles.title}>
            <span className={styles.boldFont}>数据源配置</span>
            <Space>
                {/* <Button
                  className={classnames(styles.indb, styles.ml10)}
                  onClick={() => openModal()}
                  disabled={!supportCreate}
                >
                  安全设置
                </Button> */}
                {
                  permissionlist && 
                  <PermissionTooltip
                    title="手动授权"
                    permissionlist={permissionlist}
                  >
                    <Button
                      type="primary"
                      className={classnames(styles.indb, styles.ml10)}
                      onClick={handleAddAuthorize}
                      disabled={!supportCreate || permissionlist?.isOnlyRead}
                    >
                      <FileAddOutlined />
                      新增权限
                    </Button>
                  </PermissionTooltip>
                }
            </Space>  
          </div>
          {/* 自定义权限等级列表 */}
          <CustomPermissionList dataSourceType={dataSourceType} permissionlist={permissionlist}/>
        </>
      );
    } else if (
      upperSelectNodeType !== "GROUP" &&
      upperSelectNodeType?.endsWith("GROUP")
    ) {
      // 表、视图、函数等对象授权
      return (
        <ObjectLevelAuthorization
          selectTreeItem={selectTreeItem}
          selectNodeType={selectNodeType}
        />
      );
    } else {
      // 自定义分组和用户授权
      return (
        <ConnectionSetting
          nodePathWithType={nodePathWithType}
          selectNodeType={selectNodeType}
          selectId={selectId}
          dataSourceType={dataSourceType}
          // permissionlist={permissionlist}
        />
      );
    }
  }, [
    selectTreeItem,
    dataSourceType,
    nodePathWithType,
    selectId,
    selectNodeType,
    supportCreate,
  ]);

  const breadcrumbData = [
    { title: "数据库管理" },
    { title: "手动授权" },
    {
      title: "客体授权",
    },
  ];
  return (
      <div className={styles.groupAuthorizationWrap}>
        <div style={{ padding: "0 10px" }}>
          <SimpleBreadcrumbs items={breadcrumbData} />
        </div>
        <div className={styles.content}>
          <ResizableBox
            className={styles.resizableBox}
            handle={renderResizeHandle}
            onResize={handleLeftWrapResize}
            {...ResizableBoxProps}
          >
           
              <TreeComponent
                selectedNode={selectTreeItem}
                handleTreeSelect={handleTreeSelect}
                handleTreeDefaultSelect={handleTreeDefaultSelect}
              />
          </ResizableBox>

          <div className={styles.rightWrap} style={{ width: rightWrapWidth }}>
            <div className={styles.contentStyle}>
              {_.isEmpty(selectTreeItem) ? <Empty /> : renderRightContent}
            </div>
          </div>
        </div>

        {/* 操作权限 - 新增 */}
        {permissionModalVisible && (
          <OperationPermissionsModal
            visible
            optionType={"add"}
            dataSourceType={dataSourceType}
            setModalVisible={setPermissionModalVisible}
          />
        )}
        {/* 安全设置 */}
        <SecuritySettingWrapper />
      </div>
  );
}
export default AuthorizationManagePage
