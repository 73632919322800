import React, { FC, useState } from 'react'
import { Button, Card, Form, Input, message } from 'antd'
import classnames from 'classnames'
import { useForm } from 'antd/lib/form/Form'
import { useRequest } from 'src/hook'
import { dealWithAccessPermission } from 'src/api'
import { useHistory } from 'react-router-dom'
import styles from '../index.module.scss'

const FormLayout = {
  labelCol: { span: 0 },
  wrapperCol: { offset: 0, span: 8 },
}

interface ListProps {
  uuid: string
}

export const WeightingPage: FC<ListProps> = (props) => {
  const { uuid} = props
  const [form] = useForm()
  const history = useHistory()
  const [approveType, setApproveType] = useState<
    'rejected' | 'fullilled' | 'pending'
  >('pending')

1
  // 落权
	const { run: weightingRun } = useRequest(dealWithAccessPermission, {
		manual: true,
		onSuccess(data) {
			if (approveType === 'fullilled') {
        message.success('通过成功')
      } else {
        message.success('拒绝成功')
      }
      history.push('/mine_approve')
		}
	})

  // 同意
  const agreeBtn = () => {
    setApproveType('fullilled')
    let params = {
      flowUUID:uuid, 
      powerStatus: 'already',
      remarks: form.getFieldValue('approvedComment') 
    }
    //@ts-ignore
    weightingRun(params).finally(() => {
      setApproveType('pending')
    })

  }

  const isEmpty = () => {
    setApproveType('rejected')
    let params = {
      flowUUID:uuid, 
      powerStatus: 'rejected',
      remarks: form.getFieldValue('approvedComment') 
    }
    //@ts-ignore
    weightingRun(params).finally(() => {
      setApproveType('pending')
    })
  }
  return (
    <Card
      title="授权信息"
      className={classnames(styles.borderShow, styles.mt27, styles.mb27, styles.detailCard)}
      actions={[
        <div>
          <Button type='primary' onClick={() => agreeBtn()}>同意授权</Button>
          <Button onClick={() => isEmpty()}>拒绝授权</Button>
        </div>
      ]}
    >
      <Form form={form} {...FormLayout}>
        <Form.Item
          label="备注"
          name="approvedComment"
          // rules={[{ required: true }, {min: 1, max: 100}]}
        >
          <Input.TextArea allowClear maxLength={100}/>
        </Form.Item>
      </Form>
    </Card>
  )
}
