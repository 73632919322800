import React, { useEffect, useState, useMemo } from "react";
import * as _ from 'lodash';
import { Modal, Button, Tooltip, Switch, Space, Input, message } from "antd";
import {
  setDesensScanTaskStatus,
  deleteDesensScanTask,
  IScanTaskItemRes,
  triggerDesensScanTask,
  permissionSupport,
} from "src/api";
import { ExclamationCircleFilled, SearchOutlined, RedoOutlined } from "@ant-design/icons";
import { useRequest, useSelector } from "src/hook";
import { getDesensScanTaskAction } from "./utils";
import type { NodeTypeKey } from "../type.d";
import { Iconfont, SimpleTable } from "src/components";
import { DESENS_SCAN_TASK_STATUS } from "../../constant";
import DesensScanTaskModal from "./DesensScanTaskModal";
import styles from "./index.module.scss";
import classnames from "classnames";

const { confirm } = Modal;
//除table 层其他页面全部公用 除了接口不一样
const TopDesensScanTaskContent = ({
  type,
  onRefreshScanResult,
}: {
  type: NodeTypeKey;
  onRefreshScanResult: () => void;
}) => {
  const { selectedNode, selectedNodePermissionInfo } = useSelector((state) => state.dataProtection);
  const {modulePermissionObj, permissionSupportData } = selectedNodePermissionInfo["DATA_PROTECT"] || {};

  const [searchParams, setSearchParams] = useState<string>("");
  const [scanRuleVisible, setScanRuleVisible] = useState(false);
  const [editRecordItem, setEditRecordItem] = useState<
    IScanTaskItemRes | any
  >();

  const {
    data: list = [],
    loading,
    refresh,
    run: runGetScanTasks,
  } = useRequest<IScanTaskItemRes[]>(getDesensScanTaskAction(type),{manual: true}
  );
  //状态修改
  const { run: setStatus } = useRequest(setDesensScanTaskStatus, {
    manual: true,
    onSuccess: () => {
      message.success("修改成功");
      refresh();
      onRefreshScanResult();
    },
  });
  //删除
  const { run: runDeleteDesensScanTask } = useRequest(deleteDesensScanTask, {
    manual: true,
    onSuccess: () => {
      message.success("删除成功");
      refresh();
    },
  });
  //手动修改
  const { run: runTriggerDesensScanTask } = useRequest(triggerDesensScanTask, {
    manual: true,
    onSuccess: () => {
      message.success("操作成功");
      refresh();
      onRefreshScanResult();
    },
  });

  useEffect(() => {
 
    if (selectedNode && type) {
     let defaultParams: any = {};
      if (type === "datasource") {
        //@ts-ignore
        defaultParams.datasourceType = selectedNode.props.id;
      } else if (type === "group") {
        defaultParams.groupId = selectedNode.props.id;
      } else {
        //@ts-ignore
        defaultParams.nodePath = selectedNode.props.nodePathWithType;
      }
      runGetScanTasks(defaultParams);
    }
  }, [selectedNode, type]);


  async function handleSetStatus(record: IScanTaskItemRes) {
    await setStatus({
      taskId: record.id,
      jobStatus: !record.jobStatus,
    });
  }

  const showDeleteConfirm = (id: number) => {
    confirm({
      title: `确定删除?`,
      icon: <ExclamationCircleFilled />,
      onOk() {
        runDeleteDesensScanTask({ jobIds: [id] });
      },
    });
  };

  const columns: any = [
    {
      dataIndex: "jobName",
      title: "任务名称",
      ellipsis: true,
      width: 100,
      render(value: string, record: any) {
        return (
          <span>
            <Iconfont
              type={`icon-connection-${record?.datasourceType}`}
              style={{ marginRight: 4 }}
            />
            {value}
          </span>
        );
      },
    },
    {
      dataIndex: "path",
      title: "任务路径",
      width: 200,
      render(value: string) {
        return (
          <Tooltip title={value}>
            {value?.length <= 15 ? value : value?.slice(0, 15) + "..."}
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "desensRule",
      title: "扫描规则",
      width: 200,
    },
    {
      dataIndex: "cronExpression",
      title: "任务周期",
      width: 200,
    },
    {
      dataIndex: "jobRunTime",
      title: "耗时",
      width: 100,
      render: (v: number) => (v ? `${v}ms` : "-"),
    },
    {
      dataIndex: "jobStatus",
      title: "任务状态",
      width: 100,
      render: (v: number) => DESENS_SCAN_TASK_STATUS[v],
    },
    {
      dataIndex: "lastExecuteTime",
      title: "上次执行时间",
      width: 200,
      render(value: string) {
        return (
          <Tooltip title={value}>
            {value?.length <= 15 ? value : value?.slice(0, 15) + "..."}
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "jobStatus",
      title: "状态",
      width: 100,
      render: (value: string, record: any) => (
        <Tooltip title={
          !permissionSupportData?
          `您当前的角色是[${modulePermissionObj?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
          : null
          } 
        >
          <Switch
            checked={value ? true : false}
            checkedChildren="启用"
            unCheckedChildren="关闭"
            onChange={(checked: boolean) => {
              handleSetStatus(record);
            }}
            disabled={!permissionSupportData}
          />
        </Tooltip>
      ),
    },
    {
      dataIndex: "options",
      title: "操作",
      fixed: "right",
      width: 200,
      render(value: string, record: any) {
        return (
          <Space size="small" align="start">
            <Tooltip title={
              !permissionSupportData?
              `您当前的角色是[${modulePermissionObj?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
              : null
              } 
              className={styles.displayFlex}
            >
              <Button
                type="link"
                className={classnames(styles.linkBtn)}
                onClick={() => runTriggerDesensScanTask({ taskId: record?.id })}
                disabled={!permissionSupportData}
              >
                手动执行
              </Button>
              <Button
                type="link"
                className={classnames(styles.linkBtn)}
                onClick={() => {
                  setEditRecordItem(record);
                  setScanRuleVisible(true);
                }}
                disabled={!permissionSupportData}
              >
                编辑
              </Button>
              <Button
                type="link"
                className={classnames(styles.deleteBtn)}
                onClick={() => showDeleteConfirm(record?.id)}
                disabled={!permissionSupportData}
              >
                删除
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const filteredDataSource = useMemo(() => {
    if (!searchParams) return list;
    return list?.filter((item: any) => item?.jobName.toLowerCase()?.includes(searchParams.toLocaleLowerCase()));
  }, [list, searchParams]);

  return (
    <div className={styles.desensScanTaskPart}>
      <div className={styles.taskHeader}>
        <div className={styles.title}>脱敏扫描任务管理</div>
        <Space>
          <Input
            allowClear
            prefix={<SearchOutlined />}
            placeholder="搜索扫描任务"
            className={styles.search}
            onChange={(e: any) => {
              setSearchParams(e.target.value);
            }}
          />
          {["connection", "database", "schema", "tableGroup", "table"].includes(type) && (
            <Tooltip title={
              !permissionSupportData?
              `您当前的角色是[${modulePermissionObj?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
              : null
              } 
            >
              <Button
                type="primary"
                onClick={() => setScanRuleVisible(true)}
                disabled={!permissionSupportData}
              >
                添加
              </Button>
            </Tooltip>
          )}
            <Button
            icon={<RedoOutlined  />}
            onClick={() => refresh()}
          />
        </Space>
      </div>

      <SimpleTable
        rowKey="id"
        total={filteredDataSource?.length}
        columns={columns}
        showPagination={false}
        loading={loading}
        dataSource={filteredDataSource}
        pagination={false}
        scroll={{ x:'max-content', y: `calc(100vh / 2 - 120px)` }}
      />
      {
        scanRuleVisible && 
          <DesensScanTaskModal
            visible={scanRuleVisible}
            selectedNode={selectedNode}
            editRecordId={editRecordItem?.id}
            refresh={() => {
              refresh();
              onRefreshScanResult();
            }}
            onCancel={() => {
              setScanRuleVisible(false);
              setEditRecordItem({});
            }}
          />
    }
    </div>
  );
};

export default TopDesensScanTaskContent;
