import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ITextImportEntity,
  NodeEntity,
  IImportFileRequest,
  ITargetTableMessage
} from 'src/api'

type FileType = 'txt' | 'csv' | 'xls' | 'xlsx'

interface ITableData {
  columnName: string | null
  columnTypeName: string | null
  index: number | string | null
  sourceColumnName: string | null
}

type ITableMap = ITableData[]

const initialState: Partial<ITextImportEntity> = {
  filePath: '',
  fileType: 'txt',
  targetTable: '',
  fileCode: '',
  // 所有目标表数据
  targetTableList: [],
  // 目标表需要的请求信息
  targetTableMessage: null,
  // 获取源字段需要的请求信息
  sourceTableFieldsRequest: null,
  // 映射表数据
  tableFieldMap: [
    {
      columnName: null,
      columnTypeName: null,
      index: null,
      sourceColumnName: null,
    },
  ],
  // 被授予用户没操作权限的层级右键标识
  noAccessRightClick: false
}

export const textImportSlice = createSlice({
  name: 'textImport',
  initialState,
  reducers: {
    setFilePath: (state, action: PayloadAction<string>) => {
      state.filePath = action.payload
    },
    setFileType: (state, action: PayloadAction<FileType>) => {
      state.fileType = action.payload
    },
    setTargetTable: (state, action: PayloadAction<string>) => {
      state.targetTable = action.payload
    },
    setFileCode: (state, action: PayloadAction<string>) => {
      state.fileCode = action.payload
    },
    setTargetTableList: (state, action: PayloadAction<NodeEntity[]>) => {
      state.targetTableList = action.payload
    },
    setTargetTableMessage: (state, action: PayloadAction<ITargetTableMessage | null>) => {
      state.targetTableMessage = action.payload
    },
    setSourceTableFieldRequest: (
      state,
      action: PayloadAction<IImportFileRequest | null>,
    ) => {
      state.sourceTableFieldsRequest = action.payload
    },
    setTableFieldMap: (state, action: PayloadAction<ITableMap>) => {
      state.tableFieldMap = action.payload
    },
    setNoAccessRightClick: (state, action: PayloadAction<boolean>) => {
      state.noAccessRightClick = action.payload
    }
  },
})

export const textImportReducer = textImportSlice.reducer
export const {
  setFilePath,
  setFileType,
  setTargetTable,
  setFileCode,
  setTargetTableList,
  setTargetTableMessage,
  setSourceTableFieldRequest,
  setTableFieldMap,
  setNoAccessRightClick
} = textImportSlice.actions
