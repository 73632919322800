import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { AppThunk } from 'src/store'
import { batch } from 'react-redux'
import * as _ from 'lodash'
import { getTargetNode, specialFormatTree } from './utils';
import {
  getApplyCartNode,
  getApplyCartConnection,
} from 'src/api'

interface AppliedResourceListState {
  permissionList: any[];
  authorizeUserList: any[];
  expandedKeys: any[];
  //权限集tree
  loadedTreeNodeMap: Record<string, any[]>,
  loadedKeys: string[];
  selectedTreeNode: any;
  newTreeData: any[];
  treeNodeChildrenFetching: any;
  permissionCollections: any;
  connectionObjectPermissions: any
}

const initialState: AppliedResourceListState = {
  permissionList: [],
  authorizeUserList: [],
  expandedKeys: [],
  loadedTreeNodeMap: {},
  loadedKeys: [],
  newTreeData: [],
  selectedTreeNode: {},
  treeNodeChildrenFetching: {},
  permissionCollections: {},
  connectionObjectPermissions: {}
}


export const getTreeNodeChildren = createAsyncThunk<
  any[],
  any,
  { state: any }
>('appliedResourceList/getTreeNodeChildren', async (params, { dispatch, getState }) => {

  const {
    sdt: { treeNodeChildrenMap, treeNodeChildrenFetching },
    sdtPermission: { status = false }
  } = getState()

  const list = await dispatch(fetchTreeNodeChildren({
    ...params,
  })).unwrap()

  return list
})

// 更新树节点 children
export const fetchTreeNodeChildren = createAsyncThunk<
  any,
  any,
  { state: any }
>(
  'appliedResourceList/fetchTreeNodeChildren',
  async (params) => {
    let children = [];
    if (params?.nodeType) {
      children = await getApplyCartNode(params);
    }
    return children
  },
  {
    condition({ nodePath }, { getState }) {
      const {
        sdt: { treeNodeChildrenFetching },
      } = getState()
      const fetching = treeNodeChildrenFetching[nodePath]
      // 应用当前已经在获取数据，则不再重复请求
      return !fetching
    },
  },
)

export const fetchConnections = (params: {flowMainUUID: string}): AppThunk => async (dispatch, getState) => {
  const {
    sdt: { expandedKeys },
    sdtPermission: { status }
  } = getState()

  getApplyCartConnection(params)
    .then((list) => {

      const filteredConnections = list?.nodeList?.filter(((node: any) => node?.nodeType === 'connection'));
      const result = filteredConnections?.map((curr: any) => ({
        ...curr,
        key: curr?.nodePathWithType,
        title: curr?.nodeName,
        dataSourceType: curr?.connection?.connectionType,
        isLeaf: !curr?.hasChild,
      }))

      dispatch(setNewTreeData(result))
      dispatch(setSelectedNode(result?.[0]))
      batch(() => {
        dispatch(setLoadedKeys([]))
        // dispatch(setExpandedKeys([...expandedKeys]))
      })
    })
    .finally(() => {

    })
}
export const refreshOnRoot = (params: {flowMainUUID: string}): AppThunk => (dispatch) => {

  dispatch(fetchConnections(params))
}

export const appliedResourceListSlice = createSlice({
  name: "appliedResourceList",
  initialState,
  reducers: {
    setPermissionList: (state, action: PayloadAction<any[]>) => {
      state.permissionList = action.payload;
    },
    setAuthorizeUserList: (state, action: PayloadAction<any[]>) => {
      state.authorizeUserList = action.payload;
    },
    setSelectedNode: (state, action: PayloadAction<any>) => {
      state.selectedTreeNode = action.payload
    },
    setExpandedKeys: (state, action: PayloadAction<any[]>) => {
      state.expandedKeys = action.payload;
    },
    setLoadedTreeNodeMap: (state, action: PayloadAction<any>) => {
      state.loadedTreeNodeMap = action.payload;
    },
    setLoadedKeys: (state, action: PayloadAction<any[]>) => {
      state.loadedKeys = action.payload;
    },
    setNewTreeData: (state, action: PayloadAction<any[]>) => {
      state.newTreeData = action.payload;
    },
    setTreeDataNodeChildren: (
      state,
      action: PayloadAction<{
        nodePath: string
        children: any[]
      }>,
    ) => {
      const { nodePath, children } = action.payload
      const parent = getTargetNode(nodePath, state.newTreeData)
      if (parent) {
        parent.children = children
      }
    },
    //存储当前选中权限内容
    updatePermissionCollections: (state, action: PayloadAction<any>) => {
      const newValue = action.payload
      state.permissionCollections = { ...state.permissionCollections, ...newValue };
    },
    resetPermissionCollections: (state) => {
      state.permissionCollections = {}
    },
    updateConnectionObjectPermissions: (state, action: PayloadAction<any>) => {
      const settings = action.payload
      state.connectionObjectPermissions = settings;
    },
    resetConnectionObjectPermissions: (state) => {
      state.connectionObjectPermissions = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTreeNodeChildren.pending, (state, action) => {
      const { key } = action.meta.arg
      const { treeNodeChildrenFetching } = state
      treeNodeChildrenFetching[key] = true
    })
    builder.addCase(fetchTreeNodeChildren.fulfilled, (state, action) => {

      const list = action.payload
      const { key } = action.meta.arg

      const target: any = getTargetNode(key, state.newTreeData)

      if (target) {

        if (list?.length) target.children = specialFormatTree(list, false, target?.roleId)
        else target.children = []
      }
      state.treeNodeChildrenFetching[key] = false
    })
    builder.addCase(fetchTreeNodeChildren.rejected, (state, action) => {
      const { nodePath } = action.meta.arg

      state.loadedKeys = state.loadedKeys.filter((key) => key !== nodePath)
      state.treeNodeChildrenFetching[nodePath] = false
    })
  }
});

export const appliedResourceListReducer = appliedResourceListSlice.reducer

export const { setPermissionList, setAuthorizeUserList,
  setLoadedTreeNodeMap,
  setLoadedKeys,
  setNewTreeData,
  setSelectedNode,
  setExpandedKeys,
  updatePermissionCollections,
  resetPermissionCollections,
  updateConnectionObjectPermissions,
  resetConnectionObjectPermissions
} = appliedResourceListSlice.actions;



