// 访问申请-我的审批-审批单
import React, { useEffect } from 'react';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { Descriptions, Spin } from 'antd';
import { MENU_FLOW } from 'src/constants'
import { SimpleBreadcrumbs } from 'src/components';
import { useLocation, useParams } from 'react-router-dom';
import { ResourceBidPage } from './resource-bid';
import { OrderFlowPage } from '../common/order-flow';
import { ApplicationsInfoPag } from '../common/application-info';
import { AuditInfoPage } from './audit-info';
import { useRequest } from 'src/hook';
import {
  getFlowRemarkInfo,
  getFlowStatus,
  getInventoryDataForMessage,
  getInventoryData,
  getNewFlowProcess,
  getPowerRemarkInfo,
  getWithdrawRemarkInfo,
} from 'src/api';
import { ApprovalRemarksPag } from '../common/approval-remarks/inedx';
import styles from './index.module.scss';
import { WeightRemarksPag } from '../common/weighting-info';
import { WeightingPage } from './weighting-sub';
import AppliedResourceList from '../AppliedResourceList'
import { NO_NEED_TO_LAND_TYPES } from '../constants'

export const MyApprovalRequestDetailPage = () => {
  const location = useLocation().state as any;
  const { id } = useParams<{ id?: string; editId?: string }>();

  const {
    data: thinData = {},
    run: thinRun,
    loading: thinLoading,
  } = useRequest(
    (params) => {
      if (location?.originType === 'message') {
        delete params?.originType;
        return getInventoryDataForMessage(params);
      } else {
        return getInventoryData(params);
      }
    },
    {
      manual: true,
    }
  );
  //详情
  const { data: list = [], run: getFlowSteps } = useRequest(getNewFlowProcess, {
    manual: true,
  });

  //工单状态
  const { data: flowStatus = {}, run: getFlowStatusRun } = useRequest(
    getFlowStatus,
    {
      manual: true,
    }
  );

  //落权
  const { data: powerData = [], run: getPowerRun } = useRequest(
    getPowerRemarkInfo,
    {
      manual: true,
    }
  );

  //备注
  const { data: remarkData = [], run: getRemarkInfoRun } = useRequest(
    getFlowRemarkInfo,
    {
      manual: true,
    }
  );
  //撤回备注
  const { data: retractRemarks = [], run: runGetRetractRemarks } = useRequest(
    getWithdrawRemarkInfo,
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (location?.mainUUID) {
      thinRun({
        flowMainUUID:
          location?.originType === 'message'
            ? location?.flowUUID
            : location?.mainUUID,
        connectionIds: location?.connectionIds,
        canOperation:
          location?.canOperation === false || location?.canOperation === true
            ? location?.canOperation
            : null,
      });
      getFlowSteps(location?.mainUUID);
      getFlowStatusRun(location?.mainUUID);
      getRemarkInfoRun(location?.mainUUID);
      getPowerRun(location?.mainUUID);
    }

  }, [location]);

  useEffect(() => {

    if (flowStatus?.approvedResult === 'withdraw' && location?.mainUUID) {

      runGetRetractRemarks(location?.mainUUID)
    }

  }, [flowStatus?.approvedResult])

  const breadcrumbData = [
    { title: MENU_FLOW },
    {
      title: location?.flag === 'worksheetManage' ? '工单管理' : '我的审批',
      href:
        location?.flag === 'worksheetManage'
          ? '/flow_work_order_management'
          : '/mine_approve',
    },
    {
      separator: '/',
      title: '审批单',
    },
  ];

  return (
    <div className={styles.myApplyRequestDetailWrap}>
      <SimpleBreadcrumbs items={breadcrumbData} />
      <Spin spinning={thinLoading}>
        <div className={styles.content}>
          <Descriptions>
            <Descriptions.Item label="申请单号">
              <span className={styles.oddBgText}>{id}</span>
            </Descriptions.Item>
          </Descriptions>
          {!(
            NO_NEED_TO_LAND_TYPES.includes(location?.priGranType) ||
            NO_NEED_TO_LAND_TYPES.includes(thinData?.flowType)
          ) && (
              <ErrorBoundary>
                <AppliedResourceList flowMainUUID={id}/>
              </ErrorBoundary>
            )}
          <ErrorBoundary>

            <ApplicationsInfoPag
              {...thinData}
              priGranType={location?.priGranType || thinData?.flowType}
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <OrderFlowPage dataList={list} flowStatus={flowStatus} />
          </ErrorBoundary>
          <ErrorBoundary>
            {(location?.flag === 'worksheetManage' ||
              location?.applyStatus !== 'pending') && (
                <ApprovalRemarksPag remarkData={remarkData} />
              )}
          </ErrorBoundary>
          {!(
            NO_NEED_TO_LAND_TYPES.includes(location?.priGranType) ||
            NO_NEED_TO_LAND_TYPES.includes(thinData?.flowType)
          ) && (
              <ErrorBoundary>
                <WeightRemarksPag remarkData={powerData} />
              </ErrorBoundary>
            )}
          <ErrorBoundary>
            {/* 通知进入location?.originType === 'message' 判断是否从通知进来 */}
            {location?.originType === 'message' &&
              Boolean(location?.canApply) &&
              location?.applyType === 'APPROVAL' &&
              location?.type !== 'power' && (
                <AuditInfoPage
                  applyId={location?.applyId}
                  flowTaskId={location?.flowTaskId}
                />
              )}
            {/* 访问申请正常逻辑 */}
            {location?.flag === 'childPending'
              ? ''
              : location?.originType !== 'message' &&
              location?.applyStatus === 'pending' &&
              location?.flag !== 'worksheetManage' && (
                <AuditInfoPage
                  applyId={location?.applyId}
                  flowTaskId={location?.flowTaskId}
                />
              )}
          </ErrorBoundary>
          <ErrorBoundary>
            {((Boolean(location?.canOperation) && !location?.originType) ||
              (Boolean(location?.canOperation) &&
                location?.type === 'power' &&
                location?.originType === 'message')) &&
              id && <WeightingPage uuid={id} />}
          </ErrorBoundary>
          {flowStatus?.approvedResult === 'withdraw' && (
            <ErrorBoundary>
              <WeightRemarksPag remarkData={retractRemarks} withdrawalState />
            </ErrorBoundary>
          )}
        </div>
      </Spin>
    </div>
  );
};
