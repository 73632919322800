import {
  fetchGet,
  fetchPost,
  fetchPut,
  fetchWithErrorHandler,
} from './customFetch'
import {
  downloadResponse,
  fetchWithStatusHandler,
  readResponseAsJSONBig,
  requestInitJSON,
} from './customFetch/_util'
import { QueryBase, DataSourceType, NodeEntity, ResponseNodeEntity, QueryParams, QueryLogItem } from '../types'
import { TxModeType } from 'src/constants'
import { formatExecuteResult } from 'src/util/grid'
import { TableColumn } from './wizrdForm'
import { IIndex, IIndexOperation, IIndexColumnOrder } from '../types/indexs'
import { SegmentMsg } from 'src/pageTabs/queryPage/resultTabs/resultTabsSlice'
import { getIsBase64EncodeVal, strArrToBase64, strToBase64 } from 'src/util'

/** 解析响应结果，用户期望提示的类型*/
export enum CompletionEnum {
  //列类型
  COLUMN = 'COLUMN',
  //表类型
  TABLE = 'TABLE',
  //数据库类型
  DATABASE = 'DATABASE',
  //模式类型
  SCHEMA = 'SCHEMA',
  //请求所有类型 预留解析异常的情况
  ALL = 'ALL',
  // 数据库/schema+表的集合
  LIBRARIES_TABLES = 'LIBRARIES_TABLES',

  //表+列的集合
  TABLE_COLUMN = 'TABLE_COLUMN',
  // 关键key
  KEYWORD = 'Keyword',
}

export interface StatementExecuteParams extends QueryBase {
  statements: string[]
  /** 排序时偏移量 */
  offset?: number
  /** 查询数量 */
  rowCount?: number
  /** 列排序对象 */
  sortModels?: any[]
  tabKey?: string
  plSql?: boolean
  /** 是否自动提交 */
  autoCommit?: boolean
}

export type CellRenderType =
  | 'text'
  | 'text-json'
  | 'binary'
  | 'binary-image'
  | 'binary-png'
  | 'binary-jpg'
  | 'binary-gif'
  | 'base64-image'
  | 'base64-png'
  | 'base64-jpg'
  | 'base64-gif'
  | null
export type CellEditorFromBackend =
  // date relating
  'date' | 'time' | 'timestamp' | string
export interface ResultColumnInfo {
  columnName: string
  cellEditor: CellEditorFromBackend
  sortable: boolean
  filterable: boolean
  nullable: boolean
  comment: string
  renderType: CellRenderType
  editable: boolean
  defaultValue: any
  /* 结果集列脱敏权限 */
  desensitization: {
    permissionType: 'Desensitization'
    permissionPath: string
    status: boolean
  },
  downloadable?: boolean
}
export interface PlainRowData {
  [key: string]: any
}
export interface CellData {
  value: any
  renderType?: CellRenderType
  editable?: boolean
  completeValue?: boolean
  partSize?: string
  size?: string
}
export interface RowData {
  [key: string]: CellData
}

export interface QueryResult extends QueryBase {
  /** 结果集数据 */
  resultData: PlainRowData[]
  detailedResultData: RowData[]
  /** 结果集是否可编辑 */
  editable: boolean
  /** 列信息 */
  columnInfos: ResultColumnInfo[]
  /** 结果集总行数 */
  lastRow: number
  /** 动态结果集总行数 */
  pageTotal: number
  /** 该结果集对应的执行语句 */
  statement: string
  /** 语句操作对象 */
  statementObject?: string
  /** 带引号的执行作用对象 */
  operatingObjectWithQuoteCharacter?: string
  /** 语句操作对象（带引号） */
  statementObjectWithQuoteCharacter?: string
  /** 执行时间(毫秒) */
  executeTime: number | string
  /** 错误信息 */
  executeError?: {
    [x: string]: any, message: string
  }
  /* 导出权限 */
  dataExport: {
    status: boolean
    permissionType: string
    permissionPath: string
    showExported: boolean
  }
  /** 执行成功标志 */
  success?: boolean
  /** 是否可以进行 sql 格式导出  */
  resultSetSqlExport?: boolean
  /** 是否可以刷新 */
  refreshable?: boolean
  /** 语句中是否包含临时表 */
  containTempTable: boolean
  /** 是否支持分页 */
  supportPage: boolean
  scale?: number
  permissionResult?: any
  /** 列过滤 */
  filteredColumns: { columnName: string }[]
  allObjectPathSting?: string[]
  desensitized: boolean,
  doubleCheckType: 'NONE' | 'SMS' | 'OTP' | null | undefined,
  checkUserId?: string,
  checkUserName?: string,
  departmentName?: string,
  checkPermissionId?: string,
  /** 用于标志当前执行语句是否是脱敏明文执行，
   *  有则是二次或者以上执行，那么结果集不需要重新请求
   */
  isDesensitizedPlaintext?: boolean,
  /** 同上，前端自填，不为后端返回值 */
  currentParams?: any,
  /** 脱敏明文选择表字段 */
  checkedList?: string[],
}

export interface CellRenderResultData
  extends Omit<QueryResult, 'resultData' | 'detailedResultData'> {
  resultData: RowData[]
}

export interface SingleExecuteResult {
  executeLogInfo: {
    message: QueryLogItem,
    sessionId: string
  },
  response: QueryResult
}

const ExecuteStatusValue = ['RUNNING', 'SUCCESS', 'FAILED', 'CANCEL']
export type ExecuteStatus = typeof ExecuteStatusValue[number]

export interface MessageData {
  executionInfos: SingleExecuteResult[],
  segmentExecutionLog: SegmentMsg,
  reviewResult?: any
}

export interface MultiQueryResult {
  executeStatus: ExecuteStatus,
  messageData: MessageData,
  messageId: string
  executePercentage: number
  executeStatusMessage: string
  highRiskResultInfoList?: any[]
}

export interface ResultModify extends QueryBase {
  columnInfos: {
    columnName?: string
    columnType?: string
  }[]
  resultOperating: 'INSERT' | 'UPDATE' | 'DELETE' | 'RENDER'
  // 'RENDER' 用于生成二次渲染语句
  resultOperationDates: {
    resultNewData?: object
    resultOldData?: object
  }[]
  operatingObjectWithQuoteCharacter?: string
  statementObjectWithQuoteCharacter?: string
  statementObject?: string
}

// http://dev.bintools.cn:8080/browse/CQ-975
// 执行行数限制，执行次数限制
export type ActioneType = 'UPDATE' | 'REFRESH' | 'EXECUTE' | 'ROLLING_RESULT' | 'QUERY_AFTER_UPDATE'

export const executeSqlStatement = async (
  params: StatementExecuteParams & { actionType?: ActioneType },
): Promise<MessageData> => {
  if (getIsBase64EncodeVal()) {
    params.statements = strArrToBase64(params?.statements)
  }
  const method = 'POST'
  const { headers, body } = requestInitJSON(params)
  const response = await fetchWithStatusHandler('/dms/segment/statement/blocking/execute', {
    method,
    headers,
    body,
  })
  return await readResponseAsJSONBig(response).then((data: MessageData) => {
    const { executionInfos } = data
    const executionInfo = executionInfos.map((data) => {
      if (data.response) {
        const res = formatExecuteResult([data.response])[0] as QueryResult
        return {
          ...data,
          response: res
        }
      }
      return data
    })

    return {
      ...data,
      executionInfos: executionInfo,
    }
  })
}

/**
 * 创建表、创建视图等(不走事务的执行接口)
 */
export const executeMenuActionSql = (
  params: StatementExecuteParams,
): Promise<QueryResult[]> => {
  if (getIsBase64EncodeVal()) {
    params.statements = strArrToBase64(params?.statements)
  }
  return fetchPost(`/dms/segment/statement/executeMenuActionSql`, params).then(
    formatExecuteResult,
  )
}

export const testSqlStatement = (
  params: StatementExecuteParams,
): Promise<MessageData> => {
  if (getIsBase64EncodeVal()) {
    params.statements = strArrToBase64(params?.statements)
  }
  return fetchPost('/dms/segment/statement/blocking/execute', params).then(
    (data: MessageData) => {
      const { executionInfos } = data
      const executionInfo = executionInfos.map((data) => {
        if (data.response) {
          const res = formatExecuteResult([data.response])[0] as QueryResult
          return {
            ...data,
            response: res
          }
        }
        return data
      })

      return {
        ...data,
        executionInfos: executionInfo,
      }
    }
  )
}

type StatementExplainRequest = StatementExecuteParams

export const explainSqlStatement = (
  params: StatementExplainRequest,
): Promise<QueryResult[]> => {
  if(getIsBase64EncodeVal()) {
    params.statements = strArrToBase64(params?.statements)
  }
  return fetchPost(`/dms/statement/explain`, params).then(formatExecuteResult)
}

export const connectionChangeDatabase = (params: QueryBase) => {
  return fetchPut('/dms/connection/change/database', params)
}

/** 结果集再编辑 */
export const modifyResult = (params: ResultModify): Promise<any> => {
  return fetchPost('/dms/statement/result', params)
}

interface CompletionRequest {
  connectionId: number | string
  dataSourceType: DataSourceType
  operatingObject: string | undefined
  promptType: CompletionEnum
  tables: string[]
}

interface CompletionItem {
  label: string
  insertText: string
  detail: string
}

export const getCompletionItemSuggestions = (
  params: CompletionRequest,
): Promise<CompletionItem[]> => {
  return fetchPost('/dms/meta/completionWorlds', params)
}

interface RedisKeyRequest {
  connectionId?: number | string
  nodeName?: string
  nodePath?: string
  nodeType?: string
}

export const openRedisKey = (params: RedisKeyRequest) => {
  return fetchPost(`/dms/menu/openRedisKey`, params)
}

// 关闭查询窗口物理连接相关
// export const closeSingleConnection = (
//   tabKey: string,
//   connectionId: number | string,
// ) => {
//   return fetchGet(`/dms/connection/closeConnection/${tabKey}/${connectionId}`)
// }
// export const closeConnectionsOnTab = (tabKey: string) => {
//   return fetchGet(`/dms/connection/closeConnectionsOnTab/${tabKey}`)
// }
// export const closeConnectionsOnTabs = (tabKeys: string[]) => {
//   return fetchPost(`/dms/connection/closeConnectionsOnTabs`, tabKeys)
// }

// 连接事务提交模式相关
export type TxModeContext = {
  connectionId: number | string
  tabKey: string
  databaseName?: string
}

export const getTxModeOfConnection = ({
  connectionId,
  tabKey,
  databaseName = '',
}: TxModeContext): Promise<{ txMode: TxModeType }> => {
  const dbName = encodeURIComponent(databaseName)
  const search = dbName ? `?databaseName=${dbName}` : ''
  return fetchGet(
    `/dms/tx/connection/txModel/${connectionId}/${tabKey}` + search,
  )
}

export const setTxModeOfConnection = (
  params: TxModeContext & { txMode: TxModeType },
): Promise<{ txMode: TxModeType }> => {
  return fetchPut(`/dms/tx/connection/txModel`, params)
}

/**
 * 查询待提交语句
 */
export const getExecutedSql = ({
  connectionId,
  tabKey,
  databaseName = '',
}: {
  connectionId: number | string
  tabKey: string
  databaseName?: string
}): Promise<string[]> => {
  const dbName = encodeURIComponent(databaseName)
  const search = dbName ? `?databaseName=${dbName}` : ''
  return fetchGet(
    `/dms/tx/connection/executedSql/${connectionId}/${tabKey}` + search,
  )
}

export const commitTransaction = (params: TxModeContext) => {
  return fetchPost(`/dms/tx/connection/commit`, params)
}

export const rollbackTransaction = (params: TxModeContext) => {
  return fetchPost(`/dms/tx/connection/rollback`, params)
}

export const cancelExecute = (params: {
  connectionId?: number | string
  messageId: string,
  tabKey: string
  databaseName?: string
}) => {
  return fetchPost(`/dms/statement/stopExecute`, params)
}

export const startExecute = (params: {
  messageId: string,
  tabKey: string
}) => {
  return fetchPost(`/dms/statement/startExecute`, params)
}

export const getCompleteCell = (
  params: StatementExecuteParams,
): Promise<CellRenderResultData[]> => {
  if (getIsBase64EncodeVal()) {
    params.statements = strArrToBase64(params?.statements)
  }
  return fetchPost(`/dms/statement/render`, params)
}

export const downloadGridCell = async (params: StatementExecuteParams) => {
  if (getIsBase64EncodeVal()) {
    params.statements = strArrToBase64(params?.statements)
  }
  const method = 'POST'
  const { headers, body } = requestInitJSON(params)
  const response = await fetchWithErrorHandler(
    `/dms/statement/renderDownload`,
    {
      method,
      headers,
      body,
    },
  )
  // ! 避免从 response 中伪装下载文件，应该改为直接使用 a 标签下载
  // ! 此处 POST 请求可以拆为两个接口，一个 POST 接口传递参数，在后端生成文件；一个 GET 接口下载文件；
  // ! 需要后端配合
  await downloadResponse(response)
}

// 设计表相关
export interface TableColumnsResponse {
  tableName: string
  tableComment: string
  hasCommentProperty: boolean
  columns: TableColumn[]
  indexes: (IIndex & {
    sortType: IIndexColumnOrder
    tableName: string
    unique: boolean
  })[]
  foreignKeys: {}[]
  alreadyHasPrimaryKey: boolean
}

export interface NameRes {
  names: string[]
}

export interface QueryTableInfoParams {
  connectionId: number | string | any,
  connectionType: string,
  tableName?: string,
  schemaName?: string
  databaseName?: string
}

// 外键数据接口
export interface TableForeignKeyResponse {
  "constraintName": string,
  "columnNames": string[],
  "constraintType": string,
  "condition": string,
  "enabled": boolean,
  "refDatabaseName": string,
  "refSchemaName": string,
  "refTableName": string,
  "refColumnNames": string[],
  "updateRule": string | null,
  "deleteRule": string
}


// 设计表-生成 sql 接口
export interface GenerateSql {
  "connectionType": string,
  "databaseName"?: string,
  "schemaName"?: string,
  "tableName": string,
  "tableInfo"?: {
    "newTableName"?: string,
    "oldComment"?: string,
    "comment"?: string
  },
  "primaryKey"?: {
    "operationType"?: string,
    "primaryKeys"?: string[],
    "newPrimaryKeys"?: string[],
    "oldPkName"?: string
  },
  "columns"?: object[],
  "indexes"?: object[],
  "constraints"?: object[],
  "fkConstraints"?: object[]
}


// 索引接口
export interface TableIndexesResponse {
  indexName: string,
  columnNames: string[],
  indexType: string,
  nullable: string,
  unique: boolean,
  comment: string,
}

// 获取字段接口
export interface TableColumnResponse {
  "columnName": string,
  "dataType": string,
  "length": number,
  "scale": number,
  "defaultValue": string,
  "comment": string,
  "virtual": boolean,
  "nullable": boolean,
  "autoIncrease": boolean,
  "primary": boolean
}

// 获取 Mysql 选项接口
export interface TableMysqlOptionResponse {
  "engine": string,
  "tablespace": string,
  "autoIncrement": string,
  "charset": string,
  "collation": string,
  "avgRowLength": string,
  "minRows": string,
  "maxRows": string,
  "keyBlockSize": string,
  "rowFormat": string,
  "statsAutoRecalc": string,
  "statsPersistent": string,
  "statsSamplePages": string
}

// 获取 Oracle 选项接口
export interface TableOracleOptionResponse {
  "tablespaceName": string,
  "logging": string,
  "compression": string,
  "cache": string,
  "rowMovement": string,
  "degree": string
}

// 获取 PostgreSQL 选项接口
export interface TablePostgreSQLOptionResponse {
  "tableowner": string,
  "tablespace": string,
  "inhparent": string,
  "fillfactor": string,
  "persistence": string
}

// 获取 SQLServer 选项接口
export interface TableSQLServerOptionResponse {
  "identityColumn": string,
  "seed": string,
  "incr": string,
  "curr": string
}

// 基本数据库特征接口
export interface DataSourceMetaResponse {
  dataTypeEnum: string[]
  indexTypeEnum: string[]
  constraintTypeEnum: string[]
  updateRuleEnum: string[]
  deleteRuleEnum: string[]
  supportedColumnProperty: object
  supportedIndexProperty: object
  supportedConstraintProperty: object
  supportedFKConstraintProperty: object
  supportedTriggerProperty: object
  supportedPartitionProperty: object
}


// 约束数据接口
export interface TableConstraintResponse {
  constraintName: string,
  columnNames: string[],
  constraintType: string,
  condition: string,
  enabled: boolean
}
//查看database列表
export const getRefDatabaseNames = (
  params: QueryTableInfoParams,
): Promise<NameRes> => {
  return fetchPost(`/dms/meta/database/names`, params)
}

//查看function列表
export const getFunctionNames = (
  params: QueryTableInfoParams,
): Promise<NameRes> => {
  return fetchPost(`/dms/meta/function/names`, params)
}

//查看Schema列表
export const getRefSchemaNames = (
  params: QueryTableInfoParams,
): Promise<NameRes> => {
  return fetchPost(`/dms/meta/schema/names`, params)
}

//查看table列表
export const getRefTableNames = (
  params: QueryTableInfoParams,
): Promise<NameRes> => {
  return fetchPost(`/dms/meta/table/names`, params)
}

//查看 column 列表
export const getRefColumnNames = (
  params: QueryTableInfoParams,
): Promise<NameRes> => {
  return fetchPost(`/dms/meta/refColumn/names`, params)
}

export const getTableColumns = (
  params: NodeEntity,
): Promise<TableColumnsResponse> => {
  return fetchPost(`dms/meta/table/structure`, params)
}

export interface ITableInfoProps {
  connectionId: number | string;
  connectionType: string;
  databaseName?: string;
  schemaName?: string;
  tableName?: string;
  nodePathWithType?: string;
}

export const getTableInfo = (
  params: ITableInfoProps,
): Promise<{
  tableName: string;
  comment: string;
  pkName: string;
}> => {
  return fetchPost(`/dms/meta/table/info`, params)
}

// 获取子分区类型
export const getSubpartitionTypes = (
  params: { connectionType: string; partitionType: string },
): Promise<{ type: string[] }> => {
  return fetchPost(`/dms/meta/partition/subpartitionType`, params)
}

// 获取分区信息 new api
export const getNewTablePartitions_api = (
  params: Partial<NodeEntity>,
): Promise<TableConstraintResponse[]> => {
  return fetchPost(`/dms/meta/table/partition`, params)
}
// 获取临时分区信息 new api
export const getNewTableTempPartition_api = (
  params: Partial<NodeEntity>,
): Promise<TableConstraintResponse[]> => {
return fetchPost(`/dms/meta/table/tempPartition`, params)
}
// 获取选项信息
export const getTableOption_api = (
  params: QueryTableInfoParams,
): Promise<TableMysqlOptionResponse | TableOracleOptionResponse | TablePostgreSQLOptionResponse
  | TableSQLServerOptionResponse> => {
  return fetchPost(`/dms/meta/table/option`, params)
}

// 获取触发器信息
export const getTableTriggers_api = (
  params: QueryTableInfoParams,
): Promise<TableConstraintResponse[]> => {
  return fetchPost(`/dms/meta/table/triggers`, params)
}

// 获取数据库特征
export const getDatabaseMeta = (
  params: { connectionType: DataSourceType },
): Promise<DataSourceMetaResponse> => {
  params.connectionType = params.connectionType ?? 'MySQL'
  return fetchPost(`dms/meta/database`, params)
}

// 获取ddl信息
export const getDDl_api = (
  params: QueryTableInfoParams,
): Promise<{ ddl: string }> => {
  return fetchPost(`dms/meta/table/ddl`, params)
}

// 生成sql信息
export const generateSql_api = (
  params: GenerateSql,
): Promise<{ sql: string }> => {
  return fetchPost(`dms/menu/designTable/generateSql`, params)
}

// 获取约束信息
export const getTableConstraints_api = (
  params: QueryTableInfoParams,
): Promise<TableConstraintResponse[]> => {
  return fetchPost(`dms/meta/table/constraints`, params)
}

// 获取外键信息
export const getTableForeignKey_api = (
  params: QueryTableInfoParams,
): Promise<TableForeignKeyResponse[]> => {
  return fetchPost(`dms/meta/table/fks`, params)
}

// 获取索引信息
export const getTableIndexes_api = (
  params: QueryTableInfoParams,
): Promise<TableIndexesResponse[]> => {
  return fetchPost(`dms/meta/table/indexes`, params)
}

// 获取字段信息
export const getTableColumn_api = (
  params: QueryTableInfoParams,
): Promise<TableColumnResponse[]> => {
  return fetchPost(`dms/meta/table/columns`, params)
}

interface SegmentPosition {
  segmentIndex: number
}

/** 语句分块执行 */
export const executeSqlSegment = (
  params: SegmentPosition & StatementExecuteParams | any,
) => {
  const { databaseName = '', operatingObject = '' } = params
  if(getIsBase64EncodeVal()) {
    const sql = params?.statements;
    if (typeof sql === 'string') {
      params.statements = strToBase64(sql)
    } else if (Array.isArray(sql)) {
      params.statements = strArrToBase64(sql)
    }
  }
  return fetchPost(`/dms/segment/statement/execute`, {
    ...params,
    databaseName,
    operatingObject,
  })
}

/** 获取语句分块执行结果 */
export const getSegmentResults = async (params: QueryParams): Promise<MultiQueryResult> => {
  const method = 'POST'
  const { headers, body } = requestInitJSON(params)
  const response = await fetchWithStatusHandler(
    '/dms/segment/statement/fetchSegmentResult',
    { method, headers, body },
  )
  return await readResponseAsJSONBig(response).then((result: MultiQueryResult): MultiQueryResult => {
    const { messageData } = result
    if (messageData) {
      const executionInfos = messageData.executionInfos.map((data) => {
        if (data.response) {
          const res = formatExecuteResult([data.response])[0] as QueryResult
          return {
            ...data,
            response: res
          }
        }
        return data
      })

      return {
        ...result,
        messageData: {
          executionInfos,
          segmentExecutionLog: messageData.segmentExecutionLog,
          reviewResult: messageData?.reviewResult
        }
      }
    }

    return result
  })
}

// 支持数组形式
export const getSegmentResultsWithList = async (params: QueryParams[]): Promise<MultiQueryResult[]> => {
  const method = 'POST'
  const { headers, body } = requestInitJSON(params)
  const response = await fetchWithStatusHandler(
    '/dms/segment/statement/fetchSegmentResult',
    { method, headers, body },
  )
  return await readResponseAsJSONBig(response).then((result: MultiQueryResult[]): MultiQueryResult[] => {
    return result.map((item) => {
      const { messageData } = item
      const executionInfos = messageData.executionInfos.map((data) => {
        const res = formatExecuteResult([data.response])[0] as QueryResult
        return {
          ...data,
          response: res
        }
      })
      return {
        ...item,
        messageData: {
          executionInfos,
          segmentExecutionLog: messageData.segmentExecutionLog
        }
      }
    })
  })
}

/** 选中语句 数据源信息 */
export const getConnectionInfoFromSqlSegment = (
  params: SegmentPosition & StatementExecuteParams | any,
) => {
  const { databaseName = '', operatingObject = '' } = params
  return fetchPost(`/dms/meta/object/definition`, {
    ...params,
    databaseName,
    operatingObject,
  })
}
export interface IDesginIndex {
  connectionId: string | number
  connectionType: DataSourceType
  userInputs: {
    databaseName: string | number
    tableName: string | number
    indexOperation: (IIndex & { operationType: IIndexOperation })[] | undefined
    schemaName: string | number
  }
}

/* 设计索引 */
export const desginIndexs_api = (params: any) =>
  fetchPost(`/dms/menu/designIndex`, params)

/* 通过关键字查询节点树 */
export const searchTreeNodeByKeyword = (params: {
  condition: string
  userId: string
}): Promise<(ResponseNodeEntity & { children?: ResponseNodeEntity[] })[]> =>
  fetchPost(`/dict/meta/search `, { ...params, pageNo: 1, pageSize: 100000 })

/* 查询数据库真实总数 */
export const getCountOfQueryResult = (params: any) => {
  if(getIsBase64EncodeVal()) {
    const sql = params?.statements;
    if (typeof sql === 'string') {
      params.statements = strToBase64(sql);
    } else if (Array.isArray(sql)) {
      params.statements = strArrToBase64(sql);
    }
  }
  return fetchPost(`/dms/segment/statement/fetchCountOfQuery`, params);
}

/* 终止查询数据库真实总数 */
export const stopCountOfQueryResult = (params: {
  tabKey: string
  connectionId: string | number
  databaseName: string
}) => {
  return fetchPost(`/dms/statement/stopCountOfQuery`, params)
}

interface IDataSourceDescrption {
  /** 数据源类别 */
  dataSourceCategory: string
  /** 是否是一库一连接 */
  oneLibraryAndOneConnection: boolean
  /** 是否支持数据库 */
  supportDatabase: boolean
  /** 是否支持模式 */
  supportSchema: boolean
  /** 是否支持执行计划 */
  supportExplain: boolean
  /** 是否支持事务 */
  supportTransaction: boolean
  /** 是否支持停止执行 */
  supportStopExecute: boolean
}

/** 数据源描述 */
export const getDataSourceDescription = (
  dataSourceType: DataSourceType,
): Promise<IDataSourceDescrption> => {
  return fetchGet(`/dms/meta/datasource_info/${dataSourceType}`)
}

export interface FlashBackSqlDataType {
  executeSql: string;
  flashBackSql: string;
  type: string;
  date: string;
  operation?: boolean;
}

/** 获取 闪回语句 */
export const getSqlFlashBackModalList = (
  connectionId: number | string | undefined,
  tabKey: string | undefined,
): Promise<FlashBackSqlDataType[]> => {
  // return Promise.resolve(data1)
  return fetchGet(`/dms/tx/connection/flashback/${connectionId}/${tabKey}`)
}

/** 获取闪回状态（是否可闪回） */
export const getSqlFlashBackModalStatus = (
  connectionId: number | string | undefined,
  tabKey: string | undefined,
): Promise<any> => {
  // return Promise.resolve(data2)
  return fetchGet(`/dms/tx/connection/flashback/status/${connectionId}/${tabKey}`)
}

/** 获取ob数据源对应的表组和视图组 */
interface ITableViewGroupprops {
  connectionId: number | string | undefined
  connectionType: string | undefined
  nodeType: string | undefined
  nodePath: string | undefined
  nodePathWithType: string | undefined
  groupId: number | string | undefined
  globalHavePermissionFlag: boolean
}
export const getObTableViewGroup = (params: ITableViewGroupprops) =>{
  return fetchPost(`/dms/meta/loadTableAndView`, params)
}

/** 生成view select sql语句 */
export const generateViewSelectSql = (
  params: {
    connectionType: string | undefined
    schemaName: string | undefined
    databaseName: string | undefined
    tableName: string | undefined
    tableAliasName: string | undefined
    columns: any[]
  }
) => {
  return fetchPost(`/dms/menu/viewSelect/generateSql`, params)
}

/** 生成view create sql语句 */
export const generateViewCreateSql = (
  params: {
    dataSourceType: string | undefined
    nodeType: string | undefined
    userInputs: {
      databaseName: string | undefined
      viewName: string | undefined
      viewSql: string | undefined
      checkPoint: string | undefined
    }
  }
) => {
  return fetchPost(`/dms/menu/viewCreate/generateSql`, params)
}

/** 查看视图结构字段 */
export const viewStructFields = (
  params: {
    connectionId: number | string | undefined
    connectionType: string | undefined
    schemaName: string | undefined
    databaseName: string | undefined
    viewName: string | undefined
  }
) => {
  return fetchPost(`/dms/meta/view/columns`, params)
}

/** 查看视图结构 DDL */
export const viewStructDDL = (
  params: {
    connectionId: number | string | undefined
    connectionType: string | undefined
    schemaName: string | undefined
    databaseName: string | undefined
    viewName: string | undefined
  }
) => {
  return fetchPost(`/dms/meta/view/ddl`, params)
}