/**
 * 自动授权-权限集操作（创建/编辑）
 */
import React, { useState, useEffect } from "react"
import { SearchOutlined } from '@ant-design/icons'
import * as _ from 'lodash';
import classnames from 'classnames'
import { Spin, Input, message } from 'antd'
import { useLocation } from 'react-router-dom'
import { ResizableBox, ResizableProps } from 'react-resizable'
import {
  getCartConnection,
  addFlowRequisitionToCart,
  getApplyResourceSearchSetting
} from 'src/api'
import { useRequest, useDispatch, useSelector } from 'src/hook'
import { SimpleBreadcrumbs, Iconfont } from 'src/components'
import {
  setNewTreeData,
  fetchConnections,
  setResourceTotal,
  resetPermissionCollections,
  resetConnectionObjectPermissions,
} from './visitRequestOperateSlice'
import ObjectLevelAuthorization from "./objectLevelAuthorization"
import ConnectionRightMenuSetting from './ConnectionRightMenuSetting'
import TreeComponent from './LeftResourceTreeComponent/TreeComponent'
import { EmptyTipContent } from "./components/EmptyTipContent";
import styles from './index.module.scss'

interface IProps {
  [p: string]: any
}
const ResizableBoxProps: ResizableProps = {
  axis: 'x',
  width: 320,
  height: 0,
  minConstraints: [260, 0],
  maxConstraints: [620, 0],
}

const breadcrumbData = [
  { title: "流程" },
  {
    title: "我的申请",
    href: '/mine_apply',
  },
  {
    separator: '/',
    title: '访问申请',
  },
];


const VisitRequestOperation = (props: IProps) => {
  const dispatch = useDispatch()
  const location = useLocation<any>()
  const roleId = location.state?.roleId

  const { newTreeData, selectedTreeNode, resourceTotal } = useSelector(state => state.visitRequestOperate);

  const [searchValue, setSearchValue] = useState<string>("");

  const [permissionCollectionVOS, setPermissionCollectionVOS] = useState<any[]>([])

  const [rightWrapWidth, setRightWrapWidth] = useState<string>("")
  //是否刷新
  const [needRefresh, setNeedRefresh] = useState(false);
  //是否批量操作
  const [isBatchOperation, setIsBatchOperation] = useState(false)

  //批量选中数据类型
  const [checkedTreeNodes, setCheckedTreeNodes] = useState<any[]>([]);

  //购物车数量
  const { data: totalSchema, refresh: refreshCartNum } = useRequest(getCartConnection, {
    formatResult(res) {
      return res?.totalSchema || 0
    }
  })
  //是否开启 资源搜索页展示所有资源
  const { data: isShowAllResource } = useRequest(getApplyResourceSearchSetting)

  //加入购物车
  const { run: runAddRequisitionToCart } = useRequest(addFlowRequisitionToCart, {
    manual: true,
    onSuccess: () => {
      message.success('成功加入申请单');
      setPermissionCollectionVOS([]);
      dispatch(resetConnectionObjectPermissions());
      dispatch(resetPermissionCollections());
      setIsBatchOperation(false);
      setCheckedTreeNodes([]);
      setNeedRefresh(!needRefresh);
      refreshCartNum();
    }
  });

  useEffect(() => {
    handleLeftWrapResize();
  }, []);


  useEffect(() => {
    //每次只提交当前页申请 不做存储
    if (!isBatchOperation) {
      setPermissionCollectionVOS([]);
    }

  }, [selectedTreeNode?.key, isBatchOperation])

  useEffect(() => {

    //增加参数
    if (isShowAllResource) {
      dispatch(fetchConnections())
    }else {
      dispatch(fetchConnections({
        ip: searchValue
      }))
    }
   
    return () => {
      dispatch(setNewTreeData([]));
      if (!isShowAllResource) {
        dispatch(setResourceTotal(0))
      }
    
      dispatch(resetConnectionObjectPermissions());
      dispatch(resetPermissionCollections());
    }
  }, [dispatch, isShowAllResource, searchValue])

  const handleSearch = (e: any) => {
    const value = e.target.value;
    setSearchValue(value?.trim());
  };

  const handleUpdateParams = (params: any[], compare?: boolean) => {
    setPermissionCollectionVOS(params)
  }

  const onAddApplicationToCart = () => {

    //批量且 选中节点信息
    if (isBatchOperation && checkedTreeNodes?.length) {
      let params: any = [];
      const permission = permissionCollectionVOS?.[0];
      checkedTreeNodes?.forEach(node => {
        let value: any = {};

        if (permission?.permissionSdtUserAddVo) {
          value.permissionSdtUserAddVo = {
            ...(permission?.permissionSdtUserAddVo || {}),
            nodePathWithType: node?.nodePathWithType,
            nodeName: node?.nodeName,
            nodePath: node?.nodePath,
          }
        }
        if (permission?.userToolVo) {
          value.userToolVo = {
            ...(permission?.userToolVo || {}),
            nodePathWithType: node?.nodePathWithType,
            nodeName: node?.nodeName,
            nodePath: node?.nodePath,
          }
        }
        params.push(value);
      })

      runAddRequisitionToCart({
        flowType: "FAT",
        nodeType: checkedTreeNodes?.[0]?.nodeType,
        permissionCollectionVOS: params,
      });
    } else {
      runAddRequisitionToCart({
        flowType: "FAT",
        nodeType: selectedTreeNode?.nodeType,
        permissionCollectionVOS
      });
    }
  }


  const handleLeftWrapResize = () => {
    // @ts-ignore
    const lWidth = document.getElementsByClassName("react-resizable")[0]?.style.width ||
      "320px";

    const width = `calc(100vw - 30px - ${lWidth} - 250px )`;
    setRightWrapWidth(width);
  };

  const renderResizeHandle = (
    <div className={styles.resizeHandle}>
      <Iconfont type="icon-handle-8"></Iconfont>
    </div>
  );
  console.log(permissionCollectionVOS, 'isBatchOperation--',selectedTreeNode);

  return (
    <Spin spinning={false}>
      <div className={styles.automaticAuthorizationWrap}>
        <div style={{ padding: "0 10px" }}>
          <SimpleBreadcrumbs items={breadcrumbData} />
        </div>
        <div className={styles.content}>
          <ResizableBox
            className={styles.resizableBox}
            handle={renderResizeHandle}
            onResize={handleLeftWrapResize}
            {...ResizableBoxProps}
          >
            <div className={styles.leftWrap}>
              <div className={classnames(styles.title, styles.mb10)} style={{ color: '#667084' }}>
                资源列表:{resourceTotal}
              </div>
              <Input
                allowClear
                className={classnames(styles.searchBtn, styles.mb10)}
                prefix={<SearchOutlined />}
                placeholder={isShowAllResource ? "请输入资源名/IP地址" : '请输入IP地址'}
                onChange={handleSearch}
                value={searchValue}
              />
             
              <TreeComponent
                searchValue={isShowAllResource ? searchValue : ''}
                checkedTreeNodes={checkedTreeNodes}
                setCheckedTreeNodes={setCheckedTreeNodes}
                isBatchOperation={isBatchOperation}
                onCancelBatchAction={() => {
                  setIsBatchOperation(false);
                  setCheckedTreeNodes([]);
                  setPermissionCollectionVOS([]);
                  setNeedRefresh(!needRefresh)
                }}
                onOpenBatchAction={() => {
                  setIsBatchOperation(true);
                  setPermissionCollectionVOS([]);
                }}
              />
            </div>
          </ResizableBox>
          <div className={styles.rightWrap} style={{ width: rightWrapWidth }}>
            {/* 数据源和组级别右侧有内容 没有选中内容 */}
            {
              true &&
              <div className={styles.contentStyle}>
                <div className={styles.operationList}>
                  {/* connection、db、schema层级授权*/}
                  {

                    (!isBatchOperation && ['datasource', 'group', 'database', 'connection', 'schema', 'oracleUser'].includes(selectedTreeNode?.nodeType) ||
                      (isBatchOperation && ['datasource', 'group', 'database', 'connection', 'schema', 'oracleUser'].includes(checkedTreeNodes?.[0]?.nodeType))
                    ) &&
                    <ConnectionRightMenuSetting
                      isEdit={true}
                      roleId={roleId}
                      cartNumber={totalSchema}
                      needRefresh={needRefresh}
                      selectTreeItem={isBatchOperation ? checkedTreeNodes?.[0] : selectedTreeNode}
                      permissionCollectionVOS={permissionCollectionVOS}
                      updatePermissionCollectionVOS={handleUpdateParams}
                    />
                  }
                  {
                    isBatchOperation && !checkedTreeNodes?.length && !selectedTreeNode?.nodeType?.toUpperCase()?.endsWith("GROUP") &&
                    <EmptyTipContent   cartNumber={totalSchema}/>
                  }
                  {/* 对象级权限设置 */}
                  {
                    selectedTreeNode?.nodeType?.toUpperCase()?.endsWith("GROUP") && !checkedTreeNodes?.length &&
                    <ObjectLevelAuthorization
                      selectTreeItem={selectedTreeNode}
                      updateParams={handleUpdateParams}
                      canEdit={true}
                      cartNumber={totalSchema}
                      roleId={roleId}
                      needRefresh={needRefresh}
                      onCancelBatchOperation={() => { setIsBatchOperation(false); setCheckedTreeNodes([]) }}
                    />
                  }
                </div>
                <div className={styles.addRequestFooter}>
                  <span
                    className={classnames(styles.addRequestBtn, {
                      [styles.hightlightBtn]: !_.isEmpty(permissionCollectionVOS)
                    })}
                    onClick={() => !_.isEmpty(permissionCollectionVOS) && onAddApplicationToCart()}
                  >
                    加入申请单
                  </span>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </Spin>
  )
}

export default VisitRequestOperation