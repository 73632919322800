import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Iconfont } from 'src/components'
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'src/hook'

export const BdNavigation = ({showIconSty = {}, showBdNavigation = true}: {showIconSty?: any, showBdNavigation?: boolean}) => {

  const history = useHistory();
  const [iconType, setIconType] = useState<{
    dataOperation: string, taskCenter: string
  }>({dataOperation: 'icon-shujuyuan-1', taskCenter: 'icon-renwuzhongxin-0'});

  const dataOperationRouter = "/system_data_operate";
  const taskCenterRouter = "/download";
  // theme: 'dark' | 'light'
  const { theme } = useSelector(
    (state) => state.appearance,
  )

  useEffect(() => {
    const pathname = window?.location?.pathname;
    switch (pathname) {
      case dataOperationRouter:
        setIconType(
          {dataOperation: 'icon-shujuyuan-1', taskCenter: 'icon-renwuzhongxin-0'}
        );
        break;
      case taskCenterRouter:
        setIconType(
          {dataOperation: 'icon-shujuyuan-0', taskCenter: 'icon-renwuzhongxin-1'}
        );
        break;
    }
  }, [])

  const [isShow, setIsShow] = useState<boolean>(showBdNavigation);

  const renderContent = (
    <div className={classnames(styles.bdNavigationWrap, theme === "light" ? styles.bgColorLight : styles.bgColorDark)}>
      <div className={styles.bdNavigation}>
        <div className={styles.dataOperation}>
          <Iconfont
            type={iconType?.dataOperation}
            className={classnames(styles.icon, styles.dataOperationIcon, theme === "light" ?styles.iconLight : styles.iconDark)}
            onClick={() => history.push({
              pathname: dataOperationRouter,
              state: {
                showBdNavigation: isShow
              }
            })}
          />
          <span>数据源</span>
        </div>
        <div className={styles.task}>
          <Iconfont
            type={iconType?.taskCenter}
            className={classnames(styles.icon, styles.taskIcon, theme === "light" ?styles.iconLight : styles.iconDark)}
            onClick={() => history.push({
              pathname: taskCenterRouter,
              state: {
                showBdNavigation: isShow
              }
            })}
          />
          <span>任务</span>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <div className={styles.showIcon} style={showIconSty}>
          <Iconfont
            type={isShow? `icon-close-${theme}` : `icon-open-${theme}`}
            onClick={() => {
              setIsShow((b: boolean) => {
                return !b;
              })
            }}
          />
      </div>
      {isShow ? renderContent : null}
    </>
  )
}
