import { fetchDelete, fetchGet, fetchPost, fetchPut } from "./customFetch"

/**
 * 添加用户
 */
interface addUsertI {
  userIds: string[]
  templateId: number
  nodePathWithType?: string
  groupId?: string
}

/** 非对象级模板授权，自定义连接组、连接、schema、database等 */
export const addPermissionUser = (params: addUsertI) => {
  return fetchPost(`/user/permission/sdt/user/add`, params)
}

/**
 * 是否有权限添加用户 
 */
interface supprtAddUserI {
  nodePathWithType?: string
  groupId?: string
}
export const hasPermissionAddUser = (params: supprtAddUserI) => {
  return fetchPost(`/user/permission/sdt/user/support/add`, params)
}

/**
 * 组织架构和人员查询
 */
export interface IUserOrgSdtVoData {
  name: string;
  child: IUserOrgSdtVoData[] | null;
  orgId: number;
  type: string;
  userId: null | string;
  path: string;
  avatarUrl?: string;
  count?: number;
}

export const queryOrgSdtWithUser = ():Promise<IUserOrgSdtVoData[]> => {
  return fetchGet(`/user/org/findUserOrgSdtVo`)
}

/**
 * 获取权限等级列表
 */
export const getPermissionList = (dataSourceType: string) => {
  return fetchGet(`/user/datasource/permission/template/all/${dataSourceType}`)
}

/**
 * 查询不同数据源下对应的所有权限信息
 */
export const getPermissionTemplate = (dataSourceType: string) => {
  return fetchGet(
    `/user/datasource/permission/template/operations/${dataSourceType}`,
  )
}

export interface IHighRiskPermissionParams {
  dataSourceType: string;
  groupId: string | null;
  nodePathWithTypeList: string[] | null
}

export interface IHighRiskPermissionRes {
  level: number;
  childObjects: string[];
  operationsVoList: {
    objectType: string;
    objectTypeName: string;
    operations: {
      operation: string;
      operationName: string;
      description: string;
      hasSelected: boolean;
    }[]
   
  }[]
}
//获取操作模板以及勾选的值
export const getHighRiskPermission = (params: IHighRiskPermissionParams): Promise<IHighRiskPermissionRes>  => {
  return fetchPost(
    `/user/permission/highRisk/getPermission`,
    params
  )
}
export interface IBasicSettingParams {
  dataSourceType: string;
  groupId: string | null;
  nodePathWithTypeList: string[] | null
}
//获取安全设置-基础设置的值
export const getBasicSetting = (params: IBasicSettingParams): Promise<any>  => {
  return fetchPost(
    `/user/security/export/setting/get`,
    params
  )
}

export interface ISaveHighRiskPermissionParams1 {
  dataSourceType: string;
  groupId: string | null;
  nodePathWithTypeList: string[] | null
  level?: number;
  operationVoList?: any
}
export interface ISaveHighRiskPermissionParams {
  dataSourceType: string;
  groupId: string | null;
  nodePathWithTypeList: string[] | null
  operationVoList?: any
}
// 保存 安全设置-高危操作设置的值
export const saveHighRiskPermission = (params: ISaveHighRiskPermissionParams): Promise<IHighRiskPermissionRes>  => {
  return fetchPost(
    `/user/permission/highRisk/savePermission`,
    params
  )
}
export interface IBasicSettingParams {
  dataSourceType: string
  groupId: string | null
  nodePathWithTypeList: string[] | null
  operationVoList?: any
  settingType?: string
  count?: string | number
}
// 保存 安全设置-基础设置 的值
export const saveBasicSetting = (params: IBasicSettingParams): Promise<any>  => {
  return fetchPost(
    `/user/security/export/setting/save`,
    params
  )
}

/**
 * 新增或编辑权限
 */
interface savePermisssionI {
  id?: number,
  name: string,
  dataSourceType: string
  templateOperationsSet: any[]
}
export const savePermisssion = (params: savePermisssionI) => {
  return fetchPost(`/user/datasource/permission/template/save`, params)
}

/**
 * 禁用/启用权限
 */
interface paramsI { 
  id: number,
  status: string
}
export const togglePermisssion = (params: paramsI) => { 
  return fetchPost(`/user/datasource/permission/template/status`, params)
}

/**
 * 删除权限等级
 */
export const deletePermissionItem = (ids: number[]) => {
  return fetchDelete(`/user/datasource/permission/template`, ids)
}

/**
 * 查看修订历史
 */
export const queryPermissionHistory = (id: number) => {
  return fetchGet(`/user/datasource/permission/template/history/${id}`)
}

/**
 * 查询关联用户
 */
export const queryPermissionUser = (id: number) => {
  return fetchGet(`/user/datasource/permission/template/user/${id}`)
}

/**
 * 删除关联用户
 */
interface deleteParamsI { 
  id: number,
  userIds: string[]
}
export const deletePermissionUser = (params: deleteParamsI) => {
  return fetchDelete(`/user/datasource/permission/template/user`, params)
}

/**
 * 是否支持创建
 */
export const isSupportCreate = (dataSourceType: string) => {
  return fetchGet(
    `/user/datasource/permission/template/support/create/${dataSourceType}`,
  )
}

/**
 * 连接及以下层级:隐藏模式和测试环境状态回显
 */
interface scurityStausParams {
  nodePath: string
  connectionId: number
  [p:string]: any
}
export const querySecurityStatus = (params: scurityStausParams) => {
  return fetchGet(`/user/security`, params);
}

/**
 * 组:隐藏模式和测试环境状态回显
 */
export const queryGroupSecurityStatus = (groupId: string) => {
  return fetchGet(`/user/security/group/${groupId}`)
}

/**
 * 数据源:隐藏模式和测试环境状态回显
 */
export const queryDataSourceSecurityStatus = (datasource: string) => {
  return fetchGet(`/user/security/datasource/${datasource}`)
}

/**
 * 连接及以下层级:隐藏模式修改  
 */
interface hiddenModeParamsI {
  nodePath: string
  conceal: boolean
  connectionId: string
}
export const changeHiddenMode = (params: hiddenModeParamsI) => {
  return fetchPut(`user/security/conceal`, params)
}

/**
 * 组：隐藏模式修改
 */
export const changeGroupHiddenMode = (params: any) => {
  return fetchPut(`/user/security/group/conceal/${params?.groupId}`, {
    conceal: params?.conceal,
  })
}

/**
 * 数据源：隐藏模式修改
 */
export const changeDataSourceHiddenMode = (params: any) => {
  return fetchPut(`/user/security/datasource/conceal/${params?.datasource}`, {
    conceal: params?.conceal,
  })
}

/**
 * 连接及以下层级:测试环境修改 
 */
export const changeTestEnvironment = (params: any) => {
  return fetchPut(`/user/security/experiment`, params)
}

/**
 * 组:测试环境修改
 */
export const changeGroupTestEnvironment = (params:any) => {
  return fetchPut(`/user/security/group/experiment/${params?.groupId}`, {
    experiment: params?.experiment,
  })
}

/**
 * 数据源:测试环境修改
 */
export const changeDataSourceTestEnvironment = (params: any) => {
  return fetchPut(
    `/user/security/datasource/experiment/${params?.datasource}`,
    {
      experiment: params?.experiment,
    },
  )
}

/**
 *  获取权限等级模板 
 */
export const getPermissionTemplateInfo = (dataSourceType: string) => {
  return fetchGet(
    `/user/datasource/permission/templateInfo/all/${dataSourceType}`,
  )
}

/**
 * 修改权限等级
 */
interface permissionI {
  nodePathWithType?: string
  groupId?: string
  userId: string
  templateId: string
}
 export const updatePermissionTemplateInfo = (params: permissionI) => {
   return fetchPost(`/user/permission/sdt/user/template`, params)
 }

/**
 *  获取工具权限模板 
 */
export const getToolTemplateInfo = (dataSourceType: string, nodeType: string) => {
  return fetchGet(
    `/user/datasource/permission/template/operations/tool/${dataSourceType}/${nodeType}`,
  )
}


/**
 * 获取连接角色
 */
export const getUserRole = () => {
  return fetchGet(`/user/permission/sdt/user/get/role`)
}

/**
 * 修改连接角色
 */
interface modifyUserRoleParams {
  userId: string
  roleType: string
  nodePathWithType?: string
  groupId?: string
}
export const updateUserRole = (params: modifyUserRoleParams) => {
  return fetchPost(`/user/permission/sdt/user/update/role`, params)
}

/**
 * 修改工具权限
 */
interface modifyUserToolParams {
  userIds: string[]
  templateOperationsSet: any[]
  nodePathWithType?: string
  groupId?: string
}
export const updateUserTool = (params: modifyUserToolParams) => {
  return fetchPost(`/user/permission/sdt/user/tool/update`, params)
}

/**
 * 查询工具权限选中值
 */
interface queryUserToolParams {
  userId: string
  nodePathWithType?: string
  groupId?: string
}
export const queryUserTool = (params: queryUserToolParams) => {
  return fetchPost(`/user/permission/sdt/user/tool/get`, params)
}

/**
 * 移除连接用户
 */
interface deleteParamsI {
  nodePathWithType?: string
  groupId?: string
  userId: string
}
export const removeConnectionUser = (params: deleteParamsI) => {
  return fetchDelete(`/user/permission/sdt/user/delete`, params)
}

/**
 * 禁用、启用用户
 */
interface togglePermissionUserParamsI {
  nodePathWithType?: string
  nodePathWithTypeList: string[]
  groupId?: string
  userId: string
  status: boolean
}
export const togglePermissionUser = (params: togglePermissionUserParamsI) => {
  return fetchPost(`/user/permission/sdt/user/status`, params)
}

/**
 * 获取权限用户列表 
 */
interface permissionUserParams {
  nodePathWithType?: string
  groupId?: string
}
export const getPermissionUser = (params: permissionUserParams) => {
  return fetchPost(`/user/permission/sdt/user/all`,params)
}

/**
 * 逐级获取树结构
 */
interface nodeParams {
  connectionId: number | string
  connectionType: string
  nodeType: string
  nodeName: string
  nodePath: string
  nodePathWithType: string
}
export const queryTreeNode = (params: nodeParams) => {
  return fetchPost(`/dms/meta/group/node`, params)
}

/**
 * 组上同步复核设置
 */
interface groupSynchronousReviewI {
  doubleCheck: string;
  groupId: string;
}
export const setGroupSynchronousReview = (params: groupSynchronousReviewI) => {
  return fetchPost(`/user/security/group/doubleCheck`, params);
};

/**
 * 连接或schema同步复核设置 
 */
interface synchronousReviewI {
  doubleCheck: string;
  connectionId: string;
}
export const setSynchronousReview = (params: synchronousReviewI) => {
  return fetchPost(`/user/security/group/doubleCheck/connection`, params);
};

/**
 * 安全设置查看(单条数据)【连接/Schema/database】
 */
export const queryConnectionSafeSetting = (connectionId: string) => {
  return fetchGet(`/user/security/time/connection`, { connectionId });
};

/**
 * 安全设置【连接/Schema/database】
 */
export const setConnectionSafeSetting = (params: any) => {
  return fetchPut(`/user/security/time/connection`, params);
};

/**
 * 安全设置查看(单条数据)【表、视图、函数等细粒度】
 */
interface SafeSettingParamsI {
  connectionId: string;
  nodePath: string;
  [p: string]: any;
}
export const querySafeSetting = (params: SafeSettingParamsI) => {
  return fetchGet(`/user/security/time`, params);
};

/**
 * 安全设置【表、视图、函数等细粒度】
 */
export const setSafeSetting = (params: any[]) => {
  return fetchPut(`/user/security/time`, params);
};

/**
 * 操作安全设置权限查询
 */
export const getSafeSettingAuthorize = (nodePathWithType: string) => {
  return fetchPost(`/user/security/time/canEditNodePath`, { nodePathWithType });
};

/**
 * 查看表、视图、函数等组上的列表信息
 */
export const queryDmsNodeData  = (params: any) => {
  return fetchPost(`/dms/meta/auth/node`, params);
};

/**
 * 分页渲染接口
 */
export const queryTableGroupData = (params: any) => {
  return fetchPost(`/user/permission/sdt/user/object`, params);
};

/**
 * 查询操作对象关联权限用户列表 
 */
interface perrelationuserParams { 
  dataSourceType: string
  nodePathWithTypeList: string
}
export const queryPermissionRelationUsers = (
  params: perrelationuserParams
) => {
  return fetchPost(
    `/user/permission/sdt/object/user/find`,
    params
  );
};

/**
 * 查询用户权限枚举 
 */
export const getUserAuthorizeEnum = (params: any) => {
  return fetchGet(
    `/user/permission/sdt/object/operation/${params?.dataSourceType}/${params?.nodeType}`
  );
};

/**
 * 修改操作对象关联权限用户
 */
export const updatePermissionRelationUsers = (
  params: any[]
) => {
  return fetchPost(
    `/user/permission/sdt/object/user/update`,
    params
  );
};

/**
 * 添加用户时候权限校验 是否冲突
 */
export const checkPermissionStatus = (
  params: {
    userIds: string[],
    groupId?:number;
    nodePathWithType?: string;
    templateId: string
  }
) => {
  return fetchPost(
    `/user/permission/sdt/user/checkAdd`,
    params
  );
};

/**
 *  访问申请 申请清单 获取权限等级模板 
 */
export const getPermissionTemplateAllNoCheckInfo = (dataSourceType: string) => {
  return fetchGet(
    `/user/datasource/permission/templateInfo/all/nocheck/${dataSourceType}`,
  )
}

/**
 * 访问申请 申请清单 获取连接角色
 */
export const getUserRoleNocheck = () => {
  return fetchGet(`/user/permission/sdt/user/get/role/nocheck`)
}

/**
 * 获取数据源高危申请详情
 */
export const getFlowPermissionList = (dataSourceType: string) => {
  return fetchGet(`/user/permission/highRisk/getFlowPermission/${dataSourceType}`)
}

/**
 * 获取数据源高危申请详情
 */
export const delPermission = (params: { permissionIds: any[] }) => {
  return fetchDelete(`/user/permission/highRisk/deleteFlowPermission`, params)
}

/**
 * 查询当前层级下的所有权限
 */
export const getPermissionInfos = (
  params: {
    userId: string, 
    nodePathWithType: string | undefined, 
    groupId: number | string | undefined
  }) => {
  return fetchPost(`/user/permission/sdt/user/permissionInfo`, params)
}

/**
 * 删除权限
 */
export const delPermissionInfo = (
  params: {
    userId: string | undefined, 
    permissionIds: number[]
  }) => {
  return fetchDelete(`/user/permission/sdt/user/permissionInfo/delete`, params)
}