import React, { isValidElement } from 'react';
import { Tooltip } from 'antd';
import { getCurrentModulePermissionByUrl } from 'src/util';
import { useSelector } from 'src/hook';

interface IProps {
  visible?: boolean;
  permissionlist: {
    isOnlyRead: boolean
    roleNameList: string[]
  },
  title: string
  [p: string]: any
}

export interface IMenuPermissionCollection  { isOnlyRead: boolean; roleNameList: string[] };

const PermissionTooltip: React.FC<IProps> = ({ permissionlist, title,visible=undefined,  align = undefined, width = '', children }) => {
  
  const { isOnlyRead, roleNameList = [] } = permissionlist || {};
  //是否由外界控制权限展示
  const isShowTooltip = visible !== undefined ? visible : isOnlyRead;

  return Array.isArray(children) ? (
    <>
      {children.map((child, index) =>
        isValidElement(child) && isShowTooltip ? (
          <Tooltip
            key={index}
            title={`您当前的角色是[${roleNameList?.join(", ")}], 对[${title}]没有操作权限`}
            align={align}
            overlayStyle={{ width: width }}
          >
            {  React.cloneElement(child as React.ReactElement)}
          </Tooltip>
        ) : (
          <React.Fragment key={index}>{child}</React.Fragment>
        )
      )}
    </>
  ) : (
    isShowTooltip ? (
      <Tooltip
        title={`您当前的角色是[${roleNameList?.join(", ")}], 对[${title}]没有操作权限`}
        align={align}
        overlayStyle={{ width: width }}
      >
        {React.cloneElement(children as React.ReactElement)}
      </Tooltip>
    ) : (
      <>{children}</>
    )
  );
}

export default PermissionTooltip;