/**
 * 安全设置
 */
import React, { useEffect, useState } from "react";
import { Spin, Form, Space, message, Select, InputNumber } from "antd";
import { SecurityFormLayout } from "./constants";
import { useRequest } from "src/hook";
import { saveBasicSetting } from "src/api";
import PermissionTooltip from "src/components/PermissionTooltip/PermissionTooltip";

interface IProps {
  form: any;
  connectionId: string;
  fromType: string; // 来源：1、connection:连接、shcme、database级别；2、group:连接下的组级别
  [p: string]: any;
}
interface optionsIprops {
  label: string;
  value: string | number
}

const BasicSetting = (props: IProps, ref: any) => {
  const {
    form,
    options,
    saveParams,
    permissionlist
  } = props;

  const { isOnlyRead } = permissionlist || {}
  const [settingTypeMenu, setSettingTypeMenu] = useState<optionsIprops[]>([])
  // 导出设置 "EXPORT_ROW_LIMIT": "单次导出行数限制",//只有选择这个选项才能填行数
  const [countItemDisabled, setCountItemDisabled] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      if (form.getFieldValue('settingType') === "EXPORT_ROW_LIMIT") {
        setCountItemDisabled(false)
      } else {
        setCountItemDisabled(true)
      }
    }, 1000); // 1秒后执行，可以根据实际情况调整延迟时间
  }, [form]);

  useEffect(() => {
    let menu: optionsIprops[] = []
    for (const key in options) {
      if (Object.prototype.hasOwnProperty.call(options, key)) {
        menu.push({
          value: key,
          label: options[key],
        })
      }
    }
    setSettingTypeMenu(menu)
  }, [options])

  const { run: runSaveBasicSetting } = useRequest(
    saveBasicSetting,
    {
      manual: true,
      onSuccess: (res) => {
        message.success('提交成功')
      },
    }
  );

  const handleChange = (values: any, key: string) => {
    if (key === "settingType" && values === "EXPORT_ROW_LIMIT") {
      setCountItemDisabled(false)
    } else if (key === "settingType" && values !== "EXPORT_ROW_LIMIT") {
      setCountItemDisabled(true)
    }

    if (key === "count") {
      runSaveBasicSetting({
        ...saveParams,
        [key]: values,
        settingType: "EXPORT_ROW_LIMIT"
      })
    } else {
      runSaveBasicSetting({
        ...saveParams,
        [key]: values
      })
    }
  }

  const integerFormatter = (value: any) => `${parseInt(value, 10) ?? ''}`;
  const integerParser = (value: any) => parseInt(value || '0', 10);

  // 自定义组和连接级等别设置
  return (
    <Spin spinning={false}>
      <Form
        form={form}
        {...SecurityFormLayout}
      >
        <PermissionTooltip
          title="数据保护管理"
          permissionlist={permissionlist}
          align={{ points: ['bl', 'tl'], offset: [250, 4] }}
        >
          <Form.Item label="导出设置" name="settingType">
            <Select
              placeholder="选择导出设置"
              style={{ width: "170px" }}
              onChange={(val) => handleChange(val, "settingType")}
              options={settingTypeMenu}
              disabled={isOnlyRead}
            />
          </Form.Item>
        </PermissionTooltip>
        {
          !(countItemDisabled || isOnlyRead) &&
          <PermissionTooltip
            title="数据保护管理"
            permissionlist={permissionlist}
            align={{ points: ['bl', 'tl'], offset: [250, 4] }}
          >
            <Form.Item label="行数限制" name="count">
              <InputNumber
                min={0}
                onChange={(val: any) => handleChange(val, "count")}
                style={{ width: "100px" }}
                step={1}
                formatter={integerFormatter}
                parser={integerParser}
              />
            </Form.Item>
          </PermissionTooltip>
        }
      </Form>
    </Spin>
  );
};
export default React.forwardRef(BasicSetting);
