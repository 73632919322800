import {
  fetchPost,
  fetchGet,
  fetchWithErrorHandler,
  getFetchJsonp,
} from './customFetch'
import { UserEntity, IEncryptKeys, IWwLoginInfo } from '../types'
/** RSA ｜ SM2 公共方法 */
import { setEncrypt } from 'src/util/encryptRsaSm2'
import { store } from 'src/store'

export interface UserLogin {
  password: string
  userId: string
}

const ukeyUrl = 'http://127.0.0.1:17681'
/**
 * @description: 初始化ukey
 */
export const initUKey = (): Promise<{}> => {
  const oJson = { "function": "InitiateLock", "sn": 0 };
  const sJson = JSON.stringify(oJson);
  return getFetchJsonp(ukeyUrl, `json=${sJson}`)
}

/**
 * @description: 获取当前UKey sn
 */
export const getSnKeyInfo = (): Promise<{}> => {
  const oJson = { "function": "GetSn" };
  const sJson = JSON.stringify(oJson);
  return getFetchJsonp(ukeyUrl, `json=${sJson}`)
}

/**
 * @description: 获取用户id和seed
 */

interface IKeyUserInfo {
  id: number
  seed: number
}

export const applyUKey = (
  deviceId: number
): Promise<IKeyUserInfo> => {
  return fetchGet(`/user/ukey/apply/${deviceId}`)
}

/**
 * @description: 获取加密结果, 根据id和seed生成
 */
export interface IKeySeed {
  sn: number,
  seed: number
}

export const getUkeyResult = (params: IKeySeed): Promise<{}> => {
  const oJsonLock = { "function": "Lock32_Function", "sn": params.sn, "rand": params.seed };
  const sJsonLock = JSON.stringify(oJsonLock);
  return getFetchJsonp(ukeyUrl, `json=${sJsonLock}`)
}

export const userLogin = async (params: UserLogin): Promise<UserEntity> => {
  /** SM2|RSA 加密 */
  const getKey = await getTransmissionPublicKey()
  params.password = setEncrypt({ algorithm: getKey?.algorithm, publicKey: getKey?.publicKey, password: params.password })
  // const { algorithm, publicKey } = store.getState().encryption
  /* 密码加密 */
  // params.password = btoa(unescape(encodeURIComponent(params.password)))
  return fetchPost('/user/login', params)
}

// 两步验证 - 短信
export const twoStepSMSLogin = async (params: UserLogin): Promise<boolean> => {
  /** SM2|RSA 加密 */
  const getKey = await getTransmissionPublicKey()
  params.password = setEncrypt({ algorithm: getKey?.algorithm, publicKey: getKey?.publicKey, password: params.password })
  // const { algorithm, publicKey } = store.getState().encryption
  // /* 密码加密 */
  // params.password = btoa(unescape(encodeURIComponent(params.password)))
  return fetchPost('/user/login/twoStepLogin', params)
}

// 两步验证 - 短信
export const twoStepSMSADLogin = async (params: {userId: string}): Promise<boolean> => {

  return fetchPost('/user/login/sendSMSCodeByUserId', params)
}

export interface ITwoStepSMSLoginAuth {
  userId: string
  authCode: string
}

// 验证码
export const twoStepSMSLoginAuth = (params: ITwoStepSMSLoginAuth): Promise<UserEntity> => {
  return fetchPost('/user/login/twoStepLoginAuth', params)
}

export interface UserUkeyLogin extends UserLogin {
  result: number
  encryptionId: number
}
/** ukey 登录 */
export const userUkeyLogin = async (params: UserUkeyLogin): Promise<UserEntity> => {
  /** SM2|RSA 加密 */
  const getKey = await getTransmissionPublicKey()
  params.password = setEncrypt({ algorithm: getKey?.algorithm, publicKey: getKey?.publicKey, password: params.password })
  // const { algorithm, publicKey } = store.getState().encryption
  // params.password = btoa(unescape(encodeURIComponent(params.password)))
  return fetchPost('/user/login/uKey', params)
}

export const userLogout = (): Promise<void> => {
  return fetchGet('/user/login/logout')
}

interface IOtpLogin extends UserLogin {
  otpPassword: string
}

interface ISmsLogin extends UserLogin {
  smsPassword: string
}

/* oauth 用户认证中心 认证 */
export const needOauthCertification = () => {

  fetchGet('/user/login/oauth2Login')
}

/* otp用户登录 */
export const otpLogin_api = async (params: IOtpLogin) => {
  /** SM2|RSA 加密 */
  const getKey = await getTransmissionPublicKey()
  params.password = setEncrypt({ algorithm: getKey?.algorithm, publicKey: getKey?.publicKey, password: params.password })
  params.otpPassword = setEncrypt({ algorithm: getKey?.algorithm, publicKey: getKey?.publicKey, password: params.otpPassword })
  // const { algorithm, publicKey } = store.getState().encryption
  // params.password = btoa(unescape(encodeURIComponent(params.password)))
  // params.otpPassword = btoa(unescape(encodeURIComponent(params.otpPassword)))
  return fetchPost('/user/login/otpLogin', params)
}

/* opt 判断用户是否需要绑定 */
export const needOtpBind_api = async (params: UserLogin) => {
  /** SM2|RSA 加密 */
  const getKey = await getTransmissionPublicKey()
  params.password = setEncrypt({ algorithm: getKey?.algorithm, publicKey: getKey?.publicKey, password: params.password })
  // const { algorithm, publicKey } = store.getState().encryption
  // params.password = btoa(unescape(encodeURIComponent(params.password)))
  return fetchPost('/user/login/otpBind', params)
}

/** 忘记密码（验证账号） */
export const verifyUserId = (params: {
  userId: string
  verificationCode: string
}) => {
  return fetchPost(`/user/login/password`, params)
}

/** 重新发送邮箱验证码 */
export const resendMailCode = (userId: string) => {
  return fetchPost(`/user/login/resend/code`, userId)
}

/** 验证邮箱验证码 */
export const verifyMail = ({
  code,
  userId,
}: {
  code: string
  userId: string
}) => {
  return fetchPost(`/user/login/code/${code}`, userId)
}

/** 发送重置密码链接的邮件 */
export const sendResetMail = (params: { address: string; userId: string }) => {
  return fetchPost(`/user/login/mail/password`, params)
}

/** 重置密码(通过发送到用户邮箱中的链接) */
export const changePasswordByLink = (params: {
  newPassword: string
  key: string
}) => {
  return fetchPost(`/user/login/reset/password`, params)
}

/** 获取图片验证码 */
export const getCaptchaImg = async () => {
  const response = await fetchWithErrorHandler(`/user/login/getVerifyCodeImg`)
  const blob = await response.blob()
  return URL.createObjectURL(blob)
}

/**
 * 根据邮箱收到的链接上的 code 参数，获取账号
 * 如果账号为 null，则该链接无效
 */
export const getUserIdByMailCode = (key: string) => {
  return fetchGet(`/user/login/key/${key}`)
}

/** cas 登录,通过重定向携带 ticket 获取用户信息 */
export const getUserInfoByTicket = (ticket: string) => {
  return fetchGet(`user/login/casValidation${ticket}`)
}

/** oauth 登录,通过重定向携带 ticket 获取用户信息 */
export const getOauithCertifications = (code: any) => {
  return fetchGet(`/user/login/oauth2Login${code}`)
}

/** oauth 登录,通过重定向携带 ticket 获取用户信息 */
export const getUserInfoByOauthCode = (code: any) => {
  return fetchGet(`/user/login/createOauth2AccessToken`, code)
}


interface ISpecialLoginType {
  casLogin: boolean
  adLogin: boolean
  openLdapLogin: boolean
  oauthLogin: boolean
}

/** 获取特殊登录类型 */
export const getSpecialLoginType = (): Promise<ISpecialLoginType> => {
  return fetchGet(`/user/sys/loginType`)
}
/** AD 登录 */
export const adLoginPost = async (params: UserLogin): Promise<UserEntity> => {
  /** SM2|RSA 加密 */
  const getKey = await getTransmissionPublicKey()
  params.password = setEncrypt({ algorithm: getKey?.algorithm, publicKey: getKey?.publicKey, password: params.password })
  // const { algorithm, publicKey } = store.getState().encryption
  /* 密码加密 */
  // params.password = btoa(unescape(encodeURIComponent(params.password)))
  return fetchPost('/user/login/adDirect', params)
}

/* OpenLdap登录 */
export const OpenLdaptLoginPost = async (params: UserLogin): Promise<UserEntity> => {
  /** SM2|RSA 加密 */
  const getKey = await getTransmissionPublicKey()
  params.password = setEncrypt({ algorithm: getKey?.algorithm, publicKey: getKey?.publicKey, password: params.password })
  // const { algorithm, publicKey } = store.getState().encryption
  /* 密码加密 */
  // params.password = btoa(unescape(encodeURIComponent(params.password)))
  return fetchPost('/user/login/openLdap', params)
}

/** 加密算法 获取公钥 RSA|SM2 */
export const getTransmissionPublicKey = (): Promise<IEncryptKeys> => {
  return fetchGet('/user/sys/transmission/publicKey')
}

/* sms用户登录 */
export const smsLogin_api = async (params: ISmsLogin) => {
  /** SM2|RSA 加密 */
  const getKey = await getTransmissionPublicKey()
  params.password = setEncrypt({ algorithm: getKey?.algorithm, publicKey: getKey?.publicKey, password: params.password })
  params.smsPassword = setEncrypt({ algorithm: getKey?.algorithm, publicKey: getKey?.publicKey, password: params.smsPassword })
  return fetchPost('/user/login/check', params)
}

/** 获取企微配置信息 */
export const getWwLoginConfig = (): Promise<IWwLoginInfo> => {
  return fetchGet('/user/sys/wwLoginConfig')
}

/** 企微登录 */
export const wwLogin = (code: string): Promise<UserEntity> => {
  return fetchGet(`/user/login/wwLogin?code=${code}`)
}

/** 轮询检测是否为唯一设备登录 */
export const getIsSingleLogin = (): Promise<any> => {
  return fetchGet(`/user/msg/detectionSingleDeviceLogin`)
}
