import { log } from 'console';
import { IconFontProps } from '@ant-design/icons/lib/components/IconFont'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DEFAULT_KEY } from './constant'
import { ElementNodeProps } from './SdtTree'

export interface TreeNode {
  key: string
  value: string
  title: string
  props: ElementNodeProps & { id: number | null }
  icon?: React.FC<IconFontProps<string>>
  isLeaf: boolean
  nodeType: string
  nodeName: string
  id: string | number
  nodePathWithType: string | null
  nodePath: string
  sdt?: any
  connection: {
    connectionType: string
  },
  connectionType?: string
  newNodeType?: string;
}

interface InitialState {
  selectedNode: TreeNode | null
  activeTab: string
  columnType: string
  nodePathOfColumn: string
  importDesConfigModalVisible: boolean,
  desensRuleSettingObj: any;
  desensPolicySettingObj: any;
  desensitizedUserListObj: any;
  selectedNodePermissionInfo: {
    [key: string]: {
      modulePermissionObj: {
        isOnlyRead: boolean, 
        roleNameList: string[],
      };
      permissionSupportData: boolean;
    };
  };
}

const initialState: InitialState = {
  selectedNode: null,
  activeTab: DEFAULT_KEY,
  columnType: '',
  nodePathOfColumn: '',
  importDesConfigModalVisible: false,
  desensRuleSettingObj: {}, //编辑脱敏字段存储信息
  desensPolicySettingObj: {}, //脱敏字段 策略设置
  desensitizedUserListObj: {}, // 脱敏用户列表 设置
  selectedNodePermissionInfo: {}, // 数据保护，权限禁用前置 
}

export const dataProtectionSlice = createSlice({
  name: 'dataProtection',
  initialState,
  reducers: {
    setSelectedNode: (state, action: PayloadAction<TreeNode | null>) => {
      state.selectedNode = action.payload
    },
    setActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload
    },
    setColumnType: (state, action: PayloadAction<string>) => {
      state.columnType = action.payload
    },
    setNodePathOfColumn: (state, action: PayloadAction<string>) => {
      state.nodePathOfColumn = action.payload
    },
    setImportDesConfigModalVisible:  (state, action: PayloadAction<boolean>) => {
      state.importDesConfigModalVisible = action.payload
    },
    setDesensRuleSettingObj:(state, action: PayloadAction<any>) => {
      state.desensRuleSettingObj = action.payload
    },
    resetDesensRuleSettingObj:(state) => {
      state.desensRuleSettingObj = {}
    },
    //敏感用户列表
    setDesensitizedUserListObj:(state, action: PayloadAction<any>) => {
      state.desensitizedUserListObj = action.payload
    },
    resetDesensitizedUserListObj:(state) => {
      state.desensitizedUserListObj = {}
    },
    setDesensPolicySettingObj:(state, action: PayloadAction<any>) => {
      state.desensPolicySettingObj = action.payload
    },
    resetDesensPolicySettingObj:(state) => {
      state.desensPolicySettingObj = {}
    },
    setSelectedNodePermissionInfo: (state, action: PayloadAction<any>) => {
      state.selectedNodePermissionInfo = action.payload
    },
  },
})

export const dataProtectionReducer = dataProtectionSlice.reducer

export const {
  setSelectedNode,
  setActiveTab,
  setColumnType,
  setNodePathOfColumn,
  setImportDesConfigModalVisible,
  setDesensRuleSettingObj,
  resetDesensRuleSettingObj,
  setDesensPolicySettingObj,
  resetDesensPolicySettingObj,
  setDesensitizedUserListObj,
  resetDesensitizedUserListObj,
  setSelectedNodePermissionInfo
} = dataProtectionSlice.actions
