import {
  NodeEntity,
  SdtNodeType
} from 'src/api'
import { DataSourceType, DataSourceTypes } from 'src/types/types'

/* nodepath 拼接规则 */
const DEFAULT_PATH_RULE = {
  connection: 1,
  database: 2,
  table: 4,
}
const WITHSCHEMA_PATH_RULE = {
  connection: 1,
  database: 2,
  schema: 3,
  table: 5,
}

const DAMENG_PATH_RULE = {
  connection: 1,
  schema: 2,
  table: 3,
}

/* 数据源结构类型 */
const STRUTURE_TYPE = ['connection', 'database', 'schema', 'table'] as const

type IStrutureType = typeof STRUTURE_TYPE[number]

type IStrutureTypeLevelMap = {
  [key in IStrutureType]: number
}

/* 数据源结构类型分层映射,fallback为DEFAULT_PATH_RULE */
const DATASOURCE_STRUTURE_MAP: Record<
  DataSourceType,
  Partial<IStrutureTypeLevelMap>
> = {
  PostgreSQL: WITHSCHEMA_PATH_RULE,
  SQLServer: { ...DEFAULT_PATH_RULE, table: 5 },
  DamengDB: DAMENG_PATH_RULE,
  GaussDB: WITHSCHEMA_PATH_RULE,
  OpenGauss: WITHSCHEMA_PATH_RULE,
  MariaDB: DEFAULT_PATH_RULE,
  MongoDB: DEFAULT_PATH_RULE,
  MySQL: DEFAULT_PATH_RULE,
  OceanBase: DEFAULT_PATH_RULE,
  OceanBaseMySQL: DEFAULT_PATH_RULE,
  Oracle: DEFAULT_PATH_RULE,
  OracleCDB: WITHSCHEMA_PATH_RULE,
  StarRocks: WITHSCHEMA_PATH_RULE,
  Other: DEFAULT_PATH_RULE,
  PolarDB: WITHSCHEMA_PATH_RULE,
  RDSMySQL: DEFAULT_PATH_RULE,
  Redis: DEFAULT_PATH_RULE,
  dble: DEFAULT_PATH_RULE,
  ShardingSphere: DEFAULT_PATH_RULE,
  Vertica: WITHSCHEMA_PATH_RULE,
  Trino: WITHSCHEMA_PATH_RULE,
  Impala: DEFAULT_PATH_RULE,
  Hive: DEFAULT_PATH_RULE,
  Inceptor: DEFAULT_PATH_RULE,
  DB2: DAMENG_PATH_RULE,
  MogDB: WITHSCHEMA_PATH_RULE,
  Phoenix: DEFAULT_PATH_RULE,
  GBase: DEFAULT_PATH_RULE,
  KingBasePG: WITHSCHEMA_PATH_RULE,
  KingBaseOracle: WITHSCHEMA_PATH_RULE,
  Oscar: DEFAULT_PATH_RULE,
  TDSQL: DEFAULT_PATH_RULE,
  HANA: DEFAULT_PATH_RULE,
  TeleDB: DEFAULT_PATH_RULE,
  GaussDB_DWS: WITHSCHEMA_PATH_RULE,
  HighGo: WITHSCHEMA_PATH_RULE,
  Greenplum: WITHSCHEMA_PATH_RULE,
  TiDB: DEFAULT_PATH_RULE,
  TDSQLPG: WITHSCHEMA_PATH_RULE,
  GBase8a: DEFAULT_PATH_RULE,
  GBase8c: WITHSCHEMA_PATH_RULE,
  GoldenDB: DEFAULT_PATH_RULE,
}

export const getInfoFromPath = (
  // todo: path 换为完整 node, 在内部而非传 connectionType 和 nodeType 参数
  path: string = '',
  nodeType: SdtNodeType,
  connectionType?: DataSourceType,
): string => {
  let dataSourcePath
  if (path.includes('g-')) {
    const reg = /(?:g-\w+)(\/[^g-].*)/g
    const regResult = reg.exec(path)?.[1]
    if (!regResult) return '解析失败'
    dataSourcePath = regResult
  } else {
    dataSourcePath = path.replace('/root', '')
  }
  const infoList = dataSourcePath.split('/')

  let index = DATASOURCE_STRUTURE_MAP[connectionType || 'MySQL'][
    nodeType as IStrutureType
  ] as number
  return infoList[index]
}

export const getNodeParams = (node: Partial<NodeEntity>) => {
  const { connectionType, connectionId, nodeType, nodeName, nodePath, nodePathWithType } = node
  return { connectionId, connectionType, nodeName, nodePath, nodeType, nodePathWithType }
}

const UpperCaseConnectionTypeMap = new Map<string, DataSourceType>(
  // 将大写类型转为 DataSourceType
  DataSourceTypes?.map((type) => [type?.toUpperCase(), type]),
)
export const normalizeConnectionType = (type: string): DataSourceType => {
  if (DataSourceTypes?.some((normalizedType) => normalizedType === type))
    return type as DataSourceType
    console.log("type",type)
  return UpperCaseConnectionTypeMap.get(type) || 'Other'
}

export const findTargetTreeNode = (
  nodePath: string | number,
  treeData: any,
) => {
  const queue = [...treeData]
  while (true) {
    const currNode = queue.shift()
    if (!currNode) return
    if (currNode.nodePath === nodePath) {
      return currNode
    }
    if (currNode.children && currNode.children[0]) {
      queue.push(...currNode.children)
    }
  }
}

interface IGetOperatingObjectProps {
  info: {
    databaseName?: string
    schemaName?: string
    [props: string]: any
  }
  connectionType: DataSourceType
}

/* operatingobject：执行需要的连接信息最后一层，pg&polardb 为schema，默认为 database  */
export const getOperatingObject = (
  info: IGetOperatingObjectProps['info'],
  connectionType: IGetOperatingObjectProps['connectionType'],
) => {
  if (!connectionType) return info['databaseName']
  const datasourceStruture = DATASOURCE_STRUTURE_MAP[connectionType]
  if ('schema' in datasourceStruture) {
    return info['schemaName']
  }
  return info['databaseName']
}

//根据nodePath规则 获取schemaName值 或者使用其他数据源结构
export const getSchemaFormConnectionType = (connectionType: DataSourceType, nodePath: string) => {
  if (!connectionType || !nodePath) return;
  switch (connectionType) {
    case 'SQLServer':
    case 'PostgreSQL':
    case 'KingBasePG':
    case 'KingBaseOracle':
    case 'PolarDB':
    case 'MogDB':
    case 'GaussDB_DWS':
    case 'HighGo':
    case 'Greenplum':
    case 'TDSQLPG':
    case 'GaussDB':
    case 'OracleCDB':
    case 'StarRocks':
    case 'OpenGauss':
    case 'OceanBaseMySQL':
    case 'Vertica':
    case 'GBase8c':
    case "Trino":
      return getInfoFromPath(nodePath, 'schema', 'PostgreSQL')
    case 'DamengDB':
    case 'OceanBase':
    case 'Oracle':
    case 'MySQL':
    case 'DB2':
    case 'OceanBaseMySQL':
    case 'HANA':
    case 'GBase':
    case "Oscar":
    case "Hive":
    case "Inceptor":
    case "Impala":
    case 'GBase8a':
    case 'GoldenDB':
      return getInfoFromPath(nodePath, 'schema', 'DamengDB')
    default:
      return getInfoFromPath(nodePath, 'connection', connectionType)
  }
}

//根据nodePath规则 获取table name值 或者使用其他数据源table所在位置
export const getTableNameFormConnectionType = (connectionType: DataSourceType, nodePath: string) => {
  if (!connectionType || !nodePath) return;
  switch (connectionType) {
    case 'SQLServer':
    case 'PostgreSQL':
    case 'PolarDB':
    case 'MogDB':
      return getInfoFromPath(nodePath, 'table', 'PostgreSQL')
    case 'DamengDB':
    case 'DB2':
      return getInfoFromPath(nodePath, 'table', 'Oracle')
    default:
      return getInfoFromPath(nodePath, 'table', connectionType)
  }
}