import React from "react";
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Card, Button, Badge, Tooltip } from "antd";

import styles from './index.module.scss';
import { useHistory } from "react-router-dom";

export const EmptyTipContent = ({
  cartNumber
}: {
  cartNumber: number
}) => {

  const history = useHistory();

  return (
    <Card
      title={<div className={styles.emptyTipTitle}>
        <div>选择权限</div>
        <Tooltip title='资源数量以schema数量为准'>
          <Badge count={cartNumber} showZero size="small" className="mr20">
            <Button
              type="primary"
              icon={<ShoppingCartOutlined />}
              onClick={() => history.push('/mine_apply/application')}
            >
              申请清单
            </Button>
          </Badge>
        </Tooltip>
      </div>}
      bordered={false}
      headStyle={{ borderBottom: "1px solid #EBEEF6" }}
      className={styles.emptyTipCard}
    >
      <div className={styles.emptyContent}>
        请在左侧勾选需要批量的资源
      </div>
    </Card>
  )
}