import React from 'react'
import { Button, Card, Modal, message } from 'antd'
import { CopyOutlined } from "@ant-design/icons";
import classnames from 'classnames'
import styles from '../index.module.scss'
import { VerticalAlignBottomOutlined } from '@ant-design/icons'
import { fetchGet } from 'src/api/customFetch'
import copy from "copy-to-clipboard";

interface IProps {
  uploadFileData: {
    fileName: string,
    taskId: number;
    taskStatus: 'DOWNLOADED' | 'SUCCESS';
    errorLog?: string;
  }
}

export const UploadFilePag = (props: IProps) => {
  const { uploadFileData } = props
  const { taskId, fileName } = uploadFileData

  const copyMet = (mes: string) => {
    copy(mes);
    message.success("复制成功");
  };

  // 下载附件
  const downloadFile = (taskId: number) => {
    fetchGet(`/export/export/check/encrypt/${taskId}`).then((res: any)=>{
      const { whetherToEncrypt, fileSecretKey } = res || {}
      if(whetherToEncrypt){
        Modal.info({
          width: 520,
          content: (
            <div>
              <div style={{ fontSize: '18px', marginBottom:'30px'}}>文件密钥</div>
              导出文件已加密 , 密钥为:
              <span style={{ fontWeight: "bold" }}>
                {fileSecretKey}
              </span>
              <CopyOutlined
                style={{ color: "#0c2dc7" }}
                onClick={() => {
                  copyMet(fileSecretKey);
                }}
              />
            </div>
          ),
          icon: null,
          onOk: () => {
            window.open(`/export/export/download/${taskId}`, '_self')
          }
        });
      }else {
        window.open(`/export/export/download/${taskId}`, '_self')
      }
    }).catch((err: any)=>{console.error(err)})


    
  }

  return (
    <Card
      title="附件信息"
      className={classnames(styles.borderShow, styles.mt27, styles.mb27)}
    >
      {
        ['DOWNLOADED', 'SUCCESS'].includes(uploadFileData?.taskStatus) ?
          <Button
            type="link"
            onClick={() => downloadFile(taskId)}
          >
            {fileName}<VerticalAlignBottomOutlined />
          </Button>
          : `导出异常：${uploadFileData?.errorLog}`
      }
    </Card>
  )
}
