/**
 * 将数组转换为 一行两个元素 的字符串
 * @param 一维数组
 * @returns 字符串
 */
export function setStr (arr: any) {
  let result: string = ''
  if (!!arr) {
    for (let i = 0; i < arr.length; i += 2) {
      const element1 = arr[i];
      const element2 = arr[i+1];
      if (element1 && element2) {
        result += `${element1}, ${element2}, \n`;
      } else {
        result += `${element1}\n`;
      }
    }
  }
  return result
}
