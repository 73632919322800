/**
 * 选择管理员列表弹框
 */
import React, { useState, useEffect } from 'react'
import { Modal, message, Tree, Tooltip, Input, Row, Col, Radio, Form, Button } from 'antd'
import { useRequest, useSelector } from "src/hook";
import {
  queryConnectionManageInfo,
  queryManageList,
  updateManageInfo,
  queryGroupManage,
  updateGroupManage,
} from 'src/api'
import classnames from 'classnames'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Iconfont } from 'src/components'
import {flatData, treeNodeAdapter} from './utils'
import styles from './index.module.scss'

interface PropsI {
  groupId?: string
  connectionId?: string
  [p: string]: any
}

const ChooseManage = (props: PropsI) => {
  const { groupId, connectionId, formProps, permissionlist, canEdit, operationType } = props
  const { roleNameList } = permissionlist || {}
  const [visible, setVisible] = useState(false)
  const [manageInfo, setManageInfo] = useState<any>({})

  const [selectedKeys, setSelectKeys] = useState<any[]>([]);
  const [checkedUserInfo, setCheckedUserInfo] = useState<any[]>([]);
  const [searchUserList, setSearchUserList] = useState<any[]>([]);
  const [inputCurVal, setInputCurVal] = useState<any>();
  
  const { roles=[], userId } = useSelector((state) => state.login.userInfo);

  // 可以更改的管理员信息
  const { data: treeData=[] } = useRequest(queryManageList, {
      formatResult:(res: any[])=>{
        return treeNodeAdapter(res)
      }
    }
  )
  
  // 查询组管理员信息
  useEffect(() => {
    if (connectionId || groupId) {
      queryManage()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionId, groupId])

  const queryManage = () => {
    if (connectionId) {
      queryConnectionManageInfo(connectionId)
        .then((i: any) => {
          setManageInfo(i)
        })
        .catch()
    } else if (groupId) {
      queryGroupManage(groupId)
        .then((i: any) => {
          setManageInfo(i)
        })
        .catch()
    }
  }

  // 管理员更改
  const handleOk = () => {
    const userId = checkedUserInfo[0]?.userId
    if ((operationType && operationType === "connectionConfig")) {
      setInputCurVal({
        userId: userId,
        userName: checkedUserInfo[0]?.userName
      })
      setVisible(false)
      return 
    }
    const fn = connectionId ? updateManageInfo : updateGroupManage
    const params: any = connectionId
      ? { connectionId, userId }
      : { groupId, userId }
    fn(params)
      .then((_: any) => {
        message.success('管理员更新成功')
        setVisible(false)
        queryManage()
      })
      .catch((err: any) => {
        console.error('管理员更新失败', err)
      })
  }

  //  tree select
  const handleSelect = (_: any, e: any) => {
    const {name, id, key, type} = e?.node
    if(type==='USER'){
      const userInfo = [{
        userName: name,
        userId: id,
        key
      }]
      setSelectKeys([key])
      setCheckedUserInfo(userInfo)
    }
  }

  // 搜索
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value?.trim();
    const searchUserList = flatData(treeData).map((item: any) => {
      const {id, type, name, key, path } = item
      if (value && name?.includes(value) && type === 'USER') {
        return {
          id,
          name,
          key,
          path
        };
      }
      return null
    }).filter(i=>i).filter((i,index, self)=>self.findIndex((j:any)=>j?.key===i?.key)===index)
    setSearchUserList(searchUserList)
    setSelectKeys([]);
    setCheckedUserInfo([]);
  }

  const handleRadioChange = (e: any) => {
    const value = e.target.value
    const checkedUsers = flatData(treeData)
      ?.filter((i: any) => value === i?.key)
      ?.map((i: any) => {
        return {
          userName: i?.name,
          userId: i?.id,
          key: i?.key
        };
      });
    setCheckedUserInfo(checkedUsers);
    setSelectKeys([value]);
  }

  // 删除选择的用户
  const handleCloseItem = () => {
    setCheckedUserInfo([])
    setSelectKeys([])
  }

  const titleRender = (node: any) => {
    const { type, title } = node
    let icon = null
    switch (type) {
      case 'COMPANY':
        icon = <Iconfont type="icon-org-company" />
        break
      case 'SUB_COMPANY':
        icon = <Iconfont type="icon-org-company" />
        break
      case 'DEPT':
        icon = <Iconfont type="icon-org-department" />
        break
      case 'GROUP':
        icon = <Iconfont type="icon-org-group" />
        break
      case 'USER':
        icon = <Iconfont type="icon-org-user" />
        break
      default:
        break
    }
    const showTitle =
      title?.length > 15 ? (
        <Tooltip title={title}>{title.substring(0, 15) + '...'}</Tooltip>
      ) : (
        title
      )
    return (
      <>
        {icon}&nbsp;{showTitle}
      </>
    )
  }

  useEffect(() => {
    const name = groupId ? manageInfo?.managerName : manageInfo?.userName;
    const id = groupId ? manageInfo?.managerId : manageInfo?.userId;
    setInputCurVal({
      userId: id,
      userName: name
    });
  }, [groupId, manageInfo?.managerName, manageInfo?.userName])


  const renderForm = () => {
    return (
      <div>
        {formProps ? 
          <Form {...formProps}>
            <Form.Item
              label={groupId ? "组管理员" : " 连接管理员"}
            >
              <>
                {groupId ? manageInfo?.managerName : manageInfo?.userName}
                {
                  canEdit
                  ? <Button
                      type='link'
                      className={classnames(styles.options, styles.ml10, styles.padding0)}
                      onClick={() => setVisible(true)}
                    >
                      更改
                    </Button>
                    : <Tooltip title={`您当前的角色是[${roleNameList?.join(',')}]，对[连接管理]没有操作权限`}>
                        <Button
                          type='link'
                          className={classnames(styles.options, styles.ml10, styles.padding0)}
                          disabled={true}
                        >
                          更改
                        </Button>
                      </Tooltip>
                }
              </>
            </Form.Item>
          </Form>
          :
          <>
            {groupId ? "组管理员" : " 连接管理员"}
            :&nbsp;{groupId ? manageInfo?.managerName : manageInfo?.userName}
            {
              canEdit
              ? <Button
                  type='link'
                  className={classnames(styles.options, styles.ml10, styles.padding0)}
                  onClick={() => setVisible(true)}
                >
                  更改
                </Button>
              : <Tooltip title={`您当前的角色是[${roleNameList?.join(',')}]，对[连接管理]没有操作权限`}>
                  <Button
                  type='link'
                  className={classnames(styles.options, styles.ml10, styles.padding0)}
                  disabled={true}
                >
                  更改
                </Button>
              </Tooltip> 
            }
          </>
        }
        
      </div>
    )
  }

  const layoutTmp = { 
    labelCol: { span: 5 },
    wrapperCol: { span: 7 }
  }

  useEffect(() => {
    if(props?.form) {
      var thisForm = props?.form;
      thisForm.setFieldsValue({ connectionManager: inputCurVal?.userId || '' }) // 手动更新表单项的值
    }
  }, [inputCurVal, props?.form])

  const renderFormItems = () => {
    return (
      <div>
        <Form.Item
          label={groupId ? "组管理员" : " 连接管理员"}
          {...layoutTmp}
          name={'connectionManager'} 
          key={'connectionManager'} 
          getValueProps={() => ({})}
        >
          <Input
            autoComplete="off"
            placeholder={`请输入选择连接管理员`}
            onClick={() => setVisible(true)}
            value={inputCurVal?.userName}
          />
        </Form.Item>
      </div>
    )
  }

  return (
    <>
      {
        (operationType && operationType === "connectionConfig") ? renderFormItems() : renderForm()
      }
      <Modal
        width={620}
        visible={visible}
        title="请选择"
        onCancel={() => setVisible(false)}
        onOk={handleOk}
        destroyOnClose
        className={styles.chooseManageModal}
      >
        <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.title}>组织架构</div>
          <Input.Search
            style={{ width: 280, marginBottom: 8 }}
            onChange={handleSearch}
          />
          {!searchUserList?.length && !!treeData?.length && (
            <Tree
              className={styles.treeWrap}
              titleRender={titleRender}
              treeData={treeData}
              onSelect={handleSelect}
              selectedKeys={selectedKeys}
            />
          )}
          {/* 搜索用户列表 */}
          {!!searchUserList?.length && (
            <div className={styles.searchUserListWrap}>
              <Radio.Group
                style={{ width: "100%" }}
                value={selectedKeys?.[0]}
                onChange={handleRadioChange}
              >
                {searchUserList?.map((item) => {
                  return (
                    <Row key={item?.key} style={{margin:'8px 0'}}>
                      <Col span={24}>
                        <Radio value={item?.key} className={styles.flexItem}>
                          <span className={styles.flexItem}>
                            <Iconfont
                              type="icon-org-user"
                              style={{marginRight: '8px'}}
                            />
                            <span>
                              <div>{`${item?.name}(${item?.id})`}</div>
                              <div>{item?.path}</div>
                            </span>
                          </span>
                        </Radio>
                      </Col>
                    </Row>
                  );
                })}
              </Radio.Group>
            </div>
          )}
        </div>
        <div className={styles.right}>
          {!!checkedUserInfo?.length && (
            <div
              className={styles.title}
            >{`已选择用户`}</div>
          )}
          <div className={styles.userWrap}>
            {checkedUserInfo?.map((item: any) => {
              const renderUser =
                item?.userName?.length > 12 ? (
                  <Tooltip title={item?.userName}>
                    {item?.userName.substring(0, 12) + "..."}
                  </Tooltip>
                ) : (
                  item?.userName
                );
              return (
                <div className={styles.selectItem} key={item.key}>
                  {renderUser}
                  <CloseCircleOutlined
                    onClick={() => handleCloseItem()}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      </Modal>
    </>
  );
}
export default ChooseManage
