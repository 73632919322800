import React, { useState, useRef, useEffect, useReducer } from 'react'
import ReactDOM from 'react-dom'
import { useSelector, useRequest, useDispatch } from 'src/hook'
import { Button, Space, Form, Steps, message } from 'antd'
import {
  TextTypeForm,
  FilePathForm,
  SeparateSettingForm,
  SettingTwoForm,
  SelectTargetTableForm,
  FieldMapForm,
} from '../forms/textImportForms'
import { LazyWrapper } from 'src/components'
import { postTextImport } from 'src/api'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import {
  setFilePath,
  setFileType,
  setTargetTable,
  setFileCode,
  setTargetTableList,
  setTargetTableMessage,
  setSourceTableFieldRequest,
  setTableFieldMap,
} from 'src/store/extraSlice/textImportSlice'
import styles from './textWizard.module.scss'

enum WizardStep {
  setTextType = 0,
  setFilePath = 1,
  setSeparate = 2,
  setTwo = 3,
  selectTarget = 4,
  setFieldMap = 5,
}

export const TextImportWizard = ({
  submitterRef,
}: {
  submitterRef: React.RefObject<HTMLElement>
}) => {
  const dispatch = useDispatch()
  const [current, setCurrent] = useState<WizardStep>(WizardStep.setTextType)
  const [targetFields, setTargetFields] = useState<string>('')
  const furthestStepRef = useRef<WizardStep>(WizardStep.setTextType)

  const { fileType, filePath, targetTableMessage, tableFieldMap } = useSelector(
    (state) => state.textImport,
  )

  // !HACK
  const [, forceUpdate] = useReducer((r) => r + 1, 0)
  useEffect(() => {
    forceUpdate()
  }, [])

  useEffect(() => {
    const furthestStep = furthestStepRef.current
    furthestStepRef.current = current > furthestStep ? current : furthestStep
  }, [current])

  const [typeForm] = Form.useForm()
  const [pathForm] = Form.useForm()
  const [separateForm] = Form.useForm()
  const [s2Form] = Form.useForm()
  const [targetForm] = Form.useForm()
  const [mapForm] = Form.useForm()

  const steps = [
    {
      title: '选择文件类型',
      content: <TextTypeForm form={typeForm} />,
    },
    {
      title: '选择文件及编码',
      content: <FilePathForm form={pathForm} />,
    },
    {
      title: '分隔符配置',
      content: <SeparateSettingForm form={separateForm} />,
    },
    {
      title: '格式配置',
      content: <SettingTwoForm form={s2Form} />,
    },
    {
      title: '选择目标表',
      content: <SelectTargetTableForm form={targetForm} />,
    },
    {
      title: '目标表和字段对应关系',
      content: (
        <FieldMapForm form={mapForm} targetFields={targetFields || '空'} />
      ),
    },
  ]

  useEffect(() => {
    const { targetTable } = targetForm.getFieldsValue()
    if (targetTable) {
      setTargetFields(targetTable)
    }
  }, [targetForm])

  // 调用导入接口
  const { loading: loadingImport, run: runTextImport } = useRequest(
    postTextImport,
    {
      manual: true,
      onSuccess: () => {
        message.success('添加成功')
        dispatch(hideModal('ModalTextImportWizard'))
        // 重置表单
        typeForm.resetFields()
        pathForm.resetFields()
        separateForm.resetFields()
        s2Form.resetFields()
        targetForm.resetFields()
        mapForm.resetFields()
        // 某些表单值存在redux中也要重置
        dispatch(setFilePath(''))
        dispatch(setFileType('txt'))
        dispatch(setTargetTable(''))
        dispatch(setFileCode(''))
        dispatch(setTargetTableList([]))
        dispatch(setTargetTableMessage(null))
        dispatch(setSourceTableFieldRequest(null))
        dispatch(setTableFieldMap([{
          columnName: null,
          columnTypeName: null,
          index: null,
          sourceColumnName: null,
        }]))
      },
    },
  )

  // 开始导入
  function handleConfirmWizard() {
    const pathFormValues = pathForm.getFieldsValue()
    const separateFormValues = separateForm.getFieldsValue()
    const s2FormValues = s2Form.getFieldsValue()
    const mapFormValues = mapForm.getFieldsValue()

    const isNull = tableFieldMap?.some(t => (t.columnName === null || t.index === null))
    if (isNull) {
      return message.error('源字段和目标字段不能为空')
    }
    // 检测目标字段是否重复
    const targetListArr = tableFieldMap?.filter(t => t.columnName !== null).map(t => t.columnName)
    const noRepeatArr =  Array.from(new Set(targetListArr))
    if(targetListArr && targetListArr.length > noRepeatArr.length) {
      return message.error('目标字段不能相同')
    }

    const {dateSort, dateTimeSort } = s2FormValues
    const params = {
      charsetName: pathFormValues.codeType,
      columnMap: tableFieldMap!,
      dataStartLine: s2FormValues.firstRow,
      dataEndLine: s2FormValues.lastRow,
      dateDelimiter: s2FormValues.dateSeparator,
      dateSort,
      dateTimeSort,
      decimalPointSymbol: s2FormValues.decimalDot,
      delimiter: separateFormValues.fieldSeparator,
      encodeType: s2FormValues.binaryCode,
      fieldNameLine: s2FormValues.fieldRow,
      filePath: filePath!,
      fileType: fileType!,
      lineBreak: separateFormValues.rowSeparator,
      quote: separateFormValues.fieldRecognize,
      stopOnError: mapFormValues.isContinue,
      timeDelimiter: s2FormValues.timeSeparator,
      ...targetTableMessage!
    }
    runTextImport(params)
  }

  // 下一步按钮
  const submitter = (
    <Space>
      <Button
        className={styles.submitterBtn}
        onClick={() => {
          if (
            current >= WizardStep.setTwo &&
            current < WizardStep.selectTarget &&
            (fileType === 'xls' || fileType === 'xlsx')
          ) {
            setCurrent((current) => current - 1)
          }
          setCurrent((current) =>
            current > WizardStep.setTextType ? current - 1 : current,
          )
        }}
        disabled={current <= WizardStep.setTextType}
      >
        上一步
      </Button>
      {current < WizardStep.setFieldMap ? (
        <Button
          className={styles.submitterBtn}
          onClick={async () => {
            // try {
            if (
              current >= WizardStep.setFilePath &&
              current < WizardStep.setSeparate
            ) {
              await pathForm.validateFields()
              if (fileType === 'xls' || fileType === 'xlsx') {
                setCurrent((current) => current + 1)
              }
            } else if (
              current >= WizardStep.setSeparate &&
              current < WizardStep.setTwo
            ) {
              await separateForm.validateFields()
            } else if (
              current >= WizardStep.setTwo &&
              current < WizardStep.selectTarget
            ) {
              await s2Form.validateFields()
              const pathFormValues = pathForm.getFieldsValue()
              const separateFormValues = separateForm.getFieldsValue()
              const s2FormValues = s2Form.getFieldsValue()
              const {dateSort, dateTimeSort } = s2FormValues
              dispatch(
                setSourceTableFieldRequest({
                  charsetName: pathFormValues.codeType,
                  delimiter: separateFormValues.fieldSeparator,
                  fieldNameLine: s2FormValues.fieldRow,
                  filePath: filePath!,
                  fileType: fileType!,
                  lineBreak: separateFormValues.rowSeparator,
                  quote: separateFormValues.fieldRecognize,
                  dateDelimiter: s2FormValues.dateSeparator,
                  dateSort,
                  dateTimeSort,
                  decimalPointSymbol: s2FormValues.decimalDot,
                  timeDelimiter: s2FormValues.timeSeparator
                }),
              )
            } else {
              await targetForm.validateFields()
            }
            
            setCurrent((current) =>
              current < WizardStep.setFieldMap ? current + 1 : current,
            )
            // } catch {}
          }}
        >
          下一步
        </Button>
      ) : (
        <Button
          className={styles.submitterBtn}
          type="primary"
          onClick={() => handleConfirmWizard()}
          loading={loadingImport}
        >
          开始导入
        </Button>
      )}
    </Space>
  )

  return (
    <div className={styles.textImport}>
      <div>
        <Steps current={current} direction="vertical" size="small">
          {steps.map(({ title }, index) => (
            <Steps.Step title={title} key={index} />
          ))}
        </Steps>
      </div>
      <div>
        {steps.map(({ content }, index) => (
          <LazyWrapper
            className={styles.stepsForm}
            active={current === index}
            key={index}
            style={{ overflow: 'auto' }}
          >
            {content}
          </LazyWrapper>
        ))}
        {submitterRef.current &&
          ReactDOM.createPortal(submitter, submitterRef.current)}
      </div>
    </div>
  )
}
