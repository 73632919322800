import { fetchGet, fetchPost } from '../customFetch'

export interface IControlRecordListParams {
  pageNum: number;
  pageSize: number;
  searchFilter1?: string;
  searchFilter2?: string;
}
export interface IControlRecordItem {
  id: number;
  name: string;
  clientType: string;
  ip: string;
  connectInstance: string;
  clientHost: string;
  managerState: boolean;
  loginTime: string;
  description: string;
}

export interface IControlRecordData {
  totalNum: number;
  managerRecords: IControlRecordItem[]
}

export const getControlRecordList = (
  params: IControlRecordListParams,
): Promise<IControlRecordData> => {
  return fetchPost(`/clientManager/record/getAllByPage`, params)
}

export interface IControlRecordDetail{
  basicInfo: any;
  connectionInfo: any;
}

//详情
export const getControlRecordDetail = (
  params: {id: IControlRecordDetail},
): Promise<any> => {
  return fetchGet(`/clientManager/record/queryDetails`, params)
}

