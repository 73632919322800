import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/store'
import { Modal } from 'antd'
import {
  userLogin,
  UserEntity,
  otpLogin_api,
  adLoginPost,
  getUserInfoByTicket,
  OpenLdaptLoginPost,
  userUkeyLogin,
  getCq369,
  twoStepSMSLogin,
  getLoginPurpose,
  getUserInfoByOauthCode,
  IWwLoginInfo,
  getWwLoginConfig,
  wwLogin,
  IPermissionList,
} from 'src/api'
import {
  setEditorPromptOnClose,
  setQueryTheme,
} from 'src/store/extraSlice/appearanceSlice'
import { storeRegister } from 'src/store/storeRegister'
import { showModal } from 'src/store/extraSlice/modalVisibleSlice'

type ILoginType = 'normal' | 'casLogin' | 'adLogin' | 'openLdapLogin' | 'OauthLogin'

interface LoginState {
  isLoggedIn: boolean
  userInfo: Partial<UserEntity>
  purposeConfirm: boolean
  redirectUrl: string
  loginType: ILoginType
  saveEditorContent?: boolean
  productGrade?: string
  hasIpAccess?: boolean
  wwLoginInfo?: IWwLoginInfo
  permissionList?: Partial<IPermissionList>
  visibleAlert?: boolean  // 编辑器执行行数提示
  isBase64Encode: boolean // 判断传sql时是否需要编码
}

const initialState: LoginState = {
  isLoggedIn: false,
  userInfo: {},
  purposeConfirm: false,
  redirectUrl: "/system_index",
  loginType: "normal",
  saveEditorContent: false,
  productGrade: "CQ3",
  // ip白名单访问控制
  hasIpAccess: true,
  // 企微登录信息
  wwLoginInfo: {
    enable: false,
    appId: '',
    agentId: '',
  },
  permissionList:{},
  visibleAlert: true,
  isBase64Encode: false,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUserInfo(state, action: PayloadAction<Partial<UserEntity>>) {
      state.userInfo = action.payload
    },
    setIsLoggedIn(state, action: PayloadAction<boolean>) {
      state.isLoggedIn = action.payload
    },
    setRedirectUrl(state, action: PayloadAction<string>) {
      state.redirectUrl = action.payload
    },
    setSpecialLoginType(state, action: PayloadAction<ILoginType>) {
      state.loginType = action.payload
    },
    setExitPrompt(state, action: PayloadAction<boolean>) {
      state.saveEditorContent = action.payload
    },
    setProductGrade(state, action: PayloadAction<string>) {
      state.productGrade = action.payload
    },
    setPurposeConfirm(state, action: PayloadAction<boolean>) {
      state.purposeConfirm = action.payload
    },
    setIpAccess(state, action: PayloadAction<boolean>) {
      state.hasIpAccess = action.payload
    },
    setWwLoginInfo(state, action: PayloadAction<IWwLoginInfo>) {
      state.wwLoginInfo = action.payload
    },
    setPermissionList(state, action: PayloadAction<IPermissionList>) {
      state.permissionList = action.payload
    },
    setVisibleAlert(state, action: PayloadAction<boolean>) {
      state.visibleAlert = action.payload
    },
    setIsBase64Encode(state, action: PayloadAction<boolean>) {
      state.isBase64Encode = action.payload
    },
  },
})

export const loginReducer = loginSlice.reducer

export const {
  setUserInfo,
  setIsLoggedIn,
  setRedirectUrl,
  setSpecialLoginType,
  setExitPrompt,
  setProductGrade,
  setPurposeConfirm,
  setIpAccess,
  setWwLoginInfo,
  setPermissionList,
  setVisibleAlert,
  setIsBase64Encode,
} = loginSlice.actions

const generatePermissionList = (info: UserEntity) => {
  const { roles, permissionMenus } = info || {};
  const res: any = {};
  const roleNameList = roles.map(item => item.roleName);
  const roleTypeList = roles.map(item => item.type);

  if (Array.isArray(permissionMenus)) {
    permissionMenus.forEach((item) => {
      const { menuType, items, accessMode } = item || {};
      res[menuType] = {
        isOnlyRead: accessMode === "READ_AUTHED",
      };
      if (Array.isArray(items) && items.length > 0) {
        items.forEach((i) => {
          const { permissionType, accessMode } = i || {};
          res[menuType][permissionType] = {};
          res[menuType][permissionType].isOnlyRead =
            accessMode === "READ_AUTHED";
          res[menuType][permissionType].roleNameList = roleNameList;
          res[menuType][permissionType].roleTypeList = roleTypeList;
        });
      } else {
        res[menuType].roleNameList = roleNameList;
        res[menuType].roleTypeList = roleTypeList;
      }
    });
  }
  return res;
}

//通用 登录前判断是否需要清空缓存信息
export const commonClearUserCache = (curUserInfo: any, getState: any, dispatch: any) => {
  //账号退出 再次登录人信息需要清空缓存 
  const preUserInfo = getState()?.login?.userInfo;
  const preWwLoginInfgo = getState()?.login?.wwLoginInfo;
  if (preUserInfo?.userId !== curUserInfo?.userId) {
    const persistor = storeRegister.getPersistor()
    persistor?.purge().then(() => {
      // 如果因为用户不同而清空了数据，企业微信信息仍然需要保存，这是独立与平常登录的信息，在登陆前就获取并存储，所以不需要清空
      dispatch(setWwLoginInfo(preWwLoginInfgo));
    })
  }
  //用户权限也会发生变化需要先清空
  dispatch(setUserInfo({}))
}

export const login =
  (params: {
    userId: string
    password: string
  }): AppThunk<Promise<UserEntity>> =>
    (dispatch, getState) => {
      return userLogin(params).then((userInfo) => {
        commonClearUserCache(userInfo, getState, dispatch);
        getCq369().then((data) => {
          const dataFormat = `CQ${data}`
          dispatch(setProductGrade(dataFormat))
        })
        const handleQueryTheme = () => {
          const { personalSettings, saveEditorContent } = userInfo || {}
          const { theme, editorPromptOnClose } = personalSettings || {}
          // 保存[登出]状态
          dispatch(setExitPrompt(saveEditorContent as boolean))
          dispatch(setQueryTheme(theme))
          dispatch(setEditorPromptOnClose(editorPromptOnClose))
        }
        const { warnings } = userInfo || {}
        if (warnings && warnings.length) {
          Modal.confirm({
            content: warnings[0],
            okText: '确认',
            cancelText: '取消',
            cancelButtonProps: { style: { display: 'none' } },
            onOk() {
              handleQueryTheme()
              dispatch(setIsLoggedIn(true))
              dispatch(setUserInfo(userInfo))
              dispatch(setPermissionList(generatePermissionList(userInfo)))
              dispatch(setVisibleAlert(true))
            },
            onCancel() {
              // logout()
            },
          })
        } else {
          handleQueryTheme()
          dispatch(setIsLoggedIn(true))
          dispatch(setUserInfo(userInfo))
          dispatch(setPermissionList(generatePermissionList(userInfo)))
          dispatch(setVisibleAlert(true))
        }
        // 是否需要填写登录目的
        getLoginPurpose().then((isNeeded) => {
          dispatch(setPurposeConfirm(!userInfo.firstLogin && isNeeded))
        })
        return userInfo
      })
    }

// 该方法是对上下文若干登录的函数共有的逻辑部分进行封装
// modal 挂载在login组件上，所以通过暴露的方法来给其内部调用
export const commonLoginProcess = (userInfo: UserEntity, dispatch: any) => {
  getCq369().then((data) => {
    const dataFormat = `CQ${data}`
    dispatch(setProductGrade(dataFormat))
  })
  const handleQueryTheme = () => {
    const { personalSettings, saveEditorContent } = userInfo || {}
    const { theme, editorPromptOnClose } = personalSettings || {}
    // 保存[登出]状态
    dispatch(setExitPrompt(saveEditorContent as boolean))
    dispatch(setQueryTheme(theme))
    dispatch(setEditorPromptOnClose(editorPromptOnClose))
  }
  const { warnings } = userInfo || {}
  if (warnings && warnings.length) {
    Modal.confirm({
      content: warnings[0],
      okText: '确认',
      cancelText: '取消',
      cancelButtonProps: { style: { display: 'none' } },
      onOk() {
        handleQueryTheme()
        dispatch(setIsLoggedIn(true))
        dispatch(setUserInfo(userInfo))
        dispatch(setPermissionList(generatePermissionList(userInfo)))
        dispatch(setVisibleAlert(true))
      },
      onCancel() {
        // logout()
      },
    })
  } else {
    handleQueryTheme()
    dispatch(setIsLoggedIn(true))
    dispatch(setUserInfo(userInfo))
    dispatch(setPermissionList(generatePermissionList(userInfo)))
    dispatch(setVisibleAlert(true))
  }
  handleQueryTheme()
  dispatch(setIsLoggedIn(true))
  dispatch(setUserInfo(userInfo))
  dispatch(setPermissionList(generatePermissionList(userInfo)))
  dispatch(setVisibleAlert(true))

  // 是否需要填写登录目的
  getLoginPurpose().then((isNeeded) => {
    dispatch(setPurposeConfirm(!userInfo.firstLogin && isNeeded))
  })
  
  return userInfo
}

export const smsTwoStepLogin =
  (params: {
    userId: string
    password: string
  }): AppThunk<Promise<any>> =>
    () => {
      const smsLoginFlows = async () => {
        const isAccountCorrect = await twoStepSMSLogin(params)
        if (!isAccountCorrect) {
          return Promise.reject('账号或密码错误')
        }
        // dispatch(showModal('TwoStepLoginBySMS'))
      }

      return smsLoginFlows()
    }

export const adLogin =
  (params: {
    userId: string
    password: string
  }): AppThunk<Promise<UserEntity>> =>
    (dispatch, getState) => {
      return adLoginPost(params).then((userInfo) => {
        commonClearUserCache(userInfo, getState, dispatch);
        getCq369().then((data) => {
          const dataFormat = `CQ${data}`
          dispatch(setProductGrade(dataFormat))
        })
        const handleQueryTheme = () => {
          const { personalSettings, createFrom, saveEditorContent } =
            userInfo || {}
          dispatch(setSpecialLoginType(createFrom as ILoginType))
          // 保存[登出]状态
          dispatch(setExitPrompt(saveEditorContent as boolean))
          const { theme, editorPromptOnClose } = personalSettings || {}
          dispatch(setQueryTheme(theme))
          dispatch(setEditorPromptOnClose(editorPromptOnClose))
        }
        handleQueryTheme()
        dispatch(setIsLoggedIn(true))
        dispatch(setUserInfo(userInfo))
        dispatch(setPermissionList(generatePermissionList(userInfo)))
        dispatch(setVisibleAlert(true))

        // 是否需要填写登录目的
        getLoginPurpose().then((isNeeded) => {
          dispatch(setPurposeConfirm(!userInfo.firstLogin && isNeeded))
        })

        return userInfo
      })
    }

/** OpenLdapt */
export const OpenLdaptLogin =
  (params: {
    userId: string
    password: string
  }): AppThunk<Promise<UserEntity>> =>
    (dispatch, getState) => {
      return OpenLdaptLoginPost(params).then((userInfo) => {
        commonClearUserCache(userInfo, getState, dispatch);
        getCq369().then((data) => {
          const dataFormat = `CQ${data}`
          dispatch(setProductGrade(dataFormat))
        })
        const handleQueryTheme = () => {
          const { personalSettings, createFrom, saveEditorContent } =
            userInfo || {}
          dispatch(setSpecialLoginType(createFrom as ILoginType))
          // 保存[登出]状态
          dispatch(setExitPrompt(saveEditorContent as boolean))
          const { theme, editorPromptOnClose } = personalSettings || {}
          dispatch(setQueryTheme(theme))
          dispatch(setEditorPromptOnClose(editorPromptOnClose))
        }
        handleQueryTheme()
        dispatch(setIsLoggedIn(true))
        dispatch(setUserInfo(userInfo))
        dispatch(setPermissionList(generatePermissionList(userInfo)))
        dispatch(setVisibleAlert(true))

        // 是否需要填写登录目的
        getLoginPurpose().then((isNeeded) => {
          dispatch(setPurposeConfirm(!userInfo.firstLogin && isNeeded))
        })

        return userInfo
      })
    }

export const otpLogin =
  (params: {
    userId: string
    password: string
    otpPassword: string
  }): AppThunk<Promise<UserEntity>> =>
    (dispatch) => {
      return otpLogin_api(params).then((userInfo: UserEntity) => {
        getCq369().then((data) => {
          const dataFormat = `CQ${data}`
          dispatch(setProductGrade(dataFormat))
        })
        const handleQueryTheme = () => {
          const { personalSettings, saveEditorContent } = userInfo || {}
          const { theme, editorPromptOnClose } = personalSettings || {}
          // 保存[登出]状态
          dispatch(setExitPrompt(saveEditorContent as boolean))
          dispatch(setQueryTheme(theme))
          dispatch(setEditorPromptOnClose(editorPromptOnClose))
        }

        const { warnings } = userInfo || {}
        if (warnings && warnings.length) {
          Modal.confirm({
            content: warnings[0],
            okText: '确认',
            cancelText: '取消',
            cancelButtonProps: { style: { display: 'none' } },
            onOk() {
              handleQueryTheme()
              dispatch(setIsLoggedIn(true))
              dispatch(setUserInfo(userInfo))
              dispatch(setPermissionList(generatePermissionList(userInfo)))
              dispatch(setVisibleAlert(true))
            },
            onCancel() {
              // logout()
            },
          })
        } else {
          handleQueryTheme()
          dispatch(setIsLoggedIn(true))
          dispatch(setUserInfo(userInfo))
          dispatch(setPermissionList(generatePermissionList(userInfo)))
          dispatch(setVisibleAlert(true))
        }

        // 是否需要填写登录目的
        getLoginPurpose().then((isNeeded) => {
          dispatch(setPurposeConfirm(!userInfo.firstLogin && isNeeded))
        })

        return userInfo
      })
    }
/** cas 登录跳转第三方页面自动登录 */
export const loginCas =
  (ticket: string): AppThunk<Promise<UserEntity>> =>
    (dispatch, getState) => {
      return getUserInfoByTicket(ticket)
        .then((userInfo) => {
          commonClearUserCache(userInfo, getState, dispatch);
          getCq369().then((data) => {
            const dataFormat = `CQ${data}`
            dispatch(setProductGrade(dataFormat))
          })
          const handleQueryTheme = () => {
            const { personalSettings, createFrom, saveEditorContent } =
              userInfo || {}
            dispatch(setSpecialLoginType(createFrom))
            // 保存[登出]状态
            dispatch(setExitPrompt(saveEditorContent as boolean))
            const { theme, editorPromptOnClose } = personalSettings || {}
            dispatch(setQueryTheme(theme))
            dispatch(setEditorPromptOnClose(editorPromptOnClose))
          }
          handleQueryTheme()
          dispatch(setIsLoggedIn(true))
          dispatch(setUserInfo(userInfo))
          dispatch(setPermissionList(generatePermissionList(userInfo)))
          dispatch(setVisibleAlert(true))

          // 是否需要填写登录目的
          getLoginPurpose().then((isNeeded) => {
            dispatch(setPurposeConfirm(!userInfo.firstLogin && isNeeded))
          })

          return userInfo
        })
        .catch(() => {
          window.location.href = '/'
        })
    }
/** cas 登录跳转第三方页面自动登录 */
export const loginOauth =
  (code: any): AppThunk<Promise<UserEntity>> =>
    (dispatch, getState) => {
      return getUserInfoByOauthCode(code)
        .then((userInfo) => {
          commonClearUserCache(userInfo, getState, dispatch);
          getCq369().then((data) => {
            const dataFormat = `CQ${data}`
            dispatch(setProductGrade(dataFormat))
          })
          const handleQueryTheme = () => {
            const { personalSettings, createFrom, saveEditorContent } =
              userInfo || {}
            dispatch(setSpecialLoginType(createFrom))
            // 保存[登出]状态
            dispatch(setExitPrompt(saveEditorContent as boolean))
            const { theme, editorPromptOnClose } = personalSettings || {}
            dispatch(setQueryTheme(theme))
            dispatch(setEditorPromptOnClose(editorPromptOnClose))
          }
          handleQueryTheme()
          dispatch(setIsLoggedIn(true))
          dispatch(setUserInfo(userInfo))
          dispatch(setPermissionList(generatePermissionList(userInfo)))
          dispatch(setVisibleAlert(true))

          // 是否需要填写登录目的
          getLoginPurpose().then((isNeeded) => {
            dispatch(setPurposeConfirm(!userInfo.firstLogin && isNeeded))
          })

          return userInfo
        })
        .catch(() => {
          window.location.href = '/'
        })
    }
/** ukey 登录 */
export const ukeyLogin =
  (params: {
    userId: string
    password: string
    result: number
    encryptionId: number
  }): AppThunk<Promise<UserEntity>> =>
    (dispatch) => {
      return userUkeyLogin(params).then((userInfo) => {
        getCq369().then((data) => {
          const dataFormat = `CQ${data}`
          dispatch(setProductGrade(dataFormat))
        })
        const handleQueryTheme = () => {
          const { personalSettings, saveEditorContent } = userInfo || {}
          const { theme, editorPromptOnClose } = personalSettings || {}
          // 保存[登出]状态
          dispatch(setExitPrompt(saveEditorContent as boolean))
          dispatch(setQueryTheme(theme))
          dispatch(setEditorPromptOnClose(editorPromptOnClose))
        }
        const { warnings } = userInfo || {}
        if (warnings && warnings.length) {
          Modal.confirm({
            content: warnings[0],
            okText: '确认',
            cancelText: '取消',
            cancelButtonProps: { style: { display: 'none' } },
            onOk() {
              handleQueryTheme()
              dispatch(setIsLoggedIn(true))
              dispatch(setUserInfo(userInfo))
              dispatch(setPermissionList(generatePermissionList(userInfo)))
              dispatch(setVisibleAlert(true))
            },
            onCancel() {
              // logout()
            },
          })
        } else {
          handleQueryTheme()
          dispatch(setIsLoggedIn(true))
          dispatch(setUserInfo(userInfo))
          dispatch(setPermissionList(generatePermissionList(userInfo)))
          dispatch(setVisibleAlert(true))
        }

        // 是否需要填写登录目的
        getLoginPurpose().then((isNeeded) => {
          dispatch(setPurposeConfirm(!userInfo.firstLogin && isNeeded))
        })

        return userInfo
      })
    }

/** 获取企微配置信息，写入store */
export const getWwLoginInfo = (): AppThunk<Promise<IWwLoginInfo>> =>
  (dispatch) => {
    return getWwLoginConfig().then((res) => {
      dispatch(setWwLoginInfo(res));
      return res;
    })
  }


/** 获取企微配置信息，写入store */
export const userWwLogin = (code: string): AppThunk<Promise<UserEntity>> =>
  (dispatch) => {
    return wwLogin(code).then((res) => {
      commonLoginProcess(res, dispatch)
      return res;
    })
  }