import { fetchGet, fetchPost } from './customFetch'
import { DataSourceType, NodeEntity } from 'src/types'

// 文件导入相关信息类型定义
type FileType = 'txt' | 'csv' | 'xls' | 'xlsx'
export interface ITextImportEntity {
  // 文件类型
  fileType: FileType
  // 文件路径及编码
  filePath: string
  fileCode: string
  // 分隔符
  rowSeparator: string
  fieldSeparator: string
  fieldRecognize: string
  // 格式配置
  fieldNameRow: string | number
  firstRow: string | number
  lastRow: string | number
  dateSort: string
  dateSeparator: string
  timeSeparator: string
  decimalDot: string
  dateTimeSort: string
  binaryCode: string
  // 目标表
  targetTable: string
  // 可选目标表
  targetTableList: NodeEntity[]
  // 字段对应关系
  sourceFields: string[]
  targetFields: string[]
  // 目标表信息
  targetTableMessage: ITargetTableMessage | null
  // 目标表字段信息
  targetTableFieldList: any[]
  // 获取源字段请求数据
  sourceTableFieldsRequest: IImportFileRequest | null
  // 表字段映射数据
  tableFieldMap: any[] | null
  // 被授予用户没操作权限的层级右键标识
  noAccessRightClick: boolean
}

/** 获取分隔符接口：获取csv默认格式参数 */
export const getSeparators = () => {
  return fetchGet('/export/importer/csvDefaultFormat').then((v) => {
    return {
      rowSeparator: v.lineBreak,
      fieldSeparator: v.delimiter,
      fieldRecognize: v.quote,
    }
  })
}

/** 获取表结构信息：获取目标表的字段信息 */
export interface IFieldMapRequest {
  connectionId: number
  dataSourceType: DataSourceType
  nodePath: string
  nodePathWithType: string
  tableName: string
  [p:string]: any
}

export interface ITargetTableMessage extends IFieldMapRequest {
  connectionName: string
}

interface IFIeldMapType {
  columnName: string
  columnTypeName: string
  index: number
  nullable: boolean
  unique: boolean
}
type IFieldMapResponse = IFIeldMapType[]
export const postSourceTableFields = (
  params: IFieldMapRequest,
): Promise<IFieldMapResponse> => {
  return fetchPost('/export/importer/columnNames', params)
}

/** 文件导入日志下载 */
export const textImportLogDownload = (taskId: number) => {
  return fetchGet(`/export/importer/download/${taskId}`)
}

/** 预览文件数据：导入文件信息获取（源字段） */
export interface IImportFileRequest {
  // 编码，excel类型没有
  charsetName: string
  // 字段分隔符 excel没有
  delimiter: string
  // 字段名行
  fieldNameLine: number
  filePath: string
  fileType: string
  // 行分隔符 excel 没有
  lineBreak: string
  // 字段识别符 excel 没有，其他为可选（可为空）
  quote: string
  // 日期时间排序
  dateTimeSort: string
  // 日期分隔符
  dateDelimiter: string
  // 日期排序
  dateSort: string
  // 小数点
  decimalPointSymbol: string
  // 时间分隔符
  timeDelimiter: string
}
interface IImportFileResponse {
  fieldNames: string[]
  // 字段名和返回值按照索引顺序对应
  values: string[][]
}
export const postImportFileMessage = (
  params: IImportFileRequest,
): Promise<IImportFileResponse> => {
  return fetchPost('/export/importer/fileHeadData', params)
}

/** 创建数据导入任务：文本导入 */
interface ITextImportRequest {
  charsetName: string
  columnMap: {
    columnName: string
    columnTypeName: string
    index: number
    sourceColumnName: string
  }[]

  connectionName: string
  connectionId: number
  dataSourceType: DataSourceType
  dataStartLine: number
  dataEndLine: number
  dateDelimiter: string
  dateSort: string
  dateTimeSort: string
  decimalPointSymbol: string
  delimiter: string
  // 二进制编码，excel 没有
  encodeType: string
  // 字段名行
  fieldNameLine: number
  filePath: string
  fileType: string
  lineBreak: string
  quote: string
  nodePath: string
  nodePathWithType: string
  stopOnError: boolean
  tableName: string
  timeDelimiter: string
}
export const postTextImport = (params: ITextImportRequest) => {
  return fetchPost('/export/importer/importTask', params)
}

/** 启动任务：继续导入 */
export const getStartTask = (taskId: number) => {
  return fetchGet(`/export/importer/startTask/${taskId}`)
}

/** 获取文本导入任务列表 */
export const getTextImportTaskList = (pageNum: number, pageSize: number) => {
  return fetchGet(
    `/export/importer/userTaskList/pageNum=${pageNum}/pageSize=${pageSize}`,
  )
}

/** 获取文本导入任务数量 */
export const getTextImportTaskNumber = () => {
  return fetchGet('/export/importer/userTaskNumber')
}