import React from "react";
import { Form, DatePicker, Radio } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;
export const FormItemEffectiveTime = () => {
  const disabledDate = (current: any) => {
    return current && current < moment().startOf("day");
  };
  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  const disabledTime = (now: any, type: any) => {
    let time: any = moment();
    let hour = moment(time).hour();
    let minute = moment(time).minute();
    let second = moment(time).second();
    if (type === "start") {
      return {
        disabledHours: () => range(0, hour),
        disabledMinutes: () => range(0, minute),
        disabledSeconds: () => range(0, second),
      };
    }
    return {};
  };

  return (
    <Form.Item noStyle>
      <Form.Item label="生效时间" name="timeType">
        <Radio.Group defaultValue={'forever'}>
          <Radio value="forever">永久</Radio>
          <Radio value="custom">自定义时间段</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues?.timeType !== currentValues?.timeType
        }
      >
        {({ getFieldValue }) =>
          getFieldValue("timeType") === "custom" ? (
            <Form.Item
              label="时间段"
              name="time"
              // initialValue={[moment(),null]}
              rules={[
                { required: true },
                {
                  validator(_rule, value, cb) {
                    const s = moment(value?.[0]).valueOf();
                    const e = moment(value?.[1]).valueOf();
                    if (!s || !e) {
                      cb("起始时间和结束时间都不能为空");
                    }
                    if (e < moment().valueOf()) {
                      cb("请重新选择时间，结束时间必须大于当前时间");
                    } else if (s > e) {
                      cb("起始时间必须小于结束时间");
                    }
                    cb();
                  },
                },
              ]}
            >
              <RangePicker
                disabledDate={disabledDate}
                disabledTime={disabledTime}
                ranges={{
                  近7日: [moment(), moment().add(7, "d")],
                  近15日: [moment(), moment().add(15, "d")],
                  近30日: [moment(), moment().add(30, "d")],
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                format="YYYY-MM-DD HH:mm:ss"
              />
            </Form.Item>
          ) : null
        }
      </Form.Item>
    </Form.Item>
  );
};
