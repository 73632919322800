/*
 * @Author: yangchao yangchao@bintools.cn
 * @Date: 2023-06-12 16:00:48
 * @LastEditors: yangchao yangchao@bintools.cn
 * @LastEditTime: 2023-10-08 11:34:08
 * @FilePath: /cq-enterprise-frontend/src/features/websocket/useDownloadNotification.tsx
 * @Description:
 */
import React, { useCallback } from "react";
import { checkFileExistance, TaskStatus } from "src/api";
import { notification, Modal, message } from "antd";
import { LinkButton } from "src/components";
import { handleDownload } from "src/util";
import copy from "copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";
import { fetchGet } from 'src/api/customFetch'

const placement = "bottomLeft";

export type DownloadMsg = {
  taskId: number;
  taskStatus: TaskStatus;
  message: string;
  origin: string;
  fileSecretKey: string;
  whetherToEncrypt: boolean;
};

/** websocket 从服务端收到的 “sql 导出任务状态“ notification 的渲染 */
export const useDownloadNotificationRenderer = () => {
  let createdId: number[] = [];
  const copyMet = (mes: string) => {
    copy(mes);
    message.success("复制成功");
  };
  const renderNotification = useCallback((downloadMsg: DownloadMsg) => {
    if (!downloadMsg) return;
    const { taskId, taskStatus, message, origin } = downloadMsg;
    const [title, description] = message.split("：");
    switch (taskStatus) {
      case "CREATE":
        // 避免轮询时多次创建弹窗
        if (createdId.indexOf(taskId) >= 0) return;
        createdId.push(taskId);
        notification.info({ placement, message: title, description });
        break;
      case "SUCCESS":
        notification.success({
          placement,
          duration: null,
          message: title,
          description: (
            <>
              {description}
              <LinkButton
                onClick={() => {
                  // ! BAD: 使用 a 标签下载是最符合标准的，如果资源不存在，直接报 404 或 410 即可
                  checkFileExistance(taskId.toString(), origin)
                    .then(() => {
                      fetchGet(`/export/export/check/encrypt/${taskId}`).then((res: any)=>{
                        const { whetherToEncrypt, fileSecretKey } = res || {}
                        if(whetherToEncrypt){
                          Modal.info({
                            width: 520,
                            content: (
                              <div>
                                <div style={{ fontSize: '18px', marginBottom:'30px'}}>文件密钥</div>
                                导出文件已加密 , 密钥为:
                                <span style={{ fontWeight: "bold" }}>
                                  {fileSecretKey}
                                </span>
                                <CopyOutlined
                                  style={{ color: "#0c2dc7" }}
                                  onClick={() => {
                                    copyMet(fileSecretKey);
                                  }}
                                />
                              </div>
                            ),
                            icon: null,
                            onOk: () => {
                              handleDownload({
                                href: `/${origin}/export/download/${taskId}`,
                              });
                            }
                          });
                        }else {
                          handleDownload({
                            href: `/${origin}/export/download/${taskId}`,
                          });
                        }
                      }).catch((err: any)=>{console.error(err)})
                    })
                    .catch();
                }}
              >
                下载
              </LinkButton>
            </>
          ),
        });
        break;
      case "FAILURE":
        notification.error({
          placement,
          duration: null,
          message: title,
          description,
        });
        break;
      default:
        break;
    }
  }, []);

  return [renderNotification];
};
