// 申请变更add/再次申请edit
import React, { FC, useEffect, useState } from 'react'
import { Form, message } from 'antd'
import  moment  from 'moment'
import * as _ from 'lodash'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Flow from 'src/components/Process'
import {
  ApplyItem,
  saveAsDraft,
  getDataChangeDetail,
  applyDataChange,
} from 'src/api'
import { useRequest } from 'src/hook'
import { DATA_REQUEST_APPLY_TYPES } from 'src/constants'
import { SimpleBreadcrumbs, RcSegmented } from 'src/components'
import {
  DataUpdateForm,
  SQLCheckForm,
  SubmitApplicationForm,
  SimuleEnvironmentValidation,
} from "../../components";
import { FormContext } from '../../ApplyFormContext'
import styles from './index.module.scss'

interface IUrlSearchFields {
  reapply?: boolean;
  applyUserId?: string;
  sqlStatement?: string;
  nodePath?: string;
  dataSourceType?: string;
  connectionName?: string;
  connectionId?: number;
}

// 变更申请-数据订正
const applyChangeFlow_dataCorrection = [
  {
    title: '申请数据变更',
  },
  {
    title: 'SQL审核',
  },
  {
    title: '提交申请',
  },
]
// 变更申请-发布变更
const applyChangeFlow_releaseChanges = [
  {
    title: '申请数据变更',
  },
  {
    title: 'SQL审核',
  },
  {
    title: '模拟环境验证',
  },
  {
    title: '提交申请',
  },
]

export const Apply: FC = () => {

  const history = useHistory()
  const [form] = Form.useForm()
  //@ts-ignore
  const {state={}} = useLocation();

  const { id, editId } = useParams<{ id?: string;editId?: string }>()
  const isViewDetail = !!id ;
  //statement nodePath  高危申请内容
  const { reapply = false, applyUserId = "", connectionId, sqlStatement, nodePath, connectionName, dataSourceType } = state as IUrlSearchFields

  //当前申请流程
  const [currentStep, setCurrentStep] = useState(0)
  //存储当前数据
  const [currentContextValue, setCurrentContextValue] = useState<
    ApplyItem | any
    >({type: 1, sqlType: 1})
  const [pageType, setPageType ] = useState<number>(1)

  //申请详情
  const { run: runGetDataChangeDetail, refresh } = useRequest(getDataChangeDetail, {
    manual: true,
    onSuccess: (data:ApplyItem) => {
      if (data) {
        form.setFieldsValue({
          ...data,
          executeTime: data?.executeTime ?moment(data.executeTime) : null
        })
      }
      setCurrentContextValue({...data,executeTime: data?.executeTime ?moment(data.executeTime) : null})
    },
  })


  //暂存草稿
  const { run: runSaveAsDraft } = useRequest(saveAsDraft, {
    manual: true,
    onSuccess: () => {
      message.success('提交成功')
      history.push('/data_change_mine_apply')
    },
  })
  const { run: runApplyDataChange } = useRequest(applyDataChange, {
    manual: true,
    onSuccess: () => {
      message.success('提交成功')
      history.push('/data_change_mine_apply')
    },
  })

  //高危申请赋值
  useEffect(() => {

    if(sqlStatement && nodePath) {
      setCurrentContextValue({
        nodePath,
        sqlStatement,
        dataSourceType,
        connectionName,
        sqlType: 1,
        type: 1,
        connectionId
      })
      form.setFieldsValue({
        nodePath,
        sqlStatement,
        sqlType: 1
      })
    }
  },[sqlStatement, nodePath, dataSourceType, connectionName,connectionId])

  useEffect(() => {
    setPageType(currentContextValue?.type)
  }, [currentContextValue])

  useEffect(() => {
    if (isViewDetail || editId) {
      const idPar:string | undefined = id || editId;
      idPar && runGetDataChangeDetail(idPar)
    }else {
      form.setFieldsValue({type: 1})
    }
  }, [isViewDetail,editId])

  // 提交申请：保存草稿、提交
  const onSubmitApplicationForm = (type: 'finalize' | 'draft', params: any) => {
    //如果是再次申请不需要id
    if (reapply) {
      delete currentContextValue?.id
    }
    params.rollbackSqlType = params.sqlType;

    if (type === 'finalize') {
      const paraments = { ...params, id: currentContextValue?.id };
      runApplyDataChange(paraments);
    } else {
      const paraments = { ...params, id: editId };
      runSaveAsDraft(paraments);
    }
  }

  const handleTypeChange = (value: any) => {
    setCurrentStep(0);
    setCurrentContextValue({
      ...currentContextValue,
      type: value,
      dataSourceType: '',
      simulateNodePath: '',
      nodePath: '',
      skip2Step: false,
      templateName: '',
    })
    form.setFieldsValue({
      simulateNodePath: '',
      nodePath: ''
    })
  }

  const APPLY_TYPES_OPTIONS = (() => {
    return Object.keys(DATA_REQUEST_APPLY_TYPES).map((key) => ({
      //@ts-ignore
      label: DATA_REQUEST_APPLY_TYPES[key],
      value: Number(key),
      //@ts-ignore
      // disabled: Number(key) === 2,
    }))
  })()

  const BREADCRUMB_ITEMS = [
    {
      title: '数据变更',
    },
    {
      title: '我的申请',
      href: '/data_change_mine_apply',
      onclick: () => history.go(-1)
    },
    {
      separator: '/',
      title: '申请变更',
    },
  ]

  const applicantStepItems = [
    // 申请数据变更
    {
      hidden: !(currentStep === 0),
      description: (
        <DataUpdateForm
          form={form}
          isViewDetail={isViewDetail}
          isEdit={!!editId}
          pageType={pageType}
          currentRouteType='MINE_APPLY'
        />
      ),
    },
    // SQL审核
    {
      hidden: !((currentStep === 1)),
      description: (
        <SQLCheckForm
          isViewDetail={isViewDetail}
          currentRouteType="MINE_APPLY"
        />
      ),
    },
    // 模拟环境验证
    {  
      hidden:  !(currentContextValue?.type === 2 && currentStep === 2),
      description: (
        <SimuleEnvironmentValidation
          form={form}
          applyUserId={applyUserId}
          currentRouteType='MINE_APPLY'
        />
      ),
    },
    // 提交申请
    {
      hidden: !((currentContextValue?.type === 1 && currentStep === 2) || (currentContextValue?.type === 2 && currentStep === 3)),
      description: (
        <SubmitApplicationForm
          form={form}
          isViewDetail={isViewDetail}
          isEdit={!!editId}
          onSubmitApplication={(type, params: ApplyItem) =>
            onSubmitApplicationForm(type, params)
          }
          currentRouteType='MINE_APPLY'
        />
      ),
    },
  ];

  return (
    <div
      style={{
        height: '100%',
        backgroundColor: "#fff",
      }}
    >
      <FormContext.Provider
        value={{
          form,
          currentContextValue,
          setCurrentContextValue,
          setCurrentStep,
        }}
      >
        <SimpleBreadcrumbs items={BREADCRUMB_ITEMS} />
        <div className={styles["application-detail"]}>
          {!isViewDetail &&
            <Form form={form} name="basic">
              <Form.Item name="type" initialValue={1}>
                <RcSegmented options={APPLY_TYPES_OPTIONS} onChange={handleTypeChange} />
              </Form.Item>
            </Form>
          }
          {
            <div style={{backgroundColor: "#f7f9fc", paddingTop: 10, paddingBottom: 10}}>
              <Flow
                items={pageType === 1 ? applyChangeFlow_dataCorrection : applyChangeFlow_releaseChanges}
                current={currentStep}
                className={styles["approval-step"]}
              />
            </div>
          }
          <div className={styles.applyContent}>
            <Flow
              items={applicantStepItems}
              current={currentStep}
              direction="vertical"
              isShowStepIcon={false}
            />
          </div>
        </div>
      </FormContext.Provider>
    </div>
  );
}

export default Apply
