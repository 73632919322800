export const FormLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 11 },
}

export const FormLayoutTwo = {
  labelCol: { span: 5 },
  wrapperCol: { span: 6 },
}

export const FormTailLayout = { wrapperCol: { offset: 8, span: 11 } }

export const FormTailLayoutTwo = { 
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
}