
import { DataSourceType } from "..";
import { fetchPost, fetchGet, fetchDelete } from "../customFetch";
import { IUserOrgSdtVoData } from 'src/api';
import { SourceType, PermissionType } from 'src/constants';


//sdt
export const findUserAndDeptSdt = (): Promise<IUserOrgSdtVoData[]> => { 
	return fetchGet(`/user/org/findUserAndDeptSdt`);
}

export interface IMainAuthListParams {
  pageSize: number;
  pageNumber: number;
  totalPages?: number;
  orgId?: number;
  userId?: string;
  objectTypes?: string[];
  permissionTypes?: string[];
  permissionSources?: string[];
  nodePathWithTypes?: string[];
}


export interface IIMainAuthListItem{
  resource: number;
  objectType: string;
  sdtRole: string;
  permissionId: number;
  permissionName?: string;
  permissionType: PermissionType,
  effectiveTime: string;
  dataSourceType: DataSourceType;
  authorizerId: number;
  sourceType: SourceType;
  authorizationIp: string;
  authorizationTime: string;
  supportEdit: boolean;
  orgId?: number;
  userId?: string;
  templateId?: number
}
export interface IFormattedTreeNode extends IUserOrgSdtVoData {
  title?: string;
  key?: string;
  isLeaf?: boolean;
  children?: IFormattedTreeNode
  id?: string | number | null;
}

export interface IMainAuthListRes {
  totalRows: number;
  list: IIMainAuthListItem[];
}

//用户详情列表
export const getMainAuthList = (params: IMainAuthListParams): Promise<IMainAuthListRes> => { 
	return fetchPost(`/user/permission/mainAuthorization/findPermission`,params);
}


//自定义列表
export const customColumnList = (): Promise<string[]> => { 
	return fetchGet(`/user/permission/mainAuthorization/customList`);
}

//保存自定义列表
export const saveCustomList = (params: string[]): Promise<string[]> => { 
	return fetchPost(`/user/permission/mainAuthorization/customList/save`,params);
}


export interface ICopyPermissionParams {
  permissionIds: number[];
  userIds: string[];
}
//复制
export const copyPermission = (params: ICopyPermissionParams): Promise<IIMainAuthListItem[]> => { 
	return fetchPost(`/user/permission/mainAuthorization/copyPermission`,params);
}

interface IRevokePermissionParams {
  permissionIds: number[];
  userIds?: string[] | null;
  orgId?: number;
}
//移除
export const revokePermission = (params: IRevokePermissionParams): Promise<IIMainAuthListItem[]> => { 
	return fetchPost(`/user/permission/mainAuthorization/revokePermission`,params);
}

interface templateOperationsSet {
  objectType: string;
  operations: string[];
}
export interface ISaveAuthParams {
  dataSourceType: string;
  groupIds?: number[];
  nodePathWithTypes?: string[];
  templateId?: number;
  templateOperationsSet?: templateOperationsSet[];
  operations?: string[];
  userIds?: string[];
  beginTime?: number;
  endTime?: number;
}
//授权
export const saveAuthPermission = (params: ISaveAuthParams): Promise<any> => { 
	return fetchPost(`/user/permission/mainAuthorization/saveAuthorizationPermission`,params);
}

interface IPermissionDataItem{
  isObject: boolean;//false工具权限
  template?: string[];
  operation?: string;
  tool: string[]
}
//获取可选权限
export const getAuthPermission = (params: {userId: string; permissionId: number;}): Promise<IPermissionDataItem[]> => { 
	return fetchGet(`/user/permission/mainAuthorization/getSelectPermission/${params.userId}/${params.permissionId}`);
}

export interface IUpdateAuthPermissionParams {
  userId: string; 
  permissionId: number;
  templateId?: number;
  operation?: string;
}
//修改权限
export const updateAuthPermission = (params: IUpdateAuthPermissionParams): Promise<any> => { 
	return fetchPost(`/user/permission/mainAuthorization/updatePermission`,params);
}

//影响用户
export const getPermissionEffectUser = ({orgId, permissionId}: {orgId: number; permissionId: number;}): Promise<IPermissionDataItem[]> => { 
	return fetchGet(`/user/permission/mainAuthorization/getPermissionEffectUser/${orgId}/${permissionId}`);
}


