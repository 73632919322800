import React, { FC, useContext, useEffect, useState, useMemo } from 'react'
import * as _ from 'lodash'
import { Table, Button, Card, Modal, Input, Tooltip, Tag, Tabs } from 'antd'
import { UpOutlined, DownOutlined } from '@ant-design/icons'
import { useRequest } from 'src/hook'
import { generateObjectName, getScrollX } from 'src/util'
import { SegmentedValue, Iconfont, RcSegmented } from 'src/components'
import { getSqlSplitStatement, ApplyItem, getSqlCheck, splitCheckDownload } from 'src/api'
import { FormContext } from '../ApplyFormContext'
import { ExecutePlanDetail } from './ExecutePlanDetail'
import styles from './index.module.scss'
import classnames from 'classnames'
import { CURRENT_ROUTE_TYPE } from 'src/constants'

interface SQLCheckFormProps {
  isViewDetail: boolean
  currentRouteType?: CURRENT_ROUTE_TYPE
  [p: string]: any,
}

export const SQLCheckForm: FC<SQLCheckFormProps> = ({
  isViewDetail,
  currentRouteType
}) => {
  const [currentSqlTabType, setCurrentSqlTbaType] = useState<SegmentedValue>('execute')
  const [selectedColumnInfo, setSelectedColumnInfo] = useState(null)
  const [errorInfo, setErrorInfo] = useState<{execute: boolean, fallback: boolean}>({execute: true, fallback: true})
  const [hasErrorInfo, setHasErrorInfo] = useState<boolean>(true)

  const isMineApply = (currentRouteType === "MINE_APPLY");
  const isMineApprove = (currentRouteType === "MINE_APPROVE");
  
  //@ts-ignore
  const { 
    setCurrentStep,
    currentContextValue = {},
    setCurrentContextValue,
  } = useContext(FormContext)

  const {
    sqlType,
    sqlStatement,
    nodePath,
    rollbackSqlStatement,
    dataSourceType,
    connectionId
  } = currentContextValue as ApplyItem

  const [tableSearchParams, setTableSearchParams] = useState({
    currentPage: 1,
    pageSize: 10,
  })

  const { data: statementData, loading, refresh } = useRequest(
    () => {
      // 回退sql 非必填, 未填写时不进行 sql审核
      if (currentSqlTabType === 'fallback' && !rollbackSqlStatement) {
        return {}
      }
      return sqlType &&
        sqlStatement &&
        // !skip2Step &&
        // currentStep >= itemStepMark &&
        getSqlSplitStatement({
          type: sqlType,
          //@ts-ignore
          statement: currentSqlTabType === 'execute' ? sqlStatement : rollbackSqlStatement,
          dataSourceType,
          ...tableSearchParams,
        })
    },
    {
      refreshDeps: [tableSearchParams, nodePath, sqlStatement],
    },
  )

  // 查询sql审核结果
  const { data: sqlCheckData, loading: sqlCheckLoading } = useRequest(
    async() => {
      if(!statementData) return
      const params = {
        connectionId,
        dataSourceType,
        statements: statementData?.statements || [],
        nodePathWithType: nodePath,
      }
      const result = await getSqlCheck(params)
      const isAllowSubmit = [2, 5].includes(result?.flag)
      setCurrentContextValue({
        ...currentContextValue,
        sqlCheckNotAllowSubmit: isAllowSubmit
      })
      return result
    },{
      refreshDeps: [statementData],
    }
  )

  // 下载审核结果
  const handleDownload = () => {
    const params = {
      type: sqlType,
      database: generateObjectName(nodePath),
      statementMap: {
        execCheck: sqlStatement,
        rollBackCheck: rollbackSqlStatement,
      },
      dataSourceType,
      connectionId,
      ...tableSearchParams
      }
    splitCheckDownload(params).then((res: any) => {
    }).catch((err: any) => {
      console.error('下载审核结果错误 :>> ', err);
    })
  };


  const SQLCHECK_TYPE_TABS = [
    {
      label: '执行审核',
      value: 'execute'
    },
    {
      label: '回退审核',
      value: 'fallback'
    }
  ]

  useEffect(() => {
    setTableSearchParams({
      currentPage: 1,
      pageSize: 10,
    })
  }, [currentSqlTabType])

  const columns: any[] = [
    {
      title: '序号',
      dataIndex: 'id',
      width: 50,
      align: 'center',
    },
    {
      title: '数据库',
      dataIndex: 'nodePath',
      width: 100,
      ellipse: true,
      render: (path: string) => generateObjectName(path)
    },
    {
      title: '执行语句',
      dataIndex: 'sqlStatement',
      width: 800,
      ellipse: true,
      render: (val: string) => (
        val && <div className='flexSpaceBetween'>
            {
              val?.length > 88
              ? 
                <Tooltip 
                  title={val} 
                  getPopupContainer={() => document.getElementById('sql-review-table') as HTMLElement}
                  overlayClassName={styles.sqlCheckTooltip}
                >
                  <span style={{color: '#667084' }}>{val?.slice(0, 88) + '...'}</span>
                </Tooltip> 
              : <span style={{color: '#667084' }}>{val}</span>
            }
          <span
            className={styles['link-text']}
            onClick={() =>
              Modal.info({
                width: 700,
                icon: null,
                centered: true,
                title: '详情',
                content: (
                  <Input.TextArea
                    rows={5}
                    className={styles['sql-modal']}
                    value={val}
                  ></Input.TextArea>
                ),
              })
            }
          >
            详情
          </span>
        </div>
      ),
    },
    // {
    //   title: '执行计划',
    //   dataIndex: 'aa',
    //   width: 80,
    //   align: 'center',
    //   fixed: 'right',
    //   render: (_: string, record: any) => (
    //     <div
    //       className={styles['link-text']}
    //       onClick={() => setSelectedColumnInfo(record)}
    //     >
    //       查看
    //     </div>
    //   ),
    // },
    {
      title: '解析结果',
      dataIndex: 'parseResult',
      width: 100,
      fixed: 'right',
      render: (res: boolean)=> res? '成功' : '失败'
    },
    {
      title: '审核结果',
      dataIndex: 'result',
      width: 200,
      fixed: 'right',
      render: (_: string, record: any)=>{
        const { error = [], notice = [], warn = [] } = record 
        if(error?.length || notice?.length || warn?.length){
          return (
            <>
              {!!error?.length && <span className={`mr10`} style={{color:'#FF3232'}}>{`Error X ${error?.length}`}</span>}
              {!!warn?.length && <span className={`mr10`} style={{color:'#F59A61'}}>{`Warn X ${warn?.length}`}</span>}
              {!!notice?.length && <span style={{color:'#95ADFF'}}>{`Notice X ${notice?.length}`}</span>}
            </>
          )
        }
        return <span style={{color:'#7ce687'}}>{`passed`}</span>
      }
    }
  ]

  const checkFallBack = () => {
    if (!!rollbackSqlStatement) {
      getSqlSplitStatement({
        type: sqlType,
        //@ts-ignore
        statement: rollbackSqlStatement,
        dataSourceType,
        ...tableSearchParams,
      }).then(async (res: any) => {
        if(!res) return
        const params = {
          connectionId,
          dataSourceType,
          statements: res?.statements || [],
          nodePathWithType: nodePath,
        }
        const result = await getSqlCheck(params)
        let markIsHasErrorInfo = 0;
        if (!result?.reviewItems?.length) {
          setErrorInfo((params: any) => {
            return {...params, fallback: false}
          })
        } else {
          result?.reviewItems?.map((item: any, index: number) => {
            if (item?.error.length > 0) {
              markIsHasErrorInfo = 1;
            }
            if (index === result?.reviewItems?.length - 1 && markIsHasErrorInfo === 0) {
              setErrorInfo((params: any) => {
                return {...params, fallback: false}
              })
            } 
          })
        }
        
      })
    } else {
      setErrorInfo((params: any) => {
        return {...params, fallback: false}
      })
    }
  }

  useEffect(() => {
    const allTrue = (!Object.values(errorInfo).every(value => value === false));
    setHasErrorInfo(allTrue)
  }, [errorInfo])

  const dataSourceData = useMemo(() => {
    // 检查回退审核是否有error
    checkFallBack()
    let markIsHasErrorInfo = 0;
    if (!sqlCheckData?.reviewItems?.length || !nodePath) return []
    return sqlCheckData?.reviewItems?.map((item: any, index: number) => {
      if (item?.error.length > 0) {
        markIsHasErrorInfo = 1;
      }
      if (index === sqlCheckData?.reviewItems?.length - 1 && markIsHasErrorInfo === 0) {
        setErrorInfo((params: any) => {
          return {...params, [currentSqlTabType]: false}
        })
      } 
      return ({
        key: index + 1,
        id: index + 1,
        nodePath,
        sqlStatement: item?.statement,
        error: item?.error,
        notice: item?.notice,
        warn: item?.warn,
        parseResult: item?.parseResult,
      })
    })
  }, [sqlCheckData, nodePath])

  const tableItemExpandDetail = (item: any, index: number, type: string, bgColor: string)=>{
    const { brief, description, params } = item 
    let paramsList = params ? JSON.parse(params) : [];
    return (
      <div className={`flexAlignCenter mb10 ml36`} key={index}  style={{padding: '8px', backgroundColor: bgColor}}>
        <Iconfont type={`icon-${type?.toUpperCase()}`} />
        <span className='ml4' style={{width: 88}}>{type}</span>
        <div>
          <div style={{color:'#000'}}>
            {
              brief?.length > 50 
              ? <Tooltip title={brief}>
                  {brief?.slice(0, 50) + '...'}
                </Tooltip>
              : brief
            }
          </div>
          <div style={{color:'#aaa'}}>
            {
              description?.length > 50 
              ? <Tooltip title={description}>
                  {description?.slice(0, 50) + '...'}
                </Tooltip>
              : description
            }
          </div>
          <div>
            {
              paramsList?.map((item: { index: number; desc: any; value: any; }) => {
                const { index, desc, value } = item;
                return (
                  <Tag
                    key={index}
                    style={{ margin:'4px 4px 4px 0' }}
                  >
                    {desc}:{value}
                  </Tag>
                ) 
              })
            }
          </div>
        </div>
      </div>
    )
  }

  const renderTable = (
    <Table
      bordered={false}
      size="small"
      //@ts-ignore
      columns={columns}
      loading={loading||sqlCheckLoading}
      dataSource={dataSourceData}
      className={styles['sql-table']}
      id='sql-review-table'
      tableLayout='auto'
      scroll={{ x: getScrollX(columns) + 100, y: `calc(100vh - 600px)` }}
      pagination={{
        current: tableSearchParams.currentPage,
        total: statementData?.total || 0,
        pageSize: tableSearchParams?.pageSize,
        onChange: (page, pageSize = 10) => {
          setTableSearchParams({
            currentPage: page,
            pageSize,
          })
        },
      }}
      expandable={{
        expandedRowRender: (record) => {
          const {error=[], notice=[], warn=[]} = record
          return (
            <>
              { 
                !!error?.length &&
                error?.map((item: any, index: number)=>tableItemExpandDetail(item, index, 'Error', '#FFF0EF'))
              }
              { 
                !!warn?.length &&
                warn?.map((item: any, index: number)=>tableItemExpandDetail(item, index, 'Warn', '#FFF4EF'))
              }
              { 
                !!notice?.length &&
                notice?.map((item: any, index: number)=>tableItemExpandDetail(item, index, 'Notice', '#F8F9FF'))
              }
            </>
          )
        },
        rowExpandable: (record) => !!record?.error?.length || !!record?.notice?.length || !!record?.warn?.length,
        expandIcon: ({ expanded, onExpand, record }) => {
          const showExpandIcon = !!record?.error?.length || !!record?.notice?.length || !!record?.warn?.length
          if(!showExpandIcon){
            return '-'
          }
          return (
            <span onClick={(e) => onExpand(record, e)} style={{display:'inline-block', width: 100, cursor: 'pointer'}}>
              {
                expanded 
                ? <span>收起&nbsp;<UpOutlined /></span>
                : <span>展开&nbsp;<DownOutlined /></span>
              }
            </span>
          ) 
        },
        expandIconColumnIndex: columns.length,
      }}
    />
  )

  return (
    <Card
      title={!isViewDetail ? "" : "审核结果"}
      className={classnames(styles['sql-card'], isMineApply && styles['step-card2'], isMineApply && styles['borderNone'])}
      id='sqlCheckCard'
      bordered={false}
      extra={
        isMineApprove &&
        <>
        <Button
          className={styles.extraBtn}
          onClick={handleDownload}
        >
          下载检查结果
        </Button>
        <RcSegmented options={SQLCHECK_TYPE_TABS} onChange={(type: SegmentedValue) => setCurrentSqlTbaType(type)} />
        </>
      }
      actions={
        !isViewDetail
          ? [
            <div className={styles['footer-btn']}>
              {
                isMineApply && 
                <>
                <Button
                  className={styles['secondary-btn']}
                  style={{ marginRight: 5 }}
                  onClick={handleDownload}
                >
                  下载审核结果
                </Button>
                <Button
                  className={styles['secondary-btn']}
                  style={{ marginRight: 5 }}
                  onClick={() => {
                    setCurrentStep && 
                    setCurrentStep((currentStep: number) => {
                      return (currentStep - 1)
                    });
                  }}
                >
                  上一步
                </Button>
                {
                  hasErrorInfo?
                  <Tooltip title="SQL审核结果中存在error等级的暂不允许进行下一步操作" >
                    <Button
                      type='primary'
                      disabled={hasErrorInfo}
                    >
                      下一步
                    </Button>
                  </Tooltip>
                  :
                  <Button
                    type='primary'
                    onClick={() => {
                      setCurrentStep &&
                      setCurrentStep((currentStep: number) => {
                        return (currentStep + 1)
                      });
                    }}
                  >
                    下一步
                  </Button>
                }
              </>
              }
            </div>,
          ]
          : []
      }
    >
      {
        isMineApply && 
        <Tabs
          onChange={(type: SegmentedValue) => setCurrentSqlTbaType(type)}
        >
          <Tabs.TabPane tab={'执行审核'} key={'execute'}>
            {renderTable}
          </Tabs.TabPane>
          <Tabs.TabPane tab={'回退审核'} key={'fallback'}>
            {renderTable}
          </Tabs.TabPane>
        </Tabs>
      }

      { isMineApprove && renderTable }     
      
      {selectedColumnInfo && (
        <ExecutePlanDetail
          title="执行计划"
          selectedRecord={selectedColumnInfo}
          visible={true}
          onCancel={() => setSelectedColumnInfo(null)}
        />
      )}
    </Card>
  )
}

export default SQLCheckForm
