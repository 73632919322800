import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Alert,
  Input,
  Layout,
  Modal,
  Switch,
  Table,
  Tooltip
} from 'antd';
import * as _ from 'lodash';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  deleteDesensRule, permissionSupport,
} from 'src/api';
import { LinkButton } from 'src/components';
import { useDispatch, useRequest, useSelector } from 'src/hook';
import { showModal } from 'src/store/extraSlice/modalVisibleSlice';
import { getDesensInternalDataAction, getDesensInternalStatusAction } from '../utility'
import { DesensRulesModalPage, DesenCaluParameterSettingModal } from '../Dialog';
import styles from './index.module.scss';
import { getCurrentModulePermissionByUrl } from 'src/util/utils';

const RenderColumns = ({
  refresh,
  selectedNode,
  setEditRecord,
}: {
  refresh: () => Promise<any>;
  selectedNode: any;
  setEditRecord: (record: any) => void;
}, permissionlist: any, permissionSupportData: boolean): ColumnsType<any> => {
  const dispatch = useDispatch();
 
 
  const {roleNameList} = permissionlist || {}
  
  const {
    run: runUpdateDesensStatus,
  } = useRequest<any>(getDesensInternalStatusAction(selectedNode?.nodeType),{
    manual: true,
    onSuccess: () => {
      refresh()
    }
   }
  );
  const { run: runDeleteDesensRule } = useRequest(
    deleteDesensRule,
    {
      manual: true,
      onSuccess() {
        refresh();
      },
    }
  );
  // 状态 启用/禁用
  const setStatus = (ruleId: number, val: boolean) => {
    const operationalVal = val ? "ENABLE" : "DEACTIVATE"
    if (selectedNode?.props?.nodeType === "datasource") {
      runUpdateDesensStatus({
        ruleId,
        datasourceType: selectedNode?.props?.nodeName,
        operational: operationalVal,
      });
    }else if (selectedNode?.props?.nodeType === "group") {
      runUpdateDesensStatus({
        ruleId,
        groupId: selectedNode?.props?.id,
        operational: operationalVal,
      });
    } else {
      runUpdateDesensStatus({
        connectionId: selectedNode?.props?.connectionId,
        ruleId,
        nodePathWithType: selectedNode?.props?.nodePathWithType,
        operational: operationalVal,
      });
    }
  }
  return [
    {
      dataIndex: "ruleName",
      title: "规则名称",
    },
    {
      dataIndex: "description",
      title: "描述",
      render: (text) => <div>{text ? text : '-'}</div>,
    },
    {
      dataIndex: "resource",
      title: "匹配规则",
      ellipsis: { showTitle: false },
      render: (text) => <Tooltip title={text}>{text}</Tooltip>,
    },
    {
      dataIndex: "checkMethod",
      title: "匹配方式",
      width: 120,
      render: (text) => {
        // ! 当前只有两种匹配方式，如果类型再增多的话，从维护性考虑，需要定义好对应的类型
        if (text === "EXPRESSING") {
          return "正则表达式";
        }
        if (text === "ALGORITHM") {
          return "算法";
        }
        return text;
      },
    },
    {
      dataIndex: "example",
      title: "脱敏结果",
      ellipsis: true,
      render: (text) => <div>{text ? text : '-'}</div>,
    },
    {
      dataIndex: "valid",
      width: 120,
      title: "状态",
      render: (_, record) => {
        const { valid, id: ruleId } = record;
        // return <span>{valid ? "已启用" : "已停用"}</span>;
        return (
          <Tooltip title={
            !permissionSupportData?
            `您当前的角色是[${roleNameList.join(", ")}], 对[数据保护]没有操作权限`
            : null
            } 
          >
            <Switch
              checked={valid ? true : false}
              checkedChildren="启用"
              unCheckedChildren="禁用"
              onChange={(checked: boolean) => {
                setStatus(ruleId, checked)
              }}
              disabled={!permissionSupportData}
            />
          </Tooltip>
        )
    },
    },
    {
      key: "action",
      title: "操作",
      width: 180,
      render: (_, record) => {
        const { valid, id: ruleId, ruleName, editable } = record;
        return (
            <Tooltip title={
              !permissionSupportData?
              `您当前的角色是[${roleNameList.join(", ")}], 对[数据保护]没有操作权限`
              : null
              }
              className={styles.displayFlex}
            >
              <LinkButton
                disabled={!editable || !permissionSupportData}
                onClick={() => Modal.confirm({
                  title: '确定删除该规则？',
                  onOk: () => runDeleteDesensRule({ id: ruleId }),
                  okText: '确定',
                  centered: true
                })}
              >
                删除
              </LinkButton>
              <LinkButton
                disabled={!editable || !permissionSupportData}
                onClick={() => {
                  setEditRecord(record)
                  dispatch(showModal("DesensRulesModal"));
                }}
              >
                编辑
              </LinkButton>
            </Tooltip>
        );
      },
    },
  ];
};

const DataInfoTablePage = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    userInfo
  } = useSelector((state) => state.login)
  const { selectedNode, selectedNodePermissionInfo } = useSelector((state) => state.dataProtection);
  const {modulePermissionObj, permissionSupportData } = selectedNodePermissionInfo["DATA_PROTECT"] || {};

  const [editRecord, setEditRecord] = useState(null)

  const {
    data,
    loading,
    refresh,
    run: runGetInternalData,
  } = useRequest<any>(getDesensInternalDataAction(selectedNode?.props?.nodeType),{manual: true}
  );

  const getCurLevelDefaultParams = (type: string) => {
    let params: any = {};
    const {  nodeName, id, connection, nodePathWithType, props } =
      selectedNode as any;
    switch(type) {
      case 'datasource':
        params.datasourceType = nodeName;
        break;
      case 'group':
        params.groupId =  `${selectedNode?.props?.id}`;
        break;
      case 'connection':
        params = {
          connectionId: id,
          dataSourceType: connection?.connectionType,
          nodePathWithType: nodePathWithType
        }
        break;
        default:
          params = {
            connectionId: props.connectionId,
            dataSourceType: props?.connectionType,
            nodePathWithType: props?.nodePathWithType
          }
          break;
    }
    return params;
  }

  useEffect(() => {
    if (selectedNode && selectedNode?.props?.nodeType) {
      const curParams = getCurLevelDefaultParams(selectedNode?.props?.nodeType);
      runGetInternalData(curParams)
    }
  
  }, [ selectedNode?.key]);

  //在函数组件中用useCallback来确保不同生命周期内函数引用不变
 
  const debounceFun = useCallback(
    _.debounce((val) => {
      const curParams = selectedNode && getCurLevelDefaultParams(selectedNode?.props?.nodeType);
    if (!curParams) return
   
   runGetInternalData({
      ...curParams,
      condition:val
    })
    }, 500),
    []
  );

  const debounceHandleChange = useCallback(
    e => {
        debounceFun(e.target.value);
    },
    [debounceFun]
  );

  return (
    <Layout className="cq-container">
      <section className="cq-card cq-card--padding">
        {
          selectedNode?.newNodeType && ['datasource','group','CONNECTION'].includes(selectedNode?.newNodeType)
           &&
           <Alert
             message="具体设置以实际层级为准"
             type="info"
             showIcon
           />
          }
     
        <div className="cq-card__headerbar">
          <div className={styles.desenHeader}>   
            {
              selectedNode?.newNodeType && ['datasource','group','CONNECTION'].includes(selectedNode?.newNodeType) ?
              <>
                <div>
                  <span className={styles.desenSetting}>采样行数：{data?.sampledCount || 0}</span>
                  <span className={styles.desenSetting}>命中率:{data?.sampleRate || 0}%</span>
                  <Tooltip title={
                      !permissionSupportData?
                      `您当前的角色是[${modulePermissionObj?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
                      : null
                      } 
                    >
                      <Button
                        onClick={() => dispatch(showModal("DesensCaluParameterSettingModal"))}
                        style={{ marginLeft: '10px' }}
                        disabled={!permissionSupportData}
                      >
                        编辑
                      </Button>
                    </Tooltip>
                </div>
            </> : <div></div>
            }
            <div className={styles.flexrc}>
                  <Input
                    prefix={<SearchOutlined />}
                    placeholder="请输入规则名称"
                    allowClear
                    onChange={debounceHandleChange}
                  />
              {
                userInfo?.version !== 'community' && 
                <Tooltip title={
                  !permissionSupportData?
                  `您当前的角色是[${modulePermissionObj?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
                  : null
                  } 
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      dispatch(showModal("DesensRulesModal"));
                    }}
                    style={{ marginLeft: '10px' }}
                    disabled={!permissionSupportData}
                  >
                    添加规则
                  </Button>
                </Tooltip>
              }
            </div>
          </div>
        </div>
        {/* <section className="cq-table"> */}
        <Table
          columns={RenderColumns({ refresh, selectedNode, setEditRecord }, modulePermissionObj, permissionSupportData)}
          loading={loading}
          dataSource={data?.list || []}
        // pagination={false}
          scroll={{ y: `calc(100vh - 415px)`}}
        ></Table>
        <DesensRulesModalPage
          refresh={() => refresh()}
          editRecord={editRecord}
          clearEditRecord={() => setEditRecord(null)}
        />
       {/* 脱敏命中设置 */}
       <DesenCaluParameterSettingModal
         defaultValues={{sampledCount: Number(data?.sampledCount) || 1, sampleRate: Number(data?.sampleRate) || 0}}
         refresh={() => refresh()}
       />
      </section>
    </Layout>
  );
};

export { DataInfoTablePage };

