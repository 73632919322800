import React, { useEffect, useMemo, useState } from "react";
import * as _ from 'lodash'
import { Modal, Tooltip, Switch, Space, message, Button, Input } from "antd";
import { ExclamationCircleFilled, RedoOutlined, SearchOutlined } from "@ant-design/icons";
import {
  setDesensitizeResourceStatus,
  batchDeleteDesensResource,
  permissionSupport,
  getDesensScanRules,
  IScanRuleItem,
} from "src/api";
import { SimpleTable } from "src/components";
import { useRequest, useSelector, useDispatch } from "src/hook";
import { showModal } from "src/store/extraSlice/modalVisibleSlice";
import type { NodeTypeKey } from "../type";
import { formatRuleName } from "../utility";
import { getDesensScanResultAction } from "./utils";
import { setDesensRuleSettingObj } from "../../DataProtectionPageSlice";
import styles from "./index.module.scss";
import classnames from "classnames";

interface ISearchParams {
  current: number;
  pageSize: number;
  groupId?: number | null;
  nodePath?: string | null;
  datasourceType?: string | null;
  tableName?: string | undefined;
}
const { confirm } = Modal;
//除table 层其他页面全部公用 除了接口不一样
const DesensScanRuleContent = ({ type, isRefresh }: 
  { type: NodeTypeKey, isRefresh: boolean, [p: string]: any }
) => {
  const dispatch = useDispatch();

  const { selectedNode, selectedNodePermissionInfo } = useSelector((state) => state.dataProtection);
  const {modulePermissionObj, permissionSupportData } = selectedNodePermissionInfo["DATA_PROTECT"] || {};

  const [searchParams, setSearchParams] = useState<ISearchParams | any>({});
  // 假设有一个变量来跟踪服务是否已经执行过
  const [serviceExecuted, setServiceExecuted] = useState<boolean>(false);
  const [isShowLoading, setIsShowLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  //不同类型， 接口不同参数不同
  const {
    data: scanResult = {},
    loading,
    refresh,
    run: getScanResult,
  } = useRequest(
    () => {
      const action = getDesensScanResultAction(type);
      
      return !_.isEmpty(searchParams) && action && action(searchParams);
    },
    { 
      refreshDeps: [searchParams] , 
      onSuccess: () => {
        setIsShowLoading((val: boolean) => {
          if(val) {
            return !val
          } else {
            return val
          }
        })
        setServiceExecuted(true)
      }
    },
  );

  //批量删除
  const { run: batchDeleteDesens } = useRequest(batchDeleteDesensResource, {
    manual: true,
    onSuccess: () => {
      message.success("删除成功");
      setSearchParams({ ...searchParams, current: 1 });
    },
  });

  //规则
  const { data: rules } = useRequest(getDesensScanRules, {
    formatResult: (res) => {
      return res?.map((item: IScanRuleItem) => ({
        text: item?.ruleName,
        value: item?.ruleName,
      }));
    },
  });

  useEffect(() => {
    setServiceExecuted(false);
    setSearchValue('');
  }, [selectedNode?.props?.nodePath])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
  
    const startRecursiveQuery = async () => {
      try {
        await refresh();
        // 继续下一次查询，等待 5000 毫秒
        timeoutId = setTimeout(async () => {
          await startRecursiveQuery();
        }, 3000);
      } catch (error) {
        console.error('轮询出错：', error);
      }
    };
  
    serviceExecuted && startRecursiveQuery();
  
    // 返回一个清除定时器的函数
    return () => {
      // console.log('组件销毁');
      clearTimeout(timeoutId);
    };
  }, [serviceExecuted]);

  useEffect(() => {
    
    if (selectedNode && type) {
      let defaultParams: ISearchParams = {
        current: 1,
        pageSize: 10,
      };
      if (type === "datasource") {
        //@ts-ignore
        defaultParams.datasourceType = selectedNode.props.id;
      } else if (type === "group") {
        defaultParams.groupId = selectedNode.props.id;
      } else {
        //@ts-ignore
        defaultParams.nodePath = selectedNode.props.nodePathWithType;
      }
      if (searchValue) {
        defaultParams.tableName = searchValue;
        setSearchValue(searchValue);
      }
      setSearchParams(defaultParams);
    }
  }, [selectedNode, type,isRefresh]);

  const { run: setStatus } = useRequest(setDesensitizeResourceStatus, {
    manual: true,
    onSuccess: () => {
      message.success("修改成功");
      refresh();
    },
  });

  async function handleSetStatus(record: any) {
    setIsShowLoading(true)
    await setStatus({
      id: [record.id],
      status: !record.status,
    });
  }

  const showDeleteConfirm = (params: { resourceIds: number[] }) => {
    confirm({
      title: `确定删除？`,
      centered: true,
      icon: <ExclamationCircleFilled />,
      onOk() {
        batchDeleteDesens(params);
      },
    });
  };

  const columns: any = [
    {
      dataIndex: "connectionName",
      title: "字段路径",
      width: 200,
      render(value: string) {
        return (
          <Tooltip title={value}>
            {value?.length <= 20 ? value : value?.slice(0, 20) + "..."}
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "matchRule",
      title: "匹配规则",
      ellipsis: true,
      filters: rules,
      onFilter: (value: string, record: any) => record.matchRule.indexOf(value) === 0,
      render(value: string) {
        return (
          <Tooltip title={value}>
            {value?.length <= 15 ? value : value?.slice(0, 15) + "..."}
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "ruleName",
      title: "脱敏规则",
      ellipsis: true,
      render(value: string) {
        return formatRuleName(value);
      },
    },
    {
      dataIndex: "ruleParam",
      title: "算法",
      ellipsis: true,
      render(value: string) {
        return (
          <Tooltip title={value}>
            {value?.length <= 15 ? value : value?.slice(0, 15) + "..."}
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "source",
      title: "来源",
    },
    {
      dataIndex: "status",
      title: "状态",
      render: (value: string, record: any) => (
        <Tooltip title={
          !permissionSupportData?
          `您当前的角色是[${modulePermissionObj?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
          : null
          } 
        >
          <Switch
            checked={value ? true : false}
            checkedChildren="启用"
            unCheckedChildren="关闭"
            onChange={(checked: boolean) => {
              handleSetStatus(record);
            }}
            disabled={!permissionSupportData}
          />
        </Tooltip>
      ),
    },
    {
      dataIndex: "options",
      title: "操作",
      fixed: "right",
      render(value: string, record: any) {
        return (
          <Space size="small" align="start">
            <Tooltip title={
              !permissionSupportData?
              `您当前的角色是[${modulePermissionObj?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
              : null
              } 
              className={styles.displayFlex}
            >
              <Button
                type="link"
                className={classnames(styles.linkBtn, styles.padding0)}
                onClick={() => {
                  dispatch(showModal("CommonEditDesensRulesModal"));
                  dispatch(
                    setDesensRuleSettingObj({
                      refresh,
                      editRecord: record,
                      isScanResult: true //扫描规则
                    })
                  );
                }}
                disabled={!permissionSupportData}
              >
                编辑
              </Button>
              <Button
                type="link"
                className={classnames(styles.deleteBtn, styles.padding0)}
                onClick={() => showDeleteConfirm({ resourceIds: [record?.id] })}
                disabled={!permissionSupportData}
              >
                删除
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const renderTable = useMemo(() => {
    return (
      <SimpleTable
        rowKey="id"
        total={scanResult?.total ?? 0}
        loading={isShowLoading? loading : false} // 轮询时，无loading
        columns={columns}
        dataSource={scanResult?.list || []}
        scroll={{x: 'max-content'}}
        searchParams={{...searchParams,currentPage: searchParams?.current}}
        setSearchParams={(params: any) => {
          setSearchParams({
            ...searchParams,
            pageSize: params?.pageSize,
            current: params?.currentPage
          })
        }}
      />
    )
  }, [JSON.stringify(columns), loading, isShowLoading, JSON.stringify(scanResult), JSON.stringify(searchParams)])

  return (
    <div className={styles.desensScanResultPart}>
      <div className={styles.resultHeader}>
        <div className={styles.title}>扫描结果</div>
        <div>
          <Input
            allowClear
            placeholder="按表名搜索"
            prefix={<SearchOutlined />} 
            value={searchValue}
            className={styles.searchByName}
            onChange={(e: any) => {
              const val = e?.target.value || '';
              Promise.all([setIsShowLoading(true), setSearchValue(val)]).then(() => {
                setSearchParams({
                  ...searchParams,
                  current: 1,
                  tableName: val,
                })
              })
            }}
          />
          <Button
            icon={<RedoOutlined  />}
            onClick={() => refresh()}
          />
        </div>
      </div>
      {renderTable}
    </div>
  );
};

export default DesensScanRuleContent;
