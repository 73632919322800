import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch, useRequest } from 'src/hook'
import copy from 'copy-to-clipboard'
import { Menu, message, Modal } from 'antd'
import { find, last } from 'lodash'
import {
  getNodeMenu,
  MenuEntity,
  deleteSdtGroup,
  removeConnectionfromSdtGroup,
  deleteSdtNode,
  closeConnection,
  truncateTable,
  emptyTable,
  getPermissionContext,
  getCallStatement,
  SdtNodeType,
  MenuType,
  postSyncDataDict,
  TreeNode,
  syncMetaData,
  debugStoreProcess,
  resetConnectionPool,
  getBasicSetting,
} from 'src/api'
import { addPane, setSdtBasicSettingData } from 'src/pageTabs/queryPage/queryTabs/queryTabsSlice'
import { getInfoFromPath, findTreeParentNode, findTreeRootNode } from 'src/util'
import {
  addConnection,
  startCreateDatabase,
} from 'src/features/wizards/wizardsSlice'
import { showModal } from 'src/store/extraSlice/modalVisibleSlice'
import { openFlowForm } from 'src/pageTabs/flowPages/flowFormsSlice'
import {
  openSqlEditor,
  refreshOnRoot,
  setExpandedKeys,
  viewElementInEditor,
  showViewInEditor,
  fetchTreeNodeChildren,
  refreshChildTreeCache,
  callProcedure,
  openTaskExecutionInEditor,
  openRefreshTaskInEditor
} from 'src/pageTabs/queryPage/sdt/sdtSlice'
import {
  setExpandedGroupNodes,
  setSelectedNode,
  setSdtExpandedKeys,
} from 'src/pageTabs/connectionManagementPage/connectionManagementPageSlice'
import Service from 'src/service'
import { databaseLike } from 'src/constants'
import { getSchemaName } from './utils'
import protocolChecker from './protocolChecker'
import { setCreateBatchExecuteModalVisible } from '../../queryPageSlice'

const { Item, SubMenu } = Menu

// ! 需要重构

export function findParent(root: any, target: any) {
  if (!target || !root) return null
  const pos: string | undefined = target.pos
  if (!pos) return null
  const parentNode = pos
    .split('-')
    .slice(2, -1)
    .reduce((prev, next) => {
      if (prev.children) {
        return prev.children[Number(next)]
      } else {
        return root
      }
    }, root)
  return parentNode
}

export function openBtnLink(href: string) {
  // const debugButton = document.createElement('a')
  // document.body.appendChild(debugButton)
  // debugButton.style.display = 'none'
  // debugButton.href = href
  // debugButton.click()
  // document.body.removeChild(debugButton)

  //第一个参数不能是函数
  protocolChecker(href, function () {
    /**
     * @产品经理: 缪睿
     * @后端开发: 沈奕涵
     * @前端开发: 付政昊
     * @沟通结果: 
     * 产品经理给出的理由,在用户线上环境不允许跳转至官网链接,会出现跳转失败的问题,
     * 因此驳回DQ后端开发要求,注释掉一下代码,采用弹框显示
     * 且不应给出跳转按钮,只存放跳转链接给用户复制,如果需要更改带代码,请找负责人沟通
     */
    // window.location.href = 'https://www.bintools.cn/dock_query'
    message.warning('DQ协议未注册,请到DQ官网下载(https://www.bintools.cn/dock_query)')
  })
}

export const TreeNodeMenu = ({ setVisibleMenu }: any) => {
  const history = useHistory()
  const { rightClickedNode, expandedKeys, treeData } = useSelector(
    (state) => state.sdt,
  )
  const {status = false } = useSelector((state) => state.sdtPermission)
  const dispatch = useDispatch()

  const { selectedNode } = useSelector((state) => state.sdt)
  const { userId } = useSelector((state) => state.login?.userInfo)

  const { noAccessRightClick } = useSelector((state) => state.textImport)

  const {
    data: menuList,
    run: fetchMenu,
    reset: resetMenu,
  } = useRequest(getNodeMenu, {
    manual: true,
    // formatResult: (data) => 
    //   formatMenuList(data.map(getExtendedMenuItem), rightClickedNode.nodeType),
    formatResult: (data) => {
      return formatMenuList(
        [
          ...data, 
        ].map(getExtendedMenuItem), rightClickedNode.nodeType)
      }
    })
  //查询 基础设置
  const {
    run: runGetBasicSetting
  } = useRequest(getBasicSetting, {
    manual: true,
    onSuccess: (data: any) => {
      dispatch(setSdtBasicSettingData(data))
    },
  });

  useEffect(() => {
    // groupId -1 为未授权组
    if (!rightClickedNode.nodePath || rightClickedNode.groupId === -1) return
    fetchMenu({ ...rightClickedNode, userId })
    return () => {
      resetMenu()
    }
  }, [fetchMenu, resetMenu, rightClickedNode, userId])

  const operationConfirm = (
    operationName: string,
    nodeName: string,
    okFunction: Function,
    okParams?: any,
  ) => {
    Modal.confirm({
      title: `确定${operationName}：${nodeName}？`,
      onOk: () =>
        okFunction(okParams).then(() => {
          message.success(`${operationName}成功`)
          dispatch(refreshOnRoot())
        }),
      centered: true,
      okButtonProps: { danger: true },
    })
  }

  const getExtendedMenuItem = (menu: MenuEntity): MenuEntity => {
    const { menuName, menuType, valid, childMenuList } = menu
    const {
      connectionType,
      connectionId = 0,
      nodeType,
      nodeName,
      nodePath,
      groupId = 0,
      nodePermissionLimits,
      alias,
      nodePathWithType,
      funcAndProcOriginName,
    } = rightClickedNode

    const nodeParams = {
      connectionId,
      connectionType,
      nodeName,
      nodePath,
      nodeType,
    }
    const databaseName = getInfoFromPath(nodePath, 'database')
    menu.disabled = !valid
    switch (menuType) {
      case 'connectionSwitch':
        menu.disabled = !expandedKeys.includes(String(nodePath))
        // 移除关闭连接菜单
        // http://share.bintools.cn:8080/pages/viewpage.action?pageId=2176650
        // menu.hide = false
        menu.hide = true
        menu.menuName = '关闭连接'
        menu.handleClick = () => {
          closeConnection(connectionId).then(() => {
            message.success('连接关闭成功')
            dispatch(
              setExpandedKeys(expandedKeys.filter((key) => key !== nodePath)),
            )
            dispatch(refreshOnRoot())
          })
        }
        break
      case 'new':
        menu.handleClick = () => {
          switch (nodeType) {
            case 'database':
              dispatch(startCreateDatabase())
              break
            case 'schema':
              dispatch(showModal('ModalAddSchema'))
              break
            case 'table':
            case 'tableGroup':
              // 添加表/表组
              dispatch(
                addPane({
                  paneType: 'addTable',
                  connectionId,
                  connectionType,
                  databaseName,
                  nodePath,
                  nodeType,
                }),
              )
              break
            case 'view':
            case 'viewGroup':
              // ob创建视图
              const lowerConnectionType = connectionType?.toLowerCase() || ''
              if(['oceanbase', 'oceanbasemysql'].includes(lowerConnectionType)){
                menu.menuName = '创建视图'
                dispatch(
                  addPane({
                    paneType: 'obCreateView',
                    connectionId,
                    connectionType,
                    databaseName,
                    nodeName,
                    nodeType,
                    nodePath,
                    nodePathWithType,
                    groupId,
                  }),
                )
              }else {
                dispatch(
                  addPane({
                    paneType: 'addView',
                    connectionId,
                    connectionType,
                    databaseName,
                    nodePath,
                  }),
                )
              }
              break
            default:
              message.warning('暂不支持')
              break
          }
        }
        break
      case 'designTable':
        menu.handleClick = () => {
          dispatch(
            addPane({
              paneType: 'designTable',
              connectionId,
              connectionType,
              databaseName,
              nodePath,
              nodeName,
              nodeType,
            }),
          )
        }
        break
      case 'viewTableStructure':
        menu.handleClick = () => {
          dispatch(
            addPane({
              tabName: nodeName,
              paneType: 'viewTableStructure',
              connectionId,
              connectionType,
              databaseName,
              nodePath,
              nodeName,
              nodeType,
            }),
          )
        }
        break
      case 'viewViewStructureMenu':
        // 查看视图结构
        menu.handleClick = () => {
          dispatch(
            addPane({
              tabName: nodeName,
              paneType: 'viewViewStructureMenu',
              connectionId,
              connectionType,
              databaseName,
              nodePath,
              nodeName,
              nodeType,
            }),
          )
        }
        break
      case 'delete':
        menu.handleClick = () => {
          switch (rightClickedNode.nodeType) {
            case 'connectionGroup':
              operationConfirm(menuName, nodeName!, deleteSdtGroup, groupId)
              break
            default:
              operationConfirm(menuName, nodeName!, deleteSdtNode, nodeParams)
          }
        }
        break
      case 'truncateTable':
        menu.handleClick = () => {
          operationConfirm(menu.menuName, nodeName!, truncateTable, nodeParams)
        }
        break
      case 'emptyTable':
        menu.handleClick = () => {
          operationConfirm(menu.menuName, nodeName!, emptyTable, nodeParams)
        }
        break
      case 'rename':
        menu.handleClick = () => {
          dispatch(showModal('RenameSdtNode'))
        }
        break
      case 'managerGroup':
        // if (nodeType === 'connectionGroup') {
        //   menu.hide = true
        //   break
        // }
        if (nodeType === 'connection') {
          menu.childMenuList = []
          const groupId = getInfoFromPath(nodePath, 'connectionGroup')
          if (groupId !== '0') {
            menu.menuName = '移出该组'
            menu.handleClick = () =>
              removeConnectionfromSdtGroup(connectionId, groupId).then(() => {
                message.success('移出成功')
                dispatch(refreshOnRoot())
              })
          }
          menu.menuName = '移动到组'
          menu.handleClick = () => dispatch(showModal('MoveToSdtGroup'))
          break
        }
        break
      case 'openQuery':
        menu.handleClick = () => {
          // todo: 移除建立连接。查询窗口已经处理过连接创建逻辑
          const databaseName = getInfoFromPath(
            nodePath,
            'database',
            connectionType,
          )
          const schemaName = getInfoFromPath(nodePath, 'schema', connectionType)
          dispatch(
            addPane({
              tabName: alias ? alias : nodeName,
              connectionType,
              connectionId,
              databaseName,
              schemaName,
            }),
          )
        }
        break
      case 'openTerminal':
        menu.handleClick = () => {
          dispatch(
            addPane({
              paneType: 'terminal',
              connectionId,
              tabName: nodeName,
            }),
          )
        }
        break
      case 'openRedisKey':
        menu.handleClick = () => {
          // todo: 移除建立连接。查询窗口已经处理过连接创建逻辑
          // establishConnection(connectionId).then(() => {
          const databaseName = getInfoFromPath(nodePath, 'database')
          dispatch(
            addPane({
              tabName: [databaseName, nodeName].join(' / '),
              connectionType,
              connectionId,
              databaseName,
              paneType: 'grid',
              nodePath,
              nodeName,
              nodeType,
              nodePathWithType
            }),
          )
          // })
        }
        break
      case 'connectionManagement':
        menu.menuName = '连接管理'
        dispatch(setExpandedGroupNodes([]))
        dispatch(setSdtExpandedKeys([]))
        menu.handleClick = () => {
          const { parentGroupId } = rightClickedNode
          if (parentGroupId && parentGroupId > 0) {
            const nodePathArr = nodePath?.split('/')
            nodePathArr?.pop()
            const groupArr = nodePathArr?.slice(2)
            const groupNodes = groupArr?.map((value, index) => {
              const nodePath = `/root/${groupArr.slice(0, index + 1).join('/')}`
              return {
                groupId: Number(value.split('-')[1]),
                nodePath,
                nodeType: 'connectionGroup',
                key: nodePath,
              }
            })

            dispatch(setExpandedGroupNodes(groupNodes || []))
          }

          history.push('/connection_management')
          dispatch(
            setSelectedNode({
              nodeType: 'connection',
              connectionId,
              key: connectionId,
              title: nodeName!,
              connectionType,
              connectionName: nodeName,
            }),
          )
        }
        break
      case 'show':
        menu.handleClick = () => {
          if (!rightClickedNode) return
          dispatch(showViewInEditor(rightClickedNode))
        }
        break
      case 'open':
        menu.handleClick = () => {
          if (!rightClickedNode) return
          dispatch(viewElementInEditor(rightClickedNode))
        }
        break
        case 'openTaskExecution':
          menu.handleClick = () => {
            if (!rightClickedNode) return
            dispatch(openTaskExecutionInEditor(rightClickedNode))
          }
          break;
        case 'openRefreshTask': 
          menu.handleClick = () => {
            if (!rightClickedNode) return
            dispatch(openRefreshTaskInEditor(rightClickedNode))
          }
          break;
      case 'openPlSql':
      case 'openMySQLProcedure':
        menu.handleClick = () => {
          if (!rightClickedNode) return
          dispatch(openSqlEditor('plSql', rightClickedNode))
        }
        break
      case 'openTsql':
        menu.handleClick = () => {
          if (!rightClickedNode) return
          dispatch(openSqlEditor('tSql', rightClickedNode))
        }
        break
      case 'call':
        menu.handleClick = () => {
          if (!rightClickedNode) return
          dispatch(callProcedure(rightClickedNode))
        }
        break
      case 'refresh':
        menu.handleClick = () => {
          const { hasChild } = rightClickedNode
          let target: Partial<TreeNode> | null = rightClickedNode
          if (!hasChild) {
            const root = findTreeRootNode(treeData, rightClickedNode)
            target = findTreeParentNode(root, rightClickedNode)
          }
          if (!target) return
          const { connectionId, connectionType, nodeName, nodePath, nodePathWithType, nodeType } =
            target
          if (
            connectionId != null &&
            connectionType &&
            nodeName != null &&
            nodePath &&
            nodePathWithType &&
            nodeType
          ) {
            dispatch(refreshChildTreeCache(nodePath))
            dispatch(
              fetchTreeNodeChildren({
                connectionId,
                connectionType,
                nodeName,
                nodePath,
                nodePathWithType,
                nodeType,
                groupId,
                globalHavePermissionFlag: status
              }),
            )
          }
        }
        break
      case 'copy':
        menu.menuName = '复制名称'
        menu.handleClick = () => {
          if (nodeType === 'procedure' || nodeType === 'function') {
            copy(String(!!alias ? alias : funcAndProcOriginName))
          }else {
            copy(String(!!alias ? alias : nodeName))
          }
          message.success('复制成功')
        }
        break
      case 'copyConnection':
        menu.handleClick = () => {
          const dataSourceType = rightClickedNode?.connectionType
          if (!dataSourceType) return message.error('不存在的数据源类型')
          dispatch(addConnection(dataSourceType, 'copy'))
        }
        break
      case 'edit':
        menu.hide = true
        break
      case 'dumpSQL':
        menu.handleClick = () => {
          const {connectionType, groupId, nodePathWithType} = rightClickedNode
          runGetBasicSetting({
            dataSourceType: (connectionType? connectionType : ''),
            groupId: (groupId? groupId.toString() : null),
            nodePathWithTypeList: nodePathWithType?[nodePathWithType]: null,
          });
          dispatch(showModal('AddSdtNodeExport'))
        }
        break
      case 'dumpExport':
        menu.childMenuList = menu.childMenuList?.map((child) =>
          getExtendedMenuItem(child),
        )
        break

      case 'dumpImport':
        menu.menuName = connectionType === 'Oracle' ? '导入 DMP 文件' : '导入'
        menu.childMenuList = menu.childMenuList?.map((child) =>
          getExtendedMenuItem(child),
        )
        break
      case 'dumpImportSql':
        menu.handleClick = () => dispatch(showModal('SqlImportModal'))
        break
      case 'dumpExportDmp':
        menu.childMenuList = []
        menu.handleClick = () => dispatch(showModal('DumpExportModal'))
        break
      case 'moveToGroup':
        menu.handleClick = () => dispatch(showModal('MoveToSdtGroup'))
        break
      case 'createSubGroup':
        menu.handleClick = () => dispatch(showModal('AddSubSdtGroup'))
        break
      case 'updateConnAlias':
        menu.handleClick = () => dispatch(showModal('UpdateConnAlias'))
        break
      case 'connectionAccess':
        if (!Service.moduleService.isModuleExist('/flow')) {
          menu.hide = true
          break
        }
        menu.hide = menu.disabled
        menu.handleClick = async () => {
          /* 数据操作权限对应连接访问提权,默认拥有 select 权限 */
          const permissionType = 'dataSource'
          const permissionObj = find(nodePermissionLimits, { permissionType })
          const { permissionNodes } = await getPermissionContext({
            permissionType,
            nodePath: nodePath || '',
            connectionId,
            nodeName,
            permissionInfoEnum: 'SDT',
            permissionPath: permissionObj?.permissionPath || '',
          })
          dispatch(
            openFlowForm({
              type: 'connectionAccess',
              fields: {
                elements: permissionNodes,
              },
            }),
          )
        }
        break
      case 'dataExport':
        if (!Service.moduleService.isModuleExist('/flow')) {
          menu.hide = true
          break
        }
        menu.hide = menu.disabled
        menu.handleClick = async () => {
          const { connectionId, connectionType, nodeName, nodePath, nodeType, nodePathWithType } =
            selectedNode
          //const nodePathFormat = nodePath.replace('/root', '/root/0')
          dispatch(
            openFlowForm({
              type: menuType,
              fields: {
                elements: [
                  {
                    connectionId,
                    connectionType,
                    nodeName,
                    nodePath: nodePath,
                    nodePathWithType,
                    nodeType,
                  },
                ],
              },
            }),
          )
        }
        break
      case 'dataMasking':
        if (!Service.moduleService.isModuleExist('/flow')) {
          menu.hide = true
          break
        }
        menu.hide = menu.disabled
        menu.handleClick = async () => {
          const { key: permissionType } = Service.permService.getPermData({
            flowType: menuType,
          }) as any
          const permissionObj = find(nodePermissionLimits, { permissionType })
          const { permissionNodes } = await getPermissionContext({
            permissionType,
            nodePath: nodePath || '',
            connectionId,
            nodeName,
            permissionInfoEnum: 'SDT',
            permissionPath: permissionObj?.permissionPath || '',
          })
          dispatch(
            openFlowForm({
              type: menuType,
              fields: {
                elements: permissionNodes,
              },
            }),
          )
        }
        break
      case 'syncDataDict':
        menu.handleClick = () => {
          const { connectionId, connectionType, nodeType, nodeName, nodePath } =
            rightClickedNode
          postSyncDataDict({
            connectionId,
            connectionType,
            nodeName,
            nodePath,
            nodePathWithType,
            nodeType,
          }).then(() => message.success('新增同步任务成功'))
        }
        break
      case 'compileInvalidObjects':
        menu.handleClick = () => {
          dispatch(
            addPane({
              paneType: 'compileInvalidObjects',
              connectionId,
              connectionType,
              databaseName,
              nodePath,
              nodeName,
              nodeType,
            }),
          )
        }
        break
      case 'import':
        menu.handleClick = () => {
          dispatch(showModal('ModalTextImportWizard'))
        }
        break
      case 'dataSync':
        menu.handleClick = () => {    
          const { connectionId, connectionType, nodeType, nodeName, nodePath} =
            rightClickedNode
          syncMetaData({
            connectionId,
            connectionType,
            nodeName,
            nodePath,
            nodeType,
          } as any)
        }
        break
      case 'debugProcedure':
        // let param = {
        //   connId: '1',
        //   dbName: '123',
        //   schemaName: '123',
        //   elementName: 'qwe',
        //   elementType: 'procedure',
        //   dbType: 'dameng',
        //   ip: '192.168.1.32',
        // }

        menu.handleClick = () => {
          /**
           * OracleCDB表示oracle数据库12版本, Oracle表示数据库11版本
           * 12版本有三层结构: 
           * 数据库名-存储过程组名-存储过程名(节点名)
           * databaseName-schemaName-elementName
           * 
           * 11版本结构:
           * 存储过程组名-存储过程名(节点名)
           * schemaName-elementName
           */       
           let schemaName = getSchemaName(nodePath, connectionType)
          let param: any = {
            connId: connectionId,
            dbName: databaseName,
            schemaName: schemaName,
            elementName: nodeName,
            elementType: nodeType || '',
            dbType: connectionType || '',
            // eslint-disable-next-line no-restricted-globals
            host: location.host
          }
         
          if (connectionType && connectionType === 'Oracle') {
            param['schemaName'] = databaseName;
          }
          if (connectionType && connectionType === 'PostgreSQL') {
            //防止同名使用oid
            const oid = last(nodePath?.split('/')) 
            param['oid'] = oid;
          }
          debugStoreProcess(param)?.then((data: any) => {
            if (data) {
              /**
               * 此处数据处理请注意获取到的data数据为string类型，鉴于后端response返回结果data会出现异常
               * 即 ""/ data ""
               * 原因是后端返回数据再Object中重新包裹了一层Object
               * 因此前端再这里进行一遍data的parse
               */
              let dataWithHandel = JSON.parse(data)
              openBtnLink(dataWithHandel)
            } else {
              message.warning('唤起DQ失败')
            }
          })
        }

        break
      case 'connectionPool':
        menu.childMenuList = menu.childMenuList?.map((child) =>
          getExtendedMenuItem(child),
        )
        break
      case 'connectionPoolRestDelete':
        menu.handleClick = () => {
          const { connectionId } = rightClickedNode
          resetConnectionPool({
            connectionId: connectionId!,
            type: 'DELETE',
          }).then(() => message.success('回收成功'))
        }
        break
      case 'connectionPoolRestUpdate':
        menu.handleClick = () => {
          const { connectionId } = rightClickedNode
          resetConnectionPool({
            connectionId: connectionId!,
            type: 'UPDATE',
          }).then(() => message.success('重置成功'))
        }
        break
        case 'batchExecute':
        menu.handleClick = () => {
          dispatch(setCreateBatchExecuteModalVisible(true))
        }
        break
      case 'createFunction':
        menu.handleClick = () => {
          dispatch(
            addPane({
              paneType: 'createFunction',
              connectionId,
              connectionType,
              databaseName,
              nodePath,
            }),
          )
        }
        break
      case 'createProcedure':
        menu.handleClick = () => {
          dispatch(
            addPane({
              paneType: 'createProcedure',
              connectionId,
              connectionType,
              databaseName,
              nodePath,
            }),
          )
        }
        break
      default:
    }
    if (!childMenuList) menu.childMenuList = []
    return menu
  }

  const renderMenuItem = (menu: MenuEntity) => {
    const { childMenuList, menuName, menuType, handleClick, disabled, hide } =
      menu
    if (hide) return null
    if (!childMenuList[0]) {
      return menuType === 'divider' ? (
        <Menu.Divider key={menuName} />
      ) : (
        <Item
          key={menuName}
          disabled={disabled}
          onClick={(e) => handleClick && handleClick()}
          style={{ height: 28, lineHeight: '28px' }}
        >
          {menuName}
        </Item>
      )
    }
    return (
      <SubMenu key={menuName} title={menuName} disabled={disabled}>
        {childMenuList.map((menu) => renderMenuItem(menu))}
      </SubMenu>
    )
  }

  return (
    <Menu
      triggerSubMenuAction="click"
      onClick={(e) => setVisibleMenu(false)}
      // todo: 按照文档，Dropdown 下的 Menu 默认不可选中，但表现不如预期，需要手动指定 selectable 为 false
      selectable={false}
    >
      {noAccessRightClick ? [] : menuList?.map((menu) => renderMenuItem(menu))}
    </Menu>
  )
}

/**
 * 对后端返回的 menu list 进行分组和重排
 */
function formatMenuList(list: MenuEntity[], nodeType?: SdtNodeType) {
  if (!nodeType) return list
  if (databaseLike.includes(nodeType)) {
    list = resortDatabaseMenu(list)
  }
  if (nodeType === 'table') {
    list = regroupTableMenu(list)
  }
  return list
}

/**
 * @description 对数据库层级节点的右键菜单重新排序
 * @param list 菜单
 * @param nodeType 触发菜单的节点的类型
 * @return sorted list
 */
function resortDatabaseMenu(list: MenuEntity[]) {
  const sortedType: (MenuType | 'others')[] = [
    'edit',
    'new',
    'dumpSQL',
    'delete',
    'others',
    'copy',
    'refresh',
  ]
  return list.sort((a, b) => {
    const aType = sortedType.includes(a.menuType) ? a.menuType : 'others'
    const bType = sortedType.includes(b.menuType) ? b.menuType : 'others'
    const aIndex = sortedType.indexOf(aType)
    const bIndex = sortedType.indexOf(bType)
    return aIndex - bIndex
  })
}

/**
 * @description 对表节点的右键菜单分组和重排
 * @param list 菜单
 * @param nodeType 触发菜单的节点的类型
 * @return grouped list
 */
function regroupTableMenu(list: MenuEntity[]) {
  // 分组排序规则
  const g1: MenuType[] = [
    'open',
    'designTable',
    'new',
    'delete',
    'emptyTable',
    'truncateTable',
  ]
  const g2: MenuType[] = ['viewTableStructure', 'dumpSQL']
  const g3: MenuType[] = ['dataMasking', 'dataExport']
  const g4: MenuType[] = ['copy', 'refresh', 'compileInvalidObjects']

  // 分组 list
  const m1: MenuEntity[] = []
  const m2: MenuEntity[] = []
  const m3: MenuEntity[] = []
  const m4: MenuEntity[] = []
  const other: MenuEntity[] = []

  const entries: [MenuType[], MenuEntity[]][] = [
    [g1, m1],
    [g2, m2],
    [g3, m3],
    [g4, m4],
  ]

  // group
  for (const el of list) {
    const target = entries.find(([g]) => g.includes(el.menuType))
    if (!target) {
      other.push(el)
      continue
    }
    target[1].push(el)
  }

  // sort
  const ml = entries.map(([g, m]) => sortMenu(g, m))
  // other 插入 m3 和 m4 之间（业务要求）
  ml.splice(-2, 0, other)
  return ml.reduce((prev, curr, index, arr) => {
    const isLast = index === arr.length - 1
    const shown = curr.filter(({ hide }) => !hide)
    const isEmpty = !shown.length
    // 虚假的 menuItem
    const divider = {
      menuType: 'divider',
      menuName: `divider-${index}`,
      childMenuList: [],
    } as any
    return prev.concat(curr).concat(isLast || isEmpty ? [] : divider)
  }, [])
}

function sortMenu(group: MenuType[], menu: MenuEntity[]) {
  return menu.sort((a, b) => {
    const aIndex = group.indexOf(a.menuType)
    const bIndex = group.indexOf(b.menuType)
    return aIndex - bIndex
  })
}
