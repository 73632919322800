import { AppSecretRecord } from "src/pageTabs/appSecretPage/AppSecret";
import { fetchDelete, fetchGet, fetchPost, fetchPut } from "./customFetch";

/** 获取企业名称 */
export const getCompanyName = () => {
  return fetchGet(`/user/sys/company`);
};

/** 修改企业名称 */
export const updateCompanyName = (company: string) => {
  return fetchPut(`/user/sys/company/${company}`);
};

/** 获取邮箱设置 */
export const getEmailConfig = () => {
  return fetchGet(`/user/sys/email`);
};

export interface EmailConfigEntity {
  authCode: string;
  emailAddress: string;
  host: string;
  port: number;
  tls: boolean;
}

/** 修改邮箱设置 */
export const updateEmailConfig = (params: Partial<EmailConfigEntity>) => {
  return fetchPut(`/user/sys/email`, params);
};

/** 测试邮箱设置 */
export const testEmailConfig = (params: Partial<EmailConfigEntity>) => {
  return fetchPost(`/user/sys/email/test`, params);
};

// 获取双因素认证项
export const getTwoFactor = () => {
  return fetchGet(`/user/sys/twoFactor`);
};

// 修改双因素认证项
export const postTwoFactor = (type: string) => {
  return fetchPost(`/user/sys/setTwoFactor?twoFactorEnum=${type}`);
};

//获取失效时间项
export const getExpireTimeByMin = (): Promise<number> => {
  return fetchGet(`/user/session/timeout`);
};

//修改失效时间项
export const postOvertime = (int: number) => {
  return fetchPost(`/user/session/timeout?timeout=${int} `);
};

export const getCopy = () => {
  return fetchGet(`/user/sys/copy`);
};

export const postCopy = (flag: boolean) => {
  return fetchPost(`/user/sys/copy/${flag}`);
};

export const getWatermark = () => {
  return fetchGet(`/user/sys/watermark`);
};

export const postWatermark = (flag: boolean) => {
  return fetchPost(`/user/sys/watermark/${flag}`);
};

export const getTabMax = () => {
  return fetchGet(`/user/sys/tab/max`);
};

export const postTabMax = (count: number) => {
  return fetchPost(`/user/sys/tab/max/${count}`);
};

export const getFileMax = () => {
  return fetchGet(`/user/sys/file/max`);
};

export const postFileMax = (count: number) => {
  return fetchPost(`/user/sys/file/max/${count}`);
};

/* 初始化菜单权限列表 */
export const getInitMenuPerms_api = () => {
  return fetchGet(`/user/permission/permission/menu`);
};

/* 编辑初始化菜单权限 */
export const postInitMenuPerms_api = (data: string[]) => {
  return fetchPost(`/user/permission/update/init/menu`, data);
};

/** 编辑指定的 Owner 用户 */
export const updateOwners = (userIds: (string | number)[] = []) => {
  return fetchPost(`/user/owner/applications/owner/update/user`, { userIds });
};

/** 编辑 指定 Owner toggle 的状态 (true/false) */
export const updateOwnerToggleStatus = (checked: boolean) => {
  return fetchGet(`/user/owner/applications/update/owner/${checked}`);
};

/** 获取 指定 Owner toggle 的状态  */
export const getOwnerToggleStatus = () => {
  return fetchGet(`/user/owner/applications/owner/status`);
};

/** 获取 登出提示 状态  */
export const getLogOutPrompt = () => {
  return fetchGet(`/user/sys/saveEditorContent`);
};

/** 设置 登出提示 状态 */
export const postLogOutPrompt = (flag: boolean) => {
  return fetchPost(`/user/sys/saveEditorContent/${flag}`);
};
/** 获取 行级锁是否开启 **/
export const getRowLock = () => {
  return fetchGet("/user/sys/forUpdate");
};
/** 设置 行级锁开启状态 **/
export const postRowLock = (flag: boolean) => {
  return fetchPost(`/user/sys/forUpdate/${flag} `);
};
/** 获取 导入数量 **/
export const getImportNumber = () => {
  return fetchGet("/user/sys/queue/import");
};
/** 获取 导出数量 **/
export const getExportNumber = () => {
  return fetchGet("/user/sys/queue/export");
};
/** 设置 导入数量 **/
export const postImportNumber = (count: number) => {
  return fetchPost(`/user/sys/queue/import/${count}`);
};
/** 设置 导入数量 **/
export const postExportNumber = (count: number) => {
  return fetchPost(`/user/sys/queue/export/${count}`);
};
/** 获取 登录目的开关 */
export const getLoginPurpose = (): Promise<boolean> => {
  return fetchGet(`/user/sys/loginPurpose`);
};
export const getDataMigration = async (): Promise<boolean> => {
  return await fetchGet(`/user/cloudquery/all/dataMigration`);
};
/** 设置 登录目的开关 */
export const postLoginPurpose = (flag: boolean) => {
  return fetchPost(`/user/sys/loginPurpose/${flag}`);
};
/** 设置 登录目的 */
export const postLoginPurposeContent = (param: { purpose: string }) => {
  return fetchPost(`/user/sys/loginPurpose`, param);
};

/**
 * sql解析错误显示开关（实际上是权限检查开关）
 * 后端定义的是同一个接口
 * 获取数据调用接口不传参数，修改数据调用接口需要传参数
 * */

interface SqlMerrorIgnore {
  dataSourceType: string;
  flag: boolean;
}

/** 获取解析模式配置 */
export const getSqlMerrorIgnore = () => {
  return fetchGet(`/user/sys/strictmode`);
};

/** 保存解析模式配置 */
export const postSqlMerrorIgnore = (param: SqlMerrorIgnore[]) => {
  return fetchPost(`/user/sys/batchsavemode`, param);
};

/** IP 白名单开关 */
export const getIPWhiteSwitch = () => {
  return fetchGet(`/user/sys/openIPWhiteList`);
};

export const postIPWhiteSwitch = (openIPWhiteList: boolean) => {
  return fetchPost(`/user/sys/openIPWhiteList/${openIPWhiteList}`);
};
interface ICasConfig {
  casSwitch?: boolean;
  casServerLoginUrl?: string;
  casServerValidateUrl?: string;
  casServerLogoutUrl?: string;
  casCQServerName?: string;
  casUserNameSuffix?: string;
}

interface IAdConfig {
  adDirectSwitch?: boolean;
  adDirectLdapAddress?: string;
  adDirectSearchBase?: string;
  adDirectUsernameSuffix?: string;
}

interface ILdapConfig {
  adminSecret: string; // 管理员密码
  openLdapAdminName: string; // 管理员账号
  openLdapSwitch: boolean; // OpenLdap开关
  openLdapUrl: string; // OpenLdap登录地址
  searchScope: string; // 搜索范围
}

export interface IOauthConfig {
  oauth2Switch: boolean;
  clientId: string;
  clientSecret: string;
  userAuthorizationUri: string;
  accessTokenUri: string;
  oauth2CQServerUrl: string;
  resourceUserInfoUrl: string;
  oauthUserNameSuffix: string;
}
/** 保存oauth配置 */
export const putOauthConfig = (params: IOauthConfig) => {
  return fetchPut(`user/sys/oauth2`, params);
};

/** 获取oauth配置 */
export const getOauthConfig = () => {
  return fetchGet(`user/sys/oauth2`);
};
/** 获取cas配置 */
export const getCasConfig = () => {
  return fetchGet(`user/sys/cas `);
};
/** 保存cas配置 */
export const putCasConfig = (params: ICasConfig) => {
  return fetchPut(`user/sys/cas`, params);
};

/** 获取 ad 域配置 */
export const getAdConfig = () => {
  return fetchGet(`user/sys/adDirect`);
};
/** 更新 ad 域配置 */
export const putAdConfig = (params: IAdConfig) => {
  return fetchPut(`user/sys/adDirect`, params);
};
/** 获取 ukey 列表 */
export const getUkeyList = () => {
  return fetchGet(`/user/ukey/ukeys`);
};
interface UkeyItem {
  admin: boolean;
  deviceId: string;
  status: boolean;
  userId: string;
}
/** 删除 ukey  */
export const delUkey = (params: UkeyItem) =>
  fetchPost("/user/ukey/ukeys/delete", params);
/**  更新 ukey **/
export const updateUkey = (params: Partial<UkeyItem>) =>
  fetchPost("/user/ukey/ukeys/update", params);
/**  新增 ukey **/
export const addUkey = (params: Partial<UkeyItem>) =>
  fetchPost("/user/ukey/ukeys", params);

/** 获取流程-连接访问自动赋予select权限开关项 */
export const getFlowSelectPerm = () => {
  return fetchGet("/user/sys/connectionFlow");
};
/** 设置流程-连接访问自动赋予select权限开关项 */
export const postFlowSelectPerm = (flag: boolean) => {
  return fetchPost(`/user/sys/connectionFlow/${flag}`);
};

/** 权限看板开关 */
interface IDashSwitch {
  status: boolean;
}
export const dashboardSwitchPost = (params?: IDashSwitch) => {
  return fetchPost(`/user/sys/dashBoard/status`, params);
};

/** 获取OpenLdap 配置 */
export const getLdapConfig = () => {
  return fetchGet("/user/sys/openLdap");
};

/** 保存OpenLdap 配置 */
export const putLdapConfig = (params: ILdapConfig) => {
  return fetchPut("/user/sys/openLdap", params);
};

interface IExecLog {
  pagination: {
    page: number;
    pageSize: number;
  };
  sqlName?: string;
}

/**宽松模式 导出 */
export const exportStrictModeLog = (params: { sqlName?: string }) => {
  return fetchPost("/user/sys/export/strictmode", params);
};

/** 获取用户宽松模式下的执行记录 */
export const postExecLog = (params: IExecLog) => {
  return fetchPost("/user/history/getParsingFailedRecords", params);
};

/** 版本信息 */
export const getVersion = () => {
  return fetchGet("/user/versionInfo");
};

/**version信息 */
export const getVersionIsCommunity = () => {
  return fetchGet("/user/sys/version");
};

/** 授权管理信息 */
export const getAuthorizationMessage = () => {
  return fetchGet("/dms/versionInfo/authorMessage");
};

/** 获取cq3/6/9版本信息 */
export const getCq369 = () => {
  return fetchGet("/dms/versionInfo/productGrade");
};

interface IAppKeyParams {
  appKey: string;
  user: string;
  note?: string;
}

/** 系统管理-AppSecret- appKey list */
export const getAppKeyList = (params: any): Promise<any> => {
  return fetchPost("/gateway/cq/token/list", params);
};
/** 系统管理-AppSecret- 生成 */
export const appKeyGenerate = (params: IAppKeyParams): Promise<string> => {
  return fetchPut("/gateway/cq/token", params);
};
/** 系统管理-AppSecret- 重置 */
export const appKeyReset = (params: IAppKeyParams): Promise<string> => {
  return fetchPost("/gateway/cq/token", params);
};
/** 系统管理-AppSecret- 编辑 */
export const appKeyEdit = (params: {
  id: number;
  note: string;
}): Promise<string> => {
  return fetchPut(`/gateway/cq/token/${params.id}`, params);
};
/** 系统管理-AppSecret- 删除 */
export const appKeyDelete = (id: number): Promise<string> => {
  return fetchDelete(`/gateway/cq/token/${id}`);
};
interface ISysPasswrdPolicy {
  maxDay: string | number;
  noRepeatCount: string | number;
  expireWarningDay: string | number;
  maxFailureCount: string | number;
  lockoutMinute: string | number;

  passwordMax?: string | number;
  passwordMin?: string | number;
  containDigits?: boolean;
  containUpperLetters?: boolean;
  containLowerLetters?: boolean;
  containSymbols?: boolean;
  systemPasswordStrong?: boolean;
  defaultPassword?: string | number;
}
/** 密码域配置 获取密码策略 */
export const getSysPasswordPolicy = () => {
  return fetchGet("/user/sys/passwordPolicy");
};
/** 密码域配置 修改密码策略 */
export const updateSysPasswordPolicy = (
  params: ISysPasswrdPolicy
): Promise<string> => {
  return fetchPut("/user/sys/passwordPolicy", params);
};

/**
 * 系统设置 水印
 */
export const setWatermarkSetting = (params: any): Promise<any> => {
  return fetchPost("/user/sys/watermark/setting", params);
};
/**
 * 系统设置 获取水印
 */
export const getWatermarkSetting = (type: string) => {
  return fetchGet(`/user/sys/watermark/setting?type=${type}`);
};

/* 系统设置 用户登录限制 IP白名单*/
export const getUserLoginIpList = () => {
  return fetchGet(`/user/login/ip/list`);
};

export const postUserLoginIpList = (params: any) => {
  return fetchPost(`/user/login/ip/list`, params);
};

/* 系统设置  文件读取/上传 限制*/
export const getUserSysFile = () => {
  return fetchGet(`/user/sys/file/setting`);
};

export const postUserSysFile = (params: {
  readSize: string | number;
  uploadSize: string | number;
}) => {
  return fetchPost(`/user/sys/file/setting`, params);
};

/* 系统设置  获取帐号锁定 期限*/
export const getUserSysLogin = () => {
  return fetchGet(`/user/sys/login/term`);
};


export const postUserSysLogin = (days: number | string) => {
  return fetchPost(`/user/sys/login/term/${days}`);
};

/* 系统设置  获取结果集导出加密*/
export const getResultEnc = () => {
  return fetchGet(`/user/sys/export/encrypt`);
};

export const postResultEnc = (isEnc:boolean) => {
  return fetchPost(`/user/sys/encrypt/${isEnc}`);
};

/** 系统设置  获取是否可以多设备登录 */
export const getMultipleDeviceLogin = () => {
  return fetchGet(`/user/sys/multipleDeviceLogin/setting`);
};

/** 系统设置  设置是否可以多设备登录 */
export const postMultipleDeviceLogin = (mul: boolean) => {
  return fetchPost(`/user/sys/multipleDeviceLogin/setting/${mul}`);
};

/** 系统设置  获取是否全局强制otp登录 */
export const getOtpForceLogin = () => {
  return fetchGet(`/user/sys/globalOtpLogin/setting`);
};

/** 系统设置  设置是否全局强制otp登录 */
export const postOtpForceLogin = (val: boolean) => {
  return fetchPost(`/user/sys/globalOtpLogin/setting/${val}`);
}

/* 系统设置  设置资源搜索页是否展示所有资源*/
export const postApplyResourceSearchSetting = (flag: boolean) => {
  return fetchPost(`/user/sys/applyResourceSearch/setting/${flag}`);
};

/* 系统设置  获取资源搜索页是否展示所有资源*/
export const getApplyResourceSearchSetting = () => {
  return fetchGet(`/user/sys/applyResourceSearch/setting`);
};

/**
 * 系统设置-手机短信设置 查看自定义消息网关接口
 */
export const getSms = () => {
  return fetchGet(`/user/sys/sms`);
};

/**
 * 删除 告警配置
*/
export const delSms = (id: string | number) => {
  return fetchDelete(`user/sys/sms?id=${id}`);
};

/**
 * 短信网关-告警配置
 * 服务参数可用的系统变量名，这个接口是让用户在填网关的时候选择业务，可以用的参数
*/
export const getParam = (businessType: string): Promise<{
  description: string;
  name: string;
}[]> => {
  return fetchGet(`/user/sys/param?businessType=${businessType}`);
};

/**
 * 获取系统用户登录设置
 */
export const getUserLoginSetting = (): Promise<{
  value: boolean;
}> => {
  return fetchGet(`/user/sys/forceLogin`);
};
// 设置用户登录方式
export const putUserLoginSetting = (value: string) => {
  return fetchPut(`/user/sys/forceLogin?value=${value}`);
};

/**
 * 系统设置-手机短信设置 更改消息接口网关 url：/user/sys/sms
 */
export interface StringMap {
  [key: string]: string;
}
export interface IParmas {
  // 网关地址
  baseUrl: string;
  // 网关请求方式 GET POST
  method: string;
  // 请求头字段
  headers?: StringMap;
  // url参数字段
  urlParams: StringMap;
  // body数据，只有请求方式为post时生效
  body?: string;
  phone?: string;
  [p: string]: any;
}

/**
 * 更改消息接口网关
 */
export const updateSms = (params: IParmas) => {
  return fetchPost(`/user/sys/sms`, params);
};
/**
 * 系统设置-手机短信设置 发送验证短信，测试短信网关
 */
export const smsTestPhone = (params: IParmas) => {
  return fetchPost(`/user/sys/sms/test`, params);
};

// json转map
export const jsonToMap = (jsonData: string) => {
  // 解析 JSON 数据为 JavaScript 对象
  const parsedData = JSON.parse(jsonData);

  // 创建空的 Map 实例
  const resultMap: Map<string, string> = new Map();

  // 将属性添加到 Map 中
  for (const key in parsedData) {
    if (Object.prototype.hasOwnProperty.call(parsedData, key)) {
      const value = parsedData[key];
      resultMap.set(key, String(value));
    }
  }
  return resultMap;
};
// 二维数组转map
export const twoArrToMap = (arr: string[][] | undefined) => {
  // 创建空的 Map 实例
  const resultMap: Map<string, string> = new Map();

  // 将二维数组转换为 Map
  arr?.forEach(([key, value]) => {
    resultMap.set(key, value);
  });
  return resultMap;
};
// map 转 StringMap {[key: string]: string}
export const convertMapToStringMap = (map: Map<string, string>): StringMap => {
  const stringMap: StringMap = {};
  for (const [key, value] of map) {
    stringMap[key] = value;
  }
  return stringMap;
};

export const addParamToJsonData = (
  map: Map<string, string>,
  jsonData: { [x: string]: string }
) => {
  for (let key in jsonData) {
    if (jsonData.hasOwnProperty(key)) {
      map.set(key, jsonData[key]);
    }
  }
  return map;
};

// body
export const validateJson = (
  _: any,
  value: any,
  cb: any,
  isNotEmpty: boolean
) => {
  if (isNotEmpty || (!isNotEmpty && value)) {
    try {
      JSON.parse(value);
      // return Promise.resolve();
      cb();
    } catch (error) {
      // return Promise.reject(new Error('请输入正确的 JSON 格式'));
      cb("请输入正确的 JSON 格式");
    }
  }
  // return Promise.resolve();
  cb();
};

// phone
export const validatePhone = (_: any, value: any) => {
  const phoneRegex = /^1[0-9]{10}$/; // 正则表达式，用于匹配中国大陆手机号码
  if (value && !phoneRegex.test(value)) {
    return Promise.reject("请输入有效的手机号码！");
  }
  return Promise.resolve();
};

// json对象转json字符串
export const getJsonStr = (jsonData: { [x: string]: string }) => {
  let urlParamsStr: string = "";
  const keys = Object.keys(jsonData);
  for (let key in jsonData) {
    if (jsonData.hasOwnProperty(key)) {
      urlParamsStr += '"' + key + '":' + jsonData[key];
    }
    if (key !== keys[keys.length - 1]) {
      // 不是最后一个json对象
      if (jsonData.hasOwnProperty(key)) {
        urlParamsStr += ",";
      }
    }
  }
  urlParamsStr = "{" + urlParamsStr + "}";
  return urlParamsStr;
};
export interface IAccessProps {
  id?: number;
  ipAddr: string;
  type: number; //表单中的访问策略， 0为白名单， 1为黑名单
  comment?: string;
  updatedAt?: string;
}

export interface IFilterAccessParams {
  condition?: number | undefined;
}

export interface ConListType {
  pageNo?: number,
  pageSize?: number,
  type?: number,
  ipAddr?: string,
  comment?:string
}

/* 获取系统访问限制信息列表 */
export const getAccessList = (
  condition: number | undefined
): Promise<IAccessProps[]> => {
  return fetchGet(`/user/sys/access/restriction/list?condition=${condition}`);
};

/* 根据条件获取系统访问限制信息列表 */

export const getConList = (params:ConListType) => {
  return fetchPost(`/user/sys/access/restriction/list`,params)
}

/* 新增/修改 系统访问限制信息时，校验是否有在线用户被影响 */
export const checkAccess = (params: IAccessProps) => {
  return fetchPost(`/user/sys/access/restriction/update/check`, params);
};

/* 新增/修改 系统访问限制信息 */
export const updateAccess = (params: IAccessProps) => {
  return fetchPost(`/user/sys/access/restriction/update`, params);
};

/* 删除 系统访问限制信息时，校验是否有在线用户被影响 */
export const checkDeleteAccess = (id: number) => {
  return fetchDelete(`/user/sys/access/restriction/check/${id}`);
};

/* 删除访问资源 */
export const deleteAccess = (id: number) => {
  return fetchDelete(`/user/sys/access/restriction/${id}`);
};

/* 访问系统之前 校验接口 */
export const checkIp = () => {
  return fetchGet(`/user/sys/check/access/ip`);
};

/* 数据操作 短信复核-获取验证码 */
interface checkSmsParams {
  connectionId: number | string;
  queryTabKey: string;
  [p: string]: any;
}
export const getCheckSms = (params: checkSmsParams) => {
  return fetchGet(`/user/sys/check/sms`, params);
};

/* 判断传sql时是否需要编码 */
export const ifBase64Encode = () => {
  return fetchGet(`/user/sys/ifBase64Encode`);
};