// 自定义勾选列表项
import React, { useEffect, useState } from 'react'
import { Drawer, Checkbox, Button } from "antd"
import {
  USER_AUDIT_AUTH_DETAIL_COLUMN,
  USER_AUDIT_AUTH_DETAIL_DEFAULT_COLUMN,
  AUTH_BOARD_DETAIL_COLUMN,
  AUTH_BOARD_DETAIL_DEFAULT_COLUMN,
  USER_AUDIT_SQL_DETAIL_COLUMN,
  USER_AUDIT_SQL_DETAIL_DEFAULT_COLUMN
} from 'src/constants'
import styles from './index.module.scss'

interface IProps{
	type: string
	visible: boolean
	setVisible: (v: boolean)=>void
	handleOk: (p: any[])=>void
	defaultValue?: string[]
}
const CustomColumnsDrawer = (props: IProps)=>{
	const {visible, setVisible, type='', defaultValue, handleOk } = props
	const [options, setOptions] = useState<any[]>([])
	const [checkedValues, setCheckedValues] = useState<any[]>([])

	useEffect(()=>{
    let detail_column: any
    let detail_default_column: any
    switch(type) {
      case 'auth_detail':
        detail_column = USER_AUDIT_AUTH_DETAIL_COLUMN
        detail_default_column = USER_AUDIT_AUTH_DETAIL_DEFAULT_COLUMN
        break
      case 'sql_detail':
        detail_column = USER_AUDIT_SQL_DETAIL_COLUMN
        detail_default_column = USER_AUDIT_SQL_DETAIL_DEFAULT_COLUMN
        break
      case 'auth_board':
        detail_column = AUTH_BOARD_DETAIL_COLUMN
        detail_default_column = AUTH_BOARD_DETAIL_DEFAULT_COLUMN
        break
    }
		const options = transformObjToArr(detail_column, detail_default_column) 
		setOptions(options)
	},[type])

	useEffect(()=>{
		if(defaultValue?.length){
			const checkedValues = defaultValue?.map((i: any)=>i?.dataIndex)
			setCheckedValues(checkedValues)
		}else{
			setCheckedValues(USER_AUDIT_SQL_DETAIL_DEFAULT_COLUMN)
		}
	},[type, defaultValue])
	
	const transformObjToArr = (obj: any, defaultCheck: any[]) => {
		return Object.keys(obj).map((i: any)=>{
			return {
				label: obj[i],
				value: i,
				disabled: defaultCheck.includes(i)
			}
		})
	}

	const onClose = ()=>{
		setVisible(false)
	}

	const handleCheckboxChange = (checkedValues: any[]) =>{
		setCheckedValues(checkedValues)
	}
	
	return <Drawer 
			visible={visible}
			onClose={onClose} 
			title='自定义列表项'
			className={styles.customColumnsDrawer}
			footer={<>
			<Button type='primary' onClick={()=>handleOk(checkedValues)}>确定</Button>
			<Button style={{marginLeft: 10}} onClick={onClose}>取消</Button>
			</>}
		>
		<Checkbox.Group  value={checkedValues} options={options} onChange={handleCheckboxChange} />
	</Drawer>
}

export default CustomColumnsDrawer