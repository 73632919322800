import React from 'react'
import styles from '../index.module.scss'
import { Modal } from 'antd'

interface IProps  {
  title: any,
  top: string,
  right: string,
  content: string,
  okText: string,
  cancelText: string,
  visible: boolean,
  setVisible: (b: boolean) => void,
  [p: string]: any,
}

const MarkModal = (props: IProps) => {
  const { title, top, right, content, okText, cancelText, visible, setVisible, next } = props;

  const handleOk = () => {
    setVisible(false)
    next()
  }

  return (
    <Modal
      key={'guideModal'}
      title={title}
      visible={visible}
      closable={false}
      onOk={handleOk}
      onCancel={() => setVisible(false)}
      okText={okText}
      cancelText={cancelText}
      className={styles.guideMark}
      style={{
        position: 'absolute',
        right: right,
        top: top,
      }}
      zIndex={500}
      cancelButtonProps={{
        style: {
          backgroundColor: '#AAAAAA',
          color: 'white'
        }
      }}
    >
      {content}
    </Modal>
  )
}
export default MarkModal