import { DataNode } from "antd/lib/tree";

export const treeData: DataNode[] = [
  {
    title: '全部消息',
    key: 'allMessage',
  },
  {
    title: '未读消息',
    key: 'notReadMessage',
  },
  {
    title: '已读消息',
    key: 'readMessage',
  }
];