import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getExportList, getImportList, getSyncDictList, queryBatchSql } from 'src/api';
import { AppThunk } from 'src/store';

interface myTaskCenterState {
  tabsKey: string;
  loading: boolean;
  exportInfo: any;
  batchSqlInfo: any;
  tableInfo: any;
}
const initialState: myTaskCenterState = {
  tabsKey: 'handleExport',
  loading: false,
  exportInfo: {},
  batchSqlInfo: {},
  tableInfo: {},
}
export const myTaskCenterSlice = createSlice({
    name: 'myTaskCenter',
    initialState,
    reducers: {
      setTabsKey: (state, action: PayloadAction<string>) => {
        state.tabsKey = action.payload;
      },
      setLoading: (state, action: PayloadAction<any>) => {
        const newValue = action.payload
        state.loading = newValue;
      },
      setExportInfo:(state, action: PayloadAction<any>) => {
        const newValue = action.payload
        state.exportInfo = {...state.exportInfo, ...newValue};
      },
      setBatchSqlInfo:(state, action: PayloadAction<any>) => {
        const newValue = action.payload
        state.batchSqlInfo = {...state.batchSqlInfo, ...newValue};
      },
      setTableInfo:(state, action: PayloadAction<any>) => {
        const newValue = action.payload
        state.tableInfo = {...newValue};
      },
    }
})

export const myTaskCenterReducer = myTaskCenterSlice.reducer;

export const { 
  setTabsKey,
  setLoading,
  setExportInfo,
  setBatchSqlInfo,
  setTableInfo,
} = myTaskCenterSlice.actions;

// 导出
export const queryAndSetExportList =
  (params?: any): AppThunk =>
  (dispatch) => {
    dispatch(setLoading(true))
    getExportList(params).then((res) => {
      dispatch(setTableInfo({...res, paraments: params}))
    }).catch((err: any)=>{
      console.error('查询失败', err)
    }).finally(()=>{
      dispatch(setLoading(false))
    })
  }

// 导入
export const queryAndSetImportList =
(params?: any): AppThunk =>
(dispatch) => {
  dispatch(setLoading(true))
  getImportList(params).then((res) => {
    dispatch(setTableInfo({...res, paraments: params}))
  }).catch((err: any)=>{
    console.error('查询失败', err)
  }).finally(()=>{
    dispatch(setLoading(false))
  })
}

// 批量执行
export const queryBatchSqlList =
  (params: any): AppThunk =>
    (dispatch) => {
      dispatch(setLoading(true))
      queryBatchSql(params).then((res) => {
        dispatch(setTableInfo({...res, paraments: params}))
      }).catch((err: any)=>{
        console.error('查询失败', err)
      }).finally(()=>{
        dispatch(setLoading(false))
      })
    }

// 同步数据字典
export const querySyncDictList =
  (params: any): AppThunk =>
    (dispatch) => {
      dispatch(setLoading(true))
      getSyncDictList(params).then((res) => {
        dispatch(setTableInfo({...res, paraments: params}))
      }).catch((err: any)=>{
        console.error('查询失败', err)
      }).finally(()=>{
        dispatch(setLoading(false))
      })
    }
