import React, { FC, useEffect } from 'react'
import { Button, Card, DatePicker, Modal, Form, Input, message, Radio } from 'antd'
import classnames from 'classnames'
import { useForm } from 'antd/lib/form/Form'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { useRequest, useSelector } from 'src/hook'
import { saveDraftFat, startFlowInstance } from 'src/api'
import { useHistory } from 'react-router-dom'
import { FormTailLayoutTwo } from 'src/constants';
import styles from '../index.module.scss'
moment.locale('zh-cn')

const { RangePicker } = DatePicker;

const FormLayout = {
  labelCol: { span: 6 },

}

interface ListProps {
  onOk: (values: any) => void;
  onCancel: () => void;
}

export const SubmitApplicationModal: FC<ListProps> = (props) => {

  const {  onCancel, onOk } = props
  const [form] = useForm()

  const onSubmit = () => {
    form.validateFields().then().then((values: any) => {


      let params: any
      if (values.timeType === 'custom') {
        const beginTime = values?.time[0].format('YYYY-MM-DD HH:mm:ss');
        const endTime = values?.time[1].format('YYYY-MM-DD HH:mm:ss');

        const e = moment(endTime).valueOf();

        if (e < moment().valueOf()) {
          return message.error('请重新选择时间，结束时间必须大于当前时间')
        }
        params = {
          title: values?.title,
          remark: values?.remark,
          beginTime,
          endTime
        }
      } else {
        params = {
          title: values?.title,
          remark: values?.remark,
        }
      }


      onOk({ ...params })
    })
  }

  const disabledDate = (current: any) => {
    return current && current < moment().startOf('day');
  };
  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  const disabledTime = (now: any, type: any) => {

    let time: any = moment();
    let hour = moment(time).hour();
    let minute = moment(time).minute();
    let second = moment(time).second();
    if (type === 'start') {
      return ({
        disabledHours: () => range(0, hour),
        disabledMinutes: () => range(0, minute),
        disabledSeconds: () => range(0, second)
      })
    }
    return {}
  }
  return (
    <Modal title="提交申请" visible
     onOk={() => onSubmit()}
     onCancel={onCancel}
    >
        <Form form={form} {...FormTailLayoutTwo} >
          <Form.Item label="生效时间" name="timeType" required
          initialValue='forever'
            rules={[{required: true, message: '请选择生效时间'}]}
          >
            <Radio.Group>
              <Radio value="forever">永久</Radio>
              <Radio value="custom">自定义时间段</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues?.timeType !== currentValues?.timeType
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("timeType") === "custom" ? (
                <Form.Item
                  label="时间段"
                  name="time"
                  rules={[
                    { required: true },
                    {
                      validator(_rule, value, cb) {
                        const e = moment(value?.[1]).valueOf();

                        if (e < moment().valueOf()) {
                          cb("请重新选择时间，结束时间必须大于当前时间");
                        }
                        cb();
                      },
                    },
                  ]}
                >
                  <RangePicker
                    disabledDate={disabledDate}
                    disabledTime={disabledTime}
                    ranges={{
                      近7日: [moment(), moment().add(7, "d")],
                      近15日: [moment(), moment().add(15, "d")],
                      近30日: [moment(), moment().add(30, "d")],
                    }}
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment("00:00:00", "HH:mm:ss"),
                        moment("11:59:59", "HH:mm:ss"),
                      ],
                    }}
                    format="YYYY-MM-DD HH:mm:ss"
                  />
                </Form.Item>
              ) : null
            }
          </Form.Item>
          <Form.Item
            label="标题"
            name="title"
            rules={[{ required: true }, { max: 20, min: 2 }]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            label="备注"
            name="remark"
          >
            <Input.TextArea allowClear maxLength={100} />
          </Form.Item>
        </Form>
    </Modal>
  )
}
