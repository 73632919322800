import React, { useMemo, useState } from "react";
import { Modal, Checkbox, Row, Col } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import styles from './index.module.scss';

export const FineGrainedPermissionModal = ({
  permissions,
  onCancel,
  onOk
}: {
  permissions: any;
  onCancel: () => void;
  onOk: (values: any) => void;

}) => {
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < permissions?.length);
    setCheckAll(list.length === permissions?.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? permissions?.map((permission: any) => permission?.operation) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const options = useMemo(() => {
    return permissions?.map((permission: any) => ({
      label: permission?.operationName,
      value: permission?.operation
    }))
  }, [permissions])

  return (
    <Modal
      visible
      title='选择权限'
      width={500}
      className={styles.choosePsermissionModal}
      onCancel={onCancel}
      onOk={() => onOk(checkedList)}
      okButtonProps={{
        disabled: !checkedList?.length 
      }}
    >
      <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
        全选
      </Checkbox>
      <div className={styles.permissionGroup}>
        <Checkbox.Group value={checkedList} onChange={onChange} style={{ width: '100%' }}>
          <Row>
            {
              options?.map((option: any) => (
                <Col span={6}>
                  <Checkbox value={option?.value}>{option?.label}</Checkbox>
                </Col>
              ))
            }
          </Row>
        </Checkbox.Group>
      </div>
    </Modal>
  )
}