import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ExclamationCircleOutlined, CloseOutlined } from '@ant-design/icons';
import * as _ from 'lodash';
import classnames from 'classnames'
import { Tree, Modal, Tooltip } from 'antd'
import { Iconfont } from 'src/components'
import { useSelector, useDispatch } from 'src/hook';
import { getExpandKeysAboutContainSearchValue } from 'src/util';
import {
  setLoadedTreeNodeMap,
  setLoadedKeys,
  getTreeNodeChildren,
  setSelectedNode,
  setExpandedKeys
} from './applicationListSlice'

import styles from './index.module.scss'

interface IProps {
  searchValue?: string;
  onDeleteNodeItem?: (params: { nodePathWithType: string }) => void;
}


const TreeComponent = (props: IProps) => {
  const {
    searchValue,
    onDeleteNodeItem
  } = props

  const { loadedKeys, newTreeData, expandedKeys, selectedTreeNode } = useSelector(state => state.applicationList);

  const dispatch = useDispatch();

  useEffect(() => {

    return () => {
      dispatch(setLoadedKeys([]));
      dispatch(setLoadedTreeNodeMap({}))
    }

  }, [])


  const handleExpand = (newExpandedKeys: React.Key[]) => {
    // 收起时，过滤掉该组层级下所有被展开的节点
    setExpandedKeys(newExpandedKeys)
  }

  const handleSelect = (item: any[], info: any) => {

    if (!info.selected) {
      return;
    }
    dispatch(setSelectedNode(info?.node as any))
  }

  // 异步逐级加载数据 (连接及以下层级加载树内容)
  const handleLoadData = useCallback(async (node: any) => {
    if (node?.newNodeType === 'datasource') {
      return
    }

    const { id, roleId, nodePathWithType, nodeType, dataSourceType, connectionId, nodeName,
      nodePath, key
    } = node

    const params = {
      connectionId: connectionId || id,
      connectionType: dataSourceType,
      nodeType,
      nodeName,
      nodePath,
      nodePathWithType,
      roleId,
      key
    }
    await dispatch(getTreeNodeChildren(params))

  }, [dispatch])

  // 权限集删除 确认弹窗
  const showDelPermissionConfirm = (params: {
    nodePathWithType: string;
    nodePath: string;
    nodeType: string;
  }) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: "确认要删除吗？",
      onOk() {
        onDeleteNodeItem?.(params)
      },
    })
  }



  const generatorSearchTitle = (node: any) => {

    const { nodeName, title, nodeType, connection = {}, testModel, dataSourceType } = node || {}
      ;
    return (
      <>
        <Iconfont
          className={classnames(styles.mr4, styles.color008dff, {
            [styles.colorf00]: testModel === 1 && nodeType === "connection",
            [styles.colorgreen]: !!testModel && testModel !== 1 && nodeType === "connection",
          })}
          type={`${nodeType === "datasource"
            ? `icon-connection-${nodeName}`
            : nodeType === "group"
              ? "icon-shujukuwenjianjia"
              : nodeType === "connection"
                ? `icon-${dataSourceType}`
                : `icon-${nodeType}`
            } `}
        />
        <span className={styles.titleTxt}>
          <Tooltip title={nodeType === "connection" && <>
            {connection?.ip + ':' + connection?.port}
          </>}>
            {title}
          </Tooltip>
          {["datasource", "group"].includes(nodeType) && `(${node?.children?.length || 0})`}
        </span>
      </>
    );
  };

  // 渲染tree title完整内容
  const treeTitleRender = (node: any) => {
    const { id, nodeType, nodeName, nodePath, nodePathWithType } = node
    const result = (
      <div className={styles.treeTitleItem}>
        {generatorSearchTitle(node)}
        <CloseOutlined
          className={styles.deleteNodeIcon}
          onClick={() => showDelPermissionConfirm({ nodePathWithType, nodePath, nodeType })}
        />
      </div>
    );
    return result
  }

  const matchKeyword = (target = '', substring = '') => {
    if (!target) return false
    return target.toLowerCase().indexOf(substring.toLowerCase()) > -1
  }

  // /* 实现全局节点搜索 */
  const filterNodesNotMatch = useCallback(
    (nodes: any[]): any[] =>
      nodes.filter((node) => {

        //后端搜索 筛选信息只有一条， 前端筛选模糊匹配
        const keywordHit = matchKeyword(`${node?.title}${node?.connection?.ip}`, searchValue)
        if (!keywordHit && node.children) {
          node.children = filterNodesNotMatch(node.children)
        }
        return keywordHit || node.children?.length
      }),
    [searchValue],
  )

  const filteredTreeDataOnLocal = useMemo(() => {

    return searchValue ? filterNodesNotMatch(_.cloneDeep(newTreeData)) : newTreeData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(newTreeData), searchValue])

  useEffect(() => {
    // 只获取包含search 的父节点
    if (searchValue) {
      let keys = getExpandKeysAboutContainSearchValue(filteredTreeDataOnLocal, searchValue)

      dispatch(setExpandedKeys(keys))
      // setAutoExpandParent(true)
    } else {
      dispatch(setExpandedKeys([newTreeData?.[0]?.key]))
    }

  }, [searchValue])

  return (
    <>
      {
        filteredTreeDataOnLocal?.length > 0 ? (
          <Tree
            className={styles.treeWrap}
            titleRender={treeTitleRender}
            treeData={filteredTreeDataOnLocal}
            onSelect={handleSelect}
            selectedKeys={selectedTreeNode ? [selectedTreeNode?.key] : []}
            onExpand={(expandedKeys) => dispatch(setExpandedKeys(expandedKeys))}

            expandedKeys={expandedKeys}
            loadData={handleLoadData}
            loadedKeys={loadedKeys || []}
            onLoad={(keys) => dispatch(setLoadedKeys(keys))}
          />
        ) : <div className='color667084 tc'>暂无数据</div>
      }
    </>
  )
}
export default TreeComponent