/**
 * 用户权限设置
 */
import React, { useEffect, useState, useImperativeHandle, useRef } from "react";
import { Spin, Checkbox, Table, message, Modal, Button, Tooltip, Dropdown, Menu } from "antd"
import { DownOutlined, PlusOutlined } from "@ant-design/icons"
import { useRequest, useDispatch, useSelector } from "src/hook"
import { setAuthorizeUserList } from '../authorizationManagePageSlice'
import {
  queryPermissionRelationUsers,
  togglePermissionUser,
  updatePermissionRelationUsers,
} from "src/api";
import { renderTableFields } from 'src/util'
import styles from './index.module.scss'
import { cloneDeep } from "lodash";
import classnames from "classnames";
import AddUserModal from "../AddUserModal";
import PermissionTooltip from "src/components/PermissionTooltip/PermissionTooltip";
import SetOperationAuthModal from "./SetOperationAuthModal";

interface IProps {
  [p: string]: any;
}

const UserAuthorize = (props: IProps, ref: any) => {
  const { dataSourceType, nodePathWithTypeList, authorizeEnum, selectItems, permissionlist, selectNodeType } =
    props;
  
    const [columns, setColumns] = useState<any[]>([
      {
        title: "用户名",
        dataIndex: "userName",
        key: "userName",
        width: 100,
        ellipsis:true,
        render: 
        (str: string, record: any) => (
          <span className={!record?.status? styles.disableFontColor : ''} >{str? str : '-'}</span>
        )
      },
      {
        title: "操作",
        dataIndex: "delete",
        key: "delete",
        width: 120,
        fixed: "right",
        render: (_: string, record: any) => (
          <PermissionTooltip
            title="手动授权"
            permissionlist={permissionlist}
          >
            <Tooltip
              title={!record?.supportDelete ? `此用户设置于上层级，无法移除，请从上层开始移除，或者进行禁用/更换权限等级操作` : null}
            >
              <Button
                type="link"
                className={styles.pl0}
                onClick={() => record?.supportDelete && handleDelete(record, false)}
                disabled={isOnlyRead || !record?.supportDelete}
                style={{padding: 0}}
              >
                移除
              </Button>
            </Tooltip>
            <Button
                type='link'
                onClick={() => { handleToggleStatus(record) }}
                disabled={permissionlist?.isOnlyRead}
              >
                {record?.status ? "禁用" : "启用"}
              </Button>
          </PermissionTooltip>
        ),
      },
    ]);


  const {isOnlyRead = false, roleNameList = []} = permissionlist || {}

  // 操作权限
  const [operationModalVisible, setOperationModalVisible] = useState<boolean>(false);  
  const [userList, setUserList] = useState<any[]>([]);
  const [updateLoading, setUpdateLoading] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowInfo, setSelectedRowInfo] = useState<any>();
  const [editStatus, setEditStatus] = useState<boolean>(false)

  const dispatch = useDispatch();
  const nodePathWithTypeListRef = useRef(nodePathWithTypeList);
  // 获取权限用户列表
  const {
    run: getAuthorizeUserList,
    refresh: refreshUserList,
    loading
  } = useRequest(queryPermissionRelationUsers, {
    manual: true,
    onSuccess: (res: any[]) => {
      const userList = cloneDeep(res)
      setUserList(userList);
      dispatch(setAuthorizeUserList(res));
      setSelectedRowKeys([])
    }
  });

  useEffect(() => {
    if (dataSourceType && nodePathWithTypeListRef.current) {
      const params = {
        dataSourceType,
        nodePathWithTypeList: nodePathWithTypeListRef.current,
      };
      getAuthorizeUserList(params);
    }
  }, [dataSourceType, getAuthorizeUserList, editStatus]);

  useEffect(() => {
    if (authorizeEnum?.length) {
      const arr: any[] = [];
      authorizeEnum.forEach((item: any) => {
        arr.push({
          title: item?.operationName,
          dataIndex: item?.operation,
          key: item?.operation,
          width: 80,
          render: (_: string, record: any) => (
            <PermissionTooltip
              title="手动授权"
              permissionlist={permissionlist}
            >
              <Checkbox
                checked={record?.operations.map((i: { operation: any; }) => i.operation).includes(item?.operation)}
                onClick={(e) => handleAuhtorizeChange(e, item?.operation, record)}
                disabled={isOnlyRead || !record?.status}
              /> 
            </PermissionTooltip>
          ),
        });
      });
      arr.push({
        title: '生效时间',
        dataIndex: 'beginTime',
        key: 'beginTime',
        width: 300,
        render: (val: string, record: any) => {
          const { operations } = record || {};
          if (Array.isArray(operations) && operations.length > 0) {
            const { beginTime, endTime, operation } = operations[0] || {};
            if (operations.length === 1) {
             return renderTime(beginTime, endTime, getOperationName(operation));
            } else if (operations.length > 1) {
              return <Tooltip title={
                <>
                  {
                    operations.map((item, index) => {
                      const { beginTime, endTime, operation } = item || {};
                      return <span key={index}>{renderTime(beginTime, endTime, getOperationName(operation))}<br /></span>
                    })
                  }
                </>
              }>
                <span>{renderTime(beginTime, endTime, getOperationName(operation))}...</span>
              </Tooltip>
            }
          }
          return '-'
        }
      })
      setColumns((c) => {
        c.splice(1, 0, ...arr);
        return [...c];
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizeEnum?.length]);

  const renderTime = (start: string, end: string, name: string) => {
    return <>{name}：{start ? <>{start} - {end}</> : '永久'}</>
  }

  const getOperationName = (operationKey: string) => {
    if (Array.isArray(authorizeEnum)) {
      const target = authorizeEnum.find(item => {
        const { operation } = item || {};
        return operation === operationKey;
      })
      return target?.operationName;
    }
    return '';
  }

  // 更新用户权限
  const updateUserPermissions = (newUserList: any, updateUserId: any, isBatch?: boolean) => {
    let userListTmp: any;
    if (isBatch) {
      userListTmp = newUserList.filter((item: any) => item?.deleteFlag === true)
    } else {
      userListTmp = newUserList.filter((item: any) => item.userId === updateUserId)
    }
    const params = userListTmp?.map((item: any) => {
      const { userId, operations, deleteFlag, beginTime, endTime } = item

      return { 
        userIds: isBatch? updateUserId : [userId],
        operations: operations?.map((item: { operation: any; }) => item.operation),
        deleteFlag,
        dataSourceType,
        nodePathWithTypeList,
        beginTime: new Date(beginTime).getTime() || undefined,
        endTime: new Date(endTime).getTime() || undefined,
      };
    });
    setUpdateLoading(true)

    return updatePermissionRelationUsers([params[0]])
      .then((res) => {
        message.success("用户权限更新成功");
        refreshUserList();
        setUserList(newUserList)
        // callback && callback();
      })
      .catch((err: any) => {
        throw new Error(`用户权限更新失败: ${err}`)
      }).finally(() => { 
        setUpdateLoading(false);
      });
  };

  // 暴露给父组件方法
  useImperativeHandle(ref, () => ({
    // updateUserPermissions,
    refreshUserList,
  }));

  // 权限修改
  const handleAuhtorizeChange = (e: any, operation: string, record: any) => {
    const checked = e.target.checked;
    const { beginTime, endTime,operations } = record || {};
    const newOperations = checked
      ? [...operations, { operation, beginTime, endTime }]
    : operations.filter((i: { operation: string; }) => i?.operation !== operation);

    setUserList((d: any[]) => {
      d.map((i: any) => {
      if (i?.userId === record?.userId) {
        i.operations = newOperations;
      }
      return { ...i };
    });
    updateUserPermissions(d, record.userId)
    return [...d];
    });
  };

  // 权限用户
  const handleDelete = (record: any, isBatch: boolean = false) => {
    Modal.confirm({
      title:'确认移除吗？',
      onOk:()=>{
        setUserList((d: any[]) => {
          const userListTmp = isBatch? [...selectedRowInfo] : [...d];
          const updateId = isBatch? userListTmp.map(obj => obj?.userId) : record?.userId;
          const updatedData = d.map((i: any) => {
              const condition = (
                isBatch ? (!!userListTmp.find((item: any) => i?.userId === item?.userId))
                        : (i?.userId === record?.userId)
              ) && i?.supportDelete;
              if (condition) {
                return { ...i, deleteFlag: true }; // 创建一个新对象并设置 deleteFlag 属性为 true
              }
              return { ...i }
            })
          updateUserPermissions(updatedData, updateId, isBatch)
            .then(() => {
              // return updatedData
            })
            .catch((error) => {
              console.log('updateUserPermissions Failed', error)
              return userListTmp
            })
            return userListTmp
        });
      }
    })
  };

  // 禁用、启用用户
  const handleToggleStatus = (item: any, isBatch: boolean = false, batchStatus?: boolean) => {
    let userIds = isBatch && selectedRowInfo.filter((item: any) => {
      return item?.userId;
    }).map((item: any) => item.userId);
    let params: any = {
      userIds:  isBatch? userIds : [item?.userId],
      status: isBatch? batchStatus : !item?.status,
    }

    params.nodePathWithTypeList = nodePathWithTypeList
    togglePermissionUser(params)
      .then(() => {
        // refreshUserList();  // 获取权限用户列表
        setEditStatus((d: boolean) => !d);
        message.success('操作成功')
      })
      .catch((err: any) => {
        console.log('表层级禁用/启用 err :>> ', err);
      })
  }


  const [addModalVisible, setAddModalVisible] = useState(false);
  const { authorizeUserList } = useSelector(
    (state) => state.authorizationManagement
  );
  const { connectionType } = selectItems?.[0] || {};
  const handleAddUser = () => {
    setAddModalVisible(true);
  };

  // render 添加用户
  const renderAddUser = () => {
    return (
      <PermissionTooltip
        title="手动授权"
        permissionlist={permissionlist}
      >
        <Button
          type="link"
          className={classnames(styles.add)}
          onClick={handleAddUser}
          disabled={isOnlyRead}
        >
          <PlusOutlined />&nbsp;新增用户
          {authorizeUserList?.length ? authorizeUserList.length : ""}
        </Button>
      </PermissionTooltip>
    )
  }

  const dataSource = userList?.filter(i=>!i?.deleteFlag)
  const scrollX = columns?.length * 100;
  
  // if (loading || updateLoading) { 
  //   return <Spin spinning={true} className={styles.loading} />;
  // }

  const onSelectChange = (newSelectedRowKeys: React.Key[], infos: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRowInfo(infos)
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  // 在数据源中设置 key 值为 ID 字段
  // const dataWithKeys = dataSource.map((item: any) => {
  //   return { ...item, key: item?.userId };
  // });

  const isSupportBatchDelete = (info: any) => {
    let arr = info && info.filter((item: any) => item?.supportDelete === true)
      if (arr && arr.length > 0) {
        return true
      } else {
        return false
      }
  }
  
  const menu = (
    <Menu>
      <Menu.Item onClick={() => { handleDelete({}, true)}} disabled={!isSupportBatchDelete(selectedRowInfo)}>
        <Tooltip
          title={!isSupportBatchDelete(selectedRowInfo) && 
            '此用户设置于上层级，无法移除，请从上层开始移除，或者进行禁用/更换权限等级操作'}
        >
          <span>批量移除</span>
        </Tooltip>
      </Menu.Item>
      <Menu.Item onClick={() => { handleToggleStatus({}, true, false) }}>批量禁用</Menu.Item>
      <Menu.Item onClick={() => { handleToggleStatus({}, true, true) }}>批量启用</Menu.Item>
      <Menu.Item onClick={() => { setOperationModalVisible(true) }}>批量设置操作权限</Menu.Item>
    </Menu>
  );

    return (
      <>
      <Dropdown disabled={!hasSelected} overlay={menu}>
        <Button type="primary" style={{position: 'absolute', right: 26, top: 64, width: 136}}>批量操作<DownOutlined /></Button>
      </Dropdown>
      <Table
        rowKey="userId"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ x: scrollX, y: `calc(100vh - 260px)` }}
        bordered
        footer={renderAddUser}
        style={{ marginTop: 24}}
        loading={loading || updateLoading}
      />
      {/* 权限等级 */}
      { operationModalVisible && (
        <SetOperationAuthModal
          type='fineGrit'  // 细粒度
          visible
          isFineGrainedAuthorization
          handleClose={() => {
            setOperationModalVisible(false);
          }}
          refreshList={refreshUserList}
          authorizeEnum={authorizeEnum}
          dataSourceType={connectionType}
          nodePathWithTypeList={nodePathWithTypeList}
          selectNodeType={selectNodeType}
          userIds={selectedRowKeys}
        />
      )
      }
      {addModalVisible && (
        <AddUserModal
          visible
          isFineGrainedAuthorization
          handleClose={() => {
            setAddModalVisible(false);
          }}
          refreshList={refreshUserList}
          authorizeEnum={authorizeEnum}
          dataSourceType={connectionType}
          nodePathWithTypeList={nodePathWithTypeList}
          selectNodeType={selectNodeType}
        />
      )}
      </>
    );
  // return <div style={{padding: '80px', textAlign:'center'}}>暂无数据~</div>;
};
export default React.forwardRef(UserAuthorize);