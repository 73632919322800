import { fetchDelete, fetchPost, fetchGet, fetchPostFormData, fetchPut } from '../customFetch'
import type { ISearchSdtItem } from '../sdt';
export type ViewType = 'TABLE' | 'SCHEMA' | 'DATABASE' | 'CONNECTION'

interface DesensitizeResourceList {
  connectionId: number
  nodePath: string
  findType: ViewType
}

interface DesensitizeResourceStatus {
  id: number[]
  status: boolean
}

interface GetDesensitizeRules {
  columnType: string
  datasourceType: string
}

export type DesensRuleName =
  | 'REPLACE'
  | 'RANDOM_FLOATING'
  | 'RANDOM_IN_RANGE'
  | 'SUB'
  | 'ENCRYPTION'
  | 'HIDE'

 type DesensRuleParam =
  | {
      front: number
      count: number
      target: '数字' | '大写字母' | '小写字母' | '数字字母混合' | '*' | '#'
    }
  | {
      min: number
      max: number
    }
  | {
      min: number
      max: number
      type: '日期' | '数字'
    }
  | {
      type: '前' | '后'
      count: number
    }
  | {
      secretKey: string
    }
  
    interface UpdateDesensitizeRules {
      nodePath: string
      datasourceType: string
      columnType: string
      ruleName: DesensRuleName
      ruleParam: DesensRuleParam
    }
    
/**
 * 查询表下、schema下共用同一个接口
 * @param params
 * @returns
 */
export function getDesensitizeResourceList(params: DesensitizeResourceList) {
  return fetchPost('/desens/resource/list', params)
}

/**
 * 设置脱敏资源的开启或启用状态
 * @param params.id 脱敏资源的主键id，获取脱敏资源列表的时候会获取到
 * @param params.status 设置为开启还是关闭，true为开启
 * @returns
 */
export function setDesensitizeResourceStatus(
  params: DesensitizeResourceStatus,
) {
  return fetchPost('/desens/resource/status', params)
}

/**
 * 调用接口获取该字段上能使用什么算法
 * @param params.columnType 字段的类型
 * @param params.datasourceType 数据源类型
 * @returns
 */
export function getDesensitizeRules(params: GetDesensitizeRules): Promise<
  Array<{
    ruleType: string
    values: string[]
    ruleParam: object
    ruleName: string
  }>
> {
  return fetchPost('/desens/resource/rule', params)
}

/**
 * 设置脱敏算法
 * @param params
 * @returns
 */
export function updateDesensitizeRules(params: UpdateDesensitizeRules) {
  return fetchPost('/desens/resource/update', params)
}

/**
 * 查询组下的脱敏资源
 * @param params.groupId
 * @returns
 */
export function getGroupDesensitizeList(params: { groupId: number }) {
  return fetchPost('/desens/resource/group/list', params)
}

/**
 * 查询数据源的脱敏资源
 * @param params.datasourceType
 * @returns
 */
export function getDataResourceDesensitizeList(params: {
  datasourceType: string
}) {
  return fetchPost('/desens/resource/datasource/list', params)
}

export function deleteDesensResource(resourceId: number) {
  return fetchDelete(`/desens/resource/${resourceId}`)
}

export interface IDesensImportTaskItem {
  id: number;
  fileName: string;
  connectionName: string;
  databseName: string;
  schemaName: string;
  countOfSucceed: number;
  countOfFailed: number;
  errorMessage: string;
  completedTime: string;
}
//脱敏导入任务
export function getDesensImportTasks(): Promise<IDesensImportTaskItem[]> {
  return fetchGet(`/desens/resource/task`)
}
//脱敏导入
export function uploadDesensResource(params: {file: any}) {
  return fetchPostFormData(`/desens/resource/upload`,params)
}

//批量导出 脱敏
export function downloadDesensResource(params: any) {
  return fetchPost(`/desens/resource/download`,params)
}
//删除上传文件
export function deleteDesensLog(params: {resourceIds: number[]}) {
  return fetchPost(`/desens/resource/delete/log`,params)
}
//批量删除 脱敏
export function batchDeleteDesensResource(params: {resourceIds: any}) {
  return fetchPost(`/desens/resource/batch/delete`,params)
}
export interface IPolicyParams {
  resourceIds: number[]; 
  policy: string
}
//批量编辑策略 
export function batchSetPolicy(params: IPolicyParams) {
  return fetchPost(` /desens/resource/set/policy`,params)
}
//编辑算法
export function UpdateDesensAlgo(params: {resourceIds: any}) {
  return fetchPost(`/desens/resource/update/algo`,params)
}

//脱敏用户列表
export function getFlowPermission(params: {nodePathWithType: string | null; dataSourceType : string | undefined }) {
  return fetchPost(`/user/permission/desens/getFlowPermission`,params)
}


//删除脱敏用户
export function deleteFlowPermission(params: {permissionIds: number[]}) {
  return fetchDelete(`/user/permission/desens/deleteFlowPermission`,params)
}


interface IScanParams {
  jobName: string;
  datasourceType: string;
}
export interface IScanTaskItemRes {
  id: number;
  jobName: string;
  jobStatus: number;
  jobTypes: string;
  lastExecuteTime: string;
  corExpression: string;
  desensRule: string;
  jobRunTime: string;
  path: string;
}
//脱敏扫描
export function findDataSourceDesensScanList(params: IScanParams):Promise<IScanTaskItemRes> {
  return fetchPost(`/export/task/find/scan/datasource`,params)
}

export function findGroupDesensScanList(params: IScanParams):Promise<IScanTaskItemRes> {
  return fetchPost(`/export/task/find/scan/group`,params)
}
export function findConnectionDesensScanList(params: IScanParams):Promise<IScanTaskItemRes> {
  return fetchPost(`/export/task/find/scan/connection`,params)
}
export function setDesensScanTaskStatus(
  {
    taskId,
    jobStatus
  }: {
    jobStatus: boolean;
    taskId: number
  },
) {
  return fetchPut(`/export/task/${taskId}/status?jobStatus=${jobStatus}`)
}

export function deleteDesensScanTask(params: {jobIds: number[]}) {
  return fetchDelete(`/export/task`,params)
}
//手动执行
export function triggerDesensScanTask({taskId}: {taskId: number[]}) {
  return fetchPut(`/export/task/${taskId}/trigger`)
}

export interface IScanRuleItem {
  id: number;
  ruleName: string;
  description: string;
  resource: string;
  checkMethod: string;
  example: string;
  editable: boolean;
  algoName: string;
  algoParam: any;
  valid: boolean
}
//扫描规则
export function getDesensScanRules(): Promise<IScanRuleItem[]> {
  return fetchGet(`/desens/desens/internal/find/scan/rule`)
}
export interface IAddRuleParams {
  id?: number;
  connectionId: string;
  jobName: string;
  cronExpression: string;
  description: string;
  jobTypes: string;
  nodePathWithType: string;
  jobResource: {
    autoScanning: number[];
    dataSourceType: string;
    connectionId: string;
    objNames: string[];
    nodeType: string;
    enable?: boolean
    scanAlgoType: string
  }
}
export function addDesensScanTask(params: IAddRuleParams): Promise<IScanRuleItem[]> {
  return fetchPost(`/export/task`,params)
}
//获取任务详情
export function getDesensScanTaskDetail(taskId: number): Promise<IAddRuleParams> {
  return fetchGet(`/export/task/${taskId}`)
}

//校验
export function checkTaskCron(params: {cronExpression: string}): Promise<IAddRuleParams> {
  return fetchPost(`/export/task/common/check/cron`,params)
}

interface IScanResultParams {
  current: number;
  pageSize: number;
  nodePath: string;
  tableName?: string;
}
//脱敏扫描结果
export function findDataSourceDesensScanResultList(params: IScanResultParams):Promise<IScanTaskItemRes> {
  return fetchPost(`/desens/resource/scan/datasource/list`,params)
}

export function findGroupDesensScanResultList(params: IScanResultParams):Promise<IScanTaskItemRes> {
  return fetchPost(`/desens/resource/scan/group/list`,params)
}
export function findConnectionDesensScanResultList(params: IScanResultParams):Promise<IScanTaskItemRes> {
  return fetchPost(`/desens/resource/scan/list`,params)
}
interface IParams {
  systemPermissionType: string
  nodePathWithType: string | null
}
// 判断是否可编辑
export function permissionSupport(params: IParams): any {
  return fetchPost(`user/permission/support/edit`, params)
}

interface IUpdateampleParams {
  sampleRate: number;
  sampledCount: number;
  dataSourceType?: string;
  groupId?: string;
  nodePathWithType?: string
}
// 设置 数据源脱敏命中率
export function updateDatasourceTypeSample(params: IUpdateampleParams): any {
  return fetchPost(`/desens/desens/internal/sample/dataSource/update`, params)
}

// 设置 数据源脱敏命中率
export function updateGroupSample(params: IUpdateampleParams): any {
  return fetchPost(`/desens/desens/internal/sample/group/update`, params)
}

// 设置 数据源脱敏命中率
export function updateConnectionSample(params: IUpdateampleParams): any {
  return fetchPost(`/desens/desens/internal/sample/schema/update`, params)
}

/* 数字字典查询列表 */
export const getDataAuthSdtSearchList = (params: {query: string}): Promise<ISearchSdtItem[]> =>
  fetchGet(`/dms/fullTextSearch/searchDataAuthSdt`,params)
