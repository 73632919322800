export const stringByteLength = (string: string) => { 
    if (!string) { 
        return 0
    }
    let length = 0
    Array.from(string).forEach( (char: string) => {
        if (char.charCodeAt(0) > 255) {
        // 字符编码大于255，说明是双字节字符
            length += 2
        } else {
            length++
        }
    })
    return length
}

export const customLengthWord = (string: string, length: number, start?: number) => {
    let curLength = 0, result = '', startIndex = start || 0
    Array.from(string).forEach((char: string, index) => {
        if (index < startIndex || curLength > length) {
          return 
        }
        if (char.charCodeAt(0) > 255) {
            // 字符编码大于255，说明是双字节字符
            curLength += 2
        } else {
            curLength++
        }
        result += char
    })
    return result
}

export const treeToArray = (tree: any[]) => {
  let arr: any[] = []
  const flatTree = (data: any) => {
    data?.forEach((i: any) => {
      flatTree(i?.children)
      const item = { ...i }
      delete item?.children
      arr.push(item)
    })
  }
  flatTree(tree)
  return arr
}

export const getTargetNode = (nodePath: string, treeData: any[]) => {
    const queue = [...treeData]
    while (queue.length) {
      const currentNode = queue.shift()
      if (!currentNode) return
      if (currentNode.key === nodePath) {
        return currentNode
      }
      if (currentNode.children) {
        queue.push(...currentNode.children)
      }
    }
  }

  export const specialFormatTree = (data: any[], isFolder?: boolean,roleId?: number): any[] =>
  data?.map((item: any) => {
    const { connection ={}} = item
    item.title = item.nodeName;
    item.isFolder = isFolder || false;
    item.dataSourceType = connection?.connectionType;
    item.roleId = item?.roleId || roleId;
    item.isLeaf = !item?.hasChild;
    if (!item.nodePathWithType) {
      item.nodePathWithType = item.id + "/permission"
      item.nodePath = item.id + "/permission"
      item.key = item.id;
    } else {
      // 唯一key
      item.key = item.roleId + item?.nodePath ;
    }
    
      return item

  });
  