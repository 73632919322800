import React, { FC } from "react";
import { Card, Descriptions } from "antd";
import classnames from "classnames";
import { Iconfont, renderDatabaseElementIcon } from 'src/components';
import {
  HIGH_RISK_BASIC_INFO,
  HIGH_RISK_BASIC_FIELD,
  BASIC_ODD_FIELDS,
  HIGH_RISK_ODD_FIELDS,
  priGranTypeMap,
  DESENSITIZED_RESOURCE_ODD_FIELDS,
  PriGranType,
  EXPORT_TASK_ODD_FIELDS
} from '../../constants';
import styles from "../index.module.scss";

interface ListProps {
  beginTime: string;
  endTime: string;
  remark: string;
  title: string;
  priGranType:  PriGranType   
}

export const ApplicationsInfoPag: FC<ListProps> = (props:any) => {
  const { beginTime, endTime, priGranType } = props;
  
  let descriptionFields = BASIC_ODD_FIELDS;

  if (priGranType === 'highRisk') {
    descriptionFields = HIGH_RISK_ODD_FIELDS;
  } else if (priGranType === 'exportTask') {
    descriptionFields = EXPORT_TASK_ODD_FIELDS;
  } else if (priGranType === 'desensitizedResource') {
    descriptionFields = DESENSITIZED_RESOURCE_ODD_FIELDS;
  }
  if (priGranType === 'exportTask' && !Object.keys(props).includes("exportNumLimit")) {
    descriptionFields = descriptionFields.filter((str: string) => str !== "exportNumLimit")
  }

  const renderHighRiskItemValue = (field: HIGH_RISK_BASIC_FIELD) => { 
    if (field === 'connectionName') {
      return <><Iconfont type={`icon-${props?.dataSourceType}`} style={{fontSize: '16px', marginRight: "6px" }}/>{props?.[field]}</>
    }else if (field === 'nodePathWithTypeList') {
      return props?.nodePathWithTypeList?.map((path: string) => renderDatabaseElementIcon({nodePathWithType: path,dataSourceType: props?.dataSourceType})) 
    }else if (field === 'time') {
      if (!beginTime && !endTime) return '永久'
      return  `${beginTime} 至 ${endTime}`
    }else if (field === 'flowType') {
      return priGranTypeMap[props?.[field]]
    }else if (field === 'exportNumLimit') {
      return <>{[props?.[field]]}行</>
    }
    return props?.[field];
  }

  return (
    <Card
      title="申请信息"
      className={classnames(styles.borderShow, styles.mt27, styles.mb27)}
    >
        <Descriptions column={1} className={styles.oddDescription}>
          {descriptionFields?.map((key: any) => (
            <Descriptions.Item label={HIGH_RISK_BASIC_INFO[key]} key={key}>
              {renderHighRiskItemValue(key)}
            </Descriptions.Item>
          ))}
        </Descriptions>
    </Card>
  );
};
