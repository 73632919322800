import { fetchGet, fetchPost } from '../customFetch'

interface IAuditDrillDownProps {
  beginTimeMs?: number;
  endTimeMs?: number;
  operateTypes?: string[];
  orgIds?: number[];
  dbObjects?: string[]
  dbObjectUnit?: string,
  timeUnit?: string,
  posXDim: string,
  showDeleted: boolean
}
interface IAuditDrillDownPRes{
  posX: string;
  supportDrillDown: boolean;
  supportRolUp: boolean;
  type: string;
  amount: {[k: string]: number}
  execute_avg:  {[k: string]: number},
}
//sql执行次数 和 执行平均时长
export const getAuditDrillDownData = (
  params: IAuditDrillDownProps,
): Promise<IAuditDrillDownPRes[]> => {
  return fetchPost(`/analyze/audit_report/load_audit_oot_cube`, params)
}

interface auditOverview {
  activeUserCount: number
  allCount: number
  errorCount: number
  todayAllCount: 0 //当天sql执行总数
  todayErrorCount: 0 //当天执行错误条数
  [p:string]: any
}

export const getAuditExecuteAmount = (
  days: number = 0,
): Promise<auditOverview> => {
  return fetchPost(`/analyze/audit_report/sql_execute_amount`, { days })
}

export type AuditTimeUnit = 'MONTH' | 'DAY' | 'HOUR'

export interface AuditSqlParams {
  beginTimeMs: number
  endTimeMs: number
  unit: AuditTimeUnit
  dbTypes?: string[]
  connectionName?: string
  dbName?: string[]
}

interface CountPerUnit {
  unit: string
  amount: number
}
interface SqlCountPerUnit extends CountPerUnit {
  dbType: string
}
export const getAuditSqlLogUnit = (
  params: AuditSqlParams,
): Promise<SqlCountPerUnit[]> => {
  return fetchPost(`/analyze/audit_report/load_audit_log`, params)
}



export interface AuditSqlCountByDbType {
  dbType: string
  amount: number
}
export const getAuditDBTypeAmountUnit = (): Promise<AuditSqlCountByDbType[]> => {
  return fetchGet(`/analyze/audit_report/db_type_amount `)
}

export const getAuditUserAmountUnit = (
  params: AuditSqlParams,
): Promise<CountPerUnit[]> => {
  return fetchPost(`/analyze/audit_report/user_amount`, params)
}

export interface AuditSqlCostPerUnit extends CountPerUnit {
  connectionName?: string
  executeCost: number
  dbName?: string
}

export const getAuditExecuteCostAvgUnit = (
  params: AuditSqlParams,
): Promise<AuditSqlCostPerUnit[]> => {
  return fetchPost(`/analyze/audit_report/db_execute_cost_avg`, params)
}

export interface AuditExecutionLogParams {
  limit?: number;
  offset?: number;
  dbTypes?: string[];
  executors?: string[];
  sqlTypes?: string[];
  executeBeginMs?: number |undefined;
  executeEndMs?:number | undefined;
  timeRange?: number[];
  userIds?: string[];
  id?: string;
  depts?: string[];
  objectNames?: string[];
  connectionName?: string[];
  [p:string]: any
}

export interface AuditExecutionItem {
  beginTime?: string;
businessType?: string;
clientIp?: string;
detail?: string;
endTime: string;
id?: string;
operateEntryPoint?: string;
operateType?: string;
resultFlag?: boolean;
userId?: string;
userName?: string;
}
export interface AuditExecutionResponse {
  total: number;
  offset?: number;
  limit?: number;
  lastTime: string;
  data: AuditExecutionItem[];
}

export const findAuditExecutionLogs = (
  params: AuditExecutionLogParams,
): Promise<AuditExecutionResponse> => {
  return fetchPost(`/analyze/audit_report/find_audit_log`, params)
}

export const findAuditExecutionLogs4Personal = (
    params: AuditExecutionLogParams,
): Promise<AuditExecutionResponse> => {
  return fetchPost(`/analyze/audit_report/find_audit_log/personal`, params)
}

export interface AuditExecutionParams {
  userId: string;
  type: string;
  columns?: string[]
}

export const getAndSetAuditColumn = (
  params: AuditExecutionParams,
): Promise<AuditExecutionParams> => {
  return fetchPost(`/user/users/audit_column`, params)
}

export interface executeDetailParams {
 userId: string;
 checkPermissionId: string;
}

export interface executeDetailRes {
  id: string;
  operateType: string;
  authUserName: string;
  authUserId: string;
  authOperate: string;
  authedRoleName: string;
  authedPermissionName: string;
  time: string
 }

export const findExecuteDetail = (
  params: executeDetailParams,
): Promise<executeDetailRes[]> => {
  return fetchPost(`/analyze/audit_report/find_execute_detail`, params)
}


export const findAuditOperateLogs = (
  params: AuditExecutionLogParams,
): Promise<AuditExecutionResponse> => {
  return fetchPost(`/analyze/operate_log/detail`, params)
}

// 查看详情接口
export const getPermissionDetailById = (id: number) => {
  return fetchGet(`/user/permission/dashboard/detail/${id}`)
}