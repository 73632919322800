import React, { useEffect, useState, useMemo, useCallback } from 'react'
import * as _ from 'lodash';
import { useRequest } from 'src/hook'
import { Input, message, Modal, Tooltip, Tree, Select, Checkbox, Row, Col, Form, Radio,DatePicker } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import {
  addPermissionUser,
  queryOrgSdtWithUser,
  getPermissionTemplateInfo,
  updatePermissionRelationUsers,
  checkPermissionStatus
} from "src/api";
import { Iconfont } from 'src/components'
import { resetPermissionModal } from './commonMethods'
import styles from './index.module.scss'
import moment, { Moment } from 'moment';
import OperationPermissionsModal from './OperationPermissionsModal';
import { getParentKeysAboutContainSearchValue } from 'src/util';
interface PropsI {
  visible: boolean
  [p:string]: any
}

const treeNodeAdapter = (arr: any[], pKey?:''): any => {
  return arr?.map((item: any) => {
    const { name, orgId, userId, type, child=[], path } = item
    const id = orgId || userId
    const key = pKey ? pKey + '_' + id : id

    return {
      id,
      name,
      type,
      key,
      title: type==="USER" && userId ? `${name}(${userId})` : name,
      children: child?.length ? treeNodeAdapter(child, key) : [],
      isLeaf: !child?.length,
      path,
      userId
    }
  })
}

const AddUserModal = (props: PropsI) => {
  const {
    isFineGrainedAuthorization= false, //相当于细粒度授权 不需要权限校验
    dataSourceType,
    groupId,
    nodePathWithType,
    visible,
    handleClose,
    refreshList,
		authorizeEnum,
    nodePathWithTypeList,
    selectNodeType,
  } = props;

  const [loading, setLoading] = useState(false)
  const [templateId, setTemplateId] = useState<any>();
  const [searchValue, setSearchValue] = useState<string>('')
  const [expandedKeys, setExpandedKeys] = useState<any>([])
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
  const [realCheckedUsers, setRealCheckedUsers] = useState<any[]>([]);
  const [searchUsers, setSearchUsers] = useState<any[]>([])
  const [autoExpandParent, setAutoExpandParent] = useState(false)
  // 时间区间
  const [selectTimeRange, setSelectTimeRange] = useState<any[]>([])
  // 工具权限的选择
  const [modalVisible, setModalVisible] = useState(false)
  const [templateOperationsSet, setTemplateOperationsSet] = useState([])  // 勾选的工具选项
  const [checkedParamsOperationNames, setCheckedParamsOperationNames] = useState<any[]>([]) // 勾选的工具选项name数组

  const [form] = Form.useForm();

  // 查询组织架构用户数据
  const { data = [] } = useRequest(queryOrgSdtWithUser, {
    formatResult: (res: any[]) => {
      return treeNodeAdapter(res)
    },
    onSuccess: (res) => {
     setExpandedKeys([res?.[0]?.key])
    }
  })

  useEffect(() => {
    form.setFieldsValue({'toolPermmission': checkedParamsOperationNames?.length > 0 ? checkedParamsOperationNames.join("，") : '' })
  }, [checkedParamsOperationNames])

  // 可选权限模版
  const { data: permissionTemplate = [], run: queryTemplate } = useRequest(
    getPermissionTemplateInfo,
    {
      manual: true,
    },
  )

  useEffect(() => {
    if (dataSourceType) {
      queryTemplate(dataSourceType)
    }
  }, [dataSourceType, queryTemplate])

  const matchKeyword = (target = '', substring = '') => {
    if (!target) return false
    return target.toLowerCase().indexOf(substring.toLowerCase()) > -1
  }
  
  /* 实现全局节点搜索 */
  const filterNodesNotMatch = useCallback(
    (nodes: any[]): any[] => 
      nodes.filter((node) => {
        const keywordHit =  matchKeyword(node.title, searchValue)
        if (!keywordHit && node.children) {
          node.children = filterNodesNotMatch(node.children)
        }
        return keywordHit || node.children?.length
      }) ,
    [searchValue],
  )

  useEffect(() => {
    // 只获取包含search 的父节点
      if (searchValue) {
        let keys = getParentKeysAboutContainSearchValue(filteredTreeDataOnLocal, searchValue)
        setExpandedKeys(keys)
        setAutoExpandParent(true)
      }else {
        setExpandedKeys([data?.[0]?.key])
      }
    }, [searchValue])

  const filteredTreeDataOnLocal = useMemo(() => {
    return searchValue ? filterNodesNotMatch(_.cloneDeep(data)) : data
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ JSON.stringify(data), searchValue])


  const treeData = useMemo(() => {
    const loop = (data: any[]): any[] => {
      return data.map((item: any) => {
        // 标题与搜索值的匹配, 不区分大小写
        let strTitle = item.title
        let strTitleTmp = _.cloneDeep(strTitle)
        const index = strTitleTmp?.toLowerCase()?.indexOf(searchValue.toLowerCase())
        const beforeStr = strTitle?.substring(0, index)
        const matchStr = strTitle?.substring(index, index + searchValue.length); // 截取匹配的部分
        const afterStr = strTitle?.slice(index + searchValue?.length)
        let title = strTitle
        if (index > 15 || (strTitle?.length > 15 && index === -1)) {
          title = (
            <Tooltip title={strTitle}>
              {strTitle.substring(0, 15) + '...'}
            </Tooltip>
          )
        } else if (index > -1) {
          if (strTitle.length > 15) {
            const searchValueIndex = searchValue?.length + index
            if (searchValueIndex > 15) {
              title = (
                <Tooltip title={strTitle}>
                  <span>
                    {beforeStr}
                    <span style={{ color: '#3357ff' }}>
                      {matchStr.substring(0, 15)}
                    </span>
                    ...
                  </span>
                </Tooltip>
              )
            } else {
              title = (
                <Tooltip title={strTitle}>
                  <span>
                    {beforeStr}
                    <span style={{ color: '#3357ff' }}>{matchStr}</span>
                    {strTitle.substring(searchValueIndex, 15)}...
                  </span>
                </Tooltip>
              )
            }
          } else {
            title = (
              <span>
                {beforeStr}
                <span style={{ color: '#3357ff' }}>{matchStr}</span>
                {afterStr}
              </span>
            )
          }
        }

        if (item.children) {
          return {
            ...item,
            title,
            children: loop(item.children),
          }
        }
        return {
          ...item,
          title,
        }
      })
    }
    return loop(filteredTreeDataOnLocal)
  }, [filteredTreeDataOnLocal, searchValue])

  const generatePrentKey = (key: string, treeData: any[]) => {
    let parentKey: React.Key
    for (let i = 0; i < treeData.length; i++) {
      const node = treeData[i]
      if (node.children) {
        if (node.children.some((item: any) => item.key === key)) {
          parentKey = node.key
        } else if (generatePrentKey(key, node.children)) {
          parentKey = generatePrentKey(key, node.children)
        }
      }
    }
    return parentKey!
  }

  // tree数据拉平
  const flatData = () => {
    let dataList: any[] = []
    const generateList = (data: any[]) => {
      for (let i = 0; i < data.length; i++) {
        const node = data[i]
        const { id, type, name, path, key, userId} = node
        dataList.push({ id, type, name, path, key, userId });
        if (node.children) {
          generateList(node.children)
        }
      }
    }
    generateList(treeData)
    return dataList
  }

  // 搜索
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {

    const value = e.target.value?.trim();
    const searchUsers = flatData().map((item: any) => {
      const {id, type, name, path, key, userId } = item
      if(!value || type !== "USER"){
        return null
      }
      const nameToLowerCase = name?.toLowerCase()
      const userIdToLowerCase = userId?.toLowerCase()
      const valueToLowerCase = value?.toLowerCase()
      if (nameToLowerCase?.includes(valueToLowerCase) || userIdToLowerCase?.includes(valueToLowerCase)) {
        return {
          id,
          name,
          path,
          key
        };
      }
      return null
    }).filter((item, i, self) => item && self.indexOf(item) === i)

    setSearchUsers(searchUsers)
    setSearchValue(e.target.value)
  }

  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys)
  }

  const onCheck = async (checkedKeysValue: any, info: any) => {
    const checkedUsers = info.checkedNodes?.filter((i: any) => i?.type==='USER' && checkedKeysValue.includes(i?.key))
      ?.map((i: any) => {
        return {
          userName: i?.name,
          userId: i?.id,
          path: i?.path,
          key: i?.key
        };
      })
    setRealCheckedUsers(checkedUsers)
    setCheckedKeys(checkedKeysValue)
    setTemplateId(null)
  }

  const handleCheckboxChange = (e: any) => {
   
    const {value = '', checked = false} = e.target || {};
    let cloneRealCheckedUsers = _.cloneDeep(realCheckedUsers);
    let cloneCheckedKeys = _.cloneDeep(checkedKeys);
    if (!checked) {
      cloneRealCheckedUsers = cloneRealCheckedUsers.filter((item: any) => item?.key !== value);
      cloneCheckedKeys = cloneCheckedKeys.filter((key: any) => key !== value);
    }else {
     
      const newCheckedUsers = flatData()
        ?.filter((i: any) => i?.type === "USER" && value === i?.key)
        ?.map((i: any) => {
          return {
            userName: i?.name,
            userId: i?.id,
            path: i?.path,
            key: i?.key
          };
        });
        cloneRealCheckedUsers = cloneRealCheckedUsers.concat(newCheckedUsers);
        cloneCheckedKeys.push(value);
    }
   
    setRealCheckedUsers(cloneRealCheckedUsers);
    setCheckedKeys(cloneCheckedKeys);
  }

  // 删除选择的用户
  const handleCloseItem = (key: string) => {
 
    const regex = /(.*)_(.*)$/;
    const parentKey = key.split('_')?.[0]; //多个父节点被选中
    const match = key.match(regex);
    const dept = match?.[1];  // 如果直接选中树的父节点 父节点也在 此时右侧删除左侧没有回显
   
    setRealCheckedUsers((checkedUsers: any) => {
      return checkedUsers?.filter((item: any) => item?.key !== key)
    })
    // 根据右侧数据回填左侧组织树
    setCheckedKeys((keys: any) => keys?.filter((i: string) => !(i === key || i === dept || i?.toString() === parentKey) ))
  }

  // 权限选择
  const handleAuthChange = async(value: string) => {
    //判断是否存在权限冲突
    if(!isFineGrainedAuthorization) {
      const checkedPermissionStatus  = await checkPermissionStatus({
        userIds:realCheckedUsers?.map(user => user.userId),
        templateId: value,
        nodePathWithType,
        groupId
      })
  
      if (!checkedPermissionStatus) {
        const resetOrNot = await  resetPermissionModal({
          title: '是否重置所选用户权限等级',
          content:'所选用户在此层及下存在其他权限设置,重置将清空下层及所选用户的权限设置'
        })
        if (!resetOrNot) {
          setTemplateId(null)
          return
        }
      }
    }
   
    //存在
      setTemplateId(value)
  }

  // 添加用户
	const handleAddUser = async() => {
    const userIds = realCheckedUsers?.map((i: any) => i?.userId)
    const effectiveTime = form.getFieldValue('effectiveTime') || []
    const beginTime = effectiveTime[0]?.startOf('s').valueOf()
    const endTime = effectiveTime[1]?.endOf('s').valueOf()

    if (!userIds?.length) {
      message.error('请先选择用户')
      return
    } 
    let params:any, url
		// 连接下的组范围用户授权添加用户
    if (authorizeEnum) {
      if (!templateId?.length) {
        message.error("请先选择权限");
        return;
      }
      params = userIds?.map((id: string) => {
        return {
          userIds: [id],
          nodePathWithTypeList,
          dataSourceType,
          operations: templateId,
          beginTime,
          endTime,
        };
      });
      url = updatePermissionRelationUsers
    } else { 
      // 自定义组、连接、schema、database等添加用户
      // 权限等级 必选、工具权限 可选
      if (!templateId) {
        message.error("请先选择权限等级");
        return;
      }
      params = {
        userIds,
        groupId,
        nodePathWithType,
        templateId: templateId!,
        beginTime,
        endTime,
        templateOperationsSet,
        dataSourceType,
      };
      url = addPermissionUser;
    }
    //是否权限冲突
    // if (!item?.templateValid) {
    //   const resetOrNot = await  resetPermissionModal({})
    //   if (!resetOrNot) {
    //     return
    //   }
    // }
    setLoading(true)
    url(params)
      .then((res: any) => {
        handleClose()
        refreshList()
        message.success('添加成功')
      })
      .catch((err: any) => {
        console.log('添加用户失败', err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const titleRender = (node: any) => {
    const { type, title } = node
    let icon = null
    switch (type) {
      case 'COMPANY':
        icon = <Iconfont type="icon-org-company" />
        break
      case 'SUB_COMPANY':
        icon = <Iconfont type="icon-org-company" />
        break
      case 'DEPT':
        icon = <Iconfont type="icon-org-department" />
        break
      case 'GROUP':
        icon = <Iconfont type="icon-org-group" />
        break
      case 'USER':
        icon = <Iconfont type="icon-org-user" />
        break
      default:
        break
    }
    const showTitle =
      title?.length > 15 ? (
        <Tooltip title={title}>{title.substring(0, 15) + '...'}</Tooltip>
      ) : (
        title
      )
    return (
      <>
        {icon}&nbsp;{showTitle}
      </>
    )
  }

  const renderAuthorizeList = () => { 
    if (authorizeEnum) {
      const options = authorizeEnum?.map((item: any) => {
        return {
          label: item?.operationName,
          value: item?.operation,
        };
      });
      return (
        <Select
          placeholder="请选择"
          mode="tags"
          maxTagCount={4}
          options={options}
          style={{ width: "100%" }}
          onChange={handleAuthChange}
        />
      );
    } else {
      const options = permissionTemplate?.filter((item: any)=>![
        "DISABLE_TEMPLATE",
        "DISABLE_TEMPLATE_AND_PERMISSION",
      ].includes(item?.status))?.map((item: any) => {
        return {
          label: <Tooltip title={item?.description}>{item?.name}</Tooltip>,
          value: item?.id
        };
      });
      return (
        <Select
          placeholder="请选择"
          options={options}
          value={templateId}
          style={{ width: "100%" }}
          onChange={handleAuthChange}
        />
      );
    }
  }

  const onValuesChange = (values: any) => {
    if (form.getFieldValue("timeType") === 'forever') {
      form.setFieldsValue({ effectiveTime: [] })
    }
  }

  const disabledDate = (current: Moment) => {
    return current && current < moment().startOf('day');
  };

  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledTime = (now: any, type: any) => {
    let time: any = moment();
    let hour = moment(time).hour();
    let minute = moment(time).minute();
    let second = moment(time).second();

    if (moment(now).day() === moment(time).day()) {
      return ({
        disabledHours: () =>range(0, hour),
        disabledMinutes: () => range(0,minute),
        disabledSeconds: () =>  range(0,second)
      })
    }
     return {}
  }

  // 编辑工具权限
  const handleAddToolPermission = () => {
    setModalVisible(true)
  }

  return (
    <Modal
      style={{ top: 80 }}
      width={620}
      className={styles.addUserModal}
      visible={visible}
      title="添加用户"
      onCancel={handleClose}
      onOk={handleAddUser}
      okButtonProps={{ loading }}
    >
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.title}>组织架构</div>
          <Input.Search
            allowClear
            value={searchValue}
            style={{ width: 280, marginBottom: 8 }}
            onChange={handleSearch}
          />
          <Tree
            checkable
            className={styles.treeWrap}
            autoExpandParent={autoExpandParent}
            titleRender={titleRender}
            treeData={treeData}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            onExpand={onExpand}
            expandedKeys={expandedKeys}
          />
        </div>
        <div className={styles.right}>
          {!!realCheckedUsers?.length && (
            <div
              className={styles.title}
            >{`已选择${realCheckedUsers?.length}个用户`}</div>
          )}
          <div className={styles.userWrap}>
            {realCheckedUsers?.map((item: any) => {
              const renderUser =
                item?.userName?.length > 10 ? (
                  <Tooltip title={item?.userName}>
                    {item?.userName.substring(0, 10) + "..."}
                  </Tooltip>
                ) : (
                  item?.userName
                );
              return (
                <div className={styles.selectItem} key={item?.key}>
                  {renderUser}
                  <CloseCircleOutlined
                    onClick={() => handleCloseItem(item?.key)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Form
        form={form}
        onValuesChange={onValuesChange}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
        initialValues={{ timeType: "forever" }}
      >
        <Form.Item label="权限等级">
          {/* 权限渲染 */}
          {renderAuthorizeList()}
        </Form.Item>
        {
          authorizeEnum? <></> : 
          <Form.Item label="工具权限" name="toolPermmission">
            <Input onClick={() => handleAddToolPermission()} className={styles.toolPermmissionInput}/>
          </Form.Item>
        }
        <Form.Item label="生效时间" name="timeType">
          <Radio.Group>
            <Radio value="forever">永久</Radio>
            <Radio value="custom">自定义时间段</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues?.timeType !== currentValues?.timeType}
        >
          {({ getFieldValue }) =>
            getFieldValue('timeType') === 'custom' ? (
              <Form.Item
                label="时间段"
                name="effectiveTime"
                rules={[{ required: true }, {
                  validator(_rule, value, cb) {
                    const s = moment(value?.[0]).valueOf();
                    const e = moment(value?.[1]).valueOf();

                    if (e < moment().valueOf()) {
                      cb('请重新选择时间，结束时间必须大于当前时间')
                    } else if (s > e) {
                      cb("起始时间必须小于结束时间");
                    }
                    cb();
                  },
                }]}
              >
                <DatePicker.RangePicker
                  disabledDate={disabledDate}
                  disabledTime={disabledTime}
                  showTime={{
                    hideDisabledOptions: true,
                  }}
                  ranges={{
                    近7日: [moment(), moment().add(7, "d")],
                    近15日: [moment(), moment().add(15, "d")],
                    近30日: [moment(), moment().add(30, "d")],
                  }}
                  format="YYYY-MM-DD HH:mm:ss"
                />
              </Form.Item>
            ) : null
          }
        </Form.Item>
      </Form>

      {/* 工具权限 */}
      {
      modalVisible && (
        <OperationPermissionsModal
          visible={modalVisible}
          optionType="addToolPermission"
          groupId={groupId}
          selectNodeType={selectNodeType}
          nodePathWithType={nodePathWithType}
          dataSourceType={dataSourceType}
          setModalVisible={setModalVisible}
          setCheckedParamsOperationNames={setCheckedParamsOperationNames}
          setTemplateOperationsSet={setTemplateOperationsSet}
          callback={refreshList}
          templateOperationsSet={templateOperationsSet}
        />)
      }
    </Modal>
  );
}
export default AddUserModal
