import React, { useEffect, useState, useMemo } from 'react'
import * as _ from 'lodash';
import { Button, Dropdown, Input, Menu, message, Modal, Switch, Table, Tooltip, Alert } from 'antd'
import { useRequest } from 'src/hook'
import { getDmsResourceData, getDmsResourceUnfold, ResourceDataType, batchPartChangeStatus, ResourceBatchStatusType, changeResourceRowStatus } from 'src/api'
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons'
import styles from './index.module.scss'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'


export const ResourceConfig = (props: any) => {
  const { connectionType, connectionId, permissionlist, canEdit } = props

  const { roleNameList } = permissionlist || {}

  const [search, setSearch] = useState('')
  const [resourceData, setResourceData] = useState<ResourceDataType[]>([])

  const [pagination, setPagination] = useState({pageSize: 10, pageNum: 0});
  const [rowKeys, setRowKeys] = useState<any>([])
  const [cancelParams, setCancelParams] = useState<any>({})
  const [cancelRecord, setCanceRecord] = useState<any>({})
  //选中行信息
  const [selectedRows, setSelectedRows] = useState([]);
  //选中keys集合
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  // 过滤条件有哪些
  const [filters, setFilters] = useState({})

  const handleNoteModify = (record: any) => {
    const {id, dbName, schemaName=null, enableStatus} = record
    const handleNotesChange = (value: string)=>{
      update({
        onOk:()=>{
          const note = value?.trim()
          if(note?.length>50){
            return message.error('最长50个字符！')
          }
          const params = {
            id,
            dbName,
            schemaName,
            connectionId,
            enableStatus,
            datasourceType: connectionType,
            note
          } as ResourceBatchStatusType
          changeResourceRowStatus(params).then(()=>{
            message.success('备注修改成功')
            refresh()
          }).catch(err=>{
            console.log('备注修改失败:', err)
          })
        }
      })
    }
    const { update } = Modal.confirm({
      icon: null,
      title:'备注修改', 
      content:<Input defaultValue={record?.note} onChange={(e)=>handleNotesChange(e.target.value)} maxLength={50} />,
    })
  }

  const columns: ColumnsType<ResourceDataType> = [
    {
      title: '资源名称',
      dataIndex: 'dbName',
      width: '30%',
      render: (dbName: string, record: ResourceDataType) => {
        return <p>{record?.schemaName ? record?.schemaName : record?.dbName}</p>
      },
    },
    {
      title: '管理员',
      dataIndex: 'admin',
      width: '20%'
    },
    {
      title: '备注',
      dataIndex: 'note',
      width: '30%',
      render: (note: string, record: ResourceDataType) => {
        return <p>
          {
            record?.note ? 
              record?.note?.length > 10 ? <Tooltip title={record?.note}>{record?.note?.substring(0,10)+'...'}</Tooltip> : record?.note 
              : '-'
          }
          {!record?.hasChild &&
            (
              canEdit 
                ? <Button
                    type='link'
                    style={{marginLeft: 4, padding: 0}}
                    onClick={()=>handleNoteModify(record)}
                  >
                    修改
                  </Button>
                : <Tooltip title={`您当前的角色是[${roleNameList?.join(',')}]，对[连接管理]没有操作权限`}>
                    <Button
                      type='link'
                      style={{marginLeft: 4, padding: 0}}
                      disabled={true}
                    >
                      修改
                    </Button>
                  </Tooltip>
            )
          }
        </p>
      }
    },
    {
      title: '状态',
      dataIndex: 'enableStatus',
      width: '10%',
      align: 'center',
      filters: [
        {
          text: '开启',
          value: 'true',
        },
        {
          text: '关闭',
          value: 'false',
        }
      ],
      filterMultiple: false,
      render: (enableStatus: boolean, record: ResourceDataType) => {

        return <div>
          {
            canEdit 
            ? <Switch checked={enableStatus} onChange={(e) => changSingleStatus(e, record, record?.children ? '' : 'rowStatus')} />
            : <Tooltip title={`您当前的角色是[${roleNameList?.join(',')}]，对[连接管理]没有操作权限`}>
                <Switch disabled={!canEdit} checked={enableStatus} /> 
              </Tooltip>
          }
        </div>
      }
    }
  ]
  /* 查询 dbName层 */
  const { run: getResourceData, loading: getResourceDataLoading, refresh } = useRequest(getDmsResourceData, {
    manual: true,
    onError() {
      setResourceData([])
    },

    formatResult(data: ResourceDataType[]) {
     data?.map((item, index) => {
        if (item.hasChild) {
          item.children = []
        }
        item.dbTier = true;
        item.searchName = item.dbName // 前端模糊搜索字段 scheme层 item.searchName = item.schameName
        item.onlyId = index + 1 // 接口返回的id可能为null 为了保证rowKey唯一
        return item
      })
      setResourceData(data)
    
      async function fn(index: number) {
        await onExpandFun(true, data[index], data)
      }
      if (rowKeys && rowKeys.length) {
        rowKeys.forEach((_item: any, index: number) => {
          fn(Number(_item - 1 ))

        })
      }
    }
  })
  /* 展开 schema层 */
  const { run: getResourceDataUnfold, loading: getResourceDataUnfoldLoading } = useRequest(getDmsResourceUnfold, {
    manual: true,
    fetchKey(connectionId, dbName, connectionType) {
      return dbName
    },
    onSuccess(data, params) {
      console.log('success');
    },
    onError(e, params) {
      console.log('error', e);
    },
  })

  /* 全局状态 */
  const { run: getResourceBatchStatus, loading: batchStatusLoading } = useRequest(batchPartChangeStatus, {
    manual: true,
    onSuccess() {
      message.success('修改成功')
      getResourceData(connectionId, connectionType)
      setRowKeys([])
      setCancelParams({})
      setCanceRecord({})
      setSelectedRows([]);
      setSelectedRowKeys([]);
    },
    onError() {
      // message.error('修改失败')
    }
  })

  /* row 启/禁用 */
  const { run: getRowStatus, loading: rowStatusLoading } = useRequest(changeResourceRowStatus, {
    manual: true,
    onSuccess() {
      message.success('修改成功')
      setCancelParams({})
      setCanceRecord({})
    },
    onError() {
      // message.error('修改失败')
    }
  })

  /* 单个状态 */
  const changSingleStatus = async (e: boolean, record: ResourceDataType, flag: string) => {
    const params = {
      dbName: record.dbName,
      schemaName: record?.schemaName || null,
      connectionId,
      id: record.id,
      enableStatus: e,
      datasourceType: connectionType,
      note: record?.note
    } as ResourceBatchStatusType
    if (flag === 'rowStatus') {
      params.parentId = record?.databaseId
    }
    if (!e) {
      setCancelParams(params)
      setCanceRecord(record)
    } else {
      changeStatus(params, record)
    }

  }

  const changeStatus = async (params: ResourceBatchStatusType, record: ResourceDataType) => {
    setRowKeys(rowKeys)
    await getRowStatus(params)
    if (!record?.dbTier || record?.hasChild) {
      refresh()
    } else {
      getResourceData(connectionId, connectionType)
    }
  }

  useEffect(() => {
    getResourceData(connectionId, connectionType);
    setSelectedRows([]);
    setSelectedRowKeys([]);
  }, [connectionId, getResourceData, connectionType])


  const batchOperations = (key: any) => {
    let params = {
      connectionId,
      enableStatus: key === 'forbidden' ? false : true,
      datasourceType: connectionType
    } as any
    const formatSelectedRows = selectedRows.map((row: any) => {
      return ({
        ...params,
        id: row?.id,
        parentId: row?.parentDBId,
        dbName: row?.dbName,
        schemaName: row?.schemaName,
        note: row?.note
      })
    })

    if (key === 'forbidden') {
      setCancelParams({rows: formatSelectedRows,connectionId, isBatch: true})
    }else {
      getResourceBatchStatus(formatSelectedRows)
    }
  }

  useEffect(() => {
  
      let arr = [...rowKeys] as any
      resourceData.forEach((item: any) => {
        if (item?.children && item?.children.length) {
          arr.push(item?.onlyId)
          let a = [...new Set(arr)] as any;
          setRowKeys(a)
        }
      })
  }, [search])

  const onExpandFun = async (expanded: boolean, record: ResourceDataType, newData?: any) => {
    if (expanded) {
      let arr = [...rowKeys] as any
      arr.push(record?.onlyId)

      let cloneResourceData = newData?.length ? _.cloneDeep(newData) : _.cloneDeep(resourceData);
      const removeDuplice = [...new Set(arr)]
      setRowKeys(removeDuplice)
      if (record?.hasChild) {
        let children = await getResourceDataUnfold(record?.connectionId, record?.dbName, connectionType)
        children.map((item,cIndex) => {
          item.parentId = record?.onlyId //父节点的key 唯一字段
          item.databaseId = record?.id ? record?.id : null // 当切换schema的状态时，需要传一个parentId的值，这个值取自database的id
          item.searchName = item.schemaName
          item.dbTier = false;
          item.note = item?.note;
          item.onlyId = `${record.onlyId}-${cIndex}`
          item.parentDBId = record?.id //真正的parentId
        })
        cloneResourceData = cloneResourceData?.map((item: ResourceDataType) => {
          if (item.onlyId === record?.onlyId) {
              item.children = children
          }
          return item
      })
        setResourceData(cloneResourceData)
      }

    } else {
      let newArr = rowKeys.filter((item: number) => {
        if (item !== record?.onlyId) {
          return item
        }
      })
      setRowKeys(newArr)
    }
  }

  const judge = (data: ResourceDataType, currentFilters: Record<string, React.Key[] | null>) => {
    return Object.keys(currentFilters).every((item) => {
      try {
        /**
         * 理论上只会解析出boolean，如果解析出null，说明data里不需要过滤此项
         */
        let value = JSON.parse(currentFilters[item] as unknown as string);
        if(value === null){
          return true;
        }
        // 如果解析出boolean的情况走下面
        return value === data[item as keyof ResourceDataType];
      } catch {
        // 兜底，任何解析不出来的情况都return true,比如空字符串
        return true;
      }
    })
  }

  function handleData(
    arr: ResourceDataType[],
    val: string,
    filters: Record<string, React.Key[] | null>
  ) {
    let newArr = [] as ResourceDataType[]
    let reg = new RegExp(val, 'i')
    arr.forEach((item: ResourceDataType) => {
        if (item?.children && item?.children.length) {
            let children = handleData(item?.children, val, filters)
            let obj = {
                ...item,
                children
            } as ResourceDataType
            if (children && children.length) {
                newArr.push(obj)
            } else if (item?.searchName.match(reg) && judge(item,filters)) {
                newArr.push({ ...item })
            }
        } else {
            if (item?.searchName.match(reg) && judge(item,filters)) {
                newArr.push(item)
            }
        }
    })
    return newArr
}

  const getSchemaWithDB = (dbOnlyIds: any, selectedRows: any) => {
    let parentItems: any = [];
    dbOnlyIds.map((i: any) => {
      // 根据parentId 查找到被选中的db下的所有子节点
      const schemaItems = selectedRows.filter((a: any) => a?.parentId === i?.onlyId);
      if (schemaItems?.length) {
        parentItems =parentItems.concat(schemaItems);
      }else {
        //未被展开过
        parentItems.push(i)
      }
    })
    return parentItems;
  }

  const onTableChange = (pagination: TablePaginationConfig, filters: Record<string, React.Key[] | null>) => {
    setFilters(filters);
  }

  const rowSelection = {
    checkStrictly: false,
    onChange: (selectedRowKeys: string[], selectedRows: any) => {
      //db被选中时，父节点和子节点同时存在时 只保留schema层数据 
     
      let filteredSelectedRows: any = [];
      const dbItems = selectedRows.filter((item: any) => item?.hasChild)
      const dbOnlyIds = dbItems?.map((i: any) => i?.onlyId);
      //选中的db及schema 保留schema信息
      filteredSelectedRows = getSchemaWithDB(dbItems, selectedRows);
      //只有被选中的schema
      const schemaItems = selectedRows.filter((s: any) => !dbOnlyIds.includes(s?.parentId) && !dbOnlyIds.includes(s?.onlyId))
    
     setSelectedRows(filteredSelectedRows.concat(schemaItems));
     setSelectedRowKeys(selectedRowKeys);
    },
    onSelectAll: (selected: boolean, selectedRows: any, changeRows: any) => {

      //只需要保存db层 有些db没有被展开
      if (selected) {
        //三层
        let dbItems = selectedRows.filter((row: any) => row?.hasChild);
   
        //两层mysql
        if (!dbItems?.length) {
          dbItems = selectedRows;
        }
        setSelectedRows(dbItems);
      }
    },
    getCheckboxProps: (record: any) => ({
      disabled: !canEdit
    }),
  }

  const filteredDataSource = useMemo(() => {
    return (search || Object.keys(filters).length) ? handleData(resourceData, search, filters) : resourceData
  }, [
    search,
    JSON.stringify(resourceData),
    JSON.stringify(filters)
  ])

  return (
    <>
      <div className={styles.resourceActions}>
        <Dropdown
          // disabled={!selectedRow?.length}
          overlay={
            (!(selectedRows?.length && canEdit)) ?
            <></>
            : <Menu onClick={({ key }) => batchOperations(key)}>
              <Menu.Item key="start">批量启用</Menu.Item>
              <Menu.Item key="forbidden">批量禁用</Menu.Item>
            </Menu>
          }
          className={!canEdit ? styles.dropDownDisabled : ''}
        >
          {
            canEdit
            ? <Button>批量操作</Button>
            : <Tooltip title={`您当前的角色是[${roleNameList?.join(',')}]，对[连接管理]没有操作权限`}>
                <Button disabled={true}>批量操作</Button>
              </Tooltip>
          }
        </Dropdown>
        <Input
          allowClear
          className={styles.searchInput}
          prefix={<SearchOutlined />}
          placeholder="请输入资源名称"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value)
            setSelectedRows([]);
            setSelectedRowKeys([]);
          }}
        />
      </div>
      <Table
        scroll={{ y:`calc(100vh - 480px)` }}
        loading={
          getResourceDataLoading ||
          getResourceDataUnfoldLoading ||
          rowStatusLoading || batchStatusLoading
        }
        dataSource={filteredDataSource}
        columns={columns}
        rowKey="onlyId"
        size="small"
        expandedRowKeys={rowKeys}
        onExpand={(expanded, record) => onExpandFun(expanded, record)}
        expandIconColumnIndex={1}
        //@ts-ignore
        rowSelection={{ 
          selectedRowKeys: selectedRowKeys,
          ...rowSelection,
         }}
         onChange={onTableChange}
        pagination={{
          showTotal: (total) =>`总共 ${total || 0} 条`,
          showSizeChanger: true,
          onChange(page, pageSize) {
            setPagination({pageSize: pageSize || 10, pageNum: page - 1})
          }
         }}
      ></Table>
      <Modal
        visible={cancelParams?.connectionId}
        className={styles.resourceActionsModal}
        width={460}
        // onOk={handleOk}
        // onCancel={() => {
        //   setCancelParams({})
        //   setCanceRecord({})
        // }}
        closable={false}
        footer={[
          <Button
            style={{ width: '80px' }}
            onClick={() => {
              setCancelParams({})
              setCanceRecord({})
            }}>
            取消
          </Button>,
          <Button
            type="primary"
            style={{ width: '80px' }}
            onClick={() => {
              let params = {
                ...cancelParams
              }
              params.dropPermission = false;
              if (params?.isBatch) {
                getResourceBatchStatus(params?.rows)
              }else {
                changeStatus(params, cancelRecord)
              }
            }}>
            确认
          </Button>
        ]}
      >
        <p className={styles.modalContent} >
          <InfoCircleOutlined className={styles.contentPre} />确认关闭此资源吗?
        </p>
      </Modal>
    </>
  )
}