import React, { useState, useEffect, useMemo } from 'react'
import { Row, Col, Select, Button, Popconfirm } from 'antd'
import { DatePicker, Iconfont } from 'src/components'
import dayjs, { Dayjs } from 'dayjs'
import {
  SqlType,
  SqlTypes,
  ExtratTypes,
  ExecutionReportRequest,
  exportAuditLogSql,
  getToolbarDataNodeList, exportAuditLogSql4Personal,
} from 'src/api'
import styles from '../audit/executionPage/index.module.scss'
import { formatDateRange, handleDownload } from 'src/util'
import { openNotification } from 'src/components/ExportNotification'
import { useSelector } from 'src/hook'

const { RangePicker } = DatePicker
const { Option } = Select

interface FilterParams {
  connectionName?: string[]
  connectionId?: string[]
  timeRange?: [number, number] | null
  // dbTypes?: DataSourceType[]
  sqlTypes?: SqlType[]
  // 0 执行失败 1 执行成功
  resultFlag?: string
  extratTypes?: ExtratTypes[]
  // 0所有 1执行编辑器 2终端
  // actuatorType?: 0 | 1 | 2
  limit?: number
  executors?: string[]
  offset?: number
}

// 根据组件 filterParams 状态构造出查询请求所需要的参数
const getRequestParams = (filterParams: FilterParams) => {
  const { connectionName, connectionId, timeRange, sqlTypes, resultFlag, extratTypes } = filterParams
  // 构造查询参数
  const params: Partial<ExecutionReportRequest> = {
    executeBeginMs: timeRange?.[0],
    executeEndMs: timeRange?.[1],
  }
  if (connectionName?.length) {
    params.connectionName = connectionName
  }
  if (connectionId?.length) {
    params.connectionId = connectionId
  }
  if (sqlTypes && sqlTypes[0]) {
    params.sqlTypes = sqlTypes
  }
  if (resultFlag !== undefined) {
    params.resultFlag = Number(resultFlag)
  }
  if (extratTypes?.[0]) {
    params.extratTypes = extratTypes
  }
  delete filterParams.timeRange
  return { ...filterParams, ...params }
}

export const SearchHeader = ({
  setSearchParams,
  refresh,
}: {
  setSearchParams: (values: any) => void
  refresh: () => void
}) => {
  //@ts-ignore
  const [filterParams, setFilterParams] = useState<FilterParams>({
    limit: 10,
    offset: 0
  })
  const [isExport, setIsExport] = useState(false);
  const { userId } = useSelector((state) => state.login.userInfo)
  const [connectionList, setConnectionList] = useState<any[]>([])

  const {
    connectionName,
    connectionId,
    timeRange,
    sqlTypes,
    resultFlag,
  } = filterParams

  // 初次加载请求[执行连接]接口
  useEffect(() => {
    const getConnectionList = async () => {
      try {
        const connections = await getToolbarDataNodeList({
          nodeType: 'root',
        })
        setConnectionList(connections)
      } catch { }
    }
    getConnectionList()
  }, [])

  const rangeValue = useMemo(() => {
    if (timeRange === null) {
      return null
    } else {
      const range = timeRange && timeRange.map((timestamp) => dayjs(timestamp)) as [
        Dayjs,
        Dayjs,
      ]
      return range
    }
  }, [timeRange])

  // 导出
  const exportData = () => {
    setIsExport(true)
    exportAuditLogSql4Personal(getRequestParams({
      ...filterParams,
      executors: userId ? [userId] : undefined
    }) as Partial<ExecutionReportRequest>).then((res: number) => {
      const origin = window?.location?.origin;
      setIsExport(false);
      openNotification(
        '任务 执行历史导出',
        '已经执行完成，文件生成成功',
        () => handleDownload({ href: `${origin}/export/export/download/${res}` })
      )
    }).catch(() => {
      setIsExport(false)
    })
  }

  const SqlTypeOptions = SqlTypes.map((type) => ({ label: type, value: type }))

  return (
    <div className={styles.searchHeader}>
      <Row justify="end" gutter={16} style={{ alignItems: 'center' }}>
        <label>过滤条件:</label>
        <Col>
          <Select
            mode="multiple"
            placeholder="执行连接"
            value={connectionId}
            onChange={(selected, info) => {
              setFilterParams({ ...filterParams, connectionName: info?.map((items: any) => { return items?.label }), connectionId: selected })
            }}
            maxTagCount={2}
            allowClear
            style={{ minWidth: 144 }}
            size="small"
            optionFilterProp="label"
          >
            {connectionList.map((connection) => {
              const { connectionId, nodeName, connectionType } = connection
              return (
                <Option
                  key={connectionId}
                  value={connectionId}
                  label={nodeName}
                >
                  <Iconfont
                    type={
                      'icon-connection-' +
                      (connectionType || 'Other')
                    }
                    className="mr8"
                    style={{ fontSize: 14, opacity: 1 }}
                  />
                  <p title={nodeName} style={{ display: "contents" }}>{nodeName}</p>
                </Option>
              )
            })}
          </Select>
        </Col>
        <Col>
          <Select
            showArrow
            placeholder="语句类型"
            value={sqlTypes}
            onChange={(sqlTypes) => {
              setFilterParams({ ...filterParams, sqlTypes })
            }}
            allowClear
            style={{ minWidth: 96 }}
            mode="multiple"
            maxTagCount={1}
            options={SqlTypeOptions}
            size="small"
          ></Select>
        </Col>
        <Col>
          <Select
            placeholder="执行结果"
            value={resultFlag}
            onChange={(resultFlag: string) => {
              setFilterParams({ ...filterParams, resultFlag })
            }}
            allowClear
            style={{ minWidth: 96 }}
            size="small"
          >
            <Option key="1" value="1">
              执行成功
            </Option>
            <Option key="0" value="0">
              执行失败
            </Option>
          </Select>
        </Col>
        <Col>
          <RangePicker
            value={rangeValue}
            onChange={(dates) => {
              if (dates === null) {
                return setFilterParams({
                  ...filterParams,
                  timeRange: null,
                })
              }
              const [start, end] = formatDateRange(dates)
              if (start && end) {
                setFilterParams({
                  ...filterParams,
                  timeRange: [start, end],
                })
              }
            }}
            allowClear
            placeholder={['开始日期', '结束日期']}
            size="small"
          />
        </Col>
        <Col>
          <Button
            onClick={() => setSearchParams({ ...filterParams, offset: 0 })}
            type="primary"
            className="mr8"
            size="small"
          >
            查询
          </Button>
          <Button
            onClick={() =>
              setFilterParams({
                connectionName: [],
                connectionId: [],
                timeRange: null,
                sqlTypes: [],
                resultFlag: undefined,
              })
            }
            className="mr8"
            size="small"
          >
            重置
          </Button>
          <Popconfirm
            title="确定导出当前筛选结果？"
            onConfirm={exportData}
            placement="bottomRight"
          >
            <Button size="small" className="mr8" loading={isExport} disabled={isExport}>导出</Button>
          </Popconfirm>
        </Col>
      </Row>
    </div>
  )
}