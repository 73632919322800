// 流程
//菜单
export const MENU_FLOW = '流程';
export const DATA_CHANGE_FLOW = '数据变更';

export const STATUS_MAPPING = {
  待审核: {
    草稿: [{ label: "删除草稿" }, { label: "编辑", type: "edit" }],
    审批中: [{ label: "撤回申请", bgColor: "#3262ff", color: "#ffffff" }, { label: "催一下" }],
  },
  已审核: {
    已驳回: [{ label: "再次申请", bgColor: "#3262ff", color: "#ffffff" }],
    待执行: [{ label: "执行", bgColor: "#3262ff", color: "#ffffff" }, { label: "中止任务" }],
    执行中: [{ label: "中止执行" }],
    执行成功: [{ label: "终止任务", bgColor: "#3262ff", color: "#ffffff" }, { label: "执行回退" }], //返回已完成
    执行失败: [{ label: "终止任务", bgColor: "#3262ff", color: "#ffffff" }], //返回已完成
    回退成功: [{ label: "终止任务", bgColor: "#3262ff", color: "#ffffff" }], //返回已完成
    回退失败: [{ label: "终止任务", bgColor: "#3262ff", color: "#ffffff" }], //返回已完成
    被中止: [{ label: "终止任务" }, { label: "再次申请", bgColor: "#3262ff", color: "#ffffff" }]
  },
};

// 后端 工单详情接口，frontStatus与status的对应关系与 工单list返回的不一致, 后端没有时间修改, 额外定义按钮
export const STATUS_MAPPING_DETAIL = {
  草稿: [{ label: "删除草稿" }, { label: "编辑", type: "edit" }],
  审批中: [{ label: "撤回申请", bgColor: "#3262ff", color: "#ffffff" }, { label: "催一下" }],
  已驳回: [{ label: "再次申请", bgColor: "#3262ff", color: "#ffffff" }],
  待执行: [{ label: "执行", bgColor: "#3262ff", color: "#ffffff" }, { label: "中止任务" }],
  执行中: [{ label: "中止执行" }],
  执行成功: [{ label: "终止任务", bgColor: "#3262ff", color: "#ffffff" }, { label: "执行回退" }], //返回已完成
  执行失败: [{ label: "终止任务", bgColor: "#3262ff", color: "#ffffff" }], //返回已完成
  回退成功: [{ label: "终止任务", bgColor: "#3262ff", color: "#ffffff" }], //返回已完成
  回退失败: [{ label: "终止任务", bgColor: "#3262ff", color: "#ffffff" }], //返回已完成
  被中止: [{ label: "终止任务" }, { label: "再次申请", bgColor: "#3262ff", color: "#ffffff" }],
  已撤回: [{ label: "再次申请", bgColor: "#3262ff", color: "#ffffff" }]
};