import React, { useEffect, useState } from "react";
import { Button, Spin, Table, Tag, Popconfirm, message, Input } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useRequest } from "src/hook";
import {
  getAccessList,
  checkDeleteAccess,
  deleteAccess,
  IAccessProps,
  getConList,
  ConListType,
} from "src/api";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import AccessPolicyModal from "./AccessPolicyModal";
import { getScrollX } from 'src/util'

interface TableOneType {
    comment: string;
    id: number;
    ipAddr: string;
    type: number;
    updatedAt: string;
}
interface TableType {
  data: TableOneType[];
  pageNo: number;
  pageSize: number;
  totalItem: number;
  totalPage: number;
}

const ACCESS_VALUE = 0;
const NO_ACCESS_VALUE = 1;

export const AccessPolicyCard = () => {
  const [visible, setVisible] = useState(false);
  const [filterParams, setFilterParams] = useState<IAccessProps>();
  const [currentValue, setCurrentValue] = useState<IAccessProps>();
  const [data, setData] = useState<TableType>();
  const [search, setSearch] = useState('')
  const [remindContent, setReminContent] = useState("确定删除吗？");

  const { loading, run: updateList } = useRequest(
    (params: ConListType) => getConList(params),
    {
      manual: true,
      onSuccess: (res) => {
        setData(res);
      },
    }
  );

  useEffect(() => {
    updateList({type:-1});
  }, []);

  const checkAccess = (id: number) => {
    checkDeleteAccess(id).then((res) => {
      if (!res) {
        setReminContent("该ip正在活跃，确定删除吗？");
      } else {
        setReminContent("确定删除吗？");
      }
    });
  };

  const columns: ColumnsType<IAccessProps> = [
    {
      title: "来源",
      dataIndex: "ipAddr",
      key: "ipAddr",
    },
    {
      title: "访问策略",
      dataIndex: "type",
      key: "type",
      filters: [
        {
          text: "白名单策略",
          value: ACCESS_VALUE,
        },
        {
          text: "黑名单策略",
          value: NO_ACCESS_VALUE,
        },
      ],
      filterMultiple: false,
      render: (value, record) => {
        if (!value)
          return (
            <Tag color="green" key={record?.id}>
              白名单策略
            </Tag>
          );
        return (
          <Tag color="red" key={record?.id}>
            黑名单策略
          </Tag>
        );
      },
    },
    {
      title: "备注",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "更新时间",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render(value) {
        return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      render: (value, record) => {
        return (
          <>
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() => editAccess(record)}
            >
              编辑
            </Button>
            <Popconfirm
              title={remindContent}
              onConfirm={() => delAccess(value)}
              okText="确定"
              cancelText="取消"
            >
              <Button type="link" danger onClick={() => checkAccess(value)}>
                删除
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const onChangeTableParams = (pagination: any, filters: any) => {
    const { type } = filters || {};
    if (Array.isArray(type) && type.length) {
      updateList({ type: type[0] ,pageNo:pagination.current, pageSize:pagination.pageSize, ipAddr:search});
    } else {
      updateList({ type: -1 ,pageNo:pagination.current, pageSize:pagination.pageSize, ipAddr:search});
    }
    setFilterParams(filters);
  };

  const editAccess = (values: IAccessProps) => {
    setCurrentValue(values);
    openModal();
  };

  const addAccess = () => {
    setCurrentValue(undefined);
    openModal();
  };

  const delAccess = (id: number) => {
    deleteAccess(id).then(() => {
      message.success("删除成功");
      onSuccess();
    });
  };

  const openModal = () => {
    setVisible(true);
  };

  const onCancel = () => {
    setVisible(false);
  };

  const onSuccess = () => {
    updateList({ type: filterParams?.type ?? -1 , ipAddr:search});
    onCancel();
  };

  const onSearch = (value: string) => {
    setSearch(value)
    updateList({ type: -1, ipAddr: value });
  };

  const formatResult = (data: TableOneType[] | undefined) => {
    if (!data) {
      return
    }
    return data.map((item: IAccessProps) => {
      return {
        key: item.id,
        ...item,
      };
    });
  };

  return (
    <section className="cq-new-card flow-card">
      <div className="cq-card__headerbar">
        <h3 className="cq-card__title">访问设置</h3>
        <div className="cq-header__action">
          <Input
            width={136}
            allowClear
            placeholder="搜索来源"
            prefix={<SearchOutlined />}
            onChange={(e: any) => {
              onSearch(e.target.value);
            }}
          />
          <Button
            onClick={addAccess}
            type="primary"
            style={{ marginLeft: "10px" }}
          >
            新增访问规则
          </Button>
        </div>
      </div>

      <section className="pd-20">
        <Spin spinning={loading}>
          <Table
            columns={columns}
            dataSource={formatResult(data?.data)}
            onChange={onChangeTableParams}
            pagination={{
              total: data?.totalItem,
              current: data?.pageNo,
              pageSize: data?.pageSize,
            }}
            scroll={{
              x: getScrollX(columns),
              y: `calc(100vh - 336px)`,
            }}
          />
        </Spin>
        <AccessPolicyModal
          key={JSON.stringify(currentValue)}
          value={currentValue}
          visible={visible}
          callback={onSuccess}
          onCancel={onCancel}
        />
      </section>
    </section>
  );
};
