import React, { useContext, useState } from 'react'
import { Table, Form, Select, Input, Button, Popconfirm } from 'antd'
import { FormInstance } from 'antd/lib/form'
import styles from './index.module.scss'
import { isEmpty } from 'lodash'

const EditableContext = React.createContext<FormInstance<any> | null>(null)

interface Item {
  key: string
  sourceFields: string
  targetFields: string
  primary: string
}

interface EditableRowProps {
  index: number
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

interface EditableCellProps {
  title: React.ReactNode
  editable: boolean
  children: React.ReactNode | null
  dataIndex: keyof Item
  record: Item
  type?: 'input' | 'select'
  targetTableFieldList?: any[]
  sourceTableFieldList?: any[]
  handleSave: (record: Item) => void
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  type,
  targetTableFieldList,
  sourceTableFieldList,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const form = useContext(EditableContext)!

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({ [dataIndex]: record[dataIndex] })
  }

  const save = async () => {
    try {
      const values = await form.validateFields()

      toggleEdit()
      handleSave({ ...record, ...values })
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }

  let childNode = children
  const options =
    dataIndex === 'sourceFields' ? sourceTableFieldList : targetTableFieldList

  if (editable) {
    childNode = editing ? (
      <>
        {type === 'select' && (
          <Form.Item
            name={dataIndex}
            className="editable-cell-select"
          >
            <Select onBlur={save} options={options}></Select>
          </Form.Item>
        )}
        {type === 'input' && (
          <Form.Item
            name={dataIndex}
            className="editable-cell-input">
            <Input onPressEnter={save} onBlur={save} />
          </Form.Item>
        )}
      </>
    ) : (
      <div
        className="editable-cell-value-wrap"
        onClick={toggleEdit}
      >
        {children}
      </div>
    )
  }

  return <td {...restProps}>{childNode}</td>
}

type EditableTableProps = Parameters<typeof Table>[0]

interface DataType {
  key: React.Key
  sourceFields: string | null
  targetFields: string | null
}

interface EditableTableState {
  dataSource: DataType[]
  count: number
}

interface ExtraProps extends EditableTableProps {
  targetTableFieldList?: any[]
  sourceTableFieldList?: any[]
  setTableFieldMap: any
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>

export class EditableTable extends React.Component<
  ExtraProps,
  EditableTableState
> {
  columns: (ColumnTypes[number] & {
    editable?: boolean
    dataIndex: string
    type?: 'select' | 'input'
    targetTableFieldList?: any[]
    sourceTableFieldList?: any[]
  })[]

  constructor(props: ExtraProps) {
    super(props)

    this.state = {
      dataSource: [
        {
          key: '0',
          sourceFields: null,
          targetFields: null,
        },
      ],
      count: 1,
    }

    this.columns = [
      {
        title: '源字段',
        dataIndex: 'sourceFields',
        editable: true,
        type: 'select',
        sourceTableFieldList: this.props.sourceTableFieldList,
      },
      {
        title: '目标字段',
        dataIndex: 'targetFields',
        editable: true,
        type: 'select',
        targetTableFieldList: this.props.targetTableFieldList,
      },
      {
        title: '操作',
        dataIndex: 'operation',
        // @ts-ignore
        render: (_, record: { key: React.Key }) =>
          this.state.dataSource.length >= 1 ? (
            <Popconfirm
              title="确定删除?"
              onConfirm={() => this.handleDelete(record.key)}
            >
              <Button type="link">删除</Button>
            </Popconfirm>
          ) : null,
      },
    ]
  }

  // 返回与最后一个反括号匹配的括号的index，如：999((())) => 3
  findIndex = (str: string) => {
    if (!str) return 0;
    let bracketStack: string[] = [], index = 0;
    for (let i = str.length - 1; i > 0; i--) {
      if (str[i] === ')') {
        bracketStack.push(str[i]);
      } else if (str[i] === '(') {
        bracketStack.pop();
      }
      if (bracketStack.length === 0) {
        index = i;
        break;
      }
    }
    return index;
  }

  matchField = (str: string) => {
    const index = this.findIndex(str);
    const result = str.substring(0, index);
    return result;
  }

  setDefaultDataSource = (sourceFields: any, targetFields: any) => {
    if (!isEmpty(sourceFields)) {
      return sourceFields.map((s: any, index: string) => {
        // 需要渲染文本，否则会导致高度为0，无法触发select 或者 修改input的样式设置高度
        let initItem = {
          key: index,
          sourceFields: s.value,
          targetFields: '请选择'
        }

        targetFields && targetFields.forEach((t: any) => {
          if (this.matchField(s.label) === this.matchField(t.label)) {
            initItem['targetFields'] = t.value
          }
        })
        return initItem
      })
    }
  }

  componentDidMount(): void {
    const { targetTableFieldList = [], sourceTableFieldList } = this.props;
    const initDataSource: DataType[] = this.setDefaultDataSource(sourceTableFieldList, targetTableFieldList)
    this.setState({ dataSource: initDataSource, count: initDataSource.length + 1 })
    this.updateFieldMap(initDataSource)
  }

  updateFieldMap = (newData: any[]) => {
    const dataFormat = newData.map((d) => {
      // const columnTypeIdx = d.targetFields?.lastIndexOf('(')
      const columnTypeIdx = this.findIndex(d.targetFields)
      const sourceColumn = d.sourceFields === null ? null : d.sourceFields.match(/\([\d)]+\)$/)
      
      return {
        columnName: columnTypeIdx === -1 ? null : d.targetFields?.slice(0, columnTypeIdx),
        columnTypeName: columnTypeIdx === -1 ? null : d.targetFields?.slice(columnTypeIdx + 1, -1),
        index: sourceColumn === null ? null : sourceColumn[sourceColumn.length - 1].replace(/[()]/g, ''),
        sourceColumnName: sourceColumn === null ? null : sourceColumn.input,
      }
    })
    this.props.setTableFieldMap(dataFormat)
  }

  handleAdd = () => {
    const { count, dataSource } = this.state
    const newData: DataType = {
      key: count,
      sourceFields: null,
      targetFields: null,
    }
    const newDataSource = [...dataSource, newData]
    this.setState({
      dataSource: newDataSource,
      count: count + 1,
    })
  }

  handleDelete = (key: React.Key) => {
    const dataSource = [...this.state.dataSource]
    const newData = dataSource.filter((item) => item.key !== key)
    this.updateFieldMap(newData)
    this.setState({ dataSource: newData })
  }

  handleSave = (row: DataType, dataIndex: string) => {
    const newData = [...this.state.dataSource]
    const index = newData.findIndex((item) => row.key === item.key)
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row,
    })
    this.setState({ dataSource: newData })
    this.updateFieldMap(newData)
  }

  render() {
    const { dataSource } = this.state
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: (record: DataType) => ({
          record,
          editable: col.editable,
          type: col.type,
          sourceTableFieldList: this.props.sourceTableFieldList,
          targetTableFieldList: this.props.targetTableFieldList,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      }
    })

    return (
      <div>
        <Button
          onClick={this.handleAdd}
          type="primary"
          style={{ marginBottom: 16 }}
        >
          新增映射
        </Button>
        <Table
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          scroll={{ x: 'max-content', y: 260 }}
          dataSource={dataSource}
          columns={columns as ColumnTypes}
          size="small"
          className={styles.editableTable}
        />
      </div>
    )
  }
}
