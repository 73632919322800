/**
 * 不同对象级别授权
 */
import React, { useEffect, useState } from 'react'
import { ShoppingCartOutlined, PlusOutlined, RollbackOutlined } from "@ant-design/icons"
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
import type { TableRowSelection } from "antd/es/table/interface"
import { Badge, Button, Table, Spin, Checkbox, Tooltip, Space } from "antd"
import { useRequest, useDispatch, useSelector } from 'src/hook'
import { getScrollX, renderTableFields } from 'src/util'
import { Iconfont } from 'src/components'
import { FineGrainedPermissionModal } from '../../visit-request-operation/components/FineGrainedPermissionModal';

import styles from "./index.module.scss"


interface IProps {
  loading?: boolean;
  objectResult: any;
  onUpdataParmas?: (params: any, type?: string) => void;
  selectTreeItem?: any;
  canEdit?: boolean;
  needRefresh?: any;
  onCancelBatchOperation?: () => void;
  viewAppliedDetail?: boolean; //仅查看
  isInShoppingCart?: boolean;
  permissionCollections?: any
  onUpdateConnectionObjectPermissions?: (params: any) => void;
}

const renderGroupName = (nodeName: string) => nodeName === '表' ? '表名' : nodeName

const SubSchemaLevelComponent = (props: IProps) => {
  const { selectTreeItem, onUpdataParmas, canEdit = true, isInShoppingCart = false,
    viewAppliedDetail = false, loading = false, onCancelBatchOperation, objectResult,
    permissionCollections,
    onUpdateConnectionObjectPermissions
  } = props;

  const dispatch = useDispatch()
  const history = useHistory();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
  });
  const [columns, setColumns] = useState<any[]>([]);
  const [authorizeList, setAuthorizeList] = useState<any[]>([])
  const [canOperate, setCanOperate] = useState<boolean>(true)
  const [dataSource, setDataSource] = useState<any[]>([])
  const [permissionCollectionVOS, setPermissionCollectionVOS] = useState<any[]>([])
  const [defaultPermissionSetValues, setDefaultPermissionSetValues] = useState<any[]>([])
  //批量操作schema一下层级
  const [batchOperationState, setBatchOperationState] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isShowAuthModal, setIsShowAuthModal] = useState(false)


  const formatData = (res: any) => {

    const { authObjectVoList = [] } = res || {}
    // 初始化选中值
    const initPermissionCollectionVOS = authObjectVoList?.map((item: any) => {
      const { jsonObject, connectionType, nodePathWithType, nodeName, nodePath, nodeType } = item
      const operations = Object.keys(jsonObject)?.map(i => {
        if (jsonObject[i]) {
          return i
        }
        return false
      })?.filter(i => i)

      if (operations?.length) {
        return {
          permissionCollectionObjectVO: {
            dataSourceType: connectionType,
            nodePathWithType: nodePathWithType,
            nodeName,
            nodePath,
            nodeType,
            operations
          }
        }
      }
      return false
    })?.filter((i: any) => i);

    return initPermissionCollectionVOS;
  }

  useEffect(() => {

    if (_.isEmpty(objectResult)) return
    const list: any = formatData(objectResult);

    setAuthorizeList(objectResult?.headList || [])
    setPagination({
      current: 1,
      pageSize: 10,
      total: objectResult?.authObjectVoList?.length
    });
    const dataSource = objectResult?.authObjectVoList?.slice(0, 10) || []
    setDataSource(dataSource);

    const curItem = permissionCollections?.[selectTreeItem?.key]

    if (_.isEmpty(curItem)) {
      setDefaultPermissionSetValues(list)
      setPermissionCollectionVOS(list)
    } else {
      setPermissionCollectionVOS(curItem)
    }


    setCanOperate(canEdit && objectResult?.canOperation)
  }, [JSON.stringify(objectResult)])


  useEffect(() => {
    setCanOperate(canEdit)
  }, [canEdit])

  useEffect(() => {
    setColumns((c: any[]) => {
      const arr: any[] = [{
        title: renderGroupName(selectTreeItem?.nodeName),
        dataIndex: "objectName",
        key: "objectName",
        width: 200,
        ellipsis: true,
        render: (txt: string) => <span><Iconfont className={styles.mr4} type={`icon-${selectTreeItem?.nodeType}`} /><Tooltip title={renderTableFields(txt)}>{renderTableFields(txt)}</Tooltip></span>
      },];
      authorizeList?.forEach((item: any) => {
        const itemChecked = !!dataSource?.length && !dataSource?.map((i: any) => i?.jsonObject)?.some((i: any) => !i[item?.operation])
        const indeterminate = !itemChecked && dataSource.map((i: any) => i?.jsonObject)?.some((i: any) => i[item?.operation])
        arr.push({
          title: (
            <Checkbox
              disabled={!canOperate || !dataSource.length}
              onChange={(e) => handleItemCheck(e.target.checked, item, dataSource)}
              checked={itemChecked}
              indeterminate={indeterminate}
            >
              {item?.operationName}
            </Checkbox>
          ),
          dataIndex: item?.operation,
          key: item?.operation,
          width: 120,
          render: (_: string, record: any) => (
            <Checkbox
              disabled={!canOperate}
              checked={record?.jsonObject?.[item?.operation]}
              onClick={(e) => handleAuhtorizeChange(e, item?.operation, record)}
            />
          ),
        });
      });
      return [...arr]
    });
  }, [JSON.stringify(authorizeList), canOperate, dataSource, selectTreeItem?.nodeName, selectTreeItem?.nodeType])

  useEffect(() => {

    if (_.isEqual(defaultPermissionSetValues, permissionCollectionVOS)) {
      return
    }
    // 更新参数
    onUpdataParmas && onUpdataParmas(permissionCollectionVOS, 'compare')
    // 更新table选中状态
    setDataSource((d: any[]) => {
      return d?.map((i: any) => {
        i.jsonObject = {}
        if (permissionCollectionVOS?.map(i => i?.permissionCollectionObjectVO?.nodePathWithType)?.includes(i?.nodePathWithType)) {
          const operations = permissionCollectionVOS?.filter((r: any) => {
            return r?.permissionCollectionObjectVO?.nodePathWithType === i?.nodePathWithType
          })?.map(i => i?.permissionCollectionObjectVO?.operations)?.flat()
          operations?.forEach((element) => {
            i.jsonObject[element] = true;
          });
        }
        return { ...i }
      })
    })
    // 存储批量操作
    const permissionCollectionVOSClone = _.cloneDeep(permissionCollectionVOS)
    permissionCollectionVOSClone?.map((i: any) => {
      const currentValue = permissionCollectionVOSClone?.filter((i: any) => i?.permissionCollectionObjectVO?.key === selectTreeItem?.key)
      const permissionObj = { [selectTreeItem?.key]: permissionCollectionVOS }

      canEdit && onUpdateConnectionObjectPermissions?.(permissionObj)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(permissionCollectionVOS), pagination])

  // 选择整列
  const handleItemCheck = (checked: boolean, item: any, dataSource: any[]) => {

    const operations = checked ? [item?.operation] : [];
    let curPermissionCollectionVOS = dataSource?.map((i: any) => {
      return {
        permissionCollectionObjectVO: {
          dataSourceType: i?.connectionType,
          nodePathWithType: i?.nodePathWithType,
          operations
        }
      }
    })

    let clonePermissionCollectionVOS = _.cloneDeep(permissionCollectionVOS);

    curPermissionCollectionVOS = curPermissionCollectionVOS.map((curItem: any) => {
      const { permissionCollectionObjectVO = {} } = curItem;
      const selectedItem = clonePermissionCollectionVOS.find(cloneItem => cloneItem?.permissionCollectionObjectVO?.nodePathWithType === permissionCollectionObjectVO?.nodePathWithType);

      if (_.isEmpty(selectedItem)) {
        return curItem
      } else {

        let operations = selectedItem?.permissionCollectionObjectVO?.operations || [];
        if (checked) {
          operations = operations.concat([item.operation])
        } else {

          operations = operations?.filter((o: string) => o !== item.operation)
        }

        return {
          permissionCollectionObjectVO: {
            ...permissionCollectionObjectVO,
            operations
          }
        }
      }
    })

    setPermissionCollectionVOS(curPermissionCollectionVOS)
  }

  // 权限修改
  const handleAuhtorizeChange = (e: any, operation: string, record: any) => {

    const checked = e.target.checked;
    const checkedItem = {
      permissionCollectionObjectVO: {
        dataSourceType: record?.connectionType,
        nodePathWithType: record?.nodePathWithType,
        operations: [operation]
      }
    }

    let clonePermissionCollectionVOS = _.cloneDeep(permissionCollectionVOS);

    const changedItem = clonePermissionCollectionVOS?.find(item => item?.permissionCollectionObjectVO?.nodePathWithType === record?.nodePathWithType);
    if (_.isEmpty(changedItem)) {
      clonePermissionCollectionVOS = clonePermissionCollectionVOS.concat([checkedItem])
    } else {
      clonePermissionCollectionVOS = clonePermissionCollectionVOS.map(item => {
        const { permissionCollectionObjectVO = {} } = item;
        if (permissionCollectionObjectVO?.nodePathWithType === record?.nodePathWithType) {

          let operations = permissionCollectionObjectVO?.operations || [];

          if (checked) {
            operations = operations?.concat([operation])
          } else {
            operations = operations.filter((o: any) => o !== operation)
          }
          return {
            permissionCollectionObjectVO: {
              ...permissionCollectionObjectVO,
              operations
            }
          }
        }
        return item
      })
    }

    setPermissionCollectionVOS(clonePermissionCollectionVOS)

  }

  // 页码修改
  const handlePageChange = (page: number, pageSize: number = 10) => {
    const dataSource = objectResult?.authObjectVoList?.slice((page - 1) * pageSize, page * pageSize)
    setPagination((p) => {
      return { ...p, current: page, pageSize };
    });
    setDataSource(dataSource)
  };

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys: selectedRowKeys,
    onSelectAll(selected, newSelectedRows) {

      const curRowKeys: any = newSelectedRows.map(row => row?.nodePathWithType);
      let cloneSelectedRows: any = _.cloneDeep(selectedRows);
      let cloneSelectedRowKeys = _.cloneDeep(selectedRowKeys);
      if (selected) {
        cloneSelectedRows = cloneSelectedRows.concat(newSelectedRows)

        cloneSelectedRowKeys = cloneSelectedRowKeys.concat(curRowKeys)
      } else {

        const curKeys = dataSource?.map((row: any) => row?.permissionId) || [];
        cloneSelectedRows = cloneSelectedRows.filter((cr: any) => cr && !curKeys.includes(cr?.nodePathWithType));
        cloneSelectedRowKeys = cloneSelectedRowKeys.filter(k => !curKeys.includes(k))

      }
      setSelectedRows(cloneSelectedRows.filter((i: any) => !_.isEmpty(i)));
      setSelectedRowKeys([...new Set(cloneSelectedRowKeys)]);
    },
    onSelect(item: any, selected) {

      let cloneSelectedRows: any = _.cloneDeep(selectedRows);
      let cloneSelectedRowKeys: any = _.cloneDeep(selectedRowKeys);
      if (selected) {

        cloneSelectedRows = cloneSelectedRows.concat([item])
        cloneSelectedRowKeys = cloneSelectedRowKeys.concat([item?.nodePathWithType])
      } else {
        cloneSelectedRows = cloneSelectedRows.filter((cr: any) => cr?.nodePathWithType !== item.nodePathWithType);
        cloneSelectedRowKeys = cloneSelectedRowKeys.filter((k: any) => k !== item.nodePathWithType)
      }
      setSelectedRows(cloneSelectedRows);
      setSelectedRowKeys(cloneSelectedRowKeys);
    },
  };

  return (
    <Spin spinning={loading}>
      <div className={styles.objectLevelWrap}>
        <div className={styles.title}>
          <span> {(isInShoppingCart || viewAppliedDetail) ? '已选择的权限' : '选择权限'} </span>
          {
            !viewAppliedDetail &&
            <>
              <Space>
                {!isInShoppingCart &&
                  <Button ghost type='primary' onClick={() => {
                    //批量操作前清空schema以上批量操作
                    if (!batchOperationState) {
                      onCancelBatchOperation?.();

                    }
                    setBatchOperationState(!batchOperationState);
                    setCanOperate(!canOperate)

                  }}>
                    {batchOperationState ? '取消批量' : '批量操作'}
                  </Button>
                }
                {

                  isInShoppingCart ?
                    < Button type="primary" icon={<RollbackOutlined />}
                      onClick={() => history.push('/mine_apply/search')}
                    >继续添加资源</Button>
                    :
                    <Badge count={1} size="small" className="mr10">
                      < Button type="primary" icon={<ShoppingCartOutlined />}>申请清单</Button>
                    </Badge>
                }

              </Space>
            </>
          }
        </div>
        <Table
          rowKey={(item) => item.nodePathWithType}
          columns={columns}
          dataSource={dataSource}
          rowSelection={batchOperationState ? rowSelection : undefined}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => handlePageChange(page, pageSize),
          }}
          scroll={{
            x: getScrollX(columns),
            y: `calc(100vh - 460px)`,
          }}
        />

        <div className={styles.batchFooter}>
          {
            batchOperationState && !permissionCollectionVOS?.length && selectedRowKeys?.length ?
              <Button
                icon={<PlusOutlined />} ghost type='primary'
                onClick={() => setIsShowAuthModal(true)}
              >
                选择权限
              </Button>
              : ''
          }
        </div>
      </div>
      {
        isShowAuthModal &&
        <FineGrainedPermissionModal
          permissions={authorizeList}
          onCancel={() => {
            setIsShowAuthModal(false)
          }}
          onOk={(values: any) => {
            const batchPermissions = selectedRowKeys?.map(key => ({
              permissionCollectionObjectVO: {
                nodePathWithType: key,
                dataSourceType: selectTreeItem?.dataSourceType,
                operations: values
              }
            }))
            setPermissionCollectionVOS(batchPermissions);
            //回到批量前状态
            setIsShowAuthModal(false);
            setSelectedRowKeys([]);
            setCanOperate(!canOperate);
          }}
        />
      }

    </Spin>
  );
};
export default SubSchemaLevelComponent;
