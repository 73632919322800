import React, { useEffect, useMemo, useState } from 'react'
import { SearchOutlined, RollbackOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { Input, message, Space } from 'antd'
import * as _ from 'lodash';
import classnames from 'classnames'
import { ResizableBox, ResizableProps } from 'react-resizable'
import {
  saveCartDrafte,
  deleteCartNode,
  startFlowAccessPermission
} from 'src/api'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { SimpleBreadcrumbs, Iconfont } from "src/components"
import {
  refreshOnRoot,
  setNewTreeData,
  fetchConnections,
  setLoadedKeys,
  setExpandedKeys,
  resetConnectionObjectPermissions,
  resetPermissionCollections
} from './applicationListSlice'
import TreeComponent from './TreeComponent'
import { SubmitApplicationModal } from './modal';
import { AboveSchemaLevelContent } from './AboveSchemaLevelContent';
import { SubSchemaLevelContent } from './SubSchemaLevelContent';
import styles from './index.module.scss'

const ResizableBoxProps: ResizableProps = {
  axis: 'x',
  width: 320,
  height: 0,
  minConstraints: [260, 0],
  maxConstraints: [620, 0],
}

const breadcrumbData = [
  { title: "流程" },
  {
    title: "我的申请",
    href: '/mine_apply',
  },
  {
    separator: '/',
    title: '申请清单',
  },
];

const ApplicationListPage = () => {

  const history = useHistory()
  const dispatch = useDispatch()

  const { newTreeData, selectedTreeNode, resourceTotal } = useSelector(state => state.applicationList);

  const [searchValue, setSearchValue] = useState<string>("");
  const [rightWrapWidth, setRightWrapWidth] = useState<string>("");
  const [permissionCollectionVOS, setPermissionCollectionVOS] = useState<any[]>([])
  const [submitModalState, setSubmitModalState] = useState(false);

  //删除节点
  const { run: runDeleteCartNode } = useRequest(deleteCartNode, {
    manual: true,
    onSuccess: () => {
      message.success('删除成功');
      setPermissionCollectionVOS([]);
      dispatch(resetConnectionObjectPermissions());
      dispatch(resetPermissionCollections());
      requestTreeData();
      dispatch(setLoadedKeys([]));
      dispatch(setExpandedKeys([]));
    }
  });
  //正式提交
  const { run: runStartFlow } = useRequest(startFlowAccessPermission, {
    manual: true,
    onSuccess(data) {
      message.success('提交成功');
      setSubmitModalState(false);
      dispatch(resetConnectionObjectPermissions());
      dispatch(resetPermissionCollections());
      history.push('/mine_apply')
    }
  })

  const requestTreeData = () => {
    dispatch(refreshOnRoot())
  }

  useEffect(() => {
    dispatch(fetchConnections())
    return () => {
      dispatch(setNewTreeData([]));
      dispatch(resetConnectionObjectPermissions());
      dispatch(resetPermissionCollections());
    }
  }, [dispatch])

  useEffect(() => {
    handleLeftWrapResize();
  }, []);

  const handleSearch = (e: any) => {
    const value = e.target.value;
    setSearchValue(value?.trim());
  };

  const handleUpdateParams = (params: any[], compare?: boolean) => {
    setPermissionCollectionVOS(params)
  }
  const handleUpdateBelowSchemaParams = (params: any[], compare?: boolean) => {
    setPermissionCollectionVOS((s: any[]) => {
      if (compare) {
        params.forEach((res: any) => {
          if (s.map(i => i?.permissionCollectionObjectVO?.nodePathWithType)?.includes(res?.permissionCollectionObjectVO?.nodePathWithType)) {
            s = s.map((i: any) => {
              if (i?.permissionCollectionObjectVO?.nodePathWithType === res?.permissionCollectionObjectVO?.nodePathWithType) {
                i = {
                  permissionCollectionObjectVO: {
                    ...i.permissionCollectionObjectVO,
                    operations: res?.permissionCollectionObjectVO?.operations || []
                  }

                }
              }
              return { ...i }
            })
          } else {
            s = [...s, res]
          }
        })
        return [...s]
      }
      return [...s, ...params]
    })
  }

  const handleLeftWrapResize = () => {
    // @ts-ignore
    const lWidth = document.getElementsByClassName("react-resizable")[0]?.style.width ||
      "320px";
    const width = `calc(100vw - 30px - ${lWidth} )`;
    setRightWrapWidth(width);
  };

  const renderResizeHandle = (
    <div className={styles.resizeHandle}>
      <Iconfont type="icon-handle-8"></Iconfont>
    </div>
  );

  return (
    <div>
      <div className={styles.groupAuthorizationWrap}>
        {/* 数据库管理/自动授权   顶部模块 */}
        <div style={{ padding: "0 10px" }}>
          <SimpleBreadcrumbs items={breadcrumbData} />
        </div>
        <div className={styles.content}>
          <ResizableBox
            className={styles.resizableBox}
            handle={renderResizeHandle}
            onResize={handleLeftWrapResize}
            {...ResizableBoxProps}
          >
            <div className={styles.leftWrap}>

              {/* 搜索框 */}
              <Input
                className={classnames(styles.searchBtn, styles.mb10)}
                prefix={<SearchOutlined />}
                placeholder="请输入连接名/IP地址"
                allowClear
                onChange={handleSearch}
                value={searchValue}
              />

              {/* 树形目录 */}
              <TreeComponent
                searchValue={searchValue}
                onDeleteNodeItem={(params: any) => runDeleteCartNode({
                  ...params
                })}
              />
            </div>
          </ResizableBox>
          <div className={styles.rightWrap} style={{ width: rightWrapWidth }}>
            <div className={styles.operationContent}>
              <div className={styles.contentDetail}>
                {
                  (['datasource', 'connection', 'database', 'schema', 'oracleUser'].includes(selectedTreeNode?.nodeType) || !selectedTreeNode) &&
                  <AboveSchemaLevelContent
                    selectTreeItem={selectedTreeNode}
                    permissionCollectionVOS={permissionCollectionVOS}
                    updatePermissionCollectionVOS={handleUpdateParams}
                  />
                }
                {/* 对象级权限设置 */}
                {
                  selectedTreeNode?.nodeType?.toUpperCase()?.endsWith("GROUP") &&
                  <SubSchemaLevelContent
                    selectTreeItem={selectedTreeNode}
                    handleUpdateBelowSchemaParams={handleUpdateBelowSchemaParams}
                  />
                }
              </div>
              <div className={styles.addRequestFooter}>
                <Space>
                  <span
                    className={classnames(styles.addRequestBtn, {
                      [styles.hightlightBtn]: newTreeData?.length
                    })}
                    onClick={() => {
                      if (!newTreeData?.length) return;
                      const tipItem = permissionCollectionVOS?.find(item => !_.isEmpty(item?.userToolVo) && _.isEmpty(item?.permissionSdtUserAddVo));

                      if (!_.isEmpty(tipItem)) {
                        return message.warning(` 请选择${tipItem?.userToolVo?.nodeName}的权限等级`)
                      }

                      setSubmitModalState(true)
                    }}
                  >
                    提交申请
                  </span>
                </Space>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        submitModalState &&
        <SubmitApplicationModal
          onCancel={() => setSubmitModalState(false)}
          onOk={(params: any) => {

            saveCartDrafte({
              ...params,
              ...(permissionCollectionVOS?.length ? { permissionCollectionVOS } : {})
            }).then(() => {
              runStartFlow({ ...params, priGranType: "FAT", flowType: "dataManipulation" })
            })
          }}
        />
      }
    </div>
  );
}


export default ApplicationListPage
