import React from 'react';
import { useSelector } from 'src/hook';
import { Form } from 'antd';
import {
  SyncElementTreeSelect,
  FormItemApplicant,
  FormItemEffectiveTime,
  FormItemReason,
  FormItemTitle,
} from '../flowFormItems';
import { FormFieldElementEntity, FormFieldsHighRisk, FormFieldsDesensitizedResource, FlowFormTrackingContext } from 'src/types';
import { FlowModalForm } from './FlowModalForm';
import { launchFlowApply } from 'src/api';

export const ModalFormSensitiveResource = React.memo(() => {
  const { userId: applyUserId, email } = useSelector(
    (state) => state.login.userInfo
  );
  const { trackingContext }: {trackingContext?: any} = useSelector((state) => state.flowForms);
  
  const flowType = 'desensitizedResource';

  const [form] = Form.useForm();

  return (
    <FlowModalForm<FormFieldsHighRisk>
      type={flowType}
      name={`flow-${flowType}`}
      form={form}
      request={async (values) => {
        if (!applyUserId) return;

        const {timeType, approveUserId, applyReason, elements, time, title } = values;
     
        const nodePathWithTypeList = elements.map(
          //@ts-ignore
          ({ value }) => value
        );

        let parmas
        if (timeType === 'custom') {
          parmas ={
            applyUserId,
            email,
            remark: applyReason,
            applyReason,
            flowType,
            connectionId: trackingContext?.fields?.connectionId,
            dataSourceType: trackingContext?.fields?.connectionType,
            nodePathWithTypeList,
            nodeType: trackingContext?.fields?.nodeType,
            operationList: ['CQ_DESENSITIZED_RESOURCE_ACCESS'],
            firstApproveUserId: approveUserId,
            title: title,
            priGranType: 'desensitizedResource',
            useCartDataThinTiling: false,
            beginTime: time?.[0].format('YYYY-MM-DD HH:mm:ss'),
            endTime: time?.[1].format('YYYY-MM-DD HH:mm:ss'),
          }
        } else {
          parmas ={
            applyUserId,
          email,
          remark: applyReason,
          applyReason,
          flowType,
          connectionId: trackingContext?.fields?.connectionId,
          dataSourceType: trackingContext?.fields?.connectionType,
          nodePathWithTypeList,
          nodeType: trackingContext?.fields?.nodeType,
          operationList: ['CQ_DESENSITIZED_RESOURCE_ACCESS'],
          firstApproveUserId: approveUserId,
          title: title,
          priGranType: 'desensitizedResource',
          useCartDataThinTiling: false,
          
          }
         }
        return launchFlowApply(parmas);
      }}
    >
      <FormItemApplicant />
      <FormItemTitle />
      <Form.Item
        label="数据库元素"
        name="elements"
        required
        shouldUpdate
        rules={[
          {
            validator: (_, value?: FormFieldElementEntity[]) => {
              if (!value?.length) {
                return Promise.reject('请选择数据库元素');
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <SyncElementTreeSelect
          resourceFields={trackingContext?.fields as FormFieldsDesensitizedResource}
        />
      </Form.Item>
      <FormItemEffectiveTime />
      <FormItemReason />
    </FlowModalForm>
  );
});
