import React from 'react'
import { FormInstance } from 'antd/lib/form'
import { ApplyItem } from 'src/api';

interface IFormContext {
  form?: FormInstance
  currentContextValue: ApplyItem | any
  setCurrentContextValue: (params: any) => void;
  setCurrentStep?:  (updateFunction: (prevStep: number) => number) => void;
}

export const FormContext = React.createContext<IFormContext>({
  form: undefined,
  currentContextValue: {},
  setCurrentContextValue: () => {},
  setCurrentStep: () => {}
})
