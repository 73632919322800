import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/store'
import { getAllUnReadMes, getUnReadMes,getUnReadMesNum } from 'src/api'

interface Message {
  id: number
  sender: string
  userId: string
  message: string
  msgId: number
  msgType: string
  msgSourceType: string
  createdAt: string
  updatedAt: string
  status: string
}

export interface InitialState {
  userId: string
  num:number
  messages: Message[]
  curKey: string[]
}

export interface UserMesState {
  userId: string
  num:number
  messages: Message[]
}

const initialState: InitialState = {
  userId: '',
  num:0,
  messages: [],
  curKey: [],
}

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<UserMesState>) => {
      const { userId, messages, num } = action.payload
      state.userId = userId
      state.messages = messages
      state.num = num
    },
    setCurKey: (state, action: PayloadAction<string[]>) => {
      state.curKey = action.payload
    },
  },
})

export const messageReducer = messageSlice.reducer

export const { setMessages, setCurKey } = messageSlice.actions


export const getMessages = (userId: string): AppThunk => (dispatch) => {
  // return Promise.all([getUnReadMes(userId), getUnReadMesNum(userId)]).then((res) => {
  return Promise.all([getAllUnReadMes('', 1, 10)]).then((res) => {
    const PayloadData = {
      userId,
      messages:res[0]?.data,
      num:res[0]?.total,
    } 
    dispatch(setMessages(PayloadData))
  })
}