import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { useSelector, useDispatch, useRequest } from 'src/hook'
import { Form, Input, message, Radio, TreeSelect, Select, Tooltip, Button } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { Iconfont, UIModal } from 'src/components'
import { fetchDeptUserList, fetchOrgTreeData } from '../organizationSlice'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import {
  addUser,
  editUser,
  OrgTreeNode,
  posts_get_api,
  getIPWhiteSwitch,
  getUserParametersSearch,
  UserParameterEditType,
  UserParameterValue,
  getRoles,
  getSysPasswordPolicy,
  getUserLoginSetting
} from 'src/api'
import { FormLayout } from 'src/constants'
import { passwordSpecialCharacterValidator, passwordValidator, userIdValidator } from 'src/util/nameValidator'
import Icon, { EyeInvisibleOutlined, EyeOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import UserTreeSelect from './UserTreeSelect'

const { TextArea } = Input

const traversalOrgTree = (treeData: OrgTreeNode[]): any => {
  return treeData
    .filter(({ orgType }) => orgType !== 'GROUP')
    .map((treeNode) => {
      const { title, name, orgType, children, id } = treeNode
      return {
        title,
        value: id,
        selectable: orgType === 'DEPT',
        icon:
          orgType === 'DEPT' ? (
            <Iconfont type="icon-org-department" />
          ) : (
            <Iconfont type="icon-org-company" />
          ),
        children: traversalOrgTree(children || []),
      }
    })
}

interface AddUserModalProps {
  form: FormInstance<any>
  mode?: 'add' | 'edit' | 'editAndAdd'
  createFrom: string
  formParams?: {
    userId: string | number
    userName: string | undefined
    userGender: string | undefined
    telephone: string | undefined
    email: string | undefined
    departmentName: string | undefined
    jobNumber: string | undefined
    postId: string | undefined
    companyId: string | undefined
    departmentId: string | number | undefined
  }
}

/**
 *
 * @param ipString ip 字符串，多个ip地址要用分号隔开，最后一个分号可以省略
 * @returns ip 数组
 */
const ipFormatArray = (ipString: string) => {
  if (!ipString) return
  let tempString = ipString
  tempString.trim()

  if (tempString.includes(';')) {
    const tempArray = tempString.split(';')
    return tempArray
      .map((arr) => {
        return arr.replace(/\n/g, '').trim()
      })
      .filter((s) => s && s.trim())
  } else {
    return [tempString.trim()]
  }
}

const IpRegExp =
  /^(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])$/

const IpValidator = (_: any, value: string) => {
  if (!value) {
    return Promise.reject(new Error('请输入 IP 地址!'))
  } else {
    const ipArray = ipFormatArray(value)
    const passed = ipArray?.every((ip) => ip.match(IpRegExp))

    if (passed) {
      return Promise.resolve()
    } else {
      return Promise.reject(new Error('请输入正确 IP 地址格式!'))
    }
  }
}

const TextTitle = () => {
  return (
    <div style={{ color: 'black' }}>
      <div>示例：</div>
      <div>127.0.0.1;</div>
      <div>127.0.0.2;</div>
    </div>
  )
}
const IpTooltip = () => {
  return (
    <Tooltip title={<TextTitle />} color="white" placement="bottom">
      <span>
        允许登录的IP <QuestionCircleOutlined />
      </span>
    </Tooltip>
  )
}

export const AddUserModal: React.FC<AddUserModalProps> = ({
  form,
  mode = 'add',
  createFrom,
}) => {
  const visible = useSelector((state) => state.modal.AddUser)
  const [loading, setLoading] = useState<boolean>(false)
  const [rolesOption, setRolesOption] = useState<any>();
  const orgTree = useSelector((state) => state.organization.orgTreeData)
  const { organizationPageSize, organizationSearch } = useSelector(
    (state) => state.organization,
  )
  const treeSelectData = useMemo(() => {
    return traversalOrgTree(orgTree)
  }, [orgTree])

  const dept = useSelector((state) => state.organization.selectedNode)

  const dispatch = useDispatch()

  const [passwordVisible, setPasswordVisible] = useState(false); // 密码文本可见性的状态
  const [defaultPassword, setDefaultPassword] = useState(''); // 密码策略中设置的密码
  const togglePasswordVisibility = () => {
    setPasswordVisible((prevVisible) => !prevVisible);
  };

  const [parameterValues, setParameterValues] = useState<any>([]); // 用户参数

  /** 是否开启强制短信认证  true开启*/
  const { data: smsLoginSetting, run: runGetSmsLoginSetting } = useRequest(getUserLoginSetting, { manual: true})

  useEffect(() => {
    if (visible) {
      runGetSmsLoginSetting();
      parameterValues?.map((item: any) => {
        if (item?.parameter) {
          form.setFieldsValue({ 
            dynamicFieldName: {
              [item?.parameter?.tagName]: (mode !== "add" ? item.value : null)
            }
          });
        }
      })
    }
  }, [visible, parameterValues]);

  const getModalTitle = useCallback(() => {
    switch (mode) {
      case 'add':
        return '新增用户'
      case 'edit':
        return '编辑用户信息'
      case 'editAndAdd':
        return '编辑并新增用户信息'
    }
  }, [mode])

  /* 岗位列表 */
  const { data: positionList } = useRequest(() =>
    posts_get_api({ pageNum: 0, pageSize: 9999, postName: '' }),
  )

  const positionSelectList = positionList?.content?.map((it) => ({
    label: it.name,
    value: it.id,
  }))

  /** 是否开启IP白名单 */
  const { data, run: getIPSwitch } = useRequest(getIPWhiteSwitch, {
    manual: true,
  })

  useEffect(() => {
    if (mode === "add") {
      form.resetFields();
      getSysPasswordPolicy().then((res) => {
        setDefaultPassword(res?.defaultPassword)
      })
    }
  }, [mode])

  useEffect(() => {
    getIPSwitch()
  }, [getIPSwitch])

  /** 用户参数 */
  const { data: filterData, run: getUserParameterRun } = useRequest(() => getUserParametersSearch({ parameterName: '', pageNum: 0, pageSize: 999 }), {
    onSuccess(data) {
      !!data && addParameterValuesToForm(data?.content)
    }
  })
  const addParameterValuesToForm = (arr: any[]) => {
    const newArr = arr.filter((item) => {
      if (item?.accessMode !== 'AUTO') {
        return item
      }
    })
    const parameterValuesArr = newArr?.map((a: UserParameterEditType) => {
      const userValue: UserParameterValue = {
        parameter: a,
        value: ''
      };
      return userValue
    })
    setParameterValues(parameterValuesArr);
    form.setFieldsValue({
      "parameterValues": parameterValuesArr
    })
  }

  useEffect(() => {
    if (visible) {
      getUserParameterRun()
      getRoles({name: '', type: 'SYSTEM_ROLE'}).then(res=>{
        interface Options {
          value: string | number;
          label: string | number;
        }
        let options: Options[] = [];
        for (const item of res) {
          options.push({value: item.roleId, label: item.roleName});
        }
        setRolesOption(options);
      })
      setPasswordVisible(false)
    }
  }, [visible, mode])

  // 编辑/克隆，systemRoles返回的是字符串数组，需要进行匹配获取 rolesId
  const valueRolesStrToId = ( rolesIdArr: any[] ) => {
    let editRolesIdArr: any[] = []
    let uniqueEditRolesIdArr: any[] = []
    for ( const item of rolesIdArr ) {
      if (typeof item === 'number') {
        editRolesIdArr.push(item)
      } else {
        for ( const optionItem of rolesOption) {
          if ( item === optionItem.label) {
            editRolesIdArr[editRolesIdArr.length] = optionItem.value
          }
        }
      }
    }
    uniqueEditRolesIdArr = editRolesIdArr.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
    return uniqueEditRolesIdArr
  }
  
  const handleOkAndAdd = (hideAddUserModal: boolean) => {
    save(hideAddUserModal);
  }
  const handleOk = (hideAddUserModal: boolean) => {
    save(hideAddUserModal);
  }
  const save = (hideAddUserModal: boolean) => {
    form
      .validateFields()
      .then(async (values) => {
        // 修改ip地址格式为数组
        const { permitIps, dynamicFieldName } = values;
        const ipArray = ipFormatArray(permitIps);
        const formatValues = Object.assign(values);
        delete formatValues?.confirm;
        formatValues.permitIps = ipArray;
        let parameterValues: UserParameterValue[] = [];
        parameterValues?.forEach((item: any) => {
          parameterValues.push({
            id: item?.id,
            parameter: item.parameter,
            value:
              dynamicFieldName && dynamicFieldName[item?.parameter?.tagName]
                ? dynamicFieldName[item?.parameter?.tagName]
                : "",
          });
        });
        delete values["dynamicFieldName"];

        setLoading(true);
        try {
          switch (mode) {
            case "editAndAdd":
              values.rolesId = valueRolesStrToId(values.rolesId)
              await addUser({
                ...values,
                parameterValues,
              });
              message.success("添加成功");
              dispatch(fetchOrgTreeData());
              break;
            case "add":
              await addUser({
                ...values,
                parameterValues,
              });

              message.success("添加成功");
              dispatch(fetchOrgTreeData());
              break;
            case "edit":
              values.createFrom = createFrom;
              values.rolesId = valueRolesStrToId(values.rolesId)
              await editUser({
                ...values,
                parameterValues,
              });
              message.success("编辑成功");
              break;
            default:
              break;
          }
          if (dept) {
            dispatch(
              fetchDeptUserList({
                dept,
                search: organizationSearch,
                pageNum: 0,
                pageSize: organizationPageSize,
              })
            );
          }
          if(["add"].includes(mode)) {
            if (hideAddUserModal) {
              dispatch(hideModal("AddUser"));
            } else {
              // 保存并继续添加，不清空form表单的数据
              // form.resetFields();
            }
          } else {
            dispatch(hideModal("AddUser"));
          }
        } finally {
          setLoading(false);
        }
      })
      .catch(() => {});
  }
  const renderPasswordViewer = useMemo(() => {
    return (
      <div>
        {passwordVisible ? defaultPassword : '*'.repeat(defaultPassword.length)}
        {passwordVisible ? (
          <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
        ) : (
          <EyeOutlined onClick={togglePasswordVisibility} />
        )}
      </div>
    )
  }, [defaultPassword, passwordVisible])

  const filterTreeNode = (inputValue: any, treeNode: any) => {
    // 使用title字段进行模糊搜索
    return treeNode.title.indexOf(inputValue) > -1;
  }

  const renderParameter = useMemo(() => {
    parameterValues?
    parameterValues?.map((item: UserParameterValue, index: number) => {
      return (
        <Form.Item
          key={item?.parameter?.tagName}
          name={[
            "dynamicFieldName",
            item?.parameter?.tagName as string,
          ]}
          label={item?.parameter?.parameterName}
          rules={[
            {
              required: item?.parameter?.needed,
              message: `请输入${item?.parameter?.parameterName}名称`,
            },
            { min: 2, max: 20 },
          ]}
        >
          <Input placeholder="请输入"/>
        </Form.Item>
      );
    })
    : <></>
  }, [parameterValues])

  return (
    <UIModal
      visible={visible}
      key={"key"+visible}
      title={getModalTitle()}
      onCancel={() => dispatch(hideModal("AddUser"))}
      afterClose={() => {
        form.resetFields();
        // if (mode === 'add') {
        getUserParameterRun();
        // }
      }}
      confirmLoading={loading}
      forceRender
      footer={
        ["add"].includes(mode) ?
        [
          <Button key="save" type="primary" onClick={()=>handleOk(true)}>保存</Button>,
          <Button key="saveAndAdd" type="primary" onClick={()=>handleOkAndAdd(false)}>保存并继续添加</Button>,
          <Button key="cancel" onClick={() => dispatch(hideModal("AddUser"))}>取消</Button>
        ]
        :[
          <Button key="save" type="primary" onClick={()=>handleOk(true)}>保存</Button>,
          <Button key="cancel" onClick={() => dispatch(hideModal("AddUser"))}>取消</Button>
        ]
      }
    >
      <Form
        form={form}
        {...FormLayout}
        // initialValues={form}
      >
        <Form.Item
          name="userId"
          label="登录账号"
          rules={[
            {
              required: true,
              validator: userIdValidator,
            },
          ]}
        >
          <Input placeholder="请输入登录账号" disabled={mode === "edit"} />
        </Form.Item>
        {[""].includes(mode) && (
          <>
            <Form.Item
              name="password"
              label="密码"
              rules={[{ required: true, validator: passwordValidator }, { validator: passwordSpecialCharacterValidator }]}
            >
              <Input.Password placeholder="请输入6位数以上的密码" />
            </Form.Item>
            <Form.Item
              name="confirm"
              label="确认密码"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "请确认密码",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("两次输入密码不一致");
                  },
                }),
              ]}
            >
              <Input.Password placeholder="请确认密码" />
            </Form.Item>
          </>
        )}
        <Form.Item
          name="userName"
          label="用户名"
          rules={[{ required: true, message: "请输入用户名" }]}
        >
          <Input placeholder="请输入用户名" />
        </Form.Item>
        <Form.Item name="userGender" label="用户性别" initialValue="FEMALE">
          <Radio.Group>
            <Radio value="MALE">男</Radio>
            <Radio value="FEMALE">女</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="departmentId"
          label="所属部门"
          valuePropName="value"
          rules={[{ required: true, message: "请选择所属部门" }]}
        >
          <TreeSelect
            placeholder="请选择所属部门"
            treeData={treeSelectData}
            treeDefaultExpandAll
            treeIcon={true}
            showSearch
            allowClear
            filterTreeNode={filterTreeNode}
          />
        </Form.Item>
        <Form.Item name="jobNumber" label="工号">
          <Input placeholder="请填写工号" />
        </Form.Item>
        {["add", "edit", "editAndAdd"].includes(mode) && (
          <Form.Item
            name="rolesId"
            label="系统角色"
            valuePropName="value"
            rules={[
              {
                required: (
                  !!filterData? filterData?.content.find((item: any) => item?.tagName==='SystemRole')?.needed : false
                )
              }
            ]}
          >
            <Select
              mode="multiple"
              placeholder={ 
                `默认为${!!filterData? filterData?.content.find((item: any) => item?.tagName==='SystemRole')?.defaultValue || `普通用户` : `普通用户`}`
              }
              options={rolesOption}
              getPopupContainer={(triggerNode: { parentNode: any }) => triggerNode.parentNode || document.body}
            />
          </Form.Item>
        )}
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => currentValues.rolesId !== prevValues.rolesId}
        >
          {({ getFieldValue }) =>
          // 角色里有审计员，展示审计范围，审计员id为2
            getFieldValue('rolesId')?.includes(2) ? (
              <Form.Item
                preserve={false}
                initialValue="all"
                label={
                  <>
                    审计范围&nbsp;
                    <Tooltip title="即选择此审计员可查看的数据范围，默认可查看系统中所有用户的操作行为">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </>
                }
                required
                name='rangeType'
                rules={[{ required: true, message: "请选择审计范围" }]}
              >
                <Radio.Group>
                  <Radio value="all">全局用户</Radio>
                  <Radio value="custom">自定义</Radio>
                </Radio.Group>
              </Form.Item>
            ) : null
          }
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => currentValues.rangeType !== prevValues.rangeType || currentValues.rolesId !== prevValues.rolesId}
        >
          {({ getFieldValue }) =>
          // 是自定义并且角色包含审计员
            getFieldValue('rangeType') === 'custom' && getFieldValue('rolesId')?.includes(2) ? (
              <Form.Item
                preserve={false}
                initialValue={undefined}
                label="选择审计范围"
                required
                name='auditRange'
                rules={[{ required: true, message: "请选择用户" }]}
              >
                <UserTreeSelect
                  treeCheckable
                  showCheckedStrategy={TreeSelect.SHOW_CHILD}
                  treeDefaultExpandAll
                />
              </Form.Item>
            ) : null
          }
        </Form.Item>
        <Form.Item
          name="telephone"
          label="手机号码"
          rules={[
            {
              required: (
                !!filterData ? filterData?.content.find((item: any) => item?.tagName==='Phone')?.needed : false
              ),
              validator: (_, value) => {
                  if ((!!filterData ? filterData?.content.find((item: any) => item?.tagName==='Phone')?.needed : false) && !value) {
                    return Promise.reject("请输入手机号码");
                  }
                  if (!!value && !/^1[3-9]\d{9}$/.test(value as string)) {
                    return Promise.reject("手机号码格式错误");
                  }
                  return Promise.resolve();
              },
            },
          ]}
        >
          <Input type="tel" placeholder="请填写手机号码" />
        </Form.Item>
     
        {data && (
          <Form.Item
            name="permitIps"
            label={<IpTooltip />}
          >
            <TextArea rows={3} />
          </Form.Item>
        )}
        {renderParameter}
        {["add", "editAndAdd"].includes(mode) && (
          <Form.Item
            label={"用户密码"}
            name={"userPassword"}
            key={"defaultPassword"}
          >
            {renderPasswordViewer}
          </Form.Item>
        )}
      </Form>
    </UIModal>
  );
}
