import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { Layout, Breadcrumb, Tabs, Card, Checkbox, message, Switch, Button, Dropdown, Menu, Popconfirm, Tag, Tooltip, Form, Divider } from 'antd'
import { setSysRoles, setTabsKeyRoleId, setViewingRoles } from './sysRolesSlice'
import { getSysRoles, grantPermsToRole, viewingRolePermissions, getRoleAuthStatus, setRoleAuthStatus, delRole, saveCustomRole, editPermission, getRolesTemplate } from 'src/api'
import { Iconfont } from 'src/components'
import { EllipsisOutlined, LockOutlined, PlusOutlined, QuestionCircleOutlined, UserAddOutlined } from '@ant-design/icons'
import { RoleRecordContext } from 'src/features/perm/contexts/RoleRecordContext'
import { ModalSysBindUser } from 'src/features/perm/modals'
import { showModal } from 'src/store/extraSlice/modalVisibleSlice'
import './sysRoles.scss'
import 'src/styles/layout.scss'
import classnames from 'classnames'
import PermissionTooltip from 'src/components/PermissionTooltip/PermissionTooltip'
import StatusCard from './StatusCard'
import { AddRoleModal } from './modals/AddRoleModal'
import { delArrParams, getCurrentModulePermissionByUrl } from 'src/util/utils'
import cloneDeep from 'lodash/cloneDeep'

const { Header, Content, Sider, Footer } = Layout

export const SysRolesPage = () => {
  const dispatch = useDispatch()
  const [recordContext, setRecordContext] = useState<{
    mode: 'add' | 'edit'
    record?: any
  }>({ mode: 'add' })
  const { rolesObj, viewRoles } = useSelector(
    (state) => state.sysrolesState,
  )

  const [activeKey, setActiveKey] = useState<any>('')
  // const [authorization, setAuthorization] = useState<any>('pending')
  const [authSwitch, setAuthSwitch] = useState<boolean>(false)
  // 编辑角色Modal  add/edit
  const [roleModalType, setRoleModalType] = useState<string>("edit")
  const [isEdit, setIsEdit] = useState<boolean>(false)
  // 新增角色的角色信息
  const [newRoleInfo, setNewRoleInfo] = useState<{
    roleName: string, roleTemplate: {value: string, label: string}, description: string, roleId: string | number
  }>()
  // 新增/编辑权限集 标记是新增 or 编辑
  const [operationType, setOperationType] = useState<string>("")

  // 判断 该登录权限下,该模块 只读/可编辑
  const { permissionList , userInfo} = useSelector((state) => state?.login)
  const [permissionlist, setPermissionlist] = useState<{isOnlyRead: boolean, roleNameList: any}>()
  // 最初的用户权限 checkboxGroup options -- 用于新增的重置功能(重置从保留模板必选权限,改为全部清空)
  const [initViewRolesOptions, setInitViewRolesOptions] = useState<any>([])
  // new用户权限 checkboxGroup options
  const [newViewRolesOptions, setNewViewRolesOptions] = useState<any>([])
  // 用户权限 已选 checkboxGroup value 权限列表
  const [editRolesValues, setEditRolesValues] = useState<any>([])
  /**
   * 二级目录
   */
  // set未授权
  const [notAuthIds, setNotAuthIds] = useState<any[]>([])
  // set授权
  const [authIds, setAuthIds] = useState<any[]>([])

  //模块权限查询
  const modulePermissionObj: { isOnlyRead: boolean; roleNameList: string[]} = useMemo(() => {
    return getCurrentModulePermissionByUrl(permissionList, 'SYSTEM_MANAGEMENT')
  },[JSON.stringify(permissionList)])

  useEffect(() => {
    const pathName = (window.location.pathname.split("/")[1].toUpperCase() as keyof typeof permissionList)
    setPermissionlist(permissionList?.SYSTEM_MANAGEMENT?.[pathName])
  }, []);

  // 获取 角色列表及其详情
  const {
    data: roles,
    loading: loadingRoles,
    refresh: refreshRoles,
  } = useRequest((name: string = '') => getSysRoles({ type: 'SYSTEM_ROLE', name }), {
    // manual: true, 
    onSuccess: (data) => {
      dispatch(setSysRoles(data))
      if (activeKey) {
        !!data && dispatch(setTabsKeyRoleId(activeKey))
        activeKey && getRoles(activeKey)
      } else {
        if (!!data && operationType === "add") {
          const newRole: any = data.reduce((prev, current) => {
            return (current?.roleId > prev?.roleId) ? current : prev;
          });
          // 新增角色，activeKey=新的角色.id
          setActiveKey(newRole?.roleId)
          dispatch(setTabsKeyRoleId(newRole?.roleId))
          getRoles(newRole?.roleId)
          setOperationType('')
        } else {
          !!data && dispatch(setTabsKeyRoleId(data[0]?.roleId))
          !!data && getRoles(data[0]?.roleId)
          // 第一个用户的tabKey
          !!data && setActiveKey(data[0]?.roleId)
        }
      }
    },
  })

  /** 查看角色权限 */ 
  const { loading: getRolesLoading, run: getRoles, refresh: refreshGetRoles } = useRequest(viewingRolePermissions, {
    manual: true,
    onSuccess(data) {
      setEditRolesValues([])

      dispatch(setViewingRoles(data))
      // setAuthorization('pending')
    }
  })

  // 查看角色模板对应的权限
  const { run: runGetRolesTemplate} = useRequest(getRolesTemplate, {
    manual: true,
    onSuccess: (data) => {
      setEditRolesValues([])
      dispatch(setViewingRoles(data))
    }
  })

  // 删除自定义角色
  const { run: runDelRole } = useRequest(delRole, {
    manual: true,
    onSuccess: (res) => {
      message.success("删除成功")
      setActiveKey('')
      refreshRoles()
    },
    onError: (err) => {
      console.log('删除自定义角色失败 :>> ', err);
    }
  })

  // 新增角色
  const { run: runSaveCustomRole} = useRequest(saveCustomRole, {
    manual: true,
    onSuccess: () => {
      message.success("新增成功")
      setIsEdit(false)
      refreshRoles()
    },
    onError: (err) => {
      console.log('新增角色失败 :>> ', err);
    }
  })

  // 编辑权限
  const { run: runEditPermission} = useRequest(editPermission, {
    manual: true,
    onSuccess: () => {
      message.success("编辑成功")
      refreshGetRoles()
    }
  })

  // const { run: grantPerms } = useRequest(grantPermsToRole, {
  //   manual: true,
  //   onSuccess: () => {
  //     // if (authorization === 'success') {
  //     //   message.success('授权成功')
  //     // }
  //     // if (authorization === 'cancel') {
  //     //   message.success('取消授权')
  //     // }
  //     refreshGetRoles()
  //   },
  // })

  useEffect(()=>{
    // 审计员角色才有授权模式开关
    if(rolesObj?.type === 'AUDIT_ROLE' && !['add', 'edit'].includes(operationType)){
      queryAuthStatus()
    }
    // eslint-disable-next-line
  }, [rolesObj, operationType])

  useEffect(() => {
    // 新增角色, 标记第一次渲染 权限集列表, 渲染后将标记false
    if (operationType === "add") {
      if (operationType === "add" && newRoleInfo?.roleId) {
        Promise.all([
          setActiveKey(''),
          runGetRolesTemplate({templateRole: newRoleInfo?.roleTemplate?.value}),
          setIsEdit(false)
        ]).then(() => {})
      }
    }
  }, [operationType])


  useEffect(() => {
    // 初次, 生成option、checkbox对应的value值
    getViewRolesOptions(viewRoles)
  }, [viewRoles])

  // 初次渲染, 生成option、checkbox对应的value值
  const getViewRolesOptions = (data: any) => {
    let editRolesValuesTmp: any = {}
    let newViewRolesOptionsTmp: any = {}
    let initViewRolesOptionsTmp: any = {}
    let initEditRolesValuesTmp: any = {}
    let authIdsTmp: any = []
    data.map((role: any) => {
      newViewRolesOptionsTmp[role?.menuType] = []
      editRolesValuesTmp[role?.menuType] = []
      initViewRolesOptionsTmp[role?.menuType] = []
      initEditRolesValuesTmp[role?.menuType] = []
      role?.items.map((item: any) => {
        const option = {
          id: item?.id,
          label: <span className="custom-checkbox-label">{item?.name}</span>,
          name: item?.name,
          value: item?.object,
          required: item?.required,
          indeterminate: ((item?.accessMode.includes("READ")) ? true : false),
          accessMode: item?.accessMode
        }
        newViewRolesOptionsTmp[role?.menuType].push(cloneDeep(option))
        initViewRolesOptionsTmp[role?.menuType].push(cloneDeep(option))
        if (['READ_AUTHED', 'WRITE_AUTHED'].includes(item?.accessMode)) {
          if (!['1', '2', '3', '4', '5'].includes(activeKey.toString())) {
            // 自定义用户，编辑权限时，若未选择权限则无法保存，因此需将权限进行保存
            authIdsTmp.push(item?.id)
          }
          editRolesValuesTmp[role?.menuType].push(item?.object)
          initEditRolesValuesTmp[role?.menuType].push(item?.object)
        }
      })
    })

    if (operationType === "add") {
      // 新增角色，渲染权限列表
      initSetList(false, newViewRolesOptionsTmp)
    }
    Promise.all([setInitViewRolesOptions(initViewRolesOptionsTmp)])
    .then(() => {
      setNewViewRolesOptions(newViewRolesOptionsTmp);
      setEditRolesValues(editRolesValuesTmp);
      setAuthIds(authIdsTmp);
    })
    .catch(error => console.log(error));
  }

  /**
   * 新增角色-初次渲染 更新权限列表的 checkbox的option与value
   * @param isReset: 是否是重置
   */
  const initSetList = (isReset: boolean, data?: any) => {
    let newViewRolesOptionsTmp: any = {}
    let editRolesValuesTmp: any = {}
    let authIdsTmp: any = []
    const arr = isReset ? cloneDeep(initViewRolesOptions) : data

    Object.keys(arr).map((optionKey: any) => {
      newViewRolesOptionsTmp[optionKey] = []
      editRolesValuesTmp[optionKey] = []
      arr[optionKey].map((item: any) => {
        setIsEdit(true)
        // 新增角色,重置-清空全部权限(不勾选所选模板角色的必选权限)
        if (isReset) {
          if (item.accessMode.includes('READ')) {
            item.accessMode = 'READ_NOT_AUTH'
          } else if (item.accessMode.includes('WRITE')) {
            item.accessMode = 'WRITE_NOT_AUTH'
          }
        } else {
          if (!item?.required) {
            if (item.accessMode.includes('READ')) {
              item.accessMode = 'READ_NOT_AUTH'
            } else if (item.accessMode.includes('WRITE')) {
              item.accessMode = 'WRITE_NOT_AUTH'
            }
          } else {
            authIdsTmp.push(item?.id)
          }
        }
        // 显示选择的模板必选的权限，自定义用户可以编辑所有权限,
        item.required = false

        newViewRolesOptionsTmp[optionKey].push({
          id: item?.id,
          label: <span className="custom-checkbox-label">{item?.name}</span>,
          name: item?.name,
          value: item?.value,
          required: item?.required,
          indeterminate: ((item?.accessMode.includes("READ")) ? true : false),
          accessMode: item?.accessMode
        })
        if (['READ_AUTHED', 'WRITE_AUTHED'].includes(item?.accessMode)) {
          editRolesValuesTmp[optionKey].push(item?.value)
        }
      })
    })
    setAuthIds(authIdsTmp)
    setNewViewRolesOptions(newViewRolesOptionsTmp)
    setEditRolesValues(editRolesValuesTmp)
  }

  /**
   * 编辑一级目录 更新权限列表的 checkbox的option与value
  */
  const editFirstDirectGetOptionValue = (key: string, checked: boolean) => {
    let editRolesValuesTmp = {...editRolesValues}
    let newViewRolesOptionsTmp = {...newViewRolesOptions}
    let authIdsTmp = [...authIds]
    let notAuthIdsTmp = [...notAuthIds]
    Object.keys(newViewRolesOptions).map((optionKey: any) => {
      newViewRolesOptionsTmp[optionKey] = []
      editRolesValuesTmp[optionKey] = []
      newViewRolesOptions[optionKey].map((item: any) => {
        let id = item?.id
        if (optionKey === key) {
          if (!item.required) {
            if (checked) {
              if (item.accessMode === 'READ' || item.accessMode === 'READ_NOT_AUTH') {
                item.accessMode = 'READ_AUTHED'
                notAuthIdsTmp = delArrParams(id, 1, notAuthIdsTmp)
                authIdsTmp = delArrParams(id, 1, authIdsTmp)
                authIdsTmp.push(item?.id)
              } else if (item.accessMode === 'WRITE' || item.accessMode === 'WRITE_NOT_AUTH') {
                item.accessMode = 'WRITE_AUTHED'
                notAuthIdsTmp = delArrParams(id, 1, notAuthIdsTmp)
                authIdsTmp = delArrParams(id, 1, authIdsTmp)
                authIdsTmp.push(item?.id)
              }
            } else {
              if (item.accessMode === 'READ_AUTHED') {
                item.accessMode = 'READ_NOT_AUTH'
                notAuthIdsTmp = delArrParams(id, 1, notAuthIdsTmp)
                authIdsTmp = delArrParams(id, 1, authIdsTmp)
                notAuthIdsTmp.push(item?.id)
              } else if (item.accessMode === 'WRITE_AUTHED') {
                item.accessMode = 'WRITE_NOT_AUTH'
                notAuthIdsTmp = delArrParams(id, 1, notAuthIdsTmp)
                authIdsTmp = delArrParams(id, 1, authIdsTmp)
                notAuthIdsTmp.push(item?.id)
              }
            }
          }
        }

        newViewRolesOptionsTmp[optionKey].push({
          id: item?.id,
          label: <span className="custom-checkbox-label">{item?.name}</span>,
          name: item?.name,
          value: item?.value,
          required: item?.required,
          indeterminate: ((item?.accessMode.includes("READ")) ? true : false),
          accessMode: item?.accessMode
        })
        if (['READ_AUTHED', 'WRITE_AUTHED'].includes(item?.accessMode)) {
          editRolesValuesTmp[optionKey].push(item?.value)
        }
      })
    })
    setAuthIds(authIdsTmp)
    setNotAuthIds(notAuthIdsTmp)
    setNewViewRolesOptions(newViewRolesOptionsTmp)
    setEditRolesValues(editRolesValuesTmp)
  }

  /**
   * 编辑二级目录 更新权限列表的 checkbox的option与value
  */
  const editSecDirectGetOptionValue = (key: string, accessMode: string) => {
    let editRolesValuesTmp = {...editRolesValues}
    let newViewRolesOptionsTmp = {...newViewRolesOptions}
    let authIdsTmp = [...authIds]
    let notAuthIdsTmp = [...notAuthIds]
    Object.keys(newViewRolesOptions).map((optionKey: any) => {
      newViewRolesOptionsTmp[optionKey] = []
      editRolesValuesTmp[optionKey] = []
      newViewRolesOptions[optionKey].map((item: any) => {
        if (item?.value === key) {
          switch (accessMode){
            case 'READ_AUTHED':
              item.accessMode = 'READ_NOT_AUTH'
              break;
            case 'READ':
              item.accessMode = 'READ_AUTHED';
              break;
            case 'READ_NOT_AUTH':
              item.accessMode = 'READ_AUTHED'
              break;
            case 'WRITE_AUTHED':
              item.accessMode = 'WRITE_NOT_AUTH'
              break;
            case 'WRITE':
              item.accessMode = 'WRITE_AUTHED'
              break;
            case 'WRITE_NOT_AUTH':
              item.accessMode = 'WRITE_AUTHED'
              break;
          }
          let id = item?.id
          notAuthIdsTmp = delArrParams(id, 1, notAuthIdsTmp)
          authIdsTmp = delArrParams(id, 1, authIdsTmp)

          if (item.accessMode.includes("NOT_AUTH")) {
            notAuthIdsTmp.push(id)
          }
          if (item.accessMode.includes("AUTHED")) {
            authIdsTmp.push(item?.id)
          }
        }
        newViewRolesOptionsTmp[optionKey].push({
          id: item?.id,
          label: <span className="custom-checkbox-label">{item?.name}</span>,
          name: item?.name,
          value: item?.value,
          required: item?.required,
          indeterminate: ((item?.accessMode.includes("READ")) ? true : false),
          accessMode: item?.accessMode
        })
        if (['READ_AUTHED', 'WRITE_AUTHED'].includes(item?.accessMode)) {
          editRolesValuesTmp[optionKey].push(item?.value)
        }
      })
    })
    setNotAuthIds(notAuthIdsTmp)
    setAuthIds(authIdsTmp)
    setNewViewRolesOptions(newViewRolesOptionsTmp)
    setEditRolesValues(editRolesValuesTmp)
  }

  // 查询授权模式状态
  const queryAuthStatus = () => {
    const {roleId=''} = rolesObj 
    getRoleAuthStatus(roleId).then((res: any) => {
      if (res !== undefined) {
        setAuthSwitch(res)
      } 
    })
  }

  // 授权模式开关切换
  const handleAuthSwitch = (checked: boolean) => {
    const { roleId='' } = rolesObj
    const params = {
      roleId,
      open: checked
    }
    setRoleAuthStatus(params).then((res: any)=>{
      if(res !== undefined){
        message.success('授权模式开关切换成功')
        setAuthSwitch(checked)
        refreshGetRoles()
      }
    }).catch((err: any)=>{
      console.error('授权模式开关切换失败', err)
    })
  }

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          Promise.all([setRoleModalType("edit")]).then(() => {
            dispatch(showModal('AddRoleModal')); 
          })
        }
      }>
          修改角色
      </Menu.Item>
      <Menu.Item>
      <Popconfirm
        title="确认删除此角色？"
        onConfirm={(e)=>{
          runDelRole(activeKey)
        }}
        onCancel={()=>{}}
        okText="是"
        cancelText="否"
      >
        删除
      </Popconfirm>
      </Menu.Item>
    </Menu>
  )

  // 勾选一级目录 更新option
  const checkBoxHandleOnChange = (e: any, key: string, item: any) => {
    editFirstDirectGetOptionValue(key, e.target.checked)
  }

  // 勾选二级目录 更新option
  const checkboxGroupHandleOnChange = (e: any, accessMode: string) => {
    let key = e?.target?.value;
    editSecDirectGetOptionValue(key, accessMode)
  }

  // 编辑权限-保存
  const saveBtn = () => {
    // 原有五大角色，有默认的必选权限，编辑权限时无需进行该判断
    if (!['1','2','3','4','5'].includes(activeKey.toString()) && authIds.length < 1) {
      message.warning("请勾选授权权限")
    } else {
      runEditPermission({
        roleId: activeKey,
        removePerms: notAuthIds,
        grantPerms: authIds,
      }).then(() => {
        setIsEdit(!isEdit);
        setOperationType('');
      })
    }
  }

  const editBtn = () => {
    setOperationType('edit')
    setIsEdit(!isEdit);
  }

  // 保存-自定义角色
  const handleSaveCustomRole = () => {
    const {roleName, description, roleTemplate} = newRoleInfo || {}

    if (authIds.length < 1) {
      message.warning("请勾选授权权限")
    } else {
      runSaveCustomRole({
        roleName: roleName,
        description: description,
        permissionIds: authIds,
        roleTemplate: roleTemplate?.value, // 角色模板名字
      }).then(() => {
        setRoleModalType("");
      })
    }
  }

  return (
    <Layout className="cq-container">
      <Header className="breadcrumb-header">
        <Breadcrumb className="breadcrumb" separator=''>
          <Breadcrumb.Item>系统管理</Breadcrumb.Item>
          <Breadcrumb.Separator>|</Breadcrumb.Separator>
          <Breadcrumb.Item>角色管理</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Layout className="cq-main" style={{padding: '0 10px 0 0', overflowY: 'hidden'}}>
        <Sider className="cq-aside" width={246}>
          <div className="cq-card sys-roles-wrap">
            <div className="sys-header-title" >
              系统角色 
            </div>
            <div className='cq-card__content sys-roles-content inlineTable'>
              <Tabs
                className='roles-tabs sys-roles-tabs'
                tabPosition={'left'}
                type="card"
                activeKey={activeKey.toString()}
                onTabClick={(key) => {
                  setActiveKey(key)
                  dispatch(setTabsKeyRoleId(key))
                  getRoles(key)
                  setIsEdit(false)
                  setOperationType("")
                  setAuthIds([]);
                  setNotAuthIds([]);
                }}
              >
                {
                  roles && roles?.length ? roles?.map((item) => {
                    return ([1, 2, 3, 4, 5].includes(item?.roleId))? <Tabs.TabPane tab={
                      <>
                      <Iconfont type="icon-yonghujiaose"/>
                        {item?.roleName}
                      </>
                    } key={item?.roleId} /> : null
                  }) : null
                }
              </Tabs>
            </div>
            {userInfo.version!=='community' &&  <div className="user-header-title" >
              自定义角色
              <PermissionTooltip
                title="角色管理"
                permissionlist={modulePermissionObj}
              >
                <Button
                  type='link'
                  className={operationType === "add"? "user-header-addRoles-disable" : "user-header-addRoles"}
                  onClick={()=>{
                    setRoleModalType("add");
                    dispatch(showModal('AddRoleModal'));
                    setAuthIds([]);
                    setNotAuthIds([]);
                  }}
                  disabled={operationType === "add" || modulePermissionObj?.isOnlyRead}
                ><PlusOutlined />新增角色</Button>
              </PermissionTooltip>
            </div>}

            {userInfo.version!=='community' && <div className='cq-card__content sys-roles-content inlineTable'>
              <Tabs
                className='roles-tabs user-roles-tabs user-custom-roles-tabs'
                tabPosition={'left'}
                type="card"
                activeKey={activeKey.toString()}
                onTabClick={(key) => {
                  setActiveKey(key)
                  dispatch(setTabsKeyRoleId(key))
                  getRoles(key)
                  setAuthIds([]);
                  setNotAuthIds([]);

                  setIsEdit(false);
                  setOperationType("");
                }}
                tabBarExtraContent={null}
              >
                {
                  roles && roles?.length ? roles?.map((item) => {
                    return (![1, 2, 3, 4, 5].includes(item?.roleId))? <Tabs.TabPane tab={
                      <>
                      <Iconfont type="icon-yonghujiaose"/>
                        <Tooltip placement="topLeft" title={item?.roleName} overlayStyle={{ textAlign: 'left' }}> 
                          <div className="user-custom-roles-name">{item?.roleName}</div>
                        </Tooltip>
                        <PermissionTooltip
                          title="角色管理"
                          permissionlist={modulePermissionObj}
                        >  
                          <Dropdown overlay={modulePermissionObj?.isOnlyRead? <></> : menu} >
                            <EllipsisOutlined className='ellipsisOutlined'/>
                          </Dropdown>
                        </PermissionTooltip>
                      </>
                    } key={item?.roleId} /> : null
                  }) : null
                }
              </Tabs>
            </div>}
           
            
          </div>
        </Sider>
        <Content className="cq-content cq-content-card" style={{marginLeft: '10px'}}>
          <div className="cq-card" style={{ height: 'auto', border: 'none' }}>
            <div className='sys-roles-context'>
              {/* top */}
              <div className='sys-roles-context-card' style={{padding: '2px 16px'}}>
                <Card
                  bordered={false}
                  bodyStyle={{padding: '0 24px'}}
                >
                  <div className='sys-roles-card-label'>
                    <label className='role-name-font labelSty'>{operationType === "add" ? newRoleInfo?.roleName : rolesObj?.roleName}</label>
                    <div style={{ flex: 7 }}>
                      <div className='roles-content-headerState'>
                        {
                          operationType !== "add" && rolesObj?.validPeriod ? 
                          // <div className={rolesObj?.valid ? 'vaild_state' : 'invalid_state'}></div> : null
                          <div className={'vaild_state'}></div> : null
                        }
                        <div>{operationType !== "add" && rolesObj?.validPeriod}</div>
                      </div>
                    </div>
                  </div>
                  <div className='sys-roles-card-label'>
                    <label>角色描述: </label>
                    <div style={{ flex: 7 }}>{operationType === "add" ? (newRoleInfo?.description || '-') : rolesObj?.description ? rolesObj?.description : '-'}</div>
                  </div>
                  {operationType !== "add" &&
                  <div className='sys-roles-card-label'>
                    <label>关联用户: </label>
                    <div style={{ flex: 7 }}>
                      <span style={{ display: 'inline-block', marginRight: '10px' }}>
                        { // 只显示前五个绑定的用户
                          operationType !== "add" && rolesObj?.userInfos?.slice(0,6).map((item: {userId: string, userName: string}, index: number) => {
                            if (index < 5) {
                              return <Tag key={item?.userId}>{`${item?.userName}(${item?.userId})`}</Tag>
                            } else {
                              return <Tag key='remainUserCount'>+{rolesObj?.userInfos?.length - index}...</Tag>
                            }
                          })
                        }
                      </span>
                      {
                        !!rolesObj && rolesObj?.roleId && 
                        permissionlist?.isOnlyRead? 
                        <PermissionTooltip
                          permissionlist={permissionlist}
                          title='角色管理'
                        >
                          <PlusOutlined />添加
                        </PermissionTooltip>
                        :
                        operationType !== "add" &&
                        <>
                          <PlusOutlined
                            onClick={() => {
                              setRecordContext({ mode: 'edit', record: rolesObj })
                              dispatch(showModal('ModalSysBindUser'))
                            }}
                            className='addUser'
                          />添加
                        </>
                      }
                    </div>
                  </div>
                  }
                  {/* 审计员增加授权模式开关 */}
                  {
                    (rolesObj?.type === 'AUDIT_ROLE') && permissionlist && operationType !== "add" && 
                    <div className='sys-roles-card-label'>
                      <label>授权模式: </label>
                      <PermissionTooltip
                        permissionlist={permissionlist}
                        title='角色管理'
                      >
                        <Switch
                          checked={authSwitch}
                          checkedChildren='开'
                          unCheckedChildren='关'
                          onChange={handleAuthSwitch}
                          // 编辑权限集时, 授权模式不可编辑
                          disabled={permissionlist?.isOnlyRead || operationType === 'edit'}
                        />
                      </PermissionTooltip>
                    </div>
                  }
                </Card>
              </div>
              {/* bottom */}
              <div className='sys-roles-context' style={{borderRadius: '4px', marginTop: '10px'}}>
                <div className='sys-roles-context-card'>
                  <Card
                    title={
                      <>
                      角色授权&nbsp;
                      <Tooltip
                        title={<span className='status-tooltip-card'><StatusCard isEdit={isEdit} /></span>}
                        overlayClassName="status-tooltip"
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                      </>
                    }
                    loading={loadingRoles || getRolesLoading}
                    bordered={false}
                    bodyStyle={{padding: '0 24px'}}
                    extra={
                      operationType !== "add" ? <>
                      <PermissionTooltip
                        title="角色管理"
                        permissionlist={modulePermissionObj}
                      >  
                        <Button
                          type='primary'
                          onClick={() => { isEdit ? saveBtn(): editBtn()}}
                          disabled={modulePermissionObj?.isOnlyRead}
                        >
                          {isEdit? `保存` : `编辑`}
                        </Button>
                      </PermissionTooltip>
                      {
                        isEdit ?
                          <Button
                            onClick={() => {
                              setIsEdit(!isEdit);
                              setOperationType('');
                              // refreshGetRoles()
                            }}
                            style={{ marginLeft: 6}}
                          >
                            取消
                          </Button>
                        : <></>
                      }
                      </>
                      :
                      <></>
                      }
                  >
                    <div style={{ display: 'flex', height: `calc(100vh - 420px)`, overflowY: 'auto' }}>
                      <div style={{ flex: '85%', marginTop: '24px' }}>
                          {
                            permissionlist && viewRoles?.map((item, index) => {
                              // 没有选中授权的用户权限
                              let notAuthLen = newViewRolesOptions[item?.menuType]?.filter(
                                (prolePermission: any) => !['READ_AUTHED', 'WRITE_AUTHED'].includes(prolePermission?.accessMode)
                              ).length;

                              let hasFirstDirect = (newViewRolesOptions[item?.menuType]?.filter((prolePermission: any) => ['READ_AUTHED', 'WRITE_AUTHED'].includes(prolePermission?.accessMode)).length > 0)

                              return <>
                              <div key={index} className="sys-roles-menu-item">
                                <div className='sys-roles-menu-title'>
                                  {
                                    isEdit ?
                                      <Checkbox
                                        // disabled={item?.required}
                                        defaultChecked={item?.accessMode === 'AUTHED' ? true : false}
                                        indeterminate={
                                          notAuthLen !== newViewRolesOptions[item?.menuType]?.length && notAuthLen > 0 ?
                                          true : false
                                        }
                                        checked={
                                          !hasFirstDirect ?
                                          false : true
                                        }
                                        onChange={(e) => checkBoxHandleOnChange(e, item?.menuType, item)}
                                        disabled={item?.required && operationType!=="add" && 
                                          (newViewRolesOptions[item?.menuType]?.filter((prolePermission: any) => !prolePermission?.required).length > 0 ?
                                          false : true)
                                        }
                                      >
                                        <span className='titleFontSty'>{item?.menuName}</span>
                                      </Checkbox>
                                    :
                                    ( hasFirstDirect
                                     && <Checkbox
                                      disabled={item?.required}
                                      checked={item?.accessMode === 'AUTHED' ? true : false}
                                      className={classnames('notClick', 'notEdit-title-checkbox')}
                                    >
                                      <span className='titleFontSty'>{item?.menuName}</span>
                                    </Checkbox>)
                                  }
                                </div>
                                <div className='sys-roles-menu-content'>
                                  {
                                    newViewRolesOptions[item?.menuType] &&
                                      <Checkbox.Group value={editRolesValues[item?.menuType]}>
                                        {newViewRolesOptions[item?.menuType].map((option: any) => {
                                          if (!isEdit && ["READ_NOT_AUTH", "READ", "WRITE_NOT_AUTH", "WRITE"].includes(option.accessMode)) {
                                            return
                                          }
                                          return (
                                              <Checkbox
                                                key={option?.value}
                                                value={option?.value}
                                                indeterminate={option?.indeterminate}
                                                className={classnames(
                                                  'checkbox-padding',
                                                  (option?.required) ?
                                                  (option.accessMode === "READ_AUTHED")?
                                                  'readRequired-back' : ''
                                                  : 
                                                  (["READ_AUTHED"].includes(option?.accessMode))&& isEdit  ?
                                                  'readauth-back' : 
                                                  (["READ_AUTHED"].includes(option?.accessMode)) && !isEdit?
                                                  'readauth-back-notEdit' : 
                                                  (["READ_NOT_AUTH", "READ"].includes(option?.accessMode)) ?
                                                  'readunauth-back' :
                                                  (["WRITE_AUTHED"].includes(option?.accessMode)) && isEdit ?
                                                  'writeauth-back' : 
                                                  (["WRITE_AUTHED"].includes(option?.accessMode)) && !isEdit ?
                                                  'writeauth-back-notEdit' : 
                                                  (["WRITE_NOT_AUTH", "WRITE"].includes(option?.accessMode)) ?
                                                  'writeunauth-back' : '',
                                                  isEdit? '' : 'notClick', 'fontSty',
                                                )}
                                                onChange={(e) =>{
                                                  if (!isEdit) return
                                                  checkboxGroupHandleOnChange(e, option?.accessMode)
                                                }}
                                                disabled={option?.required}
                                              >
                                                {option?.label}
                                              </Checkbox>
                                          )
                                        })}
                                      </Checkbox.Group>
                                  }
                                </div>
                              </div>
                              {
                                (!hasFirstDirect && !isEdit) ? 
                                <div className='dividerSty'></div> 
                                : <Divider className='dividerSty'></Divider>
                              }
                              </>
                            })
                          }
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Content>
        <AddRoleModal
          type={roleModalType}
          setOperationType={setOperationType}
          setNewRoleInfo={setNewRoleInfo}
          roleId={activeKey}
          roles={roles}
          refreshRoles={refreshRoles}
        />
      </Layout>
      <Footer className={classnames('breadcrumb-footer', operationType === "add"? 'breadcrumb-footer-btn' : '')} >
        {
          operationType === "add" && 
          <>
            <Popconfirm
              title="确定要重置吗？"
              onConfirm={()=>{
                initSetList(true)
              }}
              onCancel={()=>{}}
              okText="是"
              cancelText="否"
            >
              <Button>重置</Button>
            </Popconfirm>
            <Button type="primary" onClick={() => handleSaveCustomRole()}>保存</Button>
          </>
        }
      </Footer>
      {/* 绑定用户 */}
      <RoleRecordContext.Provider
        value={{ ...recordContext, refreshRoles }}
      >
        <ModalSysBindUser />
      </RoleRecordContext.Provider>
    </Layout>
  )
}