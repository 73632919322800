import React from 'react';
import { Typography } from 'antd';
import { Iconfont } from 'src/components';
import { generateObjectName } from 'src/util';
const { Text } = Typography;

const getPointNodePathFiledValue: any = (path: string, type: string) => {
  if (!type || !path) return;

  const typeToUpper = type.toUpperCase();
  const regex = new RegExp(`${typeToUpper}:(\\w+)`);
  const match = path.match(regex);

  return match?.[1] ?? null;
};

// 新建批量执行：原先是根据 nodePathWithType 进行图标匹配，
// 但是部分数据源database层级nodePathWithType对应返回的是schame
// 因此获取选中的节点信息，使用nodeType进行图标匹配
export const RenderDatabaseFieldIcon = (
  nodePath: string,
  connectionType?: string,
  connectionName?: string,
  nodeType?: any
) => {
  const allTargetTypes: string[] = ['DATABASE', 'SCHEMA'];
  let targetFieldObj: any = {};
  allTargetTypes.map((type) => {
    const v = getPointNodePathFiledValue(nodePath, type);
    if (v) {
      targetFieldObj[type] = v;
    }
  });

  return (
    <Text ellipsis={true} style={{ display: 'flex', alignItems: 'center' }}>
      {
        connectionName && 
        <>
         <Iconfont
          type={`icon-${connectionType ?? 'database'}`}
          style={{ fontSize: '16px', marginRight: 4 }}
        />
         {connectionName}
        </>
      }
      {Object.keys(targetFieldObj).map((iconType, index) => (
        <span
          style={{ margin: "0px 2px", display: "flex", alignItems: "center" }}
          key={index+iconType}
        >
          <Iconfont
            type={`icon-${
              iconType === 'DATABASE' && connectionType
                ? 'database'
                : nodeType || iconType.toLocaleLowerCase()
            }`}
            style={{ fontSize: '16px', marginRight: 4 }}
          />
          <span>{targetFieldObj[iconType]}</span>
        </span>
      ))}
    </Text>
  );
};

//单独 database图标展示
export const renderWithOneDatabaseNodeIcon = (
  nodePath: string,
  connectionName?: string,
  dataSourceType?: string
) => {
  return (
    <>
      <Iconfont
        type={`icon-${dataSourceType ?? 'database'}`}
        style={{ fontSize: '16px', marginRight: 4 }}
      />
      {connectionName}.{generateObjectName(nodePath)}
    </>
  );
};

//具体到表层级的
export const renderDatabaseElementIcon = ({
  nodePathWithType,
  dataSourceType,
}: {
  nodePathWithType: string;
  dataSourceType: string;
}) => {
  const splitPaths = nodePathWithType?.split('/').filter((i) => i);
  let databaseElementObj: any = {};

  splitPaths.forEach((item) => {
    const splitArr = item.split(':');
    databaseElementObj[splitArr[0]] = splitArr[1];
  });

  return (
    <Text ellipsis={true} style={{ display: 'flex', alignItems: 'center' }} key={nodePathWithType}>
      {Object.keys(databaseElementObj).map((iconType, index) => (
        <span
          style={{ margin: '0px 2px', display: 'flex', alignItems: 'center' }}
          key={iconType}
        >
          {iconType !== 'CONNECTION' && (
            <>
              <Iconfont
                type={`icon-${iconType.toLocaleLowerCase()}`}
                style={{ fontSize: '16px', marginRight: 4 }}
              />
              <span>{databaseElementObj[iconType]}</span>
            </>
          )}
        </span>
      ))}
    </Text>
  );
};
