import React, { FC, useEffect, useMemo, useState } from "react";
import { useRequest, useSelector } from "src/hook";
import { Table, Tooltip } from "antd";
import { columnsRequest, columnsRequestExpandRow } from "../../common/columns";
import { Link } from "react-router-dom";
import {
  myApplyChildList,
  searchPendingFlow,
  getAllApproveFinishList,
  withdrawOrder,
  deleteOrder
} from "src/api";
import { BtnWithConfirmModal } from 'src/components';
import ModalDataChangeSelectNewApprover from "src/pageTabs/flowPages/flowDetails/ModalDataChangeSelectNewApprover";
import {
  APP_EFFECTIVE_STATES,
  MyApprovalTabKeyType,
  NO_NEED_TO_LAND_TYPES
} from "../../constants";
import { getEffectiveStatus } from '../../utils';
import styles from "../index.module.scss";
import classnames from 'classnames';

export interface IProps {
  activeKey: MyApprovalTabKeyType;
}

const VisitWorkOrderTablePage: FC<IProps> = ({ ...props }) => {
  const { activeKey } = props;
  const { applySearchValue } = useSelector((state) => state.accessRequest);
  const { permissionList } = useSelector((state) => state?.login)
  const isReadOnly = permissionList?.FLOW_APPLY?.FLOW_WORK_ORDER_MANAGEMENT?.isOnlyRead
  const roleNameList = permissionList?.FLOW_APPLY?.FLOW_WORK_ORDER_MANAGEMENT?.roleNameList

  const [currentActionRecord, setCurrentActionRecord] = useState<{
    label: string;
    // type: string
    id: number;
  }>();
  const [visible_setNewApprover, setVisible_setNewApprover] = useState(false); // 新审批人 modal visible
  const [currentTransferApprover, setCurrentTransferApprover] = useState<
    Array<string>
  >([]); // 新审批人 modal visible
  const [approveType, setApproveType] = useState<
    "rejected" | "fullilled" | "pending"
  >("pending");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filterType, setFilterType] = useState<any>(null);
  const [newData, setNewdData] = useState<any>([]);
  const [newTotal, setNewTotal] = useState<any>(0);
  const [timeSort, setTimeSort] = useState<string>("desc");

  const userId = useSelector((state) => state.login.userInfo.userId);

  // 待审批 已审批
  const {
    data,
    loading,
    run,
    refresh: refreshList,
  } = useRequest(searchPendingFlow, {
    manual: true,
    formatResult: (data) => {
      let { pageNumber, pageSize, list } = data;
      let newList = [];
      newList = list.map((item: any, index: number) => {
        item.indexKey = (pageNumber - 1) * pageSize + index + 1;
        return item;
      });
      const total = data.totalCount;
      setNewTotal(total);
      setNewdData(newList);
      return { list: newList, total };
    },
  });

  // 已完成
  const {
    data: finishData,
    loading: finishLoading,
    run: finishRun,
    refresh: finishRefresh,
  } = useRequest(getAllApproveFinishList, {
    manual: true,
    formatResult: (data) => {
      let { pageNumber, pageSize, list } = data;
      let newList = [];
      newList = list.map((item: any, index: number) => {
        item.indexKey = (pageNumber - 1) * pageSize + index + 1;
        return item;
      });
      const total = data.totalCount;
      setNewTotal(total);
      setNewdData(newList);
      return { list: newList, total };
    },
  });

  //撤回
  const {run: runWithdrawOrder } = useRequest(withdrawOrder, {manual: true, onSuccess: () => {refreshAll()}})
  //删除
  const {run: runDeleteOrder } = useRequest(deleteOrder, {manual: true, onSuccess: () => {refreshAll()}})

  const refreshAll = () => {
    if (activeKey === "finish") {
      finishRun({
        userId,
        pageSize,
        currentPage: 1,
        title: applySearchValue,
        timer: timeSort,
        priGranType: filterType,
      });
    }  else if (activeKey === 'power' || activeKey === 'withdraw') {
			//待落权 已撤回
			run({
				userId,
				pageSize,
				currentPage: 1,
				applyStatus: activeKey,
				title: applySearchValue,
				timer: timeSort,
				priGranType: filterType
			})
		} else {
      run({
        userId,
        pageSize,
        currentPage: 1,
        applyStatus: activeKey === "stayApprove" ? "pending" : "pass",
        title: applySearchValue,
        timer: timeSort,
        priGranType: filterType,
      });
    }
    setCurrentPage(1);
  };

  useEffect(() => {
    if (userId) {
      // 已完成
      if (activeKey === "finish") {
        finishRun({
          userId,
          pageSize,
          currentPage: 1,
          title: applySearchValue,
          timer: timeSort,
          priGranType: filterType,
        });
      }else if (activeKey === 'power' || activeKey === 'withdraw') {
				//待落权 已撤回
				run({
					userId,
					pageSize,
					currentPage: 1,
					applyStatus: activeKey,
					title: applySearchValue,
					timer: timeSort,
					priGranType: filterType
				})
			}  else {
        // 待审批
        run({
          userId,
          pageSize,
          currentPage: 1,
          applyStatus: activeKey === "stayApprove" ? "pending" : "pass",
          title: applySearchValue,
          timer: timeSort,
          priGranType: filterType,
        });
      }
      setCurrentPage(1);
      // setPageSize(10)
    }
  }, [activeKey, applySearchValue, userId]);

  // 转审
  const turretBtn = (record: any) => {
    setVisible_setNewApprover(true);
    setCurrentTransferApprover([record.flowTaskId]); //应该使用当前userId
    setCurrentActionRecord({
      label: "任务转交",
      // type: key,
      id: record.id,
    });
  };

  const handleChangePagination = (current: number, pageSize?: number) => {
    setCurrentPage(current);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  const renderTabAction = (record: any, activeKey: MyApprovalTabKeyType) => {
    
    if (activeKey === "stayApprove") {
      return isReadOnly ? 
          <Tooltip title={`您当前的角色是[${roleNameList?.join(',')}]，对[工单管理]没有操作权限`}>
            <span className="disabled">转审</span>
          </Tooltip>
        : <span onClick={() => turretBtn(record)}>转审</span>
    } else if (activeKey === "yetApprove") {
    
      return isReadOnly ?  (
        <Tooltip title={`您当前的角色是[${roleNameList?.join(',')}]，对[工单管理]没有操作权限`} >
          <span className="disabled">删除</span>
          {/* 待生效或生效中 */}
          {
            ['pass'].includes(record?.applyStatus) && getEffectiveStatus(record?.beginTime, record?.endTime) !== APP_EFFECTIVE_STATES.EXPIRED &&
            <span className="ml14 disabled">撤回</span>
          }
        </Tooltip>
      )
      :
      (
        <>
         <BtnWithConfirmModal title='确认删除此记录？' btnText='删除'  hideReason={true} onClick={() => runDeleteOrder(Number(record?.applyId))}/> 
        {/* 待生效或生效中 */}
          {
            ['pass'].includes(record?.applyStatus) && getEffectiveStatus(record?.beginTime, record?.endTime) !== APP_EFFECTIVE_STATES.EXPIRED && (
                <BtnWithConfirmModal title='撤回将收回申请人获得的权限，确认撤回？' btnText='撤回' onClick={(reason?: string) =>runWithdrawOrder({
                  flowId: Number(record?.applyId),
                  withdrawRemark:reason
                 })}/>
            )}
        </>
      );
    } else if (activeKey === "finish") {
     
      return isReadOnly ?  (
        <Tooltip title={`您当前的角色是[${roleNameList?.join(',')}]，对[工单管理]没有操作权限`} >
          <span className="disabled">删除</span>
          {
            (!record?.allChildRefuse && getEffectiveStatus(record?.beginTime, record?.endTime) !== APP_EFFECTIVE_STATES.EXPIRED)  && 
            <span className="ml14 disabled">撤回</span>
          }
        </Tooltip>
      ) 
      : (
        <>
          <BtnWithConfirmModal title='确认删除此记录？' btnText='删除' hideReason={true}  onClick={() => runDeleteOrder(Number(record?.applyId))}/>
          {(!record?.allChildRefuse && getEffectiveStatus(record?.beginTime, record?.endTime) !== APP_EFFECTIVE_STATES.EXPIRED)  && (
              <BtnWithConfirmModal title='撤回将收回申请人获得的权限，确认撤回？' btnText='撤回' onClick={(reason?: string) =>  runWithdrawOrder({
                flowId: Number(record?.applyId),
                withdrawRemark:reason
               })}/>
          )}
        </>
      );
      }else if (["withdraw"].includes(activeKey)) {
      return isReadOnly ? 
        <Tooltip title={`您当前的角色是[${roleNameList?.join(',')}]，对[工单管理]没有操作权限`} >
          <span className="disabled">删除</span> 
        </Tooltip>
        : 
        <BtnWithConfirmModal title='确认删除此记录？' btnText='删除' hideReason={true} onClick={() => runDeleteOrder(Number(record?.applyId))}/>
    } else {
      return "-";
    }
  };
  const newColumns = useMemo(() => {
    const newColumns = columnsRequest.map((item: any) => {
      if (item?.dataIndex === "uuid") {
        return {
          ...item,
          render: (val: any, record: any) => (
            <Link
              className={styles.underLine}
              to={{
                pathname: `/mine_approve/${val}/detail`,
                state: { ...record, flag: "worksheetManage" },
              }}
            >
              {val}
            </Link>
          ),
        };
      }
      if (item?.dataIndex === 'actions') {
        return {
          ...item,
          fixed: 'right',
          width: 150,
          render: (_: any, record: any) => (
            <div className={classnames(styles.actionsBtn, {
              [styles.disabled]: isReadOnly
            })}>
              {renderTabAction(record, activeKey)}
            </div>
          ),
        };
      }

      return item;
    });
    if (["yetApprove", "finish"].includes(activeKey)) {
      newColumns.splice(7, 0, {
        title: "生效状态",
        dataIndex: "effectiveState",
        width: 100,
        render: (_: string,record: any) => {
          return ((record?.applyStatus === 'pass' && activeKey === 'yetApprove') || activeKey === 'finish' ) ? getEffectiveStatus(record?.beginTime, record?.endTime) : '-';
        },
      });
    }

    return newColumns;
  }, [activeKey]);

  const onChange = (pagination: any, filters: any, sorter: any) => {
    setFilterType(filters?.priGranType ? filters?.priGranType[0] : null);
    const activeKeyApplyStatusMapping = {
			stayApprove: 'pending',
			yetApprove: 'pass',
			power: 'power',
			withdraw: 'withdraw'
		}
    // 已完成
    if (activeKey === "finish") {
      finishRun({
        userId,
        pageSize: pagination?.pageSize,
        currentPage: pagination?.current,
        priGranType: filters?.priGranType ? filters?.priGranType[0] : null,
        title: applySearchValue,
        timer: sorter?.order === 'ascend' ? "asc" : "desc",
      });
    } else {
      // 已审批待审核
      run({
        userId,
        pageSize: pagination?.pageSize,
        currentPage: pagination?.current,
       //@ts-ignore
				applyStatus:activeKeyApplyStatusMapping[activeKey],
        priGranType: filters?.priGranType ? filters?.priGranType[0] : null,
        title: applySearchValue,
        timer: sorter?.order === "ascend" ? "asc" : "desc",
      });
    }
    setCurrentPage(pagination?.current);
    setPageSize(pagination?.pageSize);
    setTimeSort(sorter?.order === "ascend" ? "asc" : "desc");
  };

  return (
    <div>
      <Table
        rowKey="indexKey"
        loading={loading || finishLoading}
        className={styles.tablePage}
        pagination={{
          current: currentPage,
          pageSize,
          total: newTotal,
          showSizeChanger: true,
          showQuickJumper: true,
          onChange: handleChangePagination,
        }}
        expandable={{
          expandedRowRender: (record: any) => (
            <ExpandedRowContent record={record} activeKey={activeKey} />
          ),
          rowExpandable: (record) => !NO_NEED_TO_LAND_TYPES.includes(record?.priGranType),
        }}
        rowClassName={(record, index) =>
          ((record?.applyStatus === 'pass' && activeKey === 'yetApprove') || activeKey === 'finish' )  &&
          getEffectiveStatus(record?.beginTime, record?.endTime) === APP_EFFECTIVE_STATES.EXPIRED 
            ? styles.notInEffect
            : ""
        }
        columns={newColumns}
        dataSource={newData}
        size="small"
        scroll={{ x: "1200", y: `calc(100vh - 280px)` }}
        onChange={onChange}
      />
      <ModalDataChangeSelectNewApprover
        cleanParentComponentData={() => {
          setCurrentTransferApprover([]);
          refreshAll();
          // setSearchParams(defaultSearchParams)
        }}
        userTasks={currentTransferApprover}
        visible_setNewApprover={visible_setNewApprover}
        setVisible_setNewApprover={setVisible_setNewApprover}
      />
    </div>
  );
};

export const ExpandedRowContent = ({
  record,
  activeKey,
}: {
  record: any;
  activeKey: string;
}) => {
  const flowUUID = record?.uuid;
  const {
    data: childData,
    run: childRun,
    refresh: childRefresh,
    loading,
  } = useRequest(myApplyChildList, {
    manual: true,
    refreshDeps: [flowUUID],
  });

  useEffect(() => {
    if (flowUUID) {
      // 和后端协商 只有我的审批 待审批传flowTaskId 其他传字符1
      childRun(flowUUID, "1");
    }
  }, [flowUUID]);

  const newExpandColumns = useMemo(() => {
    const newExpandColumns = columnsRequestExpandRow.map((item: any) => {
      if (item?.dataIndex === "uuid") {
        return {
          ...item,
          render: (val: any, record: any) => (
            <Link
              className={styles.underLine}
              to={{
                pathname: `/mine_approve/${val}/detail`,
                state: { ...record, flag: "worksheetManage" },
              }}
            >
              {val}
            </Link>
          ),
        };
      }

      return item;
    });

    return newExpandColumns;
  }, [activeKey, record]);

  return (
    <Table
      rowKey="uuid"
      loading={loading}
      columns={newExpandColumns}
      dataSource={childData}
      pagination={false}
      size="middle"
    />
  );
};

export { VisitWorkOrderTablePage };
