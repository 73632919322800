import React, { useEffect } from "react";
import {
  getApplyCartPermissionsPanelUpObject,

} from 'src/api';
import { useRequest} from 'src/hook';
import AboveSchemaLevelComponent from '../components/AboveSchemaLevelComponent';

export const AboveSchemaLevelContent = ({
  selectTreeItem,
  flowMainUUID,
}: {
  selectTreeItem?: any;
  needRefresh?: boolean;
  flowMainUUID: string | undefined;
}) => {

  const { data: list, loading, run: runGetApplyAutomaticObjectPermission } = useRequest(getApplyCartPermissionsPanelUpObject, {
    manual: true
  })

  useEffect(() => {

    const { connection, dataSourceType, nodePathWithType } = selectTreeItem || {};
    const { connectionType } = connection || {};
    if (!flowMainUUID) return

    if (nodePathWithType && (connectionType || dataSourceType)) {
      runGetApplyAutomaticObjectPermission({
        flowMainUUID,
        nodePath: nodePathWithType,
        dataSourceType: dataSourceType || connectionType
      })
    }
  }, [selectTreeItem, flowMainUUID])

  return (
    <AboveSchemaLevelComponent
      selectTreeItem={selectTreeItem}
      list={list}
      isEdit={false}
      loading={loading}
      viewAppliedDetail={true}
    />
  )
}