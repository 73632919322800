// 访问申请-我的申请-申请单
import React, { useEffect } from 'react';
import { useRequest } from 'src/hook';
import { useParams, useLocation } from 'react-router-dom';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { Descriptions, Spin } from 'antd';
import { MENU_FLOW } from 'src/constants';
import { SimpleBreadcrumbs } from 'src/components';
import { ResourceBidPage } from './resource-bid';
import { OrderFlowPage } from '../common/order-flow';
import { ApplicationsInfoPag } from '../common/application-info';
import {
  checkFlowInfo,
  getFlowRemarkInfo,
  getFlowStatus,
  getInventoryData,
  getNewFlowProcess,
  getPowerRemarkInfo,
  getWithdrawRemarkInfo,
} from 'src/api';
import { ApprovalRemarksPag } from '../common/approval-remarks/inedx';
import { WeightRemarksPag } from '../common/weighting-info';
import { NEED_TO_UPLOAD_FILE, NO_NEED_TO_LAND_TYPES } from '../constants'
import { UploadFilePag } from '../common/upload-file';
import AppliedResourceList from '../AppliedResourceList'
import styles from './index.module.scss';

const breadcrumbData = [
  { title: MENU_FLOW },
  {
    title: '我的申请',
    href: '/mine_apply',
  },
  {
    separator: '/',
    title: '申请单',
  },
];

export const MyApplyRequestDetailPage = () => {
  const location = useLocation().state as any;
  const { id } = useParams<{ id?: string; editId?: string }>();
  const {
    data: thinData = {},
    run: thinRun,
    loading: thinLoading,
  } = useRequest(getInventoryData, {
    manual: true,
  });

  //附件
  const { data: uploadFileData = [], run: runCheckFlowInfo } = useRequest(
    checkFlowInfo,
    {
      manual: true,
      // onSuccess(data) {
      //   console.log('附件 data :>> ', data);
      // },
    }
  );

  //备注
  const { data: remarkData = [], run: getRemarkInfoRun } = useRequest(
    getFlowRemarkInfo,
    {
      manual: true,
    }
  );

  //落权
  const { data: powerData = [], run: getPowerRun } = useRequest(
    getPowerRemarkInfo,
    {
      manual: true,
    }
  );
  //详情
  const { data: list = [], run: getFlowSteps } = useRequest(getNewFlowProcess, {
    manual: true,
  });

  //工单状态
  const { data: flowStatus = [], run: getFlowStatusRun } = useRequest(
    getFlowStatus,
    {
      manual: true,
    }
  );

  //撤回备注
  const { data: retractRemarks = [], run: runGetRetractRemarks } = useRequest(
    getWithdrawRemarkInfo,
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (location?.mainUUID) {
      thinRun({
        flowMainUUID: location?.mainUUID,
        connectionIds: location?.connectionIds,
        canOperation: location?.canOperation ? location?.canOperation : null,
      });
      getFlowSteps(location?.mainUUID);
      getFlowStatusRun(location?.mainUUID);
      getRemarkInfoRun(location?.mainUUID);
      getPowerRun(location?.mainUUID);
      runCheckFlowInfo(location?.applyId);
    }

  }, [location]);

  useEffect(() => {
    if (flowStatus?.approvedResult === 'withdraw' && location?.mainUUID) {
      runGetRetractRemarks(location?.mainUUID)
    }

  }, [flowStatus, location])


  return (
    <div className={styles.myApplyRequestDetailWrap}>
      <SimpleBreadcrumbs items={breadcrumbData} />
      <Spin spinning={thinLoading}>
        <div className={styles.content}>
          <Descriptions>
            <Descriptions.Item label="申请单号">
              <span className={styles.oddBgText}>{id}</span>
            </Descriptions.Item>
          </Descriptions>
          {!(
            NO_NEED_TO_LAND_TYPES.includes(location?.priGranType) ||
            NO_NEED_TO_LAND_TYPES.includes(thinData?.flowType)
          ) && (
              <ErrorBoundary>
                {/* 申请资源 */}
                <AppliedResourceList flowMainUUID={id}/>
              </ErrorBoundary>
            )}
          <ErrorBoundary>
            {/* 申请信息 */}

            <ApplicationsInfoPag
              {...thinData}
              priGranType={location?.priGranType || thinData?.flowType}
            />

          </ErrorBoundary>
          <ErrorBoundary>
            {/* 工单流转 */}
            <OrderFlowPage dataList={list} flowStatus={flowStatus} />
          </ErrorBoundary>
          <ErrorBoundary>
            {/* 审批信息 */}
            <ApprovalRemarksPag remarkData={remarkData} />
          </ErrorBoundary>
          {!(
            NO_NEED_TO_LAND_TYPES.includes(location?.priGranType) ||
            NO_NEED_TO_LAND_TYPES.includes(thinData?.flowType)
          ) && (
              <ErrorBoundary>
                {/* 撤回信息/落权信息 */}
                <WeightRemarksPag remarkData={powerData} />
              </ErrorBoundary>
            )}
          {flowStatus?.approvedResult === 'withdraw' && (
            <ErrorBoundary>
              {/* 撤回信息/落权信息 */}
              <WeightRemarksPag remarkData={retractRemarks} withdrawalState />
            </ErrorBoundary>
          )}
          {(
            NEED_TO_UPLOAD_FILE.includes(location?.priGranType) ||
            NEED_TO_UPLOAD_FILE.includes(thinData?.flowType)
          ) && (flowStatus.approvedResult === "pass") && (
              <ErrorBoundary>
                {/* 附件上传 */}
                <UploadFilePag uploadFileData={uploadFileData} />
              </ErrorBoundary>
            )
          }
        </div>
      </Spin>
    </div>
  );
};
