/**
 * 连接管理
 */
import React, { useState, useEffect, useMemo } from 'react'
import { ResizableBox, ResizableProps } from "react-resizable"
import { Input, Tabs, Menu, Dropdown, Tooltip, message, Modal } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { SimpleBreadcrumbs, Iconfont } from 'src/components'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import {
  queryGroupNodes,
  queryConnectionInfo,
  queryGroupConnections,
  queryDatasourceConnectionsByPage,
  getConnectionConfigItems,
  testConnection,
  deleteConnection,
} from "src/api";
import ChooseConnectionManager from './components/ChooseConnectionManage'
import ConnectionList from './ConnectionList'
import ConnectionTabs from './ConnectionTabs'
import TreeComponent from './TreeComponent'
import AddGroupModal from './AddGroupModal'
import UserAuthorizeList from "./UserAuthorizeList"
import CreateOrEditConnectionModal from "./CreateOrEditConnectionModal"
import classnames from 'classnames'
import { getCurrentModulePermissionByUrl } from 'src/util'
import styles from './index.module.scss'
import { showModal } from "../../store/extraSlice/modalVisibleSlice";
import { BatchCreateConnectionModal } from "../queryPage/sdt/modals";
import MarkModal from './components/MarkModal'

const ResizableBoxProps: ResizableProps = {
  axis: 'x',
  width: 320,
  height: 0,
  minConstraints: [260, 0],
  maxConstraints: [520, 0],
}

interface IRefTypeProps {
  handleTestConnection: (args: any) => void;
  handleDeleteConnection: (args: any) => void;
}

const ConnectionManagementPage = () => {
  const ref: React.RefObject<IRefTypeProps> = React.createRef();

  const [rightWrapWidth, setRightWrapWidth] = useState<string>("");
  const [showContentType, setShowContentType] = useState<string>("list");
  const [connectionData, setConnectionData] = useState({});
  const [addGroupVisible, setAddGroupVisible] = useState(false);
  const [connectionModalVisible, setConnectionModalVisible] = useState(false);
  const [optionType, setOptionType] = useState<string>("");
  const [groupInfo, setGroupInfo] = useState({});
  const [selectNodeType, setSelectNodeType] = useState<string>("");
  const [selectId, setSelectId] = useState<string>("");
  const [curTabs, setCurTabs] = useState<string>("group");
  const [searchValue, setSearchValue] = useState<string>("");
  const [dataSourceType, setDataSourceType] = useState<string>("");
  const [connectionName, setConnectionName] = useState<string>("");
  const [connectionId, setConnectionId] = useState<string>("");
  const [selectedNodeInfo, setSelectedNodeInfo] = useState<any>();

  //新建连接 需单独存储datasourceType，不能与tree有混淆 CQ-3019
  const [createLinkDatasourceType, setCreateLinkDatasourceType] = useState('');
  const { dataSourceList } = useSelector((state) => state.dataSource);
  // 判断 该登录权限下,该模块 只读/可编辑
  const { permissionList } = useSelector((state) => state?.login)
  const isReadOnly = permissionList?.DATABASE_MANAGEMENT?.CONNECTION_MANAGEMENT?.isOnlyRead
  const roleNameList = permissionList?.DATABASE_MANAGEMENT?.CONNECTION_MANAGEMENT?.roleNameList

  const [connectionManagerId, setConnectionManagerId] = useState<string>();
  const [visibleGuideMark, setVisibleGuideMark] = useState<boolean>(false);
  const [visibleConnectionDatabase, setVisibleConnectionDatabase] = useState<boolean>(false);
  const [isFirstBuildConnection, setIsFirstBuildConnection] = useState<boolean>(false);
  const [isBuildConnection, setIsBuildConnection] = useState<boolean>(false);
  const [isFirstQuery, setIsFirstQuery] = useState<any>(null);

  const initPagination = { pageNo: 1, pageSize: 10 }
  const [datasourceConnectionsParams, setDatasourceConnectionsParams] = 
  useState<{datasource: string,
    pageNo: string | number,
    pageSize: string | number,
  }>({
    datasource: '',
    ...initPagination
  })

  const dispatch = useDispatch()
  const BatchCreateConnectionModalVisible = useSelector(
    (state) => state.modal['BatchCreateConnectionModal'],
  )

  // 左侧treeData
  const {
    data: { total = 0, nodeList = [] } = {},
    refresh: refreshTree,
    run: queryNodes,
  } = useRequest(queryGroupNodes, {
    manual: true,
    onSuccess: () => {
      if (isFirstQuery === null) {
        setIsFirstQuery(true)
      }
      setIsBuildConnection(false)
    }
  });

  useEffect(() => {
    // 数据第一次请求结束后再进行判断
    if (isFirstQuery) {
      // 连接数为0, 出现智能向导step1
      if (total === 0) { 
        setVisibleGuideMark(true)
      } else {
        setVisibleGuideMark(false)
      }
      setIsFirstQuery(false)
    }
    // 新建了第一个连接，导航显示到 资源管理tab
    if (isBuildConnection) {
      // 连接数为1, 出现智能向导step2
      if (total === 1) {
        setIsFirstBuildConnection(true)
      } else {
        setIsFirstBuildConnection(false)
      }
    }
  }, [isFirstQuery, total])

  useEffect(() => {
    queryNodes(false);
  }, [queryNodes]);

  useEffect(() => {
    handleLeftWrapResize();
  }, []);

  useEffect(() => {
    getDatasourceConnections(datasourceConnectionsParams)
  }, [datasourceConnectionsParams])

  const handleLeftWrapResize = () => {
    // @ts-ignore
    const lWidth = document.getElementsByClassName("react-resizable")[0]?.style.width ||
      "320px";
    const width = `calc(100vw - 30px - ${lWidth} )`;
    setRightWrapWidth(width);
  };
  
  // 根据数据源查连接
  const { run: getDatasourceConnections, loading: loadingDatasourceConnections } = useRequest(
    queryDatasourceConnectionsByPage,
    {
      manual: true,
      onSuccess: (res = []) => {
        setConnectionData(res);
      }
    },
  );

  // 根据组查询连接
  const { run: getGroupConnections } = useRequest(queryGroupConnections, {
    manual: true,
    onSuccess: (res) => {
      setConnectionData(res);
    },
  });

  // 右侧展示内容
  const handleShowContentChange = (type: string, record?: any) => {
    setShowContentType(type);
    if (record) {
      setConnectionId(record?.connectionId);
      setConnectionName(record?.connectionName);
    }
  };
  
  const findNodeById = (tree: any[], id: string, type?: 'single' | 'children'): any => {
    for (const node of tree) {
      if (node.id === id) {
        return type === 'single' ? node : node?.children; // 找到匹配的节点，返回它
      }

      if (node.children && node.children.length > 0) {
        const result = findNodeById(node.children, id); // 递归调用以在子节点中查找
        if (result) {
          return result;
        }
      }
    }
    return null;
  }
  
  // 刷新所有数据
  const handleRefresh = () => {
    return refreshTree().then((res: any) => {
      const { nodeList: currentNodeList = [] } = res || {};
      getConnectionList(selectNodeType, selectId, findNodeById(currentNodeList, selectId), currentNodeList);
    });
  };

  // tabs切换
  const handleTabChange = (activeKey: string) => {
    setCurTabs(activeKey);
    setSearchValue(""); // 重置搜索值
    setSelectNodeType("datasource"); // 重置左侧树选择类型、默认数据源
    setShowContentType("list"); // 重置右侧展示内容
  };

  // 搜索值
  const handleSearch = (e: any) => {
    const value = e.target.value;
    setSearchValue(value?.trim());
  };

  // 新增组
  const handleAddGroup = (groupInfo: any) => {
    setGroupInfo(groupInfo);
    setAddGroupVisible(true);
  };

  //批量设置管理员
  const handleSettingManager = (id: string) => {
    setConnectionManagerId(id)
  }

  // 组新增连接
  const handleGroupConnection = (groupInfo: any) => {
    setGroupInfo(groupInfo);
    setAddGroupVisible(true);
  };

  // 创建连接
  const handleCreateConnection = (dataSourceName: string) => {
    setOptionType("add");
    setConnectionModalVisible(true);
    // setDataSourceType(dataSourceName);
    setCreateLinkDatasourceType(dataSourceName)
  };

  // 编辑连接
  const handleEditContent = (record: any) => {
    setOptionType("edit");
    setConnectionId(record?.connectionId);
    setConnectionModalVisible(true);
  };

  // 复制连接
  const handleCopyContent = (record: any) => {
    // const { connectionType: dataSourceName } = record || {}
    setOptionType("copy");
    setConnectionModalVisible(true);
    setConnectionId(record?.connectionId);
  };

  /**
   *  删除连接
   */
  const handleDeleteConnection = (record: any) => {
    const { connectionName } = record || {};
    Modal.confirm({
      title: `确认删除${connectionName}吗？`,
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        delDeleteConnection(record)
      },
    })
  }

  const delDeleteConnection = (data: any) => {
    const { connectionId, connectionType } = data
    const params: any = {
      connectionId,
      connectionType,
      nodePath: '',
      nodeType: 'connection',
    }
    deleteConnection(params)
      .then(() => {
        message.success('删除成功')
        handleRefresh().then(res => {
          // 解决删除节点本身的问题，选中父亲节点
          const node = findNodeById(nodeList, selectedNodeInfo?.parentId, 'single')
          if (node) {
            handleTreeSelect([{}], {
              selected: true,
              node,
              selectedNodes: [node]
            })
          }
        })
      })
      .catch((err: any) => {
        console.error('删除失败', err)
      })
  }

  /**
   * 树选择
   */
  const handleTreeSelect = (_: any[], info: any) => {
    if (!info.selected) {
      return;
    }
    const selectId = info.node.id;
    const groupDatasourceName = info.node.parentId;
    const nodeType = info?.selectedNodes?.[0]?.nodeType;
    const nodeName = info?.selectedNodes?.[0]?.nodeName;
    if (nodeType === "datasource") {
      setDataSourceType(selectId);
    } else if (nodeType === "group") {
      // 解决实例视图组下datasource读取不到的问题
      setDataSourceType(groupDatasourceName);
    } else {
      const dataSourceType =
        info?.selectedNodes?.[0]?.connection?.connectionType;
      setDataSourceType(dataSourceType);
    }
    if (nodeType === "connection") {
      setConnectionName(nodeName);
      setConnectionId(selectId);
    }
    const showContentType = nodeType === "connection" ? "tabs" : "";
    setSelectId(selectId);
    setSelectNodeType(nodeType);
    setSelectedNodeInfo(info?.node);
    setShowContentType(showContentType); // 切换右侧展示内容
    getConnectionList(nodeType, selectId, info.node?.children);
  };

  /**
 * 测试连接
 * 如果右边打开的是当前数据源且列表的情况，那么调用子组件的handleTestConnection实现状态同步
 * 如果右边打开的不是列表，那么先获取当前用户输入的数据，在手动调用测试连接接口
 */
  const handleTestConent = (id:string, sourceType:string) => {
   
    if (selectNodeType !== 'connection' && sourceType === dataSourceType) {
      ref?.current?.handleTestConnection({ connectionId: id });
    } else {
      getConnectionConfigItems(id).then(res => {
        const userInputs = {} as any;
        if (Array.isArray(res)) {
          res.forEach(item => {
            const { value, field } = item || {};
            if (field !== 'password') {
              userInputs[field] = value;
            }
          })
        }
        testConnection({
          connectionId: Number(id),
          dataSourceType,
          userInputs,
        }).then(res => message.success('连接可用'));
      })
    }
  }

  /**
   * 初始化默认树选项
   */
  const handleTreeDefaultSelect = (item: any) => {
    const { id, nodeType, nodeName } = item || {};
    const showContentType = nodeType === "connection" ? "tabs" : "";
    setSelectId(id);
    setSelectNodeType(nodeType);
    setDataSourceType(nodeName);
    setShowContentType(showContentType); // 切换右侧展示内容
    getConnectionList(nodeType, id);
  };

  // 获取数据源和组下面的连接id
  const getChildrenId = (data: any[]) => {
    let connectionIds: any[] = [];
    const getId = (data: any[]) => {
      data?.forEach((i: any) => {
        if (i?.nodeType === "connection") {
          connectionIds.push(i?.id);
        } else if (i?.children) {
          getId(i?.children);
        }
      });
    };
    getId(data);
    return connectionIds;
  };
  
  // 实例试图从左侧树构造连接数据 接口请求
  const customConnectionList = (
    nodeType: string,
    selectId: string,
    children?: any[],
    list?: any[]
  ) => {
    let connectionIds = [];
    let currentList = Array.isArray(list) ? list : nodeList
    if (nodeType === "group") {
      connectionIds = (children || [])?.map((i: any) => i?.id);
    } else {
      const matchChildren = currentList?.filter(
        (i: any) => i?.id === selectId && i?.nodeType === nodeType
      )?.[0]?.children;
      connectionIds = getChildrenId(matchChildren);
    }
    queryConnectionInfo(connectionIds)
      .then((res) => {
        setConnectionData({ connectionList: res });
      })
      .catch((err: any) => {
        console.error("查询失败:", err);
      });
  };

  /**
   * 连接数据查询
   * 不要使用useEffect去管理该方法，nodeType、selectId两个参数都改变时，没发确保两个参数同时更新
   */
  const getConnectionList = (
    nodeType: string,
    selectId: any,
    children?: any[],
    list?: any[]
  ) => {
    if (curTabs === "group") {
      if (nodeType === "datasource") {
        setDatasourceConnectionsParams(() => {
          return {...initPagination, datasource: selectId}
        })
      } else if (nodeType === "group") {
        getGroupConnections(selectId);
      }
    } else {
      customConnectionList(nodeType, selectId, children, list);
    }
  };

  const handleAddGroupModalClose = () => {
    setAddGroupVisible(false);
  };

  const handleConnectioModalClose = () => {
    setConnectionModalVisible(false);
  };

  //模块权限查询
  const modulePermissionObj: {
    roleTypeList: string[], isOnlyRead: boolean; roleNameList: string[]
} = useMemo(() => {
    return getCurrentModulePermissionByUrl(permissionList,'DATABASE_MANAGEMENT')
  },[JSON.stringify(permissionList) ]) 
  
  const renderRightContent = useMemo(() => {
    switch (showContentType) {
      // 概览tabs
      case "tabs":
        return (
          <ConnectionTabs
            connectionId={connectionId}
            connectionName={connectionName}
            dataSourceType={dataSourceType}
            handleShowContentChange={handleShowContentChange}
            handleEditContent={handleEditContent}
            permissionlist={modulePermissionObj}
            isFirstBuildConnection={isFirstBuildConnection}
            setIsFirstBuildConnection={setIsFirstBuildConnection}
            setIsBuildConnection={setIsBuildConnection}
          />
        );
      // 用户授权列表
      case "userAuthorize":
        return (
          <UserAuthorizeList
            handleShowContentChange={handleShowContentChange}
          />
        );
      // 连接列表
      default:
        return (
          <ConnectionList
            ref={ref}
            curTab={curTabs}
            connectionData={connectionData}
            loading={loadingDatasourceConnections}
            selectNodeType={selectNodeType}
            dataSourceType={dataSourceType}
            selectedNodeInfo={selectedNodeInfo}
            handleEditContent={handleEditContent}
            handleCopyContent={handleCopyContent}
            handleShowContentChange={handleShowContentChange}
            handleRefreshTree={refreshTree}
            handleRefresh={handleRefresh}
            permissionlist={modulePermissionObj}
            handleDeleteConnection={handleDeleteConnection}
            setPagination={({pageNo, pageSize}: any) => {
              setDatasourceConnectionsParams((params: any) => {
                return {...params, pageNo, pageSize}
              })
            }}
          />
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    connectionData,
    connectionId,
    connectionName,
    curTabs,
    dataSourceType,
    selectNodeType,
    showContentType,
    isFirstBuildConnection,
    loadingDatasourceConnections
  ]);

  const ResizeHandle = (
    <div className={styles.resizeHandle}>
      <Iconfont type="icon-handle-8"></Iconfont>
    </div>
  );

  const DataSourceMenu = (
    <Menu>
      {dataSourceList.map(({ dataSourceName }) => (
        <Menu.Item
          key={dataSourceName}
          onClick={() => {
            handleCreateConnection(dataSourceName);
            setVisibleConnectionDatabase(false);
            setVisibleGuideMark(false);
          }}
        >
          <Iconfont
            type={"icon-connection-" + dataSourceName}
            className="mr8"
            style={{ fontSize: 14 }}
          ></Iconfont>
          {dataSourceName}
        </Menu.Item>
      ))}
      <Menu.Item
        key="BatchCreate"
        onClick={() => dispatch(showModal('BatchCreateConnectionModal'))}
      >
        <Iconfont
          type="icon-batchcomputepiliangjisuan"
          className="mr8"
          style={{ fontSize: 14 }}
        ></Iconfont>
        批量创建
      </Menu.Item>

      {!dataSourceList.length && (
        <Menu.Item key="NoDataSource" disabled>
          暂无可创建数据源类型
        </Menu.Item>
      )}
    </Menu>
  );
  const breadcrumbData = [
    { title: "数据库管理" },
    {
      title: "连接管理",
    },
  ];

  return (
    <div className={styles.connectionManagementPageWrap} >
      <div className={styles.breadcrumbLine} id="">
        <SimpleBreadcrumbs items={breadcrumbData} />
            {
              !isReadOnly && visibleGuideMark && 
              <MarkModal
                title={null}
                top={'50px'}
                right={'150px'}
                content={'新建连接，将连接纳管至平台，即可在平台上进行管理'}
                okText={'立即建立'}
                cancelText={'忽略'}
                visible={visibleGuideMark}
                setVisible={setVisibleGuideMark}
                next={() => setVisibleConnectionDatabase(true)}
              />
            }
            {
              visibleGuideMark || visibleConnectionDatabase? 
              <Dropdown 
                overlay={DataSourceMenu} 
                trigger={["click"]} 
                disabled={(!modulePermissionObj?.roleNameList?.includes('DBA角色') || isReadOnly) && !modulePermissionObj?.roleTypeList?.some(item => item.startsWith('CUSTOM_SYSTEM'))}
                overlayClassName={styles.createConnectionOverlayStyle}
                visible={visibleConnectionDatabase}
                onVisibleChange={(e: any) => setVisibleConnectionDatabase(e)}
              >
                <span 
                  id="connectionManagementBreadcrumb" 
                  className={ classnames(styles.addConnectionBtn, styles.zIndex999)}
                  onClick={() => {setVisibleConnectionDatabase(true); setVisibleGuideMark(false); }}
                >
                  <Iconfont type="icon-add-link" /> &nbsp;新建连接
                </span>
              </Dropdown>
              :
              <Dropdown 
                overlay={DataSourceMenu} 
                trigger={["click"]} 
                disabled={(!modulePermissionObj?.roleNameList?.includes('DBA角色') || isReadOnly) && !modulePermissionObj?.roleTypeList?.some(item => item.startsWith('CUSTOM_SYSTEM'))}
                overlayClassName={styles.createConnectionOverlayStyle}
              >
                {
                  isReadOnly 
                  ? <Tooltip title={`您当前的角色是[${roleNameList?.join(',')}]，对[连接管理]没有操作权限`}>
                      <span 
                          id="connectionManagementBreadcrumb" 
                          className={classnames(styles.addConnectionBtn, styles.colorb8b8b8)}
                        >
                          <Iconfont type="icon-add-link" /> &nbsp;新建连接
                        </span>
                    </Tooltip>
                  : !modulePermissionObj?.roleNameList?.includes('DBA角色') && !modulePermissionObj?.roleTypeList?.some(item => item.startsWith('CUSTOM_SYSTEM'))
                  ? <Tooltip 
                      title={`只有DBA角色才能创建连接`}
                    >
                      <span 
                        id="connectionManagementBreadcrumb" 
                        className={classnames(styles.addConnectionBtn, styles.colorb8b8b8)}
                      >
                        <Iconfont type="icon-add-link" /> &nbsp;新建连接
                      </span>
                    </Tooltip>
                    : <span 
                        id="connectionManagementBreadcrumb" 
                        className={ styles.addConnectionBtn}
                      >
                        <Iconfont type="icon-add-link" /> &nbsp;新建连接
                      </span>
                }
              </Dropdown>
            }
      </div>
      <div className={styles.contentWrap}>
        <ResizableBox
          className={styles.resizableBox}
          handle={ResizeHandle}
          onResize={handleLeftWrapResize}
          {...ResizableBoxProps}
        >
          <div className={styles.leftWrap}>
            <div className={styles.mb10} style={{ color: '#667084' }}>连接列表:{total}</div>
            <Input
              className={classnames(styles.searchBtn, styles.mb10)}
              prefix={<SearchOutlined />}
              placeholder="请输入搜索内容"
              onChange={handleSearch}
              value={searchValue}
              allowClear
            />
            <Tabs onChange={handleTabChange} activeKey={curTabs}>
              <Tabs.TabPane tab="分组视图" key="group" />
              <Tabs.TabPane tab="实例视图" key="instance" />
            </Tabs>
            <TreeComponent
              curTabs={curTabs}
              data={nodeList}
              searchValue={searchValue}
              refreshTree={refreshTree}
              handleTreeSelect={handleTreeSelect}
              handleAddConnection={handleGroupConnection}
              handleDeleteConnection={handleDeleteConnection}
              handleEditContent={handleEditContent}
              handleCopyContent={handleCopyContent}
              handleTestConnection={handleTestConent}
              handleCreateConnection={handleCreateConnection}
              handleTreeDefaultSelect={handleTreeDefaultSelect}
              handleAddGroup={handleAddGroup}
              handleSettingManager={handleSettingManager}
              permissionlist={modulePermissionObj}
              connectionId={connectionId}
              // 选中状态控制
              selectedKey={selectedNodeInfo?.key}
              isBuildConnection={isBuildConnection}
            />
          </div>
        </ResizableBox>
        <div className={styles.rightWrap} style={{ width: rightWrapWidth }}>
          <div className={styles.contentStyle}>{renderRightContent}</div>
        </div>
      </div>
      {/* 新建分组 */}
      {addGroupVisible && (
        <AddGroupModal
          visible
          groupInfo={groupInfo}
          handleClose={handleAddGroupModalClose}
          refresh={handleRefresh}
        />
      )}
      {/* 新建、编辑连接 */}
      {connectionModalVisible && (
        <CreateOrEditConnectionModal
          visible
          optionType={optionType}
          connectionId={connectionId}
          dataSourceType={optionType === 'add' ? createLinkDatasourceType : dataSourceType}
          handleClose={handleConnectioModalClose}
          refresh={handleRefresh}
          setIsBuildConnection={setIsBuildConnection}
        />
      )}
      {BatchCreateConnectionModalVisible && <BatchCreateConnectionModal />}
      {
        connectionManagerId && <ChooseConnectionManager groupId={connectionManagerId} onCancel={() => setConnectionManagerId('')} />
      }
    </div>
  );
}
export default ConnectionManagementPage