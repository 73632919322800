import {  DataSourceTypes } from 'src/api'
import { Range } from 'monaco-editor'
import { store } from 'src/store';

export const getCustomDataSouceType = (type: string) => {
  const customType = DataSourceTypes.find(
    (val) => val.toUpperCase() === type,
  )
  return customType
}

export const sleep = (duration: number) => {
  return new Promise(resolve => {
    setTimeout(resolve, duration)
  })
}

// 计算 以选中文本为基础的起始行列在编辑器中的全局文本中对应的 起始行列
export const calculateErrorPositionInFullText = (
  fullModelRang: Range,
  selectRange: any,
  errorPosition: {
    startLineNumber: any,
    endLineNumber: any,
    startColumn: any,
    endColumn: any
  }
) => {
  let newSelectRange = {
    startLineNumber: selectRange.startLineNumber,
    endLineNumber: selectRange.endLineNumber,
    startColumn: selectRange.startColumn,
    endColumn: selectRange.endColumn,
  }
  
  let newErrorPosition = JSON.parse(JSON.stringify(errorPosition))

  if (newSelectRange.startLineNumber === newSelectRange.endLineNumber) {
    if (newSelectRange.startColumn > newSelectRange.endColumn) {
      let tmp = newSelectRange.startColumn
      newSelectRange.startColumn = newSelectRange.endColumn
      newSelectRange.endColumn = tmp
    }
  } else if (newSelectRange.startLineNumber > newSelectRange.endLineNumber) {
    let tmp = newSelectRange.startColumn
    newSelectRange.startColumn = newSelectRange.endColumn
    newSelectRange.endColumn = tmp
    tmp = newSelectRange.startLineNumber
    newSelectRange.startLineNumber = newSelectRange.endLineNumber
    newSelectRange.endLineNumber = tmp
  }

  if (newSelectRange.startLineNumber>0) {
    newErrorPosition.startLineNumber = newErrorPosition.startLineNumber + (newSelectRange.startLineNumber-fullModelRang.startLineNumber)
    newErrorPosition.startColumn = newErrorPosition.startColumn + (newSelectRange.startColumn-fullModelRang.startColumn)
    newErrorPosition.endLineNumber = newErrorPosition.endLineNumber + (newSelectRange.startLineNumber-fullModelRang.startLineNumber)
    newErrorPosition.endColumn = newErrorPosition.endColumn + (newSelectRange.startColumn-fullModelRang.startColumn)
  }
  return newErrorPosition;
}

//细粒度提权图标类型处理
export const getSdtThinPermissionIconType = (type: string) => {
  if (!type) return '';
  //default CREATE_TRIGGER
  let permissionType = type.split('_')[0];
  //CQ_OPEN_TRIGGER 
  if (type.startsWith('CQ_')) {
    permissionType = type.split('_')[1];
  }
  return permissionType?.toLocaleLowerCase();
}

//根据系统角色权限来禁掉页面一些操作
export const getCurrentModulePermissionByUrl = (permissionCollection: any,menuType: string  ): {
    isOnlyRead: boolean;
    roleNameList: string[];
    roleTypeList: string[];
} => {
  
  if (!permissionCollection || !menuType) return {isOnlyRead: false, roleNameList: [], roleTypeList: []};
  //路由和菜单映射找到对应权限     // 判断 该登录权限下,该模块 只读/可编辑
  const pathName = (window.location.pathname.split("/")[1].toUpperCase() as keyof typeof permissionCollection)
  if ( !!permissionCollection?.[menuType] && !Object.keys(permissionCollection?.[menuType]).includes(pathName.toString())) {
    return permissionCollection?.[menuType]
  }
  return permissionCollection?.[menuType]?.[pathName]
}

/**
 * 计算给定文本的高度
 * (text: 给定文本，dist：文本渲染后bottom增加的高度 )
 * return 给定文本的渲染高度
*/
export const calculateRowHeight = (text: string, width: string, dist: number) : number => {
  const div = document.createElement('div')
  div.style.position = 'absolute'
  div.style.visibility = 'hidden'
  div.style.height = 'auto'
  div.style.width = width
  div.innerText = text?.replace(/\n/g, ""); // 因为页面渲染时没展示换行符号,需要保持一致，否则计算高度会有偏差
  document.body.appendChild(div)
  
  const height = div.offsetHeight + dist
  document.body.removeChild(div)

  return height
}

export const renderErrorFlag = (flag: number) => {
  switch (flag) {
    case 1:
      return '执行成功';
    case 2:
      return '解析失败';
    case 3:
      return '鉴权失败';
    case 4:
      return '执行失败';
    case 5:
      return '审核失败';
  }
  return '失败';
}
/**
 * 判断数组中是否存在指定元素，若存在，则删除数组指定元素，返回修改后的数组
 * @param param: 指定元素，即由此得到开始删除的位置
 * @param count: 删除count个元素
 * @returns 修改后的数组
 */
export const delArrParams = (param: number, count: number, array: any): any => {
  if (array.includes(param)) {
    const index = array.indexOf(param); // 查找元素的索引位置
    if (index > -1) {
      array.splice(index, count); // 删除元素
    }
  }
  return array
}

/**
 * enum字段映射
 * @param param: enum
 * @returns [key, value]
 */
export const generateFieldsMap = (fields: {[k: string]: string}) => {
  return Object.fromEntries(Object.entries(fields).map(([key, value]: any) => [key, value]));
}
/**
 * enum字段映射
 * @param param: enum
 * @returns [value, key]
 */
export const generateFieldsKeysMap = (fields: {[k: string]: string}) => {
  return Object.fromEntries(Object.entries(fields).map(([key, value]) => [value, key]));
}

/**
 * base64 编码
 * @param param: string | undefined
 * @returns string
*/
export const strToBase64 = (sqlStatement: string | undefined): string => {
  if (sqlStatement) {
    return Buffer.from(sqlStatement).toString('base64');
  }
  return '';
}

/**
 * base64 编码
 * @param param: string[] | undefined
 * @returns string[]
*/
export const strArrToBase64 = (sqlStatement?: string[] | undefined ): string[]  => {
  if (Array.isArray(sqlStatement)) {
    return sqlStatement?.map((sql: string) => {
      return strToBase64(sql)
    })
  }
  return [];
}

/**
 * base64 解码
 * @param param: string
 * @returns string
*/
export const base64ToStr = (sqlStatement: string): string => {
  const buffer = Buffer.from(sqlStatement, 'base64');
  const str = buffer.toString('utf-8');
  return str;
}
export const getIsBase64EncodeVal = () => {
  const { isBase64Encode } = store.getState().login
  return isBase64Encode || false;
}


/*
 * 持续时间 (PT0.522S) 转换为毫秒
 * @param param: string
 * @returns number
*/
export const  durationToMilliseconds = (duration: string | number) => {
  if (typeof duration === "string" && duration.includes("PT")) {
    var regex = /PT(\d*\.?\d*)S/;
    var match = duration.match(regex);

    if (match && match.length > 1) {
        // 将秒数转换为毫秒
        var seconds = parseFloat(match[1]);
        var milliseconds = seconds * 1000;
        return milliseconds;
    } else {
        return null;
    }
  } else if (typeof duration === "number") {
    return duration
  }
  return null
}