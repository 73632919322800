import React, { useState } from 'react'
import { CloseOutlined, ShoppingCartOutlined } from '@ant-design/icons'
import { useSelector, useDispatch, useRequest } from 'src/hook'
import { ResponseNodeEntity, deleteCartDataThin, getCartDataThin, startFlowInstance, updateCartDataThin } from 'src/api'
import { useForm } from 'antd/lib/form/Form'
import { Button, Form, Modal, DatePicker, Input, Tree, Tooltip, message, Popover, Popconfirm, Spin ,Radio } from 'antd'
import { setVisibleApplicationShop } from 'src/pageTabs/queryPage/queryPageSlice'
import moment from 'moment'
import { Iconfont } from 'src/components'
import { DataNode } from 'antd/lib/tree'
import { getSdtThinPermissionIconType } from 'src/util';
import { getSpecialConnectionTypePermissions } from 'src/constants'
import classnames from 'classnames'
import 'moment/locale/zh-cn'
import styles from './index.module.scss'

moment.locale('zh-cn')

const { RangePicker } = DatePicker;

interface RenderNode extends ResponseNodeEntity, DataNode {
  valid?: boolean
  enable?: boolean
  dataSourceType?: string
}

const FormLayout = {
  labelCol: { span: 4 },
  wrapperCol: { offset: 0, span: 16 },
}

export const ApplicationShopping = () => {
  const [form] = useForm()
  const dispatch = useDispatch()
  const [visibleFlag, setVisibleFlag] = useState(false)
  const [expandKeyArr, setExpandKeyArr] = useState<any>([])

  const { visibleApplicationShop, addToCartFlag } = useSelector((state) => state.queryPage)
  // 获取清单
  const { data: list, refresh, cancel, loading: cartLoading, run } = useRequest(getCartDataThin, {
    refreshDeps: [addToCartFlag],
    formatResult(data) {
      const newBusinessDataList = data?.businessDataList
      return {
        businessDataList: setTreeValue(newBusinessDataList),
        resourceCount: data?.resourceCount
      }
    }
  })

  // 更新权限
  const { run: updateRun, loading: updateLoading } = useRequest(updateCartDataThin, {
    manual: true,
    onSuccess() {
      refresh()
    }
  })

  // 提权申请
  const { data: startFlow, run: runStartFlow } = useRequest(startFlowInstance, {
    manual: true,
    onSuccess(data) {
      setExpandKeyArr([])
      message.success('提权成功')
      refresh()
      setVisibleFlag(false)
      form.resetFields()
    }
  })

  const { loading: delLoading, run: delRun } = useRequest(deleteCartDataThin, {
    manual: true,
    onSuccess() {
      setExpandKeyArr([])
      message.success('删除成功')
      refresh()
    }
  })

  const setTreeValue = (arr: any) => {
    const newArr = arr?.filter((item: any, index: number) => {
      item.key = item?.nodePath
      if (item?.children && item?.children?.length) {
        item.children = setTreeValue(item?.children)
      }
      expandKeyArr.push(item?.key)
      setExpandKeyArr(expandKeyArr)
      return item
    })
    return newArr
  }

  const disabledDate = (current: any) => {
    return current && current < moment().startOf('day');
  };
  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  const disabledTime = (now: any, type: any) => {

      let time: any = moment();
      let hour = moment(time).hour();
      let minute = moment(time).minute();
      let second = moment(time).second();
      if (type === 'start') {
        return ({
          disabledHours: () =>range(0, hour),
          disabledMinutes: () => range(0,minute),
          disabledSeconds: () =>  range(0,second)
        })
      }
       return {}
  }

  if (!visibleApplicationShop) {
    return (
      <div
        className={styles.nodeDetailInActiveWrapper}
        onClick={() => dispatch(setVisibleApplicationShop(true))}
      >
        <Iconfont type='icon-gouwuche' style={{ color: '#277AFE', fontSize: '16px', marginBottom: '10px' }} />
        <div className={styles.count}>
          {list?.resourceCount >= 99 ? '99' : (list?.resourceCount ? list?.resourceCount : 0)}
        </div>
        <div className={styles.nodeName}>
          申请单
        </div>
      </div>
    )
  }
  const titleRender = (node: RenderNode) => {
    const {
      nodeName,
      nodeType,
      dataSourceType,
      sourceOperationList,
      flowUUID
    } = node

    const getNodeIcon = () => {
      switch (nodeType) {
        case 'connectionGroup':
          return (
            <Iconfont
              style={{ color: '#3f84e9' }}
              type="icon-folder-fill"
              className="mr8"
            ></Iconfont>
          )
        case 'connection':
          return (
            <Iconfont
              type={'icon-connection-' + dataSourceType}
              className="mr8"
            ></Iconfont>
          )
        case 'trigger':
          return <Iconfont
            type={'icon-' + nodeType + '_off'}
            className="mr8"
          ></Iconfont>
        case 'schema':
          return <Iconfont
            type={'icon-connection-' + dataSourceType}
            className="mr8"
          ></Iconfont>
        default:
          let defaultIconType = `icon-${nodeType}`
          return <Iconfont
            type={defaultIconType}
            className="mr8"
          ></Iconfont>
      }
    }
    const nodeIcon = getNodeIcon()

    return (
      <div
        className={styles.sdtTitleWrap}
      >
        <Popover
          content={nodeName}
        >
          <span
          // className={classnames(styles.sdtTitle,)}
          >
            {nodeIcon}
            {nodeName?.length <= 15 ? nodeName : nodeName?.slice(0, 15) + '...'}
          </span>
        </Popover>

        <span style={{ textAlign: 'right' }}>
          {
            getSpecialConnectionTypePermissions(dataSourceType)[nodeType]?.map((type: string) => {
              let permissionTypeStatus = sourceOperationList[type]
              const permissionType = getSdtThinPermissionIconType(type);
              let iconType = permissionTypeStatus === '1' ? `icon-type-${permissionType}` : (
                permissionTypeStatus === '0' ? `icon-type-${permissionType}_disabled` : `icon-type-${permissionType}_add`
              )
              return (
                <Tooltip title={type} key={type}>
                  <Iconfont
                    type={iconType}
                    className={styles.permissionLimitIcon}
                    onClick={() => {
                      if (permissionTypeStatus !== '1') {
                        let params = {
                          ...sourceOperationList,
                        }
                        params[type] = params[type] === '0' ? '2' : '0'
                        updateRun({
                          flowUUID,
                          operationMap: params
                        })
                      } else {
                        message.warning('已有权限')
                      }
                    }}
                  />
                </Tooltip>
              )
            }
            )}
          <Popconfirm
            placement="topLeft"
            title={'确定要删除该节点'}
            onConfirm={() => {
              delRun({
                nodePathWithType: node?.nodePath,
                flowType: 'THIN'
              })
            }}
            okText="确定"
            cancelText="取消"
          >
            <Tooltip title={'删除'}>
              <CloseOutlined className={styles.ml10} />
            </Tooltip>
          </Popconfirm>
        </span>
      </div>
    )
  }

  const onSubmit = () => {
    form.validateFields().then().then((values: any) => {
           
      let params
      if (values.timeType === 'custom') {
        const beginTime = values?.time[0].format('YYYY-MM-DD HH:mm:ss');
        const endTime =  values?.time[1].format('YYYY-MM-DD HH:mm:ss');
        const s = moment(beginTime).valueOf();
        const e = moment(endTime).valueOf();
        
        if (e < moment().valueOf()) {
          return message.error('请重新选择时间，结束时间必须大于当前时间')
        }else if (s > e) {
          return message.error("起始时间必须小于结束时间");
        }
        params = {
          priGranType: 'THIN',
          flowType: 'dataManipulation',
          title: values?.title,
          remark: values?.remark,
          beginTime,
          endTime
       }

      } else {
        params = {
          priGranType: 'THIN',
          flowType: 'dataManipulation',
          title: values?.title,
          remark: values?.remark,
       }
      }
      runStartFlow(params)
    })
  }

  return (
    <div className={styles.nodeDetailActiveWrapper}>
      <Iconfont
        type='icon-shouhui'
        className={styles.iconBack}
        onClick={() => dispatch(setVisibleApplicationShop(false))}
      />
      <div className={styles.nodeDetailHeader}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* <ShoppingCartOutlined style={{ fontSize: '20px', color: '#FF3232', marginRight: 8 }} /> */}
          <Tooltip title="关闭">
            <Iconfont type='icon-gouwuche' style={{ color: '#277AFE', fontSize: '16px', marginRight: '8px' }} />
          </Tooltip>
          <div className={styles.count}>
            {list?.resourceCount >= 99 ? '99' : (list?.resourceCount ? list?.resourceCount : 0)}
          </div>
          申请清单
        </div>
        <div>

          <Popconfirm
            placement="topLeft"
            title={'确定要清空申请清单？'}
            onConfirm={() => {
              delRun({
                nodePathWithType: '/',
                flowType: 'THIN'
              })
              // stopLocalInstanceProgress(selectedProcess)
            }}
            okText="确定"
            cancelText="取消"
          >
            <span style={{ marginRight: '10px', cursor: 'pointer' }}>清空</span>
          </Popconfirm>
          {/* <CloseOutlined
            className={styles.nodeDetailClose}
            onClick={() => dispatch(setVisibleApplicationShop(false))}
          /> */}
        </div>
      </div>

      <div className={styles.nodeDetailContent}>
        <Spin spinning={cartLoading || delLoading}>
          <Tree
            className={styles.tree}
            treeData={list?.businessDataList}
            titleRender={titleRender as any}
            showIcon={false}
            selectable={false}
            defaultExpandAll={true}
            expandedKeys={expandKeyArr.length ? expandKeyArr : []}
            onExpand={(expandedKeys, { expanded: bool, node }) => {
              setExpandKeyArr(expandedKeys)
            }}
          />
        </Spin>
        <Modal
          visible={visibleFlag}
          closable={false}
          title="提交申请单"
          footer={[
            <Button type='primary' onClick={() => onSubmit()}>提交申请</Button>,
            <Button onClick={() => {
              setVisibleFlag(false)
              form.resetFields()
            }}>取消</Button>
          ]}
          width={700}
        >
          <Form form={form} {...FormLayout}  initialValues={{ timeType: "forever" }}>
          <Form.Item label="生效时间" name="timeType">
          <Radio.Group>
            <Radio value="forever">永久</Radio>
            <Radio value="custom">自定义时间段</Radio>
          </Radio.Group>
            </Form.Item>
            <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues?.timeType !== currentValues?.timeType
          }
        >
          {({ getFieldValue }) =>
            getFieldValue("timeType") === "custom" ? (
              <Form.Item
              label="时间段"
              name="time"
              // initialValue={[moment(),null]}
              rules={[{ required: true }, {
                validator(_rule, value,cb) {
                  const s = moment(value?.[0]).valueOf();
                  const e = moment(value?.[1]).valueOf();
                  if (!s || !e) {
                    cb("起始时间和结束时间都不能为空");
                  }
                  if (e < moment().valueOf()) {
                    cb('请重新选择时间，结束时间必须大于当前时间')
                  }else if (s > e) {
                    cb("起始时间必须小于结束时间");
                  }
                 cb();
                },
              }]}
            >
              <RangePicker
                disabledDate={disabledDate}
                disabledTime={disabledTime}
                ranges={{
                  '近7日': [moment(), moment().add(7,'d')],
                  '近15日': [moment(), moment().add(15,'d')],
                  '近30日':[moment(), moment().add(30,'d')]
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [ moment('00:00:00','HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                }}
                format="YYYY-MM-DD HH:mm:ss"
              />
            </Form.Item>
            ) : null
          }
        </Form.Item>
            
            <Form.Item
              label="标题"
              name="title"
              rules={[{ required: true }, { min: 2, max: 20 }]}
            >
              <Input allowClear />
            </Form.Item>
            <Form.Item
              label="备注"
              name="remark"
            // rules={[{ required: true }]}
            >
              <Input.TextArea allowClear maxLength={100} />
            </Form.Item>
          </Form>
        </Modal>
      </div>
      <div className={styles.footerBtn}>
        <Button
          type='primary'
          onClick={() => setVisibleFlag(true)}
          style={{ flex: 1 }}
          disabled={!Boolean(list?.resourceCount)}
        >
          提交申请
        </Button>
      </div>
    </div>
  )
}
