import { toInteger } from 'lodash'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/store'
import {
  IFormattedTreeNode,
  findUserAndDeptSdt
} from 'src/api'

interface OrganizationState {
  selectedTreeNode: IFormattedTreeNode | null

  /** org tree expanded keys */
  expandedKeys: string[];
  treeLoading: boolean;
  userTreeData: any[];
 
}

const initialState: OrganizationState = {
  selectedTreeNode: null,
  expandedKeys: [],
  treeLoading: false,
  userTreeData: []
}

const treeNodeAdapter = (arr: IFormattedTreeNode[], pKey?:string | number | null): any => {
  return arr?.map((item: IFormattedTreeNode) => {
    const { name, orgId, userId, type, child=[], path,count, avatarUrl } = item
    const id = orgId || userId
    const key = pKey ? pKey + '_' + id : id

    return {
      id,
      name,
      type,
      key,
      title: type==="USER"  ? `${name}(${userId})` : type === 'DEPT' ? `${name}(${count})`: name,
      children: child?.length ? treeNodeAdapter(child, key) : [],
      isLeaf: !child?.length,
      path,
      avatarUrl,
      userId,
      orgId
    }
  })
}


export const fetchUserSdtConnections = (): AppThunk => async (dispatch, getState) => {
  const {
    subjectAuth: { expandedKeys },
  } = getState()
  dispatch(setTreeLoading(true))

  try {
    const data = await findUserAndDeptSdt();
    const formatTreeData = treeNodeAdapter(data);
    dispatch(setUserTreeData(formatTreeData));
    dispatch(setExpandedKeys([formatTreeData?.[0]?.key]));
    dispatch(setSelectedNode(formatTreeData?.[0] as any));
    dispatch(setTreeLoading(false))
  } catch (error) {
    dispatch(setTreeLoading(false))
  }
}

export const subjectAuthSdtSlice = createSlice({
  name: 'subjectAuth',
  initialState,
  reducers: {
    setUserTreeData(state, action: PayloadAction<any[]>) {
      state.userTreeData = action.payload
    },
    setSelectedNode(state, action: PayloadAction<IFormattedTreeNode | null>) {
 
      state.selectedTreeNode = action.payload
    },

    setExpandedKeys(state, action: PayloadAction<string[]>) {
      state.expandedKeys = action.payload
    },
    setTreeLoading: (state, action: PayloadAction<boolean>) => {
      state.treeLoading = action.payload
    },
  },
})

export const subjectAuthSdtReducer = subjectAuthSdtSlice.reducer

export const {
  setSelectedNode,
  setExpandedKeys,
  setTreeLoading,
  setUserTreeData
} = subjectAuthSdtSlice.actions
