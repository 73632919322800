import { Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

// 排序
const SortFilters = [
  {
    text: '正序',
    value: 'asc',
  },
  {
    text: '倒序',
    value: 'desc',
  },
]

// 导出
const columnsExport = [
  {
    title: '任务编号',
    dataIndex: 'taskNo',
    key: 'taskNo',
    fixed: true,
    render: (text: string) => {
      return <Text>{text || '-'}</Text>
    }
  },
  {
    title: '创建人',
    dataIndex: 'userShow',
    key: 'userShow',
    render: (text: string) => {
      return <Text>{text || '-'}</Text>
    }
  },
  {
    title: '创建时间',
    dataIndex: 'createAt',
    key: 'createAt',
    render: (text: string) => {
      return <Text>{text || '-'}</Text>
    }
  },
  {
    title: '状态',
    dataIndex: 'taskStatus',
    key: 'taskStatus',
  }
];

// 文本导入
const columnsImport = [
  {
    title: '任务编号',
    dataIndex: 'taskNo',
    key: 'taskNo',
    fixed: true,
    render: (text: string) => {
      return <Text>{text || '-'}</Text>
    }
  },
  {
    title: '创建人',
    dataIndex: 'user',
    key: 'user',
    render: (text: string) => {
      return <Text>{text || '-'}</Text>
    }
  },
  {
    title: '创建时间',
    dataIndex: 'startTime',
    key: 'startTime',
    render: (text: string) => {
      return <Text>{text || '-'}</Text>
    }
  },
  {
    title: '状态',
    dataIndex: 'taskStatus',
    key: 'taskStatus',
  }
];

// 批量执行
const columnsBatchExecute = [
  {
    title: '任务编号',
    dataIndex: 'taskNo',
    key: 'taskNo',
    fixed: true,
    render: (text: string) => {
      return <Text>{text || '-'}</Text>
    }
  },
  {
    title: '描述',
    dataIndex: 'description',
    key: 'description',
    render: (text: string) => {
      return <Text>{text || '-'}</Text>
    }
  },
  {
    title: '创建人',
    dataIndex: 'userShow',
    key: 'userShow',
    render: (text: string) => {
      return <Text>{text || '-'}</Text>
    }
  },
  {
    title: '创建时间',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text: string) => {
      return <Text>{text || '-'}</Text>
    }
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
  }
];

// 同步数据字典
const columnsSyncDict = [
  {
    title: '任务编号',
    dataIndex: 'taskNo',
    key: 'taskNo',
    fixed: true,
    render: (text: string) => {
      return <Text>{text || '-'}</Text>
    }
  },
  {
    title: '来源',
    dataIndex: 'sourceType',
    key: 'sourceType',
    render: (text: string) => {
      return <Text>{text || '-'}</Text>
    }
  },
  {
    title: '创建人',
    dataIndex: 'showUser',
    key: 'showUser',
    render: (text: string) => {
      return <Text>{text || '-'}</Text>
    }
  },
  {
    title: '创建时间',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: '状态',
    dataIndex: 'processStatus',
    key: 'processStatus',
  }
];

// 导出-任务信息-字段定义
enum ExportFields {
  // title
  handleExport = '导出',
  createInfo = '创建信息',
  taskInfo = '任务信息',
  exportFile = '导出文件',
  // fields
  // 创建信息
  userShow = '创建人',
  createAt = '创建时间',
  // 任务信息
  taskNo = '任务编号',
  taskType = '任务类型', // 后端不返回，其值与tab名称一致，写死
  resourceName = '导出的资源', // connectionType + resourceName
  statement = '导出语句',
  exportFormat = '导出格式',
  exportDataNum = '导出行数',
  menuDumpType = '导出类型',
  finishAt = '任务结束时间',
}
// 文本导入-任务信息-字段定义
enum ImportFields {
  // title
  handleImport = '文本导入',
  createInfo = '创建信息',
  taskInfo = '任务信息',
  dataImportConfig = '导入配置',
  columnMap = '映射关系展示',
  // fields
  // 创建信息
  user = '创建人',
  startTime = '创建时间',
  // 任务信息
  taskNo = '任务编号',
  taskType = '任务类型', // 后端不返回，其值与tab名称一致，写死
  resourceName = '目标表',
  fileName = '导入文件', // dataSourceType + resourceName
  // 导入配置
  delimiter = '字段分隔符',
  lineBreak = '记录行分隔符',
  quote = '字段识别符',
  fieldNameLine = '字段名行',
  dataStartLine = '第一个数据行',
  dataEndLine = '最后一个数据行',
  decimalPointSymbol = '小数点符号',
  dateSort = '日期时间排序',
  encodeType = '二进制数据编码',
  // 映射关系展示
  sourceColumnName = '源字段',
  targetColumnName = '目标字段',
}
// 批量执行-任务信息-字段定义
enum BatchExecuteFields {
  // title
  batchExecute = '批量执行',
  createInfo = '创建信息',
  taskInfo = '任务信息',
  scriptInfos = '附件信息',
  indexFile = '索引文件',
  // fields
  // 创建信息
  userShow = '创建人',
  createdAt = '创建时间',
  // 任务信息
  taskNo = '任务编号',
  dataSourceType = '数据源',
  updatedAt = '任务结束时间',
  executeMode = '执行方式',
  errorHandleMode = '执行错误处理',
  taskType = '任务类型', // 后端不返回，其值与tab名称一致，写死
}
// 同步数据字典-任务信息-字段定义
enum SyncDictFields {
  // title
  syncDict = '同步数据字典',
  createInfo = '创建信息',
  taskInfo = '任务信息',
  // fields
  // 创建信息
  showUser = '创建人',
  createdAt = '创建时间',
  // 任务信息
  taskNo = '任务编号',
  taskType = '任务类型', // 后端不返回，其值与tab名称一致，写死
  resourceName = '同步的资源',
  sourceType = '来源',
  updatedAt = '任务结束时间',
}

// 导出-执行状态
const HandleExportStatus = {
  'SUCCESS': '成功',
  'FAILURE': '失败',
  'DOWNLOADED': '已下载',
  'PROCESSING': '处理中',
  'CREATE': '正在创建',
  'QUEUING': '队列中',
}

// 文本导入-执行状态
const HandleImportStatus = {
  'SUCCESS': '成功',
  'FAILURE': '失败',
  'PROCESSING': '进行中',
}

// 批量执行-执行状态
const BatchExecuteStatus = {
  'EXECUTE_SUCCESS': '执行成功',
  'EXECUTE_FAILURE': '执行失败',
  'EXECUTING': '执行中',
  'TOBE_EXECUTE': '待执行',
  'TERMINATE': '终止',
}

// 同步数据字典-执行状态
const SyncDictStatus = {
  'WAITING': '正在等待',
  'RUNNING': '正在执行',
  'TASK_COMPLETE': '任务完成',
  'TASK_FAILED': '任务失败',
  'TASK_CLOSED': '任务关闭',
}

// 状态对应 icon color
const StatusIconColor: {[p: string]: string} = {
  'SUCCESS': '#52c41a',
  'FAILURE': '#ff3333',
  'DOWNLOADED': '#15c44f',
  'PROCESSING': '#0256ff',
  'CREATE': '#a7c4ff',
  'QUEUING': '#a7c4ff',
  'EXECUTE_SUCCESS': '#15c44f',
  'EXECUTE_FAILURE': '#cb3838',
  'EXECUTING': '#0256ff',
  'TOBE_EXECUTE': '#a7c4ff',
  'TERMINATE': '#868fa3',
  'WAITING': '#a7c4ff',
  'RUNNING': '#0256ff',
  'TASK_COMPLETE': '#15c44f',
  'TASK_FAILED': '#cb3838',
  'TASK_CLOSED': '#868fa3',
  'FINISH': '#868fa3',
}

const TaskCenterTabsItems = ['handleExport', 'handleImport', 'batchExecute', 'syncDict'];

// 任务类型Map
const TaskTypeMap: { [key: string]: string } = {
  "handleExport": "导出",
  "handleImport": "文本导入",
  "batchExecute": "批量执行",
  "syncDict": "同步数据字典",
}

// 执行方式Map
export const ExecuteWayMap: { [key: string]: string } = {
  "AUTO":"自动",
  "MANUAL":"手动",
}

// 错误处理方式Map
export const ErrorHandleWayMap: { [key: string]: string } = {
  "TERMINAL":"终止",
  "IGNORE":"忽略",
}

// 导出-导出配置字段
const DataImportConfigFields = [
  "delimiter",
  "lineBreak",
  "quote",
  "fieldNameLine",
  "dataStartLine",
  "dataEndLine",
  "decimalPointSymbol",
  "dateSort",
  "encodeType"
]

interface TaskInfoConfigType {
  [key: string]: { section: string; fields: any }[];
}

// 任务信息配置
const TaskInfoConfig: TaskInfoConfigType = {
  handleExport: [ // 导出
    // section: 详情显示块，fields 各个详情块，都要显示哪些字段
    { section: 'createInfo', fields: [ExportFields.userShow, ExportFields.createAt] },
    { section: 'taskInfo', fields: [ExportFields.taskNo, ExportFields.taskType, ExportFields.resourceName, ExportFields.statement, 
      ExportFields.exportFormat, ExportFields.exportDataNum, ExportFields.menuDumpType, ExportFields.finishAt] },
    // section: 'file' 是文件显示块，fields 是指有几个 文件显示块
    { section: 'file', fields: [ExportFields.exportFile] },
  ],
  handleImport: [ // 文本导入 
    { section: 'createInfo', fields: [ImportFields.user, ImportFields.startTime] },
    { section: 'taskInfo', fields: [ImportFields.taskNo, ImportFields.taskType, ImportFields.resourceName, ImportFields.fileName] },
    { section: 'dataImportConfig', fields: [ImportFields.lineBreak, ImportFields.delimiter, ImportFields.quote, 
      ImportFields.fieldNameLine, ImportFields.dataStartLine, ImportFields.dataEndLine, ImportFields.decimalPointSymbol, 
      ImportFields.dateSort, ImportFields.encodeType] },
    { section: 'columnMap', fields: [ImportFields.sourceColumnName, ImportFields.targetColumnName] },
  ],
  batchExecute: [ // 批量执行 
    // section: 详情显示块，fields 各个详情块，都要显示哪些字段
    { section: 'createInfo', fields: [BatchExecuteFields.userShow, BatchExecuteFields.createdAt] },
    { section: 'taskInfo', fields: [BatchExecuteFields.taskNo, BatchExecuteFields.dataSourceType, BatchExecuteFields.updatedAt, BatchExecuteFields.executeMode, 
      BatchExecuteFields.errorHandleMode, BatchExecuteFields.taskType] },
    // section: 'file' 是文件显示块，fields 是指有几个 文件显示块
    { section: 'file', fields: [BatchExecuteFields.scriptInfos, BatchExecuteFields.indexFile] },
  ],
  syncDict: [ // 同步数据字典
    { section: 'createInfo', fields: [SyncDictFields.showUser, SyncDictFields.createdAt] },
    { section: 'taskInfo', fields: [SyncDictFields.taskNo, SyncDictFields.taskType, SyncDictFields.resourceName, SyncDictFields.sourceType, SyncDictFields.updatedAt] },
  ],
};

export {
  columnsExport,
  columnsImport,
  columnsBatchExecute,
  columnsSyncDict,
  ExportFields,
  ImportFields,
  BatchExecuteFields,
  SyncDictFields,
  TaskInfoConfig,
  TaskCenterTabsItems,
  TaskTypeMap,
  DataImportConfigFields,
  SortFilters,
  HandleExportStatus,
  HandleImportStatus,
  BatchExecuteStatus,
  SyncDictStatus,
  StatusIconColor,
}


