/**
 * 创建、编辑连接
 */
import React, { useEffect, useMemo, useState } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import * as _ from 'lodash'
import { Modal, Form, message, Button, Switch, Tooltip, Collapse, Row, Col } from 'antd'
import { useSelector, useDispatch } from 'src/hook'
import { TooltipWithQuestionIcon } from 'src/components';
import { FormLayoutTwo, FormTailLayout } from 'src/constants'
import {
  createConnectionConfig,
  editConnectionConfig,
  getConnectionConfigItems, // 同getdmsConnectionConfig
  validateConnectionConfig,
  generateConnectionUrl
} from 'src/api'
import { setConnectionResult, setIsTabEditConnection, setRefreshTabConnection } from 'src/pageTabs/connectionManagementPage/connectionManagementPageSlice'
import GenerateFormItem from './components/GenerateFormItem'
import styles from './index.module.scss'
import ConnectionSetting from './ConnectionSetting'

interface IProps {
  visible: boolean
  optionType: string
  [p: string]: any
}

const OPERATION_TYPE_MAP = {
  'edit': '编辑',
  'add': '创建',
  'copy': '复制',
} as any

const CreateOrEditConnectionModal = (props: IProps) => {
  const {
    visible,
    optionType,
    dataSourceType,
    connectionId,
    handleClose,
    refresh,
    setIsBuildConnection,
  } = props

  const dispatch = useDispatch()

  const [form] = Form.useForm()
  const { Panel } = Collapse;
  const { dataSourceMap } = useSelector((state) => state.dataSource)
  const { isTabEditConnection } = useSelector((state) => state.connectionManagement)  // 是否为tab编辑连接
  const { testConnection } = useSelector((state) => state.connectionManagement)

  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [testLoading, setTestLoading] = useState<boolean>(false)
  const [formItems, setFormItems] = useState<any[]>()
  const [hasChanedPassword, setHasChanedPassword] = useState(false)
  const [activeKey, setActiveKey] = useState<string[]>(['connectionInfo'])

  useEffect(() => {
    if (optionType === 'edit' && connectionId) {
      getConnectionConfigItems(connectionId).then((data) => {
        const items = data.filter(({ label }) => label !== '监控开关')
        setFormItemsWrapper(items)
      })
    } else if (optionType === 'copy' && connectionId) {
      getConnectionConfigItems(connectionId).then((data) => {
        // 复制不知道需不需要这个字段，暂时留着
        // const items = data.filter(({ label }) => label !== '监控开关')
        // 复制时候，连接名称和备注h和url可以编辑
        const editFields = ['connectionName', 'remark', 'connectionUrlView', 'password'];
        setFormItemsWrapper(data.map(item => {
          const { field } = item;
          return editFields.includes(field) ? { ...item, value: '' } : { ...item };
        }))
      })
    } else {
      // @ts-ignore
      const items = dataSourceMap[dataSourceType]?.connectionForm || [];
      setFormItemsWrapper(items);
    }
  }, [connectionId, dataSourceMap, dataSourceType, optionType])
  const setFormItemsWrapper = (formItems: any) => {
    setFormItems(formItems);
    // feature: MongoDB 表单特殊处理
    if (dataSourceType === 'MongoDB') {
      const connectionMode = formItems.filter((formItem: any) => formItem.field === 'connectionMode')[0]?.value;
      const srv = formItems.filter((formItem: any) => formItem.field === 'srv')[0]?.value;
      const authenticationMode = formItems.filter((formItem: any) => formItem.field === 'authenticationMode')[0]?.value;
      // 深拷贝，复制可编辑的 formItems
      let editableFormItems = _.cloneDeep(formItems);
      // 调整 MongoDB 表格
      adjustMongoForm(editableFormItems, srv, connectionMode, authenticationMode);
      // 更新
      setFormItems(editableFormItems);
    }

    if (dataSourceType === 'MySQL') {
      const connectionMode = formItems.filter((formItem: any) => formItem.field === 'connectionMode')[0]?.value;
      // 深拷贝，复制可编辑的 formItems
      let editableFormItems = _.cloneDeep(formItems);
      // 调整 mysql 表格
      adjustMySQLForm(editableFormItems, connectionMode);
      // 更新
      setFormItems(editableFormItems);
    }
  }
  // 创建/编辑连接
  const handleSaveConnection = () => {
    const action =
      optionType === 'edit' ? editConnectionConfig : createConnectionConfig
    form.validateFields().then((values) => {

      let initParams = _.cloneDeep(values);

      initParams.dataSourceType = dataSourceType

      delete initParams?.openAllSchema;

      const params: any = {
        dataSourceType,
        userInputs: initParams,
        connectionpoolUserInputs: {
          borrowMaxWaitMillis: initParams?.borrowMaxWaitMillis,
          maxSize: initParams?.maxSize,
          removeAbandonedTimeout: initParams?.removeAbandonedTimeout
        },
        commitMode: initParams?.commitMode,
        connectionManager: initParams?.connectionManager,

        // 连接管理 部分的字段，与 连接管理tab的字段名不一致
        sqlBackUpSwitch: initParams?.sqlBackupStatus || false, // 数据备份 switch
        sqlBackUpLocation: initParams?.sqlBackupLocation,  // 备份位置
        sqlFlashBackSwitch: initParams?.sqlFlashBackSetting || false, // 闪回功能 switch
      }
      delete initParams?.borrowMaxWaitMillis;
      delete initParams?.maxSize;
      delete initParams?.removeAbandonedTimeout;
      delete initParams?.commitMode;
      delete initParams?.connectionManager;
      delete initParams?.sqlBackupStatus;
      delete initParams?.sqlBackupLocation;
      delete initParams?.sqlFlashBackSetting;

      if (optionType === 'edit') {
        params.connectionId = connectionId

      } else {
        params.openAllSchema = values?.openAllSchema
      }
      setConfirmLoading(true)
      action(params, hasChanedPassword)
        .then(() => {
          message.success(`${OPERATION_TYPE_MAP[optionType]}连接成功`)
          refresh()
          if (optionType === "add") {
            setIsBuildConnection(true)
          }
          handleClose()
          if (isTabEditConnection) {
            dispatch(setRefreshTabConnection(true))
            dispatch(setIsTabEditConnection(false))
          }
        })
        .finally(() => {
          setConfirmLoading(false)
        })
    })
  }

  // 测试连接
  const handleTestConnection = () => {
    form.validateFields().then((values) => {
      const params: any = {
        connectionId,
        dataSourceType,
        userInputs: values,
      };
      params.userInputs.dataSourceType = dataSourceType

      setTestLoading(true)
      validateConnectionConfig(params, hasChanedPassword)
        .then((res) => {
          // 有且一定是true
          const currentTestConnection = [...testConnection];
          const resTest = { connectionId, result: res };
          for (let i = 0; i < currentTestConnection.length; i++) {
            if (currentTestConnection[i].connectionId === connectionId) {
              currentTestConnection[i] = resTest
              break;
            }
            if (i === currentTestConnection.length - 1) {
              currentTestConnection.push(resTest)
            }
          }
          dispatch(setConnectionResult(currentTestConnection))
          res ? message.success('连接可用') : message.error('连接失败')
        })
        .catch(() => {
          const currentTestConnection = [...testConnection];
          const resTest = { connectionId, result: false };
          for (let i = 0; i < currentTestConnection.length; i++) {
            if (currentTestConnection[i].connectionId === connectionId) {
              currentTestConnection[i] = resTest
              break
            }
            if (i === currentTestConnection.length - 1) {
              currentTestConnection.push(resTest)
            }
          }
          dispatch(setConnectionResult(currentTestConnection))
        })
        .finally(() => {
          setTestLoading(false); 
          // 测试连接的结果，ConnectionList与其同步
          refresh(); 
        })
    })
  }

  const onValuesChange = _.debounce((changedValues: any, allValues: any) => {
    if(changedValues.hasOwnProperty('password')) {
      setHasChanedPassword(true);
    }
    if (!changedValues.hasOwnProperty('connectionUrlView')) {
      const params: any = {
        dataSourceType,
        userInputs: { ...allValues, password: undefined },
      }
      generateConnectionUrl(params).then(res => {
        const { connectionUrlView } = res || {};
        if (res) {
          form.setFieldsValue({ connectionUrlView })
        }
      })
    }
    // feature: MongoDB 表单特殊处理
    if (dataSourceType === 'MongoDB') {
      const srv = allValues.srv;
      const connectionMode = allValues.connectionMode;
      const authenticationMode = allValues.authenticationMode;
      // 深拷贝，复制可编辑的 formItems
      let editableFormItems = _.cloneDeep(formItems);
      // 调整 MongoDB 表格
      adjustMongoForm(editableFormItems, srv, connectionMode, authenticationMode);
      // 更新
      setFormItems(editableFormItems);
    }
    if (dataSourceType === 'MySQL') {
      const connectionMode = allValues.connectionMode;
      // 深拷贝，复制可编辑的 formItems
      let editableFormItems = _.cloneDeep(formItems);
      // 调整 mysql 表格
      adjustMySQLForm(editableFormItems, connectionMode);
      // 更新
      setFormItems(editableFormItems);
    }
  }, 300)
  // mongodb 表单特殊处理
  const adjustMongoForm = (formItems: any, srv: any, connectionMode: any, authenticationMode: any) => {
    // 根据变更隐藏相应的组件
    formItems?.forEach((formItem: any) => {
      checkFormHideForMongoDB(formItem, srv, connectionMode, authenticationMode)
    })
  }
  const adjustMySQLForm = (formItems: any, connectionMode: any) => {
    // 根据变更隐藏相应的组件
    formItems?.forEach((formItem: any) => {
      checkFormHideForMySQL(formItem, connectionMode)
    })
  }
  // 校验是否隐藏
  const checkFormHideForMongoDB = (formItem: any, srv: any, connectionMode: any, authenticationMode: any) => {
    // 连接成员: srv | 单机模式 隐藏
    if (formItem.field === 'connectionMembers') {
      formItem.hide = srv || (connectionMode === 'StandAlone')
    }
    // IP: 非srv | 非单机模式 隐藏
    if (formItem.field === 'connectionUrl') {
      formItem.hide = !(srv || connectionMode === 'StandAlone')
    }
    // 端口: srv | 非单机模式 隐藏
    if (formItem.field === 'connectionPort') {
      formItem.hide = srv || connectionMode != 'StandAlone'
    }
    // 复制集名称 和 读偏好: 非 Replica Set 隐藏 
    if (['replicaSet', 'readPreference'].includes(formItem.field)) {
      formItem.hide = connectionMode != 'ReplicaSet'
    }
    // 服务名 和 服务主体：非 Kerberos 隐藏
    if (['serviceName', 'serviceRealm'].includes(formItem.field)) {
      formItem.hide = authenticationMode != 'Kerberos'
    }
    // 用户名 和 密码 和 验证库：非 Password 隐藏
    if (['userName', 'password', 'authDatabase'].includes(formItem.field)) {
      formItem.hide = authenticationMode != 'Password'
    }
  }
  const checkFormHideForMySQL = (formItem: any,connectionMode: any) => {
    // 连接成员: srv | 单机模式 隐藏
    if (formItem.field === 'connectionMembers') {
        formItem.hide = !(connectionMode === 'mysqlGroupReplicationMode')
    }
    // IP: 非srv | 非单机模式 隐藏
    if (formItem.field === 'connectionUrl') {
        formItem.hide = (connectionMode === 'mysqlGroupReplicationMode')
    }
    // 端口: srv | 非单机模式 隐藏
    if (formItem.field === 'connectionPort') {
        formItem.hide = (connectionMode === 'mysqlGroupReplicationMode')
    }
  }
  const onChange = (key: any) => {
    // console.log(key);
    setActiveKey(key)
  };


  const renderFormItemsOpenAllSchemaSwitch = (index: number) => {
    return (
      <Col span={24} key={index} style={{marginLeft: -13}}>
        <Form.Item
          hidden={optionType === 'edit'}
          label={<>是否开启所有资源<TooltipWithQuestionIcon tooltipTitle='默认过滤掉配置文件中定义的系统库' /></>}
          name='openAllSchema' 
        >
          <Switch checkedChildren='开启' unCheckedChildren='关闭' defaultChecked />
        </Form.Item>
      </Col>
    )
  }

  const renderFormItems = () => {
    const rows = [];
    if (!!formItems) {
      let tmp: any
      const renderedFormItems = formItems
      .filter((value: any) => !value?.hide)
      .map((value: any, index: number) => {
        let spanNum = value?.type === "radio"? 24 : 12;
        let marginLeft = value?.type === "radio"? '13%' : '0';
        let key = index;

        let item = (
          <Col span={spanNum} key={key} style={{marginLeft: marginLeft}}>
            <GenerateFormItem spec={value} operationType="connectionInfo"/>
          </Col>
        )

        if (value?.field === 'remark') {
          key = index + 1
          tmp = item
        }
        
        return (
          value?.field === 'remark' 
            ? renderFormItemsOpenAllSchemaSwitch(index)
            : item
        )
      });

      renderedFormItems.push(tmp)

      for (let i = 0; i < renderedFormItems.length; ) {
        const renderedFormItemsCur = renderedFormItems[i];
        const renderedFormItemsNext = renderedFormItems[i + 1];
        if (renderedFormItemsCur?.props?.span === 24) {
          rows.push(
            <Row key={i}>
              {renderedFormItemsCur}
            </Row>
          );
          i += 1
        } 
        if (renderedFormItemsCur?.props?.span !== 24 && renderedFormItemsNext?.props?.span === 24) {
          rows.push(
            <Row key={i}>
              {renderedFormItemsCur}
            </Row>
          );
          rows.push(
            <Row key={i+1}>
              {renderedFormItemsNext}
            </Row>
          );
          i += 2
        } 
        if (renderedFormItemsCur?.props?.span !== 24 && renderedFormItemsNext?.props?.span !== 24) {
          rows.push(
            <Row key={i}>
              {renderedFormItemsCur}
              {renderedFormItemsNext}
            </Row>
          );
          i += 2
        }
      }
    }
    return <>{rows}</>;
  }

  const renderFooterButton = useMemo(() => {
    if (activeKey.length < 2 && activeKey[0] === 'connectionInfo') {
      return (
        [
          <Button onClick={handleTestConnection} loading={testLoading}>
            测试连接
          </Button>,
          <Button onClick={() => setActiveKey(['connectionConfig'])} type='primary'>
            下一步
          </Button>
        ]
      )
    } else if (activeKey[0] === 'connectionConfig' || activeKey.length === 2) {
      return (
        [
          <Button onClick={() => setActiveKey(['connectionInfo'])} loading={testLoading}>
            上一步
          </Button>,
          <Button onClick={() => handleSaveConnection()} type='primary'>
            确定
          </Button>
        ]
      )
    }
    return <></>
  }, [activeKey, testLoading])

  const renderModal = useMemo(() => {
    return (
      <Modal
        visible={visible}
        onCancel={handleClose}
        // onOk={handleSaveConnection}
        maskClosable={false}
        title={`${OPERATION_TYPE_MAP[optionType]}${dataSourceType}连接`}
        confirmLoading={confirmLoading}
        className={styles.createOrEditConnectionWrap}
        width={1150}
        footer={renderFooterButton}
      >
        <Form
          form={form}
          autoComplete="off"
          onValuesChange={onValuesChange}
          className={styles.createOrEditConnectionForm}
          initialValues={{openAllSchema: true}}
        >
        <Collapse bordered={false} activeKey={activeKey} onChange={onChange}>
          <Panel header="填写连接信息" key="connectionInfo">
            {renderFormItems()}
          </Panel>
          <Panel header="连接配置" key="connectionConfig">
            <ConnectionSetting
              dataSourceType={dataSourceType}
              connectionId={optionType === "add" ? '' : connectionId}
              optionType={optionType}
              operationType="connectionSetting"
              form={form}
            />
          </Panel>
        </Collapse>
          {/* <Form.Item {...FormTailLayout}>
            <Button onClick={handleTestConnection} loading={testLoading}>
              测试连接
            </Button>
          </Form.Item> */}
        </Form>
      </Modal>
    )
  },
  [
    Panel,
    confirmLoading,
    connectionId,
    dataSourceType,
    form,
    handleClose,
    onValuesChange,
    optionType,
    renderFooterButton,
    renderFormItems,
    visible,
  ])

  return (
    renderModal
  )
}

export default CreateOrEditConnectionModal