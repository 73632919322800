/**
 * 连接设置
 */
import React, { useEffect, useState } from "react";
import {
  Alert,
  Tabs,
  Form,
  Checkbox,
  message,
  Button,
  Select,
  Spin,
  Tooltip,
} from "antd";
import UserAuthorize from "./UserAuthorize";
import AddUserModal from "./AddUserModal";
import ChooseManage from "src/components/chooseManage";
import {
  querySecurityStatus,
  queryGroupSecurityStatus,
  queryDataSourceSecurityStatus,
  changeHiddenMode,
  changeGroupHiddenMode,
  changeDataSourceHiddenMode,
  changeTestEnvironment,
  changeGroupTestEnvironment,
  changeDataSourceTestEnvironment,
  getPermissionUser,
  hasPermissionAddUser,
  setGroupSynchronousReview,
  setSynchronousReview,
  updateConnectionWorkConfig,
  getVersionIsCommunity,
} from "src/api";
import { useRequest, useModal, useSelector } from "src/hook";
import { FileAddOutlined } from "@ant-design/icons";
import SafeSetting from "./SafeSetting";
import classnames from "classnames";
import styles from "./index.module.scss";
import PermissionTooltip from "src/components/PermissionTooltip/PermissionTooltip";

const ConnectionSetting = (props: any) => {
  const {
    nodePathWithType = "",
    selectId,
    selectNodeType,
    dataSourceType,
  } = props;

  // 权限 是否可编辑
  const { selectedNodePermissionInfo } = useSelector((state) => state.dataProtection);
  const {modulePermissionObj = {isOnlyRead: false, roleNameList: []}, permissionSupportData = true } = selectedNodePermissionInfo["auth_management".toLocaleUpperCase()] || {};

  const groupId = selectNodeType === "group" ? selectId : null;
  const connectionId = !["datasource", "group"].includes(selectNodeType)
    ? selectId
    : null; // 数据源和组之外选择的就是connectionId
  const [form] = Form.useForm();
  const { openModal } = useModal("SecuritySetting");

  const [concealValue, setConcealValue] = useState();
  const [experimentValue, setExperimentValue] = useState();
  const [addUserModalVisible, setAddUserModalVisible] = useState(false);
  const [hasAddPermission, setHasAddPermission] = useState(true);
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("2");
  const [strictMode, setStrictMode] = useState<undefined | boolean>();

  // 查询是否有添加用户权限
  const { run: queryHasAddPermission } = useRequest(hasPermissionAddUser, {
    manual: true,
  });

  //查询是否有短信复核权限
  const { data: isCommunity, run: fetchVersion } = useRequest(
    getVersionIsCommunity,
    {
      manual: true,
    }
  );

  useEffect(() => {
    fetchVersion();
  }, []);

  useEffect(() => {
    if (nodePathWithType || groupId) {
      const params = {
        groupId,
        nodePathWithType,
      };
      queryHasAddPermission(params)
        .then((result: any) => {
          setHasAddPermission(result);
        })
        .catch();
    }
  }, [nodePathWithType, groupId, queryHasAddPermission]);

  // 权限用户列表
  const {
    data: userList = [],
    run: getUserList,
    refresh: refreshList,
    loading: userListLoading,
  } = useRequest(getPermissionUser, { manual: true });

  // 查询状态回显
  const queryStatus = () => {
    let params: any, fn;
    switch (selectNodeType) {
      case "datasource":
        params = selectId;
        fn = queryDataSourceSecurityStatus;
        break;
      case "group":
        params = selectId;
        fn = queryGroupSecurityStatus;
        break;
      default:
        params = {
          nodePath: nodePathWithType,
          connectionId: selectId,
        };
        fn = querySecurityStatus;
        break;
    }
    setLoading(true);
    fn(params)
      .then((res: any) => {
        const {
          conceal = "",
          experiment = "",
          doubleCheck = "",
          strictMode = undefined,
        } = res;
        setStrictMode(strictMode);
        setConcealValue(conceal);
        setExperimentValue(experiment);
        form.setFieldsValue({ doubleCheck, strictMode });
      })
      .catch((err: any) => {
        console.error("查询报错", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (activeKey === "2") {
      if (selectNodeType === "group") {
        getUserList({ groupId });
      } else if (nodePathWithType) {
        getUserList({ nodePathWithType });
      }
    }
  }, [activeKey, getUserList, groupId, nodePathWithType, selectNodeType]);

  useEffect(() => {
    if (activeKey === "1") {
      if (nodePathWithType || selectId) {
        queryStatus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodePathWithType, selectId, activeKey]);

  // 测试环境修改
  const handleExperimentChange = (e: any) => {
    const value = e.target.checked;
    let params: any, fn;
    switch (selectNodeType) {
      case "datasource":
        params = {
          datasource: selectId,
          experiment: value,
        };
        fn = changeDataSourceTestEnvironment;
        break;
      case "group":
        params = {
          groupId: selectId,
          experiment: value,
        };
        fn = changeGroupTestEnvironment;
        break;
      default:
        params = {
          nodePath: nodePathWithType,
          experiment: value,
          connectionId: selectId, // 在连接及以下层级需要根据connectionId判断用户权限
        };
        fn = changeTestEnvironment;
        break;
    }
    setLoading(true);
    fn(params)
      .then(() => {
        queryStatus();
        message.success("操作成功");
      })
      .catch((err: any) => console.error("测试环境修改出错：", err))
      .finally(() => {
        setLoading(false);
      });
  };

  // 隐藏模式
  const handleHideMode = (e: any) => {
    const value = e.target.checked;
    let params: any, fn;
    switch (selectNodeType) {
      case "datasource":
        params = {
          datasource: selectId,
          conceal: value,
        };
        fn = changeDataSourceHiddenMode;
        break;
      case "group":
        params = {
          groupId: selectId,
          conceal: value,
        };
        fn = changeGroupHiddenMode;
        break;
      default:
        params = {
          nodePath: nodePathWithType,
          conceal: value,
          connectionId: selectId, // 在连接及以下层级需要根据connectionId判断用户权限
        };
        fn = changeHiddenMode;
        break;
    }
    setLoading(true);
    fn(params)
      .then(() => {
        queryStatus();
        message.success("操作成功");
      })
      .catch((err: any) => console.error("隐藏模式修改出错：", err))
      .finally(() => {
        setLoading(false);
      });
  };

  // 同步复核方式修改
  const handleSyncReviewChange = (value: string) => {
    let params: any, fn;
    switch (selectNodeType) {
      case "group":
        params = {
          groupId: selectId,
          doubleCheck: value,
        };
        fn = setGroupSynchronousReview;
        break;
      default:
        params = {
          doubleCheck: value,
          connectionId: selectId, // 在连接及以下层级需要根据connectionId判断用户权限
        };
        fn = setSynchronousReview;
        break;
    }
    setLoading(true);
    fn(params)
      .then(() => {
        queryStatus();
        message.success("操作成功");
      })
      .catch((err: any) => console.error("同步复核修改出错：", err))
      .finally(() => {
        setLoading(false);
      });
  };

  // 添加用户
  const handleAddUser = () => {
    setAddUserModalVisible(true);
  };

  const handleTabsChange = (key: string) => {
    setActiveKey(key);
  };

  // 单个连接设置是否宽松模式
  const handleStrictModeChange = (e: { target: { checked: any } }) => {
    const value = e.target.checked;
    updateConnectionWorkConfig({
      connectionId: selectId,
      variable: "strictMode",
      variable_value: value,
    }).then(() => {
      message.success("设置成功");
      queryStatus();
    });
  };

  return (
    <div className={styles.connectionSettingWrap}>
      <div className={styles.title}>
        <span className={styles.boldFont}>连接设置</span>
        <span>
          {/* 只有连接级和以下级别才可以设置 */}
          {/* {!["datasource"].includes(selectNodeType) && (
            <Button
              className={styles.ml10}
              disabled={!hasAddPermission}
              onClick={() => openModal()}
            >
              安全设置
            </Button>
          )} */}
          <PermissionTooltip title="手动授权"  permissionlist={{isOnlyRead: !permissionSupportData, roleNameList: modulePermissionObj?.roleNameList}}>
            <Button
              type="primary"
              disabled={!hasAddPermission || !permissionSupportData}
              className={classnames(styles.indb, styles.ml10)}
              onClick={handleAddUser}
            >
              <FileAddOutlined />
              添加用户
            </Button>
          </PermissionTooltip>
        </span>
      </div>
      <Alert
        message="当前授权范围对连接下的所有对象生效"
        type="info"
        showIcon
      />
      <Spin spinning={loading || userListLoading}>
        <Tabs onChange={handleTabsChange} activeKey={activeKey}>
          <Tabs.TabPane tab="基础配置" key="1">
            <Form form={form}>
              <Form.Item label="测试环境" name="deveMode">
                {/* 只有组和连接级别可以修改 */}
                <PermissionTooltip
                  title="手动授权"
                  permissionlist={{isOnlyRead: !permissionSupportData, roleNameList: modulePermissionObj?.roleNameList}}
                >
                  <Checkbox
                    indeterminate={experimentValue === "PARTIAL"}
                    checked={experimentValue === "ALL"}
                    onChange={handleExperimentChange}
                    disabled={
                      !["group", "connection"].includes(selectNodeType) ||
                      !permissionSupportData
                    }
                  ></Checkbox>
                </PermissionTooltip>
              </Form.Item>
              <Form.Item label="隐藏模式" name="hideMode">
                <PermissionTooltip
                  title="手动授权"
                  permissionlist={{isOnlyRead: !permissionSupportData, roleNameList: modulePermissionObj?.roleNameList}}
                >
                  <Checkbox
                    indeterminate={concealValue === "PARTIAL"}
                    checked={concealValue === "ALL"}
                    onChange={handleHideMode}
                    disabled={!permissionSupportData}
                  ></Checkbox>
                </PermissionTooltip>
              </Form.Item>
              {["group", "connection"].includes(selectNodeType) && (
                <PermissionTooltip
                  title="手动授权"
                  permissionlist={{isOnlyRead: !permissionSupportData, roleNameList: modulePermissionObj?.roleNameList}}
                  align={{points: ['bl', 'tl'] ,offset: [100, 4]}}
                >
                  <Form.Item label="同步复核方式" name="doubleCheck">
                    <Select
                      placeholder="请选择"
                      style={{ width: 100 }}
                      options={
                        isCommunity?.version === "community"
                          ? [
                              { value: "OTP", label: "OTP复核" },
                              { value: "NONE", label: "关闭复核" },
                            ]
                          : [
                              { value: "OTP", label: "OTP复核" },
                              { value: "SMS", label: "短信复核" },
                              { value: "NONE", label: "关闭复核" },
                            ]
                      }
                      onChange={handleSyncReviewChange}
                      disabled={!permissionSupportData}
                    />
                  </Form.Item>
                </PermissionTooltip>
              )}
              {selectNodeType === "connection" && strictMode !== undefined && (
                <Form.Item label="宽松拦截模式" name="strictMode">
                  <PermissionTooltip
                    title="手动授权"
                    permissionlist={{isOnlyRead: !permissionSupportData, roleNameList: modulePermissionObj?.roleNameList}}
                  >
                    <Checkbox
                      checked={strictMode === true}
                      onChange={handleStrictModeChange}
                      disabled={!permissionSupportData}
                    ></Checkbox>
                  </PermissionTooltip>
                </Form.Item>
              )}
              {/* 管理员修改 */}
              {/* <ChooseManage groupId={groupId} connectionId={connectionId} /> */}
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="用户授权" key="2">
            <UserAuthorize
              data={userList}
              groupId={groupId}
              selectNodeType={selectNodeType}
              nodePathWithType={nodePathWithType}
              dataSourceType={dataSourceType}
              refreshList={refreshList}
              permissionlist={{isOnlyRead: !permissionSupportData, roleNameList: modulePermissionObj?.roleNameList}}
            />
          </Tabs.TabPane>
        </Tabs>
      </Spin>
      {/* 添加用户 */}
      {addUserModalVisible && (
        <AddUserModal
          visible
          groupId={groupId}
          nodePathWithType={nodePathWithType}
          dataSourceType={dataSourceType}
          handleClose={() => setAddUserModalVisible(false)}
          refreshList={refreshList}
          selectNodeType={selectNodeType}
        />
      )}
    </div>
  );
};
export default ConnectionSetting;
