/**
 * 连接设置
 */
import React, { useEffect, useMemo, useState } from 'react'
import * as _ from 'lodash';
import { Spin, Form, Radio, Button, message, Tooltip, Row, Col } from 'antd'
import { useRequest } from 'src/hook'
import GenerateFormItem from './components/GenerateFormItem'
import ChooseManage from 'src/components/chooseManage'
import ConnectionSecretKeyPage from './ConnectionSecretKey'
import {
  getConnectionPoolConfig,
  getConnectionPoolDefaultConfig,
  getConnectionWorkConfig,
  updateConnectionPoolConfig,
  updateConnectionWorkConfig,
  getDataSourceDescription
} from 'src/api'
import classnames from 'classnames'
import styles from './index.module.scss'
import { ConnectionDataBackup } from './components/ConnectionDataBackup'
interface PropsI {
  [p: string]: any
}
const ConnectionSetting = (props: PropsI) => {
  const { connectionId, dataSourceType, permissionlist, canEdit, optionType, operationType } = props
  const { roleNameList } = permissionlist || {}
  const [form] = Form.useForm();
  const thisForm = (operationType && operationType === "connectionSetting")? (props?.form) : form
  //是否取消设置
  const [isResetSetting, setIsResetSetting] = useState(false)

  //false 不支持自动提交事物 隐藏事物
  const {data: hideTransactionSetting } = useRequest(() => dataSourceType && getDataSourceDescription(dataSourceType), {
    refreshDeps:[dataSourceType],
    formatResult: (res) => !res?.supportTransaction
  })

  // 获取事务设置信息
  const {
    data: workData,
    loading: workLoading,
    run: queryConWorkConfig,
    refresh: refreshConWorkConfig,
  } = useRequest(getConnectionWorkConfig, {
    manual: true,
    formatResult: (data) => {
      const { variable, variable_value, value } = data
      if (operationType && operationType === "connectionSetting") {
        thisForm.setFieldsValue({[variable]: value})
        thisForm.getFieldsValue()
      }
      return {
        ...data,
        value: variable_value === 'true' ? true : false,
      }
    },
  })

  // 获取连接池配置信息
  const {
    data: poolData,
    loading: poolLoading,
    run: queryConPoolConfig,
    refresh: refreshConPoolConfig,
  } = useRequest(optionType === "add"? getConnectionPoolDefaultConfig : getConnectionPoolConfig, {
    manual: true,
    formatResult: (data) => {
      return data
        ?.map((item: any) => {
          if (item?.field === 'password') {
            item.value = item?.value ? atob(item?.value) : "";
          }
          return item
        })
        ?.map((item: any) => ({
          ...item,
          label: item?.label,
          value: item?.options
            ? item?.options?.find((e: any) => e?.key === item?.value)?.title
            : item?.value,
        }))
    },
  })

  useEffect(() => {
    if (connectionId && optionType !== "add") {
      queryConPoolConfig(connectionId)
      queryConWorkConfig(connectionId)
    }
    if (optionType === "add") {
      queryConPoolConfig(0)
    }
  }, [connectionId, queryConPoolConfig, queryConWorkConfig])

  useEffect(() => {
    if (poolData) {
      const initialValus: any = {}
      poolData?.forEach((i: any) => {
        initialValus[i?.field] = i?.value
      })
    
      thisForm.setFieldsValue(initialValus);
    }
  }, [thisForm, poolData, workData])


  // 事务设置修改
  const handleWorkChange = (e: any) => {
    const value = e.target.value ? 'true' : 'false'
    const params: any = {
      connectionId,
      variable: workData?.variable,
      variable_value: value,
    }
    updateConnectionWorkConfig(params)
      .then(() => {
        message.success('事务设置成功')
      })
      .catch((err: any) => {
        console.error('事务设置失败', err)
      })
  }

  // 连接池配置
  const handleConnectionPoolSave = () => {
    thisForm.validateFields().then(async(values: any) => {
      const params = {
        connectionId,
        userInputs: values,
      }
  
      updateConnectionPoolConfig(params)
        .then(() => {
          message.success('连接池配置成功')
          refreshConPoolConfig()
          refreshConWorkConfig()
        })
        .catch((err: any) => {
          console.error('连接池配置失败', err)
        })
    })
  }

  const handleCancle = () => {
    thisForm.resetFields();
  }

  if (workLoading || poolLoading) {
    return <Spin spinning={workLoading || poolLoading}></Spin>
  }

  const renderForm = () => {
    return (
      <div className={styles.connectSettingWrap}>
        <div className={styles.settingTitle}>事务设置</div>
        {
          canEdit
          ? <Radio.Group
              defaultValue={workData?.value}
              className={styles.ml10}
              onChange={handleWorkChange}
            >
              <Radio value={true}>允许自动提交</Radio>
              <Radio value={false} disabled={hideTransactionSetting}>不允许自动提交</Radio>
            </Radio.Group>
          : <Tooltip title={`您当前的角色是[${roleNameList?.join(',')}]，对[连接管理]没有操作权限`}>
              <Radio.Group
                defaultValue={workData?.value}
                className={styles.ml10}
                disabled={true}
              >
                <Radio value={true}>允许自动提交</Radio>
                <Radio value={false}>不允许自动提交</Radio>
              </Radio.Group>
            </Tooltip>
        }
        <div className={classnames(styles.settingTitle, styles.mt20)}>
          连接池配置
        </div>
        {!!poolData?.length && (
          <Form
            form={thisForm}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
          >
          <Row align='bottom'>
            <Col span={12}>
            {poolData?.map((item: any, index: string) => (
              <GenerateFormItem key={index} spec={item} labelWithTooltip={true} />
            ))}
            </Col>
            <Col span={10}>
            <Form.Item
            >
              {
                canEdit 
                ? <>
                    <Button type="primary" onClick={handleConnectionPoolSave}>
                      保存
                    </Button>
                    <Button className={styles.ml10} onClick={handleCancle}>取消</Button>
                  </>
                :
                  <>
                    <Tooltip title={`您当前的角色是[${roleNameList?.join(',')}]，对[连接管理]没有操作权限`}>
                      <Button type="primary" disabled={true}>
                        保存
                      </Button>
                    </Tooltip>
                    <Tooltip title={`您当前的角色是[${roleNameList?.join(',')}]，对[连接管理]没有操作权限`}>
                      <Button disabled={true} className={styles.ml10} onClick={handleCancle}>取消</Button>
                    </Tooltip>
                  </>
              }
            </Form.Item>
            </Col>
            </Row>
          </Form>
        )}
        {/* sql备份 */}
        {
            ["oracle", "oraclecdb", "postgresql", "mysql", "sqlserver"].includes(dataSourceType.toLocaleLowerCase()) &&
            <ConnectionDataBackup  
              connectionId={connectionId} 
              dataSourceType={dataSourceType}
              canEdit={canEdit}
              permissionlist={permissionlist}
            />
          }
        <div className={classnames(styles.settingTitle, styles.mt20)}>
          其他配置
        </div>
        {/* 管理员修改 */}
        <div className={classnames(styles.mt20)}>
          <ChooseManage
            formProps={
              {
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
              }
            }
            connectionId={connectionId}
            permissionlist={permissionlist}
            canEdit={canEdit}
          />
        </div>
  
        {/* 秘钥 */}
        <div className={classnames(styles.mt20)}>
          <ConnectionSecretKeyPage
            connectionId={connectionId}
            permissionlist={permissionlist}
            canEdit={canEdit}
          />
        </div>
      </div>
    )
  }

  const renderFormItems = () => {
    return (
      <>
        <Form.Item 
          label={'事务设置'} 
          name={'commitMode'} 
          key={'commitMode'} 
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          rules={[{ required: true }]}
          initialValue={optionType === "add" ? false : workData?.value}
        >
          <Radio.Group>
            <Radio value={true} >允许自动提交</Radio>
            <Radio value={false} >不允许自动提交</Radio>
          </Radio.Group>
        </Form.Item>
        {!!poolData?.length && (
          <Row align='bottom'>
            <Col span={12}>
            {poolData?.map((item: any, index: string) => (
              <GenerateFormItem key={index} spec={item} labelWithTooltip={true} 
                operationType="connectionConfig"
              />
            ))}
            </Col>
            </Row>
        )}
        {/* sql备份 */}
        {
            ["oracle", "oraclecdb", "postgresql", "mysql", "sqlserver"].includes(dataSourceType.toLocaleLowerCase()) &&
            <ConnectionDataBackup  
              connectionId={connectionId} 
              dataSourceType={dataSourceType}
              operationType="connectionConfig"
              form={thisForm}
            />
          }
        {/* 管理员修改 */}
        <div className={classnames(styles.mt20)}>
          <ChooseManage
            formProps={
              {
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
              }
            }
            connectionId={connectionId}
            operationType="connectionConfig"
            form={thisForm}
          />
        </div>
      </>
    )
  }

  return (
    (operationType && operationType === "connectionSetting") ? renderFormItems() : renderForm()
  )
}

export default ConnectionSetting