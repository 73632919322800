import { Layout } from "antd";
import classnames from "classnames";
import React from "react";
import { ResizableBox, ResizableProps } from "react-resizable";
import { ErrorBoundary, Iconfont, SimpleBreadcrumbs } from "src/components";
import "src/styles/layout.scss";
import styles from "./index.module.scss";
import { SdtTree } from "./components/SdtTree";
import { UserMesMain } from "./components/UserMesMain";

const { Content } = Layout;
const ResizableBoxProps: ResizableProps = {
	axis: "x",
	width: 320,
	height: 0,
	minConstraints: [260, 0],
	maxConstraints: [600, 0],
};

const BREADCRUMB_ITEMS = [
	{
		title: "通知",
	}
];

export const UserMesPage = () => {

	const ResizeHandle = (
		<div className={styles.resizeHandle}>
			<Iconfont type="icon-handle-8"></Iconfont>
		</div>
	);

	return (
		<Layout className={styles.userMesPageWrap}>
			<div className={styles.breadcrumbHeader}>
				<SimpleBreadcrumbs items={BREADCRUMB_ITEMS} />
			</div>
			<Layout className={styles.layout}>
				<ResizableBox
					className={styles.resizableBox}
					handle={ResizeHandle}
					{...ResizableBoxProps}
				>
					<div className={classnames(styles.leftWrap)}>
						<ErrorBoundary>
							<SdtTree></SdtTree>
						</ErrorBoundary>
					</div>
				</ResizableBox>

				<Content className={styles.rightWrap}>
					<ErrorBoundary>
						<UserMesMain></UserMesMain>
					</ErrorBoundary>
				</Content>
			</Layout>
		</Layout>
	);
};
