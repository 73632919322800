import { fetchGet, fetchPost } from './customFetch'
import { SqlType } from '../types'

export interface ExecutionReport {
  clientIp: string
  createTime: string
  dbEdition: string
  dbType: string
  errorMsg?: string
  executeBegin: string
  executeCost: number
  executeEnd: string
  executeSql: string
  executor: string
  executorName: string
  id: number | string
  serverIp: string
  updateTime: string
}

interface ExecutionReportResponse {
  data: ExecutionReport[]
  limit: number
  offset: number
  total: number
}

// 越权行为：EXTRAT_FLAG   解析失效：SQL_PARSE_FLAG
export type ExtratTypes = 'EXTRAT_FLAG' | 'SQL_PARSE_FLAG'

export interface ExecutionReportRequest {
  /** 执行人 */
  executors: string[]
  /** 执行开始时间(ms) */
  executeBeginMs: number
  /** 执行结束时间(ms) */
  executeEndMs: number
  /** 查询返回行数 */
  limit: number
  /** 日志类型 */
  logType: string
  /** 开始下标 */
  offset: number
  /** 执行结果 */
  resultFlag: number
  /** 数据库类型 */
  dbTypes: string[]
  sqlTypes: SqlType[]
  /** 额外查询条件 */
  extratTypes?: ExtratTypes[]
  /** 执行器类型 */
  actuatorType: 0 | 1 | 2
  /** 导出类型 excel csv */
  type?: string
  /** 执行连接 */
  connectionName?: string[]
  /** 执行连接id */
  connectionId?: string[]
}

export const getExecutionReports = (
  params: Partial<ExecutionReportRequest>,
): Promise<ExecutionReportResponse> => {
  return fetchPost(`/audit/display/audit_report/find_audit_log`, params)
}
export const exportExecutionReports = (
  params: Partial<ExecutionReportRequest>,
) => {
  return fetchPost(`/audit/display/audit_report/export_audit_log_sql`, {
    ...params,
    limit: 0,
    offset: 0,
  })
}


// 2.4.6执行记录导出
export const exportAuditLogSql = (
  params: Partial<ExecutionReportRequest>,
) => {
  return fetchPost(`/analyze/audit_report/export_audit_log_sql`, {
    ...params,
    limit: 0,
    offset: 0,
  })
}

export const exportAuditLogSql4Personal = (
    params: Partial<ExecutionReportRequest>,
) => {
  return fetchPost(`/analyze/audit_report/export_audit_log_sql/personal`, {
    ...params,
    limit: 0,
    offset: 0,
  })
}

export const exportExecutionReportsByUserLog = (
  params: Partial<ExecutionReportRequest>,
) => {
  return fetchPost(`/user/history/export/records`, {
    ...params,
    limit: 0,
    offset: 0,
  })
}

type OperationType = 'USER' | 'ROLE' | 'PERMISSION'
type OperationResult = 0 | 1 | number

export interface OperationReportRequest {
  beginTimeMs: number
  endTimeMs: number
  // 操作记录导出的两个参数
  executeBeginMs?:number
  executeEndMs?:number
  limit: number
  offset: number
  businessType?: OperationType
  resultFlag?: OperationResult
  userIds?: string[]
}

export interface OperationReport {
  id: string
  businessType: OperationType
  operateType: string
  userId: string
  userName: string
  operateEntryPoint: string
  detail: string
  beginTime: string
  endTime: string
  clientIp: string
  resultFlag: OperationResult
}

interface OperationReportResponse {
  data: OperationReport[]
  limit: number
  offset: number
  total: number
}

export const getOperationReports = (
  params: OperationReportRequest,
): Promise<OperationReportResponse> => {
  return fetchPost(`/audit/display/operate_log/detail`, params)
}
export const exportOperationReports = (
  params: Partial<OperationReportRequest>,
) => {
  return fetchPost(`/audit/display/operate_log/export`, {
    ...params,
    limit: 0,
    offset: 0,
  })
}

// 2.4.6操作记录导出
export const exportOperateLog = (
  params: Partial<OperationReportRequest>,
) => {
  return fetchPost(`/analyze/operate_log/export`, {
    ...params,
    limit: 0,
    offset: 0,
  })
}

export interface SqlCountByDbType {
  dbType: string
  amount: number
}
export const getDbTypeAmount = (): Promise<SqlCountByDbType[]> => {
  return fetchGet(`/audit/display/audit_report/db_type_amount`)
}

export type AuditUnit = 'MONTH' | 'DAY' | 'HOUR'
export interface SqlCountRequest {
  beginTimeMs: number
  endTimeMs: number
  unit: AuditUnit
  dbTypes?: string[]
  serverIps?: string[]
}

interface CountPerUnit {
  unit: string
  amount: number
}
interface SqlCountPerUnit extends CountPerUnit {
  dbType: string
}
export const getSqlCountPerUnit = (
  params: SqlCountRequest,
): Promise<SqlCountPerUnit[]> => {
  return fetchPost(`/audit/display/audit_report/load_audit_log`, params)
}

export const getUserCountPerUnit = (
  params: SqlCountRequest,
): Promise<CountPerUnit[]> => {
  return fetchPost(`/audit/display/audit_report/user_amount`, params)
}

export interface SqlCostPerUnit extends CountPerUnit {
  serverIp: string
  executeCost: number
}
export const getExecutionTimePerUnit = (
  params: SqlCountRequest,
): Promise<SqlCostPerUnit[]> => {
  return fetchPost(`/audit/display/audit_report/db_execute_cost_avg`, params)
}

interface auditOverview {
  activeUserCount: number
  allCount: number
  errorCount: number
}

export const getAuditOverview = (days: number = 0): Promise<auditOverview> => {
  return fetchPost(`/audit/display/audit_report/sql_execute_amount`, { days })
}

interface IDashBoardRequest {
  userName?: string
  accountName?: string
  permType?: string
  connectId?: string
  databaseName?: string
  operator?: string
  roleName?: string
  authDate?: string
  pageNo?: string
  pageSize?: string
}

interface IDashboardResponse {
  pageNo: number
  pageSize: number
  totalPage: number
  totalItem: number
  data: [
    {
      accountName: string
      authDate: string
      operator: string
      permType: string
      resourcePath: string
      resourceType: string
      roleId: string
      roleName: string
      userId: string
      userName: string
    },
  ]
}

// dashboard 权限查询
export const postDashBoardPerm = (params: IDashBoardRequest): Promise<IDashboardResponse> => {
  return fetchPost('/customized/perm/dashboard/search/person/life', params)
}


export const getUnauthorizedAccessPerUnit = (
  params: SqlCountRequest,
): Promise<CountPerUnit[]> => {
  return fetchPost(`/audit/display/audit_report/permission_failed_amount`, params)
}

// 获取所有的操作类型
export const getOperateTypes = () => {
  return fetchGet(`/analyze/audit_report/operate`)
}

// 获取所有数据源类型
export const getDataSourceList = () => {
  return fetchGet('/dms/meta/datasource_list')
}

// 获取数据源对应连接
export const getDSConnectionList = (datasource: string) => {
  return fetchGet(`/analyze/audit_report/connections/?datasource=${datasource}`)
}

// 开始审计 
interface auditReportParams {
  "executeBeginMs": number,
  "executeEndMs": number,
  "sqlTypes": string[], 
  "objectWithTypes": string[]
}
export const auditReport = (params: auditReportParams) => {
  return fetchPost(`/analyze/audit_report/object`, params)
}

interface userListParams{
  "userName"?: string,  
  "sort"?: string,
  [p: string]: any
}
// 获取所有用户状态 
export const getUserlist = (params: userListParams) => {
  return fetchPost(`/analyze/operate_log/all_user`, params)
}

interface userPermissParams {
  "executors"?: string[]  
}
// 用户权限 
export const getUserPermissions = (params: userPermissParams) => {
  return fetchPost(`/analyze/audit_report/auth`, params)
}

interface sqlDetailParams {
  "limit": number,
  "offset": number,
  "executors"?: string[]  
}
// sql明细 
export const getSqlDetailList = (params: sqlDetailParams) => {
  return fetchPost(`/analyze/audit_report/find_audit_log`, params)
}

interface operateListParams {
  "limit": number,
  "offset": number,
  "userIds"?: string[]  
}
// 操作明细
export const getOperateList = (params: operateListParams) => {
  return fetchPost(`/analyze/operate_log/detail`, params)
}

// 操作类型枚举

export const getOperateTypeEnum = () => {
  return fetchGet(`/analyze/operate_log/operate/type`)
}

export interface AuthDashboardParams {
  userId: string[]
  nodePath?: string[]
  dataSourceName?: string[]
  permissionType?: string[] // 权限类型
  nodeType?: string[] // 资源类型
  authTimeBegin?: string
  authTimeEnd?: string
  offset: number
  limit: number
  resourceCondition?: string
}
// 权限看板查询接口
export const getAuthDashboard = (params: AuthDashboardParams) => {
  return fetchPost(`/analyze/audit_report/dashboard`, params)
}

export const getAuthDashboardPersonal = (params: AuthDashboardParams) => {
  return fetchPost(`/analyze/audit_report/dashboard/personal`, params)
}
