import React, { useState, useMemo, useEffect } from 'react'
import { Form, Input, Select, Alert, Switch } from 'antd'
import { CustomInputNumber, UIModal } from 'src/components'
import { FormLayout } from 'src/constants'
import { ExportFormats, getTaskResult, makeResultExport, QueryResult } from 'src/api'
import { useSelector } from 'src/hook'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useHandleExternalInfo } from 'src/hook/useHandleExternalInfo'
import { useDownloadNotificationRenderer } from 'src/features/websocket/useDownloadNotification'
import { ExportApplyModal } from './ExportApplyModal'

interface IProp {
  visible: boolean
  result: QueryResult
  // getTotal: (callback: (v: number) => void) => void
  setVisible: (visible: boolean) => void
  [p: string]: any
}

export const AddResultExportModal: React.FC<IProp> = ({
  visible,
  setVisible,
  result,
  permissionResult
}) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [addResultExportForm] = Form.useForm()
  const [isWarningOnlyShow, setIsWarningOnlyShow] = useState<boolean>(true)
  const resultCount = useSelector((state) => state.resultTabs.resultCount)
  const [renderNotification] = useDownloadNotificationRenderer()
  const [hint, setHint] = useState<string | null>(null)
  const [visibleExportApply, setVisibleExportApply] = useState(false)
  
  const exportDataNumInitValue = 500  // 导出数据行数 初始值
  const sysDefaultExportNumValue = 100000 // 常规导出系统默认支持最大导出行数为10w

  // useEffect(() => {
  //   //如果当前弹窗隐藏 则不发送请求
  //   if (visible) {
  //     getTotal((t) => {
  //       setTotal(t);
  //       //异步获取数据后 需要手动传入表单initalValue 不然无法及时更新form表单 值
  //       addResultExportForm.setFieldsValue({
  //         exportDataNum: t
  //       })
  //     })
  //   }
  // }, [visible])

  // const { 
  //   run: fetchTaskResult, 
  //   cancel: cancelFetchTaskResult 
  // } = useRequest(
  //   getTaskResult,
  //   {
  //     manual: true,
  //     pollingInterval: 1000,
  //     onSuccess: (data, params) => {
  //       if (!(data && data.executeStatus === 'RUNNING')) {
  //         cancelFetchTaskResult()
  //         if (data && data.messageData) {
  //           renderNotification(data.messageData)
  //         }
  //       }
  //     },
  //   }
  // )
  const { fetchTaskResult } = useHandleExternalInfo(getTaskResult, (data: any) => {
    renderNotification(data)
  })

  const handleSubmitAddResultExport = () => {
    addResultExportForm.validateFields().then((values) => {
      if (!result) return
      const {
        statement,
        operatingObject,
        connectionId,
        lastRow: resultNum,
        dataSourceType: connectionType,
        databaseName,
        containTempTable,
        tabKey,
      } = result
      const { exportFormat, fileName, exportDataNum, exportUserInfo=false, isExportClearText = false } = values
      const {exportFlow, exportLimit, exportType, exportClearText } = permissionResult
      // 导出申请, 导出方式为‘明文导出’,则用户勾选了‘是否导出明文’,再走导出申请
      if (exportFlow &&
         ((exportLimit == null && !exportClearText)
          || (exportLimit && (exportLimit < exportDataNum)) 
          || (exportClearText && isExportClearText)
          )
      ) {
        setVisibleExportApply(true)
        setVisible(false)
        return 
      }
      setConfirmLoading(true)
      makeResultExport({
        exportFormat,
        fileName,
        exportDataNum,
        statement,
        operatingObject,
        databaseName,
        connectionId,
        connectionType,
        resultNum,
        containTempTable,
        tabKey: tabKey || '',
        exportUserInfo
      })
        .then((data) => {
          fetchTaskResult(data).then((res) => {
            console.log(res)
          })
          setVisible(false)
        })
        .finally(() => setConfirmLoading(false))
    })
  }

  const ExportSelectOptions = useMemo(
    () =>
      ExportFormats.filter(item => {
        // mongo目前只能导出成txt格式,先在这里过滤,以后更多类型支持了再优化
        if(result.dataSourceType === 'MongoDB'){
          return item === 'TXT'
        }
        return true
      }).map((format) => {
        if (format === 'SQL') {
          return {
            value: format,
            label: format,
            disabled: !result?.resultSetSqlExport || !permissionResult?.exportOther,
          }
        }
        if(format === 'PDF'){
          return {
            value: format,
            label: format,
            disabled: !permissionResult?.exportPdf,
          }
        }
        return {
          value: format,
          label: format,
          disabled: !permissionResult?.exportOther
        }
      }),
    [result.resultSetSqlExport, permissionResult?.exportOther],
  )

  // 对 导出行数的初始值进行判断，set 提示
  useEffect(() => {
    if (visible && exportDataNumInitValue) {
      // 单次导出行数限制
      if (permissionResult?.exportLimit && !permissionResult?.exportClearText && permissionResult?.exportLimit < sysDefaultExportNumValue) {
        setHint(`导出行数限制：${permissionResult?.exportLimit ? permissionResult.exportLimit : 0}行，若导出行数大于限制值，需要走审批`)
      }
    } else {
      setHint(`常规导出目前支持最大导出行数为10W行，若您想导出更多的数据，不建议您使用常规导出`)
    }
  }, [visible]);

  const initExportFormatValuie = ExportSelectOptions?.filter(i=>!i?.disabled)[0]?.value
  return (
    <>
    <UIModal
      title="结果集导出"  // 常规导出
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={handleSubmitAddResultExport}
      afterClose={() => addResultExportForm.resetFields()}
      width={526}
      confirmLoading={confirmLoading}
    >
      <Form form={addResultExportForm} {...FormLayout}>
        <Form.Item
          name="fileName"
          label="文件名"
          rules={[{ required: true, message: '请填写文件名' }]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          name="exportFormat"
          label="导出格式"
          rules={[{ required: true, message: '请选择导出格式' }]}
          initialValue={initExportFormatValuie}
        >
          <Select options={ExportSelectOptions}></Select>
        </Form.Item>
        <Form.Item
          name="exportDataNum"
          label="导出数据行数"
          // initialValue={resultCount}
          initialValue={(permissionResult?.exportLimit === 0 || permissionResult?.exportLimit === null) ? '' : exportDataNumInitValue}
          validateFirst
          rules={[
            {
              required: true,
              message: '请输入导出行数',
            },
            {
              validator: (_, value) => {
                // 每次导出走审批
                if (!permissionResult.exportLimit && !permissionResult?.exportClearText && value > sysDefaultExportNumValue ) {
                  setHint(`导出数据量过大时，不建议使用数据导出功能`)
                } else if (permissionResult?.exportLimit && !permissionResult?.exportClearText && permissionResult?.exportLimit < sysDefaultExportNumValue) {
                  setHint(`导出行数限制：${permissionResult?.exportLimit ? permissionResult.exportLimit : 0}行，若导出行数大于限制值，需要走审批`)
                } else {
                  setHint(`常规导出目前支持最大导出行数为10W行，若您想导出更多的数据，不建议您使用常规导出`) 
                }

                if (value > 60000) {
                  //TODO 由于antdv4.6不支持warningOnly非阻塞校验,因此采用以下写法,在升级至更高版本后更换
                  setIsWarningOnlyShow(true)
                  return Promise.resolve()
                }

                setIsWarningOnlyShow(false)

                return Promise.resolve()
              },
            },
          ]}
        >
          <CustomInputNumber
            type={'int'}
            autoFocus
            placeholder="建议导出行数不超过60000"
            min={0}
            style={{ width: '100%' }}
          />
        </Form.Item>
        {/* 是否同时导出相关信息： todo换字段名 */}
        <Form.Item
          name="exportUserInfo"
          label="是否同时导出相关信息"
        >
          <Switch checkedChildren="是" unCheckedChildren="否" />
        </Form.Item>

        {
          permissionResult?.exportClearText && 
          <Form.Item
            name="isExportClearText"
            label="是否导出明文"
          >
            <Switch checkedChildren="是" unCheckedChildren="否" />
          </Form.Item>
        }

        {/* {isWarningOnlyShow ? (
          <p style={{ textAlign: 'center',fontSize: '12px',  }}>
            <InfoCircleOutlined style={{marginRight: '5px'}} />
            导出数据量过大时，不建议使用数据导出功能
          </p>
        ) : null} */}

        {/* <Form.Item
          name="exportDataNum"
          label="导出数据行数"
          initialValue={500}
          rules={[{ required: true, message: '请填写导出数据行数' }]}
        >
          <InputNumber
            max={10000000}
            min={1}
            style={{ width: '100%' }}
          ></InputNumber>
        </Form.Item> */}
        {
        /**
         * @description: 
         * @产品经理:缪睿
         * @前端开发:付政昊
         * 产品经理要求一直显示,因此去掉了显示时机
         */

        }
        
        {
          hint && <Alert
            // message="导出数据量过大时，不建议使用数据导出功能"
            message={hint}
            type="warning"
            showIcon
            style={{ fontSize: 12 }}
          />
        }
      </Form>
    </UIModal>
    {
      visibleExportApply && 
      <ExportApplyModal
        data={result}
        exportForm={addResultExportForm}
        visible={visibleExportApply}
        setVisible={setVisibleExportApply}
        object={"execute"}
      />
    }
    </>
  )
}
