import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { Iconfont, LinkButton, SimpleBreadcrumbs } from 'src/components'
import { getIncludeDir, getUserFiles, UserFile } from 'src/api'
import { clickFile, setClickTreeOrlist, setIsNotClickFile, setPopupVisible, setPwd, setSerachValue, setTreeTag } from './myFolderSlice'
import { Card, Dropdown, Input, Menu, message, Select, Tooltip, Tree, Spin } from 'antd'
import { showModal } from 'src/store/extraSlice/modalVisibleSlice'
import { BigFileUpload } from 'src/components/BigFileUpload/BigFileUpload'
import { SingleFile } from './SingleFile'
import { MyTableDetailPage } from './MyTableDetail'
import { FolderStatusBar } from './FolderStatusBar'
import { NewFolderModal } from './NewFolderModal'
import { ReNameModalPage } from './ReNameModal'
import { MoveeModalPage } from './moveFolderModal'
import { ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons'
import classnames from 'classnames'
import copy from 'copy-to-clipboard'
import styles from './myFolderPage.module.scss'

const { Option } = Select
const { DirectoryTree } = Tree;

interface IDirType {
  key: string
  name: string
}

export const MyFolderPage = () => {
  const dispatch = useDispatch()
  const { pwd, selectedFiles, treeTag, serachValue, connectionName, isNotClickFile } = useSelector((state) => state.myFolder)
  // 默认平铺： tileInfo  详情信息：detailInfo
  const [showFlag, setShowFlag] = useState<string>('tileInfo')
  const [expandedKeys, setExpandedKeys] = useState<any[]>([])
  const [contextMenuType, setContextMenuType] = useState<any>(null)
  const [myHomeDirObj, setMyHomeDirObj] = useState<IDirType>({ key: '', name: '' })
  const [sysDirObj, setSysDirObj] = useState<IDirType>({ key: '', name: '' })
  const [showPathStr, setShowPathStr] = useState<string>('')
  const [homeFlag, setHomeFlag] = useState<boolean>(true)
  const [threeLevelName, setThreeLevelName] = useState<string>('')  // 第三级目录对应的文件名
  const [lastPathLen, setLastPathLen] = useState<number>(0) // 上一次目录的数组长度

  const menu = (
    <Menu selectable style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Menu.Item key={1}>
        <BigFileUpload
          requestParams={{
            upload: {
              path: pwd.path,
            },
          }}
          refresh={() => {
            getUserFilesRun({ path: pwd?.path, condition: serachValue })
            dispatch(setPwd(pwd?.path))
          }}
        >
          <div style={{ width: '80px' }}>上传文件</div>
        </BigFileUpload>
      </Menu.Item>
      {/* <Menu.Item key={2}>
        <BigFileUpload
          requestParams={{
            upload: {
              path: pwd.path,
            },
          }}
          refresh={() => {
            getUserFilesRun({ path: pwd?.path, condition: serachValue })
            dispatch(setPwd(pwd?.path))
          }}
          directory={true}
        >
          <div style={{ width: '80px' }}>上传文件夹</div>
        </BigFileUpload>
      </Menu.Item> */}
    </Menu>
  )


  const Operations = (
    <div className={styles.myFolderPageExtraBtn}>

      <Input
        className={classnames(styles.searchBtn)}
        prefix={<SearchOutlined />}
        placeholder={`在当前文件夹中搜索`}
        onChange={(e) => {
          dispatch(setSerachValue(e?.target?.value))
          getUserFilesRun({ path: pwd?.path, condition: e?.target?.value })
        }}
        value={serachValue}
        allowClear
      />
      <LinkButton
        onClick={() => dispatch(showModal('ModalNewFolder'))}
      >
        <Tooltip title="新建文件夹">
          <Iconfont
            type="icon-new-folder"
            style={{ color: '#0256FF', fontSize: 20 }}
          ></Iconfont>
        </Tooltip>
      </LinkButton>
      <Dropdown overlay={menu}>
        <Iconfont
          type="icon-file-upload"
          style={{ color: '#0256FF', fontSize: 20, cursor: 'pointer' }}
        ></Iconfont>
      </Dropdown>
    </div>
  )


  const { data: filesTree, refresh: getIncludeDirRefresh } = useRequest(() => getIncludeDir('/'), {
    formatResult(data) {
      return setTreeValue(data)
    }
  })

  const { data: files, loading, run: getUserFilesRun, refresh } = useRequest(getUserFiles, {
    manual: true,
    debounceInterval :200,
    formatResult(data) {
      return data
    }
  })

  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys)
  }

  const handleDoubleClick = (file: any) => () => {
    const { type, path, key } = file
    dispatch(setTreeTag(key))
    if (type === 'directory') {
      dispatch(setPwd(path))
      getUserFilesRun({ path: path, condition: serachValue })
      return
    }
  }

  const setTreeValue = (files: any) => {
    const newArr = files?.filter((item: any, index: number) => {
      if (item.isDir) {
        item.key = item?.path
        item.title = item?.name
        if ((item?.isHomeUserDir || item?.isSystemSpaceDir)) {
          if (item?.isHomeUserDir) {
            setMyHomeDirObj({
              name: item?.name,
              key: (item?.key).slice(1, item?.key.length)
            })
          }
          if (item?.isSystemSpaceDir) {
            setSysDirObj({
              name: item?.name,
              key: (item?.key).slice(1, item?.key.length)
            })
          }
          item.children = setTreeValue(item?.children)
          return item
        } else {
          item.children = []
        }
        return item
      }
    })
    return newArr
  }

  const renderMenuItems = () => {
    let menuItem = [
      <Menu.Item key="copyPath" onClick={() => {
        copy(treeTag)
        message.success('复制成功')
      }}>复制文件地址</Menu.Item>
    ]
    if (contextMenuType?.isHomeUserDir) {
      menuItem.unshift(
        <Menu.Item key="newFolder" onClick={() => dispatch(showModal('ModalNewFolder'))}>新建文件夹</Menu.Item>
      )
    }
    if (contextMenuType?.isHomeUserDirChild) {
      menuItem.unshift(
        <Menu.Item key="rename" onClick={() => {
          dispatch(setClickTreeOrlist('tree'))
          dispatch(showModal('ReNameModal'))
        }}>重命名</Menu.Item>,
        <Menu.Item key="newFolder" onClick={() => dispatch(showModal('ModalNewFolder'))}>新建文件夹</Menu.Item>)
    }
    return menuItem
  }

  const doubleClick = (node: any) => {
    dispatch(clickFile(node?.path))
  }

  const backFirstOrder = () => {
    let index1 = treeTag.indexOf('/');
    let index2 = treeTag.indexOf('/', index1 + 1);
    let newStr = treeTag.substring(index1, index2 != -1 ? index2 : treeTag.length)
    dispatch(setPwd(newStr))
    dispatch(setTreeTag(newStr))
    getUserFilesRun({ path: newStr, condition: serachValue })

  }

  const backLaststOrder = () => {
    let index = treeTag.indexOf('/');
    let lastIndex = treeTag.lastIndexOf('/');
    let newStr: any; 
    if (lastIndex !== -1) {
      newStr = treeTag.substring(index, lastIndex != -1 ? lastIndex : treeTag.length)
      dispatch(setTreeTag(newStr))
    }
  }

  const onTitleRender = (node: any) => {
    return (
      <span
        onDoubleClick={handleDoubleClick(node)}
        onClick={handleDoubleClick(node)}
        className='titleSpan'
      >
        {
          node?.isHomeUserDir && <Iconfont type='icon-kongjian' style={{ width: '20px', color: '#2B58BA' }}></Iconfont>
        }
        {
          node?.isSystemSpaceDir && <Iconfont type='icon-kongjian1' style={{ width: '20px', color: '#2B58BA' }}></Iconfont>
        }
        {
          (node?.isSystemSpaceDirChild || node?.isHomeUserDirChild) && <Iconfont type='icon-wenjianjia' style={{ width: '20px' }}></Iconfont>
        }
        {node.title}
      </span>
    )
  }

  const refreshAll = () => {
    return Promise.all([getIncludeDirRefresh(), refresh()])
  }

  useMemo(() => {
    if (filesTree && filesTree.length) {
      let str = pwd?.path ? pwd?.path : filesTree[0]?.key
      dispatch(setTreeTag(str))
      dispatch(setPwd(str))
      getUserFilesRun({ path: str, condition: serachValue })
    } else {
      dispatch(setPwd(''))
    }
  }, [filesTree])

  useEffect(() => {
    setLastPathLen(pwd?.path.split('/').length - 1)
  }, [pwd?.path])

  useEffect(() => {
    if (!pwd?.path) return
    const paths = (pwd?.path).split('/').filter(Boolean)
    if (!paths[0]) return
    let str = ''
    if (paths.includes(myHomeDirObj?.key)) {
      str = `${paths.join('>').replace(myHomeDirObj?.key, myHomeDirObj?.name)}`
      setHomeFlag(true)
    }
    if (paths.includes(sysDirObj?.key)) {
      if (paths.length === 3) {
        setThreeLevelName(connectionName)
        paths[2] = connectionName
      } 
      if (paths.length >= 3) {
        paths[2] = threeLevelName
      }
      str = paths.join('>').replace(sysDirObj?.key, sysDirObj?.name)
      setHomeFlag(false)
    }
    setShowPathStr(str)
  }, [pwd?.path, threeLevelName, lastPathLen])

  // 点击空白区域，对应的文件夹取消选中状态
  const setNotChoose = (event: any) => {
    if (!(
      event.target.closest('.singleFile') || // rightWrap 文件夹
      event.target.closest('.chooseFile') || // 下拉框
      event.target.closest('.myTableWrap')  // table
      || event.target.closest('.ant-modal') // 弹窗
      || event.target.closest('.titleSpan') // tree title span
    )) {
      // 去掉文件的选中状态
      dispatch(setIsNotClickFile(true))
      backLaststOrder()
    }
  }

  const breadcrumbData = [
    { title: "个人中心" },
    {
      title: "个人文件夹",
    },
  ];
  return (
    <div className={styles.myfolderPageWrap}>
      <div className={styles.breadcrumbLine}>
        <SimpleBreadcrumbs items={breadcrumbData} />
        <Select
          className={styles.selectWrap}
          value={showFlag}
          bordered={false}
          onChange={(e) => {
            setShowFlag(e)
          }}
        >
          <Option value="detailInfo"><Iconfont type='icon-liebiaoshitu' style={{ fontSize: '16px', color: '#767676' }} />详情信息</Option>
          <Option value="tileInfo"><Iconfont type='icon-keshishitu' style={{ fontSize: '16px', color: '#767676' }} />平铺</Option>
        </Select>
      </div>
      <div className={styles.contentWrap}>
        <div className={styles.leftWrap} onClick={(event: any) => setNotChoose(event)}>
          {/* <div className={classnames(styles.fs14, styles.mb10, styles.ml10)} >
            系统设置
          </div> */}
          <Dropdown
            overlay={
              <Menu >
                {renderMenuItems()}
              </Menu>
            }
            trigger={['contextMenu']}
            onVisibleChange={(visible) => {
              if (!visible) {
                setContextMenuType(null)
              }
            }}
          >
            <DirectoryTree
              className={styles.treeWrap}
              autoExpandParent={true}
              showIcon={false}
              // blockNode={true}
              expandedKeys={expandedKeys}
              onExpand={onExpand}
              selectedKeys={[treeTag]}
              onRightClick={(e) => {
                doubleClick(e?.node)
                dispatch(setTreeTag(e?.node?.key))
                setContextMenuType(e?.node)
                dispatch(setPopupVisible(false))
              }}
              titleRender={onTitleRender}
              treeData={filesTree || []}
					    expandAction={false}
            />
          </Dropdown>
        </div>
        <div className={styles.rightWrap}>
          <Spin spinning={loading}>
            <Card
              title={
                <>
                  <div className={styles.fs14} style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{ border: '1px solid #DFE1E6', padding: '6px 20px', borderRadius: '4px' }}>
                      <LinkButton onClick={() => backFirstOrder()}>
                        <Iconfont className={styles.iconIdentify} type={homeFlag ? 'icon-kongjian' : 'icon-kongjian1'} />
                      </LinkButton>
                      {showPathStr}
                    </div>
                    <LinkButton>
                      <Tooltip title="返回上级">
                        <ArrowLeftOutlined style={{ color: '#767676', fontSize: '16px' }} onClick={() => {
                          let str = pwd?.path.lastIndexOf('/')
                          if (str) {
                            dispatch(setPwd(pwd?.path.substring(0, str)))
                            dispatch(setTreeTag(pwd?.path.substring(0, str)))
                            getUserFilesRun({ path: pwd?.path.substring(0, str), condition: serachValue })
                          }
                        }} />
                      </Tooltip>
                    </LinkButton>
                  </div>
                </>
              }
              extra={Operations}
              bodyStyle={{ padding: 0, overflowY: 'auto' }}
              bordered={false}
              className={styles.cardUi}
            >
              <div
                className={styles.fileZone}
                onClick={(event: any) => {dispatch(clickFile(treeTag)); setNotChoose(event)}}
              >
                {
                  showFlag === 'detailInfo' && <MyTableDetailPage files={files} refresh={refreshAll} />
                }
                {
                  showFlag === 'tileInfo' && <div className={styles.fileGroup}>
                    {files?.map((file: UserFile, index: number) => {
                      return (
                        <SingleFile
                          file={file}
                          selected={isNotClickFile? false:selectedFiles.some((path) => path === file.path)}
                          key={index}
                          refresh={refreshAll}
                          lastPathLen={lastPathLen}
                        />
                      )
                    })}
                  </div>
                }
              </div>
              <FolderStatusBar filePaths={selectedFiles} />
            </Card>
          </Spin>
        </div>
        <NewFolderModal refresh={getIncludeDirRefresh} />
        <ReNameModalPage refresh={getIncludeDirRefresh} />
        <MoveeModalPage refresh={getIncludeDirRefresh} />
      </div>

    </div>
  )
}
