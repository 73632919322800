import React, { useEffect } from "react";
import { Space, Tree } from "antd";
import styles from "../index.module.scss";
import { useDispatch, useSelector } from "src/hook";
import { setCurKey } from "src/store/extraSlice/useMesSlice";
import { treeData } from "src/constants";

const { DirectoryTree } = Tree;

const SdtTree = (): any => {

  const dispatch = useDispatch()
  const curKey: string[] = useSelector((state) => state.message.curKey) || ''

  useEffect(() => {
    dispatch(setCurKey(['allMessage']))
  }, [])

	return (
		<div className={styles.treeContainer}>
			<div className={styles.treeHeader}>
				<Space>
					<span style={{ color: '#0F244C' }}>消息中心</span>
				</Space>
			</div>
				<DirectoryTree
					className={styles.sdtTree}
					titleRender={(node: any) => {
						return `${node.title}`;
					}}
          icon={false}
          selectedKeys={curKey}
					treeData={treeData}
					onSelect={(_key: any, { node }: any) => {
            dispatch(setCurKey(_key))
					}}
				></DirectoryTree>
		</div>
	);
};

export { SdtTree };