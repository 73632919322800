import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { BLOCK_SIZE, EOL_SEQUENCE_TYPE } from 'src/constants'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import {
  editor,
  IPosition,
  IRange,
  IScrollEvent,
  ISelection,
  Uri,
  Range,
} from 'monaco-editor'
import {
  useEditorInstance,
  QueryPool,
  QueryPoolContext,
  BaseEditor,
  ChangeModelContentEvent,
  getModelLanguageId,
  Iconfont,
} from 'src/components'
import Watermark from '@pansy/react-watermark'
import { MonacoToolbar } from './monacoToolbar'
import { customizeLanguage } from './customizeLanguage'
import debounce from 'lodash/debounce'
import {
  activeMonacoPaneInfoSelector,
  DatabaseInfo,
  executeEditorSql,
  explainEditorSql,
  PaneInfo,
  setTabExecutionStatus,
  updateMonaco,
  updatePaneInfoAboutErrormark,
  saveExecuteActiveTabParams,
  saveExecuteActiveTabInfo, 
  setTabExecutionStatusPercentage,
  addPane
} from 'src/pageTabs/queryPage/queryTabs/queryTabsSlice'

import {
  SegmentMsg,
  setErrorJump,
  setLastErrorPosition,
} from 'src/pageTabs/queryPage/resultTabs/resultTabsSlice'
import projectConfigService from 'src/projectConfig'
import {
  calculateErrorPositionInFullText,
  getOperatingObject,
  getSqlToExecute,
  SqlSplitter,
  TransactSqlSplitter,
} from 'src/util'
import { ResizableBox } from 'react-resizable'
import { executeSqlSegment, getDataSourceDescription, getUserConfig, getWatermarkValue,
  getConnectionInfoFromSqlSegment
} from 'src/api'
import {
  resetResultList,
  resetSegmentMsg,
} from '../../resultTabs/resultTabsSlice'
import { setHotKeys } from 'src/store/extraSlice/settingSlice'
import { useLanguageClientContext } from 'src/components/BaseEditor/useLSP'
import styles from './index.module.scss'
import SqlExecuteAuditeModal from "./SqlExecuteAuditeModal"
import { Alert, message } from 'antd'
import classnames from 'classnames'
import { setVisibleAlert } from 'src/appPages/login/loginSlice'

interface MonacoPaneProps {
  modelCache: Map<string, editor.ITextModel>
  theme?: 'dark' | 'light'
  isCreateView?: boolean    // 创建视图\创建存储过程\创建函数
  [p: string]: any
}

export const MonacoPane: React.FC<MonacoPaneProps> = (props) => {
  const { theme = 'light', modelCache, isCreateView } = props
  const activePaneInfo = useSelector(activeMonacoPaneInfoSelector)
  const dispatch = useDispatch()
  const [editorInstance] = useEditorInstance()
  const dataSourceDescInfo = useSelector((S) => S.dataSource.dataSourceMap)
  const clientId = useSelector((state) => state.login.userInfo.sessionId)
  const userId = useSelector((state) => state.login.userInfo.userId)
  const activeTabKey = useSelector((state) => state.queryTabs.activeTabKey)
  const visibleAlert = useSelector((state) => state.login.visibleAlert) // 编辑器执行行数提示
  const [paneHeight, setPaneHeight] = useState(200)

  useEffect(() => {
    if (!editorInstance) return
    if (activePaneInfo?.['cursorPosition']) {
      editorInstance.setPosition(activePaneInfo?.['cursorPosition'])
      editorInstance.focus()
    }
    if (activePaneInfo?.scrollTop) {
      editorInstance.setScrollTop(activePaneInfo?.scrollTop)
    }
  }, [activeTabKey])

  // 获取个人设置中的hotKeys
  const { run: tryGetUserConfig } = useRequest(getUserConfig, {
    manual: true,
    onSuccess: ({ editorPromptOnClose, hotKeys }) => {
      if (!!hotKeys) {
        const { execute, execPlan, prompt } = hotKeys
        !!execute && dispatch(setHotKeys({ execute: execute }))
        !!execPlan && dispatch(setHotKeys({ execPlan: execPlan }))
        !!prompt && dispatch(setHotKeys({ prompt: prompt }))
      }
    },
  })

  useEffect(() => {
    tryGetUserConfig()
  }, [tryGetUserConfig])

  // 阻止触发 editor onDidContentChange 事件的标志位
  const preventTriggerContentChange = useRef<boolean>(false)
  const {
    key,
    value,
    connectionId,
    databaseName,
    language = 'sql',
    connectionType,
    execValueRange,
    hasCommitExecError = false,
    pending,
    schemaName,
    prepared,
    autoRun,
  } = activePaneInfo || {}

  const targetModel = useMemo(() => {
    if (!key) return null
    // 在 cache 中查找已有的 model, 如果没有则创建并存储 model
    let target: editor.ITextModel
    const cachedModel = modelCache.get(key)
    if (cachedModel) {
      target = cachedModel
    } else {
      target = editor.createModel(
        '',
        connectionType && getModelLanguageId(connectionType),
        Uri.parse(`inmemory://${clientId}/${key}`),
      )
      modelCache.set(key, target)
    }
    return target
  }, [key, connectionType, modelCache, clientId])

  const languageClient = useLanguageClientContext()

  const clearModelDecWithSelect = () => clearModelDecorations(targetModel)

  //  清除错误信息的方法
  const clearErrorMask = useCallback(() => {
    dispatch(updatePaneInfoAboutErrormark({}))
    if (targetModel) {
      clearModelDecorations(targetModel)
    }
  }, [dispatch, targetModel])

  useEffect(() => {
    // 初始化 model 内容
    if(!editorInstance || !targetModel) {
      return
    }
    if (value !== undefined && !targetModel?.getValue()) {
      handlePushEdit(targetModel, value)
    }
    function handlePushEdit(model: editor.ITextModel, value: string): void {
      editorInstance?.executeEdits("", [
        {
          range: model.getFullModelRange(),
          text: value,
          forceMoveMarkers: true
        }
      ]) 
      if(isCreateView){
        editorInstance?.getAction('editor.action.formatDocument').run()
      }
    }
  }, [value, targetModel, editorInstance])

  // 声明一个 mutable modelOptionRef 存放当前 modelOption, 避免 useCallback deps 数组引入 modelOption
  // 作用类似 class component 的实例属性, 使注册的事件触发时, 取到的是当前 modelOption 而不是闭包中的
  const modelOptionRef = useRef<PaneInfo | undefined>(undefined)
  useEffect(() => {
    modelOptionRef.current = activePaneInfo
  }, [activePaneInfo])

  /** 编辑器更改 model 内容的事件处理函数 */
  const handleChangeModelContent = useMemo(() => {
    const wrapped: ChangeModelContentEvent = (editor) => {
      if (modelOptionRef.current && !preventTriggerContentChange.current) {
        const { key } = modelOptionRef.current
        dispatch(updateMonaco({ key, value: editor.getValue() }))
        // 防止初始化赋值触发onDidContentChange将标红失效
        if(value !== editor.getValue()) {
          clearModelDecorations(targetModel)
        }
      }
    }
    return debounce(wrapped, 300)
  }, [dispatch, targetModel])

  const handleChangeCursorPosition = useMemo(() => {
    const wrapped = (position: IPosition) => {
      if (modelOptionRef.current) {
        const { key } = modelOptionRef.current
        const cursorPosition = { ...position }
        dispatch(updateMonaco({ key, cursorPosition }))
      }
    }
    return debounce(wrapped, 300)
  }, [dispatch])

  const handleChangeScrollTop = useMemo(() => {
    const wrapped = (e: IScrollEvent) => {
      if (modelOptionRef.current) {
        const { key } = modelOptionRef.current
        dispatch(updateMonaco({ key, scrollTop: e.scrollTop }))
      }
    }
    return debounce(wrapped, 300)
  }, [dispatch])

  /* 是否真实选中 */
  /* selection 既可以指选中区域，也可以指光标 */
  const isRealSelection = (selection?: ISelection | null) => {
    if (!selection) return false
    const {
      selectionStartLineNumber,
      selectionStartColumn,
      positionLineNumber,
      positionColumn,
    } = selection
    if (
      selectionStartLineNumber === positionLineNumber &&
      selectionStartColumn === positionColumn
    )
      return false
    return true
  }

  /* 执行前重置错误提示 */
  const updateErrormarkInfo = useCallback(
    (selection?: ISelection | null) => {
      let execValueRange = undefined
      if (selection && isRealSelection(selection)) {
        const {
          selectionStartLineNumber,
          selectionStartColumn,
          positionLineNumber,
          positionColumn,
        } = selection
        execValueRange = {
          startColumn: selectionStartColumn,
          endColumn: positionColumn,
          startLineNumber: selectionStartLineNumber,
          endLineNumber: positionLineNumber,
        }
      }
      dispatch(
        updatePaneInfoAboutErrormark({
          execValueRange: execValueRange,
          hasCommitExecError: false,
          editorExecErrorMark: [],
        }),
      )
    },
    [dispatch],
  )
  /** query pool */
  const poolMap = useContext(QueryPoolContext)

  const { run: execSegment } = useRequest(executeSqlSegment, {
    manual: true,
    onSuccess: (data, params) => {
      key && dispatch(saveExecuteActiveTabInfo({ key, data }))
      key && dispatch(setTabExecutionStatusPercentage({key: key, executePercentage: data.executePercentage, executeStatusMessage: data.executeStatusMessage}));
    },
    /* 当接口报错时，需手动结算执行状态 */
    onError: (e) => {
      dispatch(setTabExecutionStatus({ key: activePaneInfo?.key as string, pending: false }))
      dispatch(setTabExecutionStatusPercentage({key: activePaneInfo?.key as string, executePercentage: 100, executeStatusMessage: '已执行结束'}));
    },
  })

  /** 执行 sql 语句的事件处理函数 */
  /** flashbackSql是为了传入闪回语句使用，如果传入了就是优先级较高 */
  const handleExecuteSql = useCallback((flashbackSql?: string): void => {
    const modelOption = modelOptionRef.current
    if (!modelOption || !editorInstance) return
    const { connectionId, connectionType, databaseName, key, plSql, tSql } =
      modelOption
    const sqlToExecute = flashbackSql || getSqlToExecute(editorInstance)

    // 缺少执行的必须条件（上下文和语句）
    if (!connectionId || !connectionType || !sqlToExecute) return
    // 闪回先支持四大数据源，这里先不加

    // 分块执行
    // 重置结果信息和结果集
    dispatch(resetSegmentMsg(key))
    dispatch(resetResultList(key))
    // 开启一轮新查询
    dispatch(setTabExecutionStatus({ key, pending: true }))
    //在开启查询后 去除上一次的错误提示
    clearErrorMask()
    // 进入query pool 逻辑
    const pool = new QueryPool(
      (params) => {
        return execSegment({
          offset: 0,
          rowCount: BLOCK_SIZE,
          ...params,
        })
      },
      {
        connectionId,
        dataSourceType: connectionType,
        operatingObject: getOperatingObject(
          { databaseName, schemaName },
          connectionType,
        ),
        databaseName,
        tabKey: key,
        plSql,
        tSql,
        autoCommit: activePaneInfo?.txMode === 'auto',
        isFlashbackAction: flashbackSql ? true : undefined,
      },
    )
    pool.query(sqlToExecute)
    poolMap?.set(key, pool)
    //存储执行语句参数 申请命令复核需要这些参数
    dispatch(saveExecuteActiveTabParams({
      connectionId,
      dataSourceType: connectionType,
      operatingObject: getOperatingObject(
        { databaseName, schemaName },
        connectionType,
      ),
      databaseName,
      tabKey: key,
      plSql,
      tSql,
      offset: 0,
      rowCount: BLOCK_SIZE,
      statements: pool.getQuerysegments(),
      autoCommit: activePaneInfo?.txMode === 'auto',
    }))

    const selection = editorInstance.getSelection()
    updateErrormarkInfo(selection)
  }, [
    dispatch,
    editorInstance,
    execSegment,
    poolMap,
    schemaName,
    updateErrormarkInfo,
    activePaneInfo?.txMode,
  ])

  //查看表结构
  const getViewTableStructure = async() => {
       
    const modelOption = modelOptionRef.current
    if (!modelOption || !editorInstance) return
    const { connectionId, connectionType, databaseName } = modelOption;

    const position: any = editorInstance.getPosition();
    const sqlToExecute =  getSqlToExecute(editorInstance)
    //查看表结构 index 光标在整个文本中位置
    const index = position && editorInstance.getModel()?.getOffsetAt(position)
    const lineCount = editorInstance?.getModel()?.getLineCount() || 1;
    const curLineNumber = position?.lineNumber || 1;
    //当前行语句
    const sql =  editorInstance?.getModel()?.getLineContent(curLineNumber);

    if (sql?.trim() !== "" && lineCount >= curLineNumber) {

      try {
        const connectionInfo = await getConnectionInfoFromSqlSegment({
          connectionId: connectionId || null,
          dataSourceType: connectionType || null,
          databaseName,
          schemaName,
          //@ts-ignore
          operatingObject: getOperatingObject({databaseName, schemaName}, connectionType),
          charPositionInText: index - 1,
          line: position?.lineNumber || 1,
          column: position?.column || 1,
          text:sqlToExecute
        })
     
        if(connectionInfo?.findDefinition) {
          onOpenViewTablleStructrePanel(connectionInfo)
          return 
        }
       
      } catch (error) {
        
      }
    }
    message.info('未找到定义')
 }

  const onOpenViewTablleStructrePanel = (connectionInfo: any) => {
    dispatch(
      addPane({
        tabName: connectionInfo?.tableName,
        paneType: 'viewTableStructure',
        connectionId: connectionInfo?.connectionId,
        connectionType: connectionInfo?.connectionType,
        databaseName: connectionInfo?.databaseName,
        nodePath: connectionInfo?.nodePath,
        nodeName: connectionInfo?.tableName,
        nodeType:'table',
      }),
    )
  }

  /** 执行计划 */
  const handleExplainSql = useCallback(() => {
    if (!editorInstance) return
    const text = getSqlToExecute(editorInstance)
    dispatch(explainEditorSql(text))
  }, [dispatch, editorInstance])

  useEffect(() => {
    // switch theme
    editor.setTheme(theme)
  }, [theme])

  useEffect(() => {
    if (!targetModel || !connectionType) return
    const modelLanguageId = getModelLanguageId(connectionType)
    let connectionContext: DatabaseInfo | undefined
    if (connectionId && connectionType) {
      connectionContext = {
        connectionId,
        connectionType,
        databaseName,
        schemaName,
      }
    }
    // register language/completion
    const disposers = customizeLanguage(
      modelLanguageId,
      dataSourceDescInfo,
      connectionContext,
    )
    editor.setModelLanguage(targetModel, language)

    return () => disposers.forEach((disposer) => disposer.dispose())
  }, [
    connectionId,
    connectionType,
    dataSourceDescInfo,
    databaseName,
    language,
    schemaName,
    targetModel,
  ])

  useEffect(() => {
    queryPaneHeight()
  },[])

  // 监听页面大小变化
  window.onresize = () => {
    queryPaneHeight()
  }

  // 动态设置面板高度
  const queryPaneHeight = () => {
    const height = window.innerHeight
    const paneHeight = height > 468 ? height - 268 : 200
    setPaneHeight(paneHeight)
  }

  // resize
  const ResizeHandle = (
    <div className={styles.resizeHandle}>
      <Iconfont type="icon-handle-8-horizontal"></Iconfont>
    </div>
  )

  // watermark
  const { watermarkSetting, userName } = useSelector(
    (state) => state.login.userInfo,
  )

  const { data: vatermarkvalue } = useRequest(getWatermarkValue)

  const onPreCommit = () => {
    if (!editorInstance || !targetModel) return
    const sql = targetModel.getValue()
    const tSplitter = new TransactSqlSplitter(sql)
    tSplitter.split()
    const blocks = tSplitter.getBlocks()
    const range: IRange[] = blocks.map(({ start, end }) => {
      const startPosition = targetModel.getPositionAt(start)
      const endPosition = targetModel.getPositionAt(end)
      return {
        startLineNumber: startPosition.lineNumber,
        startColumn: startPosition.column,
        endLineNumber: endPosition.lineNumber,
        endColumn: endPosition.column,
      }
    })
    highlightBlocks(editorInstance, targetModel, range)
  }

  /* 获取执行错误记录 */
  const segmentMsgList = useSelector((state) => state.resultTabs.segmentMsg)
  const segmentPositionMsgList = useSelector((state) => state.resultTabs.segmentPositionMsg)
  const errorJump = useSelector((state) => state.resultTabs.errorJump)
  const isRun = useSelector((state) => state.resultTabs.isRun)
  const lastErrorPosition = useSelector((state) => state.resultTabs.lastErrorPosition)
  // model中的 文本 标红部分的 起始行列
  useEffect(() => {
    if (!key) return
    const activeSegmentMsgList = segmentMsgList[key] || []
    const errorSegmentMsgList = activeSegmentMsgList.filter(
      (msg) => !msg.success,
    )
    if (
      targetModel &&
      lastErrorPosition &&
      !(lastErrorPosition.startLineNumber === 0 && lastErrorPosition.endLineNumber === 0 && lastErrorPosition.startColumn === 0 && lastErrorPosition.endColumn === 0) &&
      (!hasCommitExecError || errorJump)
    ) {

      const selectRange = (activePaneInfo?.execValueRange) ? (activePaneInfo?.execValueRange) : targetModel.getFullModelRange()
      /* 当所选范围中的sql语句，第一条sql语句前有空格行时，要进行+，因为传到后端的sql语句没有包含这些空格行，计算错误sql语句位置时会出错 */
      let leadingWhitespaceLines = 0;
      if (selectRange && !errorJump && isRun) {
        // 获取选中范围之前的文本
        const beforeText = targetModel?.getValueInRange(selectRange);

        // 以换行符分割文本为行数组
        const lines = beforeText.split('\n');

        // 计算空白行数
        for (let i = 0; i < lines.length; i++) {
          if (lines[i].trim() === '') {
            leadingWhitespaceLines++;
          } else {
            break;
          }
        }
      }



      let positionJson:
        {
          endColumn: number
          endLineNumber: number
          startColumn: number
          startLineNumber: number
        } = {
        "endColumn": 0,
        "endLineNumber": 0,
        "startColumn": 0,
        "startLineNumber": 0
      }

      // 当前执行的sql语句所在的tab的key
      const queryTabKey = activePaneInfo?.key
      if (queryTabKey) {
        if (queryTabKey in lastErrorPosition && !!lastErrorPosition[queryTabKey]) {
          positionJson = lastErrorPosition[queryTabKey]
        }
      }
      // 点击滚动定位
      if (errorJump) {
        dispatch(setErrorJump(false))
      }

      if (positionJson &&
        !(positionJson.startLineNumber === 0 &&
          positionJson.endLineNumber === 0 &&
          positionJson.startColumn === 0 &&
          positionJson.endColumn === 0)) {
        let editorExecErrorMarkRange: any =
          [new Range(positionJson.startLineNumber,
            positionJson.startColumn,
            positionJson.endLineNumber,
            positionJson.endColumn)]
        // 执行过后，所选范围全为 全局, 更新 json 中的position为相对于 全局范围,而非所选sql语句的范围
        // 而切换 tab、点击滚动定位，则不需要再重新进行位置的计算
        if (isRun) {
          positionJson = calculateErrorPositionInFullText(targetModel.getFullModelRange(), selectRange, positionJson)
          positionJson = {
            "startLineNumber": positionJson.startLineNumber + leadingWhitespaceLines,
            "startColumn": positionJson.startColumn,
            "endLineNumber": positionJson.endLineNumber + leadingWhitespaceLines,
            "endColumn": positionJson.endColumn,
          }
          editorExecErrorMarkRange =
            [new Range(positionJson.startLineNumber,
              positionJson.startColumn,
              positionJson.endLineNumber,
              positionJson.endColumn)]

          // 执行过后，所选范围全为 全局, 更新 json 中的position为相对于 全局范围,而非所选sql语句的范围
          if (queryTabKey) {
            if (queryTabKey in lastErrorPosition && !!lastErrorPosition[queryTabKey]) {
              let tmp = { ...lastErrorPosition }
              tmp[queryTabKey] = positionJson
              dispatch(
                setLastErrorPosition(tmp)
              )
            }
          }
        } else {
          dispatch(setErrorJump(false))
        }

        // 标红
        if (editorExecErrorMarkRange[0] && editorInstance && editorExecErrorMarkRange[0]) {
          commitErrorMark(editorInstance, targetModel, editorExecErrorMarkRange)

          dispatch(
            updatePaneInfoAboutErrormark({
              execValueRange,
              hasCommitExecError: true,
              // editorExecErrorMark,
              editorExecErrorMarkRange,
            }),
          )
        }
      }
    }
  }, [
    activePaneInfo,
    dispatch,
    editorInstance,
    execValueRange,
    hasCommitExecError,
    key,
    pending,
    segmentMsgList,
    targetModel,
    errorJump,
    lastErrorPosition,
  ])

  useEffect(() => {
    if (
      !targetModel ||
      !connectionType ||
      !languageClient ||
      !languageClient.enhancedApi?.didChangeLanguageContext
    )
      return
    const modelLanguageId = getModelLanguageId(connectionType)
    editor.setModelLanguage(targetModel, modelLanguageId)
    languageClient.enhancedApi?.didChangeLanguageContext({
      connectionId: connectionId || '',
      dataSourceType: connectionType,
      languageType: connectionType,
      operatingDatabase: databaseName,
      operatingSchema: schemaName,
      userId,
    } as any)
  }, [
    connectionId,
    connectionType,
    databaseName,
    languageClient,
    schemaName,
    targetModel,
    userId,
  ])

  useEffect(() => {
    // 执行时重置 errormark
    clearErrorMask()
  }, [dispatch, targetModel])

  // sdt 打开表，在切库完成后自动执行语句
  useEffect(() => {
    // 切换执行窗口，显示 执行行数提示
    // setVisibleAlert(true)
    if (key && editorInstance && autoRun && prepared) {
      dispatch(updateMonaco({ key, autoRun: false }))
      //等待窗口初始化，延迟执行，窗口初始化的时候会更改状态，如果直接调用执行会出现状态变化的顺序不对
      setTimeout(() => {
        dispatch(executeEditorSql(getSqlToExecute(editorInstance)))
      }, 500);
    }
  }, [autoRun, dispatch, editorInstance, key, prepared])

  // 数据库描述
  const { data: dataSourceDescription, run: fetchDescription } = useRequest(
    getDataSourceDescription,
    { manual: true },
  )

  useEffect(() => {
    if (connectionId && connectionType) {
      fetchDescription(connectionType)
    }
  }, [connectionId, connectionType, fetchDescription])
  return (
    <section className={styles.queryEditor}>
      <MonacoToolbar
        onPressExecute={(flashbackSql) => handleExecuteSql(flashbackSql)}
        clearModelDec={clearModelDecWithSelect}
        onPreCommit={onPreCommit}
        supportTransaction={dataSourceDescription?.supportTransaction}
        oneLibraryAndOneConnection={dataSourceDescription?.oneLibraryAndOneConnection}
        isCreateView={isCreateView}
      />
      {
        (!isCreateView && visibleAlert) 
        ? <Alert
            message='编辑器最大执行行数建议不超过10000行'
            type='warning'
            closable
            onClose={() => {dispatch(setVisibleAlert(false))}}
            closeText='关闭'
            className={classnames(theme === 'dark'?'darkBg' : 'lightBg', 'editorContainerAlert')}
          />
        : null
      }
      {/* https://github.com/STRML/react-resizable/issues/69 */}
      <ResizableBox
        handle={ResizeHandle}
        height={192}
        width={Infinity}
        minConstraints={[Infinity, 48]}
        maxConstraints={[Infinity, paneHeight]}
      >
        <>
          {watermarkSetting && (
            <Watermark
              {...projectConfigService.waterMarkConfig}
              text={vatermarkvalue && vatermarkvalue?.length ? vatermarkvalue.join(' ') : ''}
              zIndex={99}
              opacity={theme === 'dark' ? 0.4 : 0.2}
            />
          )}
          <BaseEditor
            className={styles.editorContainer}
            model={targetModel}
            onExecuteSelectedText={handleExecuteSql}
            onChangeModelContent={handleChangeModelContent}
            onChangeCursorPosition={handleChangeCursorPosition}
            handleChangeScrollTop={handleChangeScrollTop}
            onCommandExplain={handleExplainSql}
            getViewTableStructure={getViewTableStructure}
          />
        </>
      </ResizableBox>
      {/* sql审核结果弹框内容 */}
      {
        key && 
        <SqlExecuteAuditeModal 
          activePaneKey={key}
          execSegment={execSegment}
        />
      }
    </section>
  )
}

/* handle EOL */
/* todo hack */
const transStatementByEOL = (
  statement: string,
  paneEOL?: EOL_SEQUENCE_TYPE,
) => {
  if (!paneEOL || !statement) return statement
  const isCRLF = /\/r\/n/g.test(statement)
  if (isCRLF && paneEOL === 'LF') {
    return statement.replace(/\n/g, '\r\n')
  } else if (!isCRLF && paneEOL === 'CRLF') {
    return statement.replace(/\r\n/g, '\n')
  }
  return statement
}


/* calculate range list */
/* 执行计划也使用 QueryPool 处理后，可以合并到QueryPool中*/
const calculateErrorRangeList = (
  model: editor.ITextModel,
  errorStatementIndex: number,
  paneInfo?: PaneInfo,
): IRange[] => {
  const {
    execValueRange: selectRange,
    endOfLine: paneEOL,
    plSql,
    tSql,
  } = paneInfo || {}
  const scanRange = selectRange ? selectRange : model.getFullModelRange()
  /* 0 复原执行的 statement list*/
  const sqlText = model.getValueInRange(scanRange)
  let execStatementList: string[]
  if (tSql) {
    const splitter = new TransactSqlSplitter(sqlText)
    execStatementList = splitter.split()
  } else if (plSql) {
    execStatementList = [sqlText]
  } else {
    const splitter = new SqlSplitter(sqlText)
    execStatementList = splitter.splitWithComment()
  }

  /* 1 获取错误语句内容 */
  const errorText = transStatementByEOL(
    execStatementList[errorStatementIndex],
    paneEOL,
  )
  if (!errorText) {
    console.error(`未获取到原错误语句内容,索引${errorStatementIndex}`)
    return []
  }

  /* 2 获取选中范围匹配错误语句 list */
  /* todo 带优化 */
  const matchStatementRangeList = model.findMatches(
    errorText,
    scanRange,
    false,
    true,
    null,
    true,
  )
  /* 3 双指针匹配到对应错误语句，返回 range */
  let matchErrorRange: IRange | undefined = undefined
  let p1 = 0
  let p2 = 0
  while (p1 <= execStatementList.length && !matchErrorRange) {
    const currStatement = execStatementList[p1]
    const isMatchedStatement = p1 === errorStatementIndex
    if (isMatchedStatement) {
      matchErrorRange = matchStatementRangeList[p2]?.range
      /* todo 当前只要求查询一个 error，查询到即 break */
      break
    }
    if (currStatement === errorText) {
      p2++
    }
    p1++
  }
  return matchErrorRange ? [matchErrorRange] : []
}

/* commit error mark */
const commitErrorMark = (
  editorInstance: editor.IStandaloneCodeEditor | null,
  model: editor.ITextModel,
  // editorExecErrorMark: IRange[],
  editorExecErrorMarkRange: IRange[],
) => {
  if (!editorInstance || !editorExecErrorMarkRange[0]) return
  const waitCommitRange = editorExecErrorMarkRange[0]
  /* 0 错误语句标红 */
  model.deltaDecorations(
    [],
    editorExecErrorMarkRange.map(
      (range: IRange): editor.IModelDeltaDecoration => ({
        range: range,
        options: {
          inlineClassName: 'editor-exec-error-markline',
        },
      }),
    ),
  )
  /* 1 滚动到对应编辑,距离顶部两行 */
  const { startLineNumber } = waitCommitRange
  const scrollPositon = editorInstance.getScrolledVisiblePosition({
    lineNumber: startLineNumber > 2 ? startLineNumber - 2 : startLineNumber,
    column: 0,
  })
  editorInstance.setScrollPosition({
    scrollTop: startLineNumber == 0 ? scrollPositon?.top : (scrollPositon?.top ? scrollPositon?.top : 0) + editorInstance.getScrollTop(),
    scrollLeft: 0,
  })
  /* 2 光标定位到错误语句 */
  editorInstance.setSelection(waitCommitRange)
}

/* model 清空标记 */
const clearModelDecorations = (model: editor.ITextModel | null) => {
  if (!model) return
  // 若 model 已经 dispose 保护性退出 不执行getAllDecorations 防止报错
  if (model.isDisposed()) return
  const currDecotation = model.getAllDecorations()
  if (!currDecotation || !currDecotation[0]) return
  const currDecotationIdList = currDecotation
    .filter((it) =>
      ['editor-highlight', 'editor-exec-error-markline'].includes(
        it.options.inlineClassName || '',
      ),
    )
    ?.map((it) => it.id)
  model.deltaDecorations(currDecotationIdList, [])
}

/* 计算错误语句在 statement 中的索引 */
const calculateErrorStatementIndex = (errorSegmentMsgList: SegmentMsg[]) => {
  if (!errorSegmentMsgList || !errorSegmentMsgList[0]) {
    return
  }
  const { statementIndex, segmentIndex } = errorSegmentMsgList[0]
  const offset = segmentIndex + statementIndex
  return offset
}

/* TSQL 分块高亮 */
const highlightBlocks = (
  editorInstance: editor.IStandaloneCodeEditor | null,
  model: editor.ITextModel,
  blocksRange: IRange[],
) => {
  if (!editorInstance || !blocksRange[0]) return
  model.deltaDecorations(
    [],
    blocksRange.map(
      (range: IRange): editor.IModelDeltaDecoration => ({
        range: range,
        options: {
          inlineClassName: 'editor-highlight',
        },
      }),
    ),
  )
}
