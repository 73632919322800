import React, { useEffect, useState } from 'react'
import { SettingOutlined } from '@ant-design/icons';
import { useSelector, useRequest } from 'src/hook'
import { Badge, Card,Table, Tag, Tooltip, Button, Typography } from 'antd'
import {  
  getPermissionDetailById,
  getAndSetAuditColumn,
  getAuthDashboardPersonal
} from 'src/api'
import { Iconfont } from 'src/components'
import {
  SQL_OPERATE_TYPES,
  USER_AUDIT_AUTH_DETAIL_DEFAULT_COLUMN,
  USER_AUDIT_AUTH_DETAIL_COLUMN,
  PERMISSION_TYPE,
  NODE_TYPE,
} from 'src/constants'
import CustomColumnsDrawer from 'src/pageTabs/audit/components/CustomColumnsDrawer';
import PermissionDetailModal from 'src/pageTabs/audit/permissionPage/modals/PermissionDetailModal'
interface PaginationIProps {
	current: number,
	pageSize: number,
	total: number
}

export const UserConnections = () => {

  const defaultPaginationParams = {
    current: 1,
    pageSize: 10,
    total: 0
  }
  const [visiblePermissionDetailModal,setVisiblePermissionDetailModal] = useState(false);
  const [modalVisible, setModalVisible] = useState(false)
  const [columns, setColumns] = useState<any[]>([])
  const [permissionDetaiModalData, setPermissionDetaiModalData] = useState<any>();
	const [pagination, setPagination] = useState<PaginationIProps>(defaultPaginationParams)

  const {
    userInfo: { userId },
  } = useSelector((state) => state.login)

  // 权限看板查询接口
  const { data: dataSource, run: getAuthDashboardDetail, loading } = useRequest(getAuthDashboardPersonal, {
		manual: true,
    onSuccess: (res: any) => {
      const { limit, offset, total } = res;
 
      setPagination({
        current: offset / 10 + 1,
        pageSize: limit,
        total: total
      })
		}
	})

  // 查看详情接口
  const { run: getPermissionDetailByIdRun } = useRequest(getPermissionDetailById, {
    manual: true,
    onSuccess: (res: any) => {
      setPermissionDetaiModalData(res)
      setVisiblePermissionDetailModal(true)
    }
  })

  useEffect(() => {
    if (userId) {
      getAuthDashboardDetail({
        userId: [userId],
        limit: 10, 
        offset: 0
      })
      userAuthCustomColumnsItem()
    }
   
  },[userId])

  const handleTableChange = (
		pagination: any,
		filters: Record<string, any>,
	) => {
		const { current, pageSize } = pagination
    if (!userId) return;

      getAuthDashboardDetail({
        userId: [userId],
        ...filters,
        offset: current - 1,
        limit: pageSize,
      });
			setPagination(pagination)
	};

  const renderColumnContent = (txt: any, key: string, record: any) => {
		switch (key) {
			case 'sqlType':
				return <Tag color={SQL_OPERATE_TYPES[txt]}>{txt}</Tag>;
			case 'resultFlag':
				return (
					<Badge
						status={txt ? 'success' : 'error'}
						text={txt ? '成功' : '失败'}
					/>
				)
			case 'executeSql':
        return (
          <Typography.Paragraph key={JSON.stringify(record)} style={{ width: 180, fontSize: 12, wordBreak: 'break-all' }} copyable={{ text: txt }}>
            {txt?.length > 66 ? <Tooltip title={txt}>{txt.substring(0, 66) + '...'}</Tooltip> : txt}
          </Typography.Paragraph>
        )
			case 'connectionName':
				return <span><Iconfont type={`icon-${record?.dbType}`} style={{ marginRight: 2 }} />{txt}</span>
      case 'nodePath':
        return <>
                <Tooltip
                  title={<>
                  <Iconfont
                    type={`icon-${record?.connectionType ?? 'database'}`}
                    style={{ fontSize: '16px', marginRight: 4 }}
                  />{txt}
                  </> 
                  }
                >
                  <Iconfont
                    type={`icon-${record?.connectionType ?? "database"}`}
                    style={{ fontSize: "16px", marginRight: 4 }}
                  />{txt}
                </Tooltip>
              </>
      case 'permissionId':
        return <Button type='link' onClick={()=>getPermissionDetailByIdRun(txt)}>权限详情</Button> 
			default:
				return txt || '-'
		}
	}
  // 查询并设置columns -- 权限tab
  const userAuthCustomColumnsItem = (columns?: any[], callback?: () => void) => {
    const params: any = {
      userId,
      type: 'auth_detail',
      columns
    }
    getAndSetAuditColumn(params).then((res: any) => {
      const { columns = [] } = res
      // @ts-ignore
      let newColumns = columns?.filter((i: string) => USER_AUDIT_AUTH_DETAIL_COLUMN[i] !== undefined)
      if (!columns?.length) {
        newColumns = USER_AUDIT_AUTH_DETAIL_DEFAULT_COLUMN
      }
      newColumns = newColumns?.map((i: string, index: number) => {
        let item: any = {
          // @ts-ignore
          title: USER_AUDIT_AUTH_DETAIL_COLUMN[i],
          dataIndex: i,
          ellipsis: true,
          fixed: !index ? 'left' : '',
          render: (txt: string, record: any) => renderColumnContent(txt, i, record)
        }
        if (i === 'permissionType') {
          item.filters = PERMISSION_TYPE.map(item => ({text: item?.value, value: item?.key}))
          // 是否可多选
          item.filterMultiple = true
        }
        if (i === 'nodeType') {
          item.filters = NODE_TYPE.map(item => ({text: item, value: item}));
          item.filterMultiple = true
        }
        return item
      })
      newColumns[newColumns.length] = {
        title: <SettingOutlined onClick={() => setModalVisible(true)} />,
        dataIndex: 'setting',
        width: 30,
        fixed: 'right',
        // render: <></>
      }
      setColumns(newColumns)
      callback && callback()
    }).catch()
  }

  return (
    <section className="cq-new-card flow-card" style={{ marginBottom: '10.5px' }}>
      <div className="cq-card__headerbar">
        <h3 className="cq-card__title">可访问资源</h3>
      </div>
      <section className="card__content">
        <Card
          // todo: UICard 或 util 封装 card title 样式
          size="small"
          bordered={false}
          style={{height: '100%'}}
        >
         <Table
            columns={columns}
            dataSource={dataSource?.data || []}
            loading={loading}
            pagination={pagination}
            size="small"
            onChange={handleTableChange}
            scroll={{y: `calc(100vh - 300px)`}}
          />
        </Card>
        {
					modalVisible &&
					<CustomColumnsDrawer
						visible
						setVisible={setModalVisible}
            type='auth_detail' 
						handleOk={(columns: any) =>  userAuthCustomColumnsItem(columns, () => {
              setModalVisible(false)
            })}
						defaultValue={columns}
					/>
				}
      <PermissionDetailModal
        visible={visiblePermissionDetailModal}
        setVisible={setVisiblePermissionDetailModal}
        permissionDetaiModalData={permissionDetaiModalData}
      />
      </section>
    </section>

  )
}
