/*
 * @Author: fuzhenghao
 * @Date: 2023-01-17 09:42:37
 * @LastEditTime: 2023-01-17 11:19:49
 * @LastEditors: fuzhenghao
 * @Description: 消息相关接口
 * @FilePath: \cq-enterprise-frontend\src\api\userMessage.ts
 */

import { fetchGet, fetchPost } from './customFetch'

/* ----------------------------------- api ---------------------------------- */

const batchDelMessagesApi = `user/msg/batchDelMessages`

/* -------------------------------- interface ------------------------------- */

export interface IParams_batchDelMessagesPost {
  ids: Array<string | number>
}

/* ---------------------------------- 接口请求 ---------------------------------- */
// 通知 全部 消息
export const getAllMes = (value: any, pageNum: number, pageSize: number) => {
  return fetchGet(`/user/msg/getMessageByUserId/value=${value}/pageNum=${pageNum}/pageSize=${pageSize}`)
}

// 通知  已读 消息
export const getAllReadMes = (value: any, pageNum: number, pageSize: number) => {
  return fetchGet(`/user/msg/getReadMessageByUserId/value=${value}/pageNum=${pageNum}/pageSize=${pageSize}`)
}

// 通知 未读 消息
export const getAllUnReadMes = (value: any, pageNum: number, pageSize: number) => {
  return fetchGet(`/user/msg/getUnreadMessageByUserId/value=${value}/pageNum=${pageNum}/pageSize=${pageSize}`)
}

// 通知 删除 消息
export const batchDelMessages = (params: IParams_batchDelMessagesPost) => {
  return fetchPost(`user/msg/batchDelMessages`, params)
}

export const getUnReadMes = (userId: string) => {
  return fetchGet(`/user/msg/getMessageUnRead/${userId}`)
}

export const getUnReadMesNum = (userId: string) => {
  return fetchGet(`/user/msg/getMessageUnReadNum/${userId}`)
}

export const putReadMes = (id: number) => {
  return fetchPost(`/user/msg/readMessage/${id}`)
}

export const putReadMess = (ids: Number[]) => {
  return fetchPost(`/user/msg/markedMessageReaded`, ids)
}

export const batchDelMessagesPost = (params: IParams_batchDelMessagesPost): Promise<number> => {
  return fetchPost(batchDelMessagesApi, params)
}
