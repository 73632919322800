import { AutoComplete, Button, Space, Spin, Tag, Tooltip, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useContext, useState } from "react";
import { addFilterRule, queryExpression, testFilterRule, testSqlFilterRule } from "src/api";
import { useDispatch, useRequest, useSelector } from "src/hook";
import { RuleRequest } from "./TableFilterRow";

const AddRuleBox = (): JSX.Element => {
  const { selectedNode, selectedNodePermissionInfo ={} } = useSelector(state => state.dataProtection);
  const [inputValue, setInputValue] = useState<string>("");
  const [suggestions, setSuggestions] = useState([]);
  const [addBtnStatus, setAddBtnStatus] = useState<boolean>(true);
  const [showRuleSql, setShowRuleSql] = useState<boolean>(false);
  const { refreshView } = useContext(RuleRequest)!;

  const {modulePermissionObj = {isOnlyRead: false, roleNameList: []}, permissionSupportData = true } = selectedNodePermissionInfo["DATA_PROTECT"] || {};

  const { run } = useRequest(queryExpression, { manual: true });
  const { run: addRule, loading: loadingForAdd } = useRequest(addFilterRule, {
    manual: true,
    onSuccess() {
      message.success("添加成功");
      setInputValue("");
      // 刷新规则表
      refreshView();
    },
  });
  const { run: testRule, loading: loadingForTest } = useRequest(testFilterRule, {
    manual: true,
    onSuccess() {
      message.success("测试成功");
      setAddBtnStatus(false);
    },
  });

  const { run: testSqlRule, loading: loadingForTestSql, data: testSqlData } = useRequest(testSqlFilterRule, {
    manual: true,
    onSuccess() {
      setShowRuleSql(true)
    },
  });

  async function generateOptions(input: string) {
    if (input.endsWith("$")) {
      const res = await run();
      const formattedOptions = res.map((i: any) => {
        return {
          label: (
            <Space>
              <span>{i.key}</span>
              {i.desc && <Tag color="blue">{i.desc}</Tag>}
            </Space>
          ),
          value: i.key,
        };
      });
      setSuggestions(formattedOptions);
    } else {
      setSuggestions([]);
    }
  }

  function handleSelect(value: string) {
    setInputValue(prev => {
      return `${prev}${value.slice(1)}`;
    });
  }

  function handleAddRule() {
    if (selectedNode) {
      const { nodePath, nodePathWithType, connectionType } = selectedNode.props;
      const params = {
        nodePath,
        nodePathWithType,
        dataSourceType: connectionType,
        content: inputValue?.trim(),
      };
      addRule(params);
    }
  }

  function handleTestRule() {
    if (selectedNode) {
      const { nodePath, nodePathWithType, connectionType } = selectedNode.props;
      const params = {
        nodePath,
        nodePathWithType,
        dataSourceType: connectionType,
        content: inputValue?.trim(),
      };
      testSqlRule(params)
      testRule(params);
    }
  }

  return (
    <Spin spinning={loadingForTest || loadingForAdd}>
      <div style={{ marginBottom: "40px" }}>
        <AutoComplete
          value={inputValue}
          style={{ width: "100%" }}
          options={suggestions}
          onSelect={handleSelect}
        >
          <TextArea
            rows={3}
            value={inputValue}
            onChange={async e => {
              setAddBtnStatus(true);
              setInputValue(e.target.value);
              await generateOptions(e.target.value);
            }}
            // eslint-disable-next-line no-template-curly-in-string
            placeholder="请输入行过滤规则（条件表达式）：[变量名][><=!like][值]
            变量名为字段名，值为用户参数，用户参数使用$引用
            示例：ID = '${USER.UserId}'"
          />
        </AutoComplete>

        <Space>
          <Tooltip title={
            !permissionSupportData?
            `您当前的角色是[${modulePermissionObj?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
            : null
            }
          >
            <Button
              type="link"
              onClick={handleTestRule}
              disabled={!permissionSupportData || !inputValue?.trim()}
            >
              测试
            </Button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title="测试成功后允许添加"
          >
            <Button
              type="link"
              onClick={handleAddRule}
              disabled={addBtnStatus}
            >
              添加
            </Button>
          </Tooltip>
        </Space>
        {
          showRuleSql && <div>
            校验SQL: {testSqlData?.testSql ? testSqlData?.testSql : '-'}
          </div>
        }
      </div>
    </Spin>
  );
};

export { AddRuleBox };

