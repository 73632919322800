import React, { FC, useContext, useEffect, useState } from "react";
import { FormInstance } from "antd/lib/form";
import moment from "moment";
import {
  Form,
  Input,
  Radio,
  Row,
  Col,
  Card,
  Button,
  Tabs,
  message,
} from "antd";
import { SQL_TYPES, DATA_REQUEST_APPLY_TYPES, CURRENT_ROUTE_TYPE } from "src/constants";
import { SimpleEditor } from "src/components/SimpleEditor";
import {
  RenderDatabaseFieldIcon,
  SelectDataChangeTreeNodeSingle,
} from "src/components";
import { SqlFileUpload } from "./DataChangeBigFileUpload/SqlFileUpload";
import { FormContext } from "../ApplyFormContext";
import styles from "./index.module.scss";
import classnames from "classnames";

interface DataUpdateFormProps {
  form: FormInstance;
  itemStepMark?: number; //当前步骤
  isViewDetail: boolean;
  isEdit?: boolean;
  currentRouteType?: CURRENT_ROUTE_TYPE;
  [p: string]: any;
}
// 数据库, 标题 SQL
export const DataUpdateForm: FC<DataUpdateFormProps> = ({
  form,
  isViewDetail,
  currentRouteType,
}) => {
  const { 
    setCurrentStep,
    currentContextValue,
    setCurrentContextValue,
  } = useContext(FormContext);

  const [sqlType, setSqlType] = useState(1);
  const [templateName, setTemplateName] = useState<string>('')

  const isMineApply = (currentRouteType === "MINE_APPLY");

  useEffect(() => {
    setSqlType(currentContextValue?.sqlType);
  }, [isViewDetail, currentContextValue, currentContextValue?.sqlType]);

  return (
    <Card
      title={''}
      className={classnames(styles["step-card"], isMineApply && styles["step-card2"], styles['borderNone'])}
      bordered={false}
      actions={
        isMineApply && !isViewDetail
          ? [
            <div className={styles["footer-btn"]}>
              <Button
                className={styles["reset-btn"]}
                onClick={() => {
                  form.resetFields();
                }}
              >
                重置
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  form.validateFields().then((values) => {
                    setCurrentStep && 
                    setCurrentStep((currentStep: number) => {
                      return (currentStep + 1)
                    });
                    
                    setCurrentContextValue({
                      ...currentContextValue,
                      ...values,
                    });
                  }).catch(({ values, errorFields }) => {
                    if (
                      values?.type === 2 &&
                      !values?.sqlStatement &&
                      errorFields?.length < 2
                    ) {
                      message.error("执行SQL不能为空");
                    }
                  });
                }}
              >
                SQL审核
              </Button>
            </div>,
          ]
          : []
      }
    >
      <Form
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 10 }}
        layout="horizontal"
        autoComplete="off"
        form={form}
        onValuesChange={(changedValues, allValues) => {
          setCurrentContextValue({
            ...currentContextValue,
            ...allValues,
            executeTime: allValues?.executeTime
              ? moment(allValues.executeTime)
              : null
          });
        }}
      >
        {currentContextValue?.type === 2 && (
          <Form.Item 
            label="模拟数据库" 
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
          >
            <Row>
              <Col span={12}>
                <Form.Item 
                  noStyle
                  name="simulateNodePath"
                >
                  {!isViewDetail ||
                    (currentRouteType === "MINE_APPROVE" &&
                      currentContextValue?.executeStatus === "审批中") ? (
                    <SelectDataChangeTreeNodeSingle
                      experiment={true}
                      dependFieldValue={form.getFieldValue("nodePath")}
                      selectedNodeConnectionName={currentContextValue?.simulateConnectionName}
                      onChange={(path) => {
                        setCurrentContextValue({
                          ...currentContextValue,
                          simulateNodePath: path,
                        });
                        form.setFieldsValue({ simulateNodePath: path });
                      }}
                      selectedNodeConnectionType={currentContextValue?.dataSourceType}
                      setDatabaseType={(params: {type: string; connectionId: string; connectionName: string}) => {
                        setCurrentContextValue({
                          ...currentContextValue,
                          dataSourceType: params.type,
                          connectionId: params.connectionId,
                          simulateConnectionName: params.connectionName,
                        });
                      }}
                      // updateTemplateName={(val: string)=>setSimulateTemplateName(val)}
                    />
                  ) : (
                    RenderDatabaseFieldIcon(
                      currentContextValue?.simulateNodePath,
                      currentContextValue?.dataSourceType,
                      currentContextValue?.simulateConnectionName
                    )
                  )}
                </Form.Item>
              </Col>
              {/* {
                simulateTemplateName &&
                <Col span={12}>
                  <div style={{ height: '32px', display:'flex', alignItems:'center', marginLeft:10 }}>
                    规则模板：{simulateTemplateName}
                  </div>  
                </Col>
              } */}
            </Row>
          </Form.Item>
        )}
        <Form.Item 
          label="数据库"  
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          required
        >
          <Row>
            <Col span={12}>
              <Form.Item
                noStyle
                name="nodePath"
                rules={[{ required: true, message: "请选择数据库" }]}
              >
                {isViewDetail ? (
                  RenderDatabaseFieldIcon(
                    currentContextValue?.nodePath,
                    currentContextValue?.dataSourceType,
                    currentContextValue?.connectionName
                  )
                ) : (
                  <SelectDataChangeTreeNodeSingle
                    dependFieldValue={form.getFieldValue("simulateNodePath")}
                    selectedNodeConnectionType={currentContextValue?.dataSourceType}
                    selectedNodeConnectionName={currentContextValue?.connectionName}
                    setDatabaseType={(params: {
                      type: string; 
                      connectionId: string;
                      connectionName: string,
                      templateName: string,
                    }) =>
                      setCurrentContextValue({
                        ...currentContextValue,
                        dataSourceType: params.type,
                        connectionId: params.connectionId,
                        connectionName: params.connectionName,
                        templateName: params.templateName,
                      })
                    }
                    updateTemplateName={(val: string)=>setTemplateName(val)}
                  />
                )}
              </Form.Item>
            </Col>
            {
              currentContextValue?.templateName &&
              <Col span={12}>
                <div style={{ height: '32px', display:'flex', alignItems:'center', marginLeft:10 }}>
                  规则模板：{currentContextValue?.templateName}
                </div>  
              </Col>
            }
          </Row>
        </Form.Item>
        <Form.Item
          label="标题"
          name="title"
          rules={[{ required: true, message: "请输入标题" }]}
        >
          {isViewDetail ? (
            currentContextValue?.title
          ) : (
            <Input placeholder="请输入标题" />
          )}
        </Form.Item>
        <Form.Item
          label="数据类型"
          hidden={!isViewDetail}
          rules={[{ required: true, message: "请输入标题" }]}
        >
          {DATA_REQUEST_APPLY_TYPES[currentContextValue?.type]}
        </Form.Item>
        <Form.Item
          label="变更SQL"
          name="sqlType"
          initialValue={sqlType}
          rules={[{ required: true }]}
          extra={!isViewDetail && "变更SQL类型后，已输入内容将会被清空"}
        >
          {
            //@ts-ignore
            isViewDetail ? (
              SQL_TYPES[currentContextValue?.sqlType]
            ) : (
              <Radio.Group
                options={Object.keys(SQL_TYPES).map((key: string) => ({
                  //@ts-ignore
                  label: SQL_TYPES[Number(key)],
                  value: Number(key),
                }))}
                onChange={(event) => {
                  setSqlType(event.target.value);
                  form.setFieldsValue({ sqlStatement: "" });
                  form.setFields([
                    { name: "sqlStatement", value: "", errors: [] },
                    { name: "rollbackSqlStatement", value: "", errors: [] },
                  ]);
                }}
              />
            )
          }
        </Form.Item>

        {sqlType === 1 && (
          <>
            <Form.Item label="SQL文本" required wrapperCol={{ span: 18 }}>
              <div className={styles.tabsSimpleEditorWrap}>
                <Tabs>
                  <Tabs.TabPane tab="执行SQL" key="1">
                    <Form.Item
                      name="sqlStatement"
                      rules={
                        [
                          { 
                            required: true, 
                            message: "请输入执行SQL文本", 
                          },
                          () => ({
                            validator(rule, value) {
                              if (value && value.trim()==='') {
                                return Promise.reject('请输入执行SQL文本');
                              } else {
                                return Promise.resolve();
                              }
                            },
                          })
                        ]
                      }
                    >
                      <SimpleEditor
                        // onChange={handleEditChange}
                        id="sqlStatement"
                        readOnly={isViewDetail}
                        theme="vs"
                      />
                    </Form.Item>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="回退SQL" key="2">
                    <Form.Item
                      name="rollbackSqlStatement"
                      dependencies={['sqlStatement']}
                    >
                      <SimpleEditor
                        // onChange={handleEditChange}
                        id="rollbackSqlStatement"
                        readOnly={isViewDetail}
                        theme="vs"
                      />
                    </Form.Item>
                  </Tabs.TabPane>
                </Tabs>
              </div>
            
            </Form.Item>
            {/* <DataBackup type={currentContextValue?.type} connectionId={currentContextValue?.connectionId}/> */}
          </>

        )}
        {sqlType === 2 && (
          <Form.Item
            label="SQL附件"
            required
            extra={!isViewDetail && "支持格式：.sql"}
          >
            {isViewDetail ? (
              <Row gutter={8}>
                <Col>执行SQL: {currentContextValue?.sqlStatement}</Col>
                <Col>
                  回退SQL: {currentContextValue?.rollbackSqlStatement}
                </Col>
              </Row>
            ) : (
              <>
                <Form.Item
                  required
                  name="sqlStatement"
                  rules={[{ required: true, message: "请上传执行SQL附件" }]}
                >
                  <SqlFileUpload btnText="单个执行SQL文件上传" />
                </Form.Item>
                <Form.Item
                  required
                  name="rollbackSqlStatement"
                >
                  <SqlFileUpload btnText="单个回退SQL文件上传" />
                </Form.Item>
              </>
            )}
          </Form.Item>
        )}
        <Form.Item
          label="执行方式"
          hidden={!isViewDetail}
          rules={[{ required: true, message: "请输入标题" }]}
        >
          {currentContextValue?.executeTimeType === 1 ? "手动执行" : "自动执行"}
        </Form.Item>
        <Form.Item
          label="执行时间"
          hidden={!isViewDetail || currentContextValue?.executeTimeType === 1}
        >
          {currentContextValue?.executeTime &&
            moment(currentContextValue?.executeTime).format(
              "YYYY-MM-DD HH:mm:ss"
            )}
        </Form.Item>
        <Form.Item label="备注" hidden={!isViewDetail}>
          {currentContextValue?.applyUserComment}
        </Form.Item>
        <Form.Item
          label="审批人备注"
          hidden={!currentContextValue?.approveUserComment}
        >
          {currentContextValue?.approveUserComment}
        </Form.Item>
      </Form>
    </Card>
  );
};

export default DataUpdateForm;
