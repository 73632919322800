import React, { FC } from 'react'
import { Badge, Card, Steps } from 'antd'
import classnames from 'classnames'
import { MENU_FLOW } from 'src/constants'
import { applyStatusMap } from '../../constants'
import styles from '../index.module.scss'


interface ListProps {
	dataList: any[]
	flowStatus: any
}

export const OrderFlowPage: FC<ListProps> = (props) => {
	const { dataList, flowStatus } = props


	return (
		<Card
			title={<div>
				<span>工单流转</span>
				<span>
					{/* {
						flowStatus?.approvedResult === 'pass' ? <CheckCircleOutlined style={{ color: '#1AD42D' }} /> : (
							flowStatus?.approvedResult === 'refuse' ? <CloseCircleOutlined style={{ color: '#EA3223' }} /> : <ClockCircleOutlined style={{ color: '#0256FF' }} />
						)} */}
					<span className={classnames(
						styles.fs14,
						styles.ml10,
						flowStatus?.approvedResult === 'pass' ? styles.alreadyColor : (
							flowStatus?.approvedResult === 'refuse' ? styles.rejectColor : styles.pendingColor
						)
					)}>
						{flowStatus?.approvedResultDesc ? `(${flowStatus?.approvedResultDesc})` : null}
					</span>
				</span>

			</div>}
			className={classnames(styles.borderShow, styles.mt27, styles.mb27)}
		>
			<Steps current={dataList && dataList.length ? dataList.length - 1 : 0} className={styles.stepsWrap}>
				{dataList &&
					dataList.map((flow: any, index: number) => {
						const { flowProcessInfos = [], flowNodeResult, flowNodeName, flowNodeType } = flow
						return (
							<Steps.Step
								key={index}
								title={<div className={styles['step-title']}>{index === 0
									? <div className={styles.desc}>
										<span>{MENU_FLOW}</span>
										<span className={styles.descColor}>{flowProcessInfos?.[0]?.approvedUser}</span>
									</div>
									: flowNodeName}</div>
								}
								description={
									<div className={styles.desc}>
										<div>{index === 0 ? null : (
											flowNodeType === 'sign' ? (
												flowProcessInfos?.map((item: any, index: number) => {
													return <div key={index} className={classnames(styles.m0,styles.descColor)}>
														<span className={styles.mr10}>{item?.approvedUser}</span>
														<span>{item?.approvedResultDesc}</span>
														<p className={styles.m0}>{item?.approvedTime}</p>
													</div>
												})
											) :
												<p className={classnames(styles.m0,styles.descColor)}>
													<span className={styles.mr10}>{flowProcessInfos?.[0]?.approvedUser}</span>
													<span>{applyStatusMap[flowProcessInfos?.[0]?.approvedResult]}</span>
												</p>
										)}</div>
										{
											flowNodeType !== 'sign' ? <p className={styles.descColor}>{flowProcessInfos?.[0]?.approvedTime}</p> : null
										}
									</div>
								}
								icon={
									<div>
										{
											<div
												className={classnames(styles['step-icon'], {
													[styles.start]: index === 0,
												})}
											>
												<span
													className={classnames(styles.icon, {
														[styles.pending]: flowNodeResult === 'pending',
													})}
												>
													{index === 0 ? '建' : '审'}
												</span>
												{index !== 0 && (
													<Badge
														dot={true}
														offset={[8, 10]}
														className={styles['icon-badge']}
														color={flowNodeResult === 'pending' ? '#3262FF' : '#36B839'}
													></Badge>
												)}
											</div>
										}
									</div>

								}

							></Steps.Step>
						)
					})}
			</Steps>
		</Card>
	)
}
