import { designTableReducer } from 'src/pageTabs/queryPage/queryTabs/designNewTablePane/designTableSlice'
// ref: https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer, PURGE } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { dataSourceReducer } from './extraSlice/dataSourceSlice'

import { appManagementReducer } from 'src/pageTabs/appManagementPage/appManagementPageSlice'
import { appearanceReducer } from './extraSlice/appearanceSlice'
import { sdtPermissionReducer } from './extraSlice/sdtPermissionSlice'
import { auditReducer } from 'src/pageTabs/auditPage/auditSlice'
import { connectionManagementReducer } from 'src/pageTabs/connectionManagementPage/connectionManagementPageSlice'
import { downloadReducer } from 'src/pageTabs/downloadPage/downloadPageSlice'
import { editorReducer } from './extraSlice/editorSlice'
import { flowFormsReducer } from 'src/pageTabs/flowPages'
import { loginReducer } from 'src/appPages/login/loginSlice'
import { modalVisibleReducer } from './extraSlice/modalVisibleSlice'
import { organizationReducer } from 'src/pageTabs/organizationPage/organizationSlice'
import { queryPageReducer } from 'src/pageTabs/queryPage/queryPageSlice'
import { queryTabsReducer } from 'src/pageTabs/queryPage/queryTabs/queryTabsSlice'
import { resultTabsReducer } from 'src/pageTabs/queryPage/resultTabs/resultTabsSlice'
import { sdtReducer } from 'src/pageTabs/queryPage/sdt/sdtSlice'
import { logsReducer } from './extraSlice/logsSlice'
import { wizardsReducer } from 'src/features/wizards/wizardsSlice'
import { myFolderReducer } from 'src/pageTabs/myFolderPage/myFolderSlice'
import { settingReducer } from './extraSlice/settingSlice'
import { textImportReducer } from './extraSlice/textImportSlice'
import { dataSourceMetaReducer } from './extraSlice/dataSourceMetaSlice'
import { messageReducer } from './extraSlice/useMesSlice'
import { resultLogFormReducer } from 'src/pageTabs/queryPage/resultTabs/resultLogs/resultLogSlice'
import { encryptionReducer } from './extraSlice/encryptionSlice'
import { companyReducer } from 'src/pageTabs/organizationPage/companySlice'
import { dataProtectionReducer } from 'src/pageTabs/dataProtection/DataProtectionPageSlice'
import { mySysRolesReducer } from 'src/pageTabs/sysRolesPage/sysRolesSlice'
import { resourceReducer } from 'src/pageTabs/connectionManagementPage/main/resourceConfig/resourceSlice'
import { authorizationManageReducer } from 'src/pageTabs/authorizationManagePage/authorizationManagePageSlice'
import { createTransferReducer } from 'src/pageTabs/dataTransfer/createTransferTask/CreateTransferTaskSlice'
import { personalCenterReducer } from 'src/pageTabs/PersonalCenter/personalCenterSlice'
import { userAvatarReducer } from 'src/store/extraSlice/userAvatarSlice'
import { accessRequestReducer } from 'src/pageTabs/access-request/accessRequestSlice'
import { hierarchicalResourceManagementReducer } from 'src/pageTabs/database-management/hierarchical-auth-management/hierarchicalResourceManagementSlice'
import { authorizationAuthorizationManageReducer } from 'src/pageTabs/automaticAuthorizationManagePage/authorizationAuthorizationManagePageSlice'
import { automaticAuthorizationOperateReducer } from 'src/pageTabs/automaticAuthorizationOperate/automaticAuthorizationOperateSlice'
import { indexedDBStorage } from './indexedDB';
import { PermissionLevelSliceReducer } from 'src/pageTabs/access-request/components/permissionLevelSlice'
import { sqlBatchExecutionReducer } from 'src/pageTabs/sqlBatchExecution/sqlBatchExecutionSlice'
import { subjectAuthSdtReducer } from 'src/pageTabs/database-management/subject-authorization/ContentWrapper/subjectAuthSdtSlice'
import { myTaskCenterReducer } from 'src/pageTabs/taskCenter/taskCenterSlice'
import { visitRequestOperationReducer } from 'src/pageTabs/access-request/visit-request-operation/visitRequestOperateSlice'
import { applicationListReducer } from 'src/pageTabs/access-request/application-list/applicationListSlice'
import { appliedResourceListReducer } from 'src/pageTabs/access-request/AppliedResourceList/appliedResourceListSlice'

const appReducer = combineReducers({
  appManagement: appManagementReducer,
  appearance: appearanceReducer,
  connectionManagement: connectionManagementReducer,
  dataSource: dataSourceReducer,
  download: downloadReducer,
  audit: auditReducer,
  editor: editorReducer,
  flowForms: flowFormsReducer,
  login: loginReducer,
  modal: modalVisibleReducer,
  myFolder: myFolderReducer,
  organization: organizationReducer,
  queryPage: queryPageReducer,
  queryTabs: queryTabsReducer,
  resultTabs: resultTabsReducer,
  sdt: sdtReducer,
  logs: logsReducer,
  wizards: wizardsReducer,
  message: messageReducer,
  setting: settingReducer,
  textImport: textImportReducer,
  dataSourceMeta: dataSourceMetaReducer,
  designTable: designTableReducer,
  resultLog: resultLogFormReducer,
  encryption: encryptionReducer,
  companyInfo: companyReducer,
  dataProtection: dataProtectionReducer,
  sysrolesState: mySysRolesReducer,
  resourceDepot: resourceReducer,
  authorizationManagement: authorizationManageReducer,
  createTransfer: createTransferReducer,
  personalCenter: personalCenterReducer,
  sysUserAvatar: userAvatarReducer,
  accessRequest: accessRequestReducer,
  sdtPermission: sdtPermissionReducer,
  hierarchicalRM: hierarchicalResourceManagementReducer,
  automaticAuthorizationOperate: automaticAuthorizationOperateReducer,
  permissionLevelData: PermissionLevelSliceReducer,
  sqlBatchExecutionData: sqlBatchExecutionReducer,
  authorizationAuthorizationManage: authorizationAuthorizationManageReducer,
  subjectAuth: subjectAuthSdtReducer,
  myTaskCenter: myTaskCenterReducer,
  visitRequestOperate: visitRequestOperationReducer,
  applicationList: applicationListReducer,
  appliedResourceList: appliedResourceListReducer
})

// https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
// TODO: redux-persist actions 应该是被序列化检验的中间件阻拦了，导致这里手动实现了 purge
const rootReducer: typeof appReducer = (state, action) => {
  if (action.type === PURGE) {
    return appReducer(
      { sdt: state?.sdt } as ReturnType<typeof appReducer>,
      action,
    )
  }
  return appReducer(state, action)
}

export const persistedReducer = persistReducer(
  {
    key: 'root',
    version: 3,
    storage: indexedDBStorage,
    whitelist: ['appearance', 'queryPage', 'queryTabs', 'user', 'login'],
  },
  rootReducer,
)
