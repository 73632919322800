// 申请信息
import { Button, Card, Col, DatePicker, Radio, Row, Tooltip, Typography } from 'antd';
import React, { FC, useEffect, useState, useContext } from 'react';
import { FormContext } from '../ApplyFormContext';
import { DATA_REQUEST_APPLY_TYPES, SQL_TYPES } from 'src/constants';
import { RenderDatabaseFieldIcon } from 'src/components';
import styles from "./index.module.scss"
import Form, { FormInstance } from 'antd/lib/form';
import moment from 'moment';
import classnames from 'classnames';

const { Text } = Typography;

interface ApplyInfoCardProps {
  currentRouteType: string; // 我的申请 MINE_APPLY、我的审核 MINE_APPROVE
  form?: FormInstance;
  [p: string]: any;
}

export const ApplyInfoCard: FC<ApplyInfoCardProps> = ({currentRouteType, form}) => {

  const { currentContextValue } = useContext(FormContext);
  
  const [visibleSqlTypes, setVisibleSqlTypes] = useState<boolean>(false);
  const [executionWay, setExecutionWay] = useState(1);

  const isMineApprove = (currentRouteType === "MINE_APPROVE");

  useEffect(() => {
    setVisibleSqlTypes(false);
    if (currentContextValue?.executeTimeType === 1) {
      setExecutionWay(1)
    }else {
      setExecutionWay(2)
    }
  }, [currentContextValue])


  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate = (current: any) => {
    return current && current < moment().startOf('day');
  };

  const disabledTime = (now: any) => {
    let time: any = moment();
    let hour = moment(time).hour();
    let minute = moment(time).minute();
    let second = moment(time).second();
    return ({
      disabledHours: () =>range(0, hour),
      disabledMinutes: () => range(0,minute),
      disabledSeconds: () =>  range(0,second)
    })
  }
  
  const ColSpan = 6;
  
  return (
    <Card title="申请信息" className={classnames(styles.applyInfoCard, styles.cardBorder)}>
      <Form
        name="basic"
        form={form}
        initialValues={{ executeTimeType: 1 }}
        autoComplete="off"
      >
        <Row>
          <Col span={ColSpan}>
            <Form.Item
              label="申请人"
            >
              <Text>{currentContextValue?.applyUserId || '-'}</Text>
            </Form.Item>
          </Col>
          <Col span={ColSpan}>
            <Form.Item
              label="所在部门"
            >
              <Text>{currentContextValue?.departmentName || '-'}</Text>
            </Form.Item>
          </Col>
          <Col span={ColSpan}>
            <Form.Item
              label="申请单号"
            >
              <Text>{currentContextValue?.flowUUID || '-'}</Text>
            </Form.Item>
          </Col>
          <Col span={ColSpan}>
            <Form.Item
              label="申请时间"
            >
              <Text>{currentContextValue?.applyTime || '-'}</Text>
            </Form.Item>
          </Col>
        </Row>
          
        <Row>
          <Col span={ColSpan}>
            <Form.Item
              label="申请类型"
            >
              <Text>{DATA_REQUEST_APPLY_TYPES[currentContextValue?.type] || '-'}</Text>
            </Form.Item>
          </Col>
          <Col span={ColSpan}>
            <Form.Item
              label="变更SQL"
            >
              <Text>{SQL_TYPES[currentContextValue?.sqlType] || '-'}</Text>
            </Form.Item>
          </Col>
          <Col span={ColSpan}>
            {!visibleSqlTypes ? (
              <Form.Item
                label="执行方式"
                rules={[{ required: true }]}
              >
              {currentContextValue?.executeTimeType === 1 ? '手动执行' : '自动执行'}
              {isMineApprove && currentContextValue?.executeStatus === '审批中' &&
                <Button type="link" onClick={() => setVisibleSqlTypes(true)}>
                  修改
                </Button>
              }
              </Form.Item>
            ) : (
              <Form.Item
                label="执行方式"
                name="executeTimeType"
                rules={[{ required: true }]}
              >
                <Radio.Group
                  onChange={(event) => setExecutionWay(event.target.value)}
                >
                  <Radio value={1}>手动执行</Radio>
                  <Radio value={2}>自动执行</Radio>
                </Radio.Group>
              </Form.Item>
            )}
          </Col>
          <Col span={ColSpan}>
            {executionWay === 1 ? null : (
              !visibleSqlTypes?
              <Form.Item
                label="执行时间"
              >
                <Text>{currentContextValue?.executeTime ? moment(currentContextValue?.executeTime).format('YYYY-MM-DD HH:mm:ss') : ''}</Text>
              </Form.Item>
              :
              <Form.Item
                label="执行时间"
                name="executeTime"
                rules={[{ required: true, message: '请选择执行时间' },{
                  validator(_rule, value,cb) {
                    const time = moment(value).valueOf();
                  
                    if (time < moment().valueOf()) {
                      cb('执行时间必须大于当前时间')
                    }
                  cb();
                  },
                }]}
              >
                <DatePicker 
                showTime={{
                    hideDisabledOptions: true,
                }}    
                disabledDate={disabledDate}  
                disabledTime={disabledTime} 
                format="YYYY-MM-DD HH:mm:ss" />
              </Form.Item>
            )}
          </Col>
        </Row>
       
        <Row>
          <Col span={ColSpan}>
            <Form.Item
              label="数据库"
            >
              <div
                className={styles.fieldEllipsis}
              >
                <Tooltip 
                  title={
                    RenderDatabaseFieldIcon(
                    currentContextValue?.nodePath,
                    currentContextValue?.dataSourceType,
                    currentContextValue?.connectionName
                  )}
                  color={"white"}
                  overlayInnerStyle={{minWidth: "max-content"}}
                >
                {
                RenderDatabaseFieldIcon(
                  currentContextValue?.nodePath,
                  currentContextValue?.dataSourceType,
                  currentContextValue?.connectionName
                )}
                </Tooltip>
              </div>
            </Form.Item>
          </Col>
          {
            currentContextValue?.type === 2 && (
              <Col span={ColSpan}>
                <Form.Item
                  label="模拟数据库"
                >
                  <div
                    className={styles.fieldEllipsis}
                  >
                    <Tooltip 
                      title={
                        RenderDatabaseFieldIcon(
                        currentContextValue?.simulateNodePath,
                        currentContextValue?.dataSourceType,
                        currentContextValue?.simulateConnectionName
                      )}
                      color={"white"}
                      overlayInnerStyle={{minWidth: "max-content"}}
                    >
                    {
                    RenderDatabaseFieldIcon(
                      currentContextValue?.simulateNodePath,
                      currentContextValue?.dataSourceType,
                      currentContextValue?.simulateConnectionName
                    )
                    }
                    </Tooltip>
                  </div>
                </Form.Item>
              </Col>
            )
          }
          <Col span={ColSpan}>
            <Form.Item
              label="标题"
            >
              <Text>{currentContextValue?.title || '-'}</Text>
            </Form.Item>
          </Col>
          <Col span={ColSpan}>
            <Form.Item
              label="备注"
            >
              <div className={styles.fieldEllipsis} title={currentContextValue?.applyUserComment || '-'}>
                {currentContextValue?.applyUserComment || '-'}
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default ApplyInfoCard