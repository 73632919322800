import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import * as _ from 'lodash';
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { Button, Descriptions, Input, message, Tabs, Spin } from 'antd'
import {
  executeMenuActionSql,
  GenerateSql,
  generateSql_api,
  getDDl_api,
  getTableInfo,
  ITableInfoProps
} from 'src/api'
import { INCLUDE_SCHEMA_CONNECTIONS } from 'src/constants';
import { correctComment, getTableNameFormConnectionType, getSchemaFormConnectionType } from 'src/util'
import { refreshOnRoot } from '../../sdt'
import { DesignPane } from './components/DesignPane'
import PartitionPane from './components/PartitionPane'
import OptionPane from './components/OptionPane'
import { DESIGN_TABS_FILED_MAPPING, ENABLE_DESIGN_FIELDS } from './constants';
import { getPointDataSourceTabs } from './utils';
import MoreTableInfoFields from './components/MoreTableInfoFields';
import { DatabaseInfo, updateTabsInfo, updatePaneInfo } from '../queryTabsSlice'
import styles from './designTablePane.module.scss'

const { TabPane } = Tabs

interface CreateTablePaneProps {
  databaseInfo: Partial<DatabaseInfo>
  queryTabKey: string
  modeType: string
}

export const DesignNewTablePane = (props: CreateTablePaneProps) => {
  const { databaseInfo, queryTabKey, modeType } = props
  const dispatch = useDispatch()

  let {
    connectionId,
    connectionType = 'MySQL',
    databaseName,
    nodePath,
    nodePathWithType,
    nodeName,
    nodeType,
  } = databaseInfo

  const { toolbarConnections } = useSelector((state) => state.editor)
  const connection = toolbarConnections?.find(
    (t) => t.connectionId === connectionId,
  )
  const connectionName = connection?.alias
    ? connection.alias
    : connection?.nodeName
  // be careful for this 除特殊含有schema连接外其余默认使用databaseName值；
  const schemaName = (() => {
    if (!nodePath) return ;
    let transformedSchemaName = getSchemaFormConnectionType(connectionType, nodePath);
     return transformedSchemaName;
  })()

    const tableName = (() => {
      if (!nodePath) return ;
      let transformedTableName = getTableNameFormConnectionType(connectionType, nodePath);
       return transformedTableName;
    })()
    
  const [sql, setSql] = useState<string>('')
  const [tabsActiveKey, setTabsActiveKey] = useState<string>('columns')
  const [newTableComment, setNewTableComment] = useState('')
  const [newTableName, setNewTableName] = useState<string>('')

  // 缓存所有待提交的参数
  const generateParamsRef = useRef<Partial<GenerateSql>>({})

  const {
    data: tableInfo,
    run: tryGetTableInfo,
    loading: getTableColumnsLoading,
  } = useRequest(
    () => {
      if (!(connectionId && nodeName && nodePath && nodeType)) return;
      let tableInfoParams: ITableInfoProps = {
        connectionId,
        connectionType,
        tableName,
        databaseName,
        schemaName,
        nodePathWithType,
      }
      return getTableInfo(tableInfoParams)
    },
    {
      manual: true,
      onSuccess(data) {
        // tryGetTableColumns 方法会在，首次会执行，重置状态会执行
        generateParamsRef.current = {}

        const { comment, pkName } = data
        setNewTableComment(comment)

        if (comment) {
          generateParamsRef.current.tableInfo = {
            oldComment: comment
          }
        }
        if (pkName) {
          generateParamsRef.current.primaryKey = {
            oldPkName: pkName
          }
        }
      },
    },
  )

  const { data: ddlData, run: getDDL } = useRequest(
    (params?: any) => {
      if (!(connectionId && nodeName && nodePath && nodeType)) return
      return getDDl_api({
        connectionId,
        connectionType,
        nodeName,
        nodePath,
        nodeType,
        ...params,
      })
    },
    {
      manual: true,
    },
  )

  const { comment: tableComment } = tableInfo || {}

  useEffect(() => {
    tryGetTableInfo()
  }, [tryGetTableInfo])

  // 生成 sql预览
  const generateSqlPreview = async (info: Partial<GenerateSql>) => {
    let base = {
      connectionType,
      databaseName,
      schemaName,
      tableName,
    }

    let params = { ...base, ...info } as GenerateSql

    return generateSql_api(params)
  }

  // 更新时设置信息
  useEffect(() => {
    if (tableName) {
      // 重新获取值时，设置 tableName
      generateParamsRef.current.tableName = tableName
      setNewTableName(tableName)
    }
  }, [tableName])
  //修改表名 刷新 store内容没有更新问题 手动更新
  const onUpdateTabInfo = () => {
    const newFileds = {
      key: queryTabKey,
      nodeName: newTableName,
      nodePath:
        nodePath && tableName && _.replace(nodePath, tableName, newTableName),
    }
    dispatch(updateTabsInfo({ key: queryTabKey, tabName: newTableName }))
    dispatch(updatePaneInfo({ key: queryTabKey, paneInfo: newFileds }))
  }
  // 保存信息
  const onSaveInfo = async () => {
    const { tableInfo = {} } = generateParamsRef.current

    let res = await generateSqlPreview({
      ...(generateParamsRef.current || {}),
      tableInfo: {
        ...tableInfo,
        comment:
          tableInfo?.comment &&
          correctComment(connectionType, tableInfo.comment),
      },
    })
    setSql(res?.sql || '')
  }

  // 执行修改sql
  const onClickExecSql = async () => {
    if (connectionId) {
      const result = await executeMenuActionSql({
        connectionId: connectionId,
        dataSourceType: connectionType,
        databaseName,
        operatingObject: schemaName,
        tabKey: queryTabKey,
        statements: [sql],
      })

      const executeResultFlag = result.reduce((pre, cur) => {
        if (cur.success) pre++
        return pre
      }, 0)
      //change tabName should update tabInfo
      if (result?.[0]?.success && newTableName !== tableName) {
        onUpdateTabInfo()
      }
      if (executeResultFlag === result.length) {
        message.success('执行成功')
      } else if (executeResultFlag > 0) {
        message.warning('部分语句执行失败')
      } else {
        message.error('执行失败')
      }

      await resetStatus()

      dispatch(refreshOnRoot())
    }
  }

  // 重置原始状态
  const resetStatus = async () => {
    // 重置显示sql值
    setSql('')
    // 重新请求当前数据库信息
    // 如果修改了表名，需要重新根据新表名请求
    if (generateParamsRef.current?.tableInfo?.newTableName) {
      nodePath = nodePath?.replace(
        new RegExp(`${nodeName}`),
        generateParamsRef.current?.tableInfo?.newTableName,
      )
      nodeName = generateParamsRef.current?.tableInfo?.newTableName
    } else if (generateParamsRef.current?.tableName) {
      // 如果没有修改表名，根据当前的表名
      nodeName = generateParamsRef.current?.tableName
      let path = nodePath?.split('/')
      nodePath = path?.slice(0, path.length - 1).join('/') + nodeName
    }

    tryGetTableInfo().then((data) => {
      // generateParamsRef.current = {}
      // fix CQ-1221 获取最新的表名
      generateParamsRef.current.tableName = data?.tableName
    })
  }

  // 切换基本信息 表名，表注释
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onchangeBaseInfo = useCallback(_.throttle(onSaveInfo, 1000), [])
  //当前数据源 表结构
  const CURRENT_TABLE_STRUCTURE_TABS = useMemo(() => getPointDataSourceTabs(connectionType, nodeType), [connectionType])
  
  return (
    <div className={styles.designTablePane}>
      <Spin spinning={getTableColumnsLoading || (!!tableName && !newTableName)}>
        <Descriptions column={2}>
          {/* todo: 升级 antd 版本，使用 contentStyle 和 labelStyle */}
          <Descriptions.Item label="连接名">{connectionName}</Descriptions.Item>
          {!['DamengDB', 'DB2'].includes(connectionType) && (
            <Descriptions.Item label="数据库">{databaseName}</Descriptions.Item>
          )}
          {INCLUDE_SCHEMA_CONNECTIONS.includes(
            connectionType,
          ) && (
            <Descriptions.Item label="Schema">{schemaName}</Descriptions.Item>
          )}
        
          <Descriptions.Item label="表名">
            <Input.TextArea
              value={newTableName}
              disabled={modeType === 'viewTableStructure'}
              onChange={(e) => {
                setNewTableName(e.target.value)
                if (e.target.value !== tableName) {
                  generateParamsRef.current.tableInfo = {
                    ...generateParamsRef.current.tableInfo,
                    newTableName: e.target.value,
                  }
                } else {
                  generateParamsRef.current.tableInfo = {}
                }
                onchangeBaseInfo()
              }}
              style={{ width: 320 }}
            />
          </Descriptions.Item>
          <Descriptions.Item label="表注释">
            <Input.TextArea
              value={newTableComment}
              disabled={modeType === 'viewTableStructure'}
              onChange={(e) => {
                setNewTableComment(e.target.value)

                if (
                  (_.isEmpty(e.target.value) && _.isEmpty(tableComment)) ||
                  e.target.value === tableComment
                ) {
                  let tableInfo = generateParamsRef.current.tableInfo
                  delete tableInfo?.comment
                  generateParamsRef.current.tableInfo = {
                    ...tableInfo,
                  }
                } else if (e.target.value !== tableComment) {
                  generateParamsRef.current.tableInfo = {
                    ...generateParamsRef.current.tableInfo,
                    comment: e.target.value,
                  }
                }

                onchangeBaseInfo()
              }}
              style={{ width: 320 }}
            />
          </Descriptions.Item>
        </Descriptions>
        <MoreTableInfoFields  connectionType={connectionType} tableFieldInfos={tableInfo}/>
        </Spin>
       <div style={{height: '100%'}}>
        <Tabs
          tabPosition="top"
          className={styles.tab}
          activeKey={tabsActiveKey}
          onChange={async (active) => {
            setTabsActiveKey(active)
            if (active === 'ddl') {
              await getDDL({
                tableName,
                databaseName,
                schemaName,
              })
            }
          }}
        >
          {CURRENT_TABLE_STRUCTURE_TABS.map((keyName: any) => {
            if (['partition', 'temporaryPartition'].includes(keyName) && modeType === 'viewTableStructure') {
              return (
                <TabPane tab={DESIGN_TABS_FILED_MAPPING[keyName]} tabKey={keyName} key={keyName}>
                  <PartitionPane
                    tabKey={keyName}
                    connectionId={connectionId}
                    databaseName={databaseName}
                    schemaName={schemaName}
                    connectionType={connectionType}
                    tableName={tableName || ''}
                    disbaledEdit={modeType === 'viewTableStructure'}
                    onSave={async (data) => {
                      // @ts-ignore
                      const pkResult = data.primaryKey
                      const oldPkName = generateParamsRef.current?.primaryKey?.oldPkName
    
                      generateParamsRef.current = {
                        ...generateParamsRef.current,
                        ...data,
                      }
    
                      // 主键有修改，则需传 oldPkName
                      if (pkResult !== undefined && oldPkName != null) {
                        generateParamsRef.current.primaryKey = {
                          ...data.primaryKey,
                          oldPkName: oldPkName
                        }
                      }
                      await onSaveInfo()
                      setTabsActiveKey('sql')
                    }}
                  />
                </TabPane>
              )
            }
            if (!ENABLE_DESIGN_FIELDS.includes(keyName)) return;
            return (
              <TabPane tab={DESIGN_TABS_FILED_MAPPING[keyName]} key={keyName} tabKey={keyName}>
                <DesignPane
                  connectionId={connectionId}
                  databaseName={databaseName}
                  connectionType={connectionType}
                  schemaName={schemaName}
                  tableName={tableName || ''}
                  tableKey={keyName}
                  successfulCallback={() => tryGetTableInfo()}
                  queryTabKey={queryTabKey}
                  nodePath={nodePath}
                  nodeType={nodeType}
                  nodePathWithType={nodePathWithType}
                  onSave={async (data) => {
                    // @ts-ignore
                    const pkResult = data.primaryKey
                    const oldPkName = generateParamsRef.current?.primaryKey?.oldPkName

                    generateParamsRef.current = {
                      ...generateParamsRef.current,
                      ...data,
                    }

                    // 主键有修改，则需传 oldPkName
                    if (pkResult != undefined && oldPkName != null) {
                       generateParamsRef.current.primaryKey = {
                         ...data.primaryKey,
                         oldPkName: oldPkName
                       }
                    }
                    await onSaveInfo()
                    setTabsActiveKey('sql')
                  }}
                  sql={sql}
                  disabledEdit={modeType === 'viewTableStructure'}
                />
              </TabPane>
            )
          })}
         
          {modeType === 'viewTableStructure' && CURRENT_TABLE_STRUCTURE_TABS.includes('option') && (
              <TabPane tab="选项" key="option" tabKey="option">
                <OptionPane
                    connectionId={connectionId}
                    connectionType={connectionType}
                    databaseName={databaseName}
                    schemaName={schemaName}
                    tableName={tableName || ''}
                />
              </TabPane>
          )}
          {modeType !== 'viewTableStructure' && (
            <TabPane tab="SQL预览" key="sql" tabKey="sql">
              <div style={{ marginBottom: '16px' }}>
                <Button
                  size="small"
                  type="primary"
                  onClick={async () => {
                    if (sql) {
                      await onClickExecSql()
                    }
                  }}
                >
                  提交
                </Button>
              </div>
              <Input.TextArea
                placeholder={
                  '操作完数据库变更，可以到这边查看sql语句，确认无误后，点击保存执行'
                }
                autoSize={{ minRows: 10 }}
                value={sql}
                readOnly
              />
            </TabPane>
          )}
          <TabPane tab="DDL" key="ddl" tabKey="ddl">
            <Input.TextArea
              autoSize={{ minRows: 10, maxRows: 25 }}
              value={ddlData?.ddl}
              readOnly
            />
          </TabPane>
        </Tabs>
        </div>
    
    </div>
  )
}
