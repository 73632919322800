// 申请变更
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, message } from 'antd';
import { FormContext } from '../../ApplyFormContext';
import { SimpleBreadcrumbs } from 'src/components';
import styles from './index.module.scss';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ApplyItem, applicationAmend, approveApplication, getDataChangeDetail } from 'src/api';
import { useRequest } from 'src/hook';
import moment from 'moment';
import {
  ApplyInfoCard,
  ExecuteChange,
  ReviewProcess,
  SQLCheckForm,
  SimuleEnvironmentValidation,
  SqlCard 
} from 'src/pageTabs/data-change/components';
import ModalDataChangeSelectNewApprover from 'src/pageTabs/flowPages/flowDetails/ModalDataChangeSelectNewApprover';


interface LocationState {
  flowTaskId?: string;
  applyUserId?: string;
  status?: string;
  orderType?: string; // 用于区别 从工单管理跳转的
}

export const WorkOrder: FC = () => {

  const history = useHistory();
  const [form] = Form.useForm();
  const { id } = useParams<{ id?: string }>();
  const isViewDetail = !!id ;
  //存储当前数据
  const [currentContextValue, setCurrentContextValue] = useState<
    ApplyItem | any
    >({type: 1, sqlType: 1});

  const [visible_setNewApprover, setVisible_setNewApprover] = useState(false) // 新审批人 modal visible
  const [currentTransferApprover, setCurrentTransferApprover] = useState<
    Array<string>
  >([]) // 新审批人 modal visible

  const [operateBtn, setOperateBtn] = useState<any>([]);

  const isRenderRight = !(isViewDetail && currentContextValue?.executeStatus === "审批中");

  const { state }: { state: LocationState }  = useLocation();
  const flowTaskId = state?.flowTaskId || "";
  const applyUserId = state?.applyUserId || "";
  const orderType = state?.orderType || "";

  const isOrderManagement = orderType === "FLOW_WORK_ORDER_MANAGEMENT";

  const BREADCRUMB_ITEMS = [
    {
      title: '数据变更',
    },
    isOrderManagement?
    {
      title: '工单管理',
      href: '/data_change_work_order_management',
      onclick: () => history.go(-1)
    }
    :
    {
      title: '我的审批',
      href: '/data_change_mine_approve',
      // CQ-3664 消息通知--数据变更类型的消息通知点击查看详情，详情页面包屑跳转问题
      // onclick: () => history.go(-1)
    },
    {
      separator: '/',
      title:  '工单详情',
    },
  ]
  

  const getActions = (status: string) => {
    switch (status) {
      case '待审核':
        if (isOrderManagement) {
          return [
            { label: '转审', key: 'transfer' },
          ]
        } else {
          return [
            { label: '转审', key: 'transfer' },
            { label: '驳回', key: 'reject' },
            { label: '通过', key: 'pass', bgColor: "#3262ff", color: "#ffffff" },
          ]
        }
    }
  }

  //申请详情
  const { data: applyDetail, run: runGetDataChangeDetail, refresh } = useRequest(getDataChangeDetail, {
    manual: true,
    onSuccess: (data:ApplyItem) => {
      if (data) {
        form.setFieldsValue({
          ...data,
          executeTime: data?.executeTime ?moment(data.executeTime) : null
        })
      }
      setCurrentContextValue({...data, executeTime: data?.executeTime ?moment(data.executeTime) : null})
    },
  })

  useEffect(() => {
    if (isViewDetail ) {
      runGetDataChangeDetail(id)
    }
  }, [isViewDetail, id, runGetDataChangeDetail])
  
  useEffect(() => {
    // 工单页面可进行的操作btn显示
    setOperateBtn(getActions(applyDetail?.frontStatus));
  }, [applyDetail?.frontStatus])

  //修改执行时间
  const { run: runApplicationAmend } = useRequest(applicationAmend, {
    manual: true,
  })

  // 通过驳回审批
  const { run: runExecuteFlowTask } = useRequest(approveApplication, {
    manual: true,
  })
  // 通过
  const onExecuteFlowTask = async (params:any) => {
    let defaultFlowParams = {
      approvedFlag: true,
      flowId: currentContextValue?.flowId,
      approvedTime:  '',
      taskId: flowTaskId || '',
    } 
    const paramsKeys = Object.keys(params);
    if (paramsKeys.includes('executeTimeType') || paramsKeys.includes('executeTime')) {
      const amendParams = {
        id: currentContextValue?.id,
        approveUserComment: '',
        executeTimeType: params?.executeTimeType || applyDetail?.executeTimeType,
        executeTime: params?.executeTime ? moment(params?.executeTime).format('YYYY-MM-DD HH:mm:ss') : ''
      }
      await runApplicationAmend(amendParams);
      runExecuteFlowTask(defaultFlowParams).then(() => {
        message.success( '通过成功' )
        refresh()
      })
    } else {
      runExecuteFlowTask(defaultFlowParams).then(() => {
        message.success( '通过成功' )
        refresh()
      })
    }
  }

  // 驳回
  async function confirm(params: any ) {
    async  function isEmpty(closeFn: any, val?: string) {
      if (!val) {
        message.warn('请输入驳回理由')
        return
      }
      const amendParams = {
        id: currentContextValue?.id,
        approveUserComment: val,  // 驳回理由
        executeTimeType: params?.executeTimeType || applyDetail?.executeTimeType,
        executeTime: params?.executeTimeType === 1? '' :
        params?.executeTime ? moment(params?.executeTime).format('YYYY-MM-DD HH:mm:ss') : applyDetail?.executeTime || ''
      }
      
      await runApplicationAmend(amendParams)
      const res  = await runExecuteFlowTask({
        approvedFlag: false,
        flowId: currentContextValue?.flowId,
        refuseReasonVo:{},
        taskId: flowTaskId || '',
      })
      if (res) {
        message.success( '驳回成功')
        history.go(-1)
      }

      closeFn()
    }

    const textOnchange = (val: string) => {
      update({
        onOk: (closeFn: any) => isEmpty(closeFn, val),
      })
    }

    const { update } = Modal.confirm({
      title: '驳回理由',
      content: (
        <Input.TextArea
          rows={5}
          onChange={(e) => textOnchange(e.target.value)}
        />
      ),
      okText: '确认',
      cancelText: '取消',
      onOk: (closeFn) => isEmpty(closeFn),
    })
  }

  const renderOperateBtn = useMemo(() => {
    return (
      <div className={styles.operateBtn}>
        {operateBtn?.map((items: {label?: string, key?: string, bgColor?: string, color?: string }) => {
          return (
            <Button
              style={{marginRight: "5px", backgroundColor: items?.bgColor || '', color: items?.color || ''}}
              onClick={() => {
                const key = items?.label;
                switch (key) {
                  case '转审':
                      setVisible_setNewApprover(true)
                      setCurrentTransferApprover([flowTaskId]) //应该使用当前userId
                      break
                    case '通过':
                      form.validateFields().then((values) => {
                        onExecuteFlowTask(values);
                      })
                      break
                    case '驳回':
                      form.validateFields().then((values) => {
                        confirm(values);
                      })
                      break
                    default:
                      break
                }
              }}
            >
              {items?.label}
            </Button>
          )
        })}
      </div>
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContextValue?.id, flowTaskId, form, operateBtn])

  return (
    <div
      style={{
        height: '100%',
        backgroundColor: "#fff",
      }}
    >
      <FormContext.Provider
        value={{
          form,
          currentContextValue,
          setCurrentContextValue,
        }}
      >
        <SimpleBreadcrumbs items={BREADCRUMB_ITEMS} />
        <div className={styles.contentWrap}>
          <Row gutter={[8, 8]} className={styles.content} >
            {/* 申请信息 */}
            <Col span={24} >
              <div className={styles.applyInfoWrap}>
                <ApplyInfoCard 
                  form={form}
                  currentRouteType='MINE_APPROVE'
                />
              </div>
            </Col>
            {/* SQL文本 */}
            <Col span={isRenderRight? 13 : 24} >
              <div className={styles.sqlContentWrap}>
                <SqlCard />
              </div>
            </Col>
            {/* 工单流转、执行变更 */}
            {
              isRenderRight && 
              <Col span={11}>
              <div className={styles.contentRightWrap}>
                <ReviewProcess />
                <ExecuteChange />
              </div>
            </Col>
            }
            {/* 审核结果 */}
            <Col span={24} >
              <div className={styles.sqlCheckWrap}>
                {
                  !(isViewDetail && currentContextValue?.executeStatus !== "审批中") &&
                  <SQLCheckForm
                    isViewDetail={isViewDetail}
                    currentRouteType={"MINE_APPROVE"}
                  />
                }
              </div>
            </Col>
            {/* 模拟数据验证 */}
            <Col span={24} >
              {!(isViewDetail && (currentContextValue?.executeStatus !== "审批中" || currentContextValue?.type === 1)) &&
                <SimuleEnvironmentValidation
                  form={form}
                  isViewDetail={isViewDetail}
                  applyUserId={applyUserId}
                  currentRouteType={"MINE_APPROVE"}
                />
              }
            </Col>
          </Row>
        </div>
      </FormContext.Provider>
      {
        operateBtn &&
        <div className={styles.operateBtnWrap} >
          {renderOperateBtn}
        </div>
      }
      <ModalDataChangeSelectNewApprover
        cleanParentComponentData={() => {
          setCurrentTransferApprover([]);
          history.go(-1);
        }}
        userTasks={currentTransferApprover}
        visible_setNewApprover={visible_setNewApprover}
        setVisible_setNewApprover={setVisible_setNewApprover}
      />
    </div>
  );
}

export default WorkOrder
