// 申请变更
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row, message } from 'antd';
import { FormContext } from '../../ApplyFormContext';
import { SimpleBreadcrumbs } from 'src/components';
import styles from './index.module.scss';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { 
  ApplyItem,
  closeApplication,
  executeApplication,
  executeBack,
  getDataChangeDetail,
  getUrgeReminders,
  terminateApplication,
  withdrawOrder
} from 'src/api';
import { useRequest } from 'src/hook';
import moment from 'moment';
import { ApplyInfoCard, ExecuteChange, ReviewProcess, SqlCard } from 'src/pageTabs/data-change/components';
import { STATUS_MAPPING_DETAIL } from "src/constants";

const getActions = (executeStatus: any,executeTime: string) => {
  //@ts-ignore
  let normalBtns = STATUS_MAPPING_DETAIL?.[executeStatus] ?? [];
  //手动执行不需要 执行按钮
  if (executeTime) {
    normalBtns = normalBtns?.filter((item: any) => item.label !== '执行' ) 
  }
  return normalBtns ?? []
};

export const WorkOrder: FC = () => {

  const history = useHistory();
  const [form] = Form.useForm();
  const { id } = useParams<{ id?: string; }>();
  const isViewDetail = !!id ;
  //存储当前数据
  const [currentContextValue, setCurrentContextValue] = useState<
    ApplyItem | any
    >({type: 1, sqlType: 1});

  const [operateBtn, setOperateBtn] = useState<any>([]);

  const BREADCRUMB_ITEMS = [
    {
      title: '数据变更',
    },
    {
      title: '我的申请',
      href: '/data_change_mine_apply',
      onclick: () => history.go(-1)
    },
    {
      separator: '/',
      title: '工单详情',
    },
  ]

  //申请详情
  const { run: runGetDataChangeDetail, refresh } = useRequest(getDataChangeDetail, {
    manual: true,
    onSuccess: (data:ApplyItem) => {

      // 工单页面可进行的操作btn显示
      setOperateBtn(getActions(data?.executeStatus, data?.executeTime || ''));
      
      setCurrentContextValue({...data, executeTime: data?.executeTime ?moment(data.executeTime) : null})
    },
  })

  //工单流转-撤回 撤回申请
  const { run: runWithDrawApplication } = useRequest(withdrawOrder, {
    manual: true,
    onSuccess: () => {
      message.success('撤回成功')
      history.go(-1)
    },
  })

  // 催一下
  const { run: runGetUrgeReminders } = useRequest(getUrgeReminders, {
    manual: true,
    onSuccess: () => {
      message.success('催办成功');
    },
  })

  //执行
  const { run: runExecuteApplication } = useRequest(executeApplication, {
    manual: true,
    onSuccess: () => {
      refresh();
    }
  })
  //中止
  const { run: runTerminateApplication } = useRequest(terminateApplication, {
    manual: true,
    onSuccess: () => {
      refresh();
    }
  })

  //终止
  const { run: runCloseApplication } = useRequest(closeApplication, {
    manual: true,
    onSuccess: () => {
      history.go(-1)
    }
  })
  //回退
  const { run: runExecuteBack } = useRequest(executeBack, {
    manual: true,
    onSuccess: () => {
      refresh();
    }
  })

  useEffect(() => {
    if (isViewDetail) {
      const idPar:string | undefined = id;
      idPar && runGetDataChangeDetail(idPar)
    }
  }, [id, isViewDetail])

  const renderOperateBtn = useMemo(() => {
    return (
      <div className={styles.operateBtn}>
        {operateBtn?.map((items: {label?: string, key?: string, bgColor?: string, color?: string }) => {
          return (
            <Button
              style={{marginRight: "5px", backgroundColor: items?.bgColor || '', color: items?.color || ''}}
              onClick={() => {
                switch (items?.label) {
                  case "撤回申请":
                    currentContextValue?.flowId && runWithDrawApplication({flowId: currentContextValue?.flowId, isMyApply: true});
                    break;
                  case "催一下":
                      runGetUrgeReminders(
                        { 
                          flowId: Number(currentContextValue?.flowId),
                          senderIds: currentContextValue?.approveUserId
                        }
                      );
                    break;
                  case "中止任务":
                    runTerminateApplication(currentContextValue?.id);
                    break;
                  case "执行":
                    runExecuteApplication(currentContextValue?.id);
                    break;
                  case "再次申请":
                    history.push({
                      pathname: `/data_change_mine_apply/${currentContextValue?.id}/edit`,
                      state: { reapply: true, applyUserId: currentContextValue?.applyUserId },
                    });
                    break;
                  case "终止任务":
                    runCloseApplication(currentContextValue?.id);
                    break;
                  case "执行回退":
                    runExecuteBack(currentContextValue?.id);
                    break;
                }
              }}
            >
              {items?.label}
            </Button>
          )
        })}
      </div>
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentContextValue?.applyUserId,
    currentContextValue?.approveUserId,
    currentContextValue?.flowId,
    currentContextValue?.id,
    operateBtn,
    runCloseApplication,
    runExecuteApplication,
    runExecuteBack,
    runGetUrgeReminders,
    runTerminateApplication,
    runWithDrawApplication
  ])

  return (
    <div
      style={{
        height: '100%',
        backgroundColor: "#fff",
      }}
    >
      <FormContext.Provider
        value={{
          form,
          currentContextValue,
          setCurrentContextValue,
        }}
      >
        <SimpleBreadcrumbs items={BREADCRUMB_ITEMS} />
        <div className={styles.contentWrap}>
          <Row gutter={[8, 8]} className={styles.content} style={{height: "260px"}}>
            {/* 申请信息 */}
            <Col span={24} >
              <div className={styles.applyInfoWrap}>
                <ApplyInfoCard currentRouteType='MINE_APPLY'/>
              </div>
            </Col>
          </Row>
          <Row gutter={[8, 8]} className={styles.content} style={{height: "calc(100% - 260px"}}>
            {/* SQL文本 */}
            <Col span={13} >
              <div className={styles.sqlContentWrap}>
                <SqlCard />
              </div>
            </Col>
            {/* 工单流转、执行变更 */}
            <Col span={11}>
              <div className={styles.contentRightWrap}>
                <ReviewProcess />
                <ExecuteChange onRefresh={() => refresh()} />
              </div>
            </Col>
          </Row>
        </div>
      </FormContext.Provider>
      { operateBtn &&
        <div className={styles.operateBtnWrap} >
          {renderOperateBtn}
        </div>
      }
    </div>
  );
}

export default WorkOrder
