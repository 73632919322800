import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import { Checkbox } from 'antd';
import { DrawerWrapper } from 'src/components';

interface IProps {
  currentCheckedColumnFields: string[]
  nonModifyColumnFields: string[]
  allTableColumnFields: any;
  visible: boolean
  onCancel: () => void
  onOk: (fields: string[]) => void
}

export const CustomTableColumnPanel = ({
  allTableColumnFields = {},
  nonModifyColumnFields = [], //禁止修改
  currentCheckedColumnFields,
  visible = false,
  onCancel,
  onOk,
}: IProps) => {

  const [checkedColumnFields, setCheckedColumnFields] = useState<string[]>([]);
	
  useEffect(() => {
    setCheckedColumnFields(currentCheckedColumnFields);
  },[currentCheckedColumnFields])

  return (
    <DrawerWrapper
      title="自定义列表项"
      width={400}
      visible={visible}
      
      onCancel={() => {
        onCancel();
        setCheckedColumnFields(currentCheckedColumnFields);
      }}
      onOk={() => {
        onOk(checkedColumnFields)
      }}
    >
      <Checkbox.Group
        value={checkedColumnFields}
        //@ts-ignore
        onChange={(checkedValue: string[]) =>
          checkedValue && setCheckedColumnFields(checkedValue)
        }
      >
        {Object.keys(allTableColumnFields).map((key: any) => (
          <>
            <Checkbox
              key={key}
              disabled={nonModifyColumnFields.includes(key)}
              value={key}
            >
              {allTableColumnFields[key]}
            </Checkbox>
            <div style={{ padding: '8px 0' }} />
          </>
        ))}
      </Checkbox.Group>
    </DrawerWrapper>
  )
}
