import {
  ColDef,
  Column,
  GridReadyEvent,
  IDatasource,
  IGetRowsParams,
  RowEditingStartedEvent,
  RowEditingStoppedEvent,
  RowNode,
} from '@ag-grid-community/core'
import type { ICellRendererParams } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { message, Modal, Spin } from 'antd'
import classNames from 'classnames'
import copy from 'copy-to-clipboard'
import cloneDeep from 'lodash/cloneDeep'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ActioneType,
  downloadGridCell,
  executeSqlSegment,
  executeSqlStatement,
  explainSqlStatement,
  getCompleteCell,
  getTaskResult,
  makeResultAllExport,
  MessageData,
  modifyResult,
  PlainRowData,
  QueryBase,
  QueryResult,
  ResultColumnInfo,
  ResultModify,
  getSegmentResults,
  QueryParams
} from 'src/api'
import { CustomHeader, Iconfont, indexColDef, indexColId } from 'src/components'
import type { TextEditorProps, IGridContext } from 'src/components'
import { useDispatch, useSelector, useRequest } from 'src/hook'
import { openFlowForm } from 'src/pageTabs/flowPages/flowFormsSlice'
import {
  setPageTotalByQueryKey,
  updateResultTabs
} from 'src/pageTabs/queryPage/resultTabs/resultTabsSlice'
import Service from 'src/service'
import {
  activePaneInfoSelector,
  setTabExecutionStatus, setTabExecutionStatusPercentage,
} from '../../queryTabs/queryTabsSlice'
import { useCopyableChange } from '../useCopyableChange'
import { AddResultExportModal } from './AddResultExportModal'
import { AddSelectedResultExportModal } from './AddSelectedResultExportModal'
import { CellViewerModal, isCellTag } from './CellViewerModal'
import styles from './grid.module.scss'
import {
  defaultColDef,
  GridConfigBase,
  infiniteModeOptions,
  BLOCK_SIZE,
} from './gridConfig/GridConfig'
import { ResultSider } from './ResultSider'
import { ResultToolbar, ToolbarActionType } from './ResultToolbar'
import { RowViewerModal } from './RowViewerModal'
import { getAlteredRowData } from './util'
import { useGridStatus, IGridStatus } from './useGridStatus'
import { ResultAllExport } from './ResultAllExport'
import {
  saveExecuteActiveTabParams
} from 'src/pageTabs/queryPage/queryTabs/queryTabsSlice'
import { useHandleExternalInfo } from 'src/hook/useHandleExternalInfo'
import { useDownloadNotificationRenderer } from 'src/features/websocket/useDownloadNotification'
import { DesensitizedFieldsModal } from './DesensitizedFieldsModal'

interface ResultGridProps {
  tabResultKey?: string
  result: QueryResult
  queryKey: string
  type?: 'explain' | 'execute'
  executePercentage?: number
  executeStatusMessage?: string
}

export const ResultGrid: React.FC<ResultGridProps> = (props) => {

  const dispatch = useDispatch()


  const { tabResultKey, result, queryKey, type, } = props

  const {
    connectionId,
    dataSourceType,
    columnInfos,
    detailedResultData,
    statement,
    statementObject,
    statementObjectWithQuoteCharacter,
    editable,
    operatingObject,
    operatingObjectWithQuoteCharacter,
    dataExport,
    databaseName,
    refreshable,
    scale,
    permissionResult,
    filteredColumns,
    desensitized,
    doubleCheckType,
    isDesensitizedPlaintext,
    currentParams,
    resultData: currentRusultData,
  } = result || {}

  // 有脱敏字段并且开启了二次复核
  const isDesensitized = desensitized && ![undefined, null, 'NONE'].includes(doubleCheckType)

  const isExplain = statement.includes('explain')

  const { status, dispatchAction } = useGridStatus()
  const [loading, setLoading] = useState<boolean>(false)
  const [actionType, setActionType] = useState<ActioneType>('ROLLING_RESULT')
  const [visibleCellViewer, setVisibleCellViewer] = useState(false)
  const [visibleRowViewer, setVisibleRowViewer] = useState(false)
  const [visibleCommonExport, setVisibleCommonExport] = useState(false)
  const [visibleSelectedExport, setVisibleSelectedExport] = useState(false)
  const [visibleExportAll, setVisibleExportAll] = useState(false)
  const [visibleDesensitizedFields, setVisibleDesensitizedFields] = useState(false)
  const [selectedNodes, setSelectedNodes] = useState<RowNode[]>([])
  const { theme } = useSelector((state) => state.appearance)
  const { plSql } = useSelector(activePaneInfoSelector) || {}
  const { handleExternalInfo } = useHandleExternalInfo()
  const [renderNotification] = useDownloadNotificationRenderer()
  
  const allResultDataRef = useRef<any[]>([]);
  
  const formatColumn = (columns: ResultColumnInfo[]) => {
    return columns.map((def) => {
      const {
        columnName,
        comment,
        renderType,
        cellEditor,
        desensitization,
        sortable,
        filterable,
        nullable,
        downloadable,
        editable: columnEditable,
      } = def
      const isDesens = Service.moduleService.isModuleExist('/flow')
        ? desensitization?.status
        : false
  
      const cellEditorParams: Pick<
        TextEditorProps,
        'cellEditorType' | 'nullable' |'renderType' | 'downloadable'
      > = {
        cellEditorType: cellEditor,
        nullable,
        renderType,
        downloadable // 单元格是否支持下载
      }
  
      return {
        field: columnName,
        sortable: !['INSERT', 'UPDATE'].includes(status) && sortable,
        filter: filterable,
        editable: (params: any) => {
          // todo: do not hack
          // 如果是undefined，则消除影响
          const columnEditableFilter =
            columnEditable === undefined ? true : columnEditable
          return (
            editable &&
            columnEditableFilter &&
            renderType !== 'binary' &&
            !Object.values(params?.data || {})?.some((value) =>
              isCellTag(String(value)),
            )
          )
        },
        // valueGetter(params) {
        //   const column = params.column.getColId()
        //   const originData = params.data?.[column]
        //   // if (originData === null) return '<null>'
        //   return originData
        // },
        cellRenderer: 'SimpleTextRendererWithContextMenu',
        cellEditor: 'textEditor',
        cellEditorParams,
        headerComponentFramework: CustomHeader,
        headerComponentParams: { headerName: columnName, comment, isDesens },
      }
    })
  }
  
  const { tabInfoMap, paneInfoMap, activeTabKey } = useSelector(
    (state) => state.queryTabs,
    )
  const { 
    resultTabMap,
  } = useSelector((state) => state.resultTabs)
  const setPageTotal = useCallback((pageTotal: number) => {
      dispatch(setPageTotalByQueryKey({ queryKey, pageTotal }))
    }, [dispatch, queryKey])
    const { copySetting } = useSelector((state) => state.login.userInfo)
  const paneType = tabInfoMap[queryKey].paneType
  const txMode = paneInfoMap[queryKey].txMode
  const { connectionType } = paneInfoMap?.[activeTabKey] ?? {}
  
  const columnDefs: ColDef[] = formatColumn(columnInfos)
  
  columnDefs.unshift({
    ...indexColDef,
    cellRenderer: 'RowIndexRendererWithContextMenu',
  })

  /** 各个编辑状态下激活的 toolbarActions */
  const enabledActionsMap: { [key in IGridStatus]: ToolbarActionType[] } = {
    NORMAL: ['refresh', 'add'],
    UPDATE: ['refresh', 'confirm', 'cancel'],
    INSERT: ['refresh', 'confirm', 'cancel'],
    DELETE: ['refresh', 'add', 'delete'],
    CLONE: ['refresh', 'confirm', 'cancel'],
  }

  // const [total, setTotal] = useState<number>(0)
  /** 获取可用总行数 */
  /**
   * params
   * 	"connectionId": 1,
    "connectionType": "MySQL",
    "databaseName": "test",
    "statement": "select * from a1"
   * **/
  // useEffect(() => {
  //   permissionExportNumber({
  //     connectionId,
  //     connectionType: result.dataSourceType,
  //     databaseName: result.databaseName,
  //     statement: result.statement,
  //     containTempTable: result.containTempTable,
  //     tabKey: result.tabKey
  //   }).then((v) => {
  //     setTotal(v)
  //   })
  // }, [result, dataExport, visibleCommonExport, connectionId])

  // 结果页再编辑的参数 columnInfos
  const ColumnInfosEdit = useMemo(
    () =>
      columnInfos.map(({ columnName, cellEditor, defaultValue }) => ({
        columnName,
        columnType: cellEditor,
        defaultValue,
      })),
    [columnInfos],
  )

  const filterNames = useMemo(() => {
    if (!filteredColumns) return []
    return filteredColumns.map(item => item.columnName)
  }, [filteredColumns])

  const getRowDataTemplate = useCallback(() => {
    const newRowData = ColumnInfosEdit.reduce((total: any, colInfo: any) => {
      total[colInfo?.columnName] = colInfo.defaultValue ?? null
      return total
    }, {})
    return newRowData
  }, [ColumnInfosEdit])

  /* 执行模块获取的 初始块数据 */
  const isInitializeDataUsed = useRef<boolean>(false)
  /* 获取远程 块数据 */
  const { run: fetchBlockDataOrFromStore } = useRequest(
    async (
      offset: number = 0,
      sortModels: any,
      filterModel: any,
      rowCount: undefined | number = BLOCK_SIZE,
    ) => {
      /* 0 首块数据，第一次获取时使用初始化数据。第二次获取使用远程数据 */
      if (offset === 0 && !isInitializeDataUsed.current) {
        isInitializeDataUsed.current = true
        return Promise.resolve([cloneDeep(result)])
      }
      const payload = {
        connectionId,
        dataSourceType,
        databaseName,
        operatingObject,
        statements: [statement],
        offset,
        rowCount,
        tabKey: queryKey,
        plSql,
        sortModels,
        filterModel,
        autoCommit: txMode === 'auto',
        actionType: actionType,
      }
      // 设置执行 pending
      dispatch(setTabExecutionStatus({ key: queryKey, pending: true }))
      dispatch(setTabExecutionStatusPercentage({key: queryKey, executePercentage: 0, executeStatusMessage: '正在执行...0%'}));
      if (type === 'explain') {
        const res = explainSqlStatement(isDesensitizedPlaintext ? currentParams : payload)
        // 恢复面板为可执行状态
        dispatch(setTabExecutionStatus({ key: queryKey, pending: false }))
        dispatch(setTabExecutionStatusPercentage({key: queryKey, executePercentage: 100, executeStatusMessage: '已执行结束'}));
        return res
      } else {
        const res = await executeSqlSegment(isDesensitizedPlaintext ? currentParams : payload) 
        const { channelId, groupName, messageId } = res
        const params = { channelId, groupName, messageId }
        const result = await recursiveQuery(params);
        return result
      }
    },
    {
      manual: true,
      onSuccess: () => {
        setActionType('ROLLING_RESULT')
      }
    },
  )

  const recursiveQuery = async (params: QueryParams, delay = 100): Promise<QueryResult[]> => {
    try {
      const data = await getSegmentResults(params);
      if (data && data?.messageData) {
        const { executionInfos } = data?.messageData;
        let queryResult: QueryResult[] = [];
        executionInfos.forEach((item: any) => {
          queryResult.push(item.response);
          handleExternalInfo({ type: 'LOG', msg: item.executeLogInfo.message });
          // 结果集刷新后需要更新表头（比如修改脱敏字段后应更新显示）
          if (actionType === 'REFRESH' && item.response) {
            const newColumns: ColDef[] = formatColumn(columnInfos);
  
            newColumns.unshift({
              ...indexColDef,
              cellRenderer: 'RowIndexRendererWithContextMenu',
            });
            item.response.columnInfos && gridApiRef.current?.api.setColumnDefs(newColumns);
          }
        });
        // 恢复面板为可执行状态
        dispatch(setTabExecutionStatus({ key: queryKey, pending: false }));
        dispatch(setTabExecutionStatusPercentage({key: queryKey, executePercentage: 100, executeStatusMessage: '已执行结束'}));
        return queryResult;
      }
      if (data && data.executeStatus === 'RUNNING') {
        // 继续下一次查询，等待 3000 毫秒
        return new Promise((resolve) => {
          setTimeout(async () => {
            // 继续下一次查询，增加延迟从100ms开始，每次增加100ms，但不超过3000ms
            const max = 3000;
            let nextDelay = delay;
            if (delay < max){
              nextDelay = delay + 100;
            }
            const result = await recursiveQuery(params, nextDelay);
            resolve(result);
          }, delay);
        });
      }
      // 添加一个默认返回值
      return [];
    } catch (error) {
      console.error('轮询出错：', error);
      throw error;
    }
  };

  const getLastRow = useCallback((blockData: unknown[], offset: number) => {
    if (blockData?.length < BLOCK_SIZE) {
      return blockData.length + offset
    }
  }, [])

  const formatFilterModel = (filterModel: IGetRowsParams['filterModel']) => {
    const formattedFilterModel = Object.keys(filterModel)
      .map((key) => {
        const model = filterModel[key]
        if (model.operator) {
          // 双重过滤
          const { condition1, condition2, ...rest } = model
          return [
            key,
            {
              ...rest,
              conditions: [model.condition1, model.condition2],
            },
          ]
        }
        return [key, { conditions: [model] }]
      })
      .reduce(
        (prev, [k, v]) => ({ ...prev, [k]: v }),
        {} as Record<string, any>,
      )
    return formattedFilterModel
  }

  const gridApiRef = useRef<GridReadyEvent | null>(null)
  const handleGridReady = (options: GridReadyEvent): void => {
    gridApiRef.current = options
  }

  // !HACK: 保存第一块数据请求时的引用，用于直接修改第一块数据
  const firstCacheCbRef = useRef<
    (rowsThisBlock: any[], lastRow?: number | undefined) => void
  >(() => { })
  const firstCacheRef = useRef<PlainRowData[]>([])

  const getRows: (params: IGetRowsParams) => void = useCallback(
    async (params) => {
      // 在数据量大或其他情况导致获取结果慢的情况下，不允许有其他获取结果集的操作
      setLoading(true)
      const {
        startRow,
        endRow,
        sortModel,
        filterModel,
        successCallback,
        failCallback,
        context,
      } = params

      const { status } = context as IGridContext
      const api = gridApiRef.current?.api
      const mockEmptyRow = getRowDataTemplate()
      if (status === 'INSERT') {
        // 空表添加行时触发 getRows
        if (startRow === 0) {
          successCallback([mockEmptyRow])
          api?.startEditingCell({
            rowIndex: 0,
            colKey: columnDefs[1].field!,
          })
          // 空行添加时，取消loading再return
          setLoading(false)
          return
        }
        // 整 BLOCK_SIZE 添加行时触发 getRows
        const refreshFirstCache = firstCacheCbRef.current
        const firstCache = firstCacheRef.current
        refreshFirstCache([mockEmptyRow].concat(firstCache))
        api?.startEditingCell({
          rowIndex: 0,
          colKey: columnDefs[1].field!,
        })
        failCallback()
        // return前取消loading
        setLoading(false)
        return
      }
      try {
        // 不支持分页不需要重新请求数据
        if(allResultDataRef.current?.length){
          const resultData = allResultDataRef.current
          const curResultData = resultData.slice(startRow, endRow)  
          let totalRows = resultData.length
          setPageTotal(totalRows)
          successCallback(curResultData, totalRows)
          return
        } 
        else if (isDesensitizedPlaintext && startRow === 0) {
          let totalRows = detailedResultData?.length
          setPageTotal(totalRows)
          successCallback(currentRusultData, getLastRow(currentRusultData, startRow))
          firstCacheRef.current = currentRusultData
          return
        }
        /* 0 格式化过滤参数 */
        const formattedFilterModel = formatFilterModel(filterModel)
        /* 1 获取块数据 */
        let response = await fetchBlockDataOrFromStore(
          startRow,
          sortModel,
          formattedFilterModel,
        )
        let [{ resultData, executeError, supportPage }] = response || []
        /* 2 错误处理 */
        if (executeError) {
          // throw Error(executeError.message)
          console.error(executeError.message)
        }
        /* 3 根据本块数据量计算总数 */
        if (!supportPage) {
          allResultDataRef.current = resultData
          let totalRows = resultData.length
          // 不分页一次返回所有数据需单独分配当前数据
          const curResultData = resultData.slice(startRow, endRow)  
          setPageTotal(totalRows)
          successCallback(curResultData, totalRows)
        } else {
          let rows = getLastRow(resultData, startRow)
          setPageTotal(resultData.length + startRow)
          successCallback(resultData, rows)
        }
        /** 4
         * !HACK: 保存第一块数据请求时的引用，用于直接修改第一块数据
         */
        if (startRow === 0) {
          firstCacheCbRef.current = successCallback
          firstCacheRef.current = resultData
        }
      } catch (error) {
        failCallback()
        console.error(error)
        // message.error(String(error))
      } finally {
        setLoading(false)
        dispatchAction('reset')
      }
    },
    [
      getRowDataTemplate,
      columnDefs,
      dispatch,
      queryKey,
      fetchBlockDataOrFromStore,
      getLastRow,
      dispatchAction,
    ],
  )

  const datasource: IDatasource = {
    getRows,
  }

  // /** 获取可用总行数方法 */
  // const getTotal = useCallback(
  //   (callback: (v: number) => void) => {
  //     permissionExportNumber({
  //       connectionId,
  //       connectionType: result.dataSourceType,
  //       databaseName: result.databaseName,
  //       statement: result.statement,
  //       containTempTable: result.containTempTable,
  //       tabKey: result.tabKey,
  //     }).then((v) => {
  //       callback(v)
  //     })
  //   },
  //   [result, dataExport, visibleCommonExport, connectionId],
  // )

  // 点击刷新按钮
  const handleRefresh = useCallback(() => {
    if (!gridApiRef.current) return
    const { api } = gridApiRef.current
    setActionType('REFRESH')
    setLoading(true)
    // 重置筛选/排序，清空 cache
    api.setFilterModel(null)
    api.setSortModel(null)
    api.ensureIndexVisible(0)
    api.setRowCount(0)
    api.purgeInfiniteCache()
  }, [])
  // 临时提供外部调用
  //@ts-ignore
  // global.handleRefresh = handleRefresh

  // 点击添加按钮
  const handleAddRow = () => {
    if (!gridApiRef.current) return
    const { api } = gridApiRef.current
    dispatchAction('startInsert')
    const rowCount = api.getInfiniteRowCount()
    if (typeof rowCount === 'undefined') return
    api.setRowCount(rowCount + 1)
    if (rowCount % BLOCK_SIZE === 0) return
    // 行数非整块时，不会触发 getRows，直接添加空行并进入编辑
    const refreshFirstCache = firstCacheCbRef.current
    const firstCache = firstCacheRef.current
    const row = getRowDataTemplate()
    refreshFirstCache([row].concat(firstCache))
    api.startEditingCell({
      rowIndex: 0,
      colKey: columnDefs[1].field!,
    })
  }

  // 点击删除按钮
  const handleDeleteRow = useCallback(async () => {
    if (!gridApiRef.current) return
    const selectedNodes = gridApiRef.current.api.getSelectedNodes()
    const resultOperationDates = selectedNodes.map(({ data }) => ({
      resultOldData: data,
    }))
    const modifyParams: ResultModify = {
      connectionId,
      dataSourceType,
      operatingObject,
      statementObject,
      columnInfos: ColumnInfosEdit,
      resultOperationDates,
      operatingObjectWithQuoteCharacter,
      statementObjectWithQuoteCharacter,
      resultOperating: 'DELETE',
      databaseName,
    }
    setLoading(true)
    try {
      const statements = await modifyResult(modifyParams)
      const executeParams = {
        connectionId,
        dataSourceType,
        operatingObject,
        statements: statements || [],
        tabKey: queryKey,
        databaseName,
        autoCommit: txMode === 'auto',
        actionType: 'UPDATE' as const,
      }
      // 设置执行 pending
      dispatch(setTabExecutionStatus({ key: queryKey, pending: true }))
      dispatch(saveExecuteActiveTabParams(executeParams))
      dispatch(setTabExecutionStatusPercentage({key: queryKey, executePercentage: 0, executeStatusMessage: '正在执行...0%'}));
      const executeResults = await executeSqlStatement(executeParams)
      const { executionInfos } = executeResults
      executionInfos.forEach((item) => {
        const { response, executeLogInfo } = item
        if (response?.executeError) message.error(response.executeError.message)
        handleExternalInfo({ type: 'LOG', msg: executeLogInfo?.message })
      })
    } catch {
    } finally {
      dispatch(setTabExecutionStatusPercentage({key: queryKey, executePercentage: 100, executeStatusMessage: '已执行结束'}));
      dispatch(setTabExecutionStatus({ key: queryKey, pending: false }))
      gridApiRef.current?.api.purgeInfiniteCache()
      setActionType('QUERY_AFTER_UPDATE')
    }
  }, [
    connectionId,
    dataSourceType,
    operatingObject,
    statementObject,
    ColumnInfosEdit,
    operatingObjectWithQuoteCharacter,
    statementObjectWithQuoteCharacter,
    databaseName,
    queryKey,
    dispatch,
    txMode
  ])

  // 点击确定按钮
  const handleConfirmModify = useCallback(() => {
    if (!gridApiRef.current) return
    const { api } = gridApiRef.current
    // 保持修改后的状态退出编辑
    api.stopEditing(false)    
  }, [])

  // 点击取消按钮
  const handleCancelModify = useCallback(() => {
    if (!gridApiRef.current) return
    //如果为克隆状态 则调用刷新
    if (status === 'CLONE') {
      handleRefresh()
      return
    }
    if (status === 'INSERT') {
      handleRefresh()
    }
    const { api } = gridApiRef.current
    api.stopEditing(true)
    dispatchAction('reset')
  }, [dispatchAction])

  // 进入编辑状态时暂存编辑前的行数据, 用于 dirty check
  const preEditRowRef = useRef<any>(null)

  useEffect(() => {
    if (!selectedNodes.length) return
    dispatchAction('startDelete')
  }, [dispatchAction, selectedNodes])

  // 放大缩小页面
  const handleScaleView = (num: number) => {

    //设置单独结果集scale值 queryKey {}
    const resultTabs = Object.keys(resultTabMap).map(key => {
      if (key === tabResultKey) {
        return {
          key,
          info: {
            ...resultTabMap[key].info,
            scale: num
          }
        }
      }
      return resultTabMap[key]
    })

    dispatch(updateResultTabs(resultTabs))
  }
  // 选中行
  const handleSelectionChange = useCallback((event) => {
    const nodes = event.api.getSelectedNodes()
    setSelectedNodes(nodes)
  }, [])

  //结果集包含二进制 则不允许修改
  const isColumnContainBlobField = (curColumns: any) => {
    const binaryColumns =  curColumns.filter(({ cellEditorParams }: any) => {
      const {renderType} = cellEditorParams || {};
       return renderType === 'binary';
      
     });
    if (binaryColumns?.length) {
      return true
    }
    return false
  }


  // 进入编辑状态
  const handleStartRowEditing = (event: RowEditingStartedEvent) => {
    //包含二进制 增加提示
    const curColumns = event.api.getColumnDefs();
    if (curColumns && isColumnContainBlobField(curColumns)) {
      message.warning({top: 100,content: '结果集包含二进制不允许编辑'})
    }

    preEditRowRef.current = { ...event.data }
    //进入编辑状态时设置是否点击确认按钮为false
    dispatchAction('startUpdate')
  }

  const handleCloneRow = useCallback(async (params: ICellRendererParams) => {
    const { data } = params
    const formatData = Object.fromEntries(
      Object.entries(data).map(([k, v]) => [k, v === null ? '' : String(v)]),
    )
    if (!gridApiRef.current) return
    const { api } = gridApiRef.current
    dispatchAction('startClone')
    const rowCount = api.getInfiniteRowCount()
    if (typeof rowCount === 'undefined') return
    api.setRowCount(rowCount + 1)
    if (rowCount % BLOCK_SIZE === 0) return
    // 行数非整块时，不会触发 getRows，直接添加空行并进入编辑
    const refreshFirstCache = firstCacheCbRef.current
    const firstCache = firstCacheRef.current
    refreshFirstCache([formatData].concat(firstCache))
    api.startEditingCell({
      rowIndex: 0,
      colKey: columnDefs[1].field!,
    })
    // try {
    //   const modifyParams: ResultModify = {
    //     connectionId,
    //     dataSourceType,
    //     operatingObject,
    //     databaseName,
    //     statementObject,
    //     operatingObjectWithQuoteCharacter,
    //     statementObjectWithQuoteCharacter,
    //     columnInfos: ColumnInfosEdit,
    //     resultOperationDates: [{ resultNewData: Object.fromEntries(Object.entries(data).map((([k, v]) => ([k, String(v)])))) }],
    //     resultOperating: 'INSERT',
    //   }
    //   const statements = await modifyResult(modifyParams)
    //   const executeParams = {
    //     connectionId,
    //     dataSourceType,
    //     operatingObject,
    //     databaseName,
    //     statements: statements || [],
    //     tabKey: queryKey,
    //   }
    //   // 设置执行 pending
    //   dispatch(setTabExecutionStatus({ key: queryKey, pending: true }))
    //   const executeResults = await executeSqlStatement(executeParams)
    //   executeResults.forEach(({ executeError }) => {
    //     if (executeError) message.error(executeError.message)
    //   })
    // } catch {
    // } finally {
    //   dispatch(setTabExecutionStatus({ key: queryKey, pending: false }))
    //   dispatchAction('reset')
    // }
  }, [])

  // 退出编辑状态
  const handleStopRowEditing = useCallback(
    async ({ data, api, rowIndex }: RowEditingStoppedEvent) => {
      // const lastRow = lastRowRef.current
      let isCloneSuccess = false
      const prev = preEditRowRef.current || {}
      const next = data || {}
      const resultNewData = getAlteredRowData(prev, next)
      const isRowDirty = Object.keys(resultNewData).length
      const modifyParams: ResultModify = {
        connectionId,
        dataSourceType,
        operatingObject,
        databaseName,
        statementObject,
        operatingObjectWithQuoteCharacter,
        statementObjectWithQuoteCharacter,
        columnInfos: ColumnInfosEdit,
        resultOperationDates: [{ resultOldData: prev, resultNewData }],
        resultOperating: 'UPDATE',
      }
      if (status === 'INSERT') {
        // 当前操作状态是添加行
        // if (!isRowDirty) {
        //   api.purgeInfiniteCache()
        //   dispatchAction('reset')
        //   return
        // }
        // 修改请求参数
        modifyParams.resultOperating = 'INSERT'
        modifyParams.resultOperationDates = [{ resultNewData }]
      }

      if (status === 'CLONE') {
        modifyParams.resultOperating = 'INSERT'
        modifyParams.resultOperationDates = [{ resultNewData: next }]
      }

      // 如果是 'UPDATE' 状态, 但没有修改行, 直接退出编辑
      if (status === 'UPDATE' && !isRowDirty) {
        dispatchAction('reset')
        return
      }

      // 根据执行的操作生成语句, 执行语句并刷新
      setLoading(true)
      try {
        const statements = await modifyResult(modifyParams)
        const executeParams = {
          connectionId,
          dataSourceType,
          operatingObject,
          databaseName,
          statements: statements || [],
          tabKey: queryKey,
          autoCommit: txMode === 'auto',
          actionType: 'UPDATE' as const,
        }
        // 设置执行 pending
        dispatch(setTabExecutionStatusPercentage({key: queryKey, executePercentage: 0, executeStatusMessage: '正在执行...0%'}));
        dispatch(setTabExecutionStatus({ key: queryKey, pending: true }))
        dispatch(saveExecuteActiveTabParams(executeParams))
        const executeResults = await executeSqlStatement(executeParams)
        executeResults?.executionInfos?.forEach((item) => {
          const { response } = item
          if (response?.executeError) {
            message.error(response.executeError.message)
            //若执行出错并且为clone模式 保持当前编辑状态
            if (status === 'CLONE') {
              isCloneSuccess = true
              api.setFocusedCell(rowIndex, columnDefs[1].field!)
              api.startEditingCell({
                rowIndex: rowIndex,
                colKey: columnDefs[1].field!,
              })
              return
            }
          }
          handleExternalInfo({ type: 'LOG', msg: item.executeLogInfo.message })
        })
      } catch {
      } finally {
        dispatch(setTabExecutionStatusPercentage({key: queryKey, executePercentage: 100, executeStatusMessage: '已执行结束'}));
        dispatch(setTabExecutionStatus({ key: queryKey, pending: false }))
        //若当前为克隆模式 则失败后不执行刷新和结束编辑
        if (isCloneSuccess) {
          setLoading(false)
        } else {
          // 结果集修改之后的查询 actionType 为 'QUERY_AFTER_UPDATE'
          setActionType('QUERY_AFTER_UPDATE')
          api.purgeInfiniteCache()
          dispatchAction('reset')
        }
      }
    },
    [
      connectionId,
      dataSourceType,
      operatingObject,
      databaseName,
      statementObject,
      operatingObjectWithQuoteCharacter,
      statementObjectWithQuoteCharacter,
      ColumnInfosEdit,
      status,
      dispatchAction,
      queryKey,
      dispatch,
    ],
  )

  const updateFocusedCell = async (
    newData: PlainRowData,
    oldData: PlainRowData,
  ) => {
    const modifyParams: ResultModify = {
      connectionId,
      dataSourceType,
      operatingObject,
      databaseName,
      statementObject,
      columnInfos: ColumnInfosEdit,
      resultOperationDates: [
        { resultOldData: oldData, resultNewData: newData },
      ],
      resultOperating: 'UPDATE',
      operatingObjectWithQuoteCharacter,
      statementObjectWithQuoteCharacter,
    }

    // 根据执行的操作生成语句, 执行语句并刷新
    setLoading(true)
    try {
      const statements = await modifyResult(modifyParams)
      const executeParams = {
        connectionId,
        dataSourceType,
        operatingObject,
        databaseName,
        statements: statements || [],
        tabKey: queryKey,
        autoCommit: txMode === 'auto',
        actionType: 'UPDATE' as const,
      }
      // 设置执行 pending
      dispatch(setTabExecutionStatus({ key: queryKey, pending: true }))
      dispatch(saveExecuteActiveTabParams(executeParams))
      dispatch(setTabExecutionStatusPercentage({key: queryKey, executePercentage: 0, executeStatusMessage: '正在执行...0%'}));
      const executeResults = await executeSqlStatement(executeParams)
      const { executionInfos } = executeResults
      executionInfos.forEach((item) => {
        const { response, executeLogInfo } = item
        if (response?.executeError) message.error(response.executeError.message)
        handleExternalInfo({ type: 'LOG', msg: executeLogInfo?.message })
      })
    } catch {
    } finally {
      dispatch(setTabExecutionStatusPercentage({key: queryKey, executePercentage: 100, executeStatusMessage: '已执行结束'}));
      dispatch(setTabExecutionStatus({ key: queryKey, pending: false }))
      gridApiRef.current?.api.purgeInfiniteCache()
      setActionType('QUERY_AFTER_UPDATE')
    }
  }

  const downloadFocusedCell = async (
    newData: PlainRowData,
    oldData: PlainRowData,
  ) => {
    const modifyParams: ResultModify = {
      connectionId,
      dataSourceType,
      operatingObject,
      databaseName,
      statementObject,
      operatingObjectWithQuoteCharacter,
      statementObjectWithQuoteCharacter,
      columnInfos: ColumnInfosEdit,
      resultOperationDates: [
        { resultOldData: oldData, resultNewData: newData },
      ],
      resultOperating: 'RENDER',
    }
    const statements = await modifyResult(modifyParams)
    const executeParams = {
      connectionId,
      dataSourceType,
      operatingObject,
      databaseName,
      statements: statements || [],
      tabKey: queryKey,
    }
    return downloadGridCell(executeParams)
  }

  const fetchFocusedCell = async (
    newData: PlainRowData,
    oldData: PlainRowData,
  ) => {
    const modifyParams: ResultModify = {
      connectionId,
      dataSourceType,
      operatingObject,
      databaseName,
      statementObject,
      columnInfos: ColumnInfosEdit,
      operatingObjectWithQuoteCharacter,
      statementObjectWithQuoteCharacter,
      resultOperationDates: [
        { resultOldData: oldData, resultNewData: newData },
      ],
      resultOperating: 'RENDER',
    }
    const statements = await modifyResult(modifyParams)
    const executeParams = {
      connectionId,
      dataSourceType,
      operatingObject,
      databaseName,
      statements: statements || [],
      tabKey: queryKey,
    }
    return getCompleteCell(executeParams)
  }
  const EditingStatus: IGridStatus[] = ['INSERT', 'UPDATE']

  const isDataExportPermissionEnabled = Service.moduleService.isModuleExist(
    '/flow',
  )
    ? dataExport.status
    : false

  /* 打开申请流程权限表单 */
  const applyDataExportPermission = async () => {
    dispatch(
      openFlowForm({
        type: 'dataExport',
      }),
    )
  }

  // 结果集 allow copy
  const [copyableRef, allowCopy] = useCopyableChange()

  const onOpenExportModal = (key: string) => {
    const visibleMap = {
      ResultExport: () => setVisibleCommonExport(true),
      SelectedResultExport: () => setVisibleSelectedExport(true),
      ResultAllExport: () => setVisibleExportAll(true),
    }
    if (key in visibleMap) {
      visibleMap[key as keyof typeof visibleMap]()
    }
  }

  const { fetchTaskResult } = useHandleExternalInfo(getTaskResult, (data: any) => {
    renderNotification(data)
  })

  const handleExportAllResult = useCallback(
    (data: any) => {
      const params = {
        connectionId: result.connectionId,
        connectionType: result.dataSourceType,
        databaseName: result.databaseName,
        operatingObject: result.operatingObject,
        statement: result.statement,
        containTempTable: result.containTempTable,
        tabKey: result.tabKey,
      }
      return makeResultAllExport(Object.assign({}, params, data)).then((data) => {
        fetchTaskResult(data)
      })
    },
    [result, fetchTaskResult],
  )

  const [focusedRowData, setFocusedRowData] =
    useState<Record<string, unknown>>()
  const [focusedColumn, setFocusedColumn] = useState<Column | null>(null)

  // rowdata 拿到的信息不全，只有每个字段的value值
  const [focusedRowIndex, setFocusedRowIndex] = useState<number | null>(null)

  const handleViewCell = useCallback(() => {
    setVisibleCellViewer(true)
  }, [])

  const handleViewRow = useCallback(() => {
    setVisibleRowViewer(true)
  }, [])

  const stringifyCellData = useCallback((cellData: unknown) => {
    if (typeof cellData === 'string') {
      return cellData
    }
    return JSON.stringify(cellData)
  }, [])

  const handleCopyCell = useCallback(
    (params: ICellRendererParams) => {
      const cellData = stringifyCellData(params.value)
      copy(cellData)
    },
    [stringifyCellData],
  )

  const handleCopyRow = useCallback((context: ICellRendererParams) => {
    const selectedRows = context.api.getSelectedRows();
    const columnDefs = context.columnApi.getAllDisplayedColumns();

    const rowData = selectedRows.map(row => {
      // 通过columnDefs获取表格标题按标题顺序复制内容就能保证顺序一致
      // @ts-ignore
      return columnDefs.map(column => row[column.colDef.field]).join('\t');
    });

    const clipboardData = rowData.join('\n');
    copy(clipboardData);
  }, [])
   
  const canCopy = permissionResult?.resultSetCopy && allowCopy;

  const agContext = useMemo<IGridContext>(
    () => ({
      status,
      disableContextMenu: paneType === 'tSql',
      copyable: canCopy,
      allowClone: !(connectionType === 'DB2' || connectionType === 'StarRocks' || connectionType === 'Inceptor') && editable,
      handleCopyCell,
      handleCopyRow,
      handleViewCell,
      handleViewRow,
      handleCloneRow,
    }),
    [
      allowCopy,
      connectionType,
      handleCopyCell,
      handleCopyRow,
      handleViewCell,
      handleViewRow,
      handleCloneRow,
      paneType,
      status,
      permissionResult?.resultSetCopy
    ],
  )

  const executePayload = useMemo<QueryBase>(
    () => ({
      connectionId,
      dataSourceType,
      operatingObject,
      databaseName,
      statements: [statement] || [],
      tabKey: queryKey,
    }),
    [connectionId, dataSourceType, databaseName, operatingObject, queryKey, statement]
  )

  const handleToolbarView = useCallback(() => {
    if (!focusedColumn) return
    const colId = focusedColumn.getColId()
    if (colId === indexColId) {
      // 是序号列，展示行数据
      setVisibleRowViewer(true)
      return
    }
    // 展示单元格数据
    setVisibleCellViewer(true)
  }, [focusedColumn])

  useEffect(() => {
    if (copySetting) return
    if (visibleRowViewer || visibleCellViewer) {
      document.oncopy = (e) => {
        e.returnValue = false
      }
      document.oncontextmenu = (e) => {
        e.returnValue = false
      }
    } else {
      document.oncopy = (e) => {
        e.returnValue = true
      }
      document.oncontextmenu = (e) => {
        e.returnValue = true
      }
    }
  }, [visibleRowViewer, visibleCellViewer, copySetting])

  return (
    <div className={styles.resultGrid}>
      <div className={styles.resultContent} ref={copyableRef}>
        <Spin spinning={loading} wrapperClassName={styles.gridSpinContainer}>
          {
            !columnInfos || columnInfos.length === 0
              ?
              <div className={styles.resultGrid__filter}>
                <Iconfont type='icon-yizhongzhi' style={{ marginRight: '4px' }} /> 
                结果为空，存在被过滤字段：{filterNames?.join(',')}
              </div>
              :
              <>
                <ResultToolbar
                  enabledActions={enabledActionsMap[status].concat(
                    !EditingStatus.includes(status) && focusedRowData ? 'view' : [],
                  )}
                  paneType={paneType}
                  onRefresh={handleRefresh}
                  onAddRow={handleAddRow}
                  onDeleteRow={() => {
                    Modal.confirm({
                      title: `确定删除选中行数据？`,
                      onOk: () => handleDeleteRow(),
                      centered: true,
                      okButtonProps: { danger: true },
                    })
                  }}
                  onConfirmModify={handleConfirmModify}
                  onScalePage={handleScaleView}
                  onCancelModify={handleCancelModify}
                  onViewCell={handleToolbarView}
                  readOnly={!editable}
                  refreshable={refreshable}
                  isDataExportPermissionEnabled={isDataExportPermissionEnabled}
                  applyDataExportPermission={applyDataExportPermission}
                  onOpenExportModal={onOpenExportModal}
                  showExported={dataExport.showExported}
                  connectionType={connectionType}
                  executePayload={executePayload}
                  scale={scale === undefined ? 100 : scale}
                  type={type}
                  permissionResult={permissionResult}
                  dataExport={dataExport}
                  filterNames={filterNames}
                  txMode={txMode}
                  onOpenFieldsModal={() => { setVisibleDesensitizedFields(true) }}
                  isDesensitized={isDesensitized}
                />
                <div
                  id={`${tabResultKey}gridWrapper`}
                  className={classNames(
                    styles.gridWrapper,
                    theme === 'dark' ? 'ag-theme-balham-dark' : 'ag-theme-balham',
                    !canCopy && styles.unCopyable,
                  )}
                >
                  <AgGridReact
                    {...GridConfigBase}
                    {...infiniteModeOptions}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    datasource={datasource}
                    onGridReady={handleGridReady}
                    onSelectionChanged={handleSelectionChange}
                    onCellFocused={(event) => {
                      if (event.rowIndex != null) {
                        setFocusedRowIndex(event.rowIndex)
                        const rowNode = event.api.getDisplayedRowAtIndex(
                          event.rowIndex,
                        )

                        setFocusedRowData(rowNode?.data)
                        setFocusedColumn(event.column)
                      }
                    }}
                    onRowEditingStarted={handleStartRowEditing}
                    onRowEditingStopped={handleStopRowEditing}
                    context={agContext}
                    suppressDragLeaveHidesColumns={true}
                    key={JSON.stringify(detailedResultData)}
                  />
                </div>
              </>
          }
        </Spin>
      </div>
      <CellViewerModal
        gridApi={gridApiRef.current?.api || null}
        rowIndex={focusedRowIndex}
        rowData={focusedRowData}
        resultData={detailedResultData}
        editable={editable}
        allowResultCopy={permissionResult?.resultSetCopy}
        // todo: resultData 应当更新为 insert/update 后的结果集
        visible={visibleCellViewer}
        allowBinaryCellDownload={permissionResult?.resultSetBinaryFileDownload }
        setVisible={setVisibleCellViewer}
        updateFocusedCell={updateFocusedCell}
        downloadFocusedCell={downloadFocusedCell}
        fetchFocusedCell={fetchFocusedCell}
        type={type!}
        isExplain={isExplain}
      />
      {visibleRowViewer && (
        <RowViewerModal
          gridApi={gridApiRef.current?.api || null}
          rowData={focusedRowData}
          resultData={detailedResultData}
          editable={editable}
          // todo: resultData 应当更新为 insert/update 后的结果集
          visible={visibleRowViewer}
          setVisible={setVisibleRowViewer}
          updateFocusedCell={updateFocusedCell}
          downloadFocusedCell={downloadFocusedCell}
          fetchFocusedCell={fetchFocusedCell}
        />
      )}

      <AddSelectedResultExportModal
        visible={visibleSelectedExport}
        setVisible={setVisibleSelectedExport}
        gridApi={gridApiRef.current?.api || null}
        permissionResult={permissionResult}
      />
      <AddResultExportModal
        result={result}
        // getTotal={getTotal}
        visible={visibleCommonExport}
        setVisible={setVisibleCommonExport}
        permissionResult={permissionResult}
      />
      <ResultAllExport
        result={result}
        visible={visibleExportAll}
        setVisible={setVisibleExportAll}
        hanldeExportAll={handleExportAllResult}
        permissionResult={permissionResult}
      />
      <DesensitizedFieldsModal
        result={result}
        visible={visibleDesensitizedFields}
        setVisible={setVisibleDesensitizedFields}
        doubleCheckType={doubleCheckType}
        filterNames={filterNames}
      />
      {/* <CellEditorModal selectedCell={selectedCell} /> */}
    </div>
  )
}
