/**
 * 上传文件夹弹框
 */
import React, { useState } from "react"
import { Button, Modal, message } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { CustomDragableUploadList } from 'src/components'
import { downloadTemplateFile } from 'src/api'
import styles from './index.module.scss'
interface IProps {
  visible: boolean
  onClose: () => void
  [p: string]: any
}


const UploadFolderModal = ({
  visible,
  uploadSize,
  onClose,
  callback
}: IProps) => {
  const [uploadValue, setUploadValue] = useState<any[]>()
  const [folderName, setFolderName] = useState<string>('')
  const [isLimit, setIsLimit] = useState<boolean>(true)

  const handleOk = () => {
    if(!isLimit){
      setUploadValue([])
      return message.error(`文件大小超过${uploadSize}M限制`)
    }
    callback && callback(uploadValue, folderName)
    onClose()
  }

  const handleDownLoadTemplate = () => {
    downloadTemplateFile().then().catch((err: any)=>{
      console.error('下载模板文件失败', err)
    })
  }

  const callBackIsLimitSize = (flag: boolean) => {
    setIsLimit(flag)
  }

  return (
    <Modal
      width={600}
      title="上传文件夹"
      visible={visible}
      maskClosable={false}
      onOk={handleOk}
      onCancel={onClose}
      className={styles.uploadFolderModal}
    >
      <div className={styles.title}>模板文件夹下载</div>
      <div className={styles.desc}>
        模板文件中包含执行顺序文件模板和sql文件示例，您可下载后参考
        <Button 
          className="ml16" 
          style={{borderRadius: 280}}
          onClick={handleDownLoadTemplate}
        >
          <span className="options" ><DownloadOutlined className="mr4" />模板下载</span>
        </Button>
      </div>
      <div className={styles.title}>上传文件夹</div>
      <div className={styles.desc}>
        <p>若您已知执行逻辑，可直接选择文件进行上传</p>
        <CustomDragableUploadList 
          multiple={false}
          accept=".zip"
          uploadSize={uploadSize}
          showUploadList={false}
          buttonProps={{
            type: 'primary',
            style: {marginLeft: '16px', borderRadius: 280},
            disabled: uploadValue && uploadValue?.length > 0,
            buttonText: '选择zip压缩包'
          }}
          value={uploadValue}
          onChange={(value: any) => {
            setUploadValue(value)
          }}
          callBackIsLimitSize={callBackIsLimitSize}
        />
        <CustomDragableUploadList 
          multiple={false}
          directory={true}
          uploadSize={uploadSize}
          showUploadList={false}
          buttonProps={{
            type: 'primary',
            style: {marginLeft: '16px', borderRadius: 280},
            disabled: uploadValue && uploadValue?.length > 0,
            buttonText: '选择文件夹'
          }}
          value={uploadValue}
          onChange={(value: any, _: any, folderName: string) => {
            setUploadValue(value)
            setFolderName(folderName)
          }}
          callBackIsLimitSize={callBackIsLimitSize}
        />
      </div>
    </Modal>
  )
}
export default UploadFolderModal