import React from 'react';
import { Form, InputNumber, Modal, Select, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { highRiskLevelMenu, levelMenu } from 'src/constants/authManagement';
import styles from '../index.module.scss'

interface IProps {
  visible: boolean
  onCancel: () => void
  onSubmit: (a: any) => void
}

export const BatchEditModal = (props: IProps) => {
  const { visible, onCancel, onSubmit } = props;

  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleSubmit = (values: any) => {
    form.resetFields();
    onSubmit(values);
  };

  return (
    <>
      <Modal
        title="批量编辑"
        visible={visible}
        onCancel={handleCancel}
        onOk={() => {
          form.submit();
        }}
      >
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          onFinish={handleSubmit}
        >
          <Form.Item
            name={"value"}
            label={
              <>
                <span>行数限制</span>&nbsp;
                <Tooltip title="行数限制仅对UID(update、insert、delete)生效">
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            }
          >
            <InputNumber
              formatter={value => `${Number(value)}`}
              min={0}
              size="middle"
              className={styles.ml3}
              style={{ width: 197 }}
            />
          </Form.Item>
          <Form.Item
            name={"level"}
            label={"高危提示"}
          >
            <Select
              placeholder="请选择高危提示"
              style={{ width: 200 }}
              allowClear
              options={levelMenu}
            />
          </Form.Item>
          <Form.Item
            name={"highRiskLevel"}
            label={"高危等级"}
          >
            <Select
              placeholder="请选择高危等级"
              style={{ width: 200 }}
              allowClear
              options={highRiskLevelMenu}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}