//不好 每次都需要遍历 所有树结构
//创建结果集时将修改过的资源 留痕 生成只包含修改的节点信息
export const generateTree = (data: any[], searchValue?: string) => {
  const allId = data
    ?.map((i) => i.id)
    ?.filter((i, index, arr) => arr.indexOf(i) === index)
  const allParentId = data
    ?.map((i) => i.parentId)
    ?.filter((i, index, arr) => arr.indexOf(i) === index)

  const validateParentId = (item: any) => {
    return !!data?.filter((i: any) => {
      return i?.parentId === item?.id && item?.nodeType !== i?.nodeType
    })?.length
  }

  const filterData = data.filter((item, _, arr) => {
    // 自身是父级（且id和parentId不能重复）
    if (allParentId.includes(item.id) && validateParentId(item)) {
      item.children = arr.filter((i) => i.parentId === item.id)
    }
    // 没有父级
    if (!allId.includes(item.parentId) || !item.parentId) {
      return true
    }
    return false
  })

  const formatTree = (data: any[]): any[] =>
    data.map((item: any) => {
      item.key = item?.nodePath  // 唯一key
      item.title = item.nodeName
      item.dataSourceType = item?.connection?.connectionType;
      if (item.children) {
        item.children = formatTree(item.children);
      }
      return { ...item }
    })
  return formatTree(filterData)
}

export const filterClickedNodes: any = (tree: any[], clickedNodes: string[]) => {

  //如果是同种类型 node key包含
  return tree.map((node: any) => {
    if (clickedNodes.includes(node.key)) {
      // 如果当前节点的 key 在点击过的节点列表中，则保留该节点，并递归处理子节点
      if (node.children) {
        return { ...node, children: filterClickedNodes(node.children, clickedNodes) };
      } else {
        return node;
      }
    } else if (node.children) {
      // 如果当前节点的 key 不在点击过的节点列表中，但有子节点，则递归处理子节点
      const filteredChildren: any[] = filterClickedNodes(node.children, clickedNodes);
      if (filteredChildren.length > 0) {
        return { ...node, children: filteredChildren };
      } else {
        return null; // 如果子节点全部被删除，则当前节点也删除
      }
    } else {
      return null; // 如果当前节点没有子节点且不在点击过的节点列表中，则删除
    }
  }).filter(Boolean); // 移除空节点
}

export const getTargetNode = (nodePath: string, treeData: any[]) => {
  const queue = [...treeData]
  while (queue.length) {
    const currentNode = queue.shift()
    if (!currentNode) return
    if (currentNode.key === nodePath) {
      return currentNode
    }
    if (currentNode.children) {
      queue.push(...currentNode.children)
    }
  }
}

export const specialFormatTree = (data: any[], isFolder?: boolean, roleId?: number): any[] =>
  data?.map((item: any) => {
    const { connection = {} } = item
    item.title = item.nodeName;
    item.isFolder = isFolder || false;
    item.dataSourceType = connection?.connectionType;
    item.roleId = item?.roleId || roleId;
    item.isLeaf = item?.nodeType?.toUpperCase().endsWith("GROUP");
    item.key = item?.nodePath
    item.connectionId = item?.sdt?.connectionId
    item.checkable = item?.nodeType?.toUpperCase()?.endsWith("GROUP") ? false: true;
    return item

  });


