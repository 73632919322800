import React, { useState, useEffect } from "react";
import * as _ from "lodash";
import classnames from 'classnames'
import { DeleteOutlined, ExclamationCircleOutlined, ShoppingCartOutlined, RollbackOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'src/hook';
import { Card, Row, Col, message, Checkbox, Radio, Spin, Tooltip, Button, Badge } from "antd";
import {
  getPermissionsPanelUpObject,
  getPermissionList,
  getPermissionTemplate,
  IObjectPermissionRes,
  getCartPermissionsPanelUpObject
} from 'src/api';
import { updateConnectionObjectPermissions } from '../visitRequestOperateSlice'
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { useRequest, } from "src/hook";
import { useHistory } from 'react-router-dom'
import { Iconfont } from "src/components";
import { resetPermissionModal } from 'src/pageTabs/authorizationManagePage/commonMethods'
import styles from "./index.module.scss";
import { setStr } from "src/util/connectionManage";

const ConnectionRightMenuSetting = ({
  selectTreeItem,
  roleId,
  isEdit = false,
  needRefresh,
  cartNumber,
  viewAppliedDetail = false, //仅查看
  permissionCollectionVOS,
  updatePermissionCollectionVOS,
}: {
  isEdit?: boolean;
  selectTreeItem?: any;
  needRefresh?: boolean;
  cartNumber?: boolean;
  roleId?: string | number;
  viewAppliedDetail?: boolean;
  permissionCollectionVOS?: any;
  updatePermissionCollectionVOS?: (params: any) => void
}) => {

  const { connection, dataSourceType } = selectTreeItem || {};
  const { connectionType } = connection || {};
  const dispatch = useDispatch();
  const history = useHistory();
  const { connectionObjectPermissions } = useSelector(state => state.visitRequestOperate)

  const radioTypes = ["permissionTemplate", "roleConnection"];
  //页面复选框存储内容{key：[]}
  const [changedObjectTypes, setChangedObjectTypes] = useState<any>({});
  const [permissionsOptsSet, setPermissionsOptsSet] = useState<any>();

  const { data: list, loading, run: runGetAutomaticObjectPermission } = useRequest<IObjectPermissionRes>(
    getPermissionsPanelUpObject, {
    manual: true,
    onSuccess(data, params) {
      const initCheckedValues = getInitCheckedValues(data?.operationsVoList)

      const cloneAllChangedObjectSetting = _.cloneDeep(connectionObjectPermissions)
      const curItem = cloneAllChangedObjectSetting?.[selectTreeItem?.key]

      if (!_.isEmpty(curItem)) {
        dispatch(updateConnectionObjectPermissions({
          ...cloneAllChangedObjectSetting,
          [selectTreeItem?.key]: curItem
        }))
        setChangedObjectTypes(curItem);
      } else {
        setChangedObjectTypes(initCheckedValues);
      }
    },
  })

  // 查询不同数据源下对应的所有权限信息
  const { run: getPermissionTemplateEnum } = useRequest(
    getPermissionTemplate,
    { manual: true },
  )

  // 获取权限等级列表
  const { run: getPermissionListEnum } = useRequest(
    getPermissionList,
    {
      manual: true,
      onSuccess: (res: any) => {
        getPermissionTemplateEnum(dataSourceType).then((templateRes: any) => {
          let options: { [key: string]: any[] } = {};
          res?.forEach((item: any) => {
            if (item?.id) {
              let templateOperationsSetTmp = item?.templateOperationsSet;
              options[item?.id] = templateOperationsSetTmp?.flatMap((optionsItem: any) => {
                const matchedTemplate = templateRes?.find((templateItem: any) => templateItem?.objectType === optionsItem?.objectType);
                if (matchedTemplate) {
                  return optionsItem?.operations?.map((operation: any) => {
                    const matchedOperation = matchedTemplate?.operations?.find((templateOperation: any) => templateOperation?.operation === operation);
                    if (matchedOperation) {
                      return `${operation}(${matchedOperation?.operationName})`;
                    }
                    return operation;
                  });
                } else {
                  return [];
                }
              });
            }
          });
          setPermissionsOptsSet(options)
        })
      }
    },
  )

  const getInitCheckedValues = (list: any[]) => {
    let obj: any = {};
    list?.map((item: any) => {
      if (!radioTypes.includes(item.objectType)) {
        let checkedTypes: string[] = [];
        item?.operations?.map((o: any) => {
          if (o?.hasSelected === true) {
            checkedTypes.push(o.operation)
          }
        })
        if (!_.isEmpty(checkedTypes)) {
          obj[item.objectType] = checkedTypes;
        }

      } else {
        const radioCheckedItem = item?.operations?.find((o: any) => o?.hasSelected === true) || {};

        if (!_.isEmpty(radioCheckedItem)) {
          obj[item.objectType] = radioCheckedItem.operation;
        }

      }

    })
    return obj
  }

  useEffect(() => {

    const { connection, dataSourceType, nodePathWithType } = selectTreeItem || {};
    const { connectionType } = connection || {};

    if (selectTreeItem?.newNodeType === "datasource") {
      runGetAutomaticObjectPermission({
        roleId,
        dataSourceType: selectTreeItem.id
      });
    } else if (nodePathWithType && (connectionType || dataSourceType)) {
      runGetAutomaticObjectPermission({
        roleId,
        nodePath: nodePathWithType,
        dataSourceType: dataSourceType || connectionType
      })
    }


  }, [selectTreeItem?.key, needRefresh])

  useEffect(() => {
    if (dataSourceType) {
      getPermissionListEnum(dataSourceType)
    }
  }, [dataSourceType, getPermissionListEnum])

  const onChangeCheckbox = (
    checkedValues: CheckboxValueType[],
    type: string
  ) => {

    let cloneChangeObjectTypes = _.cloneDeep(changedObjectTypes);
    if (checkedValues?.length) {
      cloneChangeObjectTypes = {
        ...cloneChangeObjectTypes,
        [type]: checkedValues,
      }
    } else {
      //判空处理 过滤该条数据
      cloneChangeObjectTypes = Object.fromEntries(
        Object.entries(cloneChangeObjectTypes).filter(([itemType]) => itemType !== type)
      );
    }

    setChangedObjectTypes(cloneChangeObjectTypes);
  };

  const onCheckAllChange = (checked: boolean, type: string) => {

    const changedItem = list?.operationsVoList?.find((i: any) => i.objectType === type);
    const curAllTypes = changedItem?.operations?.map((o: any) => o.operation);
    let cloneChangeObjectTypes = _.cloneDeep(changedObjectTypes);

    //过滤为空数据
    if (checked) {
      cloneChangeObjectTypes = {
        ...cloneChangeObjectTypes,
        [type]: curAllTypes
      }
    } else {

      const filteredChangeObjectTypes = Object.fromEntries(
        Object.entries(cloneChangeObjectTypes).filter(([itemType]) => itemType !== type)
      );
      cloneChangeObjectTypes = {
        ...filteredChangeObjectTypes,
      }
    }

    setChangedObjectTypes(cloneChangeObjectTypes);

  };

  const onChangeRadioStatus = async (value: string | null, type: string, valid?: boolean) => {
    //上下级 权限是否冲突
    //判断是否重置
    if (!valid) {
      const resetOrNot = await resetPermissionModal({
        title: '是否更改权限等级',
        content: '此schema下数据库对象存在其他权限设置,更改权限等级相当于重置此schema下的其他权限设置'
      })
      if (!resetOrNot) {
        return
      }
    }

    const cloneChangeObjectTypes = _.cloneDeep(changedObjectTypes);
    let params: any;
    //过滤为空数据
    if (value) {
      params = {
        ...cloneChangeObjectTypes,
        [type]: value
      }
    } else {

      const filteredChangeObjectTypes = Object.fromEntries(
        Object.entries(cloneChangeObjectTypes).filter(([itemType]) => itemType !== type)
      );
      params = {
        ...filteredChangeObjectTypes,
      }
    }

    setChangedObjectTypes(params);

  }


  const getUserToolVoValue = (nodeType: string, dataSourceType: string, connectionType: string, templateOperationsSet: any,) => {
    let res = {};
    if (nodeType === "datasource") {
      res = {
        dataSourceType: selectTreeItem?.nodeName,
        nodeName: selectTreeItem?.nodeName,
        nodePath: selectTreeItem?.nodePath,
        templateOperationsSet
      };
    } else {
      res = {
        dataSourceType: dataSourceType || connectionType,
        nodePathWithType: selectTreeItem?.nodePathWithType,
        nodeName: selectTreeItem?.nodeName,
        nodePath: selectTreeItem?.nodePath,
        templateOperationsSet
      };
    }
    return res;
  }

  const translateSubmitParams = (changedObjectTypes: any) => {

    let permissionAndRoleParams: any = {};
    let templateOperationsSet = [];
    if (!_.isEmpty(changedObjectTypes) && _.isEmpty(changedObjectTypes?.['permissionTemplate'])) {
      return message.warning('请选择权限等级')
    }
    for (const i in changedObjectTypes) {
      const checkedValue = changedObjectTypes[i];

      switch (i) {
        case 'permissionTemplate':
          if (selectTreeItem?.nodeType === "datasource") {
            permissionAndRoleParams.permissionSdtUserAddVo = {
              templateId: checkedValue ? Number(checkedValue) : checkedValue,
              dataSourceType: selectTreeItem?.nodeName,
              nodeName: selectTreeItem?.nodeName,
              nodePath: selectTreeItem?.nodePath
            }
          } else {
            permissionAndRoleParams.permissionSdtUserAddVo = {
              nodePathWithType: selectTreeItem?.nodePathWithType,
              templateId: checkedValue ? Number(checkedValue) : checkedValue,
              dataSourceType: selectTreeItem?.dataSourceType || connectionType,
              nodeName: selectTreeItem?.nodeName,
              nodePath: selectTreeItem?.nodePath
            }
          }
          break;
        case 'roleConnection':
          if (selectTreeItem?.nodeType === "datasource") {
            permissionAndRoleParams.roleUpdateVo = {
              roleType: checkedValue,
              dataSourceType: selectTreeItem?.nodeName,
              nodeName: selectTreeItem?.nodeName,
              nodePath: selectTreeItem?.nodePath
            }
          } else {
            permissionAndRoleParams.roleUpdateVo = {
              nodePathWithType: selectTreeItem?.nodePathWithType,
              roleType: checkedValue,
              dataSourceType: selectTreeItem?.dataSourceType || connectionType,
              nodeName: selectTreeItem?.nodeName,
              nodePath: selectTreeItem?.nodePath
            }
          }
          break;
        case 'sdtMenu':
        case 'resultSetOperation':
        case 'exportTool':
          templateOperationsSet.push({
            objectType: i, operations: checkedValue,
          })
          break
      }
    }
    //  更新
    const a = {
      ...permissionAndRoleParams,
      ...(!_.isEmpty(templateOperationsSet) ? {
        userToolVo:
          getUserToolVoValue(selectTreeItem?.nodeType, dataSourceType, connectionType, templateOperationsSet)
      } : [])
    }

    //存粗多个连接选中信息
    // const cloneAllChangedObjectSetting = _.cloneDeep(connectionObjectPermissions)
    // if (!_.isEmpty(changedObjectTypes)) {
    //   dispatch(updateConnectionObjectPermissions({
    //     ...cloneAllChangedObjectSetting,
    //     [selectTreeItem?.key]: changedObjectTypes
    //   }))
    // }
    //过滤为空数据
    if (_.isEmpty(changedObjectTypes)) {
      updatePermissionCollectionVOS?.([])
      return
    } else {
      updatePermissionCollectionVOS?.([a])
    }

  }

  useEffect(
    () => {
      isEdit && translateSubmitParams(changedObjectTypes)

    },
    [changedObjectTypes],
  );

  return (
    <Spin spinning={loading} style={{ padding: "10px 10px" }}>
      <Card
        title={<div className={styles.connectionTitle}>
          <div>{(viewAppliedDetail) ? '已选择的权限' : '选择权限'} </div>
          {
            !viewAppliedDetail &&
            <Tooltip title='资源数量以schema数量为准'>
              <Badge count={cartNumber} showZero size="small" className="mr20">
                <Button type="primary" icon={<ShoppingCartOutlined />}
                  onClick={() => history.push('/mine_apply/application')}>申请清单
                </Button>
              </Badge>
            </Tooltip>
          }
        </div>}
        bordered={false}
        headStyle={{ borderBottom: "1px solid #EBEEF6" }}
        bodyStyle={{ padding: "16px 28px", height: 'calc(100% - 80px)', overflowY: 'scroll' }}
        className={styles.connectionSettingCard}
      >
        <div className={styles.settingContent}>
          {list?.operationsVoList?.map((item: any) => (
            <Row
              key={item?.objectType}
              className={styles.settingItem}
              style={{ width: `calc(100% / ${list?.operationsVoList?.length || 0})` }}
            >
              <Col span={24} className={styles.itemHeader}>
                <Row align="top" justify="start">
                  <Col span={24}>
                    {radioTypes.includes(item.objectType) ? (
                      <>{item.objectTypeName}</>
                    ) : (
                      <Checkbox
                        disabled={isEdit ? list?.canOperation === false ? true : false : true}
                        checked={
                          changedObjectTypes?.[item.objectType]?.length ===
                            item?.operations?.length
                            ? true
                            : false
                        }
                        onChange={(e: any) =>
                          onCheckAllChange(e.target.checked, item?.objectType)
                        }
                      >
                        {item?.objectTypeName}
                      </Checkbox>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={24} className={styles.itemBody}>
                {radioTypes.includes(item.objectType) ? (
                  <Radio.Group
                    disabled={isEdit ? list?.canOperation === false ? true : false : true}
                    value={changedObjectTypes?.[item.objectType] || []}
                    onChange={(e: any) => {

                      const itemInfo = item?.operations?.find((operation: any) => operation?.childObjects?.length > 0);

                      onChangeRadioStatus(e.target.value, item?.objectType, _.isEmpty(itemInfo))
                    }}
                  >
                    <Row >
                      {item?.operations?.map((operation: any) => (
                        <Col span={24}
                          className={styles.bodyCol}
                          key={operation?.operation}>
                          <Radio value={operation?.operation} className={classnames(styles.fontColor, {
                            [styles.deleteLine]: operation?.childObjects?.length > 0
                          })}>
                            <Tooltip
                              title={
                                permissionsOptsSet &&
                                Object.keys(permissionsOptsSet).includes(operation?.operation) &&
                                (setStr(permissionsOptsSet[operation?.operation?.toString()]) || '-')
                              }
                              overlayClassName={
                                permissionsOptsSet &&
                                  Object.keys(permissionsOptsSet).includes(operation?.operation) &&
                                  !!permissionsOptsSet[operation?.operation?.toString()].join(",\n")
                                  ? styles.permissionTooltip : ''
                              }
                              overlayStyle={{ whiteSpace: 'pre-line' }}
                            >
                              {operation?.childObjects?.length > 0 ?
                                <>
                                  <Tooltip title={'此用户在此层级下存在其他权限设置'} >
                                    <ExclamationCircleOutlined />
                                  </Tooltip>
                                  {operation?.operationName}
                                </>
                                : operation?.operationName}
                            </Tooltip>
                          </Radio>
                        </Col>
                      ))}
                    </Row>
                  </Radio.Group>
                ) : (
                  <Checkbox.Group
                    disabled={isEdit ? list?.canOperation === false ? true : false : true}
                    value={changedObjectTypes?.[item.objectType] || [] || []}
                    onChange={(values: CheckboxValueType[]) =>
                      onChangeCheckbox(values, item?.objectType)
                    }
                  >
                    <Row>
                      {item?.operations?.map((operation: any) => (
                        <Col span={24} key={operation?.operation} className={styles.bodyCol}>
                          <Checkbox value={operation?.operation} className={styles.fontColor}>
                            {operation?.operationName}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                )}
              </Col>
            </Row>
          ))}
        </div>
      </Card>
    </Spin>
  );
};

export default ConnectionRightMenuSetting;