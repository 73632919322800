import { DataSourceType } from 'src/api';
import type { SdtNodeType } from 'src/types';

//设计表结构
const NORMAL_TABLE_STRUCTURE_TABS = [
  'columns',
  'indexes',
  'constraints',
  'fkConstraints',
  'triggers',
  'partition',
  'option'
];

const STARROCKS_TABS = ['columns', 'indexes', 'partition', 'temporaryPartition'];
const Vertica_TABS = ['columns','constraints','fkConstraints','option'];
const WITHOUT_PART_OPTION_TABS = ['columns','indexes','constraints','fkConstraints','triggers'];
const WITHOUT_OPTION_TABS = ['columns','indexes','constraints','fkConstraints','triggers','partition'];
const ONLY_COLUMN_TABS = ['columns'];
const PHOENIX_TABS = ['columns','index'];
const GBASE8A_TABS = ['columns','indexes','constraints','triggers','partition','option'];

//一些数据源特殊处理
export const getPointDataSourceTabs = (
  connectionType: DataSourceType,
  nodeType: SdtNodeType | undefined
) => {
  if (!connectionType) return [];
  switch (connectionType) {
    case 'StarRocks':
      if (nodeType === 'foreignTable') {
        return ['columns'];
      }
      return STARROCKS_TABS;
    case 'Vertica':
      return Vertica_TABS;
    case "DB2":
    case "Greenplum":
      return WITHOUT_PART_OPTION_TABS;
    case "HANA":
      return WITHOUT_OPTION_TABS;
    case "Hive":
    case "Impala":
    case "Inceptor":
    case "Trino":
      return ONLY_COLUMN_TABS;
    case "Phoenix":
      return PHOENIX_TABS;
    case 'GBase':
      return NORMAL_TABLE_STRUCTURE_TABS.slice(0,NORMAL_TABLE_STRUCTURE_TABS.length - 2)
    case 'GBase8a':
      return GBASE8A_TABS;
    default: 
      return NORMAL_TABLE_STRUCTURE_TABS
  }
};
