export const breadcrumbsData = [
  { title: "数据操作", href: '/system_data_operate' },
  {
    separator: '/',
    title: '批量执行',
  },
];

// 执行方式Map
export const ExecuteWayMap: { [key: string]: string } = {
  "AUTO":"自动",
  "MANUAL":"手动",
}

// 错误处理方式Map
export const ErrorHandleWayMap: { [key: string]: string } = {
  "TERMINAL":"终止",
  "IGNORE":"忽略",
}

// 执行状态Map
export const ExecuteStatusMap: { [key: string]: string } = {
  "TOBE_EXECUTE": '待执行',
  "EXECUTING": '执行中',
  "EXECUTE_FAILURE": '执行失败',
  "EXECUTE_SUCCESS": '执行成功',
  "TERMINATE": '终止',
}

// 所有执行状态Map
export const AllExecuteStatusMap: { [key: string]: string } = {
  "TOBE_EXECUTE": '待执行',
  "EXECUTING": '执行中',
  "EXECUTE_FAILURE": '执行失败',
  "EXECUTE_SUCCESS": '执行成功',
  "TERMINATE": '终止',
  "SPLIT_PARSE_AUTH_SUCCESS": '解析/鉴权成功',
  "SPLIT_PARSE_AUTH_FAILURE": '解析/鉴权失败',
}

// 所有执行状态颜色Map
export const AllExecuteStatusColorMap: { [key: string]: string } = {
  "TOBE_EXECUTE": '#0f244c',
  "EXECUTING": '#0f244c',
  "EXECUTE_FAILURE": '#f00',
  "EXECUTE_SUCCESS": '#0f244c',
  "TERMINATE": '#0f244c',
  "SPLIT_PARSE_AUTH_SUCCESS": '#0f244c',
  "SPLIT_PARSE_AUTH_FAILURE": '#f00',
}

// 执行状态icon
export const ExecuteStatusIconMap: { [key: string]: string } = {
  "TOBE_EXECUTE": 'icon-daizhixing',
  "EXECUTING": 'icon-zhihangzhong',
  "EXECUTE_FAILURE": 'icon-shibai',
  "EXECUTE_SUCCESS": 'icon-chenggong',
  "TERMINATE": 'icon-yizhongzhi',
}

// 执行状态枚举
export const ExecuteStatusEnums = [
  {
    label: '待执行',
    value: 'TOBE_EXECUTE'
  },
  {
    label:'执行中',
    value: 'EXECUTING'
  },
  {
    label:'执行失败',
    value: 'EXECUTE_FAILURE'
  },
  {
    label:'执行成功',
    value: 'EXECUTE_SUCCESS'
  },
  {
    label:'终止',
    value: 'TERMINATE'
  }
]

// 错误处理方式枚举
export const ErrorHandleWayEnums = [
  {
    label: '终止',
    value: 'TERMINAL'
  },
  {
    label:'忽略',
    value: 'IGNORE'
  }
]

// 执行方式枚举
export const ExecuteWayEnums = [
  {
    label: '自动',
    value: 'AUTO'
  },
  {
    label:'手动',
    value: 'MANUAL'
  }
]