import { fetchGet, fetchPost } from '../customFetch'
import { CommonResponseRes } from '../common';

export interface IControlItemParams {
  id?: number;
  name: string;
  manager: boolean;
  clientType: string;
  managerIp: {
    white: boolean;
    ip: string[]; 
  };
  managerHost: {
    white: boolean;
    host: string[];
  };
  datasourceList?: {
    datasourceType: string;
    connectionId: number;
    name?: string;
  }[];
  description?: string;
}
//添加管控项
export const addControlItem = (
  params: IControlItemParams,
): Promise<any> => {
  return fetchPost(`/clientManager/addOneRule`, params)
}

//编辑 管控项
export const editControlItem = (
  params: IControlItemParams,
): Promise<any> => {
  return fetchPost(`/clientManager/editOneRule`, params)
}


export interface IConnectionInstanceItem {
 id: number;
 name: string;
 ip: string;
 port: string;
 user: string;
 serviceName?: string;
}
export interface IRuleListData {
  totalNum: number;
  clientManagerDTOList: IConnectionInstanceItem[]
}

export interface ISearchParams {
  pageSize: number;
  pageNum: number;
  searchFilter?: string;
}
//所有配置信息
export const getAllRulesByPage = (params: ISearchParams): Promise<IRuleListData> => {
  return fetchPost(`/clientManager/getAllRulesByPage`,params)
}

export interface IConnectionInfo {
  id: number;
  name: string;
  ip:string;
  port:string;
  user: string;
  serviceName: string;
}
export interface IConnectionInstanceItem{
  connectionType: string;
  managerConnectionList: IConnectionInfo[]
}
//数据库实例
export const getConnectionInstances = (): Promise<IConnectionInstanceItem[]> => {
  return fetchGet(`/clientManager/getConnectionInstances`)
}

export interface IDeleteCtrolItemParams {
  id: number;
}
//删除 配置项
export const deleteControlItem = (params: IDeleteCtrolItemParams): Promise<CommonResponseRes> => {
  return fetchPost(`/clientManager/deleteOneRule`,params)
}

export interface IChangeCtrolStateParams {
  id: number;
  state: boolean;
}
//开启或关闭 管控配置
export const changeControlItemState = (params: IChangeCtrolStateParams): Promise<CommonResponseRes> => {
  return fetchPost(`/clientManager/changeStateOneRule`,params)
}

//受控工具类型
export const getClientType = (): Promise<string[]> => {
  return fetchPost(`/clientManager/getClientType`)
}
