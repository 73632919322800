// 模拟环境验证
import React, { useState, useContext, useEffect, useMemo } from "react";
import * as _ from "lodash";
import { Button, Card, Tabs, Spin, Table, Badge } from "antd";
import {
  simulationExecute,
  pollingExecuteResult,
  executeSqlLog,
  rollbackSqlLog,
  SimulationExecuteI
} from "src/api";
import styles from "./index.module.scss";
import { useRequest } from "src/hook";
import { FormContext } from "../ApplyFormContext";
import classnames from "classnames";
import { RcSegmented, SegmentedValue } from "src/components";
import { CURRENT_ROUTE_TYPE } from "src/constants";



interface SimuleEnvironmentValidationProps {
  form: any
  applyUserId: string
  isViewDetail?: boolean
  currentRouteType?: CURRENT_ROUTE_TYPE
  [p: string]: any
}

export const SimuleEnvironmentValidation = (props: SimuleEnvironmentValidationProps) => {
  const {
    form,
    applyUserId = "",
    isViewDetail,
    currentRouteType,
  } = props;

  const { setCurrentStep, currentContextValue, setCurrentContextValue } = useContext(FormContext);

  const [loading, setLoading] = useState(false);
  const sqlType = form.getFieldValue("sqlType");

  //控制 执行验证/回退验证 按钮显示
  const [sqlBtnVisible, setSqlBtnVisible] = useState(false);
  const [activeKey, setActiveKey] = useState("executeLog");
  const [logFileNames, setLogFileNames] = useState<{fallbackLogName?: string;executeLogName?:string}>({});
  const [executionLog, setExecutionLog] = useState<any[]>([]);
  const [fallbackLog, seFallbackLog] = useState<any[]>([]);
  // 是否执行 执行验证
  const [isClickExecutionVal, setIsClickExecutionVal] = useState<boolean>(false);
  // 是否执行 回退验证
  const [isClickFallbackVal, setIsClickFallbackVal] = useState<boolean>(false);
  const isMineApply = (currentRouteType === "MINE_APPLY");


  const LOG_TYPE_TABS = [
    {
      label: '执行日志',
      value: 'executeLog'
    },
    {
      label: '回退日志',
      value: 'fallbackLog'
    }
  ]

  //动态查询当前模拟库日志
  const { run: handleSimulationExecute } = useRequest(simulationExecute, {
    manual: true,
  });

  //执行sql日志
  const { run: handleExecuteSqlLog } = useRequest(executeSqlLog, {
    manual: true,
    onSuccess: (res = []) => {
      setExecutionLog(res);
    },
  });
  
  //回退sql模拟执行日志
  const { run: handleRollbackSqlLog } = useRequest(rollbackSqlLog, {
    manual: true,
    onSuccess: (res = []) => {
      seFallbackLog(res);
    },
  });

  useEffect(() => {
    if (
      currentRouteType === "MINE_APPROVE" &&
      currentContextValue?.executeStatus === "审批中" &&
      currentContextValue?.id
    ) {
      handleExecuteSqlLog(currentContextValue.id);
      handleRollbackSqlLog(currentContextValue.id);
      setLogFileNames({
        executeLogName: currentContextValue?.simulateExecuteLog,
        fallbackLogName: currentContextValue?.simulateRollbackLog
      })
    }
  }, [currentRouteType, currentContextValue?.executeStatus]);

  const handleValidate = (key: string) => {
    setActiveKey(key);
    const { simulateNodePath } = currentContextValue;
    let params: SimulationExecuteI = {
      sqlType,
      nodePath: simulateNodePath,
      simulateType: key === "executeLog" ? 1 : 2,
      dataSourceType: currentContextValue?.dataSourceType,
      sqlContent:
        key === "executeLog"
          ? currentContextValue?.sqlStatement
          : currentContextValue?.rollbackSqlStatement,
      sqlOwnerId: applyUserId,
    };
    if (currentContextValue?.flowUUID) {
      params.dataChangeId =  currentContextValue?.id;
    }
    setLoading(true);
    handleSimulationExecute(params)
      .then((simualteKey: string) => {
        // 轮询执行结果
        pollingResult(simualteKey, key);
        setSqlBtnVisible(!sqlBtnVisible);
      })
      .catch((err: any) => {
        setLoading(false);
        console.error("验证失败", err);
      });
  };

  // 轮询执行结果
  const pollingResult = (simualteKey: string, activeKey: string) => {
    const timer = setInterval(() => {
      pollingExecuteResult(simualteKey)
        .then((res) => {
          const {
            isFinishExecute,
            logFilePath,
            statementExecuteLogList = [],
            isExist = true, //true继续轮询， false 没有对应的执行任务
          } = res;
          if (isFinishExecute || !isExist) {
            if (activeKey === "executeLog") {
              setExecutionLog(statementExecuteLogList);
       
              setLogFileNames({
                ...logFileNames,
                executeLogName: logFilePath,
              });
              setCurrentContextValue({
                ...currentContextValue,
                simulateExecuteLog: logFilePath
              })
            } else {
              seFallbackLog(statementExecuteLogList);
              setLogFileNames({
                ...logFileNames,
                fallbackLogName: logFilePath,
              });
              setCurrentContextValue({
                ...currentContextValue,
                simulateRollbackLog: logFilePath
              })
            }
            setLoading(false);
            clearInterval(timer);
          }
        })
        .catch((err) => {
          setLoading(false);
          clearInterval(timer);
          console.error("轮询结果失败：", err);
        });
    }, 3000);
  };

  // 导出
  const handleDownload = () => {
    //审批人 导出上次执行日志
    if (activeKey === "executeLog" && logFileNames?.executeLogName) {
      window.open(`/api/flow/dataChange/exportSimulateExecuteLog/${logFileNames?.executeLogName}`)
    } else {
      logFileNames?.fallbackLogName && window.open(`/api/flow/dataChange/exportSimulateExecuteLog/${logFileNames?.fallbackLogName}`);
    }
  };

  const handleActiveKeyChange = (key: string) => {
    setActiveKey(key);
  };

  const columns = [
    {
      title: 'SQL语句',
      dataIndex: 'statement',
      key: 'statement',
      width: '45%',
      fixed: true,
    },
    {
      title: '耗时',
      dataIndex: 'duration',
      key: 'duration',
      width: '10%',
      render: (text: string) => {
        return <span>{text}ms</span>
      }
    },
    {
      title: '影响行数',
      dataIndex: 'affectedRows',
      key: 'affectedRows',
      width: '10%',
    },
    {
      title: '结果分析',
      dataIndex: 'errMsg',
      key: 'errMsg',
      render: (text: string, record: any) => {
        return (
          <>
            <Badge 
              status={record?.success ? 'success' : 'error'}  
              text={record?.success ? '成功' : '失败'}   
            />
            <span>
              {!record?.success &&
                <span className={styles.errMsgWrap}>
                  (<div className={styles.errMsg} title={text}>{text}</div>)
                </span>
              } 
            </span>
          </>
        )
      }
    }
  ]

  const renderExportBtn = useMemo(() => {
    return (
      ( (activeKey === "executeLog" && logFileNames?.executeLogName) ||
        (activeKey === "fallbackLog" && logFileNames?.fallbackLogName))
      &&  <Button
            disabled={_.isEmpty(logFileNames)}
            onClick={handleDownload}
            className={styles.extraBtn} 
          >
            导出
          </Button>
      
    )
  }, [activeKey, logFileNames])

  const renderTable = (data: any[]) => {
    return (
      <Table
        key="logInfo"
        columns={columns}
        dataSource={data}
        bordered={false}
        scroll={{y: "250px"}}
      />
    )
  }

  const renderLog = (data: any[], key: string) => {
    return (
      <div className={styles.logWrap}>
        {
          key === "executeLog" && isClickExecutionVal && renderTable(data)
        }
        {
          key === "fallbackLog" && isClickFallbackVal && renderTable(data)
        }
        {isMineApply && key === "executeLog" && !isClickExecutionVal? (
          <Button
            className={styles.optionBtn}
            type="primary"
            onClick={() => {
              handleValidate("executeLog");
              setIsClickExecutionVal(true);
            }}
            disabled={
              !(
                currentContextValue?.sqlStatement &&
                !sqlBtnVisible &&
                currentContextValue?.simulateNodePath
              )
            }
          >
            执行验证
          </Button>
        )
        :
        isMineApply && key === "fallbackLog" && !isClickFallbackVal && (
          <Button
            className={styles.optionBtn}
            type="primary"
            onClick={() => {
              handleValidate("fallbackLog");
              setIsClickFallbackVal(true);
            }}
            disabled={
              !(
                currentContextValue?.rollbackSqlStatement &&
                sqlBtnVisible &&
                currentContextValue?.simulateNodePath
              )
            }
          >
            回退验证
          </Button>
        )
        }
      </div>
    );
  };

  return (
    <Spin spinning={loading}>
      <Card
        title={ !isViewDetail? "" : "模拟环境验证"}
        className={classnames(styles["step-card"], styles["simuleEnvironmentVal-card"], isMineApply && styles["step-card2"], !isViewDetail && styles['borderNone'])}
        bordered={false}
        id="simuleEnvironmentValidationCard"
        actions={
          !isViewDetail
            ? [
                <div className={styles['footer-btn']}>
                  <>
                  <Button
                    className={classnames(styles['secondary-btn'], styles['lastStep-btn'])}
                    onClick={() => {
                      setCurrentStep &&
                      setCurrentStep((currentStep: number) => {
                        return (currentStep - 1)
                      })
                      }
                    }
                  >
                    上一步
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      setCurrentStep && 
                      setCurrentStep((currentStep: number) => {
                        return (currentStep + 1)
                      })
                      }
                    }
                  >
                    下一步
                  </Button>
                  </>
                </div>,
              ]
            : []
        }
        extra={
          isViewDetail &&
          <>
          {renderExportBtn}
          <RcSegmented options={LOG_TYPE_TABS} onChange={(type: SegmentedValue) => handleActiveKeyChange(type.toString())} />
          </>
        }
      >
        {
          isViewDetail && renderTable(activeKey === "executeLog"? executionLog : fallbackLog)
        }

        {
          !isViewDetail && 
          <Tabs
            activeKey={activeKey}
            onChange={handleActiveKeyChange}
            tabBarExtraContent={renderExportBtn}
          >
            <Tabs.TabPane key="executeLog" tab="执行日志">
              {renderLog(executionLog, "executeLog")}
            </Tabs.TabPane>
            <Tabs.TabPane key="fallbackLog" tab="回退日志">
              {renderLog(fallbackLog, "fallbackLog")}
            </Tabs.TabPane>
          </Tabs>
        }

        <div className={styles.logListWrap}></div>
      </Card>
    </Spin>
  );
};
