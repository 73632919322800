import React, { PropsWithChildren, useEffect, useMemo } from 'react'
import {
  useSelector,
  useDispatch,
  useRequest,
  useSdtNodeChildren,
} from 'src/hook'
import { Row, Col, message, TreeSelect } from 'antd'
import { SelectMore as Select } from 'src/components/SelectMore'
import {
  setConnectionSession,
  // changeDatabase,
  PaneInfo,
  updateConnectionSession,
} from '../../queryTabsSlice'
import { getExecutedSql, NodeEntity } from 'src/api'
import { getOperatingObject } from 'src/util'

interface SelectorProps {
  paneInfo: PaneInfo
  clearModelDec: () => void
  oneLibraryAndOneConnection?: boolean
}

// antd select bug 受控组件 value 为undefined 时 select 其他选项value 明明为undefined 但视图却改变了 使用null 作为转换值
const transformValueUndefined2Null = (value: any) => value === undefined ? null : value

/**
 * session related controllers in editor toolbar
 */
export const MonacoToolbarSessionSelectors = React.memo(
  ({ paneInfo, clearModelDec, oneLibraryAndOneConnection }: PropsWithChildren<SelectorProps>) => {
    const dispatch = useDispatch()
    const {
      key,
      connectionId,
      databaseName,
      schemaName,
      pending: executionPending,
      plSql,
      tSql,
    } = paneInfo

    const dataSourceDescInfo = useSelector((S) => S.dataSource.dataSourceMap)

    // TODO: 多余的接口调用，应该从 toolbar 上事务相关部分获取
    /* 切库或者切表之前检查是否存在事务 */
    const { run: getIsInTransaction } = useRequest(
      () => {
        if (!connectionId || !databaseName) {
          return Promise.resolve([])
        }
        return getExecutedSql({
          tabKey: key,
          connectionId,
          databaseName,
        } as any)
      },
      {
        manual: true,
        formatResult: (data) => data && data[0],
      },
    )
    // toolbarConnections
    const { toolbarConnections, loadingConnections, toolbarConnectionsWithGroup } = useSelector(
      (state) => state.editor,
    )
    // connection options and selected value
    const selectedConnection = useMemo(
      () =>
        toolbarConnections?.find((node) => node.connectionId === connectionId),
      [connectionId, toolbarConnections],
    )

    const dataSourceLevel =
      dataSourceDescInfo[selectedConnection?.connectionType || 'MySQL']
        ?.dataSourceLevel || 2

    const { data: toolbarDatabases, loading: loadingDatabases } =
      useSdtNodeChildren(selectedConnection)

    const targetDatabase = useMemo(
      () =>
        toolbarDatabases?.find((node) => {
          if (['DamengDB', 'DB2'].includes(node.connectionType)) {
            return node.nodeName === schemaName
          } else {
            return node.nodeName === databaseName
          }
        }),
      [databaseName, toolbarDatabases, schemaName],
    )

    const databaseOptions = useMemo(
      () =>
        toolbarDatabases?.map(({ nodeName }) => ({
          label: nodeName,
          value: nodeName,
        })),
      [toolbarDatabases],
    )

    useEffect(() => {
      if (executionPending) return
      if (dataSourceLevel === 2 && targetDatabase) {
        const { connectionId, connectionType, nodeName, nodeType } =
          targetDatabase
        const dataSourceType = connectionType
        // ? Dameng 数据源没有 database，只有 schema
        // dameng和db2的databaseName按照schema传，后端执行接口需要传databaseName
        const payload =
          nodeType === 'schema'
            ? {
              key,
              paneInfo: {
                connectionId,
                connectionType,
                databaseName: nodeName,
                schemaName: nodeName,
              },
            }
            : {
              key,
              paneInfo: {
                connectionId,
                connectionType,
                databaseName: nodeName,
                schemaName,
              },
            }

            dispatch(setConnectionSession(payload))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSourceLevel, dispatch, key, targetDatabase])

    const onSelectConnection = async(path: string | number ,option: any) => {
      
      const isInTransaction = await getIsInTransaction()
      if (isInTransaction) {
        message.error('当前有尚未提交的事务，请先提交或取消再后操作')
        return
      }

      if (option) {
        const { connectionId, connectionType } = option
        dispatch(
          setConnectionSession({
            key,
            paneInfo: {
              connectionId,
              connectionType,
              databaseName: '',
              schemaName: '',
            },
          }),
        )
      }
      // 切换数据库连接也需要清空之前的错误标记
      clearModelDec()
    }

    return (
      <Row gutter={[8, 4]}>
        <Col>
            <TreeSelect
              filterTreeNode={(inputV: string, treeNode: any) => {
                if (!inputV) return true
                if (treeNode.nodeName && treeNode.nodeName.includes(inputV))
                  return true
                return false
              }}
              // treeNodeLabelProp={"alias"|| "nodeName"}
              style={{ width: 160 }}
              dropdownStyle={{ width: 200 }}
              dropdownMatchSelectWidth={false}
              value={transformValueUndefined2Null(selectedConnection?.nodePath)}
              treeData={toolbarConnectionsWithGroup as any}
              placeholder="请选择连接"
              size="small"
              showSearch
              allowClear
              treeIcon
              onSelect={onSelectConnection}
              loading={loadingConnections}
              disabled={executionPending || loadingDatabases || plSql || tSql}
              showArrow={!plSql && !tSql}
              onClear={() => {
                 //清空connetion
                  dispatch(
                    setConnectionSession({
                      key,
                      paneInfo: {
                        //@ts-ignore
                        connectionId: undefined,
                        //@ts-ignore
                        connectionType: undefined,
                        databaseName: '',
                        schemaName: '',
                      },
                    }),
                  )
              }}
            />
        </Col>
        <Col>
          {selectedConnection && (
            <Select
              options={databaseOptions}
              style={{ width: 160 }}
              allowClear
              placeholder={['DamengDB', 'DB2'].includes(selectedConnection.connectionType) ? "请选择 schema" : "请选择数据库"}
              value={transformValueUndefined2Null(targetDatabase?.nodeName)}
              onChange={async (databaseName) => {
                const isInTransaction = await getIsInTransaction()
                // 一库一连接为true时，databases不允许切换
                // http://share.bintools.cn:8080/pages/viewpage.action?pageId=2176650
                if (isInTransaction && oneLibraryAndOneConnection) {
                  message.error('当前有尚未提交的事务，请先提交或取消再后操作')
                  return
                }
                //清空databaseName 除了清除dbname 还要清除schemaname
                if (databaseName === undefined) {
                  dispatch(
                    updateConnectionSession({
                      key,
                      paneInfo: {
                        databaseName: '',
                        schemaName: ''
                      },
                    }),
                  )
                }
                // 设置查询窗口上下文
                const database = toolbarDatabases?.find(
                  (node) => node.nodeName === databaseName,
                )
                if (database) {
                  const {
                    connectionId,
                    connectionType,
                    nodeName: databaseName,
                  } = database
                  let paneInfo = {
                    connectionId,
                    connectionType,
                    databaseName,
                    schemaName: '',
                  }
                  if (['DamengDB', 'DB2'].includes(connectionType)) {
                    paneInfo = {
                      connectionId,
                      connectionType,
                      databaseName: '',
                      schemaName: databaseName,
                    }
                  }

                  dispatch(
                    setConnectionSession({
                      key,
                      paneInfo,
                    }),
                  )
                }
                clearModelDec()
              }}
              size="small"
              showSearch
              loading={loadingDatabases}
              disabled={executionPending}
            />
          )}
        </Col>
        {/* schema selector */}
        <Col>
          {dataSourceLevel === 3 && (
            <SchemaSelectRenderer
              database={targetDatabase}
              getIsInTransaction={getIsInTransaction}
              paneInfo={paneInfo}
              clearModelDec={clearModelDec}
            />
          )}
        </Col>
      </Row>
    )
  },
)

interface SchemaSelectRendererProps extends SelectorProps {
  database?: NodeEntity
  getIsInTransaction?: () => Promise<string>
}

function SchemaSelectRenderer({
  database,
  paneInfo,
  clearModelDec,
  getIsInTransaction,
}: SchemaSelectRendererProps) {
  const dispatch = useDispatch()
  const { key, schemaName, pending } = paneInfo

  const { data: schemas, loading } = useSdtNodeChildren(database)
  const schemaOptions = schemas?.map(({ nodeName }) => ({
    label: nodeName,
    value: nodeName,
  }))
  const targetSchema = useMemo(
    () =>
      schemas?.find((node) => {
        return node.nodeName === schemaName
      }),
    [schemaName, schemas],
  )


  useEffect(() => {
    if (!database || !targetSchema || pending) return
    const { connectionId, connectionType, nodeName: schemaName } = targetSchema
    const databaseName = database.nodeName
    const dataSourceType = connectionType
    // dispatch(
    //   changeDatabase({
    //     tabKey: key,
    //     connectionId,
    //     dataSourceType,
    //     databaseName,
    //     operatingObject: getOperatingObject(
    //       { databaseName, schemaName },
    //       dataSourceType,
    //     ),
    //   }),
    // )
    //   .unwrap()
    //   .then(() => {
        dispatch(
          setConnectionSession({
            key,
            paneInfo: {
              connectionId,
              connectionType,
              databaseName,
              schemaName,
            },
          }),
        )
      // })
      // .catch(() => {
      //   dispatch(
      //     setConnectionSession({
      //       key,
      //       paneInfo: {
      //         connectionId,
      //         connectionType,
      //         databaseName,
      //         schemaName: '',
      //       },
      //     }),
      //   )
      // })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [database, dispatch, key, targetSchema])

  return (
    <Select
      options={schemaOptions}
      style={{ width: 160 }}
      allowClear
      placeholder="请选择 schema"
      value={transformValueUndefined2Null(targetSchema?.nodeName)}
      onChange={async (schemaName) => {

        // 存在事务时，schema允许切换
        // http://share.bintools.cn:8080/pages/viewpage.action?pageId=2176650
        // const isInTransaction = await getIsInTransaction?.()
        // if (isInTransaction) {
        //   message.error('当前有尚未提交的事务，请先提交或取消再后操作')
        //   return
        // }
        // 清空schemaname
        if (schemaName === undefined) {
          dispatch(
            updateConnectionSession({
              key,
              paneInfo: {
                schemaName,
              },
            }),
          )
        }
        // 设置查询窗口上下文
        const schema = schemas?.find((node) => node.nodeName === schemaName)
        if (schema) {
          const { connectionId, connectionType, nodeName: schemaName } = schema
          const databaseName = database?.nodeName || ''
          dispatch(
            setConnectionSession({
              key,
              paneInfo: {
                connectionId,
                connectionType,
                databaseName,
                schemaName,
              },
            }),
          )
        }
        clearModelDec()
      }}
      size="small"
      showSearch
      loading={loading}
      disabled={pending}
    />
  )
}
