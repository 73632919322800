import React, { useEffect, useMemo, useRef, useState, useCallback, forwardRef } from 'react'
import { Tree, Dropdown, Menu, Modal, Input } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import { Iconfont } from 'src/components'
import { deleteGroup, removeConnection } from 'src/api'
import styles from './index.module.scss'
import classnames from 'classnames'
import { cloneDeep } from 'lodash'
import { TreeProps } from 'rc-tree'
import {
  addConnection,
} from 'src/api'
import { resetPermissionModal } from 'src/pageTabs/authorizationManagePage/commonMethods'
import sortBy from 'lodash/sortBy'
import PermissionTooltip from 'src/components/PermissionTooltip/PermissionTooltip'

const filteredAndFormatTreeData = (treeData: any) => {
  const formatTree = (data: any[],pKey?: string, rootId?: string): any[] => {
  
    return data.map((item: any, index: number) => {
      const key = pKey ?  `${pKey}-${index}` : index.toString()
      // 新增根节点，非同根节点不允许拖拽
      item.rootId = rootId || item.id
      item.key = key// 构造唯一id
      // item.title = higlightTitle(item.nodeName, searchValue)
      item.title = item.nodeName;
      if (item.children) {
        item.children = formatTree(item.children,key, item.rootId)
      }
      return { ...item }
    })
  }

  return formatTree(treeData).filter(node => node?.children?.length)
}

const findTargetNode = (
  data: any[], 
  nodeKey: string, 
  callback: (node: any, index: number, data: any[]) => void
) => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].key === nodeKey) {
      return callback(data[i], i, data)
    }
    if (data[i].children) {
      findTargetNode(data[i].children, nodeKey, callback)
    }
  }
}

const findParentNode = (
  data: any[],
  node: any
) => {
  if (isGroupNode(node) || isRootNode(node)) return node
  let result
  const loop = (
    data: any, 
    node: any
  ) => {
    if (data.children) {
      for (let i = 0; i < data.children.length; i++) {
        if (data.children[i].key === node.key) {
          result = data
          break
        } else if (data.children[i].children) {
          loop(data.children[i], node)
        }
      }
    }
  }
  for (let i = 0; i < data.length; i++)  {
    loop(data[i], node)
    if (result) break
  }
  return result
}

// 实例试图按连接和数据源构造tree，组按连接的ip和port构造
const generateInstanceTree = (data: any[]) => { 
  const fParent = data?.filter((i: any) => i?.nodeType === 'datasource')
  const connections = data
    ?.filter((i: any) => i?.nodeType === 'connection')
    ?.map((i: any) => {
      const datasourceType = i?.connection?.connectionType
      return {
        ...i,
        parentDatasourceType: datasourceType,
      }
    })

  fParent?.map((r: any) => {
    const children = connections?.filter(
      (i: any) => i?.parentDatasourceType === r?.id,
    )
    const groupParent = children
      ?.map((i: any) => i?.connection?.ip + ':' + i?.connection?.port)
      ?.filter((r, index, arr) => arr.indexOf(r) === index)
      ?.map((n: any) => {
        const child = children?.filter(
          (i: any) => i?.connection?.ip + ':' + i?.connection?.port === n,
        )
        return {
          id: n,
          key: n,
          title: n,
          nodeName: n,
          nodeType: 'group',
          children: child,
          layer: { childCount: child?.length || 0 },
          parentId: r?.id
        }
      })
    r.children = groupParent
    // 实例试图的数据源统计按下面的实例数计算
    r.layer = {
      childCount: groupParent?.length || 0
    }
    return { ...r }
  })
  return filteredAndFormatTreeData(fParent)
}

// 构造树结构(分组视图：id和parentId)
const generateNormalTree = (data: any[]) => { 
  const allId = data
    ?.map((i) => i.id)
    ?.filter((i, index, arr) => arr.indexOf(i) === index)
  const allParentId = data
    ?.map((i) => i.parentId)
    ?.filter((i, index, arr) => arr.indexOf(i) === index)

  const validateParentId = (item: any) => {
    return !!data?.filter((i: any) => {
      return i?.parentId === item?.id && item?.nodeType !== i?.nodeType
    })?.length
  }

  const filterData = data.filter((item, _, arr) => {
    // 自身是父级（且id和parentId不能重复）
    if (allParentId.includes(item.id) && validateParentId(item)) {
      item.children = arr.filter((i) => i.parentId === item.id)
    }
    // 没有父级
    if (!allId.includes(item.parentId) || !item.parentId) {
      return true
    }
    return false
  })
  return filteredAndFormatTreeData(filterData)
}

const isRootNode = (node: any) => !node.parentId
const isConnectionNode = ({ nodeType }: any) => nodeType === 'connection'
const isGroupNode = ({ nodeType }: any) => nodeType === 'group'
const isSameRoot = (sNode: any, dNode: any) => sNode.rootId === dNode.rootId
const isSameParent = (sNode: any, dNode: any) => sNode.parentId === dNode.parentId
const isInGroup = (node: any) => node.parentId && node.parentId !== node.rootId

 function getExpandKeysNodeTreeAllChildren(treeNode: any) {
  const keys: string[] = []

  const getKeys = (node: any) => {
    if (node?.children?.length) keys.push(node.key)
    if (node?.children?.length) {
      node.children.forEach((child: any) => getKeys(child))
    }
  }
  getKeys(treeNode)
 
  return keys
}
interface IProps {
  handleTreeSelect: (keys: any[], info: any) => void
  [p: string]: any
}
const TreeComponent = (props: IProps) => {
  const {
    data=[],
    curTabs,
    searchValue = '',
    refreshTree,
    selectedKey,
    handleTreeSelect,
    handleAddConnection,
    handleDeleteConnection,
    handleEditContent,
    handleCopyContent,
    handleTestConnection,
    handleCreateConnection,
    handleTreeDefaultSelect,
    handleAddGroup,
    handleSettingManager,
    permissionlist,
    isBuildConnection,
  } = props

  const originData = cloneDeep(data)
 
  const [expandedKeys, setExpandedKeys] = useState<any[]>([])
  const [autoExpandParent, setAutoExpandParent] = useState(true)
  const [selectedKeys, setSelectedKeys] = useState<any[]>([])
  const [isInit, setIsInit] = useState(true)
  const [treeData, setTreeData] = useState<any[]>([])
  const groupTreeData = useRef<any[]>([])
  const originDataRef = useRef(data)
  const [treeHeight, setTreeHeight] = useState<number>(300)

  useEffect(()=>{
    queryTreeHeight()
  }, [])

  window.onresize = () => {
    queryTreeHeight()
  }
  const queryTreeHeight = () => {
    const clientHeight = document.documentElement.clientHeight
    const treeHeight = clientHeight > 478 ? clientHeight - 278 : 200
    setTreeHeight(treeHeight)
  }

  // /* 实现全局节点搜索 */
  const filterNodesNotMatch = useCallback(
    (nodes: any): any => {
      //@ts-ignore
      return nodes?.reduce((acc, node) => {
        const { children, ...rest } = node;
        if (children) {
          const filteredChildren = filterNodesNotMatch(children);
          if (filteredChildren.length > 0 || rest.title.toLowerCase().includes(searchValue.toLowerCase())) {
            acc.push({ ...rest, children: filteredChildren });
          }
        } else if (rest.title.toLowerCase().includes(searchValue.toLowerCase())) {
          acc.push({ ...rest });
        }
        return acc;
      }, []);
    },
    [searchValue],
    )
    
    useEffect(() => {
      if (selectedKey) {
        setSelectedKeys([selectedKey])
      }
    }, [selectedKey])
    
    // 生成树
    useMemo(() => {
      if (!data?.length) { 
      return []
    }

    if (!originDataRef.current || !originDataRef.current.length) {
      originDataRef.current = data
    }

    if (curTabs === 'instance') {
      const result = generateInstanceTree(data)
      setTreeData(result)
      return result
    } else {
      // 因为只支持分组视图拖拽，且拖拽后的结果存储在本地
      // 所以在原数据没有变更的情况下，搜索和展开等动作都单独在本地处理和更新
      if (originDataRef.current === data && groupTreeData.current && groupTreeData.current.length) {
        // const keys = getExpandedKeys(groupTreeData.current, searchValue)
        // setExpandedKeys(keys)
        // setAutoExpandParent(true)
        setTreeData(groupTreeData.current)
      } else {
        originDataRef.current = data
        const result = generateNormalTree(data)
        groupTreeData.current = result
        setTreeData(result)
      }
      return
    }
  }, [data, curTabs])

  useEffect(() => {
    if (isInit && treeData?.length) {
      setIsInit(false)
      initStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData])

  useEffect(() => {
    // tabs切换重置tree选中值
    if (curTabs && !isInit) {
      initStatus()
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curTabs])

  const initStatus = () => { 
    setDefaultSelectKeys()
    setExpandedKeys([])
    setAutoExpandParent(true)
  }

  const handleRefresh = () => { 
    refreshTree()
    initStatus()
  }

  // 设置默认选中项
  const setDefaultSelectKeys = () => {
    const defaultSelectedItem = treeData.find(
      (item: any) => item.nodeType === 'datasource',
    )
    const defaultSelectedKeys = defaultSelectedItem?.key
    setSelectedKeys([defaultSelectedKeys])
    handleTreeDefaultSelect(defaultSelectedItem)
  }

  const handleExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys)
    setAutoExpandParent(false)
  }

  const handleSelect = (item: any[], info: any) => {
    setSelectedKeys(item)
    handleTreeSelect(item, info)
  }

  /**
   * 删除组
   */
  const dealDeleteGroup = async (id: number) => {
    await deleteGroup(id)
    refreshTree()
	}
	const handleDeleteGroup = async (id: number) => {
    Modal.confirm({
      title: '确认解除组吗？',
      okText: '确定',
      cancelText:'取消',
      onOk: () => {
        dealDeleteGroup(id)
      },
    })
  }

  /**
   * 移出连接
   */
  const dealRemoveConnection = async (id: number) => {
    await removeConnection([id])
    handleRefresh()
  }
  const handleRemoveConnection = async (id: number) => {
    Modal.confirm({
      title: '确认移出连接吗？',
      okText: '确定',
      cancelText:'取消',
      onOk: () => {
        dealRemoveConnection(id)
      },
    })
  }

  //是DBA且根据菜单权限判断；非DBA的都不能操作 
  const hasMoreMenuPermission = useMemo(() => {

    const isDBA =  permissionlist?.roleNameList?.includes('DBA角色');
    return isDBA && !permissionlist?.isOnlyRead
  },[permissionlist?.isOnlyRead])

  // tree标题右侧更多操作menu
  const treeMenu = (
    type: string,
    id: number,
    nodeName: string,
    parentId: string,
    dataSourceType: string,
  ) => {
    const groupInfo = {
      groupId: id,
      groupName: nodeName,
      connectionType:dataSourceType
    }
    switch (type) {
      case 'datasource':
        return (
          <Menu className={styles.optionTxt}>
            {/* 实例试图不能添加组 */}
            {curTabs !== 'instance' && (
              <Menu.Item onClick={() => handleAddGroup({...groupInfo,groupName: '',modalType: 'createGroup'})}>新建分组</Menu.Item>
            )}
            <Menu.Item onClick={() => handleCreateConnection(nodeName)}>
              新建连接
            </Menu.Item>
          </Menu>
        )
      case 'group':
        return (
          <Menu className={styles.optionTxt}>
            <Menu.Item onClick={() => handleAddConnection({...groupInfo,  modalType: 'modifyGroupName'})}>修改名称</Menu.Item>
            <Menu.Item onClick={() => handleDeleteGroup(id)}>解除</Menu.Item>
            <Menu.Item onClick={() => handleAddConnection({...groupInfo, modalType: 'addConnection'})}>
              添加连接
            </Menu.Item>
            <Menu.Item onClick={() => handleAddConnection({...groupInfo, modalType: 'moveoutConnection'})}>
              移出连接
            </Menu.Item>
            <Menu.Item onClick={() => handleSettingManager(id)}>设置连接管理员</Menu.Item>
          </Menu>
        ) 
      case 'connection':
        return (
          <Menu className={styles.optionTxt}
          //  onClick={(event:any) => { event?.domEvent?.stopPropagation(); }}
           >
            {
            // 组下的连接才可以移出
              type === 'connection' && getParentType(parentId) === 'group'
              && <Menu.Item onClick={() => handleRemoveConnection(id)}>
                移出连接
              </Menu.Item>
            }
            <Menu.Item onClick={() => handleEditContent({ connectionId: id })}>
              编辑连接
            </Menu.Item>
            <Menu.Item onClick={() => handleCopyContent({ connectionId: id })}>
              复制连接
            </Menu.Item>
            <Menu.Item onClick={() => handleTestConnection(id, dataSourceType)}>
              测试连接
            </Menu.Item>
            <Menu.Item onClick={(e) => {
              handleDeleteConnection({ connectionId: id, connectionType: dataSourceType, connectionName: nodeName });
              e?.domEvent?.stopPropagation();
            }}>
              删除连接
            </Menu.Item>
          </Menu>
        )
      default:
        return <></>
    }
  }

  // 生成tree搜索标题
  const generatorSearchTitle = (
    nodeName: string,
    title: any,
    nodeType: string,
    nodeCount: number,
    testModel: number,
    dataSourceType: string
  ) => {
    return (
      <>
        <Iconfont
          className={classnames(styles.mr4, styles.color008dff, {
            [styles.colorf00]: testModel === 1 && nodeType === "connection",
            [styles.colorgreen]: !!testModel && testModel !== 1 && nodeType === "connection",
          })}
          type={`${
            nodeType === "datasource"
              ? `icon-connection-${nodeName}`
              : nodeType === "group"
              ? "icon-shujukuwenjianjia"
              : nodeType === "connection"
              ? `icon-${dataSourceType}`
              : `icon-database`
          } `}
        />
        <span className={styles.titleTxt}>
          {title}
          {nodeType !== "connection" && `(${nodeCount})`}
        </span>
      </>
    );
  };

  // 查询父级nodeType
  const getParentType = (pId: string) => { 
    return data?.filter((i: any) => i?.id === pId)?.[0]?.nodeType
  }

  // 渲染tree title完整内容
  const treeTitleRender = (node: any) => {
    const { title, id, parentId, nodeType, nodeName, layer, connection } = node
    // let isGroupConnection = false
    // if (nodeType === 'connection' && getParentType(parentId) === 'group') {
    //   isGroupConnection = true
    // }
    // 直接属于数据源的连接不展示操作（组下的连接可以操作），实例组、连接不展示操作
    // 改为每个连接都有操作，不区分判断是否是组下面的连接，实例组不展示操作，连接展示操作
    // const isInstanceGroup =
    //   curTabs === 'instance' && ['group', 'connection'].includes(nodeType)
    // const showOption =
    //   (isGroupConnection || nodeType !== 'connection') && !isInstanceGroup
    const isInstanceGroup = curTabs === 'instance' && ['group'].includes(nodeType)
    const showOption = !isInstanceGroup

    return (
      <div className={styles.treeTitleItem}>
        {generatorSearchTitle(
          nodeName,
          title,
          nodeType,
          layer?.childCount,
          connection?.testModel,
          connection?.connectionType
        )}
        {showOption && hasMoreMenuPermission && (
          <PermissionTooltip
            permissionlist={permissionlist}
            title='连接管理'
          >
            <div>
              <Dropdown overlay={() => treeMenu(nodeType, id, nodeName, parentId, connection?.connectionType || node?.nodeName)}>
                <MoreOutlined className={classnames(styles.ml10, styles.options)} />
              </Dropdown>
            </div>
          </PermissionTooltip>
        )}
      </div>
    );
  }

  const onDrop: TreeProps['onDrop'] = async (info) => {
    try {
      // 只支持分组视图
      if (curTabs === 'instance') return
      const dataTemp = [...treeData]
      const { node, dragNode, dropToGap } = info as any
      // 根节点,非连接节点,跨根节点,同组节点不允许拖拽
      if (isRootNode(dragNode) || !isConnectionNode(dragNode) || !isSameRoot(node, dragNode) || (isSameParent(node, dragNode) && dropToGap)) return

      // 组外到组内
      const inGroup = !isInGroup(dragNode) && (isGroupNode(node) || isInGroup(node))
      // 组内到组外
      const outGroup = isInGroup(dragNode) && !isInGroup(node) && !isGroupNode(node)
      // 组内到另外的组内
      const groupToGroup = isInGroup(dragNode) && (
        (isInGroup(node) && !isSameParent(dragNode, node)) || (isGroupNode(node) && !dropToGap) 
      )

      let dragObj: any
      let targetParent: any
      let dragParent: any
      
      // todo: 优化节点数更新，节点增减以及更新的逻辑
      if (inGroup || groupToGroup) {
        // 找到拖拽的节点对象，并从树中移除
        findTargetNode(dataTemp, dragNode.key, (item, i, arr) => {
          if (groupToGroup) {
            // 更新组的节点数
            dragParent = findParentNode(dataTemp, dragNode)
            dragParent.layer && dragParent.layer.childCount--
          }
          dragObj = item
          arr.splice(i, 1)
        })
        findTargetNode(dataTemp, node.key, (item, _, arr) => {
          targetParent = findParentNode(dataTemp, node)
          if (isGroupNode(node)) {
            // 拖拽的目标节点是组本身
            item.children = item.children || []
            dragObj.parentId = item.id
            item.children.push(dragObj)
            // 更新组的节点数
            item.layer && item.layer.childCount++
            // 按节点名字排序
            item.children = sortBy(item.children, ['nodeName'])
          } else {
            // 拖拽的目标节点在组内
            dragObj.parentId = item.parentId
            arr.push(dragObj)
            targetParent.layer && targetParent.layer.childCount++
            targetParent.children = sortBy(arr, ['nodeName'])
          }
        })
        const result = [...dataTemp]
        groupTreeData.current = result
        setTreeData(result)
        if (groupToGroup) {
          await removeConnection([dragNode.id])
        }

        try {
          const inheritOrNot: any = await  resetPermissionModal({
            title: '是否继承分组上相关设置',
            content:'选择继承后新添加进分组的连接会继承分组此前上的设置，包括权限设置、数据保护设置等,'
          })
    
          await addConnection({
            id: isGroupNode(node) ? node.id : node.parentId,
            connectionIds: [dragNode.id],
            dataSourceType: node?.connection?.connectionType,
            inheritToken:inheritOrNot
          })
        } catch (error) {
          
        }
       
        return
      }

      if (outGroup) {
        findTargetNode(dataTemp, dragNode.key, (item, i, arr) => {
          dragParent = findParentNode(dataTemp, dragNode)
          dragParent.layer && dragParent.layer.childCount--
          dragObj = item
          arr.splice(i, 1)
        })
        findTargetNode(dataTemp, node.key, (item, _, arr) => {
          let sortResult: any[]
          let groupNode: any[]
          targetParent = findParentNode(dataTemp, node)
          // 是否为根节点
          if (node.parentId) {
            dragObj.parentId = node.parentId
            arr.push(dragObj)
            // 组节点不参与排序
            sortResult = sortBy(arr.filter((obj: any) =>  !isGroupNode(obj)), ['nodeName'])
            groupNode = arr.filter((obj: any) => isGroupNode(obj))
            targetParent.children = groupNode.concat(sortResult)
          } else {
            item.children = item.children || []
            dragObj.parentId = item.id
            item.children.push(dragObj)
            sortResult = sortBy(item.children.filter((obj: any) => !isGroupNode(obj)), ['nodeName'])
            groupNode = item.children.filter((obj: any) => isGroupNode(obj))
            item.children = groupNode.concat(sortResult)
          }
        })
        setTreeData([...dataTemp])
        await removeConnection([dragNode.id])
      }
    } catch(err) {
      console.log(err)
    }
  }

  const filteredTreeDataOnLocal = useMemo(() => {
 
    return searchValue ? filterNodesNotMatch(cloneDeep(treeData)) : treeData
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ JSON.stringify(treeData), searchValue])

  useEffect(() => {
    !searchValue && setExpandedKeys([])
  }, [searchValue])

  useEffect(() => {
   
    if (searchValue && filteredTreeDataOnLocal) {
      let keys: string[] = [];
 
        filteredTreeDataOnLocal?.forEach((item: any) => {
          keys = keys.concat(getExpandKeysNodeTreeAllChildren(item))
        })

       setExpandedKeys(keys)
    }
   
  }, [searchValue, filteredTreeDataOnLocal])

  // 新建连接，获取id最大的连接；即获取 最新建立的连接
  const findMaxKeyNode = (treeDataTmp: any) => {
    let maxId: number = 0;
    let maxNodeKey: string = '';
    let maxNode: any = {};
    let maxNodePath: string[] = [];
  
    const traverseTree = (data: any, parentKeys: string[]) => {
      data.forEach((node: any) => {
        if (Number(node.id) > maxId) {
          maxId = Number(node.id);
          maxNodePath = [...parentKeys, node.key]; // 更新最大 id 节点的路径
          maxNode = node
          maxNodeKey = node?.key
        }
        if (node.children) {
          traverseTree(node.children, [...parentKeys, node.key]); // 继续遍历子节点，并传入更新后的路径信息
        }
      });
    };
  
    treeDataTmp.forEach((node: any) => {
      traverseTree([node], []); // 从根节点开始遍历，并传入空的路径信息
    });
  
    return  {maxNodeKey, maxNode, maxNodePath};
  }

  // 触发 Tree onSelect 事件
  const triggerSelectEvent = (selectedNodes: any) => {
    const fakeInfo = {
      event: 'select',
      selected: true,
      node: selectedNodes,
      selectedNodes: [selectedNodes],
    };
    handleSelect(selectedKey, fakeInfo);
  };

  // 新建连接后，默认 点击 所新建的连接的节点
  useEffect(() => {
    if (isBuildConnection) {
      const res = findMaxKeyNode(filteredTreeDataOnLocal);
      const {maxNodeKey, maxNode, maxNodePath} = res || {}
      setSelectedKeys([maxNodeKey]);
      setExpandedKeys(maxNodePath)
      triggerSelectEvent(maxNode)
    }
  }, [filteredTreeDataOnLocal])

  return (
    <Tree
      height={treeHeight}
      className={styles.treeWrap}
      treeData={filteredTreeDataOnLocal}
      titleRender={treeTitleRender}
      expandedKeys={expandedKeys}
      autoExpandParent={autoExpandParent}
      onSelect={handleSelect}
      onExpand={handleExpand}
      selectedKeys={selectedKeys}
      onDrop={onDrop}
      draggable
    />
  )
}
export default TreeComponent