import React, { useState, useCallback, useEffect, SyntheticEvent } from 'react'
import { Table, Tooltip } from 'antd'
import { TableProps, ColumnsType } from 'antd/lib/table'
import { Resizable, ResizeCallbackData } from 'react-resizable'
import classNames from 'classnames'

import style from './index.module.scss'

export interface AdditionalProps {
  info?: any;
}

const defaultWidth = 100

export type CombinedProps<RecordType extends object> = TableProps<RecordType> & AdditionalProps;

export const ResizeTableSome = <RecordType extends object = any>(
  props: CombinedProps<RecordType>,
) => {
  const { columns, ...restProps } = props

  const [innerColumns, setInnerColumns] = useState<ColumnsType>([])

  /* 修改列宽包裹函数 */
  const resizeWrap = useCallback(
    (i: number) =>
      (_: any, { size }: { size: { width: number } }) => {
        const nextCol = [...innerColumns]
        nextCol[i] = {
          ...nextCol[i],
          width: size.width,
        }
        setInnerColumns(nextCol)
      },
    [innerColumns, setInnerColumns],
  )

  /* 双击header 自动换行 */
  const autoTextWrap = useCallback(
    (i: number) => (_: any, p: any) => {
      const nextCol = [...innerColumns]
      nextCol[i] = {
        ...nextCol[i],
        ellipsis: !nextCol[i]?.ellipsis,
      }
      setInnerColumns(nextCol)
    },
    [innerColumns, setInnerColumns],
  )

  useEffect(() => {
    if (!columns) return
    setInnerColumns([...columns] as any)
  }, [columns, setInnerColumns])

  if (!innerColumns) return null

  const myCol = innerColumns?.map((col: any, i) => {
    const nextCol = { ...col } as any
    if (props?.info) {
      const info = props?.info || {}
      if (i === info?.index) {
        nextCol.onHeaderCell = (column: any) => ({
          width: column.width || defaultWidth,
          onResize: resizeWrap(i),
          info: info, // 自定义属性, 传递要拖拽的列（index, title）
          keyName: nextCol?.key
        })
      }
    } else {
      nextCol.onHeaderCell = (column: any) => ({
        width: column.width || defaultWidth,
        onResize: resizeWrap(i),
        onDoubleClick: autoTextWrap(i),
      })
    }
    if (col.ellipsis && col.dataIndex && !col.render) {
      nextCol.render = (content: any) => {
        if (!content) return null
        return <Tooltip title={content}>{content}</Tooltip>
      }
    }

    return nextCol
  })
  
  return (
    <Table<RecordType>
      pagination={false}
      {...restProps}
      className={classNames(style.CQTable, restProps.className)}
      columns={myCol}
      components={{ header: { cell: ResizableTitle } }}
    ></Table>
  )
}

const ResizableTitle = (props: {
  onResize: (e: React.SyntheticEvent, data: ResizeCallbackData) => any
  width?: number
  className?: string
  [props: string]: any
}) => {
  const { onResize, width = defaultWidth, className, keyName, ...restProps } = props
  
  // 定义最小和最大宽度
  const minWidth = 150
  const maxWidth = 400

  if (!width) {
    return <th {...restProps} />
  }

  const handleResize = (e: SyntheticEvent, data: ResizeCallbackData) => {
    // 限制宽度在最小和最大值之间
    const newWidth = Math.max(minWidth, Math.min(maxWidth, data?.size?.width));
    // 调用父组件传递的 onResize 方法
    onResize(e, { ...data, size: { width: newWidth, height: 0}})
  }
  
  return (
      props?.info && props?.info?.keyName === keyName ?
      <Resizable
        width={width}
        height={0}
        handle={
          <span
            className="react-resizable-handle"
            onClick={(e) => {
              e.stopPropagation()
            }}
          />
        }
        onResize={handleResize}
        draggableOpts={{ enableUserSelectHack: false }}
        minConstraints={[minWidth, 0]}  // 设置最小宽度
        maxConstraints={[maxWidth, Infinity]} // 设置最大宽度
      >
        <th {...restProps} className={classNames(className, style.tableHeader)} />
      </Resizable>
      : 
      !!props?.children[1]?.$$typeof ?
      <th {...restProps} style={{paddingLeft: 8, paddingRight: 8, textAlign: 'center'}} />
      : <th {...restProps} />
  )
}
