// import { storeRegister } from 'src/store/storeRegister'
import React from 'react'
import { Modal, message } from 'antd'
import JSONBigFn from 'json-bigint'
import { LogoutMessage } from 'src/features/LogoutMessage'
import { ProviderStore } from 'src'
import { setIsLoggedIn } from 'src/appPages/login/loginSlice';
import { store } from 'src/store/index'
import { UAParser } from 'ua-parser-js'; 
import SparkMD5 from 'spark-md5'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { showModal } from 'src/store/extraSlice/modalVisibleSlice'

const RPC_ERROR_CODE = 2006
const RPC_ERROR_MESSAGE = 'rpc-error-2006'

const JSONBig = JSONBigFn({ storeAsString: true })

const { confirm } = Modal

/**
 * key => message
 * value => 是否阻止 message 显示
 */
const blockMap = new Map<string, boolean>()

const throttleErrorMessage = (msg: string) => {
  const block = blockMap.get(msg)
  // 当前处于 block 状态，直接略过
  if (block) return
  // 当前非 block 状态，显示 error message 并设置 block 状态
  message.error(msg)
  blockMap.set(msg, true)
  // 1s 后关闭 block 状态
  setTimeout(() => {
    blockMap.set(msg, false)
  }, 1000)
}

const throttleWarnMessage = (msg: string) => {
  const block = blockMap.get(msg)
  // 当前处于 block 状态，直接略过
  if (block) return
  // 当前非 block 状态，显示 error message 并设置 block 状态
  message.warn(msg)
  blockMap.set(msg, true)
  // 1s 后关闭 block 状态
  setTimeout(() => {
    blockMap.set(msg, false)
  }, 1000)
}

const showConfirmLogin = () => {
  const now = new Date();
  confirm({
    title: `您的账号于${now}在其他设备登录`,
    icon: <ExclamationCircleOutlined />,
    content: '如果非本人操作，可能密码已经泄露，建议修改密码，保证账号安全',
    onOk() {
      console.log('确定');
    },
    onCancel() {
      console.log('Cancel');
    },
  });
};

const handleHttpError = (response: Response) => {
  if (response.ok) return
  if (response.status === 401) {
    // const persistor = storeRegister.getPersistor()
    // persistor && persistor.purge()
    // throttleErrorMessage('登录失效')
    // message.destroy()
    // message.warn(<ProviderStore><LogoutMessage /></ProviderStore>, 0)
    store?.dispatch(setIsLoggedIn(false))
  }
  if (response.status === 403) {
    // reload重新走请求ip，设置ip访问限制为false的流程
    throttleErrorMessage('你没有权限访问此系统，请联系管理员');
  }
  if (response.status === 404) {
    throttleErrorMessage('服务未启动或网络异常')
  }
  if (response.status === 405) {
    throttleErrorMessage('方法不允许')
  }
  if (response.status === 408) {
    throttleErrorMessage('请求超时')
  }
  if (response.status === 413) {
    const message = response?.statusText ? `${response?.status}, ${response?.statusText}` : response.status
    throttleErrorMessage(`${message}`)
  }
  if (response.status >= 500) {
    if (response.status === 502) {
      throttleErrorMessage('网关错误')
    } else if (response.status === 503) {
      throttleErrorMessage('服务不可用')
    } else if (response.status === 504) {
      throttleErrorMessage('网关超时')
    } else {
      throttleErrorMessage('服务异常')
    }
  }
  throw new Error(`${response.status}, ${response.statusText}`)
}

const handleDataError = ({
  resMsg,
  resCode,
}: {
  resMsg: string
  resCode: number
}) => {
  if (resCode !== 10000) {
    const msg = resCode === RPC_ERROR_CODE ? RPC_ERROR_MESSAGE : resMsg
    if (msg === '请进行双因素验证') {
      throttleWarnMessage(msg)
    } else {
      throttleErrorMessage(msg)
    }
    throw new Error(msg)
  }
}

/**
 * 处理过 http 状态码报错的 fetch 请求
 */
export const fetchWithStatusHandler = async (
  ...args: Parameters<typeof fetch>
): Promise<Response> => {
  try {
    const response = await fetch(...args)
    handleHttpError(response)
    return response
  } catch (e) {
    if (e instanceof Error) {
      return Promise.reject(e.message)
    }
    return Promise.reject(e)
  }
}


/**
 * 处理过 http 状态码报错的 fetch 请求
 */
export const formFetchWithStatusHandler = async (
  ...args: Parameters<typeof fetch>
): Promise<Response> => {
  try {
    const response = await fetch(...args)
    handleHttpError(response)
    return response
  } catch (e: any) {
    if (e instanceof Error) {
      return Promise.reject(e.message)
    }
    // 上传失败报错提示 
    throttleErrorMessage('上传失败')
    return Promise.reject(e)
  }
}

export const requestInitJSON = (params?: object | string) => {
  const headers = { 'Content-Type': 'application/json' }
  let body: string | undefined
  if (typeof params === 'string') {
    body = params
  } else {
    body = JSON.stringify(params)
  }
  return { headers, body }
}

export const requestInitFormData = (params?: Record<any, any>) => {
  const formData = new FormData()
  if (params) {
    Object.keys(params).forEach((key) => {
      if(Array.isArray(params[key])) {
        params[key].forEach((item: any) => {
          formData.append(key, item)
        })
      }else{
        formData.append(key, params[key])
      }
    })
  }
  return { body: formData }
}

/**
 * read response as json，handle error in json
 */
export const readResponseAsJSON = async (response: Response) => {
  try {
    const { data, ...rest } = await response.json()
    handleDataError(rest)
    return data
  } catch (e) {
    if (e instanceof Error) {
      return Promise.reject(e.message)
    }
    return Promise.reject(e)
  }
}

/**
 * handle bigint in json
 */
export const readResponseAsJSONBig = async (response: Response) => {
  try {
    const str = await response.text()
    const { data, ...rest } = JSONBig.parse(str)
    handleDataError(rest)
    return data
  } catch (e) {
    if (e instanceof Error) {
      return Promise.reject(e.message)
    }
    return Promise.reject(e)
  }
}

/**
 * get filename from response
 */
const getFilenameFromResponse = (response: Response) => {
  let filename = ''
  const disposition = response.headers.get('Content-Disposition')
  if (disposition && disposition.indexOf('attachment') !== -1) {
    // https://stackoverflow.com/questions/23054475/javascript-regex-for-extracting-filename-from-content-disposition-header
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(disposition)
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '')
    }
  }
  try {
    filename = decodeURIComponent(filename)
  } catch (e) {
    console.error(e)
  }
  return filename
}

/**
 * handle download response
 */
export const downloadResponse = async (response: Response, fileName?: string) => {
  try {
    const blob = await response.blob()
    const url = URL.createObjectURL(blob)
    const filename = getFilenameFromResponse(response)
    const link = document.createElement('a')
    link.href = url
    link.download = fileName ? fileName : filename
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(link.href)
    link.remove()
  } catch (e) {
    if (e instanceof Error) {
      return Promise.reject(e.message)
    }
    return Promise.reject(e)
  }
}

// 根据操作系统参数生成一个设备ID
export const generateDeviceId = () => {
  const parser = new UAParser()
  let deviceInfo = parser.getResult()
  deviceInfo.ua = String(window.screen.width)
  deviceInfo.browser = { name: '', version: '', major: '' }
  const deviceId = new SparkMD5()
  deviceId.append(JSON.stringify(deviceInfo))
  return deviceId.end()
}