import React, { FC, useEffect, useMemo, useState } from 'react';
import { Table, message } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useRequest, useSelector, useDispatch } from 'src/hook';
import { BtnWithConfirmModal } from 'src/components';
import { APP_EFFECTIVE_STATES, MyApplyTabKeyType, NO_NEED_TO_LAND_TYPES } from '../../constants';
import { columnsRequest, columnsRequestExpandRow } from '../../common/columns';
import {
  getMyApplications,
  getUrgeReminders,
  myApplyChildList,
  withdrawOrder,
  getInventoryData,
  reopenFlowOrder,
  reApplyFlowOrder,
  deleteOrder
} from 'src/api';
import { openFlowForm } from 'src/pageTabs/flowPages';
import { getEffectiveStatus } from '../../utils';
import styles from '../index.module.scss';

export interface IProps {
  activeKey: any;
  refreshShoppingCart: () => void;
}

const MyApplyTablePage: FC<IProps> = ({ ...props }) => {
  const { activeKey, refreshShoppingCart } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { applySearchValue } = useSelector((state) => state.accessRequest);
  const userId = useSelector((state) => state.login.userInfo.userId);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filterType, setFilterType] = useState<any>(null);
  const [timeSort, setTimeSort] = useState<string>('desc');

  // 我的申请列表
  const { data, loading, refresh, run } = useRequest(getMyApplications, {
    manual: true,
    formatResult: (data) => {
      const list = data.myApplyResponseVoList;
      const total = data.totalElements;
      return { list, total };
    },
  });

  // 催一下
  const { run: urgeRun } = useRequest(getUrgeReminders, {
    manual: true,
    throttleInterval:800,
    onSuccess() {
      message.success('催成功');
      refresh();
    },
  });

  //撤回申请
  const { run: runWithDrawApplication } = useRequest(withdrawOrder, {
    manual: true,
    onSuccess: () => {
      message.success('撤回成功');
      setCurrentPage(1);
      let params = {
        userId,
        pageSize,
        currentPage: 1,
        title: applySearchValue,
        timer: timeSort,
        priGranType: filterType,
      } as any;
      setCurrentPage(1);
      // setPageSize(10)
      if (activeKey === 'already') {
        params.powerStatus = activeKey;
      } else {
        params.applyStatus = activeKey;
      }
      run(params);
    },
  });
  //再次申请
  const { run: runReApplyFlowOrder } = useRequest(reApplyFlowOrder, {
    manual: true,
    onSuccess: () => {
      message.success('成功加入申请单');
    },
  });

  //重新申请
  const { run: runReopenFlowOrder } = useRequest(reopenFlowOrder, {
    manual: true,
    onSuccess: () => {
      message.success('重新申请成功');
      refresh();
    },
  });

    //删除
    const { run: runDeleteFlowOrder } = useRequest(deleteOrder, {
      manual: true,
      onSuccess: () => {
        message.success('删除成功');
        refresh();
      },
    });

  useEffect(() => {
    if (userId) {
      let params = {
        userId,
        pageSize,
        currentPage: 1,
        title: applySearchValue,
        timer: timeSort,
        priGranType: filterType,
      } as any;
      setCurrentPage(1);
      // setPageSize(10)
      if (activeKey === 'already') {
        params.powerStatus = activeKey;
      } else {
        params.applyStatus = activeKey;
      }
      run(params);
    }
  }, [activeKey, applySearchValue, userId]);

  const reApplyMethods = async (record: any) => {
    if (record?.priGranType === 'highRisk') {
      const orderDetail = await getInventoryData({
        flowMainUUID: record?.mainUUID,
        connectionIds: record?.connectionIds,
        canOperation: record?.canOperation ? record?.canOperation : null,
      });

      dispatch(
        openFlowForm({
          type: 'highRisk',
          fields: {
            elements: [
              {
                connectionId: record?.connectionIds?.[0],
                connectionType: orderDetail?.dataSourceType,
                nodeName: orderDetail?.nodePathName,
                nodePath: orderDetail?.nodePathList?.[0] || '',
                nodePathWithType: orderDetail?.nodePathWithTypeList?.[0] || '',
                nodeType: orderDetail?.nodeType,
              },
            ],
            operationList: orderDetail?.operationList || [],
          },
        })
      );
    } else if (record?.priGranType === 'desensitizedResource') {
      const orderDetail = await getInventoryData({
        flowMainUUID: record?.mainUUID,
        connectionIds: record?.connectionIds,
        canOperation: record?.canOperation ? record?.canOperation : null,
      });
       //无权限的脱敏资源
       const noPermissionSensitiveResourceElements = orderDetail?.nodePathWithTypeList?.map((key: any) => ({
        label:'',
        value:key 
      }));
      dispatch(
        openFlowForm({
          type: 'desensitizedResource',
          fields: {
            //@ts-ignore
            elements: noPermissionSensitiveResourceElements,
            //@ts-ignore
            connectionId: record?.connectionIds?.[0],
            connectionType: orderDetail?.dataSourceType,
            nodeType: orderDetail?.nodeType,
          },
        })
      );
    } else{
     await runReApplyFlowOrder(record?.applyId);
      record?.priGranType === 'FAT' && refreshShoppingCart();
    }
  };

  const renderTabAction = (record: any, activeKey: MyApplyTabKeyType) => {
    if (activeKey === 'pending') {
      return (
        <>
          <span
            onClick={() => {
              urgeRun({
                flowId: Number(record?.applyId),
                senderIds: record?.currentAssignee,
              });
            }}
          >
            催一下
          </span>
          <BtnWithConfirmModal
            title="确认撤回此记录？"
            btnText="撤回"
            onClick={(reason?: string) =>
              runWithDrawApplication({
                flowId: record?.applyId,
                withdrawRemark: reason,
                isMyApply: true,
              })
            }
          />
        </>
      );
    } else if (activeKey === 'pass') {
      return (
        <>
          {
            //已驳回 || 已通过但已失效 可以再次申请, '导出申请'无'再次申请'操作
            ( record?.applyStatus === 'refuse' ||
              getEffectiveStatus(record?.beginTime, record?.endTime) ===
                APP_EFFECTIVE_STATES.EXPIRED) && record.applyType !== 'exportTask' && (
              <BtnWithConfirmModal
                title="确认再次申请？"
                btnText="再次申请"
                hideReason={true}
                onClick={() => reApplyMethods(record)}
              />
            )
          }
        </>
      );
    } else if (activeKey === 'already') {
      return (
        <>
          {/* ! 全部通过且未失效的 */}
          {!(getEffectiveStatus(record?.beginTime, record?.endTime) !==
            APP_EFFECTIVE_STATES.EXPIRED && record?.allChildApprove) && (
            <BtnWithConfirmModal
              title="确认再次申请？"
              btnText="再次申请"
              hideReason={true}
              onClick={() => reApplyMethods(record)}
            />
          )}
        </>
      );
    } else if (['withdraw'].includes(activeKey)) {
      return (
        <>
          {
            // 未失效都能重新申请
            getEffectiveStatus(record?.beginTime, record?.endTime) !==
              APP_EFFECTIVE_STATES.EXPIRED && (
              <BtnWithConfirmModal
                title="确认重新申请？"
                btnText="重新申请"
                hideReason={true}
                onClick={() => runReopenFlowOrder(record?.applyId)}
              />
            )
          }
          <BtnWithConfirmModal
              title="确认删除？"
              btnText="删除"
              hideReason={true}
              onClick={() => runDeleteFlowOrder(Number(record?.applyId))}
          />
        </>
      );
    } else {
      return '-';
    }
  };
  const newColumns = useMemo(() => {
    let newColumns = columnsRequest.map((item: any) => {
      if (item?.dataIndex === 'uuid') {
        return {
          ...item,
          render: (val: any, record: any) => (
            <Link
              className={styles.underLine}
              to={{
                pathname: `/mine_apply/${val}/detail`,
                state: record,
              }}
            >
              {val}
            </Link>
          ),
        };
      }
      if (item?.dataIndex === 'actions') {
        return {
          ...item,
          fixed: 'right',
          width: 150,
          render: (_: any, record: any) => (
            <div className={styles.optionsBtnText}>
              {renderTabAction(record, activeKey)}
            </div>
          ),
        };
      }
      return item;
    });
    if (['pass', 'already'].includes(activeKey)) {
      newColumns.splice(6, 1, {
        title: '生效状态',
        dataIndex: 'effectiveState',
        width: 100,
        render: (_: string, record: any) => {
          return (record?.applyStatus === 'pass' && activeKey === 'pass') ||
            activeKey === 'already'
            ? getEffectiveStatus(record?.beginTime, record?.endTime, record.applyType)
            : '-';
        },
      });
    }
    //样式
    return newColumns;
  }, [activeKey]);

  const onChange = (pagination: any, filters: any, sorter: any) => {
    setFilterType(filters?.priGranType ? filters?.priGranType[0] : null);
    let params = {
      userId,
      pageSize: pagination?.pageSize,
      currentPage: pagination?.current,
      title: applySearchValue,
      timer: sorter?.order === 'ascend' ? 'asc' : 'desc',
      priGranType: filters?.priGranType ? filters?.priGranType[0] : null,
    } as any;
    setCurrentPage(pagination?.current);
    setPageSize(pagination?.pageSize);
    setTimeSort(sorter?.order === 'ascend' ? 'asc' : 'desc');
    // setPageSize(10)
    if (activeKey === 'already') {
      params.powerStatus = activeKey;
    } else {
      params.applyStatus = activeKey;
    }
    run(params);
    setCurrentPage(pagination?.current);
    setPageSize(pagination?.pageSize);
  };

  return (
    <Table
      rowKey="uuid"
      loading={loading}
      className={styles.tablePage}
      rowClassName={(record, index) =>
        ((record?.applyStatus === 'pass' && activeKey === 'pass') ||
          activeKey === 'already') &&
        getEffectiveStatus(record?.beginTime, record?.endTime) ===
          APP_EFFECTIVE_STATES.EXPIRED && record.applyType !== 'exportTask' // 导出申请表单不置灰
          ? styles.notInEffect
          : ''
      }
      expandable={{
        expandedRowRender: (record: any) => (
          <ExpandedRowContent
            record={record}
            activeKey={activeKey}
            urgeRun={urgeRun}
          />
        ),
        rowExpandable: (record) => !NO_NEED_TO_LAND_TYPES.includes(record?.priGranType),
      }}
      columns={newColumns}
      dataSource={data?.list}
      size="small"
      pagination={{
        current: currentPage,
        pageSize,
        total: data?.total,
        showSizeChanger: true,
        showQuickJumper: true,
      }}
      onChange={onChange}
      scroll={{ x: "1200", y: `calc(100vh - 280px)` }}
    />
  );
};

export const ExpandedRowContent = ({
  record,
  activeKey,
  urgeRun,
}: {
  record: any;
  activeKey: string;
  urgeRun: any;
}) => {
  const flowUUID = record?.uuid;
  const {
    data: childData,
    run: childRun,
    refresh: childRefresh,
    loading,
  } = useRequest(myApplyChildList, {
    manual: true,
    refreshDeps: [flowUUID],
  });

  useEffect(() => {
    // 和后端协商 只有我的审批 待审批传flowTaskId 其他传字符1
    if (flowUUID) {
      childRun(flowUUID, '1');
    }
  }, [flowUUID]);

  let newExpandColumns = [];
  newExpandColumns = columnsRequestExpandRow.map((item: any) => {
    if (item?.dataIndex === 'uuid') {
      return {
        ...item,
        render: (val: any, record: any) => (
          <Link
            className={styles.underLine}
            to={{
              pathname: `/mine_apply/${val}/detail`,
              state: record,
            }}
          >
            {val}
          </Link>
        ),
      };
    }
    return item;
  });

  if (activeKey === 'power') {
    newExpandColumns.push({
      title: '操作',
      dataIndex: 'actions',
      width: '20%',
      render: (_: any, record: any) =>
        record?.applyStatus === 'power' && (
          <div
            className={
              record?.flowApplyStatus === 'refuse'
                ? styles.optionsRefuseBtn
                : styles.optionsBtnText
            }
          >
            <span
              onClick={() => {
                urgeRun({
                  flowId: Number(record?.applyId),
                  senderIds: record?.connectionManagerId,
                  childMainUUID: record?.uuid,
                });
              }}
            >
              催一下
            </span>
          </div>
        ),
    });
  }

  return (
    <Table
      rowKey="uuid"
      loading={loading}
      columns={newExpandColumns}
      dataSource={childData}
      pagination={false}
      size="middle"
      className={styles.childTable}
    />
  );
};

export { MyApplyTablePage };
